export const getLastMajorReleaseDate = (currentDate: Date = new Date()): Date => {
  const milestones: number[] = [1, 4, 7, 10]; // Milestone months (January, April, July, October)
  const year: number = currentDate.getFullYear();
  const month: number = currentDate.getMonth() + 1;
  const day: number = currentDate.getDate();

  let milestoneMonth: number | undefined = milestones.filter(m => m < month || (m === month && day >= 15)).slice(-1)[0];

  if (!milestoneMonth) {
    milestoneMonth = milestones[milestones.length - 1];
    return new Date(Date.UTC(year - 1, milestoneMonth - 1, 15));
  }

  return new Date(Date.UTC(year, milestoneMonth - 1, 15));
};
