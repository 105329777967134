import React from 'react';
import { styled } from '@mui/material';
import { BigidLoader } from '@bigid-ui/components';
import { DataRiskAreaChart } from './DataRiskAreaChart';
import { DataRiskProgressChart } from './DataRiskProgressChart';
import { DataRiskDataPoint, ProgressChartData } from '../../types';
import { DataRiskToggleOption } from '../DataRiskWidget';
import { DataRiskFindingsIndicator } from './DataRiskFindingsIndicator';

const ChartsContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 264px;
`;

const ProgressChartWrapper = styled('div')`
  padding: 0 24px;
  margin-right: 80px;
  width: 280px;
  z-index: 1;
`;

const AreaChartWrapper = styled('div')`
  flex-grow: 1;
`;

export const DataRiskCharts = ({
  areaChart,
  progressChart,
  viewByOption,
  isLoading = false,
}: {
  areaChart: DataRiskDataPoint[];
  progressChart: ProgressChartData;
  viewByOption: DataRiskToggleOption;
  isLoading: boolean;
}) => {
  return (
    <ChartsContainer>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <ProgressChartWrapper>
            {viewByOption === DataRiskToggleOption.OBJECTS ? (
              <DataRiskProgressChart progressChart={progressChart} />
            ) : (
              <DataRiskFindingsIndicator findingsCount={progressChart.total} />
            )}
          </ProgressChartWrapper>
          <AreaChartWrapper>
            <DataRiskAreaChart areaChart={areaChart} />
          </AreaChartWrapper>
        </>
      )}
    </ChartsContainer>
  );
};
