import React, { FC, memo, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { fetchAttributes } from '../../services/classifiersManagementService';
import { mapAttributesToDropdownValues } from '../../utils/utils';
import { generateDataAid } from '@bigid-ui/utils';
import { LabelWithTooltip } from '../LabelWithTooltip/LabelWithTooltip';
import { styled } from '@mui/material';
import { usePermissions } from '../../hooks/usePermissions';

const CategoriesFieldDropdownContainer = styled('div')`
  margin-bottom: 16px;
`;

const getMappedValue = (selectedOptions: BigidDropdownOption[], friendly_name: string) => {
  return selectedOptions?.length
    ? { friendly_name: selectedOptions?.[0]?.value?.name, glossary_id: selectedOptions?.[0]?.value?.glossary_id }
    : {
        friendly_name,
      };
};

export const AttributesFieldDropdown: FC<BigidFieldRenderProps> = memo(({ value = {}, setValue }) => {
  const { t } = useLocalTranslation('form');
  const { isCorrelationPermitted } = usePermissions();
  const { friendly_name, glossary_id } = value;

  const initialAttribute =
    friendly_name && glossary_id
      ? [
          {
            id: glossary_id,
            displayValue: friendly_name,
            value: { _id: glossary_id, name: friendly_name },
          },
        ]
      : [];
  const [selectedAttribute, setSelectedAttribute] = useState<BigidDropdownValue>(initialAttribute);
  const [attributes, setAttributes] = useState<BigidDropdownOption[]>([]);

  useEffect(() => {
    const handleFetchAttributes = async () => {
      const attributesResponse = await fetchAttributes();
      const mappedAttributes = mapAttributesToDropdownValues(attributesResponse?.data);

      setAttributes(mappedAttributes);
    };

    isCorrelationPermitted && handleFetchAttributes();
  }, []);

  const onCreateFriendlyName = async (value: string): Promise<BigidDropdownOption> => {
    const id = v4();

    const newAttribute = {
      id,
      displayValue: value,
      value: { _id: id, name: value },
    };

    setSelectedAttribute([newAttribute]);
    setAttributes([newAttribute, ...attributes]);
    return newAttribute;
  };

  const onSelect = (selectedOptions: BigidDropdownOption[]) => {
    setValue(getMappedValue(selectedOptions, friendly_name));
    setSelectedAttribute(selectedOptions);
  };

  return (
    <CategoriesFieldDropdownContainer>
      <LabelWithTooltip label={t('attributeName')} tooltipText={t('tooltips.attributes')} />
      <BigidDropdown
        size="large"
        onSelect={onSelect}
        options={attributes}
        value={selectedAttribute}
        onCreate={onCreateFriendlyName}
        dataAid={generateDataAid('AttributesFieldDropdown', ['attribute-dropdown'])}
        displayLimit={attributes?.length}
        isSearchable
        isCreatable
        isAsync
        isIgnoreCaseSensitive={true}
        isErasable
        readOnly={!isCorrelationPermitted}
      />
    </CategoriesFieldDropdownContainer>
  );
}, compareObjectsExceptFunctions);
