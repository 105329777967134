import { BigidAreaChart } from '@bigid-ui/visualisation';
import { BigidColorsV2, capitalizeFirstLetter } from '@bigid-ui/components';
import React, { FC } from 'react';
import { DataCoverageDataPoint } from '../../types';
import { DATA_COVERAGE_FORMATTERS, DataCoverageToggleOption } from '../DataCoverageWidget';

export const DataCoverageAreaChart: FC<{
  areaChart: DataCoverageDataPoint[];
  viewByOption: DataCoverageToggleOption;
}> = ({ areaChart = [], viewByOption }) => {
  return (
    <BigidAreaChart
      colorDataMap={{ listed: BigidColorsV2.blue[600], scanned: BigidColorsV2.cyan[300] }}
      isLineSmoothed={true}
      numberFormat={DATA_COVERAGE_FORMATTERS[viewByOption] || DATA_COVERAGE_FORMATTERS[DataCoverageToggleOption.AMOUNT]}
      data={areaChart}
      seriesLabelFormatter={series => capitalizeFirstLetter(series)}
      showChartCursor={true}
    />
  );
};
