import { BigidFormField, BigidFormStateAndHandlers, BigidSelectOption, ValidationResult } from '@bigid-ui/components';
import { DataSourceTestConnectionStatusEnum } from '../../DataSourceConfiguration/hooks/useTestConnection';
import { DataSourceConnectionModalOverlaySteps } from '../DataSourceConnectionModalOverlay';
import { TestConnectionError } from '../../DataSourceConfiguration/constants/enums';
import { MAX_TEST_CONNECTION_OBJECTS_TO_DISPLAY, NESTED_FIELD_DELIMITER } from '../constants/constants';
import { EMPTY_ERROR_STATE } from '../hooks/useForm';
import { addIdToArray, getMostCommonObjectType } from '../utils/utils';
import type { FC, MutableRefObject } from 'react';
import type { DataSourceConfigStateV2 } from '../DataSourceConnectionModal';
import type { TestConnectionResolutionStep } from '../hooks/useTestConnection';
import type { DataSourceConnectionErrorInsightsOption } from '../DataSourceConnectionErrorInsights';
import type {
  DataSourceTestConnectionRow,
  DataSourceTestConnectionRowType,
} from '../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { DsConnectionFieldSectionsEnum } from '../../DataSourceConfiguration/types';
import type { DataSource } from '../../../../utilities/dataSourcesUtils';

type DataSourceSubmitPayload = Record<string, string>;

type DsConnectionFieldSection = {
  name: DsConnectionFieldSectionsEnum;
  title?: string;
  component?: FC<any>;
};

const INITIAL_STEP: DataSourceConnectionModalOverlaySteps = null;
const EMPTY_ERROR_ENTRY: string[] = [null, null];

const testConnectionStatusMapper: Record<DataSourceTestConnectionStatusEnum, DataSourceConnectionModalOverlaySteps> = {
  [DataSourceTestConnectionStatusEnum.notStarted]: INITIAL_STEP,
  [DataSourceTestConnectionStatusEnum.success]: DataSourceConnectionModalOverlaySteps.SUCCESS,
  [DataSourceTestConnectionStatusEnum.failed]: INITIAL_STEP,
  [DataSourceTestConnectionStatusEnum.partialSuccess]: DataSourceConnectionModalOverlaySteps.SUCCESS,
};

const testConnectionErrorMapper: Record<string, TestConnectionError> = {
  'Authorization exception': TestConnectionError.AUTHENTICATION,
  'Network exception': TestConnectionError.NETWORK,
};

const sectionNameToRenderFieldSectionMapper: Partial<
  Record<DsConnectionFieldSectionsEnum, DsConnectionFieldSection[]>
> = {
  [DsConnectionFieldSectionsEnum.connection]: [
    { name: DsConnectionFieldSectionsEnum.connection },
    { name: DsConnectionFieldSectionsEnum.connectionAdvanced, title: 'Advanced' },
  ],
  [DsConnectionFieldSectionsEnum.details]: [{ name: DsConnectionFieldSectionsEnum.details }],
  [DsConnectionFieldSectionsEnum.scanSettings]: [{ name: DsConnectionFieldSectionsEnum.scanSettings }],
};

const relatedFieldsMapperFn = (
  values: Record<string, string>,
  fields: BigidFormField[],
  payload: DataSourceSubmitPayload,
): Partial<Record<TestConnectionError, () => string[]>> => ({
  [TestConnectionError.NETWORK]: () => {
    const networkFields = ['resourceEntry', 'resourceAddress'];

    return networkFields.reduce((acc, field) => {
      const { name } = fields.find(({ misc }) => misc.apiName === field) ?? {};
      return [...acc, name].filter(Boolean);
    }, []);
  },
  [TestConnectionError.AUTHENTICATION]: () => {
    const { authStrategy } = payload;
    const excludeFields = ['@authenticationType'];

    return fields
      .filter(
        ({ name }) =>
          name.startsWith(`${authStrategy}${NESTED_FIELD_DELIMITER}`) &&
          !excludeFields.some(field => name.includes(field)),
      )
      .map(({ name }) => name);
  },
});

export const mapTestConnectionStatusToOverlayStep = (
  status: DataSourceTestConnectionStatusEnum,
  showDetachedSuccess: boolean,
) => (showDetachedSuccess ? INITIAL_STEP : testConnectionStatusMapper[status] ?? INITIAL_STEP);

export const mapAdditionalPropsToBigidFormField = (
  { fieldProps, ...rest }: BigidFormField,
  additionalProps: Record<string, unknown>,
) => ({ ...rest, fieldProps: { ...fieldProps, ...additionalProps } });

export const mapErrorStringToTestConnectionError = (error: string): TestConnectionError => {
  const [, errorType] =
    Object.entries(testConnectionErrorMapper).find(([str]) => error.includes(str)) ?? EMPTY_ERROR_ENTRY;

  return errorType as TestConnectionError;
};

export const mapTestConnectionErrorToRelatedFields = (
  error: TestConnectionError,
  configState: DataSourceConfigStateV2['configDataSourceState'],
  control: MutableRefObject<BigidFormStateAndHandlers>,
  payload: DataSourceSubmitPayload,
): string[] => {
  const { fieldConfig: normalizedFields } = configState;
  const relatedFieldsGetter = relatedFieldsMapperFn(control.current.getValues(), normalizedFields, payload)[error];

  return relatedFieldsGetter ? relatedFieldsGetter() : [];
};

export const mapFieldNameToErrorPayload = (fields: string[]) =>
  fields.reduce<Record<string, ValidationResult>>((acc, field) => ({ ...acc, [field]: true }), EMPTY_ERROR_STATE);

export const mapErrorStringToErrorParts = (error: string) => {
  const type = mapErrorStringToTestConnectionError(error);
  const [, message] = error.split(':') ?? [null, null];

  return [type ?? TestConnectionError.CONNECTION, message ?? error];
};

export const mapTestConnectionResolutionStepsToOptions = (
  steps: TestConnectionResolutionStep[],
): DataSourceConnectionErrorInsightsOption[] =>
  steps?.map(({ stepHeader, stepID, stepText }) => ({ id: stepID, title: stepHeader, content: stepText })) ?? null;

export const mapTestConnectionResultsForNewExperience = (
  tablesResult: DataSourceTestConnectionRow[],
): { gridRowType: DataSourceTestConnectionRowType; tablesResult: DataSourceTestConnectionRow[] } => {
  const resultsSliced = tablesResult.slice(0, MAX_TEST_CONNECTION_OBJECTS_TO_DISPLAY);
  const commonObjectType = getMostCommonObjectType(resultsSliced);

  const finalTableResuls = addIdToArray(resultsSliced).filter(table =>
    commonObjectType ? table.objectType === commonObjectType : true,
  );

  return {
    tablesResult: finalTableResuls,
    gridRowType: commonObjectType,
  };
};

export const getDataSourceTypeDisplayName = (dataSourceTypes: DataSource[], dataSourceType: string) => {
  const { displayName } = dataSourceTypes?.find(({ type }) => type === dataSourceType) ?? {};
  return displayName;
};

export const mapSectionNameToRenderFieldSections = (sectionName: DsConnectionFieldSectionsEnum) =>
  sectionNameToRenderFieldSectionMapper[sectionName] ?? [];

export const mapBigidSelectOptionToValue = (options: BigidSelectOption[]): string[] =>
  options?.map(({ value }) => value);

export const mapErrorsObjectToErrorMessage = (errors: Record<string, boolean | string>): string => {
  return (Object.values(errors ?? {})?.find(value => typeof value === 'string') as string) ?? '';
};
