import { BigidMenuItemProps, EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { BigidAddRowIcon, BigidDuplicateIcon, BigidEditIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  deleteCorrelationSets,
  duplicateCorrelationSetsByIds,
  updateCorrelationSets,
} from '../services/correlationSetsService';
import { CorrelationSetModel } from '../CorrelationSetConnections/CorrelationSetConnectionTypes';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { notificationService } from '../../../services/notificationService';
import { CORRELATION_SET_CONNECTIONS_GRID_ID, isIdConnectionEnabled } from '../utils/utils';
import { isPermitted } from '../../../services/userPermissionsService';
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';

const DATA_AID = 'drop-down-item';

interface useDropDownActionsProps {
  idConnection: CorrelationSetModel;
  setName: Dispatch<SetStateAction<string>>;
  setIdConnection: Dispatch<SetStateAction<CorrelationSetModel>>;
  handleOpenWizard?: (correlationName: string) => void;
}

export const useDropDownActions = ({
  idConnection,
  setName,
  setIdConnection,
  handleOpenWizard,
}: useDropDownActionsProps) => {
  const { t } = useLocalTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleDuplicate = async () => {
    try {
      setIsLoading(true);
      const data = await duplicateCorrelationSetsByIds([idConnection.id as string]);
      entityEventsEmitter.emit(EntityEvents.ADD, {
        entityId: CORRELATION_SET_CONNECTIONS_GRID_ID,
        payload: {
          rows: [
            {
              ...idConnection,
              _id: data.data.data.insertedId,
              id: data.data.data.name,
              name: data.data.data.name,
            },
          ],
        },
        shouldEntityReload: false,
      });
      setName(data.data.data.name);
      setIsLoading(false);
      notificationService.success(t('notifications.duplicatedSuccessfully'));
    } catch (error) {
      notificationService.error(t('notifications.duplicatedFailed'));
    }
  };

  const handleDisableEnable = async (isDisable: boolean) => {
    try {
      setIsLoading(true);
      const updatedFields = { enabled: isDisable ? 'no' : 'yes' };

      const payload = {
        ids: [idConnection.id],
        updatedFields,
      };
      // check status code
      await updateCorrelationSets(payload);
      entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, idConnection.id, { ...updatedFields });
      setIdConnection(prevIdConnection => ({ ...prevIdConnection, ...updatedFields }));
      setIsLoading(false);
    } catch (error) {
      notificationService.error(t('notifications.updateFaield'));
    }
  };

  const handleDelete = async () => {
    try {
      const shouldDelete = await showConfirmationDialog({
        entitiesCount: 1,
        entityNameSingular: 'Correlation Set',
        entityNamePlural: 'Correlation Sets',
      });

      if (shouldDelete) {
        setIsLoading(true);
        const payload = {
          ids: [idConnection.id],
        };
        // check status code
        await deleteCorrelationSets(payload);
        entityEventsEmitter.emit(EntityEvents.DELETE, [idConnection]);
        setName('');
        setIdConnection(null);
        setIsLoading(false);
        notificationService.success(t('notifications.deletedSuccessfully'));
      }
    } catch (error) {
      notificationService.error(t('notifications.deletedFailed'));
    }
  };

  const getMenuItems = (): BigidMenuItemProps[] => {
    if (!idConnection) {
      return [];
    }

    const items: BigidMenuItemProps[] = [];

    // Edit correlation set
    if (isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name)) {
      items.push({
        label: t('actions.editCorrelationSet'),
        startIcon: BigidEditIcon,
        onClick: () => {
          handleOpenWizard(idConnection.name);
        },
        dataAid: generateDataAid(DATA_AID, ['edit-doc']),
      });
    }

    // Create new template
    if (true) {
      items.push({
        label: t('actions.createNewScanTemplate'),
        startIcon: BigidAddRowIcon,
        onClick: () => {
          console.log('Create new template');
        },
        dataAid: generateDataAid(DATA_AID, ['create-template']),
      });
    }

    // Duplicate
    if (isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name)) {
      items.push({
        label: t('actions.duplicate'),
        startIcon: BigidDuplicateIcon,
        onClick: handleDuplicate,
        dataAid: generateDataAid(DATA_AID, ['duplicate']),
      });
    }

    // Disable
    if (isIdConnectionEnabled(idConnection) && isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name)) {
      items.push({
        label: t('actions.disable'),
        startIcon: BigidDuplicateIcon,
        onClick: () => handleDisableEnable(true),
        dataAid: generateDataAid(DATA_AID, ['disable']),
      });
    }

    // Disable
    if (!isIdConnectionEnabled(idConnection) && isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name)) {
      items.push({
        label: t('actions.enable'),
        startIcon: BigidDuplicateIcon,
        onClick: () => handleDisableEnable(false),
        dataAid: generateDataAid(DATA_AID, ['enable']),
      });
    }

    // Delete
    if (isPermitted(CORRELATION_SET_PERMISSIONS.DELETE.name)) {
      items.push({
        label: t('actions.delete'),
        startIcon: BigidDuplicateIcon,
        onClick: handleDelete,
        dataAid: generateDataAid(DATA_AID, ['delete']),
      });
    }

    return items;
  };

  const items = useMemo(() => getMenuItems(), [idConnection]);
  return {
    isLoading,
    items,
  };
};
