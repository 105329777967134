import { httpService } from '../../services/httpService';
import { DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { createFetcherPayload } from './utils';
import { ExecutiveDashboardResponse, ProgressChartData, ProgressChartDataPoint, WidgetFetcherPayload } from './types';
import { notificationService } from '../../services/notificationService';
import { Aggregation } from '../DataExplorerSearchResults/types';
import { DashboardSubType, WidgetName } from './consts';
import { getFixedT } from './translations';
import { getDashboardFilterExpression, getDashboardDataFilterExpression } from './filterUtils';

export const isSystemWithoutDataSources = async () => {
  try {
    const {
      data: {
        data: { totalCount },
      },
    } = await httpService.fetch<{ data: DataSourcesResponse }>('ds-connections');
    return totalCount < 1;
  } catch (e) {
    console.error('could not fetch data sources, error:', e);
    return true;
  }
};

export const requestDashboardData = async (
  subType: DashboardSubType,
  widgetName: WidgetName,
  filters?: BigidAdvancedToolbarFilterUnion[],
): Promise<ExecutiveDashboardResponse> => {
  const filter = getDashboardDataFilterExpression(filters, widgetName);
  const payload = createFetcherPayload({ subType, widgetName, filter });
  return requestDashboardInfo(payload);
};

export const fetchDashboardFilterOptions = async (
  filterNameInEs: string,
  value = '',
  parseFunction: (dashboardData: Aggregation) => unknown,
  translateDisplayValues?: (parsedData: unknown) => unknown,
): Promise<any> => {
  try {
    const {
      data: { dashboardData },
    } = await requestDashboardFilters(
      DashboardSubType.DASHBOARD_FILTER,
      WidgetName.DASHBOARD_FILTER,
      filterNameInEs,
      value,
    );

    const parsedData = parseFunction(dashboardData as Aggregation);

    if (translateDisplayValues) {
      return translateDisplayValues(parsedData);
    }

    return parsedData;
  } catch (error) {
    const errMsg = `Error while loading data source filter for ${filterNameInEs}`;
    console.error(`${errMsg} Error: ${error}`);
    notificationService.error(errMsg);
  }
};

export const translateValues = (key: string) => {
  const translate = getFixedT(key);
  return (parsedData: unknown) => {
    if (Array.isArray(parsedData)) {
      return parsedData.map(item => ({
        ...item,
        displayValue: translate(item.displayValue),
      }));
    }
    return parsedData;
  };
};

const requestDashboardInfo = async (payload: WidgetFetcherPayload): Promise<ExecutiveDashboardResponse> => {
  try {
    const response = await httpService.post<ExecutiveDashboardResponse, WidgetFetcherPayload>(
      `executive-dashboard/widget`,
      payload,
    );
    return response?.data;
  } catch (e) {
    const errMsg = `Could not fetch dashboard data for dashboard ${payload.subType}`;
    console.error(errMsg);
    notificationService.error(errMsg);
    throw new Error(e.message);
  }
};

const requestDashboardFilters = async (
  subType: DashboardSubType,
  widgetName: WidgetName,
  filterName: string,
  value = '',
): Promise<ExecutiveDashboardResponse> => {
  const filter = getDashboardFilterExpression(widgetName, filterName, value);
  const payload = createFetcherPayload({ subType, widgetName, filter, gridFieldName: filterName });
  return requestDashboardInfo(payload);
};

export const getProgressChartListedValue = (data: ProgressChartDataPoint[]) => {
  const t = getFixedT('dataCoverageWidget');
  const listedItem = data.find(item => item.category === t('listed'));
  return listedItem?.value || 0;
};

export const getProgessChartScannedData = (data: ProgressChartDataPoint[]) => {
  const t = getFixedT('dataCoverageWidget');
  const scannedData = data.find(item => item.category === t('scanned'));
  return scannedData ? [scannedData] : [];
};

export const prepareProgressChartData = (progressChartData: ProgressChartData) => {
  if ((!progressChartData.total || progressChartData.total < 1) && progressChartData.data?.length) {
    const newTotalCount = getProgressChartListedValue(progressChartData.data);
    getProgessChartScannedData(progressChartData.data);
    return { total: newTotalCount, data: getProgessChartScannedData(progressChartData.data) };
  }

  return progressChartData;
};
