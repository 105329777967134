import { MutableRefObject } from 'react';
import { useValidator } from '../../../../hooks/useValidator';
import { useLocalTranslation } from '../translations';
import { getValidationRules } from '../config/validation';
import { isDataSourceExist } from '../../../../services/dataSourcesService';
import type { BigidFormStateAndHandlers } from '@bigid-ui/components';
import { notificationService } from '../../../../services/notificationService';

export type UseValidationOpts<TMisc> = {
  onSuccess?: (misc?: TMisc) => void;
  onError?: (field: string, message: string, misc?: TMisc) => void;
};

const DEFAULT_VALIDATION_RULES = ['noTrailingSpaces', 'name'];

export const useValidation = (
  sourceId: string,
  control: MutableRefObject<BigidFormStateAndHandlers>,
  opts?: UseValidationOpts<{ isAppliedFromDialog: boolean }>,
) => {
  const { t } = useLocalTranslation();
  const defaultRules = getValidationRules(t);
  const { validate } = useValidator([
    ...defaultRules,
    {
      id: 'dataSourceExists',
      message: t('duplicateDsNameError'),
      validate: async (value: string) => {
        try {
          const isNameInUse = await isDataSourceExist(value);
          return !isNameInUse;
        } catch (err) {
          notificationService.error('Problems with name verification, check logs for more information');
          return err?.response?.status === 500;
        }
      },
    },
  ]);

  const { onSuccess, onError } = opts ?? {};

  const handleValidateName = async (misc: { isAppliedFromDialog: boolean }) => {
    const { isAppliedFromDialog = false } = misc ?? {};
    const { name } = control.current?.getValues?.() ?? {};
    const shouldValidateDsExists = sourceId !== name;

    const { isValid, errors } = await validate(
      name,
      [...DEFAULT_VALIDATION_RULES, shouldValidateDsExists && 'dataSourceExists'].filter(Boolean),
    );
    const [{ message }] = errors ?? [{}];

    if (!isValid) {
      onError?.('name', message, { isAppliedFromDialog });
      return false;
    }

    onSuccess?.({ isAppliedFromDialog });
    return true;
  };

  const validationFunctionMapper: Record<string, (...args: any[]) => Promise<boolean>> = {
    name: handleValidateName,
  };

  const handleValidateField = (field: string, misc: { isAppliedFromDialog: boolean }) =>
    validationFunctionMapper[field]?.(misc);

  return { validate: handleValidateField };
};
