import {
  BigidCorrelationIllustration,
  BigidClusterIllustration,
  BigidReportsIllustration,
  BigidSettingsIllustration,
  BigidActionsIllustration,
  BigidAppsIllustration,
  EmptyStateIllustrationComponentProps,
} from '@bigid-ui/icons';
import React, { SVGProps } from 'react';
import { docsUrls } from '../../config/publicUrls';
import { getFixedT } from './translations';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';

const translationsViews = getFixedT('Views');
const translationsGeneral = getFixedT('General');
const tErrorMessages = getFixedT('Common.errorMessages');
const MIGRATION_FEATURE_KEY = 'migration_requested_feature';
const mtBaseBody = {
  migration_direction: 'MIGRATION_TO_SINGLE_TENANT',
};

export const MT_ENABLED_FF = 'MULTI_TENANT_MODE_ENABLED';

export enum FeatureFlagStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  CANT_VERIFY = 'cantVerify',
}

/**
 * Multi Tenant not supported views.
 */
export enum MtNotSupportedViews {
  CORRELATION = 'correlation',
  CLUSTER_ANALYSIS = 'clusterAnalysis',
  REPORTS = 'reports',
  CORRELATION_SETS = 'correlationSets',
  SCAN_WINDOWS = 'scanWindows',
  SAVED_QUERIES = 'savedQueries',
  CREDENTIALS = 'credentials',
  ACTION_CENTER_ACTIONS = 'actionCenterActions',
  DEFAULT = 'default',
  CERTIFICATES = 'certificates',
  APPLICATIONS_MANAGEMENT = 'applicationsManagement',
}

export interface MtMigrationEmptyStateInterface {
  id: string;
  title: string;
  content: string;
  illustration: (props: EmptyStateIllustrationComponentProps & React.SVGProps<SVGSVGElement>) => JSX.Element;
  buttonLink?: string;
  migrationButtonText?: string;
}

export const MtMigrationEmptyStates: MtMigrationEmptyStateInterface[] = [
  {
    id: MtNotSupportedViews.CORRELATION,
    title: translationsViews(`${MtNotSupportedViews.CORRELATION}.title`),
    content: translationsViews(`${MtNotSupportedViews.CORRELATION}.content`),
    illustration: BigidCorrelationIllustration,
    buttonLink: docsUrls.CORRELATION,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.CORRELATION}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.CLUSTER_ANALYSIS,
    title: translationsViews(`${MtNotSupportedViews.CLUSTER_ANALYSIS}.title`),
    content: translationsViews(`${MtNotSupportedViews.CLUSTER_ANALYSIS}.content`),
    illustration: BigidClusterIllustration,
    buttonLink: docsUrls.CLUSTER_ANALYSIS,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.CLUSTER_ANALYSIS}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.REPORTS,
    title: translationsViews(`${MtNotSupportedViews.REPORTS}.title`),
    content: translationsViews(`${MtNotSupportedViews.REPORTS}.content`),
    illustration: BigidReportsIllustration,
    buttonLink: docsUrls.REPORTS_AND_LOGS,
    migrationButtonText: `${translationsGeneral('view')}${translationsViews(`${MtNotSupportedViews.REPORTS}.title`)}`,
  },
  {
    id: MtNotSupportedViews.CORRELATION_SETS,
    title: translationsViews(`${MtNotSupportedViews.CORRELATION_SETS}.title`),
    content: translationsViews(`${MtNotSupportedViews.CORRELATION_SETS}.content`),
    illustration: BigidSettingsIllustration,
    buttonLink: docsUrls.CORRELATION_SETS,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.CORRELATION_SETS}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.SCAN_WINDOWS,
    title: translationsViews(`${MtNotSupportedViews.SCAN_WINDOWS}.title`),
    content: translationsViews(`${MtNotSupportedViews.SCAN_WINDOWS}.content`),
    illustration: BigidSettingsIllustration,
    buttonLink: docsUrls.SCAN_WINDOWS,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.SCAN_WINDOWS}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.SAVED_QUERIES,
    title: translationsViews(`${MtNotSupportedViews.SAVED_QUERIES}.title`),
    content: translationsViews(`${MtNotSupportedViews.SAVED_QUERIES}.content`),
    illustration: BigidSettingsIllustration,
    buttonLink: docsUrls.SAVED_QUERIES,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.SAVED_QUERIES}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.CREDENTIALS,
    title: translationsViews(`${MtNotSupportedViews.CREDENTIALS}.title`),
    content: translationsViews(`${MtNotSupportedViews.CREDENTIALS}.content`),
    illustration: BigidSettingsIllustration,
    buttonLink: docsUrls.CREDENTIALS,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.CREDENTIALS}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.ACTION_CENTER_ACTIONS,
    title: translationsViews(`${MtNotSupportedViews.ACTION_CENTER_ACTIONS}.title`),
    content: translationsViews(`${MtNotSupportedViews.ACTION_CENTER_ACTIONS}.content`),
    illustration: BigidActionsIllustration,
    buttonLink: docsUrls.ACTION_CENTER_ACTIONS,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.ACTION_CENTER_ACTIONS}.title`,
    )}`,
  },
  {
    id: MtNotSupportedViews.DEFAULT,
    title: translationsViews(`${MtNotSupportedViews.DEFAULT}.title`),
    content: translationsViews(`${MtNotSupportedViews.DEFAULT}.content`),
    illustration: BigidSettingsIllustration,
  },
  {
    id: MtNotSupportedViews.CERTIFICATES,
    title: translationsViews(`${MtNotSupportedViews.CERTIFICATES}.title`),
    content: translationsViews(`${MtNotSupportedViews.CERTIFICATES}.content`),
    illustration: BigidSettingsIllustration,
    buttonLink: docsUrls.CERTIFICATES,
    migrationButtonText: `${translationsGeneral('add')}${translationsViews(
      `${MtNotSupportedViews.CERTIFICATES}.title`,
    )}`,
  },

  {
    id: MtNotSupportedViews.APPLICATIONS_MANAGEMENT,
    title: translationsViews(`${MtNotSupportedViews.APPLICATIONS_MANAGEMENT}.title`),
    content: translationsViews(`${MtNotSupportedViews.APPLICATIONS_MANAGEMENT}.content`),
    illustration: BigidAppsIllustration,
    buttonLink: docsUrls.APPLICATIONS_MANAGEMENT,
    migrationButtonText: `${translationsGeneral('activate')}${translationsViews(
      `${MtNotSupportedViews.APPLICATIONS_MANAGEMENT}.title`,
    )}`,
  },
];

export const startMtMigrationForFeature = async (id: MtNotSupportedViews) => {
  try {
    await httpService.post('tenant-service/tenants/migration', { ...mtBaseBody, [MIGRATION_FEATURE_KEY]: id });
    return true;
  } catch (e) {
    notificationService.error(tErrorMessages('startingMigrationForFeature', { message: e?.message || ' ' }));
    return false;
  }
};

export const isMtMigrationInProgress = async (id: MtNotSupportedViews) => {
  const {
    data: {
      data: { migration_requested_features },
    },
  } = await httpService.fetch<{ data: { migration_requested_features: string[] } }>(
    'tenant-service/tenants/migration/status',
  );
  return migration_requested_features?.some(featureName => featureName === id);
};

export const isMtFeatureFlagEnabledInConfigService = async (): Promise<FeatureFlagStatus> => {
  try {
    const {
      data: { preferences },
    } = await httpService.fetch('preferences');
    if (
      typeof preferences === 'object' &&
      (preferences[MT_ENABLED_FF] === 'true' || preferences[MT_ENABLED_FF] === true)
    ) {
      return FeatureFlagStatus.ENABLED;
    } else {
      return FeatureFlagStatus.DISABLED;
    }
  } catch (e) {
    notificationService.error(tErrorMessages('preferences', { message: e?.message || ' ' }));
    return FeatureFlagStatus.CANT_VERIFY;
  }
};
