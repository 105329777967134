import { useState } from 'react';
import { SearchMode } from '../types';
import { useDebounce } from '@bigid-ui/components';

interface UseSearchModeParams {
  initialMode: SearchMode;
  initialSearchValue: string;
  onModeChange: () => void;
}

export const useSearchMode = ({ initialMode, initialSearchValue, onModeChange }: UseSearchModeParams) => {
  const [mode, setMode] = useState<SearchMode>(initialMode);
  const [searchValue, setSearchValue] = useState<string>(initialSearchValue);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleModeChange = (newMode: SearchMode) => {
    setSearchValue('');
    setMode(newMode);
    onModeChange();
  };

  const handleInputChange = (value: string) => setSearchValue(value);

  const clearInput = () => setSearchValue('');

  return {
    mode,
    debouncedSearchValue,
    searchValue,
    handleModeChange,
    handleInputChange,
    clearInput,
  };
};
