import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbarProps,
  FetchDataFunction,
} from '@bigid-ui/grid';
import {
  BigidFieldFilterOperator,
  BigidFilterOptionType,
  BigidFilterType,
  BigidFlexibleListCardActionType,
  BigidFlexibleListCardArea,
  BigidFlexibleListCardData,
  BigidFlexibleListCardProps,
} from '@bigid-ui/components';
import {
  CORRELATION_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  GENERAL_SETTINGS_PERMISSIONS,
  REVIEW_FINDINGS_PERMISSIONS,
} from '@bigid/permissions';
import { v4 as uuid } from 'uuid';
import {
  CuratedAttribute,
  getCuratedAttributes,
  CuratedAttributeType,
  removeCuratedAttribute,
  CuratedAttributeRemovalPayload,
  CuratedAttributeKeys,
  getAttributeCategories,
  GetCuratedAttributesPayload,
  StatusEvent,
  SavedFilter,
  setUserPreferences,
  AttributesFiltersNames,
  getUserPreferences,
  PreviewJobRequest,
  triggerGlobalPreview,
} from '../curationService';
import { CuratedAttributesProps } from './CuratedAttributes';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { CuratedAttributeContent } from './CuratedAttributeContent';
import { omit, startCase } from 'lodash';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { showAssignCategoryDialog } from './actions/AssignCategoryDialog/categoryAssignDialogService';
import { useLocalTranslation } from '../translations';
import { showAssignFriendlyNameDialog } from './actions/AssignFriendlyNameDialog/assignFriendlyNameDialogService';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { isPermitted } from '../../../services/userPermissionsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CurationEvents, trackEventCurationView } from '../curationEventTrackerUtils';
import {
  CurationStatusEvents,
  curationStatusEventsEmitter,
  getSelectedFilterOptions,
  getFilterSelectedValues,
  getFilterFromToolBar,
  getFilterFromUserPreference,
  isCTLightPreviewEnabled,
} from '../curationUtils';
import { CuratedAttributeAccuracyOperands } from './CuratedAttributeAccuracyOperands';
import { generateDataAid } from '@bigid-ui/utils';
import { getAttributeTypeAvatar } from './CuratedAttributesUtils';
import { CuratedAttributeGridForeignElement } from './CuratedAttributeGridForeignElement';
import { CurationStageId } from '../useCurationState';

export type CuratedAttributesGridRecord = BigidGridRow & CuratedAttribute;

export type AttributesGridToolbarFilterConfig =
  BigidGridWithToolbarProps<CuratedAttributesGridRecord>['filterToolbarConfig'];

export type UseCuratedDataSourcesConfigState = {
  isReady: boolean;
  gridConfig: BigidGridWithToolbarProps<CuratedAttributesGridRecord>;
};

export type UseCuratedAttributesConfigProps = CuratedAttributesProps;

const searchFilterKeys: CuratedAttributeKeys = ['attributeName'];

export const useCuratedAttributesConfig = ({
  currentCuratedDataSource,
  onProceedToFieldsReview,
  onCurationDefaultInitialStageSelect,
  curationStatus,
  curatedEntityName,
}: UseCuratedAttributesConfigProps): UseCuratedDataSourcesConfigState => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const translation = 'CuratedAttributes.Config';
  const { t } = useLocalTranslation(translation);
  const gridId = useMemo(() => `CuratedAttributes-${uuid()}`, []);
  const [filterToolbarConfig, setFilterToolbarConfig] = useState<AttributesGridToolbarFilterConfig>();

  const { fetchData, columns, onRowClick } = useMemo(() => {
    const fetchData: FetchDataFunction<CuratedAttributesGridRecord> = async (
      queryComponents: BigidGridQueryComponents,
    ) => {
      try {
        if (!currentCuratedDataSource?.source) {
          const searchBarFromToolBar = getFilterFromToolBar(queryComponents?.filter, AttributesFiltersNames.SEARCH);
          const attributeTypeFilterFromToolBar = getFilterFromToolBar(
            queryComponents?.filter,
            AttributesFiltersNames.ATTRIBUTE_TYPE,
          );
          const categoryFilterFromToolBar = getFilterFromToolBar(
            queryComponents?.filter,
            AttributesFiltersNames.CATEGORIES,
          );

          const payloadForUserPreference: SavedFilter[] = [
            {
              filterName: AttributesFiltersNames.SEARCH,
              values: searchBarFromToolBar?.value ? [searchBarFromToolBar?.value as string] : [],
            },
            {
              filterName:
                (attributeTypeFilterFromToolBar?.field as AttributesFiltersNames) ??
                AttributesFiltersNames.ATTRIBUTE_TYPE,
              values: (attributeTypeFilterFromToolBar?.value as string[]) ?? [],
            },
            {
              filterName:
                (categoryFilterFromToolBar?.field as AttributesFiltersNames) ?? AttributesFiltersNames.CATEGORIES,
              values: (categoryFilterFromToolBar?.value as string[]) ?? [],
            },
          ];

          await setUserPreferences({ attributesFilters: payloadForUserPreference });
        }

        const query = queryService.getGridConfigQuery(omit(queryComponents, ['filter'])); //NOTE: a temporal patch since BE requires a filter to be a query string
        const payload: GetCuratedAttributesPayload = {
          query: `${query}&filter=${parseFieldFiltersToSearchQuery(
            queryComponents.filter,
            Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
          )}`,
        };

        const statusEventFilter = [...(queryComponents.filter ?? [])];
        if (currentCuratedDataSource?.source) {
          payload.sources = [currentCuratedDataSource.source];
          statusEventFilter.push({
            field: 'source',
            value: [encodeURIComponent(currentCuratedDataSource?.source)],
            operator: 'in',
          });
        }

        const filterQuery = parseFieldFiltersToSearchQuery(
          statusEventFilter,
          Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
        );

        const { data } = await getCuratedAttributes(payload);
        const { attributes, totalCount } = data;

        const statusEvent: StatusEvent = {
          id: 'attributesStatus',
          filter: filterQuery,
        };

        curationStatusEventsEmitter.emit(CurationStatusEvents.UPDATE_STATUS, statusEvent);

        return {
          totalCount,
          data: attributes.map(attribute => ({
            ...attribute,
            id: attribute.attributeName,
          })),
        };
      } catch ({ message }) {
        notificationService.error(t('fetchCuratedAttributeFailure'));
        console.error(`An error has occurred: ${message}`);

        return {
          totalCount: 0,
          data: [],
        };
      }
    };

    const columns: BigidGridColumn<CuratedAttributesGridRecord>[] = [
      {
        name: 'attributeName',
        title: 'attributeName',
        width: 'auto',
        getCellValue: data => {
          const { id, attributeName, displayName, attributeType, classifierType, approvedCount, rejectedCount } = data;

          const cardData: BigidFlexibleListCardData<CuratedAttributesGridRecord> = {
            id,
            title: attributeName,
            subTitleCustomTooltipProps: {
              followCursor: true,
              title: (
                <CuratedAttributeAccuracyOperands
                  dataAid={generateDataAid('CuratedAttributesAccuracy', [id, 'summary-accuracy-tooltip'])}
                  id={id}
                  approvedCount={approvedCount}
                  rejectedCount={rejectedCount}
                />
              ),
            },
            subTitle: displayName,
            originalData: data,
            avatar: getAttributeTypeAvatar(classifierType || attributeType),
          };

          return {
            card: {
              titleCustomWidth: 400,
              isCardSelectable: true,
              cardData,
              content: CuratedAttributeContent,
              disableActionsOnceSelected: true,
              forceContextMenu: true,
              config: {
                [BigidFlexibleListCardArea.ACTIONS]: {
                  hasDelimeter: true,
                },
              },
              actions: [
                {
                  label: t('removeFromCatalog'),
                  name: 'removeFromCatalog',
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_REMOVE_ATTRIBUTE);
                    try {
                      const { attributeName, displayName, attributeType } = originalData;

                      const isConfirmed = await showConfirmationDialog({
                        entityNameSingular: t('attributeRemoval'),
                        actionName: t('confirm'),
                        actionButtonName: t('confirm'),
                        customDescription: t('attributeRemovalConfirmation', { attributeName: displayName }),
                      });

                      if (isConfirmed) {
                        const payload: CuratedAttributeRemovalPayload = {
                          sources: [currentCuratedDataSource.source],
                          attributeName,
                          attributeType,
                        };
                        await removeCuratedAttribute(payload);
                        notificationService.success(t('attributeRemovalSuccess', { attributeName: displayName }));
                      }
                    } catch ({ message }) {
                      notificationService.error(t('attributeRemovalFailure', { attributeName: displayName }));
                      console.error(`An error has occurred: ${message}`);
                    }
                    return;
                  },
                  getIsShown: () => {
                    return (
                      isPermitted(REVIEW_FINDINGS_PERMISSIONS.REMOVE_FROM_CATALOG.name) &&
                      currentCuratedDataSource?.source
                    );
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('assignFriendName'),
                  name: 'assignFriendlyName',
                  getIsShown: () => isPermitted(CORRELATION_PERMISSIONS.MANAGE.name),
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_FRIENDLY_NAMES);
                    try {
                      await showAssignFriendlyNameDialog(originalData, gridId);
                    } catch ({ message }) {
                      notificationService.error(t('assignFriendlyNameFailure', { attributeName }));
                      console.error(`An error has occurred: ${message}`);
                    }
                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('assignCategory'),
                  name: 'assignCategory',
                  getIsShown: () => isPermitted(CORRELATION_PERMISSIONS.MANAGE.name),
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_CATEGORIES);
                    try {
                      await showAssignCategoryDialog(originalData, gridId);
                    } catch ({ message }) {
                      notificationService.error(t('assignCategoryFailure', { attributeName }));
                      console.error(`An error has occurred: ${message}`);
                    }
                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('goToClassifier'),
                  name: 'goToClassifier',
                  onExecute: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_CLASSIFIER_PAGE);
                    $state.go(CONFIG.states.CLASSIFIERS, {
                      name: encodeURIComponent(originalData.attributeName),
                      type: encodeURIComponent(originalData.attributeType),
                    });

                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                  getIsShown: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>) => {
                    const { attributeType } = originalData;

                    return (
                      (attributeType === CuratedAttributeType.CLASSIFIER ||
                        attributeType === CuratedAttributeType.CLASSIFICATION_MD) &&
                      isPermitted(CLASSIFIERS_PERMISSIONS.ACCESS.name)
                    );
                  },
                },
                {
                  label: t('goToIgnoredList'),
                  name: 'goToIgnoredList',
                  onExecute: (): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_IGNORED_LIST);
                    $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'ignoredList', showBackButton: true });

                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                  getIsShown: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>) => {
                    const { attributeType } = originalData;

                    return (
                      attributeType === CuratedAttributeType.CORRELATION &&
                      isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_IGNORED_LISTS.name)
                    );
                  },
                },
              ],
            } as BigidFlexibleListCardProps,
          };
        },
        type: BigidGridColumnTypes.FLEXIBLE_CARD,
      },
    ];

    const onRowClick = async (row: CuratedAttributesGridRecord) => {
      trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_REVIEW);
      onProceedToFieldsReview(currentCuratedDataSource, row);

      if (isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && !isCTLightPreviewEnabled()) {
        try {
          const previewJobBody: PreviewJobRequest = {
            attributeName: row.attributeName,
            source: currentCuratedDataSource?.source,
          };

          await triggerGlobalPreview(previewJobBody);
        } catch ({ message }) {
          const notificationMessage = t('fetchingObjectsPreviews');

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);
        }
      }
      return;
    };

    return {
      fetchData,
      columns,
      onRowClick,
    };
  }, [currentCuratedDataSource?.source, gridId, onProceedToFieldsReview, t]);

  const fetchToolbarFilterInitialOptions = useCallback(async () => {
    const attributeTypeOptions: BigidFilterOptionType[] = [
      {
        label: CuratedAttributeType.CLASSIFIER.toString(),
        value: CuratedAttributeType.CLASSIFIER,
        isSelected: false,
      },
      {
        label: startCase(CuratedAttributeType.CLASSIFICATION_MD),
        value: CuratedAttributeType.CLASSIFICATION_MD,
        isSelected: false,
      },
      {
        label: CuratedAttributeType.CORRELATION,
        value: CuratedAttributeType.CORRELATION,
        isSelected: false,
      },
    ];

    const categoriesOptions = await getAttributeCategories(currentCuratedDataSource?.source);

    let updatedCategoriesOptions: BigidFilterOptionType[],
      updatedAttributeTypeOptions: BigidFilterOptionType[],
      updatedCategoriesSelectedValues: string[] = [],
      updatedAttributeTypeSelectedValues: string[] = [],
      searchBarFromUserPreference: SavedFilter;

    if (!currentCuratedDataSource?.source) {
      const userPreference = await getUserPreferences();
      const savedFiltersFromUserPreference = userPreference?.attributesFilters;

      searchBarFromUserPreference = getFilterFromUserPreference(
        savedFiltersFromUserPreference,
        AttributesFiltersNames.SEARCH,
      );

      const attributeTypeFilterFromUserPreference = getFilterFromUserPreference(
        savedFiltersFromUserPreference,
        AttributesFiltersNames.ATTRIBUTE_TYPE,
      );

      const categoryFilterFromUserPreference = getFilterFromUserPreference(
        savedFiltersFromUserPreference,
        AttributesFiltersNames.CATEGORIES,
      );

      savedFiltersFromUserPreference?.find(filter => filter?.filterName === AttributesFiltersNames.SEARCH);

      if (attributeTypeFilterFromUserPreference) {
        updatedAttributeTypeOptions = getSelectedFilterOptions(
          attributeTypeOptions,
          attributeTypeFilterFromUserPreference,
        );
        updatedAttributeTypeSelectedValues = getFilterSelectedValues(updatedAttributeTypeOptions);
      }

      if (categoryFilterFromUserPreference) {
        updatedCategoriesOptions = getSelectedFilterOptions(categoriesOptions, categoryFilterFromUserPreference);
        updatedCategoriesSelectedValues = getFilterSelectedValues(updatedCategoriesOptions);
      }
    }

    const filters: BigidFilterType[] = [
      {
        title: t('attributeType'),
        field: AttributesFiltersNames.ATTRIBUTE_TYPE,
        operator: 'in',
        value: updatedAttributeTypeSelectedValues,
        isSearchAsync: false,
        disabled: !updatedAttributeTypeSelectedValues.length,
        options: updatedAttributeTypeOptions || attributeTypeOptions,
      },
      {
        title: t('categoryName'),
        field: AttributesFiltersNames.CATEGORIES,
        operator: 'in',
        value: updatedCategoriesSelectedValues,
        isSearchAsync: true,
        disabled: !updatedCategoriesSelectedValues.length,
        options: updatedCategoriesOptions || categoriesOptions,
        listWidth: 400,
        loadSearchOptions: async (inputValue?: string) => {
          return await getAttributeCategories(currentCuratedDataSource?.source, inputValue);
        },
      },
    ];

    setFilterToolbarConfig({
      filters,
      searchConfig: {
        searchFilterKeys,
        initialValue: searchBarFromUserPreference?.values[0] ?? '',
        operator: 'equal' as BigidFieldFilterOperator,
      },
    });
    setIsReady(true);
  }, [t, currentCuratedDataSource?.source]);

  useEffect(() => {
    fetchToolbarFilterInitialOptions();
  }, [fetchToolbarFilterInitialOptions]);

  const handleDefaultViewSwitcherSelect = (
    stageId: CurationStageId.CURATED_SOURCES | CurationStageId.CURATED_ATTRIBUTES,
  ): void => {
    onCurationDefaultInitialStageSelect(stageId);
  };

  return {
    isReady,
    gridConfig: {
      gridId,
      entityName: 'attributes',
      showSortingControls: false,
      showFilteringControls: false,
      hideColumnChooser: true,
      cardListMode: true,
      filterToolbarConfig,
      fetchData,
      columns,
      onRowClick,
      defaultSorting: [{ field: 'normalizedDisplayName', order: 'asc' }],
      foreignElement: (
        <CuratedAttributeGridForeignElement
          curationStatus={curationStatus}
          curatedEntityName={curatedEntityName}
          translation={translation}
          onSelect={handleDefaultViewSwitcherSelect}
          stageId={CurationStageId.CURATED_ATTRIBUTES}
          hasSource={Boolean(currentCuratedDataSource?.source)}
        />
      ),
      foreignElementPlacement: 'right',
    },
  };
};
