import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { styled } from '@mui/material';
import {
  BigidBody1,
  BigidDialog,
  BigidHeading3,
  BigidInlineNotification,
  BigidLoader,
  BigidSearch,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidAddIcon, BigidScanIllustration } from '@bigid-ui/icons';
import { isEqual } from 'lodash';
import {
  editServiceConfiguration,
  getServicesConfiguration,
} from '../../../AdvancedTools/ServicesConfiguration/servicesConfigurationService';
import { ServiceConfigurationsModel } from '../../../AdvancedTools/ServicesConfiguration/ServicesConfiguration';
import { DomainItem, DomainItemData } from './DomainItem';
import { $state, $transitions } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { useLocalTranslation } from '../../translations';
import { $q } from 'ngimport';
import { useOnCloseHandler } from '../../../DataSources/DataSourceConfiguration/hooks/useOnCloseHandler';
import { generateDataAid } from '@bigid-ui/utils';

const DomainsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  height: '100%',
});

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: '8px',
});

const MainContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  background: theme.vars.tokens.bigid.backgroundPrimary,
  padding: '24px',
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '6px',
  boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
  height: '100%',
}));

const AddDomainContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '606px',
});

const DomainsList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SavedChangesDialogContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
  padding: '0px 32px 24px 32px',
});

const SuccessDialogButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
});

const Title = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundPrimary,
  fontWeight: 600,
  lineHeight: '150%',
}));

const Description = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundTertiary,
  fontWeight: 400,
  lineHeight: '150%',
}));

const SavedChangesDialogBody = styled(BigidBody1)({
  textAlign: 'center',
});

const DOMAIN_LIST_CONFIGURATION_NAME = 'ACI_ORG_DOMAIN_LIST';

const dataAid = 'domains-settings-tab';

export const Domains: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [domainsConfiguration, setDomainsConfiguration] = useState<ServiceConfigurationsModel>();
  const [domains, setDomains] = useState<DomainItemData[]>([]);
  const [shouldDisableSaveButton, setShouldDisableSaveButton] = useState(true);
  const [shouldDisableCancelButton, setShouldDisableCancelButton] = useState(true);
  const [shouldDisableAddNewDomainButton, setShouldDisableAddNewDomainButton] = useState(false);
  const [isCancelChangesDialogOpen, setIsCancelChangesDialogOpen] = useState(false);
  const [isChangesSavedDialogOpen, setIsChangesSavedDialogOpen] = useState(false);
  const [showInlineNotification, setShowInlineNotification] = useState(false);
  const [domainSearchValue, setDomainSearchValue] = useState('');
  const [activeDomainIndex, setActiveDomainIndex] = useState(-1);
  const [domainsTabKey, setDomainsTabKey] = useState(uuid());
  const domainsRef = useRef<DomainItemData[]>([]);
  const { t } = useLocalTranslation('DomainSettings');
  const onCloseDSConfig = useOnCloseHandler('Domains settings', true);

  const fetchDomains = async () => {
    setIsLoading(true);
    const configurations = await getServicesConfiguration();
    const domainsConfiguration = configurations.find(config => config.name === DOMAIN_LIST_CONFIGURATION_NAME);
    setDomainsConfiguration(domainsConfiguration);
    if (domainsConfiguration?.value && domainsConfiguration?.value.trim()) {
      const newDomains = domainsConfiguration.value
        .trim()
        .split(',')
        .map((domainValue: string) => {
          return { value: domainValue.trim(), isError: false };
        });
      setDomains(newDomains);
      domainsRef.current = newDomains;
    } else {
      domainsRef.current = [{ value: '', isError: false }];
      setDomains([{ value: '', isError: false }]);
      setShouldDisableAddNewDomainButton(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDomains();
  }, [domainsTabKey]);

  useEffect(() => {
    const findEmptyValue = domains.some(domain => !domain.value);
    const findError = domains.some(domain => domain.isError);
    setShouldDisableSaveButton(isEqual(domains, domainsRef.current) || findError);
    setShouldDisableCancelButton(isEqual(domains, domainsRef.current));
    setShouldDisableAddNewDomainButton(findEmptyValue || findError);
  }, [domains]);

  const onSaveChangesButtonClick = useCallback(
    async (shouldHideBanner?: boolean): Promise<boolean> => {
      const isErrorFound = domains.some(domain => domain.isError);
      if (isErrorFound) {
        return false;
      }
      setIsLoading(true);
      setShowInlineNotification(false);
      setShouldDisableAddNewDomainButton(true);
      setShouldDisableSaveButton(true);
      setShouldDisableCancelButton(true);
      const nonEmptyDomains = domains.filter((domain: DomainItemData) => domain.value);
      const newValue = nonEmptyDomains.map((domain: DomainItemData) => domain.value).join(',');
      const successMessage = await editServiceConfiguration(domainsConfiguration, newValue);
      if (successMessage && !shouldHideBanner) {
        setActiveDomainIndex(-1);
        domainsRef.current = nonEmptyDomains;
        setDomains(nonEmptyDomains);
        setIsChangesSavedDialogOpen(true);
      } else {
        setShouldDisableAddNewDomainButton(false);
        setShouldDisableSaveButton(false);
        setShouldDisableCancelButton(false);
      }
      setIsLoading(false);
      return true;
    },
    [domains, domainsConfiguration],
  );

  useEffect(() => {
    const deregisterLeaveFormInterceptor = $transitions.onBefore({}, transition => {
      return new $q(resolve => {
        let isSaving = false;
        if (!isEqual(domains, domainsRef.current) && transition.params()?.abortDisabled !== 'true') {
          const isErrorFound = domains.some(domain => domain.isError);
          onCloseDSConfig({
            isError: isErrorFound,
            onSave: () => {
              isSaving = true;
              onSaveChangesButtonClick(true)
                .then(result => {
                  resolve(result);
                })
                .catch(() => {
                  resolve(false);
                });
            },
            onNotSave: () => {
              resolve();
            },
            onClose: () => {
              !isSaving && resolve(false);
            },
          });
        } else {
          resolve();
        }
      }) as Promise<boolean>;
    });

    return () => {
      deregisterLeaveFormInterceptor();
    };
  }, [domains, onCloseDSConfig, onSaveChangesButtonClick]);

  const addNewDomain = () => {
    setActiveDomainIndex(0);
    setDomains([{ value: '', isError: false }, ...domains]);
  };

  const addNewDomainItemOnEnterPress = () => {
    const findEmptyValue = domains.some(domain => !domain.value);
    const findError = domains.some(domain => domain.isError);
    if (!findEmptyValue && !findError) {
      addNewDomain();
    }
  };

  const removeDomain = (index: number) => {
    const tempDomains = [...domains];
    tempDomains.splice(index, 1);
    setDomains(tempDomains);
  };

  const validateDomain = (domain: string) => {
    if (domain.length >= 254) {
      return {
        domain,
        isValid: false,
        error: t('Errors.totalDomainLengthError'),
      };
    }
    const labels = domain.split('.');
    if (labels.length > 1) {
      for (const label of labels) {
        if (label.length > 63) {
          return {
            domain,
            isValid: false,
            error: t('Errors.subDomainLengthError'),
          };
        }
        // Check for consecutive hyphens (except in SMB format)
        if (!label.startsWith('S-') && label.includes('--')) {
          return {
            domain,
            isValid: false,
            error: t('Errors.consecutiveHypensError'),
          };
        }
        // Special case for SMB domains starting with S-
        if (label.startsWith('S-')) {
          if (!/^S-[0-9-]+$/.test(label)) {
            return {
              domain,
              isValid: false,
              error: t('Errors.invalidSmbFormat'),
            };
          }
          continue; // Skip regular label validation for SMB format
        }
        if (!/^[\p{L}\p{N}](?:[\p{L}\p{N}-]*[\p{L}\p{N}])?$/u.test(label)) {
          return {
            domain,
            isValid: false,
            error: t('Errors.generalRegexError'),
          };
        }
      }
    } else if (!/^[\p{L}\p{N}](?:[\p{L}\p{N}-]*[\p{L}\p{N}])?$/u.test(domain)) {
      return {
        domain,
        isValid: false,
        error: t('Errors.generalRegexError'),
      };
    }
    return {
      domain,
      isValid: true,
    };
  };

  const onDomainItemChange = (value: string, domainIndex: number) => {
    const tempDomains = [...domains];
    if (value) {
      const { isValid, error } = validateDomain(value);
      tempDomains[domainIndex] = { value, isError: !isValid, errorMessage: error };
    } else {
      tempDomains[domainIndex] = { value, isError: false };
    }
    setActiveDomainIndex(domainIndex);
    setDomains(tempDomains);
  };

  return (
    <DomainsContainer key={domainsTabKey} data-aid={generateDataAid(dataAid, ['container'])}>
      <HeaderContainer>
        <BigidSearch
          placeholder={t('Common.searchDomain')}
          size="medium"
          onSubmit={(searchText: string) => setDomainSearchValue(searchText)}
        />
        <ButtonsWrapper>
          <TertiaryButton
            size="medium"
            text={t('Common.discardChanges')}
            disabled={shouldDisableCancelButton}
            onClick={() => setIsCancelChangesDialogOpen(true)}
            dataAid={generateDataAid(dataAid, ['discard-changes-button'])}
          />
          <PrimaryButton
            size="medium"
            text={t('Common.save')}
            disabled={shouldDisableSaveButton}
            onClick={() => onSaveChangesButtonClick()}
            dataAid={generateDataAid(dataAid, ['save-changes-button'])}
          />
        </ButtonsWrapper>
      </HeaderContainer>
      <MainContent>
        {showInlineNotification && (
          <div>
            <BigidInlineNotification
              type="warning"
              open={showInlineNotification}
              text={[
                {
                  subText: t('Texts.saveBanner'),
                },
              ]}
              isClosable
              actionButton={{
                label: t('Common.goToScans'),
                onClick: () => $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS),
              }}
              dataAid={generateDataAid(dataAid, ['must-rescan-to-apply-changes-banner'])}
            />
          </div>
        )}
        <AddDomainContainer>
          <TextWrapper>
            <Title>{t('Texts.listOfDomains')}</Title>
            <Description>{t('Texts.domainsDescription')}</Description>
          </TextWrapper>
          <PrimaryButton
            text={t('Common.addDomain')}
            startIcon={<BigidAddIcon />}
            size="medium"
            disabled={shouldDisableAddNewDomainButton}
            onClick={addNewDomain}
            dataAid={generateDataAid(dataAid, ['add-domain-button'])}
          />
        </AddDomainContainer>
        <DomainsList>
          {domains
            .filter(domain => domain.value.includes(domainSearchValue))
            .map((domain, displayIndex) => {
              return (
                <DomainItem
                  key={`${domain.value}-${displayIndex}`}
                  originalIndex={domains.findIndex(OriginalDomain => domain.value === OriginalDomain.value)}
                  displayIndex={displayIndex}
                  onChange={onDomainItemChange}
                  onDeleteDomainClick={removeDomain}
                  value={domain.value}
                  showError={domain.isError}
                  errorMessage={domain.errorMessage}
                  shouldFocus={displayIndex === activeDomainIndex}
                  addNewDomainItemOnEnterPress={addNewDomainItemOnEnterPress}
                />
              );
            })}
        </DomainsList>
      </MainContent>
      {isCancelChangesDialogOpen && (
        <BigidDialog
          isOpen={isCancelChangesDialogOpen}
          title={t('Texts.cancelChangesDialogHeader')}
          onClose={() => setIsCancelChangesDialogOpen(false)}
          buttons={[
            {
              text: t('Common.close'),
              component: TertiaryButton,
              onClick: () => setIsCancelChangesDialogOpen(false),
              dataAid: generateDataAid(dataAid, ['discard-changes-dialog-close-button']),
            },
            {
              text: t('Common.discardChanges'),
              component: PrimaryButton,
              onClick: () => {
                setDomainsTabKey(uuid());
                setIsCancelChangesDialogOpen(false);
              },
              dataAid: generateDataAid(dataAid, ['discard-changes-dialog-confirm-button']),
            },
          ]}
        >
          <BigidBody1 variantMapping={{ body1: 'strong' }}>{t('Texts.cancelChangesDialogBody1')}</BigidBody1>
          <BigidBody1 variantMapping={{ body1: 'strong' }}>{t('Texts.cancelChangesDialogBody2')}</BigidBody1>
        </BigidDialog>
      )}
      {isChangesSavedDialogOpen && (
        <BigidDialog
          isOpen={isChangesSavedDialogOpen}
          title=""
          borderTop={false}
          borderBottom={false}
          onClose={() => setIsChangesSavedDialogOpen(false)}
        >
          <SavedChangesDialogContainer>
            <BigidScanIllustration />
            <BigidHeading3>{t('Texts.saveDialogHeader')}</BigidHeading3>
            <SavedChangesDialogBody variantMapping={{ body1: 'strong' }}>
              {t('Texts.saveDialogBody')}
            </SavedChangesDialogBody>
            <SuccessDialogButtonsWrapper>
              <PrimaryButton
                size="medium"
                onClick={() => {
                  $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
                }}
                dataAid={generateDataAid(dataAid, ['go-to-scans-button'])}
              >
                {t('Common.goToScans')}
              </PrimaryButton>
              <TertiaryButton
                size="medium"
                text={t('Common.close')}
                onClick={() => {
                  setShowInlineNotification(true);
                  setIsChangesSavedDialogOpen(false);
                }}
                dataAid={generateDataAid(dataAid, ['changes-saved-dialog-close-button'])}
              />
            </SuccessDialogButtonsWrapper>
          </SavedChangesDialogContainer>
        </BigidDialog>
      )}
      {isLoading && <BigidLoader />}
    </DomainsContainer>
  );
};
