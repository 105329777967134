import React, { FC } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { eaFeatureItems, EaProgramFeature } from '../eaProgramModalService';
import { useLocalTranslation } from '../translations';
import { EaFeatureItem } from './EaFeatureItem';

const EA_PROGRAM_FEATURES_SELECTION = 'EaProgramFeaturesSelectionSubView';

export interface EaFeaturesSelectionProps {
  onFeatureSelectionChange: (featureId: EaProgramFeature, isSelected: boolean) => void;
  selectedFeatures: EaProgramFeature[];
  onSelectAllFeatures: () => void;
}

export const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 0 16px 8px;
  font-size: 14px;
`;

const ItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 484px;
  margin-right: -8px;
  padding-right: 8px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.vars.palette.bigid.gray400};
  }
  & > div:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  }
`;

const FeaturesText = styled('div')`
  display: flex;
  justify-content: space-between;
  > span {
    padding-left: 6px;
    text-decoration: underline;
    cursor: pointer;
  }
  padding-bottom: 24px;
`;

const SelectAllItems = styled('div')`
  padding-left: 6px;
  text-decoration: underline;
  cursor: pointer;
`;

export const EaFeaturesSelection: FC<EaFeaturesSelectionProps> = ({
  onFeatureSelectionChange,
  onSelectAllFeatures,
  selectedFeatures,
}) => {
  const { t } = useLocalTranslation();

  return (
    <MainContainer data-aid={generateDataAid(EA_PROGRAM_FEATURES_SELECTION, ['main-container'])}>
      <FeaturesText>
        <div>{t('selectFeaturesText')}</div>
        <SelectAllItems onClick={onSelectAllFeatures}>{t('buttonLabels.selectAll')}</SelectAllItems>
      </FeaturesText>
      <ItemsContainer>
        {eaFeatureItems.map(item => (
          <EaFeatureItem
            onFeatureSelectionChange={onFeatureSelectionChange}
            isSelected={selectedFeatures.includes(item?.id)}
            key={item?.id}
            {...item}
          />
        ))}
      </ItemsContainer>
    </MainContainer>
  );
};
