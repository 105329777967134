import { ReactText } from 'react';
import { BigidFieldFilter, BigidSelectOption, objectToQueryString, BigidFilterOptionType } from '@bigid-ui/components';
import { httpService } from '../../services/httpService';
import { DateISO8601 } from '../../types/types';
import { getOptionsFromSystemUsers, getUsersQuery } from '../../utilities/systemUsersUtils';
import { systemUsersService } from '../../services/angularServices';
import { TagEntity } from '../TagsManagement/TagsManagementService';
import { notificationService } from '../../services/notificationService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { CONFIG } from '../../../config/common';
import { getFilterDataByField } from './curationUtils';
import { CurationGuidedTourStageId, CurationGuidedTourStageStatus, CurationStageId } from './useCurationState';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { getApplicationPreference } from '../../services/appPreferencesService';

export enum ScannerTypeGroup {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
  EMAIL = 'email',
}

export enum DetailedObjectType {
  STRUCTURED = 'STRUCTURED',
  STRUCTURED_FILE = 'STRUCTURED_FILE',
  PARTITIONED_TABLE = 'PARTITIONED_TABLE',
  UNSTRUCTURED = 'UNSTRUCTURED',
  APP = 'APP',
}

export const structuredObjectsTypes = [
  DetailedObjectType.STRUCTURED,
  DetailedObjectType.STRUCTURED_FILE,
  DetailedObjectType.PARTITIONED_TABLE,
];

export enum CuratedFieldStatus {
  UNCURATED = 'Uncurated',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum SamplingStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
  NEVER_SAMPLED = 'NEVER_SAMPLED',
}

export enum CuratedAttributeType {
  CLASSIFIER = 'Classification',
  CLASSIFICATION_MD = 'ClassificationMd',
  CORRELATION = 'IDSoR Attribute',
  DOC_CLASSIFICATION = 'ClassificationDoc',
  MANUAL = 'Manual',
}

export enum AddNoteModalType {
  ADD = 'add',
  EDIT = 'edit',
  BULK = 'bulk',
  REJECT = 'reject',
  BULK_REJECT = 'bulkReject',
}

export enum ClassifierType {
  DOC = 'DOC',
  NER = 'NER',
  MD = 'MD',
}

export type CurationStatus = {
  curatedCount: number;
  totalCount: number;
};

export enum CurationFieldsAsyncFilters {
  CONTAINER = 'container',
  FIELD_NAME = 'fieldName',
  OBJECT_NAME = 'objectName',
}

export type CurationStatusPerAttribute = CurationStatus & {
  source: string;
};

export type CurationStatusPerField = CurationStatus & {
  fieldName: string;
};

export type CurationStatusResponse<EntityStatus = CurationStatus> = {
  statusPerEntity: EntityStatus[];
  curationStatus: CurationStatus;
};

export type CuratedDataSource = {
  source: string;
  sourceType?: string;
  scannerTypeGroup?: ScannerTypeGroup;
  totalAttributes?: number;
  curatedAttributes?: number;
  totalFindings?: number;
  owner?: string;
  samplingStatus?: SamplingStatus;
  scanId?: string;
  parentScanId?: string;
  isSupported?: boolean;
  collaborator?: string;
  isSamplingStuck?: boolean;
};

export type CuratedObjectFields = {
  containers?: BigidSelectOption[];
  objectNames?: BigidSelectOption[];
  fieldNames?: BigidSelectOption[];
};

export interface CuratedAttributeFriendlyName {
  glossaryId: string;
  friendlyName: string;
  originalName: string;
  description: string;
}

export interface LegacyCategoryDetails {
  color: string;
  display_name: string;
  unique_name: string;
  description: string;
  glossary_id: string;
}

export interface CategoryDetails extends Omit<LegacyCategoryDetails, 'display_name' | 'unique_name' | 'glossary_id'> {
  displayName: string;
  uniqueName: string;
}

export type CuratedAttribute = {
  attributeName: string;
  displayName?: string;
  attributeType: CuratedAttributeType;
  classifierType?: ClassifierType;
  totalFields?: number;
  curatedFields?: number;
  rejectedCount?: number;
  approvedCount?: number;
  excludedValuesCount?: number;
  totalFindings?: number;
  isCompletelyCurated?: boolean;
  precision?: number;
  friendlyName?: CuratedAttributeFriendlyName;
  categories?: CategoryDetails[];
  scannerTypeGroup?: ScannerTypeGroup;
};

export type CuratedAttributeIdentifier = Pick<CuratedAttribute, 'attributeName' | 'attributeType'>;

export type CuratedAttributeKeys = Array<keyof CuratedAttribute>;

export type PreviewJobRequest = {
  attributeName: string;
  source?: string;
};

export type PreviewJobResponse = {
  msg: string;
  sources: string[];
  attributeName: string;
};

export type ObjectProperty = {
  name: string;
  value: string;
  type?: string;
};

export enum ConfidenceLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type Note = {
  text: string;
  createdBy: string;
  createdAt: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string;
};

export enum PreviewComplexity {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export type CuratedField = {
  id: ReactText;
  source: string;
  scanId?: string;
  fieldName: string;
  fullyQualifiedName: string;
  attributeName: string;
  reviewStatus: CuratedFieldStatus;
  curatedByUser: string;
  detailedObjectType: DetailedObjectType;
  objectName: string;
  container: string;
  confidenceLevel?: ConfidenceLevel;
  updatedConfidenceLevel?: ConfidenceLevel;
  estimatedConfidenceLevel?: ConfidenceLevel;
  confidenceValue?: number;
  attributeType: CuratedAttributeType;
  objectType: string;
  fieldType: string;
  totalFindings: number;
  previewComplexity?: PreviewComplexity;
  objectProperties?: ObjectProperty[];
  curatedAt: DateISO8601;
  sampledAt: DateISO8601;
  scannedAt: DateISO8601;
  tags?: TagEntity[];
  sample1: string;
  sample2: string;
  note: Note;
};

export type StatusId = 'attributesStatus' | 'fieldsStatus';

export type StatusEvent = {
  id: StatusId;
  filter: string;
};

export type FindingDetails = {
  findingId: string;
  findingValue: string;
  reviewStatus: CuratedFieldStatus;
  position: number;
  length: number;
  attributeName?: string;
  attributeType?: CuratedAttributeType;
  excludePatternId: string;
};

export type FindingCurateProps = {
  finding: FindingDetails;
  status: CuratedFieldStatus;
  fieldId: ReactText;
  matchType?: MatchType;
};

export type FieldValueChunk = {
  chunkValue: string;
  findingDetails: FindingDetails[];
};

export type FindingField = {
  fieldName: string;
  fieldValueChunks: FieldValueChunk[];
};

export type Finding = {
  fields: FindingField[];
};

export type AdditionalAttribute = {
  attributeId: string;
  attributeName: string;
  attributeType: CuratedAttributeType;
  findings: number;
};

export type AdditionalAttributeField = {
  fieldName: string;
  attributes: AdditionalAttribute[];
};

export type GetFindingsResponse = {
  findings: Finding[];
};

export type CurateFindingPayload = {
  curationFieldId: ReactText;
  value: string;
  matchType: MatchType;
};

export type DeleteCurateFindingPayload = {
  curationFieldId: ReactText;
  patternId: string;
};

export enum MatchType {
  EXACT = 'EXACT',
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

export type CurateFindingResponse = unknown;

export type GetCuratedDataSourcePayload = {
  scanId: string;
  query: string;
};

export type GetCuratedDataSourceResponse = {
  totalCount: number;
  curationStatus: CurationStatus;
  sources: CuratedDataSource[];
};

export type GetCuratedAttributesPayload = {
  sources?: string[];
  query: string;
};

export type GetCuratedAttributesResponse = {
  totalCount: number;
  curationStatus: CurationStatus;
  attributes: CuratedAttribute[];
};

export type GetCuratedFieldsPayload = {
  query: string;
};

export type GetCuratedFieldsResponse = {
  totalCount: number;
  curationStatus: CurationStatus;
  fields: CuratedField[];
};

export type CurateFieldPayload = {
  ids?: ReactText[];
  filter?: string;
  reviewStatus: CuratedFieldStatus;
};

export type PatchFieldNotesPayload = {
  note: string;
  ids?: string[];
  filter?: string;
};

export type DeleteFieldNotesPayload = {
  ids: string[];
};

export type CurateFieldResponse = {
  changedNumber: number;
  pristineNumber: number;
};

export type GetAttributesCurationStatusResponse = CurationStatusResponse<CurationStatusPerAttribute>;

export type GetFieldsCurationStatusResponse = CurationStatusResponse<CurationStatusPerField>;

export type CuratedAttributeRemovalPayload = {
  sources: string[];
  attributeName?: string;
  attributeType?: CuratedAttributeType;
};

export type CuratedAttributeRemovalResponse = unknown; //NOTE:  figure out with the backend later

export type GetAdditionalAttributesPayload = {
  fullyQualifiedName: CuratedField['fullyQualifiedName'];
  fieldName?: CuratedField['fieldName'];
  gridConfigQuery?: string;
};

export type GetAdditionalAttributesResponse = {
  fields: AdditionalAttributeField[];
};

export type CurationUserPreferences = {
  guidedTourStatus?: Record<CurationGuidedTourStageId, CurationGuidedTourStageStatus>;
  defaultInitialStageId?: CurationStageId;
  dataSourcesFilters?: SavedFilter[];
  attributesFilters?: SavedFilter[];
};

export type SavedFilter = {
  filterName?: DataSourcesFiltersNames | AttributesFiltersNames | '';
  values?: string[];
};

export enum DataSourcesFiltersNames {
  SEARCH = 'name',
  OWNER = 'owners_v2.id',
}

export enum AttributesFiltersNames {
  SEARCH = 'attributeName',
  ATTRIBUTE_TYPE = 'attributeType',
  CATEGORIES = 'categories.unique_name',
}

export function getCuratedDataSources({ query, scanId }: GetCuratedDataSourcePayload) {
  if (!scanId) {
    return httpService
      .fetch<{ data: GetCuratedDataSourceResponse }>(`data-catalog/results-tuning/sources-all?${query}`)
      .then(({ data }) => data);
  } else {
    const scanIdEncoded = encodeURIComponent(scanId);
    return httpService
      .fetch<{ data: GetCuratedDataSourceResponse }>(`data-catalog/results-tuning/sources/${scanIdEncoded}?${query}`)
      .then(({ data }) => data);
  }
}

export const resampleCurationDs = (scanId: string) => {
  return httpService.post(`data-catalog/results-tuning/sampling/async`, {
    scanId: scanId,
  });
};

export function getCuratedAttributes({ sources, query }: GetCuratedAttributesPayload) {
  const queryComputed = sources ? `${query}&source=${encodeURIComponent(sources?.join(','))}` : query;

  return httpService
    .fetch<{ data: GetCuratedAttributesResponse }>(`data-catalog/results-tuning/attributes?${queryComputed}`)
    .then(({ data }) => data);
}

export function triggerGlobalPreview(body: PreviewJobRequest) {
  return httpService
    .post<{ data: PreviewJobResponse }, PreviewJobRequest>('data-catalog/scan-result-fetch-findings/preview/job', body)
    .then(({ data }) => data);
}

export function triggerPreviewAsync({ query }: GetCuratedFieldsPayload) {
  return httpService
    .post<{ data: PreviewJobResponse }, PreviewJobRequest>(
      `data-catalog/scan-result-fetch-findings/preview/async?${query}`,
    )
    .then(({ data }) => data);
}

export function getCuratedFields({ query }: GetCuratedFieldsPayload) {
  return httpService
    .fetch<{ data: GetCuratedFieldsResponse }>(`data-catalog/results-tuning/curation-fields?${query}`)
    .then(({ data }) => data);
}

export async function curateFieldsAsync(payload: CurateFieldPayload) {
  const { data } = await httpService.patch(`data-catalog/results-tuning/curation-fields/status/async`, payload);
  return data;
}

export function curateField(payload: CurateFieldPayload) {
  const refreshHeaders = {
    'catalog-refresh-option': 'refresh',
  };
  return httpService
    .patch<{ data: CurateFieldResponse }, Pick<CurateFieldPayload, 'reviewStatus'>>(
      `data-catalog/results-tuning/curation-fields/status`,
      payload,
      undefined,
      refreshHeaders,
    )
    .then(({ data }) => data);
}

export async function patchFieldNote(payload: PatchFieldNotesPayload) {
  return httpService.patch(`data-catalog/results-tuning/curation-fields/note`, payload).then(({ data }) => data);
}

export async function deleteFieldNote(payload: DeleteFieldNotesPayload) {
  return httpService.delete(`data-catalog/results-tuning/curation-fields/note`, payload).then(({ data }) => data);
}

export async function getSystemUsers(searchString?: string): Promise<BigidSelectOption[]> {
  try {
    const query = getUsersQuery({ searchString, maxUsers: 50 });
    const {
      data: { users },
    } = await systemUsersService.getAllSystemUsersByQuery(query);

    return getOptionsFromSystemUsers(users);
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the data owners.');
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}

export async function getGridFiltersData(
  filter: BigidFieldFilter[],
  fieldType?: CurationFieldsAsyncFilters,
  searchInput?: string,
) {
  try {
    let filterQuery;

    if (searchInput && fieldType) {
      const searchFilter: BigidFieldFilter = { field: fieldType, value: `/${searchInput}/i`, operator: 'equal' };

      filterQuery = parseFieldFiltersToSearchQuery(
        [...filter, searchFilter],
        Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
      );
    } else {
      filterQuery = parseFieldFiltersToSearchQuery(
        filter,
        Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
      );
    }

    const { data } = (
      await httpService.fetch(
        `data-catalog/results-tuning/curation-fields/grid/filters?filter=${encodeURIComponent(filterQuery)}`,
      )
    ).data;

    return data;
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the objects fields.');
    console.error(`An error has occurred: ${message}`);
    return;
  }
}

export async function getObjectsFields(
  filter: BigidFieldFilter[],
  fieldType?: CurationFieldsAsyncFilters,
  searchInput?: string,
): Promise<BigidSelectOption[]> {
  const data = await getGridFiltersData(filter, fieldType, searchInput);

  return getFilterDataByField(fieldType, data);
}

export async function getAttributeCategories(source: string, searchInput?: string): Promise<BigidFilterOptionType[]> {
  try {
    const query = source ? `?source=${encodeURIComponent(source)}` : '';

    let filterQuery = '';

    if (searchInput) {
      const filter: BigidFieldFilter = {
        field: 'categories.display_name',
        value: `/${searchInput}/i`,
        operator: 'equal',
      };
      const formattedFilter = parseFieldFiltersToSearchQuery(
        [filter],
        Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
      );
      filterQuery = source ? `&filter=${formattedFilter}` : `?filter=${formattedFilter}`;
    }

    const { data } = (
      await httpService.fetch(`data-catalog/results-tuning/attributes/categories${query}${filterQuery}`)
    ).data;

    return data.map((category: CategoryDetails) => ({
      label: category.displayName,
      value: category.uniqueName,
      isSelected: false,
    }));
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the categories.');
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}

export async function getSystemUsersByPermissions(source: string): Promise<BigidSelectOption[]> {
  try {
    const {
      data: {
        data: { users },
      },
    } = await httpService.fetch(`data-catalog/classifier-tuning/collaborator/list/${encodeURIComponent(source)}`);
    return getOptionsFromSystemUsers(users);
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the relevant users.');
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}

export function getFindings(curationFieldId: string) {
  return httpService
    .fetch<{ data: GetFindingsResponse }>(`data-catalog/scan-result-fetch-findings/${curationFieldId}`)
    .then(({ data }) => data);
}

export function excludeFinding(payload: CurateFindingPayload) {
  return httpService.put<CurateFindingResponse>(
    `data-catalog/results-tuning/curation-fields/excluded-findings`,
    payload,
  );
}

export function unexcludeFinding(payload: DeleteCurateFindingPayload) {
  return httpService.delete<CurateFindingResponse>(
    `data-catalog/results-tuning/curation-fields/excluded-findings`,
    payload,
  );
}

export function getAttributeCurationStatus(filter = '') {
  return httpService
    .fetch<{ data: GetAttributesCurationStatusResponse }>(
      `data-catalog/results-tuning/curation-fields/status/attribute?filter=${filter}`,
    )
    .then(({ data }) => data);
}

export async function getFieldsCurationStatus(filter = '') {
  return httpService
    .fetch<{ data: GetFieldsCurationStatusResponse }>(
      `data-catalog/results-tuning/curation-fields/status/field?filter=${filter}`,
    )
    .then(({ data }) => data);
}

export function removeCuratedAttribute(payload: CuratedAttributeRemovalPayload) {
  return httpService
    .delete<CuratedAttributeRemovalResponse, CuratedAttributeRemovalPayload>(
      `data-catalog/manual-fields/by-source`,
      payload,
    )
    .then(({ data }) => data);
}

export function getAdditionalAttributes(payload: GetAdditionalAttributesPayload, gridConfigQuery?: string) {
  const query = objectToQueryString(payload);

  return httpService
    .fetch<{ data: GetAdditionalAttributesResponse }>(
      `data-catalog/object-details/fields?${query}&groupByField=true${gridConfigQuery ? '&' + gridConfigQuery : ''}`,
    )
    .then(({ data }) => data);
}

export async function getUserPreferences() {
  try {
    const preference = await userPreferencesService.get<CurationUserPreferences>(CONFIG.states.CURATION);
    return preference?.data || {};
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return {};
  }
}

export async function setUserPreferences(newPreferences: Partial<CurationUserPreferences>) {
  try {
    const preferences = await userPreferencesService.get<CurationUserPreferences>(CONFIG.states.CURATION);
    if (preferences) {
      await userPreferencesService.update({
        preference: CONFIG.states.CURATION,
        data: {
          ...preferences.data,
          ...newPreferences,
        },
      });
    } else {
      await userPreferencesService.add({
        preference: CONFIG.states.CURATION,
        data: newPreferences,
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
  }
}
