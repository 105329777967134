import React, { FC, useMemo } from 'react';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidWidgetContainer,
  BigidWidgetListItemProps,
  BigidColorsV2,
  BigidCategoryColorIndicator,
} from '@bigid-ui/components';
import { BigidLineChartV2DataItem } from '@bigid-ui/visualisation';

import { useLocalTranslation } from '../translations';
import { Menu, WidgetListWithTitle } from './helpers/HelperComponents';
import { DonutLegendMarkerGrayWrapper, RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { WidgetProps, WidgetSubTypes } from '../PrivacyExecutiveDashboardTypes';
import { fetchWidgetData } from '../services/dashboardService';
import { useEmptyState } from './hooks/useEmptyState';

const CATEGORY_COLOR_INDICATOR_COLORS = [
  BigidColorsV2.purple[500],
  BigidColorsV2.magenta[300],
  BigidColorsV2.cyan[300],
  BigidColorsV2.blue[300],
  BigidColorsV2.yellow[300],
];

interface DataRiskTypesApiData {
  totalCount: number;
  riskCategoriesCount: {
    category: string;
    openCases: number;
    percentage: number;
  }[];
}

export const DataRiskTypesWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading, error, fetchData } = useFetchWidgetData(fetchDataRiskTypesWidgetData);
  const emptyStateConfig = useEmptyState(data, error, fetchData);
  const widgetListItems: BigidWidgetListItemProps[] = useMemo(() => {
    return (data || []).slice(0, 5).map((item, i) => ({
      description: item.category,
      descriptionIcon: <BigidCategoryColorIndicator color={CATEGORY_COLOR_INDICATOR_COLORS[i]} />,
      value: (
        <span>
          {item.openCases}
          <DonutLegendMarkerGrayWrapper>{` (${Math.round(item.percentage as number)}%)`}</DonutLegendMarkerGrayWrapper>
        </span>
      ),
    }));
  }, [data]);

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <WidgetListWithTitle
            items={widgetListItems}
            headerTitleOne={t('dataRiskTypes.headerType')}
            headerTitleTwo={t('dataRiskTypes.headerOpenCases')}
          />
        }
        titleConfig={{ title }}
        isLoading={isLoading}
        emptyStateConfig={emptyStateConfig}
        actionsSlot={<Menu menuItems={menuItems} />}
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskTypesWidgetData = async (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<BigidLineChartV2DataItem[]> => {
  const widgetData: DataRiskTypesApiData = await fetchWidgetData({
    subType: WidgetSubTypes.DATA_RISK_CATEGORY,
    filter: activeFilters,
  });
  return widgetData.riskCategoriesCount;
};
