import React, { FC, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../translations';
import { Overview } from './Overview/Overview';
import { generateDataAid } from '@bigid-ui/utils';
import { SidePanelMetadata } from './SidePanelMetadata';
import { CatalogSearchGridRow } from '../../../../types';
import { Preview } from './Preview/Preview';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import {
  getIsScannerTypeFeatureSupported,
  ScannerTypeFeature,
} from '../../../../../../services/scannerTypesSupportedFeaturesService';
import { DataExplorerColumns } from './DataExplorerColumns/DataExplorerColumns';
import { DataExplorerAttributes } from './Attributes/DataExplorerAttributes';
import { useGetObjectDetails } from './hooks/useGetObjectDetails';
import { DataCatalogRecordScannerTypeGroup } from '../../../../../DataCatalog/DataCatalogService';

export interface CatalogSidePanelProps {
  dataAid?: string;
  selectedItem: CatalogSearchGridRow;
  highlightValue?: string;
}

const Root = styled('div')`
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  flex-direction: column;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 16px;
`;

export const CatalogSidePanel: FC<CatalogSidePanelProps> = ({
  dataAid = 'DataSourceSidePanel',
  selectedItem,
  highlightValue,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { t } = useLocalTranslation('sidePanel');

  const { data, id } = selectedItem;
  const { datasource } = data;
  const { scannerType } = datasource;

  const {
    data: {
      data: { scanner_type_group },
    },
  } = useGetObjectDetails(id);

  const { isColumnsTabAvailable, isDataPreviewDisabled, isPermittedToPreview } = useMemo(
    () => ({
      isPermittedToPreview: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
      isDataPreviewDisabled: getApplicationPreference('DATA_PREVIEW_DISABLED'),
      isColumnsTabAvailable: scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED,
    }),
    [scanner_type_group],
  );

  const isPreviewEnabled =
    isPermittedToPreview &&
    !isDataPreviewDisabled &&
    getIsScannerTypeFeatureSupported(scannerType, ScannerTypeFeature.HAS_PREVIEW);

  const tabs: BigidTabsItem[] = [
    { label: t('tabs.overview') },
    ...(isPreviewEnabled ? [{ label: t('tabs.preview') }] : []),
    { label: t('tabs.attributes') },
    ...(isColumnsTabAvailable ? [{ label: t('tabs.columns') }] : []),
  ];

  const tabIndexToContentMap = [
    Overview,
    ...(isPreviewEnabled ? [Preview] : []),
    DataExplorerAttributes,
    ...(isColumnsTabAvailable ? [DataExplorerColumns] : []),
  ];

  const TabContent = tabIndexToContentMap[selectedTabIndex];

  return (
    <Root data-aid={generateDataAid(dataAid, ['root'])}>
      <Header>
        <SidePanelMetadata fullyQualifiedName={selectedItem.id} />
        <BigidTabs
          tabs={tabs}
          showBorderBottom
          selectedIndex={selectedTabIndex}
          onChange={setSelectedTabIndex}
        ></BigidTabs>
      </Header>
      <TabContent
        selectedItem={selectedItem}
        highlightValue={highlightValue}
        id={id}
        key={selectedTabIndex}
        datasource={datasource}
        isDataPreviewDisabled={isDataPreviewDisabled}
      />
    </Root>
  );
};
