import { DataSourceTestConnectionStatusEnum } from '../hooks/useTestConnection';
import { BigidStatusBadgeProps, BigidStatusBadgeSize, BigidStatusBadgeType } from '@bigid-ui/components';
import { BigidTabActionButtonType, DataSourceTabAction } from '../types';
import type { ScanTemplate } from '../../../../views/Scans/ScanTemplates/scanTemplateTypes';
import type { UseLocalTranslationGetter } from '../../DataSourceConnectionModal/translations';

const testConnectionStatusToActionMapper = {
  [DataSourceTestConnectionStatusEnum.failed]: [DataSourceTabAction.TEST_CONNECTION, DataSourceTabAction.SAVE],
  [DataSourceTestConnectionStatusEnum.success]: [
    DataSourceTabAction.SAVE,
    DataSourceTabAction.SCAN,
    DataSourceTabAction.TEST_CONNECTION,
  ],
  [DataSourceTestConnectionStatusEnum.partialSuccess]: [
    DataSourceTabAction.SAVE,
    DataSourceTabAction.SCAN,
    DataSourceTabAction.TEST_CONNECTION,
  ],
  [DataSourceTestConnectionStatusEnum.notStarted]: [DataSourceTabAction.TEST_CONNECTION, DataSourceTabAction.SAVE],
};

const testStatusToStatusBadgeMapperFn = (t: UseLocalTranslationGetter) => ({
  [DataSourceTestConnectionStatusEnum.success]: {
    label: t('chips.success'),
    size: BigidStatusBadgeSize.SMALL,
    type: BigidStatusBadgeType.SUCCESS,
  },
  [DataSourceTestConnectionStatusEnum.partialSuccess]: {
    label: t('chips.success'),
    size: BigidStatusBadgeSize.SMALL,
    type: BigidStatusBadgeType.SUCCESS,
  },
  [DataSourceTestConnectionStatusEnum.failed]: {
    label: t('chips.failed'),
    size: BigidStatusBadgeSize.SMALL,
    type: BigidStatusBadgeType.ERROR,
  },
  [DataSourceTestConnectionStatusEnum.notStarted]: {
    label: t('chips.notStarted'),
    size: BigidStatusBadgeSize.SMALL,
    type: BigidStatusBadgeType.PENDING,
  },
});

export const mapTestConnectionStatusToWizardActions = (
  status: DataSourceTestConnectionStatusEnum,
  actionList: BigidTabActionButtonType[],
) => {
  const actions = testConnectionStatusToActionMapper[status];
  return actions.map(actionId => actionList.find(({ id }) => id === actionId)).filter(Boolean);
};

export const mapScanTemplatesToScanActions = (templates: ScanTemplate[]) => {
  return templates.map(({ _id: templateId, name }) => ({
    label: name,
    id: templateId,
  }));
};

export const mapTestStatusToStatusBadge = (
  status: DataSourceTestConnectionStatusEnum,
  t: UseLocalTranslationGetter,
): BigidStatusBadgeProps => testStatusToStatusBadgeMapperFn(t)[status];
