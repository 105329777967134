import {
  advancedToolbarFilterMinifier,
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterUnion,
  BigidLoader,
} from '@bigid-ui/components';
import { Box } from '@mui/material';

import React, { MutableRefObject } from 'react';
import { ResultsEntityType } from '../../../../DataExplorerSearchResults/types';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { updateRecentlySearchedPreferences } from '../../dataExplorerQuickSearchService';

export type FilterBuilderProps = {
  filters: BigidAdvancedToolbarFilterUnion[];
  entityName: ResultsEntityType;
  onSubmitRef: MutableRefObject<() => void>;
  isLoading: boolean;
  dataAid?: string;
  filterToQueryStringParserFn: (filters: BigidAdvancedToolbarFilterUnion[]) => string;
};
export const FilterBuilder = ({
  filters,
  entityName,
  onSubmitRef,
  isLoading,
  dataAid,
  filterToQueryStringParserFn,
}: FilterBuilderProps) => {
  const handleFiltersChange = (selectedFilters: BigidAdvancedToolbarFilterUnion[]) => {
    const overrideValues = selectedFilters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue);

    const filter = filterToQueryStringParserFn(selectedFilters);

    onSubmitRef.current = () => {
      $state.go(
        CONFIG.states.CATALOG_SEARCH_RESULTS,
        {
          activeTab: entityName,
          queryMode: 'searchText',
          selectedFiltersConfig: overrideValues,
        },
        {
          reload: true,
        },
      );
      updateRecentlySearchedPreferences({
        searchValue: filter,
        type: 'filterBuilder',
        created_at: Date.now(),
        entityName,
        savedFilters: overrideValues,
      });
    };
  };

  return (
    <Box
      sx={{
        padding: '16px',
        position: 'relative',
        flexGrow: 1,
      }}
    >
      {isLoading ? (
        <BigidLoader position="absolute" />
      ) : (
        <BigidAdvancedToolbarFilter
          onFiltersChange={handleFiltersChange}
          filters={filters}
          key={entityName}
          dataAid={generateDataAid(dataAid, ['advancedToolbar'])}
        />
      )}
    </Box>
  );
};
