import { httpService } from '../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { getEncodedArray, queryService } from '../../services/queryService';
import {
  DeletionValidationConfigurationContent,
  DsarDeletionValidationConfig,
  DsarDeletionValidationEntity,
  DsarScanResultEntity,
} from './DsarRequestsGrid/dsarRequestsTypes';
import {
  DsarObjects,
  DsarObjectsColumns,
  DsarObjectsColumnsAttributesUpdateModel,
  DsarObjectsColumnsUpdateModel,
  DsarObjectsUpdateModel,
  DsarManualRecords,
  ExcludeConnectionsOptions,
  SarProfile,
  IncludedDataSources,
} from './SarProfileSettings/ProfileSettingsTypes';
import { FilesRetrievalFormValues } from './FilesRetrieval/Types';
import { BigidFieldFilter, BigidFilter } from '@bigid-ui/components';
import { CountryEntity } from '../ApplicationSetup/types';
import { DsarDictionary } from './DsarTypes';
export type DsarScanState = 'Completed' | 'Started';

export async function getDsarResults(state: DsarScanState, queryComponents: BigidGridQueryComponents) {
  const url = `sar/scans?state=${state}&${queryService.getGridConfigQuery(queryComponents)}`;
  const {
    data: { scans, total },
  } = await httpService.fetch<{
    scans: DsarScanResultEntity[];
    total: number;
  }>(url);

  return {
    results: scans,
    total,
  };
}

export function getDsarFullReport(requestId: string, format: 'csv' | 'json' = 'csv', parsePipedValues: boolean) {
  const parse = parsePipedValues ? 'true' : 'false';
  console.log(`parse piped values=${parse}`);
  return httpService.downloadFile(`sar/reports/file-download/${requestId}`, { format, parse });
}

export function getDsarShortReport(requestId: string, format: 'csv' | 'json' = 'csv') {
  return httpService.downloadFile(`sar/reports/file-download/${requestId}/short-report`, { format });
}

export async function getProfiles() {
  const {
    data: { profiles },
  } = await httpService.fetch<{ profiles: SarProfile[] }>('sar/profiles');
  return profiles;
}

export async function getProfileById(
  id: string,
  bigidGridQuery: BigidGridQueryComponents,
  {
    excludeDsConnections = false,
    excludeEsConnections = false,
    exposeSyncData: shouldExposeSyncData,
  }: ExcludeConnectionsOptions = {},
): Promise<SarProfile> {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const excludeDsFilter = excludeDsConnections ? `&excludeDsConnections=true` : '';
  const excludeEsFilter = excludeEsConnections ? `&excludeEsConnections=true` : '';
  const exposeSyncData = shouldExposeSyncData ? `&exposeSyncData=true` : '';
  const { data } = await httpService.fetch<SarProfile>(
    `sar/profiles/${encodeURIComponent(id)}?${gridConfigQuery}${excludeEsFilter}${excludeDsFilter}${exposeSyncData}`,
  );
  return data;
}

export async function deleteDsar(requestId: string) {
  const { data } = await httpService.delete(`sar/reports/${requestId}`);
  return data;
}

export async function stopDsar(requestId: string) {
  const { data } = await httpService.put(`scans/${requestId}/requestStop`);
  return data;
}

export async function pauseDsar(requestId: string, subScanIds?: string[]) {
  const { data } = await httpService.post<{ data: { pausedPartsNumber: number } }>(`sar/reports/${requestId}/pause`, {
    subScanIds,
  });
  return data;
}

export async function resumeDsar(requestId: string, subScanIds?: string[]) {
  const { data } = await httpService.post<{ data: { resumePartsNumber: number } }>(`sar/reports/${requestId}/resume`, {
    subScanIds,
  });
  return data;
}

export async function getFileRetrieval(): Promise<FilesRetrievalFormValues> {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: FilesRetrievalFormValues }>('sar/files-retrieval-config');
    return data;
  } catch (err) {
    const errMsg = `an error in fetch the retrieval config results`;
    console.error(`${errMsg} ${err}`);
  }
}
export async function isFileRetrievalIsValid(): Promise<boolean> {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: { isValidConfiguration: boolean } }>('sar/files-retrieval-config/isValid');
    return data.isValidConfiguration;
  } catch (err) {
    const errMsg = `an error in fetch the retrieval config is valid results`;
    console.error(`${errMsg} ${err}`);
  }
}
export async function getRetrieveFiles(requestId: string) {
  try {
    httpService.post(`/sar/reports/${requestId}/retrieve-files`);
  } catch (err) {
    const errMsg = `an error in call to retrieve files`;
    console.error(`${errMsg} ${err}`);
  }
}

export async function getDeletionValidationStatus() {
  const { data } = await httpService.fetch<DsarDeletionValidationConfig>('sar/deletion-validations/config');
  return data;
}

export async function sendDeletionValidationStatus(configuration: Partial<DeletionValidationConfigurationContent>) {
  const { data } = await httpService.post('sar/deletion-validations/config', configuration);
  return data;
}

export async function getDeletionValidationResults(
  queryComponents: BigidGridQueryComponents,
): Promise<{ sarDeletionValidation: DsarDeletionValidationEntity[]; total: number }> {
  const url = `sar/deletion-validations?${queryService.getGridConfigQuery(queryComponents)}`;
  const {
    data: {
      data: { sarDeletionValidation, total },
    },
  } = await httpService.fetch(url);
  return { sarDeletionValidation, total };
}

export async function stopDeleteValidation(requestId: string) {
  const deleteResponse = await httpService.delete(`sar/deletion-validation/${requestId}`);
  console.log('deleteResponse: ', deleteResponse);
  return deleteResponse;
}

export async function getFields(profileId: string, bigidGridQuery: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`sar/profiles/${profileId}/fields?${gridConfigQuery}`);

  return data;
}

export async function getManualRecords(profileId: string, bigidGridQuery: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`sar/profiles/${profileId}/manual-records?${gridConfigQuery}`);

  return data;
}

export async function createManualRecord(profileId: string, payload: DsarManualRecords) {
  const {
    data: { data },
  } = await httpService.post(`sar/profiles/${profileId}/manual-records`, payload);

  return data;
}

export async function includeExcludeManualRecord(
  profileId: string,
  payload: {
    query: {
      filter?: BigidFieldFilter[];
    };
    data: Partial<Pick<DsarManualRecords, 'isIncluded'>>;
  },
) {
  const {
    data: { data },
  } = await httpService.patch(`sar/profiles/${profileId}/manual-records`, payload);

  return data;
}

export async function updateManualRecord(profileId: string, { id: recordId, ...payload }: DsarManualRecords) {
  const {
    data: { data },
  } = await httpService.put(`sar/profiles/${profileId}/manual-records/${recordId}`, payload);

  return data;
}

export async function deleteManualRecord(profileId: string, recordId: string) {
  const {
    data: { data },
  } = await httpService.delete(`sar/profiles/${profileId}/manual-records/${recordId}`);

  return data;
}

export async function getCountries(): Promise<CountryEntity[]> {
  const {
    data: { data },
  } = await httpService.fetch('countries');

  return data;
}

export async function getAllIncludedDataSources(
  profileId: string,
  bigidGridQuery: BigidGridQueryComponents,
): Promise<IncludedDataSources> {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`sar/profiles/${profileId}/datasource/name?${gridConfigQuery}`);

  return data;
}

export function syncFieldsWithCatalog(profileId: string, filter: BigidFilter) {
  const filterQuery = getFilterQuery(filter);
  return httpService.post(`sar/profiles/${profileId}/fields/synchronize-with-catalog?${filterQuery}`);
}

export function exportFields(profileId: string, filter: BigidFilter) {
  const filterQuery = getFilterQuery(filter);
  return httpService.downloadFile(`sar/profiles/${profileId}/fields/file-download?${filterQuery}`);
}

export async function getObjects(
  profileId: string,
  bigidGridQuery: BigidGridQueryComponents = {},
): Promise<{ objects: DsarObjects[]; totalCount: number }> {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`sar/profiles/${profileId}/objects?${gridConfigQuery}`);

  return data;
}

export async function updateObjects(
  profileId: string,
  postData: {
    query: {
      filter?: BigidFieldFilter[];
    };
    data: DsarObjectsUpdateModel;
  },
) {
  return httpService.patch(`sar/profiles/${profileId}/objects`, postData);
}

export async function getObjectsColumns(
  profileId: string,
  fullyQualifiedObjectName: string,
): Promise<{ columns: DsarObjectsColumns[]; totalCount: number }> {
  const {
    data: { data },
  } = await httpService.fetch(`sar/profiles/${profileId}/objects/columns?objectName=${fullyQualifiedObjectName}`);

  return data;
}

export async function updateObjectsColumns(
  profileId: string,
  fullyQualifiedObjectName: string,
  payload: DsarObjectsColumnsUpdateModel,
) {
  return httpService.post(`sar/profiles/${profileId}/objects/columns?objectName=${fullyQualifiedObjectName}`, payload);
}

export async function updateObjectsColumnsAttributes(
  profileId: string,
  fullyQualifiedObjectName: string,
  columnName: string,
  payload: DsarObjectsColumnsAttributesUpdateModel,
) {
  return httpService.post(
    `sar/profiles/${profileId}/objects/columns/attributes?objectName=${fullyQualifiedObjectName}&columnName=${columnName}`,
    payload,
  );
}

function getFilterQuery(filter: BigidFilter) {
  const filterQuery = filter?.length ? `&filter=${getEncodedArray(filter)}` : '';
  return filterQuery;
}

export async function getDictionaries(
  bigidGridQuery: BigidGridQueryComponents,
): Promise<{ objects: DsarDictionary[]; totalCount: number }> {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`sar/settings/dictionaries?${gridConfigQuery}`);

  return data;
}

export async function createDictionary(
  broadcastEvent: string,
  payload: FormData,
): Promise<{ id: DsarDictionary['id'] }> {
  const {
    data: { data },
  } = await httpService.post(`sar/settings/dictionaries?broadcastEvent=${broadcastEvent}`, payload);

  return data;
}

export async function updateDictionary(
  broadcastEvent: string,
  payload: FormData,
  id: string,
): Promise<{ id: DsarDictionary['id'] }> {
  const {
    data: { data },
  } = await httpService.patch(`sar/settings/dictionaries/${id}?broadcastEvent=${broadcastEvent}`, payload);

  return data;
}

export async function deleteDictionary(id: string) {
  const {
    data: { data },
  } = await httpService.delete(`sar/settings/dictionaries/${id}`);

  return data;
}

export async function getDictionary(id: string): Promise<DsarDictionary> {
  const {
    data: { data },
  } = await httpService.fetch(`sar/settings/dictionaries/${id}`);

  return data;
}

export function downloadDictionaryTemplate() {
  return httpService.downloadFile(`sar/settings/dictionaries/template/file-download`);
}

export function downloadDictionary(dictionaryId: string) {
  return httpService.downloadFile(`sar/settings/dictionaries/${dictionaryId}/entries/file-download`);
}
