import React from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidFormRenderProps,
  BigidHeading6,
  BigidTooltip,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { WizardFormFields } from '../../../types/CorrelationSetsTypes';
import { BigidHelpIcon } from '@bigid-ui/icons';

const BigidAccordionSummaryContainer = styled(BigidAccordionSummary)`
  padding: 0 !important;
`;

const BigidAccordionDetailsContainer = styled(BigidAccordionDetails)`
  padding: 0 0 16px 3px !important;

  fieldset:last-child {
    margin-bottom: 0;
  }
`;

const FormGroupBox = styled('div')`
  margin-bottom: 16px;
  fieldset {
    margin: 0 0 6px;
  }
`;

const EnableLabel = styled('div')`
  margin-bottom: 16px;
  fieldset {
    margin: 0 0 6px;
    & > div {
      align-items: center;
    }
  }

  [data-aid='BigidFormFieldSideLabelContainer-bigid-form-field-enabled'] label {
    margin-right: 0;
  }
`;

const EnableWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  & > div:first-child {
    margin-bottom: 3px;
  }
`;

interface AdvancedOptionsProps {
  renderField: BigidFormRenderProps['renderField'];
}

export const AdvancedOptions = ({ renderField }: AdvancedOptionsProps) => {
  const { t } = useLocalTranslation('wizard.trainingStep');

  return (
    <BigidAccordion
      dataAid={generateDataAid('BigidAccordion', ['advanced-options'])}
      hasBorder={false}
      hasShadow={false}
    >
      <BigidAccordionSummaryContainer size={AccordionSummarySizeEnum.medium}>
        <BigidHeading6>{t('advancedOptionsTitle')}</BigidHeading6>
      </BigidAccordionSummaryContainer>
      <BigidAccordionDetailsContainer>
        <EnableWrapper>
          <EnableLabel>{renderField(WizardFormFields.enabled)}</EnableLabel>
          <BigidTooltip title={t('enabledTooltip')}>
            <div>
              <span>
                <BigidHelpIcon></BigidHelpIcon>
              </span>
            </div>
          </BigidTooltip>
        </EnableWrapper>
        <FormGroupBox>{renderField(WizardFormFields.scanConnectionTimeoutInSeconds)}</FormGroupBox>
        <FormGroupBox>{renderField(WizardFormFields.display_name)}</FormGroupBox>
        <FormGroupBox>{renderField(WizardFormFields.residency)}</FormGroupBox>
      </BigidAccordionDetailsContainer>
    </BigidAccordion>
  );
};
