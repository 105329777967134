import { styled } from '@mui/material';
import React from 'react';
import { DataPoint, DataPointProps } from './DataPoint';
import { useLocalTranslation } from '../../../../../translations';
import { BigidHeading6, BigidListItem, BigidSelectOption } from '@bigid-ui/components';
import { DataCatalogObjectDetails } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { dateTimeService } from '@bigid-ui/i18n';
import { DataCatalogOwnersDialog } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogOwnersDialog';
import { getBasicDetails } from '../../../../../../DataCatalog/DataCatalogDetails';
import { OwnerV2 } from '../../../../../types';
import { OverviewOwners } from './OverviewOwners';
import { formatOwnerToDetails } from '../../utils';
import { head, tail } from 'lodash';
import { DataCatalogRecordScannerTypeGroup } from '../../../../../../DataCatalog/DataCatalogService';
import { getScanStatusDisplayName } from './objectDetails.util';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 28px 48px;
`;

const List = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

type BasicDetailsProps = {
  details: DataCatalogObjectDetails;
  usersList: BigidSelectOption[];
  refetchData: () => void;
  dsOwners: OwnerV2[];
  isListView?: boolean;
};

const STRUCTURED = DataCatalogRecordScannerTypeGroup.STRUCTURED;

export const BasicDetails = ({ details, usersList, refetchData, dsOwners = [], isListView }: BasicDetailsProps) => {
  const [isAddOwnerDialogOpen, setIsAddOwnerDialogOpen] = React.useState(false);
  const { t } = useLocalTranslation('sidePanel.overview.basicDetails');
  const {
    scanStatus,
    last_scanned,
    modified_date,
    ds_location,
    tableTotalRows,
    columnOrFieldOccurrencesCounter,
    fullyQualifiedName,
    scannerType,
    dataSourceName: source,
    object_owners_struct,
    scanner_type_group,
    fullObjectName,
    createdBy,
    last_opened,
    lastAccessedBy,
  } = details || {};

  const isUnstructured = scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED;

  const handleOnDialogClose = () => {
    setIsAddOwnerDialogOpen(false);
  };

  const handleOnDialogSubmit = () => {
    refetchData();
    setIsAddOwnerDialogOpen(false);
  };

  const basicDetailsList: BigidListItem[] = getBasicDetails(details);

  const itOwners = dsOwners.filter(owner => owner.type === 'it').map(owner => owner.id);

  const businessOwners = dsOwners.filter(owner => owner.type === 'business').map(owner => owner.id);

  const DataContainer = isListView ? List : Grid;

  const valueMaxWidth = isListView ? '270px' : '200px';

  const dataPoints: DataPointProps[] = [
    {
      title: t('items.owners'),
      value: (
        <OverviewOwners
          threshold={1}
          valueMaxWidth={valueMaxWidth}
          totalNumberOfItems={object_owners_struct?.length || 0}
          value={formatOwnerToDetails(head(object_owners_struct))}
          tooltipList={tail(object_owners_struct).map(formatOwnerToDetails)}
        />
      ),
      editable: true,
      onEdit: () => setIsAddOwnerDialogOpen(true),
    },
  ];

  itOwners?.length > 0 &&
    dataPoints.push({
      title: t('items.itOwners'),
      value: (
        <OverviewOwners
          threshold={1}
          totalNumberOfItems={itOwners.length}
          value={head(itOwners)}
          valueMaxWidth={valueMaxWidth}
          tooltipList={tail(itOwners)}
        />
      ),
    });

  businessOwners?.length > 0 &&
    dataPoints.push({
      title: t('items.dsOwners'),
      value: (
        <OverviewOwners
          threshold={1}
          totalNumberOfItems={businessOwners.length}
          value={head(businessOwners)}
          valueMaxWidth={valueMaxWidth}
          tooltipList={tail(businessOwners)}
        />
      ),
    });

  ds_location &&
    dataPoints.push({
      title: t('items.dsLocation'),
      value: ds_location,
    });

  dataPoints.push({
    title: t('items.scanStatus'),
    value: getScanStatusDisplayName(scanStatus),
  });

  scanner_type_group === STRUCTURED &&
    columnOrFieldOccurrencesCounter &&
    dataPoints.push({
      title: t('items.nOfColumns'),
      value: columnOrFieldOccurrencesCounter?.length,
    });

  scanner_type_group === STRUCTURED &&
    tableTotalRows &&
    dataPoints.push({
      title: t('items.nOfRows'),
      value: tableTotalRows,
    });

  if (fullObjectName) {
    dataPoints.push({
      title: t('items.fullObjectName'),
      value: fullObjectName,
    });
  }

  if (isUnstructured) {
    createdBy &&
      dataPoints.push({
        title: t('items.createdBy'),
        value: createdBy,
      });
    last_opened &&
      dataPoints.push({
        title: t('items.lastOpened'),
        value: dateTimeService.formatLong(last_opened),
      });
    lastAccessedBy &&
      dataPoints.push({
        title: t('items.lastAccessedBy'),
        value: lastAccessedBy,
      });
  }

  last_scanned && dataPoints.push({ title: t('items.lastScanned'), value: dateTimeService.formatLong(last_scanned) });
  modified_date &&
    dataPoints.push({ title: t('items.lastModified'), value: dateTimeService.formatLong(modified_date) });

  return (
    <Root>
      <BigidHeading6>{t('title')}</BigidHeading6>
      <DataContainer>
        {dataPoints.map((dataPoint, index) => (
          <DataPoint key={index} {...dataPoint} />
        ))}
      </DataContainer>
      {isAddOwnerDialogOpen && (
        <DataCatalogOwnersDialog
          fullyQualifiedName={fullyQualifiedName}
          usersList={usersList}
          isAddOwnerDialogOpen={isAddOwnerDialogOpen}
          basicDetailsList={basicDetailsList}
          onClose={handleOnDialogClose}
          onSubmit={handleOnDialogSubmit}
          dsName={source}
          dsType={scannerType}
        />
      )}
    </Root>
  );
};
