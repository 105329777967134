import React, { FC, useEffect, useRef, useState } from 'react';
import { dataSourceConnectionsService } from '../../../services/angularServices';
import {
  BigidFormStateAndHandlers,
  BigidWizardContainer,
  BigidWizardHorizontalNavStepData,
  BigidWizardHorizontalStepStatus,
  EntityEvents,
  entityEventsEmitter,
  TertiaryButton,
  useHorizontalWizard,
} from '@bigid-ui/components';
import { BigidSaveIcon } from '@bigid-ui/icons';
import { useLocalTranslation, getFixedT } from '../translations';
import { DetailsStep } from './Steps/DetailsStep/DetailsStep';
import { PreviewStep } from './Steps/PreviewStep/PreviewStep';
import { TrainingStep } from './Steps/TrainingStep/TrainingStep';
import { ResultsStep } from './Steps/ResultsStep/ResultsStep';
import {
  CorrelationSetWizardContext,
  DEFAULT_CORRELATION_SET_FORM_DATA,
  DEFAULT_CORRELATION_SET_GUIDE_STATE,
} from '../contexts/correlationSetContext';
import {
  GuideState,
  CorrelationSetData,
  CorrelationSetFormData,
  CorrelationSetsWizardSteps,
  WizardFormFields,
  DataSourceType,
} from '../types/CorrelationSetsTypes';
import { DetailsGuideContent } from './Steps/DetailsStep/DetailsGuideContent';
import { withGuideRightComponent } from '../components/CorrelationSetStepsWrapper';
import {
  CORRELATION_SET_CONNECTIONS_GRID_ID,
  transformCorrelationSetFormStateToPayload,
  transformCorrelationSetResponseToFormState,
} from '../utils/utils';
import {
  testConnectionRequest,
  createNewCorrelationSet,
  updateNewCorrelationSet,
  fetchCorrelationSetByName,
} from '../services/correlationSetsService';
import { openTestConnectionConfirmationDialog } from '../components/Dialogs/TestConnectionConfirmationDialog';
import { getDsConnectionsQuery } from '../../../utilities/dataSourcesUtils';
import styled from '@emotion/styled';
import { TrainingGuideContent } from './Steps/TrainingStep/TrainingGuideContent';
import { PreviewGuideContent } from './Steps/PreviewStep/PreviewGuideContent';
import { isEqual } from 'lodash';
import { useKey } from '../../DataSources/DataSourceConfiguration/hooks/useKey';

const Container = styled('div')`
  height: 100%;

  & [data-aid='BigidWizardHorizontalNav'] {
    padding-top: 24px;
  }

  & [data-aid='BigidWizardContainerHeader'] {
    display: none;
  }

  & [data-aid='correlationSetsWizard'] [data-aid='BigidPaper'] {
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    position: relative;
    padding-bottom: 72px; // reserved space for the buttons container
  }

  & [data-aid='BigidWizardContainerButtons'] {
    position: absolute;
    bottom: 0;
    padding: 16px;
  }
`;

const fixedT = getFixedT('wizard');

export const correlationSetWizardSteps: BigidWizardHorizontalNavStepData[] = [
  {
    id: CorrelationSetsWizardSteps.DETAILS,
    title: fixedT('titleStep1'),
    component: withGuideRightComponent(DetailsStep),
  },
  {
    id: CorrelationSetsWizardSteps.PREVIEW,
    title: fixedT('titleStep2'),
    component: withGuideRightComponent(PreviewStep),
  },
  {
    id: CorrelationSetsWizardSteps.TRAINING,
    title: fixedT('titleStep3'),
    component: withGuideRightComponent(TrainingStep),
  },
  {
    id: CorrelationSetsWizardSteps.RESULTS,
    title: fixedT('titleStep4'),
    component: withGuideRightComponent(ResultsStep),
  },
];

export const guideStepsContent = {
  [CorrelationSetsWizardSteps.DETAILS]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
  [CorrelationSetsWizardSteps.PREVIEW]: {
    title: 'Review and adjust the Analyzing set',
    contentComponent: PreviewGuideContent,
  },
  [CorrelationSetsWizardSteps.TRAINING]: {
    title: 'Set a Unique ID',
    contentComponent: TrainingGuideContent,
  },
  [CorrelationSetsWizardSteps.RESULTS]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
};

const checkIfNextStepDisabled = (activeStepId: string) => {
  switch (activeStepId) {
    case CorrelationSetsWizardSteps.DETAILS:
      return false;
    case CorrelationSetsWizardSteps.PREVIEW:
      return false;
    case CorrelationSetsWizardSteps.TRAINING:
      return false;
    case CorrelationSetsWizardSteps.RESULTS:
      return false;
    default:
      return false;
  }
};

interface CorrelationSetWizardProps {
  correlationSetName?: string;
}

export const CorrelationSetWizard = ({ correlationSetName }: CorrelationSetWizardProps) => {
  const { t } = useLocalTranslation('wizard');
  const [correlationName, setCorrelationName] = useState<string>(correlationSetName);
  const isEdit = !!correlationName;
  const detailsStepFormControls = useRef<BigidFormStateAndHandlers>();

  const [correlationSetFormData, setCorrelationSetFormData] = useState<CorrelationSetFormData>(
    DEFAULT_CORRELATION_SET_FORM_DATA,
  );
  const [guideState, setCorrelationGuideState] = useState<GuideState>(DEFAULT_CORRELATION_SET_GUIDE_STATE);
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const { steps, updateStepAttributes, ActiveView, activeStepId, goToNext, goToPrev, noPrev, noNext, getPrevStep } =
    useHorizontalWizard(correlationSetWizardSteps, '');

  const getStepStatus = (stepId: CorrelationSetsWizardSteps, next?: boolean, prev?: boolean) => {
    if (next) {
      return BigidWizardHorizontalStepStatus.DONE;
    }
    if (prev) {
      return BigidWizardHorizontalStepStatus.DEFAULT;
    }
    return BigidWizardHorizontalStepStatus.ACTIVE;
  };

  const fetchCorrelationSetData = async (name: string) => {
    const response = await fetchCorrelationSetByName(name);

    if (response && response.id_connection) {
      const dsConnection = response.id_connection.dsConnection;
      let dsConnectionType = response.id_connection.dsConnectionType;

      if (!dsConnectionType && dsConnection) {
        const selectedDsQuery = getDsConnectionsQuery({
          operator: 'equal',
          searchString: dsConnection,
        });

        const {
          data: { ds_connections },
        } = await dataSourceConnectionsService.getDSConnectionDataByQuery(selectedDsQuery);

        dsConnectionType = (ds_connections as DataSourceType[]).find(ds => ds.name === dsConnection)?.type;
      }

      if (response.id_connection.type === 'ds-connection') {
        setCorrelationSetFormData(
          transformCorrelationSetResponseToFormState(response?.id_connection, dsConnectionType),
        );
      }
    }
  };

  useEffect(() => {
    correlationSetName && fetchCorrelationSetData(correlationSetName);
  }, [correlationSetName]);

  const handleTestConnectionError = (err: any) => {
    let errorMsg = 'Test Connection Failed!';
    if (err?.code) {
      errorMsg = err.code;
    } else if (err?.message) {
      errorMsg = err.message;
    } else if (typeof err === 'string') {
      errorMsg = err;
    }

    setCorrelationGuideState({ ...guideState, errorMessage: errorMsg });
  };

  const testESConnectionSync = async (formData: CorrelationSetData, connectionStep: CorrelationSetsWizardSteps) => {
    try {
      setIsLoadingConnection(true);
      const response = await testConnectionRequest(formData);

      if (!response.isSuccessful) {
        if (response.err?.code) handleTestConnectionError(response.err);
        return;
      }

      if (connectionStep === CorrelationSetsWizardSteps.PREVIEW) {
        setCorrelationSetFormData(prevState => ({ ...prevState, attributes: response.columns }));
        detailsStepFormControls.current.setValue(WizardFormFields.attributes)(response.columns);
        setCorrelationGuideState({ ...guideState, errorMessage: '' });
      }

      if (connectionStep === CorrelationSetsWizardSteps.TRAINING) {
        setCorrelationSetFormData(prevState => ({ ...prevState, attributesTrainingStep: response.columns }));
        detailsStepFormControls.current.setValue(WizardFormFields.attributesTrainingStep)(response.columns);
        setCorrelationGuideState({ ...guideState, errorMessage: '' });
      }

      return response;
    } catch (e) {
      handleTestConnectionError(e);
    } finally {
      setIsLoadingConnection(false);
    }
  };

  const handleTestConnection = async (
    formData: CorrelationSetFormData,
    connectionStep: CorrelationSetsWizardSteps,
    isCustomQueryStringImportantForTestConnection?: boolean,
  ) => {
    const correlationSetPayload = transformCorrelationSetFormStateToPayload(
      formData,
      formData.draft,
      isCustomQueryStringImportantForTestConnection,
    );

    return testESConnectionSync(correlationSetPayload, connectionStep);
  };

  const validateStep = async (activeStepId: CorrelationSetsWizardSteps): Promise<boolean> => {
    switch (activeStepId) {
      case CorrelationSetsWizardSteps.DETAILS:
        return new Promise<boolean>(resolve => {
          detailsStepFormControls.current?.validateAndSubmit(
            async formData => {
              setCorrelationSetFormData(formData as CorrelationSetFormData);

              let response = null;
              const hasAttributes = formData?.attributes?.length > 0;

              // is already connected to DS
              if (hasAttributes) {
                //show confirmation dialog
                const { isGoNext, isReConnect } = await openTestConnectionConfirmationDialog();

                if (isGoNext) return resolve(true);
                if (isReConnect) {
                  response = await handleTestConnection(
                    formData as CorrelationSetFormData,
                    CorrelationSetsWizardSteps.PREVIEW,
                  );
                }
              } else {
                response = await handleTestConnection(
                  formData as CorrelationSetFormData,
                  CorrelationSetsWizardSteps.PREVIEW,
                );
              }

              if (response?.columns?.length > 0) {
                resolve(true);
              }

              return false;
            },
            () => resolve(false),
          );
        });

      case CorrelationSetsWizardSteps.PREVIEW:
        return new Promise<boolean>(resolve => {
          detailsStepFormControls.current?.validateAndSubmit(
            async formData => {
              const oldAttributes = correlationSetFormData.attributes;
              const newAttributes = formData.attributes;
              const isAttributesEqual = isEqual(oldAttributes, newAttributes);

              setCorrelationSetFormData(formData as CorrelationSetFormData);

              if (isAttributesEqual && formData.attributesTrainingStep?.length > 0) return resolve(true);

              // means that user has hanged attributes array
              if (!isAttributesEqual || !formData.attributesTrainingStep?.length) {
                const response = await handleTestConnection(
                  formData as CorrelationSetFormData,
                  CorrelationSetsWizardSteps.TRAINING,
                  true,
                );

                if (response?.columns?.length > 0) {
                  return resolve(true);
                }
              }

              return false;
            },
            () => resolve(false),
          );
        });

      case CorrelationSetsWizardSteps.TRAINING:
        return new Promise<boolean>(resolve => {
          detailsStepFormControls.current?.validateAndSubmit(
            async formData => {
              setCorrelationSetFormData(formData as CorrelationSetFormData);
              resolve(true);
            },
            () => {
              resolve(false);
            },
          );
        });
      default:
        return true;
    }
  };

  const handleGoToNext = async () => {
    const isCurrentStepDataValid = await validateStep(activeStepId as CorrelationSetsWizardSteps);
    if (isCurrentStepDataValid) {
      if (noNext) {
        // TODO: implement behavior for last step save button;
        console.log('SAVE clicked');
      } else {
        updateStepAttributes([
          { stepId: activeStepId, attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps, true) } },
        ]);
        goToNext();
      }
    }
  };

  const handleGoToPrev = async () => {
    await detailsStepFormControls.current.validateAndSubmit(async formData => {
      setCorrelationSetFormData(formData as CorrelationSetFormData);
      goToPrev();

      updateStepAttributes([
        {
          stepId: activeStepId,
          attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps, false, true) },
        },
        { stepId: getPrevStep(), attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps) } },
      ]);
    });
  };

  const handleSaveDraft = async () => {
    await detailsStepFormControls.current.validateAndSubmit(async formData => {
      try {
        const correlationSetPayload = transformCorrelationSetFormStateToPayload(
          formData as CorrelationSetFormData,
          true,
        );

        let response;
        if (isEdit) {
          response = await updateNewCorrelationSet(correlationName, correlationSetPayload);
        } else {
          response = await createNewCorrelationSet(correlationSetPayload);
        }

        if ((isEdit && response?.result === 'OK') || (response?.name && response?.id)) {
          setCorrelationName(formData?.name);
          entityEventsEmitter.emit(EntityEvents.RELOAD, CORRELATION_SET_CONNECTIONS_GRID_ID);
        }
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
      }
    });
  };

  useEffect(() => {
    setIsNextDisabled(checkIfNextStepDisabled(activeStepId));
  }, [activeStepId]);

  return (
    <Container>
      <CorrelationSetWizardContext.Provider
        value={{
          correlationSetFormData,
          setCorrelationSetFormData,
          guideState,
          setCorrelationGuideState,
          isLoadingConnection,
        }}
      >
        <BigidWizardContainer
          steps={steps}
          activeStepId={activeStepId}
          ActiveView={ActiveView}
          isLoading={false}
          onNext={handleGoToNext}
          onPrev={handleGoToPrev}
          noNext={noNext}
          noPrev={noPrev}
          stepsProps={{ activeStepId, detailsStepFormControls, id: correlationSetName }}
          isNextDisabled={isNextDisabled}
          lastStepText={'Save'}
          title=""
          disableStepClick
          dataAid="correlationSetsWizard"
          customButton={
            <TertiaryButton
              size="large"
              startIcon={<BigidSaveIcon />}
              onClick={handleSaveDraft}
              dataAid="save-draft-button"
              disabled={false}
              text={t('saveDratBtn')}
            />
          }
        />
      </CorrelationSetWizardContext.Provider>
    </Container>
  );
};
