import React from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceIconByDsType, BigidTableColumnsIcon, BigidQueryLanguageIcon } from '@bigid-ui/icons';
import { DsConnectionSelectOptionType } from '../../types/CorrelationSetsTypes';
import styled from '@emotion/styled';

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 16px;
`;

const LeftHeaderContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const IconContainer = styled('div')`
  margin-right: 4px;
  display: flex;
`;

const SplitterBox = styled('div')`
  margin: 0 4px;
`;

interface GridHeaderProps {
  dsConnection: DsConnectionSelectOptionType[];
  db_table: string;
  isCustomQuery: boolean;
}

export const GridHeader = ({ dsConnection, db_table, isCustomQuery }: GridHeaderProps) => {
  const secondPartText = isCustomQuery ? 'query' : db_table;
  const secondPartIcon = isCustomQuery ? <BigidQueryLanguageIcon width={16} /> : <BigidTableColumnsIcon width={16} />;
  const { label, type } = dsConnection[0];

  return (
    <HeaderContainer>
      <LeftHeaderContainer>
        <IconContainer>
          <DataSourceIconByDsType
            data-aid={generateDataAid('DataSource', ['dsIcon', type])}
            dataSourceType={type}
            width={16}
          />
        </IconContainer>
        {label}
        <SplitterBox>/</SplitterBox>
        <IconContainer>{secondPartIcon}</IconContainer>
        {secondPartText}
      </LeftHeaderContainer>
    </HeaderContainer>
  );
};
