import React from 'react';
import { i18next } from '@bigid-ui/i18n';
import { iconSets, Generic2 } from '@bigid-ui/icons';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';

import { REGULATIONS_NAMESPACE } from './translations';
import { ComplianceRegulation } from './types';
import { frameworkIconGenerator } from './utils';

const tLabels = i18next.getFixedT(null, REGULATIONS_NAMESPACE, 'Labels');

export enum events {
  REGULATIONS_TAB_OPEN = 'Regulations_Tab_Open',
}

export const regulationFiltersConfig: BigidGridWithToolbarProps<ComplianceRegulation>['filterToolbarConfig'] = {
  filters: [],
  searchConfig: {
    searchFilterKeys: ['name', 'description', 'region', 'countries', 'states'],
    operator: 'textSearch',
  },
};

export const regulationColumnsConfig: BigidGridColumn<ComplianceRegulation>[] = [
  {
    name: 'name',
    title: tLabels('columns.regulation'),
    width: 200,
    filteringEnabled: false,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXTIFIED_ICON,
    getCellValue: ({ name, flagCode }) => {
      const Icon = iconSets.FlagIcons?.[flagCode] || Generic2;
      return {
        text: {
          displayValue: name,
          tooltip: name,
        },
        icon: {
          icon: () => <Icon width={20} height={20} />,
        },
      };
    },
  },
  {
    name: 'description',
    title: tLabels('columns.description'),
    width: 300,
    filteringEnabled: false,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ description }) => description,
  },
  {
    name: 'countries',
    title: tLabels('columns.countries'),
    width: 260,
    filteringEnabled: false,
    sortingEnabled: false,
    type: BigidGridColumnTypes.CHIPS,
    getCellValue: ({ countries }) => ({
      chips: {
        isDisabled: true,
        entityMaxWidth: 170,
        value: countries.map(country => ({
          label: country,
          variant: 'filled',
          bgColor: 'transparent',
          outlineColor: 'transparent',
          outline: null,
        })),
      },
    }),
  },
  {
    name: 'states',
    title: tLabels('columns.states'),
    width: 200,
    filteringEnabled: false,
    sortingEnabled: false,
    type: BigidGridColumnTypes.CHIPS,
    getCellValue: ({ states }) => ({
      chips: {
        isDisabled: true,
        value: states.map(state => ({
          label: state,
          variant: 'filled',
          bgColor: 'transparent',
          outlineColor: 'transparent',
          outline: null,
        })),
      },
    }),
  },
  {
    name: 'frameworks',
    title: tLabels('columns.relatedFrameworks'),
    width: 260,
    filteringEnabled: false,
    sortingEnabled: false,
    type: BigidGridColumnTypes.CHIPS,
    getCellValue: ({ frameworks }) => ({
      chips: {
        isDisabled: true,
        value: frameworks.map(framework => {
          const Icon = frameworkIconGenerator(framework) || Generic2;
          return {
            id: framework,
            label: framework,
            variant: 'outlined',
            bgColor: 'transparent',
            icon: <Icon width={16} height={16} />,
            iconPlacement: 'left',
          };
        }),
      },
    }),
  },
  {
    name: 'updated_at',
    title: tLabels('columns.lastUpdate'),
    width: 200,
    filteringEnabled: false,
    sortingEnabled: true,
    getCellValue: ({ updated_at }) => updated_at,
    type: BigidGridColumnTypes.DATE,
    formatter: { i18n: { dateTimeOptions: { formatString: 'd MMM yyyy HH:MM' } } },
  },
];
