import { styled } from '@mui/material';
import React, { useCallback } from 'react';
import { CatalogSidePanelProps } from '../CatalogSidePanel';
import { BigidGrid } from '@bigid-ui/grid';
import { useGetColumnsConfig } from './useGetColumnsConfig';
import { AttributeMappingDialog } from './modalDialogEditors/AttributeMappingDialog';
import { TagsDialog } from './modalDialogEditors/TagsDialog';
import { BigidPaper, BigidSidePanel, BigidToolbar } from '@bigid-ui/components';
import { SidePanelMasterDetails } from '../components/ColumnsMasterDetails';
import { ColumnsMasterDetailsPreview } from './ColumnsMasterDetailsPreview';
import { BusinessAttributeDialog } from './modalDialogEditors/BusinessAttributeDialog';
import { SimilarColumnsDialog } from './modalDialogEditors/SimilarColumnsDialog';
import { LinkedColumnsDialog } from './modalDialogEditors/LinkedColumnsDialog';
import { ColumnsSidePanelContent } from './ColumnsSidePanelContent';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export type DataExplorerColumnsProps = Pick<CatalogSidePanelProps['selectedItem'], 'id'> &
  Pick<CatalogSidePanelProps['selectedItem']['data'], 'datasource' | 'detailedObjectType'> & {
    isExtendedView?: boolean;
    isDataPreviewDisabled?: boolean;
    scannerTypeGroup?: string;
  };

export const DataExplorerColumns = (props: DataExplorerColumnsProps) => {
  const { datasource, scannerTypeGroup, id: fullyQualifiedName } = props;
  const { scannerType, value } = datasource;
  const {
    gridConfig,
    attributeMappingDialogConfig,
    tagsDialogConfig,
    handleMasterDetailsClose,
    selectedColumn,
    toolbarConfig,
    businessAttributeDialogConfig,
    similarColumnsDialogConfig,
    linkedColumnsDialogConfig,
    setSimilarColumnsDialogState,
  } = useGetColumnsConfig(props);

  const { isDataPreviewDisabled = false, isExtendedView } = props;

  const shouldDetailsBeOpened = selectedColumn && !isDataPreviewDisabled;

  const MasterDetailsContent = isExtendedView ? null : (
    <SidePanelMasterDetails onClose={handleMasterDetailsClose} title={selectedColumn?.column_name}>
      <ColumnsMasterDetailsPreview fullyQualifiedName={fullyQualifiedName} columnName={selectedColumn?.column_name} />
    </SidePanelMasterDetails>
  );

  const handleViewSimilarColumnsClick = useCallback((): void => {
    const { column_name, clusterId } = selectedColumn;
    setSimilarColumnsDialogState({ clusterId, columnName: column_name, fullyQualifiedName, isOpen: true });
  }, [fullyQualifiedName, selectedColumn, setSimilarColumnsDialogState]);

  return (
    <Root>
      {!isExtendedView && <BigidToolbar {...toolbarConfig} />}

      <BigidPaper>
        <BigidGrid {...gridConfig}>{shouldDetailsBeOpened && MasterDetailsContent}</BigidGrid>
      </BigidPaper>
      <AttributeMappingDialog {...attributeMappingDialogConfig} />
      <TagsDialog {...tagsDialogConfig} />
      {businessAttributeDialogConfig.isOpen && <BusinessAttributeDialog {...businessAttributeDialogConfig} />}
      {similarColumnsDialogConfig.isOpen && <SimilarColumnsDialog {...similarColumnsDialogConfig} />}
      <LinkedColumnsDialog {...linkedColumnsDialogConfig} />

      {selectedColumn && isExtendedView && (
        <BigidSidePanel
          content={
            <ColumnsSidePanelContent
              selectedColumn={selectedColumn}
              handleViewSimilarColumnsClick={handleViewSimilarColumnsClick}
              dsType={scannerType}
              scannerType={scannerTypeGroup}
              fullyQualifiedName={fullyQualifiedName}
              dsName={value}
            />
          }
          title={selectedColumn?.column_name}
          open={shouldDetailsBeOpened && isExtendedView}
          onClose={handleMasterDetailsClose}
          maxWidth="medium"
          isShowBackdrop
        />
      )}
    </Root>
  );
};
