import React, { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import { getWizardLayoutTabs } from './config/wizard';
import { BigidFormStateAndHandlers, BigidFormValues, BigidTabsItem } from '@bigid-ui/components';
import { DataSourceWizardLayout } from './DataSourceWizard/DataSourceWizardLayout';
import { UpdateDataSourceConfigState } from './hooks/useDataSourceConfigState';
import { useLocalTranslation } from '../DataSourceConnectionModal/translations';
import { updateScopeIfNeeded } from './hooks/useSubmitDataSource';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { DataSourceTypes, DsConnectionFieldSectionsEnum } from './types';
import {
  DataSourceConnectionModal,
  DataSourceConnectionModalControl,
  type DataSourceHandlerOpts,
} from '../DataSourceConnectionModal/DataSourceConnectionModal';
import type { DataSourceConnectionConfigurationState } from '../DataSourceConnectionModal/DataSourceConnectionConfiguration';
import { UseSubmitDataSourceConfig } from '../DataSourceConnectionModal/hooks/useSubmitDataSource';

type DataSourceConfigurationTabWizardProps = {
  sourceId: string;
  source: string;
  label: string;
  documentationUrl: string;
  modal: MutableRefObject<DataSourceConnectionModalControl>;
  form: MutableRefObject<BigidFormStateAndHandlers>;
  showDetachedSuccess: boolean;
  initialStep?: DsConnectionFieldSectionsEnum;
  showError?: boolean;
  shouldAutoOpenModalAndConnect: boolean;
  updateState: UpdateDataSourceConfigState;
  setShouldAutoConnect: Dispatch<SetStateAction<boolean>>;
  onChange?: (values: Record<string, unknown>) => void;
  onStateChange?: () => void;
  onBack?: () => void;
};

const DEFAULT_TAB_SECTION = DsConnectionFieldSectionsEnum.connection;

export const DataSourceConfigurationTabWizard = ({
  sourceId,
  source,
  label,
  documentationUrl,
  shouldAutoOpenModalAndConnect,
  setShouldAutoConnect,
  showDetachedSuccess = true,
  initialStep,
  showError,
  modal,
  form,
  updateState,
  onChange,
  onStateChange,
  onBack,
}: DataSourceConfigurationTabWizardProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [tab, setActiveTab] = useState<DsConnectionFieldSectionsEnum>(initialStep ?? DEFAULT_TAB_SECTION);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useLocalTranslation();
  const tabs = getWizardLayoutTabs(t ?? ((key: string) => key));

  const isSidebarVisible = [DsConnectionFieldSectionsEnum.connection, DsConnectionFieldSectionsEnum.details].includes(
    tab,
  );

  const handleUpdateEditPageLocation = async (sourceId: string) => {
    await $state.go(
      CONFIG.states.CONFIG_DATA_SOURCE,
      { id: sourceId, shouldOpenNewModal: false },
      {
        location: 'replace',
      },
    );

    const history: History[] = sessionStorageService.get('history') || [];
    sessionStorageService.set('history', history.slice(0, -1));
  };

  const handleTabChange = (_value: number, { id }: BigidTabsItem) => setActiveTab(id as DsConnectionFieldSectionsEnum);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStateChange = (_state: DataSourceConnectionConfigurationState) => onStateChange?.();

  const handleInputChange = (values: BigidFormValues) => {
    updateState({ isFormDirty: form.current?.formTouched ?? false });
    onChange?.(values);
  };

  const handleSaveDataSource = async (shouldReload: boolean, opts?: DataSourceHandlerOpts) => {
    const { sourceId, scopeChanged, scopes } = opts ?? {};
    updateState({ isFormDirty: false });

    scopeChanged && (await updateScopeIfNeeded({ name: sourceId }, scopes?.map(scope => ({ value: scope })) ?? []));
    return shouldReload && sourceId && (await handleUpdateEditPageLocation(sourceId));
  };

  const handleGoToEditPage = () => onBack?.();

  return (
    <DataSourceWizardLayout
      initial={tabs?.findIndex(({ id }) => id === initialStep)}
      onChange={handleTabChange}
      tabs={tabs}
    >
      <DataSourceConnectionModal
        showSidebar={isSidebarVisible}
        isStandalone
        isOpen
        isEdit
        isError={showError}
        showDetachedSuccess={showDetachedSuccess}
        controls={modal}
        form={form}
        sectionName={tab}
        source={source as unknown as DataSourceTypes}
        sourceId={sourceId}
        dsName={sourceId}
        dsTypeLabel={label}
        nameInDocs={documentationUrl}
        onClose={handleGoToEditPage}
        onChange={handleInputChange}
        onStateChange={handleStateChange}
        onSave={handleSaveDataSource}
        shouldAutoConnect={shouldAutoOpenModalAndConnect}
        setShouldAutoConnect={setShouldAutoConnect}
      />
    </DataSourceWizardLayout>
  );
};
