import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import { DashboardType, ExecutiveDashboard } from '../../components/ExecutiveDashboard';
import { useDashboardToolbar } from '../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import {
  DataCoverageWidget,
  DataRiskWidget,
  SensitiveDataWidget,
  EmptySystemWidget,
  DataRiskToggleOption,
  DataCoverageToggleOption,
} from './widgets';
import { styled } from '@mui/material';
import { DiscoveryDashboardContext } from './DiscoveryDashboardContext';
import { fetchDashboardFilterOptions, isSystemWithoutDataSources, translateValues } from './discoveryDashboardServices';
import { makeBrowserDownloadFileFromBinaryData } from '../../services/httpService';
import { generateReport } from '../../services/generateReportService';
import { useLocalTranslation } from './translations';
import { DataDuplicationWidget } from './widgets/DataDuplicationWidget';
import { DEFAULT_DATE_RANGE, FilterField, FilterNameInEs, WIDGET_QUERY_FILTER_NAME, WidgetName } from './consts';
import { parseAggregationNestedItemsToFilterOptions } from '../DataExplorerSearchResults/queryHelpers';
import { parseAggregationItemsToFilterOptions } from '../CatalogDiscovery/filter/utils';
import { WidgetSettings, ReportWidgets, DiscoveryDashboardWidget } from './types';
import { formatDate, getWidgetQueryFilter } from './utils';
import { DataDuplicationToggleOption } from './widgets/types/DataDuplicationWidgetTypes';
import {
  isRangeFilter,
  mapActiveFiltersToBigidFieldFilters,
  mapDiscoveryDashboardWidgetToReportWidget,
} from './mappers';
import { getDashboardDataFilterExpression, hasFiltersForWidget } from './filterUtils';

export const DISCOVERY_DASHBOARD_ID = 'discoveryDashboard';
const DEFAULT_WIDGET_SETTINGS: WidgetSettings[] = [
  {
    widgetId: ReportWidgets.RISKS,
    filters: [
      {
        field: 'viewBy',
        operator: 'equal',
        value: DataRiskToggleOption.OBJECTS,
      },
    ],
  },
  {
    widgetId: ReportWidgets.COVERAGE,
    filters: [
      {
        field: 'viewBy',
        operator: 'equal',
        value: DataCoverageToggleOption.AMOUNT,
      },
    ],
  },
  {
    widgetId: ReportWidgets.DUPLICATION,
    filters: [
      {
        field: 'viewBy',
        operator: 'equal',
        value: DataDuplicationToggleOption.AMOUNT,
      },
    ],
  },
  {
    widgetId: ReportWidgets.SENSITIVE,
  },
];
const EMPTY_WIDGET_QUERY = '';

const WidgetsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10px;
`;

const SplitSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const WidgetWrapper = styled('div')<{ isHalfWidth?: boolean }>`
  width: ${({ isHalfWidth }) => (isHalfWidth ? '50%' : '100%')};
`;

export const DiscoveryDashboard: FC = () => {
  const { t } = useLocalTranslation();
  const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'dsState',
      field: FilterField.DS_STATE,
      title: t('filters.dsState.name'),
      operator: 'in',
      asyncOptionsFetch: async (_, value) => {
        return fetchDashboardFilterOptions(
          FilterNameInEs.DS_STATE,
          value,
          parseAggregationItemsToFilterOptions,
          translateValues('filters.dsState.values'),
        );
      },
      options: [],
      isSearchable: false,
      disabled: true,
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'owner',
      field: FilterField.OWNER,
      title: t('filters.dsOwners.name'),
      operator: 'in',
      isSearchable: true,
      asyncOptionsFetch: async (_, value) => {
        return fetchDashboardFilterOptions(FilterNameInEs.OWNER, value, parseAggregationItemsToFilterOptions);
      },
      options: [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
      id: 'dateRange',
      field: FilterField.DATE_RANGE,
      title: t('filters.dateRange.name'),
      operator: 'equal',
      options: {
        currentRangeOptionSelected: DEFAULT_DATE_RANGE.OPTION_SELECTED,
        pickersState: {
          dates: {
            from: new Date(new Date().getTime() - DEFAULT_DATE_RANGE.FROM_NUM_DAYS_IN_MILLISECONDS),
            until: new Date(),
          },
          currentMode: 'from',
        },
      },
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'tags',
      field: FilterField.TAGS,
      title: t('filters.tags.name'),
      operator: 'in',
      isSearchable: true,
      asyncOptionsFetch: async (_, value) => {
        return fetchDashboardFilterOptions(FilterNameInEs.TAGS, value, parseAggregationNestedItemsToFilterOptions);
      },
      options: [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'dsType',
      field: FilterField.DS_TYPE,
      title: t('filters.dsType.name'),
      operator: 'in',
      asyncOptionsFetch: async (_, value) => {
        return fetchDashboardFilterOptions(FilterNameInEs.DS_TYPE, value, parseAggregationItemsToFilterOptions);
      },
      options: [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'dsName',
      field: FilterField.DS_NAME,
      title: t('filters.dsName.name'),
      operator: 'in',
      asyncOptionsFetch: async (_, value) => {
        return fetchDashboardFilterOptions(FilterNameInEs.DS_NAME, value, parseAggregationItemsToFilterOptions);
      },
      options: [],
    },
  ];

  const widgetIds = [
    DiscoveryDashboardWidget.DATA_COVERAGE,
    DiscoveryDashboardWidget.DATA_RISK,
    DiscoveryDashboardWidget.SENSITIVE_DATA,
    DiscoveryDashboardWidget.DATA_DUPLICATION,
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [isWaitingForReport, setWaitingForReport] = useState(false);
  const [isEmptySystem, setIsEmptySystem] = useState(true);
  const [widgetSettings, setWidgetSettings] = useState<WidgetSettings[]>(DEFAULT_WIDGET_SETTINGS);
  const { activeFilters, activeWidgetIds, externalAppliedFilters, toolbarActions, isSavedViewsReady } =
    useDashboardToolbar(widgetIds, toolbarFilters, DISCOVERY_DASHBOARD_ID, true);

  const isActiveWidget = useCallback(
    (widgetId: DiscoveryDashboardWidget) => {
      return !!activeWidgetIds?.includes(widgetId);
    },
    [activeWidgetIds],
  );

  const handleViewToggle = (id: string) => (value: string) => {
    const settings = widgetSettings.find(({ widgetId }) => widgetId === id);
    const payload = widgetSettings.filter(({ widgetId }) => widgetId !== id);
    setWidgetSettings([
      ...payload,
      {
        ...settings,
        filters: [
          {
            field: 'viewBy',
            operator: 'equal',
            value,
          },
        ],
      },
    ]);
  };

  const handleDownloadReport = async () => {
    const fileName = `bigid-discovery-report-${formatDate(Date.now())}.pdf`;
    const activeReportWidgetIds = activeWidgetIds.map(mapDiscoveryDashboardWidgetToReportWidget);
    const activeWidgets = widgetSettings.filter(({ widgetId }) =>
      activeReportWidgetIds.includes(widgetId as ReportWidgets),
    );
    const enabledFilters =
      activeFilters?.filter(({ options }) => (isRangeFilter(options) ? true : options?.length > 0)) ?? [];

    const widgetSettingsWithFilters: WidgetSettings[] = activeWidgets.map(({ widgetId, ...settings }) =>
      hasFiltersForWidget(widgetId as WidgetName)
        ? {
            widgetId,
            ...settings,
            filters: [...(settings.filters ?? []), getWidgetQueryFilter(activeFilters, widgetId as WidgetName)],
          }
        : { widgetId, ...settings },
    );

    try {
      setWaitingForReport(true);

      const buffer = await generateReport({
        fileName,
        reportType: 'DataDiscoveryReport',
        reportWidgets: widgetSettingsWithFilters,
        query: {
          filter: [
            ...mapActiveFiltersToBigidFieldFilters(enabledFilters ?? []),
            {
              field: WIDGET_QUERY_FILTER_NAME,
              value: getDashboardDataFilterExpression(activeFilters ?? [], null),
              operator: 'equal',
            },
          ],
        },
      });

      setWaitingForReport(false);
      makeBrowserDownloadFileFromBinaryData(fileName, buffer, 'application/pdf');
    } catch {
      setWaitingForReport(false);
    }
  };

  useEffect(() => {
    const updateIsEmptySystem = async () => {
      const isEmpty = await isSystemWithoutDataSources();
      setIsEmptySystem(isEmpty);
      setIsLoading(false);
    };
    updateIsEmptySystem();
  }, []);

  const widgetSelectionItems: BigidMenuItemProps[] = widgetIds.map(id => {
    return { id, label: t(`${id}Widget.title`) };
  });

  const isSplitSectionApply =
    isActiveWidget(DiscoveryDashboardWidget.SENSITIVE_DATA) &&
    isActiveWidget(DiscoveryDashboardWidget.DATA_DUPLICATION);

  return (
    <ExecutiveDashboard
      dashboardId={DISCOVERY_DASHBOARD_ID}
      dashboardType={DashboardType.DISCOVERY}
      toolbarFilters={toolbarFilters}
      activeFilters={activeFilters}
      activeWidgetIds={activeWidgetIds}
      widgetSelectionItems={widgetSelectionItems}
      toolbarActions={toolbarActions}
      externalAppliedFilters={externalAppliedFilters}
      isSavedFiltersTabsDisplayed={true}
      isExportButtonDisplayed={true}
      isExportButtonDisabled={isWaitingForReport}
      onExportButtonClick={handleDownloadReport}
      isLoading={isLoading || !isSavedViewsReady}
      isEmptySystem={isEmptySystem}
    >
      <DiscoveryDashboardContext.Provider value={{ activeFilters, isEmptySystem }}>
        <WidgetsContainer>
          {isEmptySystem && (
            <WidgetWrapper>
              <EmptySystemWidget />
            </WidgetWrapper>
          )}
          {isActiveWidget(DiscoveryDashboardWidget.DATA_COVERAGE) && (
            <WidgetWrapper>
              <DataCoverageWidget
                onToggle={handleViewToggle(ReportWidgets.COVERAGE)}
                title={t('dataCoverageWidget.title')}
              />
            </WidgetWrapper>
          )}
          {isActiveWidget(DiscoveryDashboardWidget.DATA_RISK) && (
            <WidgetWrapper>
              <DataRiskWidget onToggle={handleViewToggle(ReportWidgets.RISKS)} title={t('dataRiskWidget.title')} />
            </WidgetWrapper>
          )}

          <SplitSection>
            {isActiveWidget(DiscoveryDashboardWidget.SENSITIVE_DATA) && (
              <WidgetWrapper isHalfWidth={isSplitSectionApply}>
                <SensitiveDataWidget title={t('sensitiveDataWidget.title')} />
              </WidgetWrapper>
            )}
            {isActiveWidget(DiscoveryDashboardWidget.DATA_DUPLICATION) && (
              <WidgetWrapper isHalfWidth={isSplitSectionApply}>
                <DataDuplicationWidget
                  onToggle={handleViewToggle(ReportWidgets.DUPLICATION)}
                  title={t('dataDuplicationWidget.title')}
                />
              </WidgetWrapper>
            )}
          </SplitSection>
        </WidgetsContainer>
      </DiscoveryDashboardContext.Provider>
    </ExecutiveDashboard>
  );
};
