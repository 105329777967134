import { CONFIG } from '../../../../config/common';
import { httpService } from '../../../services/httpService';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { sendDataExplorerQuickSearchApiRequestBiAnalytics } from '../services/analytics';
import { DataExplorerPreferences, RecentlySearchedItem, SearchResponse } from './types';

export const fetchQuickSearchResults = async (searchText: string): Promise<SearchResponse> => {
  try {
    const response = await httpService.post<SearchResponse>(`data-explorer/objects/quick`, {
      searchText,
      fieldsToProject: ['source_type', 'source', 'fullyQualifiedName', 'name', '_es_mongoId'],
    });
    sendDataExplorerQuickSearchApiRequestBiAnalytics({ searchText });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      data: {
        catalog: {
          count: 0,
          results: [],
        },
        datasource: {
          count: 0,
          results: [],
        },
        policy: {
          count: 0,
          results: [],
        },
      },
    };
  }
};

export const initDataExplorerPreferences = async (searchItem?: RecentlySearchedItem) => {
  const recentSearches = [];

  if (searchItem) {
    recentSearches.push(searchItem);
  }
  await userPreferencesService.add<DataExplorerPreferences>({
    preference: 'dataExplorer',
    data: {
      recentSearches,
    },
  });
};

export const getRecentlySearchedItems = async () => {
  try {
    const preferences = await userPreferencesService.get<DataExplorerPreferences>(CONFIG.states.DATA_EXPLORER);

    if (preferences) {
      return preferences.data?.recentSearches || [];
    }

    await initDataExplorerPreferences();
    return [];
  } catch (e) {
    console.error(`An error has occurred during fetching recently searched items: `, e);
    return [];
  }
};

export const updateRecentlySearchedPreferences = async (searchItem: RecentlySearchedItem) => {
  const LIMIT = 10;
  try {
    const preferences = await userPreferencesService.get<DataExplorerPreferences>(CONFIG.states.DATA_EXPLORER);
    if (preferences) {
      const baseItems = preferences.data?.recentSearches ?? [];
      const firstTenItems = baseItems.slice(0, LIMIT);
      const filteredItems = firstTenItems.sort((a, b) => a.created_at - b.created_at).slice(0, LIMIT);
      const updatedRecentlySearched = [searchItem, ...filteredItems];

      await userPreferencesService.update<DataExplorerPreferences>({
        ...preferences,
        data: {
          recentSearches: updatedRecentlySearched,
        },
      });
      return;
    }

    await initDataExplorerPreferences(searchItem);
  } catch (error) {
    console.error(`An error has occurred: ${error}`);
  }
};
