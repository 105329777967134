import {
  BigidClassifierTypeMetadataIcon,
  BigidClassifierTypeRegexIcon,
  BigidClassifierTypeCorrelationIcon,
  BigidAttributeIcon,
} from '@bigid-ui/icons';
import { ClassifierType, CuratedAttributeType } from '../curationService';
import React from 'react';

export const getAttributeTypeAvatar = (attributeType: CuratedAttributeType | ClassifierType) => {
  const BigidClassificationIcon = () => {
    return <BigidClassifierTypeRegexIcon size="large" />;
  };
  const ClassifierTypeMetadataIcon = () => {
    return <BigidClassifierTypeMetadataIcon size="large" />;
  };
  const ClassifierTypeCorrelationIcon = () => {
    return <BigidClassifierTypeCorrelationIcon size="large" />;
  };

  switch (attributeType) {
    case CuratedAttributeType.CLASSIFIER:
      return { icon: BigidClassificationIcon, name: CuratedAttributeType.CLASSIFIER };
    case ClassifierType.MD:
      return { icon: ClassifierTypeMetadataIcon, name: CuratedAttributeType.CLASSIFICATION_MD };
    case CuratedAttributeType.CORRELATION:
      return { icon: ClassifierTypeCorrelationIcon, name: CuratedAttributeType.CORRELATION };
    case ClassifierType.DOC:
      return { icon: BigidAttributeIcon, name: CuratedAttributeType.DOC_CLASSIFICATION };
    default:
      return { icon: BigidAttributeIcon, name: 'Attribute' };
  }
};
