import { iconSets } from '@bigid-ui/icons';

export enum RegulationFrameworkStatus {
  INDETERMINATE = 'indeterminate',
  SELECTED = 'selected',
  UNSELECTED = 'unselected',
}

export interface RegulationFramework {
  name: string;
  status: RegulationFrameworkStatus;
}

export interface ComplianceRegulation {
  id: never;
  _id: string;
  name: string;
  flagCode: keyof typeof iconSets.FlagIcons;
  description: string;
  region: string;
  countries: string[];
  states: string[];
  frameworks: string[];
  updated_at: string;
  enabled: boolean;
}
