import { styled } from '@mui/material';
import React, { FC } from 'react';
import { Message } from './Message';
import { BigidDataExplorerIllustration } from '@bigid-ui/icons';
import { BigidHeading3 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { DataExplorerAdvancedSearch } from '../DataExplorerAdvancedSearch/DataExplorerAdvancedSearch';

const Container = styled('div')`
  padding-top: 24px;
  width: 640px;
  flex-grow: 1;
  gap: 24px;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
`;

const IllustrationAndTitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled(BigidHeading3)`
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundSecondary};
  text-align: center;
`;

const SearchBarAndLinkWrapper = styled('div')`
  width: 640px;
  flex-grow: 1;
  gap: 11px;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
`;

type TopContentProps = {
  dataAid?: string;
};
export const TopContent: FC<TopContentProps> = () => {
  const { t } = useLocalTranslation('');

  return (
    <Container>
      <IllustrationAndTitleWrapper>
        <BigidDataExplorerIllustration />
        <TextWrapper>{t('semiTitle')}</TextWrapper>
      </IllustrationAndTitleWrapper>
      <SearchBarAndLinkWrapper>
        <DataExplorerAdvancedSearch />
        <Message />
      </SearchBarAndLinkWrapper>
    </Container>
  );
};
