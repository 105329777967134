import React, { FC, useCallback, useMemo, useState } from 'react';

import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidColorsV2,
  BigidDropdownOption,
  BigidHeading2,
  BigidToggleButton,
  BigidWidgetContainer,
} from '@bigid-ui/components';
import { BigidColumnChart } from '@bigid-ui/visualisation';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import { DeletionColors, WidgetProps, DeletionCategories, DeletionWidgetData } from '../PrivacyExecutiveDashboardTypes';
import { Menu, useDataSources, WidgetListWithTitle } from './helpers/HelperComponents';
import {
  DeletionWidgetWrapper,
  HorizontalDivider,
  TopAssetsWidgetWrapper,
  VerticalDivider,
} from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';

const TABS = [
  { label: 'Volume', value: 'volume', numberFormat: '#.0b' },
  { label: 'Amount', value: 'amount', numberFormat: '#.0a' },
];

export const DeletionWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const [currentTab, setCurrentTab] = useState(0);
  const { DataSourceDropdown, dsSelectedOptions } = useDataSources();
  const fetcherFn = useCallback(
    (filters: BigidAdvancedToolbarFilterUnion[]) => fetchDeletionWidgetData(filters, dsSelectedOptions),
    [dsSelectedOptions],
  );
  const { data, isLoading } = useFetchWidgetData(fetcherFn);
  const { list, chart = [] } = data?.[currentTab] || {};

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <>
      <BigidWidgetContainer
        content={
          <DeletionWidgetWrapper>
            <BigidColumnChart
              colorDataMap={DeletionColors}
              data={chart}
              mode="stacked"
              numberFormat={TABS[currentTab].numberFormat}
              legendProps={{ legendPosition: 'right' }}
              lineConfig={{
                name: DeletionCategories.DELETION_PROGRESS,
              }}
            />
            <HorizontalDivider />
            <TopAssetsWidgetWrapper>
              <div>
                <BigidHeading2>{list?.total}</BigidHeading2>
                <BigidBody1 color={BigidColorsV2.gray[500]}>{t('dataDeletion.subTitle')}</BigidBody1>
              </div>
              <VerticalDivider />
              <div>
                <WidgetListWithTitle
                  items={list?.data}
                  headerTitleOne={t('dataDeletion.headerTitleOne')}
                  headerTitleTwo={TABS[currentTab].label}
                  margin="0 0 0 -10px"
                />
              </div>
            </TopAssetsWidgetWrapper>
          </DeletionWidgetWrapper>
        }
        titleConfig={{
          title,
        }}
        actionsSlot={
          <>
            <DataSourceDropdown />
            <BigidToggleButton
              size="small"
              toggleButtons={TABS}
              initialSelected={TABS[currentTab].value}
              onChange={(_, newValue) => setCurrentTab(+(newValue === TABS[1].value))}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
      />
    </>
  );
};

const fetchDeletionWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
  dsSelectedOptions: BigidDropdownOption[],
): Promise<DeletionWidgetData[]> => {
  console.log('activeFilters', activeFilters);
  console.log('dsSelectedOptions', dsSelectedOptions);
  return new Promise(resolve => {
    setTimeout(resolve, 1000, data);
  });
};

const data = [
  {
    type: 'Volume',
    list: {
      total: '5TB',
      data: [
        { description: 'HR', value: '5TB' },
        { description: 'Marketing', value: '3TB' },
        { description: 'Accounting', value: '0.15TB' },
      ],
    },
    chart: [
      {
        category: 'Jun',
        Deleted: 5000000,
        'Deletion progress': 20000000,
        'Pending deletion': 100000000,
      },
      {
        category: 'Jul',
        Deleted: 10000000,
        'Deletion progress': 30000000,
        'Pending deletion': 90000000,
      },
      {
        category: 'Aug',
        Deleted: 30000000,
        'Deletion progress': 40000000,
        'Pending deletion': 70000000,
      },
      {
        category: 'Sep',
        Deleted: 50000000,
        'Deletion progress': 50000000,
        'Pending deletion': 50000000,
      },
      {
        category: 'Oct',
        Deleted: 70000000,
        'Deletion progress': 60000000,
        'Pending deletion': 30000000,
      },
      {
        category: 'Nov',
        Deleted: 90000000,
        'Deletion progress': 70000000,
        'Pending deletion': 10000000,
      },
    ],
  },
  {
    type: 'Amount',
    list: {
      total: '10TB',
      data: [
        { description: 'HR', value: '5TB' },
        { description: 'Marketing', value: '3TB' },
        { description: 'Accounting', value: '0.15TB' },
      ],
    },
    chart: [
      {
        category: 'Jun',
        Deleted: 0,
        'Deletion progress': 20000000,
        'Pending deletion': 100000000,
      },
      {
        category: 'Jul',
        Deleted: 10000000,
        'Deletion progress': 20000000,
        'Pending deletion': 90000000,
      },
      {
        category: 'Aug',
        Deleted: 30000000,
        'Deletion progress': 20000000,
        'Pending deletion': 70000000,
      },
      {
        category: 'Sep',
        Deleted: 50000000,
        'Deletion progress': 20000000,
        'Pending deletion': 50000000,
      },
      {
        category: 'Oct',
        Deleted: 70000000,
        'Deletion progress': 20000000,
        'Pending deletion': 30000000,
      },
      {
        category: 'Nov',
        Deleted: 90000000,
        'Deletion progress': 20000000,
        'Pending deletion': 10000000,
      },
    ],
  },
];
