import React, { FC } from 'react';
import { BigidColorsV2 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceGridRecord } from './DataSourcesLayout';
import { formatNumberCompact, formatBytes } from '../../../../utilities/numericDataConverter';
import { useLocalTranslation } from './translations';
import { isNil } from 'lodash';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';

interface DataSourcesTreeMapTooltipContentProps {
  dataAid?: string;
  dataSourceName: string;
  row: DataSourceGridRecord;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    fontSize: '14px',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '62px',
    height: '62px',
    borderRadius: '50%',
    border: `1px solid ${BigidColorsV2.gray[200]}`,
  },
  properties: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  property: {
    display: 'flex',
    alignItems: 'center',
  },
  propertyName: {
    marginRight: '4px',
  },
  propertyValue: {
    fontWeight: 'bold',
  },
});

export const DataSourcesTreeMapTooltipContent: FC<DataSourcesTreeMapTooltipContentProps> = ({
  dataAid = 'DataSourcesTreeMapTooltipContent',
  dataSourceName,
  row,
}) => {
  const classes = useStyles({});
  const { t } = useLocalTranslation('hotspot');
  const { id, sourceType, aggItemName, docCount, findings, size, sourceLocation } = row;

  return (
    <div className={classes.root} data-aid={generateDataAid(dataAid, [id])}>
      {dataSourceName && (
        <div className={classes.iconWrapper}>
          <div className={classes.icon}>
            <DataSourceIconByDsType
              data-aid={generateDataAid(dataAid, ['icon', sourceType])}
              dataSourceType={sourceType}
              width={42}
              height={42}
            />
          </div>
        </div>
      )}
      <div className={classes.properties}>
        {aggItemName && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'name'])}
            >
              {t('fields.name')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'value'])}
            >
              {aggItemName}
            </div>
          </div>
        )}
        {dataSourceName && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'sourceType', 'name'])}
            >
              {t('fields.type')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'sourceType', 'value'])}
            >
              {dataSourceName}
            </div>
          </div>
        )}
        {!isNil(docCount) && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'name'])}
            >
              {t('fields.numOfObjects')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'value'])}
            >
              {formatNumberCompact(docCount)}
            </div>
          </div>
        )}
        {!isNil(findings) && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'name'])}
            >
              {t('fields.numOfFindings')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'value'])}
            >
              {formatNumberCompact(findings)}
            </div>
          </div>
        )}
        {!isNil(size) && (
          <div className={classes.property}>
            <div style={{ marginRight: '4px' }} data-aid={generateDataAid(dataAid, [id, 'property', 'size', 'name'])}>
              {t('fields.volume')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'size', 'value'])}
            >
              {formatBytes(size, 2, true)}
            </div>
          </div>
        )}
        {sourceLocation && (
          <div className={classes.property}>
            <div
              style={{ marginRight: '4px' }}
              data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'name'])}
            >
              {t('fields.location')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'value'])}
            >
              {sourceLocation}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
