import { AdditionalProperties, Widget, WidgetFetcherPayload, DiscoveryDashboardTrackingEvents } from './types';
import {
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterUnion,
  BigidFieldFilter,
} from '@bigid-ui/components';
import {
  DATA_DISCOVERY,
  DimensionName,
  DISCOVERY,
  FilterField,
  GeneralDimensionValue,
  WIDGET_QUERY_FILTER_NAME,
  WidgetName,
} from './consts';
import { getDashboardDataFilterExpression } from './filterUtils';

export const createFetcherPayload = ({
  subType,
  widgetName,
  filter,
  gridFieldName,
}: {
  subType: string;
  widgetName?: string;
  filter?: string;
  gridFieldName?: string;
}): WidgetFetcherPayload => {
  return {
    group: DISCOVERY,
    type: DATA_DISCOVERY,
    subType,
    additionalProperties: getAdditionalProperties({ widgetName, filter, gridFieldName }),
  };
};

const getWidgetsData = (widgetName: string, gridFieldName: string): Widget[] => {
  const widgetAggregationName: Widget = { widgetAggregationName: widgetName, gridFieldName };
  return [widgetAggregationName];
};

const getAdditionalProperties = ({
  widgetName,
  filter,
  gridFieldName,
}: {
  widgetName: string;
  filter?: string;
  gridFieldName?: string;
}): AdditionalProperties => {
  const widgets = getWidgetsData(widgetName, gridFieldName);
  return {
    widgets,
    filter,
    origin: 'bigid-ui',
  };
};

export const findFilterById = (
  filters: BigidAdvancedToolbarFilterUnion[],
  id: string,
): BigidAdvancedToolbarFilterUnion => {
  return filters.find(filter => filter.id === id);
};

export const stringifiedNestedFilter = (filters: BigidAdvancedToolbarFilterUnion[]): string => {
  const tagsFilter = findFilterById(filters, FilterField.TAGS);
  if (tagsFilter == null) {
    return '';
  }
  const { options } = tagsFilter as BigidAdvancedToolbarDropdownFilter;
  const grouped: Record<string, Set<string>> = {};

  for (const item of options) {
    const key = item.parentId;
    const value = item.value.aggItemName;

    if (!grouped[key]) {
      grouped[key] = new Set();
    }
    grouped[key].add(value);
  }

  const result = Object.entries(grouped)
    .map(
      ([key, values]) =>
        `catalog_tag.${key} IN (${Array.from(values)
          .map(v => `"${v}"`)
          .join(', ')})`,
    )
    .join(' OR ');

  return result;
};

export const formatDate = (date: number) => new Intl.DateTimeFormat('en-GB').format(date).split('/').join('-');

export const getDimensionNameFilter = (dimensionName: DimensionName) => {
  return `${FilterField.DIMENSION_NAME} in ("${dimensionName}")`;
};

export const getDimensionValueFilter = (dimensionValue: GeneralDimensionValue) => {
  return `${FilterField.DIMENSION_VALUE} in ("${dimensionValue}")`;
};

export const getToggleBiEvent = (label: string, widgetId: string) => {
  return {
    eventType: DiscoveryDashboardTrackingEvents.DISCOVERY_DASHBOARD_WIDGET_VIEW_TOGGLE_CLICKED,
    eventData: {
      label,
      widgetId,
    },
  };
};

export const getWidgetQueryFilter = (
  filters: BigidAdvancedToolbarFilterUnion[],
  widgetName: WidgetName,
): BigidFieldFilter => ({
  field: WIDGET_QUERY_FILTER_NAME,
  operator: 'equal',
  value: getDashboardDataFilterExpression(filters ?? [], widgetName),
});
