import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import {
  BigidBody1,
  BigidLoader,
  EntityEvents,
  entityEventsEmitter,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import { BigidNoDataIllustration, BigidDeleteIcon, BigidEditIcon, BigidAddIcon } from '@bigid-ui/icons';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { COMPLIANCE_REGULATIONS_PERMISSIONS } from '@bigid/permissions';

import { isPermitted } from '../../../services/userPermissionsService';
import { notificationService } from '../../../services/notificationService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { analyticsService } from '../../../services/analyticsService';
import { $state } from '../../../services/angularServices';

import { fetchRegulationsData, updateRegulationStatus } from '../RegulationServices';
import { events, regulationColumnsConfig, regulationFiltersConfig } from '../constants';
import { REGULATIONS_NAMESPACE } from '../translations';
import { ComplianceRegulation } from '../types';
import { AddFrameworksModal } from './AddFrameworksModal';
import { regulationsEventEmitter, RegulationsEvents } from './regulationsEventEmitter';
import { AddRegulationsButton } from './AddRegulationsButton';

const GridWrapper = styled.div(({ theme }) => ({
  margin: '24px 0',
  border: `1px solid ${theme.palette.bigid.gray200}`,
  borderRadius: '4px',
  height: 'calc(100vh - 238px)',
  '> *': {
    height: '100%',
  },
}));

export const Regulations = () => {
  const { t } = useTranslation(REGULATIONS_NAMESPACE);
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: $state.current.name,
    initialGridColumns: regulationColumnsConfig,
  });
  const [regulationsUpdateQuery, setRegulationsUpdateQuery] = useState<BigidGridQueryComponents>();
  const [selectedRegulation, setSelectedRegulation] = useState<ComplianceRegulation>();
  const toolbarActions: ToolbarAction[] = isPermitted(COMPLIANCE_REGULATIONS_PERMISSIONS.EDIT.name)
    ? [
        {
          type: ToolbarActionType.TERTIARY,
          label: t('Labels.actions.manageRelatedFrameworks'),
          icon: BigidEditIcon,
          tooltip: t('Labels.actions.manageRelatedFrameworks'),
          isInline: true,
          show: ({ selectedRows }) => selectedRows.length > 0,
          execute: async ({ selectedRowIds, allSelected, filter, selectedRows }) => {
            if (allSelected) {
              setRegulationsUpdateQuery({ filter });
            } else {
              if (selectedRows.length === 1) {
                setSelectedRegulation(selectedRows[0]);
              }
              setRegulationsUpdateQuery({
                filter: [
                  {
                    field: '_id',
                    operator: 'in',
                    value: selectedRowIds,
                  },
                ],
              });
            }
            return {};
          },
        },
        {
          type: ToolbarActionType.TERTIARY,
          label: t('Labels.actions.remove'),
          icon: BigidDeleteIcon,
          tooltip: t('Labels.actions.remove'),
          isInline: true,
          show: ({ selectedRows }) => selectedRows.length > 0,
          execute: async ({ selectedRowIds, selectedRows, allSelected, filter }) => {
            try {
              const confirmed = await showConfirmationDialog({
                entityNamePlural: t('Labels.regulations'),
                entityNameSingular: t('Labels.regulation'),
                actionButtonName: t('Labels.actions.remove'),
                entitiesCount: selectedRowIds.length,
                maxWidth: 'sm',
                ContentComponent: (
                  <BigidBody1>
                    {t('Messages.removeConfirmation', {
                      name:
                        selectedRows.length === 1
                          ? `"${selectedRows[0].description}"`
                          : `${selectedRows.length} ${t('Labels.regulations').toLowerCase()}`,
                    })}
                  </BigidBody1>
                ),
              });
              if (!confirmed) return {};

              await updateRegulationStatus(
                {
                  filter: allSelected
                    ? filter
                    : [
                        {
                          field: '_id',
                          operator: 'in',
                          value: selectedRowIds,
                        },
                      ],
                },
                false,
              );

              regulationsEventEmitter.emit(RegulationsEvents.REGULATIONS_REMOVED);
              entityEventsEmitter.emit(EntityEvents.RELOAD);
              notificationService.success(t('Success.requests.removeRegulations'), { duration: 3000 });
              return { shouldGridReload: true, shouldClearSelection: true };
            } catch (e) {
              console.error(e);
              notificationService.error(t('Error.requests.removeRegulations'), { duration: 3000 });
            }
            return {};
          },
        },
      ]
    : undefined;
  const config: BigidGridWithToolbarProps<ComplianceRegulation> = {
    showFilteringControls: true,
    rowClickShouldKeepSelection: true,
    showSelectAllColumnChooser: true,
    entityName: t('Labels.regulations')?.toLowerCase(),
    filterToolbarConfig: regulationFiltersConfig,
    toolbarActions,
    noDataContent: (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="calc(100vw - 110px)"
        height="calc(100vh - 380px)"
        position="sticky"
        left="0"
      >
        <BigidNoDataIllustration />
        <BigidBody1 mb={2}>{t('Messages.noRegulationsAddedYet')}</BigidBody1>
        <AddRegulationsButton />
      </Stack>
    ),
    fetchData: queryComponents =>
      fetchRegulationsData({
        ...queryComponents,
        filter: queryComponents.filter.concat({
          field: 'enabled',
          operator: 'equal',
          value: true,
        }),
      }).catch(err => {
        console.error(err);
        notificationService.error(t('Error.requests.fetchRegulations'));
        return {
          totalCount: 0,
          data: [],
        };
      }),
    pageSize: 50,
    columns: gridColumns,
    defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
    onGridStateChange: gridState =>
      updatePreferences({
        gridState: omit(gridState, 'filter'),
      }),
  };

  useEffect(() => {
    analyticsService.trackManualEvent(events.REGULATIONS_TAB_OPEN);
  }, []);

  return isReady ? (
    <GridWrapper>
      {!!regulationsUpdateQuery && (
        <AddFrameworksModal
          isOpen
          onClose={() => {
            setRegulationsUpdateQuery(null);
            setSelectedRegulation(null);
          }}
          regulationsQuery={regulationsUpdateQuery}
          selectedRegulation={selectedRegulation}
        />
      )}
      <BigidGridWithToolbar {...config} />
    </GridWrapper>
  ) : (
    <BigidLoader />
  );
};
