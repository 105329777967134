import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidBody1, BigidCarousel, BigidLink } from '@bigid-ui/components';
import { carouselItems } from '../eaProgramModalService';
import { useLocalTranslation } from '../translations';

const EA_PROGRAM_FEATURES_INTRO = 'EaProgramFeaturesIntroSubView';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 8px 16px 8px',
  fontSize: 14,
  gap: '24px',
});

const LinkableItem = styled('div')`
  > a {
    padding-left: 6px;
  }
`;

const CarouselContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.bigid.gray150,
  color: theme.palette.bigid.gray700,
  borderRadius: 8,
  padding: 24,
  marginBottom: 24,
  minHeight: 484,
}));

const CarouselItemTitle = styled('div')({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '24px',
  paddingTop: 12,
  paddingBottom: 8,
});

const CarouselBody = styled('div')({
  fontSize: 14,
});

export const EaFeaturesIntro: FC = () => {
  const [currentCarouselItem, setCurrentCarouselItem] = useState(0);
  const { t } = useLocalTranslation();

  const onItemChange = (newItemIndex: number) => {
    setCurrentCarouselItem(newItemIndex);
  };

  return (
    <>
      <MainContainer data-aid={generateDataAid(EA_PROGRAM_FEATURES_INTRO, ['main-container'])}>
        <LinkableItem>{t('teaserText')}</LinkableItem>
      </MainContainer>
      <CarouselContainer>
        <BigidCarousel
          disablePreview={true}
          slidesAutoplay={true}
          onChange={onItemChange}
          mediaHeight={300}
          items={carouselItems.map(({ url, type }) => ({ url, type }))}
          slideIntervalDuration={8000}
          animationDuration={1500}
        />
        <CarouselItemTitle>{carouselItems[currentCarouselItem].title}</CarouselItemTitle>
        <CarouselBody>
          <LinkableItem>
            <BigidBody1>{carouselItems[currentCarouselItem].body}</BigidBody1>
            <BigidBody1>
              <BigidLink
                underline="always"
                href={carouselItems[currentCarouselItem].learnMoreUrl}
                shouldOpenNewTab={true}
                text={t('learnMoreLink')}
              />
              .
            </BigidBody1>
          </LinkableItem>
        </CarouselBody>
      </CarouselContainer>
    </>
  );
};
