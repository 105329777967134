import React, { FC, useContext, useEffect, useState } from 'react';
import { BigidInfoIcon, BigidNoDataIllustration } from '@bigid-ui/icons';
import { BigidBody1, BigidWidgetContainer } from '@bigid-ui/components';
import { SensitiveDataChart } from './charts/SensitiveDataChart';
import { DiscoveryDashboardContext } from '../DiscoveryDashboardContext';
import { requestDashboardData } from '../discoveryDashboardServices';
import { DashboardSubType, WidgetName } from '../consts';
import { useLocalTranslation } from '../translations';

const NUMBER_OF_POLICIES = 8;

export interface SensitiveDataWidgetProps {
  title: string;
}

export interface SensitiveData {
  label: string;
  count: number;
}

export const SensitiveDataWidget: FC<SensitiveDataWidgetProps> = ({ title }) => {
  const [sensitiveData, setSensitiveData] = useState<SensitiveData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmptyWidgetData, setIsEmptyWidgetData] = useState<boolean>(false);

  const { activeFilters, isEmptySystem } = useContext(DiscoveryDashboardContext);

  const { t } = useLocalTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await requestDashboardData(
          DashboardSubType.SENSITIVE_DATA,
          WidgetName.SENSITIVE_DATA,
          activeFilters,
        );

        const fetchedData = response?.data?.dashboardData;
        if (fetchedData) {
          const processedData = (fetchedData as SensitiveData[])
            .sort((a, b) => b.count - a.count)
            .slice(0, NUMBER_OF_POLICIES);
          setSensitiveData(processedData);
          setIsEmptyWidgetData(false);
        } else {
          setIsEmptyWidgetData(true);
        }
      } catch (e) {
        setSensitiveData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeFilters]);

  return (
    <BigidWidgetContainer
      content={<SensitiveDataChart data={sensitiveData} isLoading={isLoading} />}
      contentHeight="300px"
      titleConfig={{
        title,
        iconAfter: {
          icon: BigidInfoIcon,
          tooltip: (
            <>
              <BigidBody1 display="inline" fontWeight="bold">
                {title}
              </BigidBody1>
              <BigidBody1 display="inline">{t('sensitiveDataWidget.tooltip')}</BigidBody1>{' '}
            </>
          ),
        },
      }}
      emptyStateConfig={{
        isEmpty: isEmptySystem || isEmptyWidgetData,
        showEmptyStateWithFilter: isEmptyWidgetData,
        illustration: BigidNoDataIllustration,
        description: <BigidBody1>{t('emptyStateWidget.noData')}</BigidBody1>,
      }}
    />
  );
};
