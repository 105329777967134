import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  BigidDialog,
  BigidDialogProps,
  BigidFormValues,
  BigidLoader,
  PrimaryButton,
  SecondaryButton,
  StyledButtonType,
  TertiaryButton,
} from '@bigid-ui/components';
import { EmailEditorForm } from './EmailEditorForm/EmailEditorForm';
import {
  EmailEditorOptions,
  EmailTemplateData,
  EmailTemplateType,
  generateEmailTemplatePayload,
  getInitialTemplate,
  getTemplateData,
  getTemplateTypes,
  openEmailEditor,
  saveTemplate,
} from './emailEditorService';
import { emailEditorEventEmitter, EmailEditorEvents } from '../../services/eventEmitters/emailEditorEvents';
import { noop } from 'lodash';
import { useTemplateDetailsFields } from './useTemplateDetailsFields';
import { useEmailContentFields } from './useEmailContentFields';
import { useLocalTranslation, getFixedT } from './translations';
import styled from '@emotion/styled';
import { notificationService } from '../../services/notificationService';
import makeStyles from '@mui/styles/makeStyles';
import { useValidator } from '../../hooks/useValidator';
import { getValidationRules, ValidationInfo } from './config';

type EmailEditorProps = {
  dataAid?: string;
};

const LoaderContainer = styled('div')({
  height: 650,
  display: 'flex',
});

const useStyles = makeStyles(theme => ({
  editorContainer: {
    '& .MuiDialogContent-root': {
      borderBottom: `solid 1px ${theme.palette.bigid.gray200}`,
    },
  },
}));

const defaultOptions: EmailEditorOptions = {
  title: '',
  onClose: noop,
};

const NAME_TAKEN_STATUS_CODE = 409;
const tMessages = getFixedT('messages');

export const EmailEditor: FC<EmailEditorProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [emailTypes, setEmailTypes] = useState<EmailTemplateType[]>([]);
  const [options, setOptions] = useState<EmailEditorOptions>(defaultOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveInAction, setIsSaveInAction] = useState<boolean>(false);
  const [isEmailContentLoading, setIsEmailContentLoading] = useState<boolean>(false);
  const [editStoredTemplateMode, setEditStoredTemplateMode] = useState<boolean>(false);
  const [showSaveBeforeClose, setShowSaveBeforeClose] = useState(false);
  const [templateData, setTemplateData] = useState<EmailTemplateData>();
  const { selectedCategory, templateDetailsFormProps, templateDetailsFormControls, resetTemplateDetailsFields } =
    useTemplateDetailsFields(templateData, editStoredTemplateMode, emailTypes, options?.viewOnlyMode);
  const {
    emailContentFormProps,
    emailContentFormControls,
    resetContentFields,
    sectionFields,
    clearErrors,
    isFormLoading,
  } = useEmailContentFields(templateData, options?.viewOnlyMode);
  const { t } = useLocalTranslation();
  const classes = useStyles({});
  const { viewOnlyMode = false } = options ?? {};
  const shouldValidateForm = viewOnlyMode ? false : emailContentFormControls?.current?.formTouched ?? true;

  const rules = getValidationRules(emailContentFormControls, templateData);
  const { validate } = useValidator<BigidFormValues>(rules, [templateData]);

  const handleSetErrors = (errors: ValidationInfo) => {
    clearErrors();
    errors.forEach(({ name, message }) => emailContentFormControls.current.setFieldError(name, message));
  };

  const handleValidateForm = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __valuesCounter, ...values } = emailContentFormControls.current.getValues();
    const enabled = Object.entries(values ?? {}).reduce((acc, [field, value]) => {
      const { disabled: isDisabled } = emailContentFormControls.current.getFieldProps(field);
      return isDisabled ? { ...acc } : { ...acc, [field]: value };
    }, {});

    const { isValid, info } = await validate(
      enabled,
      ['NO_INVALID_SUBJECT', 'NO_UNFILLED_LINKS', 'NO_UNFILLED_BUTTONS', 'NO_INCORRECT_IMAGE_URL', 'NO_INCORRECT_URLS'],
      [],
    );

    !isValid ? handleSetErrors(info as ValidationInfo) : clearErrors();
    return isValid;
  };

  const handleExit = () => {
    setOptions(defaultOptions);
    setTemplateData(null);
    setEditStoredTemplateMode(false);
    resetTemplateDetailsFields();
    resetContentFields();
  };

  const handleCloseWithoutSaving = () => {
    handleClose();
  };

  const handleSaveBeforeClose = () => {
    setShowSaveBeforeClose(false);
    handleSave();
  };

  const handleSave = async () => {
    const isValid = await handleValidateForm();

    isValid &&
      templateDetailsFormControls.current.validateAndSubmit(async (templateDetailsValues: BigidFormValues) => {
        emailContentFormControls.current.validateAndSubmit(async (emailContentValues: BigidFormValues) => {
          const payload = generateEmailTemplatePayload(
            templateData,
            sectionFields,
            emailContentFormProps.fields,
            templateDetailsValues,
            emailContentValues,
          );
          try {
            setIsSaveInAction(true);
            const { successMessage, name, id } = await saveTemplate(payload, options?.templateId);
            notificationService.success(successMessage);
            handleClose({ name, id });
          } catch (e) {
            if (e?.response?.status === NAME_TAKEN_STATUS_CODE) {
              templateDetailsFormControls.current.setFieldError(
                'templateName',
                tMessages('templateNameAlreadyTaken') as string,
              );
            } else {
              const errorMessage = e?.message ? `, ${tMessages('errorMessage')} ${e.message}` : '.';
              notificationService.error(tMessages('saveTemplateError', { errorMessage }));
            }
          } finally {
            setIsSaveInAction(false);
          }
        });
      });
  };

  const handleClose = useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (args?: { [key: string]: any }) => {
      setIsOpen(false);
      options?.onClose?.(args);
    },
    [options],
  );

  const handleCloseEmailTemplate = () => {
    const isTouched = emailContentFormControls?.current?.formTouched;

    isTouched && !viewOnlyMode ? setShowSaveBeforeClose(true) : handleClose();
  };

  useEffect(() => {
    function handleEvent(event: [EmailEditorOptions]) {
      const [options] = event;
      setOptions({ ...options });
      if (options?.templateId) {
        setIsLoading(true);
        setEditStoredTemplateMode(true);
      }
      setIsOpen(true);
    }

    const unregisterOpen = emailEditorEventEmitter.addEventListener(EmailEditorEvents.OPEN_EMAIL_EDITOR, handleEvent);
    const unregisterClose = emailEditorEventEmitter.addEventListener(EmailEditorEvents.CLOSE_EMAIL_EDITOR, handleClose);

    return function cleanup() {
      unregisterOpen();
      unregisterClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchEmailTypes = async () => {
      try {
        const types = await getTemplateTypes();
        setEmailTypes(types);
      } catch (e) {
        const errorMessage = e?.message ? `, ${tMessages('errorMessage')} ${e.message}` : '.';
        notificationService.error(tMessages('fetchTemplateError', { errorMessage }));
        handleClose();
      }
    };
    if (!emailTypes.length) {
      fetchEmailTypes();
    }
  }, [emailTypes, handleClose]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const newTemplateData = await getTemplateData(options.templateId);
        setTemplateData(newTemplateData);
      } catch (e) {
        const errorMessage = e?.message ? `, ${tMessages('errorMessage')} ${e.message}` : '.';
        notificationService.error(tMessages('fetchTemplateError', { errorMessage }));
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    if (options?.templateId) {
      fetchInitialData();
    }
  }, [handleClose, options]);

  useEffect(() => {
    const fetchInitialEmptyTemplateData = async () => {
      try {
        setIsEmailContentLoading(true);
        resetContentFields();
        const newTemplateData = await getInitialTemplate(selectedCategory);
        setTemplateData(newTemplateData);
      } catch (e) {
        const errorMessage = e?.message ? `, error message: ${e.message}` : '.';
        notificationService.error(tMessages('fetchTemplateError', { errorMessage }));
        handleClose();
      } finally {
        setIsEmailContentLoading(false);
      }
    };
    if (selectedCategory && !editStoredTemplateMode) {
      fetchInitialEmptyTemplateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStoredTemplateMode, selectedCategory]);

  const saveBeforeCloseActions = [
    {
      component: SecondaryButton,
      dataAid: `EmailEditor-close`,
      isClose: true,
      onClick: handleCloseWithoutSaving,
      text: t('buttonLables.closeWithoutSaving'),
    },
    {
      component: PrimaryButton,
      dataAid: 'EmailEditor-save',
      disabled: isSaveInAction,
      isClose: false,
      onClick: handleSaveBeforeClose,
      text: isSaveInAction ? tMessages('savingInProgress') : t('buttonLables.save'),
    },
  ];

  const dialogConfig: BigidDialogProps = {
    title: options?.title || t('dialogTitle'),
    isOpen,
    hideContentPadding: true,
    maxWidth: 'xl',
    muiOverrides: { disableEnforceFocus: true, className: classes.editorContainer },
    onClose: handleCloseEmailTemplate,
    onExited: handleExit,
    buttons: [
      ...(!isLoading
        ? [
            {
              component: (props: StyledButtonType) => <TertiaryButton {...props} dataAid="EmailEditor-cancel-action" />,
              onClick: handleClose,
              text: t('buttonLables.cancel'),
            },
            ...(!options?.viewOnlyMode
              ? [
                  {
                    component: (props: StyledButtonType) => (
                      <PrimaryButton {...props} data-aid="EmailEditor-save-action" />
                    ),
                    onClick: handleSave,
                    text: isSaveInAction ? tMessages('savingInProgress') : t('buttonLables.save'),
                    disabled: isSaveInAction || isLoading || isFormLoading,
                    dataAid: 'create-email-template-save-button',
                  },
                ]
              : []),
          ]
        : []),
    ],
  };

  return (
    <>
      <BigidDialog {...dialogConfig}>
        {isLoading ? (
          <LoaderContainer>
            <BigidLoader />
          </LoaderContainer>
        ) : (
          <EmailEditorForm
            emailContentFormProps={emailContentFormProps}
            templateDetailsFormProps={templateDetailsFormProps}
            selectedCategory={selectedCategory}
            templateId={options?.templateId}
            isEmailContentLoading={isEmailContentLoading}
            sectionFields={sectionFields}
            validateBeforeSend={shouldValidateForm}
            onValidate={handleValidateForm}
          />
        )}
      </BigidDialog>
      {showSaveBeforeClose && (
        <BigidDialog
          title={t('saveBeforeCloseTitle')}
          isOpen={showSaveBeforeClose}
          buttons={saveBeforeCloseActions}
          onClose={() => setShowSaveBeforeClose(false)}
        >
          {t('saveBeforeCloseContent')}
        </BigidDialog>
      )}
    </>
  );
};
