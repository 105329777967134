import React from 'react';
import { BigidToggleButton, BigidToggleButtonProps } from '@bigid-ui/components';
import { BigidQueryLanguageIcon, BigidTextIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { SearchMode } from '../types';

type Props = {
  mode: string;
  onChange: (value: SearchMode) => void;
  dataAid: string;
  isDisabled: boolean;
};

export const ModeToggle = ({ mode, onChange, dataAid, isDisabled }: Props) => {
  const toggleButtons: BigidToggleButtonProps[] = [
    { value: 'searchText', icon: BigidTextIcon, isDisabled },
    { value: 'queryFilter', icon: BigidQueryLanguageIcon, isDisabled },
  ];

  const handleModeChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    onChange(value as SearchMode);
  };

  return (
    <BigidToggleButton
      toggleButtons={toggleButtons}
      size="small"
      initialSelected={mode}
      onChange={handleModeChange}
      dataAid={generateDataAid(dataAid, ['mode', 'toggle'])}
    />
  );
};
