import React, { FC, ReactNode } from 'react';
import { EntityType } from '../../types';
import { styled } from '@mui/material/styles';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { mapEntityTypeToIcon } from './utils';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../translations';
import { useTheme } from '@mui/styles';
import { BigidChevronRightIcon } from '@bigid-ui/icons';

const SectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const HeaderSection = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ShowAllSection = styled('a')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ListContainer = styled('ul')`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

type EntitySectionProps = {
  sectionName: EntityType;
  listItems: ReactNode[];
  handleShowAllClick: () => void;
  count?: number;
  dataAid?: string;
};
export const EntitySection: FC<EntitySectionProps> = ({
  sectionName,
  count,
  listItems,
  handleShowAllClick,
  dataAid = 'EntitySection',
}) => {
  const { t } = useLocalTranslation('QuickSearchResults.Sections');
  const theme = useTheme();
  const Icon = mapEntityTypeToIcon(sectionName);

  const shouldShowViewAll = count > 0;

  const title = t(`Titles.${sectionName}`);
  return (
    <SectionContainer data-aid={generateDataAid(dataAid, [sectionName, 'container'])}>
      <SectionHeader data-aid={generateDataAid(dataAid, [sectionName, 'header'])}>
        <HeaderSection>
          <Icon color={theme.vars.tokens.bigid.foregroundTertiary} width={16} />
          <BigidBody1
            data-aid={generateDataAid(dataAid, [sectionName, 'title'])}
            color={theme.vars.tokens.bigid.foregroundTertiary}
            size="small"
            fontWeight={600}
          >
            {title}
          </BigidBody1>
        </HeaderSection>

        {shouldShowViewAll && (
          <ShowAllSection onClick={handleShowAllClick}>
            <BigidBody1 size="small" data-aid={generateDataAid(dataAid, [sectionName, 'view-all'])}>
              <BigidLink
                text={t('viewAll', { itemsCount: formatNumberCompact(count) })}
                onClick={handleShowAllClick}
                underline="hover"
              />
            </BigidBody1>
            <BigidChevronRightIcon color={theme.vars.tokens.bigid.textLink} width={16} />
          </ShowAllSection>
        )}
      </SectionHeader>
      <ListContainer data-aid={generateDataAid(dataAid, [sectionName, 'list'])}>{listItems}</ListContainer>
    </SectionContainer>
  );
};
