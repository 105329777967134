import React from 'react';
import { BigidBody1, BigidHeading6, BigidIcon } from '@bigid-ui/components';
import { ScanDetailRowContent } from './Content';
import { ScannerField } from '../ScanInsightsDetailRow/ScannerField';
import { ScannerIdField } from '../ScanInsightsDetailRow/scannerIdField';
import styled from '@emotion/styled';
import { uniq } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { ErrorMessageBox } from './ErrorMessagreBox';
import { CompletedParentScanState, ScanState } from '../../ScanTypes';

const DetailsContainer = styled('div')`
  & > *:not(:last-child) {
    margin-bottom: 18px;
  }
`;

const ScannerRow = styled('div')`
  display: flex;
  align-items: center;
  & [data-aid='BigidAdditionalItemsChip'] {
    width: 24px;
    margin-left: 12px;
  }
  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 36px);
  }
`;

const ErrorMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InfoText = styled('div')`
  margin-right: 12px;
`;

const SCANNER_ID = 'Scanner ID';
const ENUMERATION_SCANNER_ID = 'Enumeration Scanner ID';

interface DetailsProps {
  scanDetailsToShow: ScanDetailRowContent[];
  scannersIds: string[];
  isDsScan: boolean;
  enumerationScannerId: string;
  errorMessages?: string[];
  errorMessage?: string;
  dataAid?: string;
  scanId: string;
  state?: ScanState;
}

export const Details = ({
  scanDetailsToShow,
  scannersIds,
  isDsScan,
  enumerationScannerId,
  errorMessages = [],
  errorMessage = '',
  dataAid = 'Details',
  scanId,
  state,
}: DetailsProps) => {
  return (
    <DetailsContainer>
      {scanDetailsToShow.map(({ title, info, icon }, index) => (
        <div key={index} data-aid={generateDataAid(dataAid, [title])}>
          <BigidHeading6>{title}</BigidHeading6>
          <ScannerRow>
            <InfoText>{info}</InfoText>
            {icon && <BigidIcon icon={icon} />}
          </ScannerRow>
        </div>
      ))}
      <ScannerIdField
        scanId={scanId}
        scannersIds={scannersIds}
        title={SCANNER_ID}
        dataAid={generateDataAid(dataAid, ['scannerId'])}
      />
      {isDsScan && (
        <ScannerField
          scannersIds={[enumerationScannerId]}
          title={ENUMERATION_SCANNER_ID}
          dataAid={generateDataAid(dataAid, ['enumerationScannerId'])}
        />
      )}
      {state !== CompletedParentScanState.COMPLETED && (
        <>
          {errorMessages.length > 0 ? (
            <ErrorMessageContainer data-aid={generateDataAid(dataAid, ['errorMessages'])}>
              {uniq(errorMessages).map((errorMessage, index) => (
                <ErrorMessageBox key={index} errorMessage={errorMessage} />
              ))}
            </ErrorMessageContainer>
          ) : (
            <ErrorMessageContainer data-aid={generateDataAid(dataAid, ['errorMessages'])}>
              {errorMessage && <ErrorMessageBox errorMessage={errorMessage} />}
            </ErrorMessageContainer>
          )}
        </>
      )}
    </DetailsContainer>
  );
};
