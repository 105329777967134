import React, { useRef, useState } from 'react';
import { BigidButtonIconVariant, BigidMenu, BigidMenuItemProps, BigidSplitButton } from '@bigid-ui/components';
import styled from '@emotion/styled';

export type BigidSplitMenuButtonMenuActions = BigidMenuItemProps;

export type BigidSplitMenuButtonProps = {
  dataAid?: string;
  actions?: BigidSplitMenuButtonMenuActions[];
  onClick?: () => void;
  onClickIconButton?: () => void;
  buttonType?: BigidButtonIconVariant;
};

const BigidSplitMenuButtonWrapper = styled.div`
  & button:nth-of-type(2) {
    max-width: 32px;
  }
`;

export const BigidSplitMenuButton = ({
  dataAid = 'BigidSplitMenuButton',
  actions,
  onClick,
  onClickIconButton,
  buttonType,
  ...rest
}: BigidSplitMenuButtonProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleExpandMenu = () => {
    setMenuOpen(true);
    onClickIconButton?.();
  };

  return (
    <BigidSplitMenuButtonWrapper ref={ref}>
      <BigidSplitButton
        onClickButton={onClick}
        onClickIconButton={handleExpandMenu}
        size="medium"
        dataAid={dataAid}
        {...rest}
        type={buttonType || 'secondary'}
      />
      <BigidMenu
        anchorEl={ref?.current}
        open={isMenuOpen}
        items={actions ?? []}
        onMenuClose={() => setMenuOpen(false)}
        onItemSelect={() => setMenuOpen(false)}
        paperStyle={{
          marginTop: '4px',
        }}
        width={200}
      />
    </BigidSplitMenuButtonWrapper>
  );
};
