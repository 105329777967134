import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { BigidAdvancedToolbarFilterUnion, BigidMenuItemProps } from '@bigid-ui/components';
import { fetchSeverityLevels } from './services/dashboardService';
import { notificationService } from '../../services/notificationService';
import { SeverityColorMapper } from './PrivacyExecutiveDashboardTypes';
import { createSeverityDropdownItems } from './widgets/helpers/HelperComponents';

interface PrivacyExecutiveDashboardContextInterface {
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  activeWidgetIds: string[];
  severityDropdownItems: BigidMenuItemProps[];
  severityColorMapper: SeverityColorMapper;
}

interface PrivacyDashboardProps
  extends PropsWithChildren,
    Omit<PrivacyExecutiveDashboardContextInterface, 'severityDropdownItems' | 'severityColorMapper'> {}

const PrivacyExecutiveDashboardContext = createContext<PrivacyExecutiveDashboardContextInterface>(null);

export const usePrivacyExecutiveDashboardContext = () => {
  const context = useContext(PrivacyExecutiveDashboardContext);

  if (context === undefined) {
    throw new Error('usePrivacyExecutiveDashboardContext was used outside of its Provider');
  }

  return context;
};

export const PrivacyExecutiveDashboardContextProvider = ({
  children,
  activeFilters,
  activeWidgetIds,
}: PrivacyDashboardProps) => {
  const [severityDropdownItems, setSeverityDropdownItems] = useState<BigidMenuItemProps[]>();
  const [severityColorMapper, setSeverityColorMapper] = useState<SeverityColorMapper>();

  useEffect(() => {
    (async () => {
      try {
        const severityLevels = await fetchSeverityLevels();
        setSeverityDropdownItems(createSeverityDropdownItems(severityLevels));
        setSeverityColorMapper(
          severityLevels?.reduce((acc, level) => {
            acc[level.severity] = level.color.replace(/(\s+)/gi, ',');
            return acc;
          }, {} as SeverityColorMapper),
        );
      } catch (err) {
        notificationService.error(`Failed to fetch severity levels`);
        console.error(`Failed to fetch severity levels: ${JSON.stringify(err?.response)}`);
        setSeverityColorMapper({});
      }
    })();
  }, []);

  const contextValue = useMemo(
    () => ({
      activeFilters,
      activeWidgetIds,
      severityDropdownItems,
      severityColorMapper,
    }),
    [severityDropdownItems, activeFilters, activeWidgetIds, severityColorMapper],
  );

  return (
    <PrivacyExecutiveDashboardContext.Provider value={contextValue}>
      {children}
    </PrivacyExecutiveDashboardContext.Provider>
  );
};
