import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Operators, parseGraphQueryNodes, RulesStructNode } from '@bigid/query-object-serialization';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidHotspotsChartConfig, BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import {
  ActionData,
  BigidSidePanel,
  BigidSkeletonGenerator,
  EntityEvents,
  entityEventsEmitter,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridDataFetchResult,
  BigidGridProps,
  BigidGridRow,
  FetchTotalCount,
} from '@bigid-ui/grid';
import { BigidDataSourceIcon, BigidHotspotIcon, BigidLocationIcon, BigidTableColumnsIcon } from '@bigid-ui/icons';
import {
  AggregationFilterOperand,
  AggregationItemName,
  AggregationType,
  SourcesGridAggregationItem,
} from '../../catalogDiscoveryTypes';
import {
  getAggregatedData,
  getAggregatedDataCountCancellable,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
} from '../../catalogDiscoveryService';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { useLocalTranslation } from './translations';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { DataSourcesTreeMapToolbar, pageSizeAvailableOptions } from './DataSourcesTreeMapToolbar';
import { DataSourceSidePanel } from './DataSourceSidePanel/DataSourceSidePanel';
import { v4 as uuid } from 'uuid';
import { mapGridSortingToApiFormat } from '../../utils/common';
import { LayoutBasedWidgetWrapper } from '../../utils/LayoutBasedWidgetWrapper';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { getDataSourceTypeDisplayName, hasNewTemplate } from '../../../../utilities/dataSourcesUtils';
import { gridWidgetSkeletonConfig } from '../../config/skeleton';
import { GridBasedWidgetNoData } from '../../utils/GridBasedWidgetNoData/GridBasedWidgetNoData';
import { analyticsService } from '../../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../../config/BigIdBiEvents';
import { CatalogDiscoveryWidget } from '../../config/widgets';
import { isEqual } from 'lodash';
import { DataSourceLayoutNameCell } from './DataSourceLayoutNameCell';
import { DataSourcesTreeMapTooltipContent } from './DataSourcesTreeMapTooltipContent';
import { DataSourceLayoutWorldMap } from './DataSourceLayoutWorldMap/DataSourceLayoutWorldMap';
import { getDataSourcesLayoutRecordId } from './dataSourcesLayoutUtils';
import { BigidTreemapChartOption } from '@bigid-ui/visualisation';
import { renderToStaticMarkup } from 'react-dom/server';

export interface DataSourcesLayoutProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'filter' | 'onWidgetFilterChange' | 'onDataFetchStatusChange'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
}

export type DataSourceGridRecord = BigidGridRow & SourcesGridAggregationItem;

const layoutContentTypeGridId = 'grid';
const layoutContentTypeHeatMapId = 'heatMap';
const layoutContentTypeWorldMapId = 'worldMap';

export const DataSourcesLayout: FC<DataSourcesLayoutProps> = memo(
  ({
    dataAid = 'DataSourcesLayout',
    dataTourId = 'DataSourcesLayout',
    query,
    filter,
    onWidgetFilterChange,
    onDataFetchStatusChange,
    isPageInitialised,
  }) => {
    const { fetchDataSourcesCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const [previewedDataSourceName, setPreviewedDataSourceName] = useState<AggregationItemName>();
    const { layoutId } = useMemo(
      () => ({
        layoutId: `${dataAid}-${uuid()}`,
      }),
      [dataAid],
    );

    useEffect(() => {
      if (typeof query === 'string' && isPageInitialised) {
        entityEventsEmitter.emit(EntityEvents.RELOAD_WITH_FILTER, {
          entityId: layoutId,
          payload: {
            searchQuery: query,
            filter,
          },
        });
      }
    }, [filter, isPageInitialised, layoutId, query]);

    const gridConfig: BigidGridProps<DataSourceGridRecord> = useMemo(
      () => ({
        dataAid,
        showSortingControls: true,
        showSelectionColumn: true,
        showSelectionCheckboxes: true,
        rowClickShouldKeepSelection: true,
        showSelectAll: false,
        noDataContent: <GridBasedWidgetNoData dataAid={generateDataAid(dataAid, ['no-data'])} />,
        onRowClick: ({ aggItemName }: DataSourceGridRecord) => {
          setPreviewedDataSourceName(aggItemName);
        },
        defaultSorting: [
          {
            field: 'docCount',
            order: 'desc',
          },
        ],
        columns: [
          {
            title: t('grid.columns.name'),
            name: 'aggItemName',
            type: BigidGridColumnTypes.CUSTOM,
            getCellValue: row => {
              return <DataSourceLayoutNameCell row={row} />;
            },
            width: 256,
            sortingEnabled: false,
          },
          {
            title: t('grid.columns.type'),
            name: 'sourceType',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ displayName }) => displayName,
            sortingEnabled: false,
            width: 144,
          },
          {
            title: t('grid.columns.numOfObjects'),
            name: 'docCount',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ docCount }) => formatNumberCompact(docCount),
            width: 160,
          },
          {
            title: t('grid.columns.numOfFindings'),
            name: 'findings',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ findings }) => formatNumberCompact(findings),
            width: 200,
          },
          {
            title: t('grid.columns.attributes'),
            name: 'attributeCount',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ attributeCount }) => formatNumberCompact(attributeCount),
            width: 160,
          },
          {
            title: t('grid.columns.location'),
            name: 'location',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ sourceLocation }) => sourceLocation,
            sortingEnabled: false,
            width: 160,
          },
        ],
      }),
      [dataAid, t],
    );

    const hotspotsChartConfig: BigidHotspotsChartConfig = useMemo(
      () => ({
        paddingInner: 4,
        height: 420, //NOTE: a temporal solution, change once hotspot layout bug is fixed
        showOnlyOneMenu: true,
        tooltipText: ({ row }) => {
          return <DataSourcesTreeMapTooltipContent row={row} dataSourceName={row.displayName} />;
        },
        textTop: ({ row }) => {
          return row.aggItemName;
        },
        textBottom: ({ row }) => {
          return `${formatNumberCompact(row.findings)} ${t('hotspot.numOfFindings')}`;
        },
        fields: [
          {
            label: t('hotspot.fields.name'),
            value: 'aggItemName',
          },
          {
            label: t('hotspot.fields.type'),
            value: 'displayName',
          },
          {
            label: t('hotspot.fields.numOfObjects'),
            value: 'docCount',
          },
          {
            label: t('hotspot.fields.numOfFindings'),
            value: 'findings',
          },
          {
            label: t('hotspot.fields.attributes'),
            value: 'attributeCount',
          },
          {
            label: t('hotspot.fields.location'),
            value: 'sourceLocation',
          },
        ],
        hotspotsChartColorField: 'docCount',
        hotspotsChartBoxSizeField: 'findings',
        hotspotsChartViewSize: pageSizeAvailableOptions[0],
      }),
      [t],
    );

    const heatMapType = getApplicationPreference('DATA_OVERVIEW_CATEGORIES_WIDGET_DRILLDOWN_ENABLED')
      ? LayoutContentType.TREE_MAP_CHART
      : LayoutContentType.HOTSPOTS_CHART;

    const layoutConfig: BigidLayoutConfig = useMemo(
      () => ({
        layoutId,
        dataTourId,
        content: {
          initLoadingLabel: '',
          entityName: t('grid.entityName'),
          entityTooltip: t('grid.entityTooltip'),
          contentTypes: [LayoutContentType.GRID, heatMapType],
          contentTypesExtended: [
            {
              id: layoutContentTypeGridId,
              name: t('toolbar.viewSwitchers.grid'),
              type: LayoutContentType.GRID,
              icon: BigidTableColumnsIcon,
            },
            {
              id: layoutContentTypeHeatMapId,
              name: t('toolbar.viewSwitchers.hotspot'),
              type: heatMapType,
              icon: BigidHotspotIcon,
            },
            {
              id: layoutContentTypeWorldMapId,
              name: t('toolbar.viewSwitchers.worldMap'),
              type: LayoutContentType.CUSTOM,
              icon: BigidLocationIcon,
            },
          ],
          defaultContentType: LayoutContentType.GRID,
          defaultContentId: layoutContentTypeGridId,
          customToolbarComponent: <DataSourcesTreeMapToolbar />,
          onContentLoadingStatusChange: (isLoading: boolean) => {
            onDataFetchStatusChange(CatalogDiscoveryWidget.DATA_SOURCES_LAYOUT, isLoading);
          },
          viewConfig: {
            fetchGridData: async (queryComponents, { searchQuery }) => {
              try {
                const { requireTotalCount, sort, skip, limit } = queryComponents;
                const payload: GetAggregatedDataPayload = {
                  filter: searchQuery,
                  aggregations: [
                    {
                      aggName: AggregationType.DATA_SOURCE_NAME,
                      sorting: mapGridSortingToApiFormat(sort),
                      paging: {
                        limit,
                        skip,
                      },
                      isGrid: true,
                    },
                  ],
                };
                const { aggregations } = await fetchDataSourcesCancelable(
                  getAggregatedData(payload) as Promise<GetAggregatedDataResponse<SourcesGridAggregationItem>>,
                );

                if (!aggregations?.[0]) {
                  return {
                    data: [],
                    totalCount: 0,
                  };
                }

                const { aggData } = aggregations[0];
                const totalCount = requireTotalCount ? aggData.length : undefined;

                const result: BigidGridDataFetchResult<DataSourceGridRecord> = {
                  data: (aggData ?? []).map(aggDataItem => ({
                    ...aggDataItem,
                    displayName: getDataSourceTypeDisplayName(aggDataItem?.sourceType),
                    id: getDataSourcesLayoutRecordId(aggDataItem),
                  })) as DataSourceGridRecord[],
                  totalCount,
                };

                if (requireTotalCount && limit === totalCount) {
                  const payload: GetAggregatedDataPayload = {
                    filter: searchQuery,
                    aggregations: [
                      {
                        aggName: AggregationType.DATA_SOURCE_CARDINALITY,
                        isTotalRequired: true,
                      },
                    ],
                  };
                  const { promise, cancel } = getAggregatedDataCountCancellable(payload);
                  const fetchCount: FetchTotalCount = {
                    fetchCountCanceler: cancel,
                    fetchCountFunction: async () => {
                      try {
                        const {
                          data: { aggregations },
                        } = await promise;

                        return {
                          totalCount: aggregations[0]?.aggTotal,
                        };
                      } catch ({ message }) {
                        console.error(`An error has occurred: ${message}`);

                        return {
                          totalCount: undefined,
                        };
                      }
                    },
                  };

                  result.fetchCount = fetchCount;
                }

                return result;
              } catch ({ message, isCanceled }) {
                if (!isCanceled) {
                  console.error(`An error has occurred: ${message}`);
                }

                return {
                  data: [],
                  totalCount: 0,
                };
              }
            },
            gridConfig,
            hotspotsChartConfig,
            treeMapChartConfig: {
              isCellSelectable: true,
              minHeight: 420,
              formattingFunction: function (data: DataSourceGridRecord[]) {
                const formattedOptions: BigidTreemapChartOption[] = [];
                data.forEach(item => {
                  formattedOptions.push({
                    id: item.aggItemName,
                    name: item.aggItemName,
                    value: item.docCount,
                    dummyData: item as DataSourceGridRecord,
                  });
                });
                return formattedOptions;
              },
              customTooltipTemplate: (level: number, option: BigidTreemapChartOption) => `
                ${renderToStaticMarkup(
                  <DataSourcesTreeMapTooltipContent
                    row={option.dummyData}
                    dataSourceName={option.dummyData.sourceType}
                  />,
                )}`,
            },
            customContentConfig: {
              component: DataSourceLayoutWorldMap,
              customProps: { layoutId, query },
            },
            toolbarConfig: {
              hideColumnChooser: true,
              hideToolbar: false,
            },
          },
          toolbarActions: [
            {
              label: t('toolbar.actions.applyToFilter'),
              isGlobal: false,
              placement: 'end',
              execute: async ({ selectedRowIds, currentView }: ActionData) => {
                try {
                  const filterObject: RulesStructNode = {
                    id: AggregationFilterOperand.DATA_SOURCE_NAME,
                    operator: Operators.AND,
                    rules: [
                      {
                        id: AggregationFilterOperand.DATA_SOURCE_NAME,
                        leftOperand: AggregationFilterOperand.DATA_SOURCE_NAME,
                        rightOperand: selectedRowIds as string[],
                        operator: Operators.IN,
                      },
                    ],
                  };
                  const payload: GetAggregatedDataPayload = {
                    filter: parseGraphQueryNodes(filterObject),
                    aggregations: [
                      {
                        aggName: AggregationType.DATA_SOURCE_NAME,
                      },
                    ],
                  };
                  const { aggregations = [] } = await fetchDataSourcesCancelable(
                    getAggregatedData(payload) as Promise<GetAggregatedDataResponse<SourcesGridAggregationItem>>,
                  );

                  const dataSources = aggregations[0]?.aggData;

                  if (currentView === LayoutContentType.CUSTOM) {
                    onWidgetFilterChange(AggregationType.DATA_SOURCE_LOCATION, dataSources);
                  } else {
                    onWidgetFilterChange(AggregationType.DATA_SOURCE_NAME, dataSources);
                  }
                  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_GRID_COMPONENT_APPLY_FILTER_CLICK);
                } catch ({ message }) {
                  console.error(`An error has occurred: ${message}`);
                } finally {
                  return Promise.resolve({
                    shouldGridReload: false,
                    shouldClearSelection: true,
                  });
                }
              },
              disable: () => false,
              show: ({ selectedRowIds, currentView }) => {
                return selectedRowIds.length > 0 && currentView !== LayoutContentType.CUSTOM;
              },
            },
            {
              label: t('toolbar.actions.goToDataSource'),
              isTooltipFollowCursor: true,
              show: () => true,
              execute: async ({ selectedRows }) => {
                try {
                  const { aggItemName, sourceType } = selectedRows[0] as DataSourceGridRecord;
                  const isNewDsConfigPage =
                    getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED') && !!hasNewTemplate(sourceType);

                  $state.go(
                    isNewDsConfigPage ? CONFIG.states.CONFIG_DATA_SOURCE : CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
                    {
                      id: aggItemName,
                    },
                  );
                } catch ({ message }) {
                  console.error(`An error has occurred: ${message}`);
                } finally {
                  return {};
                }
              },
              disable: () => {
                return false;
              },
              isInline: true,
              hideActionInToolBar: true,
              icon: BigidDataSourceIcon,
            },
            //NOTE: disable until further notice from @maorpichadze
            // {
            //   label: t('toolbar.actions.goToScanProfile'),
            //   isTooltipFollowCursor: true,
            //   show: () => true,
            //   execute: async () => {
            // $state.go(CONFIG.states.SCANS_NEW_SCANS_COMPLETED);
            //     return {};
            //   },
            //   disable: () => {
            //     return false;
            //   },
            //   isInline: true,
            //   hideActionInToolBar: true,
            //   icon: BigidDataSearchIcon,
            // },
          ],
        },
      }),
      [
        dataTourId,
        fetchDataSourcesCancelable,
        gridConfig,
        layoutId,
        onDataFetchStatusChange,
        onWidgetFilterChange,
        query,
        t,
      ],
    );

    const handleSidePanelClose = () => {
      setPreviewedDataSourceName(undefined);
    };

    return (
      <>
        <LayoutBasedWidgetWrapper dataAid={dataAid} dataTourId={dataTourId} isReady={isPageInitialised}>
          {isPageInitialised ? (
            <BigidLayout config={layoutConfig} />
          ) : (
            <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...gridWidgetSkeletonConfig} />
          )}
        </LayoutBasedWidgetWrapper>
        <BigidSidePanel
          open={Boolean(previewedDataSourceName)}
          onClose={handleSidePanelClose}
          title={previewedDataSourceName}
          content={<DataSourceSidePanel dataSourceName={previewedDataSourceName} filter={filter} />}
          maxWidth="large"
          isShowBackdrop
        />
      </>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query &&
    prevProps.isPageInitialised === newProps.isPageInitialised &&
    isEqual(prevProps.filter, newProps.filter),
);
