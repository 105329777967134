export enum ScanStatus {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  METADATACOMPLETED = 'MetadataCompleted',
  INPROGRESS = 'InProgress',
}

export const scanStatusDisplayName: Record<ScanStatus, string> = {
  [ScanStatus.COMPLETED]: 'Fully Scanned',
  [ScanStatus.FAILED]: 'Failed',
  [ScanStatus.METADATACOMPLETED]: 'Metadata Completed',
  [ScanStatus.INPROGRESS]: 'In Progress',
};

export const getScanStatusDisplayName = (scanStatus: ScanStatus) => {
  return scanStatusDisplayName[scanStatus] || scanStatus;
};
