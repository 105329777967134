import styled from '@emotion/styled';
import { BigidColorsV2 } from '@bigid-ui/components';

const defaultColor = BigidColorsV2.blue[600];

export interface SensitivityIndicatorProps {
  priority: number;
  size?: number;
}

export const priorityColor = [
  BigidColorsV2.red[400],
  BigidColorsV2.orange[400],
  BigidColorsV2.yellow[400],
  BigidColorsV2.green[400],
];

export const getColorByPriority = (priorityLevel: number) => {
  return priorityColor[priorityLevel] || defaultColor;
};

export const SensitivityLevelIndicator = styled('div')<SensitivityIndicatorProps>`
  background-color: ${({ priority }) => getColorByPriority(priority)};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: 10px;
  margin-right: 6px;
`;
