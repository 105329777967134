import React, { ChangeEvent, FC } from 'react';
import { SystemDialogContentProps } from '../../services/systemDialogService';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidBody1, BigidTextField } from '@bigid-ui/components';
import { ClassifierTesterHighlight } from './ClassifierTesterHighlight';
import { ClassifierTesterPerformance } from './ClassifierTesterPerformance';
import styled from '@emotion/styled';
import { ClassifierTesterResultsDto, ClassifierTesterValuesDto } from './ClassifierTesterTypes';

const HeadingWrapper = styled('div')({
  display: 'inline-flex',
});
export const Wrapper = styled('div')({
  paddingTop: '15px',
});

const dataAid = 'ClassifierTester';

export interface ClassifierTesterDialogContentProps {
  handleTesterResults: (
    newValues: ClassifierTesterResultsDto | React.SetStateAction<ClassifierTesterResultsDto>,
  ) => void;
  handleTesterValues: (newValues: ClassifierTesterValuesDto | React.SetStateAction<ClassifierTesterValuesDto>) => void;
  testerResults: ClassifierTesterResultsDto;
  classificationRegex: string;
  isOutOfTheBox: boolean;
  setSaveModeDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClassifierTesterDialogContent: FC<SystemDialogContentProps<ClassifierTesterDialogContentProps>> = ({
  handleTesterResults,
  handleTesterValues,
  testerResults,
  classificationRegex,
  isOutOfTheBox,
  setSaveModeDisabled,
}) => {
  const regexToTestProps = {
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const regex = event.target.value?.trim();
      handleTesterValues((prevState: ClassifierTesterValuesDto) => {
        return { ...prevState, regexToTest: regex, isRegexChanged: true };
      });
      setSaveModeDisabled(true);
    },
    errorMessage: testerResults.onErrorTesterValue,
    disabled: isOutOfTheBox,
    defaultValue: classificationRegex,
    multiline: true,
    rows: 3,
    inputProps: { maxLength: 1500000 },
    placeholder: 'Enter the regular expression you want to test',
  };

  const textToTestProps = {
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const text = event.target.value;
      handleTesterValues((prevState: ClassifierTesterValuesDto) => {
        return { ...prevState, textToTest: text };
      });
    },
    isError: false,
    multiline: true,
    placeholder: 'Enter the text you want to test',
    rows: 4,
    inputProps: { maxLength: 2000 },
  };

  return (
    <div data-aid={`${generateDataAid(dataAid, [])}`}>
      <HeadingWrapper>
        <BigidBody1>
          {`Test your classifier to verify the highlighted matches and to view its performance speed.`}
        </BigidBody1>
      </HeadingWrapper>
      <Wrapper data-aid={`${generateDataAid(dataAid, ['regexToTest'])}`}>
        <HeadingWrapper>
          <BigidBody1>{`Classifier RegEx`}</BigidBody1>
        </HeadingWrapper>
        <BigidTextField {...regexToTestProps} />
      </Wrapper>
      <Wrapper data-aid={`${generateDataAid(dataAid, ['textToTest'])}`}>
        <BigidBody1>{`Text to test`}</BigidBody1>
        <BigidTextField {...textToTestProps} />
      </Wrapper>
      {testerResults.isTestMode && (
        <>
          <Wrapper data-aid={`${generateDataAid(dataAid, ['highlight'])}`}>
            <ClassifierTesterHighlight
              dataAid={dataAid}
              content={testerResults.contentText}
              regexMatchesDetails={testerResults.regexMatchesDetails}
            ></ClassifierTesterHighlight>
          </Wrapper>
          {!isOutOfTheBox && (
            <Wrapper data-aid={`${generateDataAid(dataAid, ['performance'])}`}>
              <ClassifierTesterPerformance
                regexPattern={testerResults.regexPattern}
                onIsLoadingChange={setSaveModeDisabled}
                handlePerformanceResult={handleTesterResults}
                regexMatches={testerResults.regexMatchesDetails}
              ></ClassifierTesterPerformance>
            </Wrapper>
          )}
        </>
      )}
    </div>
  );
};
