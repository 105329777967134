import { isEmpty, isEqual, omit, sortBy, uniqWith } from 'lodash';
import {
  CorrelationSet,
  DataSourceType,
  SelectOptionType,
  CorrelationSetFormData,
  CorrelationSetData,
  DataSourceTypes,
  CompositeAttribute,
  TestConnectionColumn,
  DsConnectionSelectOptionType,
} from '../types/CorrelationSetsTypes';
import {
  DS_SUPPORTED_COMPOSITE_UNIQUE_ID,
  HYBRID_CONNECTORS,
  NON_LEGACY_CONNECTORS,
  SUB_FLAT_CONNECTORS,
} from '../constants';
import { getFixedT } from '../translations';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CorrelationSetModel } from '../CorrelationSetConnections/CorrelationSetConnectionTypes';
import { BigidDropdownOption } from '@bigid-ui/components';

export const CORRELATION_SET_CONNECTIONS_GRID_ID = 'correlationSetConnectionsGrid';
export const STATISTICS_GRID_ID = 'statisticsGrid';

export const isNewCorrelationSetPageEnabled = () => getApplicationPreference('NEW_CORRELATION_SET_PAGE_ENABLED');
export const isSSETestConnectionEnabled = () => getApplicationPreference('SSE_TEST_CONNECTION');

export function getIsValueChanged(prevValue: any, currValue: any): boolean {
  return !isEqual(prevValue, currValue);
}

export function getDataSourceOptions(dsList: DataSourceType[]): DsConnectionSelectOptionType[] {
  return dsList.map(({ _id, name, type }) => ({
    label: name,
    value: name,
    type: type,
  }));
}

export const transformCorrelationSetResponseToFormState = (
  correlationSet: CorrelationSet = {} as CorrelationSet,
  dsConnectionType: DataSourceTypes,
): CorrelationSetFormData => {
  const { custom_query_string, attributes } = correlationSet;
  const customQueryString = custom_query_string
    ? custom_query_string
    : attributes.length > 0
    ? generateCustomQueryString(correlationSet as unknown as CorrelationSetFormData)
    : '';

  return {
    ...correlationSet,
    custom_query_string: customQueryString,
    composite_attributes: correlationSet?.composite_attributes?.map((correlationSetValue, index) => {
      return {
        id: index,
        value: correlationSetValue.map(correlationSetItem => ({
          id: correlationSetItem,
          label: correlationSetItem,
          value: correlationSetItem,
          displayValue: correlationSetItem,
        })),
      };
    }),
    scanConnectionTimeoutInSeconds: correlationSet.scanConnectionTimeoutInSeconds || 0,
    enabled: correlationSet.enabled === 'yes' ? true : false,
    residency: [
      {
        value: correlationSet.residency,
        label: correlationSet.residency,
      },
    ],
    display_name: [
      {
        value: correlationSet.display_name,
        label: correlationSet.display_name,
      },
    ],
    unique_id: [
      {
        value: correlationSet.unique_id,
        displayValue: correlationSet.unique_id,
        id: correlationSet.unique_id,
      },
    ],
    dsConnection: [
      {
        value: correlationSet.dsConnection,
        label: correlationSet.dsConnection,
        type: dsConnectionType,
      },
    ],
  };
};

export const transformCorrelationSetFormStateToPayload = (
  formState: CorrelationSetFormData,
  isDraft: boolean,
  isCustomQueryStringImportantForTestConnection?: boolean,
): CorrelationSetData => {
  const uniqueIdSplit = formState.unique_id?.[0]?.value?.split(',');
  const uniqueIdComposite = uniqueIdSplit?.length > 1 ? uniqueIdSplit : [];
  const isCustomQueryString = getIsCustomQueryStringValue(formState.isCustomQueryString);

  let customQueryString = isCustomQueryString ? formState.custom_query_string : '';
  if (isCustomQueryStringImportantForTestConnection) {
    customQueryString = formState.custom_query_string;
  }

  return {
    id_connection: omit(
      {
        ...formState,
        isCustomQueryString,
        custom_query_string: customQueryString,
        dsConnection: formState.dsConnection[0].value,
        dsConnectionType: formState.dsConnection[0].type,
        skip_sample_size: Number(formState.skip_sample_size),
        type: 'ds-connection',
        display_name: formState.display_name?.[0]?.value,
        residency: formState.residency?.[0]?.value,
        unique_id: formState.unique_id?.[0]?.value,
        scanConnectionTimeoutInSeconds: Number(formState.scanConnectionTimeoutInSeconds) || 0,
        unique_id_composite: uniqueIdComposite,
        composite_attributes: formState.composite_attributes
          ?.map(composite => composite.value.map(compositeValue => compositeValue.value))
          .filter(composite => !isEmpty(composite)),
        draft: isDraft,
        enabled: isDraft ? 'no' : formState.enabled ? 'yes' : 'no',
      },
      ['_id', 'id', 'columnsTypes', 'uuid'],
    ),
  } as CorrelationSetData;
};

export const getIsCustomQueryStringValue = (value: string | boolean) => {
  if (typeof value === 'boolean') {
    return value;
  }

  return value === 'true';
};

export const isIdConnectionEnabled = (idConnection: CorrelationSetModel) => idConnection.enabled === 'yes';
export const isHybridConnector = (dsType: DataSourceTypes) => HYBRID_CONNECTORS.includes(dsType);
export const isNonLegacyConnector = (dsType: DataSourceTypes) => NON_LEGACY_CONNECTORS.includes(dsType);
export const isSubFlatConnector = (dsType: DataSourceTypes) => SUB_FLAT_CONNECTORS.includes(dsType);
export const isDBTableFieldVisible = (dsType: DataSourceTypes) => dsType !== 'workday';
export const isCustomQueryStringFieldVisible = (dsType: DataSourceTypes) => {
  return (
    dsType !== 'mongodb' &&
    dsType !== 'workday' &&
    dsType !== 'microstrategy' &&
    dsType !== 'salesforce-marketing-cloud' &&
    dsType !== 'sap-successfactors' &&
    dsType !== 'ldap' &&
    dsType !== 'csv' &&
    dsType !== 'generic-rest-api' &&
    dsType !== 'sparksql' &&
    dsType !== 's3-structured' &&
    dsType !== 'cosmosdb' &&
    dsType !== 'adls-structured' &&
    dsType !== 'servicenow' &&
    dsType !== 'servicenow-structured' &&
    dsType !== 'adls-gen2-structured' &&
    dsType !== 'gcs-structured' &&
    dsType !== 'oracle-eloqua' &&
    dsType !== 'sap-successfactors-v2' &&
    dsType !== 'zendesk-v2' &&
    !isHybridConnector(dsType)
  );
};

export const isIdCompositeEnabled = (dsType: DataSourceTypes) => DS_SUPPORTED_COMPOSITE_UNIQUE_ID.includes(dsType);

export const isProjectIdFieldVisible = (dsType: DataSourceTypes) => dsType === 'gcp-big-query';

export const getDbTableFieldLabel = (dsType: DataSourceTypes) => {
  const t = getFixedT('wizard.detailsStep');
  if (isHybridConnector(dsType)) {
    return t('fileLabel');
  }

  switch (dsType) {
    case 'ldap':
      return t('baseDnLabel');
    case 'oracle-eloqua':
      return t('entityLabel');
    default:
      return isCustomQueryStringFieldVisible(dsType) ? '' : t('tableNameRadio');
  }
};

export const hasDuplicates = (compositeAttributes: [SelectOptionType[]]) => {
  if (isEmpty(compositeAttributes)) {
    return false;
  }

  const normalizedSubArrays = compositeAttributes.map(subCompositeAttribute =>
    sortBy(subCompositeAttribute, item => JSON.stringify(item)),
  );
  return uniqWith(normalizedSubArrays, isEqual).length !== normalizedSubArrays.length;
};

export const getUniqIdList = (
  compositeAttributes: CompositeAttribute[],
  attributes: TestConnectionColumn[],
): BigidDropdownOption[] => {
  if (isEmpty(compositeAttributes)) {
    return attributes.map(attribute => ({
      displayValue: attribute.columnName,
      id: attribute.columnName,
      value: attribute.columnName,
    }));
  }

  const processedFirst = compositeAttributes
    .filter(item => item.value && item.value.length > 0)
    .map(item => {
      const matchedColumns = item.value
        .map(value => {
          const column = attributes.find(sec => sec.columnName === value.id);
          return column ? column.columnName : null;
        })
        .filter(Boolean); // Remove any null values (unmatched)

      return {
        id: item.id,
        columnName: matchedColumns.join(','), // Combine matched column names
      };
    });

  // Identify unmatched items in `second`
  const unmatchedSecond = attributes.filter(
    sec => !compositeAttributes.some(item => item.value.some(value => value.id === sec.columnName)),
  );

  return [...processedFirst, ...unmatchedSecond].map(item => ({
    displayValue: (item.columnName as any).replaceAll(',', ', '),
    id: item.columnName,
    value: item.columnName,
  }));
};

export const generateCustomQueryString = (formState: CorrelationSetFormData) => {
  const { db_table, attributes } = formState;

  let customQueryString = 'SELECT ';
  const selectedAttributes = attributes?.filter(attr => attr.selection);

  // Add selected column names OR *
  if (selectedAttributes?.length > 0) {
    const columnNames = selectedAttributes?.map(({ columnName, aliasColumnName }) => {
      if (aliasColumnName && aliasColumnName.trim()?.length) {
        //example: email AS employee_email
        return `${columnName} AS ${aliasColumnName}`;
      }

      return columnName;
    });

    customQueryString += columnNames.join(', ');
  } else {
    customQueryString += '*';
  }

  //add table name
  const tableName = db_table ? db_table : 'table';
  customQueryString += ` FROM ${tableName}`;

  return customQueryString;
};
