import React, { FC, useEffect, useReducer, useState } from 'react';
import { BigidLoader, BigidSidePanel } from '@bigid-ui/components';
import { CaseSidePanelViews, CaseSidePanelViewsIds } from './CaseSidePanelViews';
import { caseSidePanelReducer, ReducerActions, getInitialReducerState } from './hooks/CaseSidePanelReducer';
import styled from '@emotion/styled';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { CaseSidePanelContext } from './hooks/CaseSidePanelContext';
import { ObjectPreviewBreadcrumbs } from './ObjectPreviewBreadcrumbs';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { userPreferencesService } from '../../../services/userPreferencesService';

export interface CaseSidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  caseData: Record<string, any>;
  onCaseAction: () => void;
}

const CaseSidePanelContainer = styled.div({
  '& aside > div > div': {
    overflowY: 'scroll',
  },
  '& aside > div > header': {
    borderBottom: 'unset',
  },
});

export const CaseSidePanel: FC<CaseSidePanelProps> = ({ isOpen, onClose, caseData, onCaseAction }) => {
  const [caseName, setCaseName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(caseSidePanelReducer, { onCaseAction }, getInitialReducerState);
  const currentIndex = CaseSidePanelViews.findIndex(({ id }) => id === state.activeViewId);
  const ActiveView = CaseSidePanelViews[currentIndex].component;
  const isObjectPreviewPanel = currentIndex === 1;
  const handleOnCaseSidePanelClosing = () => {
    $state.go(CONFIG.states.ACTIONABLE_INSIGHTS, { caseId: '' }, { reload: false });
    setIsLoading(true);
    dispatch({
      type: ReducerActions.UPDATE_ACTIVE_VIEW_ID,
      payload: { activeViewId: CaseSidePanelViewsIds.CASE_REPORT },
    });

    dispatch({
      type: ReducerActions.RESET_WIDGETS_FLAGS_STATUS,
      payload: {},
    });
    dispatch({
      type: ReducerActions.RESET_ACTIONS,
      payload: {},
    });
    onClose();
  };

  const handleBreadcrumb = () => {
    dispatch({
      type: ReducerActions.UPDATE_ACTIVE_VIEW_ID,
      payload: { activeViewId: CaseSidePanelViewsIds.CASE_REPORT },
    });
  };

  const ObjectPreviewBreadcrumbsProps: { customTitleComponent: ReactElement } = {
    customTitleComponent: (
      <ObjectPreviewBreadcrumbs
        handlerBreadcrumb={handleBreadcrumb}
        previousCase={caseName}
        currentCase={state.objectFullyQualifiedName}
      />
    ),
  };

  useEffect(() => {
    if (caseData && caseData[0]?.caseLabel) {
      setCaseName(caseData[0].caseLabel);
      dispatch({
        type: ReducerActions.UPDATE_SIDE_PANEL_DATA,
        payload: { caseSidePanelData: caseData[0] },
      });
      userPreferencesService.setSharedBooleanUserPreference(
        userPreferencesService.SHARED_PREFERENCES.SECURITY_POSTURE_CASE_SIDE_PANEL_WAS_VISITED,
        true,
      );
      setIsLoading(false);
    }
  }, [caseData]);

  return (
    <CaseSidePanelContainer>
      <BigidSidePanel
        isShowBackdrop={true}
        maxWidth="large"
        open={isOpen}
        title={caseName}
        onClose={handleOnCaseSidePanelClosing}
        dataAid={state.activeViewId}
        content={
          isLoading ? (
            <BigidLoader />
          ) : (
            <CaseSidePanelContext.Provider
              value={{
                caseSidePanelData: state.caseSidePanelData,
                objectFullyQualifiedName: state.objectFullyQualifiedName,
                objectSensitivity: state.objectSensitivity,
                objectType: state.objectType,
                activeViewId: state.activeViewId,
                onCaseAction: state.onCaseAction,
                actionsList: state.actionsList,
                selectedObjects: state.selectedObjects,
                enableActionsOnCaseLevel: state.enableActionsOnCaseLevel,
                isAttributesWidgetVisible: state.isAttributesWidgetVisible,
                isFindingsWidgetsVisible: state.isFindingsWidgetsVisible,
                isSensitivityWidgetVisible: state.isSensitivityWidgetVisible,
                isViolationWidgetVisible: state.isViolationWidgetVisible,
                hasWidgetsErrors: state.hasWidgetsErrors,
                amountOfFindings: state.amountOfFindings,
                dispatch,
              }}
            >
              <ActiveView />
            </CaseSidePanelContext.Provider>
          )
        }
        {...(isObjectPreviewPanel && ObjectPreviewBreadcrumbsProps)}
      />
    </CaseSidePanelContainer>
  );
};
