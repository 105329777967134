import React from 'react';
import { styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { BigidLoader, BigidStateProgressBar } from '@bigid-ui/components';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { SensitiveData } from '../SensitiveDataWidget';

interface SensitiveDataChartProps {
  data: SensitiveData[];
  isLoading: boolean;
}

const ChartsContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 264px;
`;

const ProgressBarsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  & span {
    font-size: 14px;
  }
`;

const ProgressBarsItem = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const ProgressBarsWrapper = styled('div')`
  padding-top: 3px;
  margin-left: 12px;
  display: flex;
  position: relative;
  width: 100%;
`;

const PolicyLabel = styled('div')`
  width: 35%;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SensitiveDataChart: React.FC<SensitiveDataChartProps> = ({ data, isLoading }) => {
  const theme = useTheme();

  return (
    <ChartsContainer>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <ProgressBarsContainer>
          {data?.map(item => (
            <ProgressBarsItem key={item.label}>
              <PolicyLabel>{item.label}</PolicyLabel>
              <ProgressBarsWrapper>
                <BigidStateProgressBar
                  size="large"
                  width="100%"
                  barBackgroundColor={theme.palette.bigid.white}
                  barColor={theme.palette.bigid.blue300}
                  rightFilter={formatNumberCompact(item.count)}
                  percentage={(item.count / data[0]?.count) * 100}
                />
              </ProgressBarsWrapper>
            </ProgressBarsItem>
          ))}
        </ProgressBarsContainer>
      )}
    </ChartsContainer>
  );
};
