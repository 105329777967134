import React, { ReactElement } from 'react';
import {
  ActiveScanState,
  CompletedParentScanState,
  dsScanTypes,
  FilterColor,
  GetProgressPercentageParam,
  MtSupportedParentScanType,
  OwnerChipResponse,
  ParentScanRightFilterParams,
  ParentScanType,
  ScanActions,
  ScansGridRow,
  ScanState,
  scansWithInsights,
  ScanUpdateSSE,
  ShowScanActionParams,
  ShowSubScanActionParams,
  SSEActionType,
  SSEType,
  SubScanRightFilterParams,
} from './ScanTypes';
import {
  ActionData,
  BigidButtonIcon,
  BigidChipProps,
  BigidColorsV2,
  BigidFieldFilter,
  BigidFilter,
  BigidFilterOptionType,
  BigidFilterOptionValueType,
  BigidFormField,
  BigidFormFieldTypes,
  BigidHorizontalBarItem,
  BigidMultiProgressbarOptionType,
  EntityEvents,
  entityEventsEmitter,
  FlexJustifyContent,
  MultiProgressBarSizes,
  PieChartData,
  SchedulerSettingsWithCronExpression,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridSorting,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';
import * as scanService from './ScanService';
import { notificationService } from '../../services/notificationService';
import { isNil, noop, startCase, uniqBy } from 'lodash';
import { httpService } from '../../services/httpService';
import {
  ScanDetailsParts,
  BasicPartsStates,
  States,
  SubScanGridRow,
  SubscanStage,
  AllScanPartStates,
} from './ScanInsights/ScanInsightTypes';
import { userPreferencesService } from '../../services/userPreferencesService';
import { CONFIG } from '../../../config/common';
import { $state, systemUsersService } from '../../services/angularServices';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { ScansUITrackingEvent, ScanViewEnum, trackEventScansView } from './ScansEventTrackerUtils';
import { subscribeToRepeatedSSEEventById } from '../../services/sseService';
import { formatNumberCompact } from '../../utilities/numericDataConverter';
import { DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { CreatedByUser, ScanTypes } from './ScanTemplates/scanTemplateTypes';
import { isPermitted } from '../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { getCreateScanButton } from './Scans';
import { convertCronToReadableString } from '../../services/cronStringConversionService';
import { isNameValid } from '../../utilities/validation';
import { INVALID_SCAN_NAME_REGEX_MESSAGE } from '../../config/consts';
import { runScanProfile, RunScanProfileParams } from './ScanProfiles/scanProfileService';
import { convertUsersToFilterOptions, getUsersQuery } from '../../utilities/systemUsersUtils';
import { openSystemDialog } from '../../services/systemDialogService';
import {
  ScanTemplateInformationDialog,
  ScanTemplateInformationDialogProps,
} from './ScanCreateWizard/Steps/SelectTemplateStep/SelectTemplateCardMoreDialog';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';
import { getFixedT } from './translations';
import { BigidSyncIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { BigidDonutChartProps } from '@bigid-ui/visualisation';

export const NOT_APPLICABLE = 'N/A';
const scanPartsReportDownloadData = { reportName: 'scan_parts_report' };
const failedObjectReportDownloadData = { reportName: 'failed_objects_report' };
const initiatingRetryState = 'InitiatingRetry';
// To fix flaky sorting by fields with the same values
export const HYPERSCAN = 'Hyperscan';
const LABELING = 'Labeling';
export const FULL_SCAN = 'Full Scan';
export const DEFAULT_SORT_BY_ID: BigidGridSorting[] = [{ field: '_id', order: 'desc' }];
export const DEFAULT_MAX_DS_CONNECTIONS_IN_REQUEST = 100;
const identityDiscoveryScan = {
  type: 'identityDiscoveryScan',
};
export const MIN_PARTS_TO_ROUND = 10000;
export const MAX_PERCENTAGE = 100; // 100% in the progress bar
export const MAX_PERCENTAGE_WITH_GAP = 90; // 90% in the progress bar
export const MIN_PERCENTAGE_TO_GET_LIMIT = 95;
export const GAP_COUNT = 10;
export const DISABLED_SCAN_TYPE_TOOLTIP =
  'This scan type was disabled as some of the selected data sources do not support it.';
export const SCANS_PAGE_UPDATED = 'SCANS_PAGE_UPDATED' as EntityEvents;
const SCAN_TYPES_VALUES = Object.values(ScanTypes);

export const PAUSED_DISABLED_LABEL =
  'Scan tasks are currently running that prevent the scan from being paused. Please try again later';
export const STOP_DISABLED_LABEL =
  'Scan tasks are currently running that prevent the scan from being stopped. Please try again later';
export const STOP_DISABLED_GENERIC_LABEL = 'You can not stop the scan during this phase.';
export const ASSESSMENT_SCAN_STOPPING_REASON_THRESHOLD_FOUND = 'Scan completed - assessment threshold was reached.';
export const CATEGORIES = 'categories';
export const REGULATIONS = 'regulations';

const RightFilterContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export interface GetDsConnectionsQueryProps {
  maxDs?: number;
  searchString?: string;
  filter?: BigidGridQueryComponents['filter'];
}

enum ScanPartsStates {
  PARTS_COMPLETED = 'partsCompleted',
  PARTS_FAILED = 'partsFailed',
  PARTS_IN_PROGRESS = 'partsInProgress',
  PARTS_NEW = 'partsNew',
  PARTS_QUEUED = 'partsQueued',
  PARTS_ABORTED = 'partsAborted',
  PARTS_STOPPED = 'partsStopped',
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  FAILED = 'failed',
}

interface InnerTotalTaskChild {
  title: string;
  total: number;
  progressItems: BigidMultiProgressbarOptionType[];
  isConvertValueToPercentage: boolean;
  size: MultiProgressBarSizes;
  dataAid?: string;
}

export interface InnerTotalTasks {
  title: number;
  subTitle: string;
  subTitleTooltip?: ReactElement;
  progressItems: BigidMultiProgressbarOptionType[];
  highlighterFullwidth: boolean;
  isConvertValueToPercentage: boolean;
  highlighterPosition: FlexJustifyContent;
  isShowHighlighterWithValues: boolean;
  highlighter: boolean;
  children?: InnerTotalTaskChild[];
  highlighterAutoWidth?: boolean;
  dataAid?: string;
}

enum PieChartCategories {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  IN_PROGRESS = 'In Progress',
  QUEUED = 'Queued',
  STOPPED = 'Stopped',
  NOT_STARTED = 'Not Started',
  SKIPPED = 'Skipped',
}

export const SCAN_STATUS = {
  COMPLETED: 'Completed',
  STOPPED: 'Stopped',
  FAILED: 'Failed',
  PAUSED: 'Paused',
  PREPARE_FOR_RETRY: 'PrepareForRetry',
  QUEUED: 'Queued',
  REDIS_QUEUED: 'RedisQueued',
  SUBMITTED: 'Submitted',
  STARTED: 'Started',
  COLLECTING_METADATA: 'CollectingMetadata',
  IN_PROGRESS: 'InProgress',
  FINALIZING: 'Finalizing',
  NOT_SUPPORTED: 'NotSupported',
  MIRROR_PART_FROM_VIRTUAL: 'MirrorPartFromVirtual',
  QUEUED_FOR_MDSEARCH: 'QueuedForMdSearch',
  NEW: 'New',
  ABORTED: 'Aborted',
  NON_SUFFICIENT_SCOPE_PERMISSIONS: 'NonSufficientScopePermissions',
  RETRYING: 'Retrying',
  METADATA_COMPLETED: 'MetadataCompleted',
  METADATA_STALE: 'MetadataStale',
  INITIATING_RESUME: 'InitiatingResume',
  INITIATING_RETRY: 'InitiatingRetry',
  INITIATING_RERUN_PARTS: 'InitiatingRerunParts',
  STOP_REQUESTED: 'StopRequested',
  PAUSE_REQUESTED: 'PauseRequested',
  NOT_FOUND: 'NotFound',
  PENDING: 'Pending',
  NOT_RUNNING: 'NotRunning',
  WAITING_TO_RUN: 'WaitingToRun',
  COMPLETED_WITH_FAILURES: 'CompletedWithFailures',
};

export const ARRAY_OF_FINISHED_STATES = [
  SCAN_STATUS.COMPLETED,
  SCAN_STATUS.STOPPED,
  SCAN_STATUS.FAILED,
  SCAN_STATUS.ABORTED,
  SCAN_STATUS.COMPLETED_WITH_FAILURES,
];

export const SCANS_NOT_EFFECTING_STATE = [
  SubscanStage.CLUSTERING,
  SubscanStage.LINEAGE_BASE_TREE,
  SubscanStage.HOTSPOTS,
];

export const SIDEBAR_WIDTH = 56;

export const isFinishedState = (state: string) => ARRAY_OF_FINISHED_STATES.includes(state);

export function getScanOwnerChips(owners: string[]): OwnerChipResponse {
  const value = owners.map(owner => {
    return {
      id: owner,
      label: owner,
    };
  });

  return {
    value,
    isDisabled: true,
  };
}

export async function showDownloadArchiveScan() {
  const queryComponents: BigidGridQueryComponents = {
    filter: [{ field: 'is_archived', value: true, operator: 'equal' }],
    limit: 1,
    requireTotalCount: true,
  };
  const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
  const { totalCount } = await scanService.getScansData(gridConfigQuery);
  return totalCount > 0;
}
export async function fetchScanData(queryComponents: BigidGridQueryComponents, defaultFilter: BigidFilter) {
  try {
    const hasStatusFilter = queryComponents.filter?.some(filter => filter.field === 'state');
    const gridConfigQuery = queryService.getGridConfigQuery({
      ...queryComponents,
      filter: hasStatusFilter ? queryComponents.filter : [...defaultFilter, ...queryComponents.filter],
      sort: [...queryComponents.sort, ...DEFAULT_SORT_BY_ID],
    });
    const { scanParents, totalCount } = await scanService.getScansData(gridConfigQuery);

    return {
      data: scanParents,
      totalCount,
    };
  } catch ({ message }) {
    notificationService.error('An error has occurred fetching scans data. See logs for more details.');
    console.error(`An error has occurred: ${message}`);
    return {
      totalCount: 0,
      data: [],
    };
  }
}

const getDsConnectionsFilterByString = (dsConnectionName: string): BigidFieldFilter[] => {
  return [
    {
      field: 'name',
      operator: 'textSearch',
      value: dsConnectionName,
    },
  ];
};

export const getDsConnectionsQuery = ({
  searchString,
  maxDs = DEFAULT_MAX_DS_CONNECTIONS_IN_REQUEST,
  filter,
}: GetDsConnectionsQueryProps) => {
  const pagginationObject = { skip: 0, limit: maxDs };
  const filterObject = filter
    ? { filter }
    : {
        filter: searchString ? getDsConnectionsFilterByString(searchString) : [],
      };
  return queryService.getGridConfigQuery({
    ...pagginationObject,
    ...filterObject,
  });
};

export const fetchDsConnections = async (searchString?: string) => {
  const query = getDsConnectionsQuery({ searchString, maxDs: DEFAULT_MAX_DS_CONNECTIONS_IN_REQUEST });
  const dataSources = (
    await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?${query}&fields=["name"]`)
  ).data.data.ds_connections;
  const dataSourceNames = uniqBy(
    dataSources.map(dataSource => ({
      label: dataSource.name,
      value: dataSource.name,
      isSelected: false,
    })),
    'value',
  );
  return dataSourceNames;
};

export async function getInitialScanFilters(scanStatuses?: string[]) {
  const dataSources = await fetchDsConnections();
  const initialActivityQueueFilters: BigidGridWithToolbarProps<ScansGridRow>['filterToolbarConfig'] = {
    filters: [
      {
        title: 'Type',
        field: 'type',
        operator: 'in',
        options: isMultiTenantModeEnabled()
          ? Object.values(MtSupportedParentScanType).map(type => ({
              label: startCase(type),
              value: type,
              isSelected: dsScanTypes.includes(type),
            }))
          : Object.values(ParentScanType).map(type => ({
              label: type === ParentScanType.HYPERSCAN ? HYPERSCAN : startCase(type),
              value: type,
              isSelected: dsScanTypes.includes(type),
            })),
        isSelected: true,
        value: [],
        listWidth: 400,
      },
      {
        title: 'Data Source',
        field: 'dsConnectionList',
        operator: 'in',
        options: dataSources,
        isSelected: false,
        value: [],
        isSearchAsync: true,
        loadSearchOptions: fetchDsConnections,
        listWidth: 400,
      },
      ...getScanStatusFilterIfEnabled(scanStatuses),
    ],
    searchConfig: {
      searchFilterKeys: ['name', '_id'],
      initialValue: '',
      placeholder: 'Scan Name or Scan ID',
      operator: 'textSearch',
      autoSubmit: true,
    },
  };
  return initialActivityQueueFilters;
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text ?? 'no text').catch(err => {
    console.error("Can't copy text", err);
  });
};

export const downloadScanPartsReport = async (id: string) => {
  try {
    const params = {
      subscan_id: id,
    };
    trackEventScansView(ScansUITrackingEvent.DOWNLOAD_REPORT, scanPartsReportDownloadData);
    const url = `scan-parts/file-download/report`;
    return httpService.downloadFile(url, params);
  } catch (e) {
    console.error(e);
    notificationService.error('Could not download. See logs for more information');
  }
};

export const getFailedObjectReport = async (scanId = '') => {
  try {
    trackEventScansView(ScansUITrackingEvent.DOWNLOAD_REPORT, failedObjectReportDownloadData);
    const url = `file-download/failed-object-report/${scanId}`;
    return await httpService.downloadFile(url);
  } catch (error) {
    console.error(error);
    notificationService.error('Download failed. See logs for more information');
  }
};

export function calculateRightFilterColor(state: ScanState): FilterColor {
  if (state === CompletedParentScanState.COMPLETED_WITH_FAILURES || state === CompletedParentScanState.FAILED) {
    return FilterColor.RED;
  }
  return FilterColor.DEFAULT;
}

export function sanitizeCompleteRate(complete: number): number {
  return complete > 100 ? 100 : complete < 0 ? 0 : complete;
}

export function calculateProgressBarPercentage({
  complete = 0,
  failed,
  state,
  total,
  type,
  isSubScan,
  parentScanType,
}: ParentScanRightFilterParams | SubScanRightFilterParams) {
  if (isSubScan && parentScanType === ParentScanType.ASSESSMENT_SCAN && state === CompletedParentScanState.COMPLETED) {
    return 100;
  }
  const isScanRunning = isScanStillRunningByState(state as CompletedParentScanState);
  const percentage =
    isScanRunning && type !== SubscanStage.DATA_SOURCE_SCAN
      ? sanitizeCompleteRate(complete)
      : getProgressPercentage({ complete, total, isScanRunning, isSubScan });

  switch (state) {
    case CompletedParentScanState.COMPLETED_WITH_FAILURES:
      return failed === 0 ? 99 : percentage;
    case CompletedParentScanState.FAILED:
      return 0;
    default:
      return percentage;
  }
}

export function calculateParentScanRightFilter({
  complete = 0,
  failed,
  total,
  state,
  isFailedObjects,
  enumerationStatus,
  scan,
  isShouldDisplayRetryButton,
}: ParentScanRightFilterParams) {
  let rightFilter: string | ReactElement = `${complete} / ${total} Parts`;

  if (state === ActiveScanState.COLLECTING_METADATA) {
    rightFilter = `Collecting Metadata`;
  }

  if (state === ActiveScanState.QUEUED) {
    rightFilter = `Queued...`;
  }

  if (state === CompletedParentScanState.FAILED) {
    rightFilter = 'Failed to run';
  }

  if (state === CompletedParentScanState.COMPLETED_WITH_FAILURES) {
    rightFilter = getRightFunctionErrorMessage(
      isFailedObjects,
      failed,
      null,
      null,
      enumerationStatus,
      scan,
      isShouldDisplayRetryButton,
    );
  }
  if (state === CompletedParentScanState.ABORTED) {
    rightFilter = `Aborted`;
  }

  if (state === CompletedParentScanState.COMPLETED) {
    if (failed === 0) {
      rightFilter = `${sanitizeCompleteRate(complete)}%`;
    }
    if (failed > 0) {
      rightFilter = 'Post scan failures';
    }
  }

  if (state === CompletedParentScanState.STOPPED) {
    rightFilter = 'Stopped';
  }

  return rightFilter;
}

const RetryButton = ({
  isShouldDisplay,
  scan,
  text,
}: {
  isShouldDisplay: boolean;
  scan: ScansGridRow | SubScanGridRow;
  text: string;
}) => {
  const onClickHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    const actionData: ActionData = { selectedRows: [{ ...scan }] };
    scanService.runScanActionDialog(actionData, ScanActions.RETRY);
  };

  return (
    <>
      {(text || isShouldDisplay) && (
        <RightFilterContainer>
          {text && <span>{text}</span>}
          {isShouldDisplay && (
            <BigidButtonIcon icon={BigidSyncIcon} title="Retry Failed Objects" onClick={onClickHandler} />
          )}
        </RightFilterContainer>
      )}
    </>
  );
};

export function getRightFunctionErrorMessage(
  isFailedObjects: boolean,
  failed: number,
  complete?: number,
  total?: number,
  enumerationStatus?: string,
  scan?: ScansGridRow | SubScanGridRow,
  isShouldDisplay?: boolean,
) {
  if (failed > 0) {
    const text =
      isNil(complete) && isNil(total) ? `${failed}% Failed` : `${complete}/${total} ${failed} Part(s) Failed`;
    return <RetryButton scan={scan} isShouldDisplay={isShouldDisplay} text={text} />;
  }

  if (isFailedObjects) {
    return <RetryButton scan={scan} isShouldDisplay={isShouldDisplay} text={'Failed Objects found'} />;
  }

  if (
    enumerationStatus === CompletedParentScanState.FAILED ||
    enumerationStatus === CompletedParentScanState.COMPLETED_WITH_FAILURES
  ) {
    return <RetryButton scan={scan} isShouldDisplay={isShouldDisplay} text={'Enumeration Failed'} />;
  }

  return <RetryButton scan={scan} isShouldDisplay={isShouldDisplay} text={''} />;
}

export const subscansThatHaveParts: SubscanStage[] = [SubscanStage.DATA_SOURCE_SCAN, SubscanStage.TAGGING_SCAN];
export const showProgressBar = (type: SubscanStage) => {
  return subscansThatHaveParts.includes(type);
};

export function calculateSubScanRightFilter({
  complete,
  failed,
  total,
  type,
  state,
  isFailedObjects,
  enumerationStatus,
  scan,
  isShouldDisplayRetryButton,
}: SubScanRightFilterParams) {
  if (!showProgressBar(type)) {
    return;
  }
  let rightFilter: string | ReactElement = `${complete}/${total}`;

  if (state === CompletedParentScanState.COMPLETED_WITH_FAILURES) {
    rightFilter = getRightFunctionErrorMessage(
      isFailedObjects,
      failed,
      complete,
      total,
      enumerationStatus,
      scan,
      isShouldDisplayRetryButton,
    );
  }

  if (state === CompletedParentScanState.FAILED) {
    rightFilter = 'Failed to run';
  }

  return rightFilter;
}

export function getPercentage(partialValue: number, totalValue: number) {
  return Math.round((100 * partialValue) / totalValue);
}

export function calcProgressPercentage(progress: number, total: number) {
  let percentage = total > 0 ? getPercentage(progress, total) : 0;
  const isPercentageInRangeToGetGap = percentage >= MIN_PERCENTAGE_TO_GET_LIMIT && percentage < MAX_PERCENTAGE;

  if (isPercentageInRangeToGetGap && total > MIN_PARTS_TO_ROUND) {
    percentage = percentage - GAP_COUNT;
  }
  return percentage;
}

export function shouldShowScanAction({ state, stateCondition, notEqual }: ShowScanActionParams) {
  return notEqual ? !stateCondition.includes(state) : stateCondition.includes(state);
}

export function shouldShowSubScanAction({ state, stateCondition, notEqual, type }: ShowSubScanActionParams) {
  const isShowAction = type === SubscanStage.DATA_SOURCE_SCAN || type === SubscanStage.IDENTITY_SUB_SCAN;
  return isShowAction ? shouldShowScanAction({ state, stateCondition, notEqual }) : false;
}

export function goToScanState(newScanState: boolean, state?: string) {
  newScanState
    ? $state.go(state || CONFIG.states.SCANS_NEW_SCANS, null, { location: 'replace' })
    : $state.go(CONFIG.states.SCANS, null, { location: 'replace' });
}

export async function updateScanPreferenceAndChangeState(isNewScansPage: boolean) {
  await userPreferencesService.update({
    preference: CONFIG.states.SCANS,
    data: {
      isNewScansPage,
    },
  });
  goToScanState(isNewScansPage);
}

export const navigateToScansPage = async (state?: string) => {
  const isNewPageEnabled = checkIsNewPageEnabled();
  if (isNewPageEnabled) {
    goToScanState(true, state);
    return;
  }
  goToScanState(false);
};

const checkIsNewPageEnabled = (): boolean => {
  const isNewScansPageFF = getApplicationPreference('SHOW_NEW_SCANS_PAGE');
  if (isNewScansPageFF) {
    return true;
  }
  return false;
};

export const navigateToForbiddenPageIfNecessary = async () => {
  const isNewPageEnabled = await checkIsNewPageEnabled();
  if (!isNewPageEnabled) {
    $state.go(CONFIG.states.FORBIDDEN);
  }
};

const getPreferencesFilter = async () => {
  const preferences = await userPreferencesService.get($state.$current.name);
  return preferences?.filter;
};

const handleRemoveRecordsByStateFilter = async (scan: ScansGridRow | SubScanGridRow) => {
  const { filter } = await getPreferencesFilter();
  const stateFilter = filter?.find((filter: any) => filter.field === 'state');
  const isScanStateExistInFilter = (stateFilter?.value as BigidFilterOptionValueType[])?.includes(scan.state);
  if (stateFilter && !isScanStateExistInFilter) {
    entityEventsEmitter.emit(EntityEvents.DELETE, [scan]);
  }
};

export const subscribeToSSEScanUpdates = (type: SSEType, page: ScanViewEnum, parentId?: string) => {
  return subscribeToRepeatedSSEEventById<ScanUpdateSSE>(type, async data => {
    const { results } = data;
    for (const { id, action, payload } of results) {
      switch (action) {
        case SSEActionType.ADD:
          if (page === ScanViewEnum.SCAN_INSIGHTS) {
            const { parentScanId } = payload as SubScanGridRow;
            parentId === parentScanId && entityEventsEmitter.emit(EntityEvents.ADD, [payload]);
          }
          page === ScanViewEnum.ACTIVITY_QUEUE && entityEventsEmitter.emit(EntityEvents.ADD, [payload]);
          entityEventsEmitter.emit(SCANS_PAGE_UPDATED);
          break;
        case SSEActionType.ABORTED:
          page === ScanViewEnum.SCAN_INSIGHTS && entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, payload);
          page === ScanViewEnum.COMPLETED_SCANS && entityEventsEmitter.emit(EntityEvents.ADD, [payload]);
          break;
        case SSEActionType.COMPLETE:
          if (page === ScanViewEnum.SCAN_INSIGHTS) {
            await handleRemoveRecordsByStateFilter(payload);
          }
          page === ScanViewEnum.ACTIVITY_QUEUE && entityEventsEmitter.emit(EntityEvents.DELETE, [payload]);
          page === ScanViewEnum.COMPLETED_SCANS &&
            entityEventsEmitter.emit(EntityEvents.REPLACE_OR_ADD_BY_ID, [payload]);
          page === ScanViewEnum.SCAN_INSIGHTS && entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, payload);
          entityEventsEmitter.emit(SCANS_PAGE_UPDATED);
          break;
        case SSEActionType.UPDATE:
          if (page === ScanViewEnum.SCAN_INSIGHTS || page === ScanViewEnum.ACTIVITY_QUEUE) {
            await handleRemoveRecordsByStateFilter(payload);
          }
          const { state, completedAt, progress } = payload as ScansGridRow;
          if ((state as string) === initiatingRetryState) {
            const updatedPayload = {
              ...payload,
              state: ActiveScanState.IN_PROGRESS,
              progress: { ...progress, state: ActiveScanState.IN_PROGRESS },
            };
            page === ScanViewEnum.SCAN_INSIGHTS &&
              entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, updatedPayload);
            page === ScanViewEnum.ACTIVITY_QUEUE &&
              (!!completedAt
                ? entityEventsEmitter.emit(EntityEvents.ADD, [updatedPayload])
                : entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, updatedPayload));
            page === ScanViewEnum.COMPLETED_SCANS && entityEventsEmitter.emit(EntityEvents.DELETE, [payload]);
          } else {
            page !== ScanViewEnum.COMPLETED_SCANS && entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, payload);
          }
          break;
        case SSEActionType.DELETE:
          page === ScanViewEnum.COMPLETED_SCANS && entityEventsEmitter.emit(EntityEvents.DELETE, [payload]);
          page === ScanViewEnum.ACTIVITY_QUEUE && entityEventsEmitter.emit(EntityEvents.DELETE, [payload]);
      }
    }
  });
};

export const sanitizeInsightValues = (insightValue: number): string => {
  return insightValue >= 0 ? formatNumberCompact(insightValue) : NOT_APPLICABLE;
};

export const getNumber = (value: any) => (typeof value === 'number' && !isNaN(value) ? value : 0);

export function statusCalcWrapper(state: ScanState, type: string, failed: number): ScanState {
  const isCompleted = state === CompletedParentScanState.COMPLETED;
  const hasFailures = failed > 0;
  return isCompleted && hasFailures ? CompletedParentScanState.COMPLETED_WITH_FAILURES : state;
}

export const getLabelWithCount = (name: string, array?: any[]) =>
  `${array?.length ?? 0} ${name}${array?.length === 1 ? '' : 's'}`;

export const getScanTypeLabel = (scanType: string) => {
  switch (scanType as ScanTypes) {
    case ScanTypes.DS_SCAN:
      return FULL_SCAN;
    case ScanTypes.HYPER_SCAN:
      return HYPERSCAN;
    case ScanTypes.LABELING:
      return LABELING;
    default:
      return startCase(scanType);
  }
};

export const getScanTypesOptions = (scanTypesFilteredByDs = SCAN_TYPES_VALUES) => {
  const scanTypesFilteredByFlags = SCAN_TYPES_VALUES.filter(scanType => {
    switch (scanType) {
      case ScanTypes.LINEAGE_SCAN:
        return !isMultiTenantModeEnabled();
      case ScanTypes.DATA_IN_MOTION:
        return getApplicationPreference('DIM_ENABLED');
      case ScanTypes.LABELING:
        return getApplicationPreference('LABELER_ENABLED');
      case ScanTypes.HYPER_SCAN:
        return getApplicationPreference('CLUSTERING_ENABLED');
      case ScanTypes.ASSESSMENT_SCAN:
        return getApplicationPreference('ASSESSMENT_SCAN_ENABLED');
      default:
        return true;
    }
  });

  return scanTypesFilteredByFlags.map(type => {
    const label = getScanTypeLabel(type);
    const isDisabled = !scanTypesFilteredByDs.includes(type);
    return {
      isDisabled,
      label,
      value: type,
      tooltip: isDisabled ? DISABLED_SCAN_TYPE_TOOLTIP : '',
      isSelected: false,
    };
  });
};

export const isScanStillRunningByDate = (piisummary_completed_dt?: Date, scan_management_complete_dt?: Date) => {
  return isNil(piisummary_completed_dt) && isNil(scan_management_complete_dt);
};

export const isScanStillRunningByState = (state?: CompletedParentScanState) => {
  return !isNil(state) && !Object.values(CompletedParentScanState).includes(state);
};

export const getProgressPercentage = ({ complete, total, isScanRunning, isSubScan }: GetProgressPercentageParam) => {
  let percentage = isScanRunning || isSubScan ? calcProgressPercentage(complete, total) : complete;
  if (percentage === MAX_PERCENTAGE && isScanRunning) {
    percentage = MAX_PERCENTAGE_WITH_GAP;
  }
  return percentage;
};

export const getCreatedByText = (createdByUser?: CreatedByUser) => {
  const { firstName, lastName, username } = createdByUser || {};
  if (!firstName && !lastName) {
    return username;
  }
  return `${firstName ?? ''}${firstName && lastName ? ' ' : ''}${lastName ?? ''}`;
};

export const isTemplatesEnabled = () => getApplicationPreference('ENABLE_SCAN_TEMPLATES');
export const getScanHeaderButton = (state: string) => {
  if (!isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name)) {
    return null;
  }
  if (state === CONFIG.states.SCANS_SCAN_TEMPLATES) {
    return getCreateScanButton({
      state: CONFIG.states.SCAN_TEMPLATE,
      label: 'New Template',
      dataAid: 'create-new-template-button',
      action: ScansUITrackingEvent.CREATE_SCAN_TEMPLATE_CLICK,
      isShowIcon: false,
      dataTourId: 'create-new-template-button',
    });
  } else {
    return isTemplatesEnabled()
      ? getCreateScanButton({
          state: CONFIG.states.CREATE_SCAN,
          label: 'New Scan',
          dataAid: 'create-new-scheduled-scan-button',
          action: ScansUITrackingEvent.CREATE_SCHEDULED_SCAN_CLICK,
          isShowIcon: true,
        })
      : getCreateScanButton({
          state: CONFIG.states.CREATE_SCAN_PROFILE,
          label: 'Create Scan Profile',
          dataAid: 'create-scan-profile-button',
          action: ScansUITrackingEvent.CREATE_SCAN_PROFILE_CLICK,
          isShowIcon: false,
        });
  }
};

export const getScheduleGridCellValue = (
  schedule: string | SchedulerSettingsWithCronExpression,
  scheduledBy = 'bigid',
) => {
  return schedule && scheduledBy != null
    ? convertCronToReadableString(typeof schedule === 'string' ? schedule : schedule.cronExpression)
    : 'Not Scheduled';
};

export const getScanTabs = (): string[] => {
  return [
    CONFIG.states.SCANS_NEW,
    ...(isTemplatesEnabled()
      ? [CONFIG.states.SCANS_PLANNED_SCANS, CONFIG.states.SCANS_SCAN_TEMPLATES]
      : [CONFIG.states.SCANS_SAVED_PROFILES]),
  ];
};

export function getScanTemplateRowIfEnabled(): BigidGridColumn<ScansGridRow>[] {
  const isScanTemplatesEnabled = getApplicationPreference('ENABLE_SCAN_TEMPLATES');
  return isScanTemplatesEnabled
    ? [
        {
          name: 'scanTemplateName',
          title: 'Scan Template Name',
          getCellValue: (row: ScansGridRow) => row.scanTemplateName,
          type: BigidGridColumnTypes.TEXT,
          isHiddenByDefault: true,
          width: 250,
        },
      ]
    : [];
}
export function getScanStatusFilterIfEnabled(
  scanStatuses: string[],
): BigidGridWithToolbarProps<ScansGridRow>['filterToolbarConfig']['filters'] {
  const isScanPageStateEnabled = getApplicationPreference('USE_SCAN_PAGE_STATE');
  return isScanPageStateEnabled
    ? [
        {
          title: 'Status',
          field: 'state',
          operator: 'in',
          options: scanStatuses.map(scanStatus => ({
            label: startCase(scanStatus),
            value: scanStatus,
            isSelected: false,
          })),
          isSelected: false,
          value: [],
          listWidth: 400,
        },
      ]
    : [];
}

export const nameValidator = (val: string) => {
  if (!val || !isNameValid(val)) return INVALID_SCAN_NAME_REGEX_MESSAGE;
  return false;
};

export const runScanProfileAndNavigateToActiveScans = async ({ scanProfileName, scanType }: RunScanProfileParams) => {
  const isRunScanProfileSuccessful = await runScanProfile({
    scanProfileName,
    scanType,
  });
  isRunScanProfileSuccessful && $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
};

export const getOwnerField: (name: string, isSaveUserDetails: boolean) => BigidFormField = (
  name,
  isSaveUserDetails,
) => ({
  name,
  label: 'Owner',
  type: BigidFormFieldTypes.SELECT,
  isRequired: false,
  fieldProps: {
    isSearchable: true,
    placeholder: 'Select Owner',
    isMulti: false,
    isAsync: true,
    loadOptions: async (inputType: string): Promise<BigidFilterOptionType[]> => {
      const query = getUsersQuery({ maxUsers: 50, searchString: inputType });
      const {
        data: { users },
      } = await systemUsersService.getAllSystemUsersByQuery(query);
      return convertUsersToFilterOptions(users, isSaveUserDetails);
    },
    shouldLoadInitialOptions: true,
    menuPosition: 'fixed',
  },
});

export const handleScanTemplateMoreDetailsClick = (
  scanTemplateProps: ScanTemplateInformationDialogProps,
  title?: string,
) => {
  openSystemDialog({
    title: title || 'More Information',
    content: ScanTemplateInformationDialog,
    contentProps: scanTemplateProps,
    onClose: noop,
    maxWidth: 'md',
  });
};

export const getScanMetricsByDays = async (days: number) => {
  const response = await scanService.getScanMetricsByDays(days);
  return response?.data?.data;
};

export const buildPiiChartDataFF = (data: States): BigidDonutChartProps['data'] => {
  const chartData: BigidDonutChartProps['data'] = [];
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case ScanPartsStates.PARTS_COMPLETED:
      case ScanPartsStates.COMPLETED:
        chartData.push({
          category: PieChartCategories.COMPLETED,
          value,
          color: BigidColorsV2.green[300],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_FAILED:
      case ScanPartsStates.FAILED:
        chartData.push({
          category: PieChartCategories.FAILED,
          value,
          color: BigidColorsV2.red[300],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_IN_PROGRESS:
      case ScanPartsStates.IN_PROGRESS:
        chartData.push({
          category: PieChartCategories.IN_PROGRESS,
          value,
          color: BigidColorsV2.blue[300],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_NEW:
      case ScanPartsStates.PARTS_QUEUED:
        const newPart = chartData.find(part => part.category === PieChartCategories.NOT_STARTED);
        if (newPart) {
          newPart.value += value;
          break;
        }
        chartData.push({
          category: PieChartCategories.NOT_STARTED,
          value,
          color: BigidColorsV2.gray[200],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_ABORTED:
      case ScanPartsStates.PARTS_STOPPED:
        const abortedPart = chartData.find(part => part.category === PieChartCategories.STOPPED);
        if (abortedPart) {
          abortedPart.value += value;
          break;
        }
        chartData.push({
          category: PieChartCategories.STOPPED,
          value,
          color: BigidColorsV2.gray[400],
          isActive: true,
        });
        break;
      default:
        break;
    }
  }
  return chartData;
};

export function aggregateParts(parts: BasicPartsStates[], scanParts: ScanDetailsParts): AllScanPartStates {
  const postScanParts = parts.reduce(
    (prev, curr) => {
      return {
        totalParts: curr.totalParts + prev.totalParts,
        partsCompleted: curr.partsCompleted + prev.partsCompleted,
        partsFailed: curr.partsFailed + prev.partsFailed,
        partsInProgress: curr.partsInProgress + prev.partsInProgress,
        partsNew: curr.partsNew + prev.partsNew,
        partsQueued: curr.partsQueued + prev.partsQueued,
      };
    },
    {
      totalParts: 0,
      partsCompleted: 0,
      partsFailed: 0,
      partsInProgress: 0,
      partsNew: 0,
      partsQueued: 0,
    },
  );
  return {
    totalParts: scanParts.totalParts + postScanParts.totalParts,
    partsCompleted: scanParts.partsCompleted + postScanParts.partsCompleted,
    partsFailed: scanParts.partsFailed + postScanParts.partsFailed,
    partsInProgress: scanParts.partsInProgress + postScanParts.partsInProgress,
    partsQueued: scanParts.partsQueued + postScanParts.partsQueued,
    partsAborted: scanParts.partsAborted,
    partsStopped: scanParts.partsStopped,
  };
}

export const buildPiiChartData = (data: States): BigidDonutChartProps['data'] => {
  const chartData: BigidDonutChartProps['data'] = [];
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case ScanPartsStates.PARTS_COMPLETED:
      case ScanPartsStates.COMPLETED:
        chartData.push({
          category: PieChartCategories.COMPLETED,
          value,
          color: isScanInsightsAvailable() ? BigidColorsV2.green[300] : BigidColorsV2.green[700],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_FAILED:
      case ScanPartsStates.FAILED:
        chartData.push({
          category: PieChartCategories.FAILED,
          value,
          color: isScanInsightsAvailable() ? BigidColorsV2.red[300] : BigidColorsV2.red[600],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_IN_PROGRESS:
      case ScanPartsStates.IN_PROGRESS:
        chartData.push({
          category: PieChartCategories.IN_PROGRESS,
          value,
          color: isScanInsightsAvailable() ? BigidColorsV2.blue[300] : BigidColorsV2.blue[700],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_NEW:
      case ScanPartsStates.PARTS_QUEUED:
        const newPart = chartData.find(part => part.category === PieChartCategories.QUEUED);
        if (newPart) {
          newPart.value += value;
          break;
        }
        chartData.push({
          category: PieChartCategories.QUEUED,
          value,
          color: isScanInsightsAvailable() ? BigidColorsV2.gray[200] : BigidColorsV2.purpleBlue[400],
          isActive: true,
        });
        break;
      case ScanPartsStates.PARTS_ABORTED:
      case ScanPartsStates.PARTS_STOPPED:
        const abortedPart = chartData.find(part => part.category === PieChartCategories.STOPPED);
        if (abortedPart) {
          abortedPart.value += value;
          break;
        }
        chartData.push({
          category: PieChartCategories.STOPPED,
          value,
          color: isScanInsightsAvailable() ? BigidColorsV2.gray[400] : BigidColorsV2.gray[500],
          isActive: true,
        });
        break;
      default:
        break;
    }
  }
  return chartData;
};

export const buildHorizontalBarData = (data: States): BigidHorizontalBarItem[] => {
  const horizontalBar: BigidHorizontalBarItem[] = [];
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case ScanPartsStates.COMPLETED:
        horizontalBar.push({ type: PieChartCategories.COMPLETED, value, color: BigidColorsV2.green[700] });
        break;
      case ScanPartsStates.FAILED:
        horizontalBar.push({ type: PieChartCategories.FAILED, value, color: BigidColorsV2.red[600] });
        break;
      case ScanPartsStates.IN_PROGRESS:
        horizontalBar.push({ type: PieChartCategories.IN_PROGRESS, value, color: BigidColorsV2.blue[700] });
        break;
      default:
        break;
    }
  }
  return horizontalBar;
};

export const getTheRequiredColorsToChip = (status: string) => {
  switch (status) {
    case SCAN_STATUS.COMPLETED:
      return {
        color: BigidColorsV2.green[900],
        bgColor: BigidColorsV2.green[100],
      };
    case SCAN_STATUS.FAILED:
      return {
        color: BigidColorsV2.red[900],
        bgColor: BigidColorsV2.red[100],
      };
    case SCAN_STATUS.STARTED:
    case SCAN_STATUS.IN_PROGRESS:
      return {
        color: BigidColorsV2.blue[900],
        bgColor: BigidColorsV2.blue[100],
      };
    case SCAN_STATUS.STOPPED:
      return {
        color: BigidColorsV2.gray[500],
        bgColor: BigidColorsV2.gray[150],
      };
    case SCAN_STATUS.COMPLETED_WITH_FAILURES:
      return {
        color: BigidColorsV2.orange[700],
        bgColor: BigidColorsV2.orange[100],
      };
    default:
      return {
        bgColor: '#F5F5F5',
        color: '#3D3D3D',
      };
  }
};

export const isScanInsightsAvailable = () => getApplicationPreference('SHOW_NEW_SCAN_INSIGHT');

// both Tagging Scan and Import Labels scan are displayed as Labeling. So we need to distinguish between them.
export const labelingScanSubtypeIsTaggingScan = (type: ParentScanType, name: string) =>
  type === ParentScanType.LABELING && !name.startsWith('Import Labels Operation');
export const labelingScanSubtypeIsNotTaggingScan = (type: ParentScanType, name: string) =>
  type === ParentScanType.LABELING && name.startsWith('Import Labels Operation');

export const isScanNameClickable = (row: ScansGridRow) => {
  if (labelingScanSubtypeIsTaggingScan(row.type, row.name)) {
    return getApplicationPreference('SHOW_NEW_SCAN_INSIGHT') && row.hasInsight;
  }

  return scansWithInsights.includes(row.type);
};

export const getScanTypeName = (scanType: string) => {
  const t = getFixedT('convertScanProfile');
  switch (scanType) {
    case ScanTypes.DS_SCAN:
      return t('header.fullScan');
    case ScanTypes.LINEAGE_SCAN:
      return t('header.lineageScan');
    case identityDiscoveryScan.type:
      return t('header.identityDiscoveryScan');
    case ScanTypes.METADATA_SCAN:
      return t('header.metadataScan');
    case ScanTypes.DATA_IN_MOTION:
      return t('header.dataInMotion');
    case ScanTypes.ASSESSMENT_SCAN:
      return t('header.assessmentScan');
    case ScanTypes.LABELING:
      return t('header.labeling');
    case ScanTypes.HYPER_SCAN:
      return t('header.hyperScan');
    default:
      break;
  }
};

const checkIsScanTemplateEnabled = (): boolean => {
  const isScanTemplateFF = getApplicationPreference('ENABLE_SCAN_TEMPLATES');
  if (isScanTemplateFF) {
    return true;
  }
  return false;
};

export const navigateToForbiddenPageIfScanTemplateOff = async () => {
  const isScanTemplateEnabled = await checkIsScanTemplateEnabled();
  if (!isScanTemplateEnabled) {
    $state.go(CONFIG.states.FORBIDDEN);
  }
};

export const isScanIsTemplateBasedFFOff = (scanTemplateId: string): boolean =>
  !checkIsScanTemplateEnabled() && scanTemplateId !== 'Legacy';

export const getChipPropsByState = (state: ScanState): BigidChipProps => {
  switch (state) {
    case CompletedParentScanState.FAILED:
      return {
        label: startCase(state),
        bgColor: BigidColorsV2.red[100],
        color: BigidColorsV2.red[700],
      };
    case CompletedParentScanState.COMPLETED_WITH_FAILURES:
    case CompletedParentScanState.COMPLETED:
      return {
        label: startCase(state),
        bgColor: BigidColorsV2.green[100],
        color: BigidColorsV2.green[700],
      };
    case CompletedParentScanState.STOPPED:
    case ActiveScanState.STOP_REQUESTED:
    case CompletedParentScanState.ABORTED:
      return {
        label: startCase(state),
        bgColor: BigidColorsV2.gray[150],
        color: BigidColorsV2.gray[500],
      };
    case ActiveScanState.IN_PROGRESS:
    case ActiveScanState.RESUME_REQUESTED:
    case ActiveScanState.STARTED:
    case ActiveScanState.QUEUED:
    case ActiveScanState.COLLECTING_METADATA:
      return {
        label: startCase(state),
        bgColor: BigidColorsV2.blue[100],
        color: BigidColorsV2.blue[900],
      };
    case ActiveScanState.NOT_RUNNING:
    case ActiveScanState.PAUSE_REQUESTED:
    case ActiveScanState.PAUSED:
    case ActiveScanState.PENDING:
      return {
        label: startCase(state),
        bgColor: BigidColorsV2.gray[150],
        color: BigidColorsV2.gray[700],
      };
    default:
      console.warn(`state: ${state} not match to any case.`);
      return {
        label: startCase(state),
        bgColor: BigidColorsV2.purple[100],
        color: BigidColorsV2.purple[900],
      };
  }
};

export function getMaxAmountOfDsPerScanFF() {
  const ff = getApplicationPreference('MAX_AMOUNT_OF_DS_PER_SCAN');
  return isNaN(ff) || ff < 1 ? 1000 : ff;
}
