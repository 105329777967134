import React from 'react';
import { styled } from '@mui/material';
import { DataCatalogSimilarTables } from '../../../DataCatalog/DataCatalogSimilarTables/DataCatalogSimilarTables';

export const GridWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-grow: 1;
`;

type DataCatalogSimilarTablesProps = {
  fullyQualifiedName: string;
};

export const SimilarTablesTab: React.FC<DataCatalogSimilarTablesProps> = ({ fullyQualifiedName }) => {
  return (
    <GridWrapper>
      <DataCatalogSimilarTables fullyQualifiedName={fullyQualifiedName} key="SimilarTablesTab" />
    </GridWrapper>
  );
};
