import React, { useRef, useState } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidMoreActionIcon, BigidFilterFunnelAddIcon, BigidEditIcon } from '@bigid-ui/icons';
import { BigidMenu, BigidMenuProps, BigidButtonIcon, BigidMenuItemProps } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { openAddColumnAliasDialog } from '../../components/Dialogs/AddColumnAliasDialog';
import { openAddColumnFilterDialog } from '../Dialogs/AddColumnFilterDialog';

const DATA_AID = 'GridCellDropdownMenu';

interface GridCellDropdownMenuProps {
  handleSaveColumnAlias: (aliasName: string) => void;
  handleSaveColumnFilter: (filter: string) => void;
  aliasColumnName: string;
  filter: string;
}

export const GridCellDropdownMenu = ({
  aliasColumnName,
  handleSaveColumnAlias,
  filter,
  handleSaveColumnFilter,
}: GridCellDropdownMenuProps) => {
  const { t } = useLocalTranslation('wizard.previewStep');
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLButtonElement>(null);

  const menuProps: BigidMenuProps = {
    open: isOpen,
    onMenuClose: () => setIsOpen(false),
    anchorEl: iconRef.current,
    items: [
      {
        label: t('addAliasBtn'),
        startIcon: BigidEditIcon,
        onClick: async () => {
          const { isSaved, aliasName } = await openAddColumnAliasDialog({ alias: aliasColumnName || '' });
          if (isSaved) {
            handleSaveColumnAlias(aliasName);
          }
        },
        dataAid: generateDataAid(DATA_AID, ['add-alias']),
      },
      {
        label: t('addFilterBtn'),
        startIcon: BigidFilterFunnelAddIcon,
        onClick: async () => {
          const { isSaved, filterValue } = await openAddColumnFilterDialog({ filter: filter || '' });
          if (isSaved) {
            handleSaveColumnFilter(filterValue);
          }
        },
        dataAid: generateDataAid(DATA_AID, ['add-filter']),
      },
    ],
  };

  return (
    <>
      <BigidButtonIcon
        ref={iconRef}
        selected={isOpen}
        variant="tertiary"
        icon={BigidMoreActionIcon}
        onClick={() => setIsOpen(!isOpen)}
        dataAid={generateDataAid('BigidButtonIcon', ['drop-down-actions-opener'])}
      />
      <BigidMenu {...menuProps} paperStyle={{ marginTop: '8px' }} />
    </>
  );
};
