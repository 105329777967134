import { BigidHeading6 } from '@bigid-ui/components';
import { styled, SxProps, Theme } from '@mui/material';
import React from 'react';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContentContainer = styled('div')<Pick<ExpandableSectionProps, 'contentOrientation' | 'gap'>>`
  display: flex;
  flex-direction: ${({ contentOrientation }) => (contentOrientation === 'horizontal' ? 'row' : 'column')};
  gap: ${({ gap }) => gap}px;
  flex-wrap: wrap;
  align-items: center;
`;

type ExpandableSectionProps = {
  title: string;
  children: React.ReactNode;
  contentOrientation?: 'vertical' | 'horizontal';
  gap?: number;
  sx?: SxProps<Theme>;
};

export const OverviewSection = ({
  title,
  children,
  contentOrientation = 'vertical',
  gap,
  sx,
}: ExpandableSectionProps) => {
  return (
    <Root sx={sx}>
      <BigidHeading6>{title}</BigidHeading6>
      <ContentContainer contentOrientation={contentOrientation} gap={gap}>
        {children}
      </ContentContainer>
    </Root>
  );
};
