import React, { useEffect } from 'react';
import { BigidDsSuccessIllustration, BigidEditIcon } from '@bigid-ui/icons';
import {
  Content,
  Image,
  Overlay,
  Actions,
  ScansButtonsContainer,
  CustomScanButtonWrapper,
  ScanNowButtonWrapper,
  ButtonsSeparator,
} from './DataSourceConnectionOverlayStyles';
import { Typography } from '@mui/material';
import {
  PrimaryButton,
  SecondaryButton,
  BigidLink,
  BigidTooltip,
  BigidButtonIcon,
  BigidDialogButtonType as BigidButtonType,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';
import { trackEventDataSources, DataSourcesUITrackingEvent } from '../../DataSourcesEventTrackerUtils';
import { BigidSplitMenuButton } from '../../../../components/BigidSplitMenuButton/BigidSplitMenuButton';

export type DataSourceConnectionOverlaySuccessProps = {
  dataAid?: string;
  onShowResults: () => void;
  onStartScan: () => void;
  onStartCustomScan: () => void;
  onBackToEdit?: () => void;
  isScanAvailable: boolean;
  isDetached?: boolean;
  owner?: string;
  scans?: BigidButtonType[];
};

const DEFAULT_OWNER = 'Owner';

export const DataSourceConnectionOverlaySuccess = ({
  dataAid = 'DataSourceConnectionOverlaySuccess',
  onShowResults,
  onStartScan,
  onStartCustomScan,
  onBackToEdit,
  isScanAvailable,
  isDetached = false,
  owner,
  scans,
}: DataSourceConnectionOverlaySuccessProps): JSX.Element => {
  const { t } = useLocalTranslation();
  const { configDataSourceState, isDsCollaborator, isStandalone } = useDataSourceModalContext();

  const Button = isDsCollaborator ? PrimaryButton : SecondaryButton;

  useEffect(() => {
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SUCCESS_PAGE_VIEW, {
      dsType: configDataSourceState?.selectedType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Overlay data-aid={dataAid}>
      <Image>
        <BigidDsSuccessIllustration />
      </Image>
      <Content isStandalone={isStandalone}>
        <Typography variant="h1">{t('connectionSuccessOverlay.header')}</Typography>
        <Typography textAlign="center" variant="body1">
          {isDsCollaborator ? (
            <>
              <b>{owner || DEFAULT_OWNER}</b> {t('collaboration.overlay')}
            </>
          ) : (
            t('connectionSuccessOverlay.content')
          )}
          <br />
          {t('connectionSuccessOverlay.sub')}
          <BigidLink underline="none" onClick={onShowResults} text={t('connectionSuccessOverlay.sub2')} />
          {'.'}
        </Typography>
        <Actions>
          {!isDsCollaborator && (
            <ScansButtonsContainer>
              <BigidTooltip placement="top" title={t('scanNowDisclaimer')}>
                <>
                  {scans ? (
                    scans.map(({ component, ...rest }) => {
                      const Button = component as unknown as typeof BigidSplitMenuButton;
                      return <Button key={rest.text} size="large" {...rest} buttonType={'primary'} />;
                    })
                  ) : (
                    <ScanNowButtonWrapper>
                      <PrimaryButton
                        dataAid="DataSourceConnectionModal-scanNow"
                        disabled={!isScanAvailable}
                        size="large"
                        onClick={onStartScan}
                        bi={{ disabled: true }}
                        text={t('buttons.scan')}
                      />
                    </ScanNowButtonWrapper>
                  )}
                </>
              </BigidTooltip>
              {!scans && (
                <>
                  <ButtonsSeparator />
                  <BigidTooltip placement="top" title={t('createCustomScan')}>
                    <CustomScanButtonWrapper>
                      <BigidButtonIcon
                        icon={BigidEditIcon}
                        dataAid="DataSourceConnectionModal-customScan"
                        disabled={!isScanAvailable}
                        onClick={onStartCustomScan}
                        size="large"
                        variant="primary"
                        bi={{ disabled: true }}
                      />
                    </CustomScanButtonWrapper>
                  </BigidTooltip>
                </>
              )}
            </ScansButtonsContainer>
          )}
          <Button
            dataAid="DataSourceConnectionModal-backToEdit"
            size="large"
            onClick={onBackToEdit}
            bi={{ disabled: true }}
          >
            {isDsCollaborator || isDetached ? t('buttons.close') : t('buttons.backToEdit')}
          </Button>
        </Actions>
      </Content>
    </Overlay>
  );
};
