import React, { FC, ReactElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { userPreferencesService } from '../../services/userPreferencesService';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidChevronRightIcon } from '@bigid-ui/icons';
import {
  BigidCheckbox,
  BigidConditionalWrapper,
  BigidTooltip,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import {
  updateEaProgramUserSelection,
  EA_PROGRAM_USER_PREFERENCES,
  EaProgramFeature,
  EaProgramSubView,
  eaFeatureItems,
} from './eaProgramModalService';
import { useLocalTranslation } from './translations';
import { closeSystemDialog } from '../../services/systemDialogService';
import { EaProgramEvents, trackEaProgramEvent } from './eaProgramEventsUtil';

const EA_PROGRAM_FOOTER_COMPONENT_NAME = 'EaProgramModalFooter';

const ModalFooter = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 16,
  paddingBottom: 16,
  justifyContent: 'space-between',
});

const FooterSection = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

const Indicator = styled('div')({
  fontSize: '0.75rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
});

export interface EaProgramModalFooterProps {
  isAdmin: boolean;
  isDismissed: boolean;
  currentActiveView: EaProgramSubView;
  updateCurrentActiveView: React.Dispatch<React.SetStateAction<EaProgramSubView>>;
  selectedFeatures: EaProgramFeature[];
}

export enum EaPrimaryButtonModes {
  CHOOSE_FEATURES = 'chooseFeatures',
  JOIN_PROGRAM = 'joinProgram',
}

export const EaProgramModalFooter: FC<EaProgramModalFooterProps> = ({
  isAdmin,
  isDismissed,
  currentActiveView,
  updateCurrentActiveView,
  selectedFeatures,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(isDismissed);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);
  const { t } = useLocalTranslation();

  const onCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsCheckboxChecked(checked);
    const { data = {} } = (await userPreferencesService.get(EA_PROGRAM_USER_PREFERENCES)) || {};
    await userPreferencesService.update({
      preference: EA_PROGRAM_USER_PREFERENCES,
      data: { ...data, isDismissed: checked },
    });
    if (checked) {
      trackEaProgramEvent(EaProgramEvents.EA_PROGRAM_DONT_SHOW_AGAIN_CHECKED);
    }
  };

  const onJoinSelection = (isAccepted: boolean, eaEventType: EaProgramEvents) => {
    if (isAdmin) {
      updateEaProgramUserSelection(isAccepted);
    }
    const requestedFeatures = selectedFeatures.map(item => eaFeatureItems.find(obj => obj.id === item)?.title);

    trackEaProgramEvent(eaEventType, { ...(isAccepted && { requestedFeatures }) });
    closeSystemDialog();
  };

  const onPrimaryButtonSelection = () => {
    if (currentActiveView === EaProgramSubView.FEATURES_INTRO) {
      updateCurrentActiveView(EaProgramSubView.FEATURES_SELECTION);
    } else {
      onJoinSelection(true, EaProgramEvents.EA_PROGRAM_CUSTOMER_INTEREST_TO_JOIN);
    }
  };

  useEffect(() => {
    const shouldDisablePrimaryButton = () => {
      if (!isAdmin) return true;
      if (currentActiveView === EaProgramSubView.FEATURES_INTRO) return false;
      return selectedFeatures.length === 0;
    };

    setIsPrimaryButtonDisabled(shouldDisablePrimaryButton());
  }, [isAdmin, currentActiveView, selectedFeatures]);

  const primaryButtonMode =
    currentActiveView === EaProgramSubView.FEATURES_INTRO
      ? EaPrimaryButtonModes.CHOOSE_FEATURES
      : EaPrimaryButtonModes.JOIN_PROGRAM;

  return (
    <ModalFooter>
      <FooterSection data-aid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['footer'])}>
        <BigidCheckbox onChange={onCheckboxChange} checked={isCheckboxChecked} label={t('dontShowAgain')} />
      </FooterSection>
      <FooterSection>
        <TertiaryButton
          dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['not-now', 'button'])}
          size={'medium'}
          bi={{ disabled: true }}
          onClick={() =>
            onJoinSelection(
              false,
              isAdmin ? EaProgramEvents.EA_PROGRAM_DECLINE_CLICK : EaProgramEvents.EA_PROGRAM_DECLINE_NON_ADMIN,
            )
          }
          text={t('buttonLabels.decline')}
        />
        <BigidConditionalWrapper
          condition={isPrimaryButtonDisabled}
          wrapper={(children: ReactElement) => (
            <BigidTooltip
              dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, [primaryButtonMode, 'tooltip'])}
              placement="top"
              title={
                primaryButtonMode === EaPrimaryButtonModes.CHOOSE_FEATURES
                  ? t('contactAdminToJoin')
                  : t('chooseMinFeatures')
              }
            >
              <div>{children}</div>
            </BigidTooltip>
          )}
        >
          <PrimaryButton
            dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, [primaryButtonMode, 'button'])}
            bi={{ disabled: true }}
            disabled={isPrimaryButtonDisabled}
            size={'medium'}
            onClick={onPrimaryButtonSelection}
            text={t(`buttonLabels.${primaryButtonMode}`)}
            endIcon={primaryButtonMode === EaPrimaryButtonModes.CHOOSE_FEATURES && <BigidChevronRightIcon />}
          />
        </BigidConditionalWrapper>
      </FooterSection>
    </ModalFooter>
  );
};
