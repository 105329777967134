import React from 'react';
import { styled } from '@mui/material';
import { DataDuplicationAreaChart } from './DataDuplicationAreaChart';
import { DataDuplicationHeader } from '../components/DataDuplicationHeader';
import { DataDuplicationChartsProps } from '../types/DataDuplicationWidgetTypes';
import { BigidLoader } from '@bigid-ui/components';

const ChartsContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
`;

const AreaChartWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
`;

export const DataDuplicationCharts: React.FC<DataDuplicationChartsProps> = ({
  selectedView,
  data,
  isLoading,
  latestEntry,
}) => {
  return (
    <ChartsContainer>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <AreaChartWrapper>
          {latestEntry && <DataDuplicationHeader latestEntry={latestEntry} />}
          <DataDuplicationAreaChart selectedView={selectedView} data={data} />
        </AreaChartWrapper>
      )}
    </ChartsContainer>
  );
};
