import { BigidBody1, BigidColors, SecondaryButton, ToolbarAction } from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, BigidMasterDetailsContentProps, LayoutContentType } from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import angular from 'angular';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import React, { FC, useMemo } from 'react';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { $state } from '../../../services/angularServices';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { isPermitted } from '../../../services/userPermissionsService';
import { PlaceHolderComponent } from '../SarProfileSettings/PlaceHolderComponent';
import { DEFAULT_TEMPLATE_NAME } from './consts';
import { ReportTemplateForm } from './ReportTemplateForm';
import { createNewTemplateExecuter } from './ReportTemplatesActions';
import { deleteTemplate, getAllTemplates } from './reportTemplatesService';
import { ReportTemplate } from './Types';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: BigidColors.containerShadow,
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
});

const columns: BigidGridColumn<ReportTemplate>[] = [
  {
    title: 'Template Name',
    name: 'name',
    isListColumn: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ templateName }) => templateName,
  },
];

export const ReportTemplatesLayout: FC = () => {
  const hasEditPermission = isPermitted(DSAR_PERMISSIONS.EDIT_REPORT_TEMPLATES.name);
  const isReadOnlyMode = !hasEditPermission;

  const classes = useStyles({});

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    setSelectedItemInFetch: true,
    placeholderComponent: (
      <PlaceHolderComponent>
        <BigidBody1>
          Select a Report Template
          {!isReadOnlyMode && (
            <>
              {' or'}
              <span style={{ padding: 12 }}>
                <SecondaryButton
                  size={'small'}
                  onClick={() => createNewTemplateExecuter('externally')}
                  text="Add new Template"
                />
              </span>
            </>
          )}
        </BigidBody1>
      </PlaceHolderComponent>
    ),
    tabsAndContent: {
      classes: {
        contentContainer: classes.contentContainer,
      },
      hideTabs: true,
      tabProps: {
        selectedIndex: 0,
        tabs: [
          {
            label: 'Report Template',
            data: {
              component: ReportTemplateForm,
            },
          },
        ],
      },
    },
  };

  const layoutGridConfig: BigidGridProps<ReportTemplate> = {
    pageSize: 1000,
    showSelectionColumn: false,
    columns,
  };

  const fetchGridData: FetchDataFunction<ReportTemplate> = async (query, { setSelectedItem }) => {
    let templates: ReportTemplate[] = [];
    try {
      templates = (await getAllTemplates(['id', 'templateName', 'templateTitle', 'templateDescription'])).sort(
        ({ templateName }) => (templateName === DEFAULT_TEMPLATE_NAME ? -1 : 0),
      );

      if (templates.length) {
        const { templateId } = $state.params;
        const selectedItem = (templateId && templates.find(({ _id }) => _id === templateId)) || templates[0];
        setSelectedItem(selectedItem);
      }
    } catch {}
    return {
      data: templates,
      totalCount: templates.length,
    };
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'New Template',
      icon: AddIcon,
      isGlobal: true,
      execute: async () => {
        return createNewTemplateExecuter('action');
      },
      disable: () => false,
      show: () => hasEditPermission,
    },
    {
      label: 'Delete Template',
      icon: DeleteOutlineRoundedIcon,
      execute: async ({ selectedItem }) => {
        if (!selectedItem.id || selectedItem.templateName === DEFAULT_TEMPLATE_NAME) {
          return {};
        }
        if (
          await showConfirmationDialog({
            entitiesCount: 1,
            entityNameSingular: selectedItem.templateName,
            entityNamePlural: selectedItem.templateName,
          })
        ) {
          await deleteTemplate(selectedItem._id);
          $state.go('.', { ...$state.params, templateId: null }, { reload: false, notify: false });
          return { shouldGridReload: true, shouldClearSelection: true };
        }
        return {};
      },
      disable: () => false,
      show: ({ selectedRowIds, selectedItem }) => {
        return (
          hasEditPermission &&
          selectedItem &&
          selectedItem?.templateName !== DEFAULT_TEMPLATE_NAME &&
          selectedRowIds.length > 0
        );
      },
    },
  ];

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'Templates',
      toolbarActions,
      noPadding: true,
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        fetchGridData,
        gridConfig: layoutGridConfig,
        masterDetailsConfig,
        selectedItemPropsMapping: {
          id: 'id',
          name: 'templateName',
        },
      },
    },
  };

  return <BigidLayout config={layoutConfig} />;
};

const ReportTemplatesLayoutFFWrapper: FC = () => {
  const shouldShow = useMemo(() => {
    return getApplicationPreference('USE_DSAR_REPORT_TEMPLATES');
  }, []);
  return shouldShow ? <ReportTemplatesLayout /> : null;
};

angular.module('app').component('reportTemplatesLayout', convertToAngular(ReportTemplatesLayoutFFWrapper));
