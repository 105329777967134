import React, { FC } from 'react';
import { InteractiveDashboardInfoBoxWidget } from './InteractiveDashboardInfoBoxWidget';
import { BigidFindingsIcon } from '@bigid-ui/icons';
import { makeTitleActions } from './actions';
import { isArray, isNumber } from 'lodash';
import { PoliciesTriggered } from './ACIDashboardWrapper';

export interface ACIDashboardObjectsFoundByAccessPoliciesProps {
  triggeredAccessPolicies: PoliciesTriggered['triggeredAccessPolicies'];
  totalObjectsFoundByAccessPolicies: PoliciesTriggered['totalObjectsFoundByAccessPolicies'];
  selectedDatasources: string[];
}

export const ACIDashboardObjectsFoundByAccessPolicies: FC<ACIDashboardObjectsFoundByAccessPoliciesProps> = ({
  triggeredAccessPolicies,
  totalObjectsFoundByAccessPolicies,
  selectedDatasources,
}) => {
  return (
    <>
      {isNumber(totalObjectsFoundByAccessPolicies) && isArray(triggeredAccessPolicies) && (
        <InteractiveDashboardInfoBoxWidget
          icon={<BigidFindingsIcon size="large" />}
          data={<div>{totalObjectsFoundByAccessPolicies + ''}</div>}
          titleActions={makeTitleActions.objectsFoundByAccessPolicies(
            selectedDatasources,
            triggeredAccessPolicies,
            totalObjectsFoundByAccessPolicies,
          )}
          description={'Objects Found by Access Policies'}
        />
      )}
      {totalObjectsFoundByAccessPolicies === 'IN_PROGRESS' && triggeredAccessPolicies === 'IN_PROGRESS' && (
        <InteractiveDashboardInfoBoxWidget data={<>Loading...</>} titleActions={[]} description={''} />
      )}
    </>
  );
};
