import React, { FC } from 'react';
import { BigidTabsAndContent, BigidTabsAndContentProps } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { LabelsConfigurations } from './Configuration/LabelsConfigurations';
import { ActiveDirectorySettings } from './ActiveDirectoryConfiguration/ActiveDirectorySettings';
import { Domains } from './Domains/Domains';

const useStyles = makeStyles({
  root: {
    height: '95%',
  },
});

export const AccessIntelligenceSettings: FC = () => {
  const classes = useStyles();

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: {
      contentContainer: classes.root,
    },
    tabProps: {
      tabs: [
        {
          label: 'Access Types',
          data: { component: LabelsConfigurations },
        },
        {
          label: 'Domains',
          data: { component: Domains },
        },
        {
          label: 'Active Directory Setup',
          data: { component: ActiveDirectorySettings },
        },
      ],
      selectedIndex: 0,
    },
  };

  return (
    <div className={classes.root}>
      <BigidTabsAndContent {...tabsAndContentConfig} />
    </div>
  );
};
