import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BigidAddIcon } from '@bigid-ui/icons';
import { BigidTooltip, PrimaryButton } from '@bigid-ui/components';
import { COMPLIANCE_REGULATIONS_PERMISSIONS } from '@bigid/permissions';

import { isPermitted } from '../../../services/userPermissionsService';
import { notificationService } from '../../../services/notificationService';
import { fetchRegulationsData } from '../RegulationServices';
import { REGULATIONS_NAMESPACE } from '../translations';
import { AddRegulationsModal } from './AddRegulationsModal';
import { regulationsEventEmitter, RegulationsEvents } from './regulationsEventEmitter';

export function AddRegulationsButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(REGULATIONS_NAMESPACE);
  const [isDisabled, setIsDisabled] = useState(true);
  const canAdd = isPermitted(COMPLIANCE_REGULATIONS_PERMISSIONS.EDIT.name);

  const handleCheckRegulations = useCallback(async () => {
    try {
      const { totalCount } = await fetchRegulationsData({
        limit: 1,
        requireTotalCount: true,
        filter: [
          {
            field: 'enabled',
            operator: 'equal',
            value: false,
          },
        ],
      });
      setIsDisabled(!totalCount);
    } catch (err) {
      console.error(err);
      setIsDisabled(true);
      notificationService.error(t('Error.requests.fetchRegulations'));
    }
  }, [t]);

  useEffect(() => {
    if (!canAdd) return;
    handleCheckRegulations();
    const unsubscribeAdd = regulationsEventEmitter.addEventListener(
      RegulationsEvents.REGULATIONS_ADDED,
      handleCheckRegulations,
    );
    const unsubscribeRemove = regulationsEventEmitter.addEventListener(
      RegulationsEvents.REGULATIONS_REMOVED,
      handleCheckRegulations,
    );

    return () => {
      unsubscribeAdd();
      unsubscribeRemove();
    };
  }, [handleCheckRegulations, canAdd]);

  return canAdd ? (
    <>
      <BigidTooltip
        fontSize="medium"
        placement="top"
        title={isDisabled ? t('Messages.noMoreRegulationsToAdd') : t('Labels.addRegulations')}
      >
        <div>
          <PrimaryButton
            text={t('Labels.addRegulations')}
            size="medium"
            startIcon={<BigidAddIcon />}
            onClick={() => setIsModalOpen(true)}
            disabled={isDisabled}
          />
        </div>
      </BigidTooltip>
      {isModalOpen && <AddRegulationsModal isOpen onClose={() => setIsModalOpen(false)} />}
    </>
  ) : null;
}
