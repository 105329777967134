import { BigidColorsV2, BigidIconType } from '@bigid-ui/components';
import {
  BigidAppLogoDiscoveryColorfulIcon,
  BigidAppLogoDiscoveryIcon,
  BigidAppLogoPrivacyColorfulIcon,
  BigidAppLogoPrivacyIcon,
  BigidAppLogoSecurityColorfulIcon,
  BigidAppLogoSecurityIcon,
  BigidHorizontalDiscoveryLogoIcon,
  BigidHorizontalPrivacyLogoIcon,
  BigidHorizontalSecurityLogoIcon,
} from '@bigid-ui/icons';
import { CONFIG } from '../../config/common';
import { isPagePermitted } from '../views/Login/loginUtils';
import { getApplicationPreference } from '../services/appPreferencesService';
import {
  SIDE_NAVIGATION_BY_WORKSPACE,
  SideNavigationPreferencesByWorkspaces,
} from '../components/NavigationSideBar/navigationSidebarUtils';
import { userPreferencesService } from '../services/userPreferencesService';
import { AppInfo } from '../views/CustomApp/utils/CustomAppTypes';
import { isPermitted } from '../services/userPermissionsService';
import { GETTING_STARTED_PERMISSIONS } from '@bigid/permissions';
import { customAppService } from '../services/customAppService';
import { isGettingStartedSecurityEnabled } from './featureFlagUtils';

export enum WorkspaceId {
  DISCOVERY = 'discovery',
  PRIVACY = 'privacy',
  SECURITY = 'security',
  ADMIN = 'admin',
}

export interface Workspace {
  id: WorkspaceId;
  name: string;
  icon: BigidIconType;
  colorfulIcon: BigidIconType;
  headerIcon: BigidIconType;
  color: string;
  getHomePage: () => string;
}

export const SECURITY_WORKSPACE_DASHBOARD_NAME = 'Security Dashboard (Security Workspace)';

export const DEFAULT_WORKSPACE = WorkspaceId.DISCOVERY;

export const ALL_WORKSPACES = Object.values(WorkspaceId);

const stateNameToWorkspace: { [K in keyof typeof CONFIG.states]: WorkspaceId[] } = {
  LOGIN: ALL_WORKSPACES,
  DATA_SOURCE_INIT: ALL_WORKSPACES,
  PRIVACY_PORTAL: ALL_WORKSPACES,
  FAILED_OBJECT_REPORT: ALL_WORKSPACES,
  ACTIONABLE_INSIGHTS_CASE: ALL_WORKSPACES,
  BUSINESS_FLOWS_ROPA: ALL_WORKSPACES,
  DASHBOARD: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  DASHBOARD_SECURITY: [WorkspaceId.SECURITY],
  RISK_REGISTER: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  DASHBOARD_COMPLIANCE: [WorkspaceId.SECURITY, WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  CREDENTIAL_PROVIDERS: [WorkspaceId.ADMIN],
  SCAN_RESULT: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  INVENTORY: [WorkspaceId.DISCOVERY],
  PRIVACY_COMPLIANCE: ALL_WORKSPACES,
  UPDATE_PASSWORD: ALL_WORKSPACES,
  USERS: ALL_WORKSPACES,
  DATA_SOURCE_CONNECTIONS: ALL_WORKSPACES,
  DATA_SOURCE_ROUTER: ALL_WORKSPACES,
  EDIT_DATA_SOURCE_CONNECTION: ALL_WORKSPACES,
  NEW_DATA_SOURCE_CONNECTION: ALL_WORKSPACES,
  IDENTITY_SOURCE_CONNECTIONS: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  DATA_OVERVIEW: ALL_WORKSPACES,
  DATA_MINIMIZATION: [WorkspaceId.PRIVACY],
  DATA_MINIMIZATION_REQUESTS: [WorkspaceId.PRIVACY],
  DATA_MINIMIZATION_DATA_SOURCES: ALL_WORKSPACES,
  DATA_MINIMIZATION_ACTIVITY_LOGS: [WorkspaceId.PRIVACY],
  DATA_MINIMIZATION_OBJECT: ALL_WORKSPACES,
  DATA_DELETION_GLOBAL_SETTINGS: ALL_WORKSPACES,
  NEW_IDENTITY_SOURCE_CONNECTION: ALL_WORKSPACES,
  SECONDARY_CONNECTIONS: [WorkspaceId.ADMIN],
  NEW_SECONDARY_CONNECTION: ALL_WORKSPACES,
  SCANS: ALL_WORKSPACES,
  EDIT_SCAN_PROFILE: ALL_WORKSPACES,
  CREATE_SCAN_PROFILE: ALL_WORKSPACES,
  APPLICATIONS_SETUP: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  GENERAL_SETTINGS: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  RISK_CONFIGURATION: ALL_WORKSPACES,
  BUSINESS_FLOWS: ALL_WORKSPACES,
  CORRELATION: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  ADVANCED_TOOLS: [WorkspaceId.ADMIN],
  RULES: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  TASKS_LIST: ALL_WORKSPACES,
  BREACH_RESPONSE: ALL_WORKSPACES,
  ACCESS_REQUEST_MANAGEMENT: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  ACCESS_REQUEST_MANAGEMENT_PROFILE: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  ACCESS_REQUEST_MANAGEMENT_JOINT_CORRELATION: ALL_WORKSPACES,
  ACCESS_REQUEST_MANAGEMENT_DICTIONARY: ALL_WORKSPACES,
  ACCESS_REQUEST_MANAGEMENT_REPORT_TEMPLATES: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  ACCESS_REQUEST_MANAGEMENT_FILES_RETRIEVAL: ALL_WORKSPACES,
  PERSONAL_INFO_REPORT: ALL_WORKSPACES,
  NEW_BREACH_RESPONSE: ALL_WORKSPACES,
  EXPLORE_BREACH_RESPONSE: ALL_WORKSPACES,
  FORBIDDEN: ALL_WORKSPACES,
  NOT_FOUND: ALL_WORKSPACES,
  CONNECTION_LOST: ALL_WORKSPACES,
  UNDER_MAINTENANCE: ALL_WORKSPACES,
  CREDENTIALS: [WorkspaceId.ADMIN],
  PROXIES: [WorkspaceId.ADMIN],
  CLASSIFIERS: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  CLASSIFICATION: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  SAVED_QUERIES: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  TAGS: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  POLICIES: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  AUDIT: [WorkspaceId.ADMIN],
  SCAN_ANALYSIS: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  AZURE_INFORMATION_PROTECTION: [WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  IDENTITY_ACCESS_MANAGEMENT: [WorkspaceId.ADMIN],
  ACCESS_GOVERNANCE_SETTINGS: ALL_WORKSPACES,
  ACCESS_GOVERNANCE: [WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  USER_PERMISSIONS: ALL_WORKSPACES,
  GROUP_PERMISSIONS: ALL_WORKSPACES,
  LABELS_CONFIGURATION: ALL_WORKSPACES,
  ACCESS_INTELLIGENCE_SETTINGS: ALL_WORKSPACES,
  CONSENT_GOVERNANCE: ALL_WORKSPACES,
  CONSENT_GOVERNANCE_LIST: ALL_WORKSPACES,
  CONSENT_GOVERNANCE_AGREEMENTS: ALL_WORKSPACES,
  CONSENT_GOVERNANCE_REPORTS: ALL_WORKSPACES,
  CLUSTERING: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  CLUSTERING_OBJECTS_PREVIEW: ALL_WORKSPACES,
  DUPLICATE_OBJECTS_PREVIEW: ALL_WORKSPACES,
  NEW_CONSENT_CONNECTION: ALL_WORKSPACES,
  CATALOG: ALL_WORKSPACES,
  CATALOG_PREVIEW: ALL_WORKSPACES,
  CATALOG_FOLLOWED_OBJECTS: ALL_WORKSPACES,
  CLUSTER_PREVIEW: ALL_WORKSPACES,
  CERTIFICATES_MANAGEMENT: [WorkspaceId.ADMIN],
  DIGEST_REPORT: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  DATA_INSIGHTS_STUDIO: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  DASHBOARD_DISCOVERY: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  DATA_PIPELINE: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  CUSTOM_APP_CREATE: ALL_WORKSPACES,
  CUSTOM_APP_ACTIVITY_LOG: ALL_WORKSPACES,
  CUSTOM_APP_EDIT: ALL_WORKSPACES,
  CUSTOM_APP_EDIT_PRESET: ALL_WORKSPACES,
  CUSTOM_APP_ACTIONS: ALL_WORKSPACES,
  CUSTOM_APP_DEEP_LINKING: ALL_WORKSPACES,
  CUSTOM_APP: ALL_WORKSPACES,
  CUSTOM_DASHBOARD: [WorkspaceId.ADMIN],
  CUSTOM_DASHBOARD_PREVIEW: ALL_WORKSPACES,
  DATA_SOURCE_CONNECTIONS_INIT: ALL_WORKSPACES,
  APPLICATIONS_MANAGEMENT: ALL_WORKSPACES,
  CONFIG_DATA_SOURCE_NEW: ALL_WORKSPACES,
  CONFIG_DATA_SOURCE: ALL_WORKSPACES,
  SCAN_WINDOW: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  ACTION_CENTER: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  ACTION_CENTER_OVERVIEW: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  ACTION_CENTER_ACTIONS: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  ACTION_CENTER_AUDIT: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  ACTION_CENTER_CONFIGURATIONS: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  METADATA_SEARCH_RESULTS: ALL_WORKSPACES,
  SENSITIVITY_CLASSIFICATION: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  SENSITIVITY_CLASSIFICATION_NEW: ALL_WORKSPACES,
  SENSITIVITY_CLASSIFICATION_EDIT: ALL_WORKSPACES,
  SCANS_NEW: ALL_WORKSPACES,
  SCANS_NEW_SCANS: ALL_WORKSPACES,
  SCANS_NEW_SCANS_IN_PROGRESS: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  SCANS_NEW_SCANS_COMPLETED: ALL_WORKSPACES,
  SCANS_CONTROLLER: ALL_WORKSPACES,
  SCANS_SAVED_PROFILES: ALL_WORKSPACES,
  SCANS_SCAN_TEMPLATES: ALL_WORKSPACES,
  SCANS_PLANNED_SCANS: ALL_WORKSPACES,
  SCANS_SCAN_INSIGHTS: ALL_WORKSPACES,
  FMSD: ALL_WORKSPACES,
  ACTIONABLE_INSIGHTS: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  AUTO_DISCOVERY: ALL_WORKSPACES,
  SMALLID_FOR_DEVELOPERS: ALL_WORKSPACES,
  EXECUTIVE_REPORT: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  HOTSPOTS_REPORT: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  HOTSPOTS_REPORT_NEW: ALL_WORKSPACES,
  CURATION: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  SCAN_TEMPLATE: ALL_WORKSPACES,
  CREATE_SCAN: ALL_WORKSPACES,
  CATALOG_DISCOVERY: ALL_WORKSPACES,
  GETTING_STARTED: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  GETTING_STARTED_SECURITY: [WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  RISK_ASSESSMENT: [WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  DATA_EXPLORER: ALL_WORKSPACES,
  CATALOG_SEARCH_RESULTS: ALL_WORKSPACES,
  APP_DATA_STEWARDSHIP: ALL_WORKSPACES,
  CLASSIFIERS_MANAGEMENT: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  CUSTOM_APP_EDIT_GLOBAL_PRESET: ALL_WORKSPACES,
  VENDORS: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  CLASSIFIER_FINDINGS: ALL_WORKSPACES,
  CLASSIFIER_PREDICTIONS: ALL_WORKSPACES,
  VENDOR_SUMMARY: ALL_WORKSPACES,
  NEW_DATA_CATALOG: ALL_WORKSPACES,
  AUTO_DISCOVERY_PRESET_CONFIG: ALL_WORKSPACES,
  EMAIL_TEMPLATES: ALL_WORKSPACES,
  EMAIL_TEMPLATES_BROWSE: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  LEGAL_ENTITIES: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  LEGAL_ENTITIES_OVERVIEW: ALL_WORKSPACES,
  EMAIL_TEMPLATES_BRANDS: ALL_WORKSPACES,
  RISK_LIBRARY: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  RISK_CONTROLS: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  REGULATIONS_FRAMEWORKS: ALL_WORKSPACES,
  FRAMEWORKS: ALL_WORKSPACES,
  FRAMEWORKS_CONTROLS: ALL_WORKSPACES,
  PRIVACY_EXECUTIVE_DASHBOARD: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  REGULATIONS: [WorkspaceId.SECURITY],
};

export const stateToWorkspace = Object.keys(stateNameToWorkspace).reduce((acc, stateName) => {
  acc.set(
    CONFIG.states[stateName as keyof typeof CONFIG.states],
    stateNameToWorkspace[stateName as keyof typeof CONFIG.states],
  );
  return acc;
}, new Map<string, WorkspaceId[]>());

export const customNavLinkToWorkspace: Record<string, WorkspaceId[]> = {
  dataExplorerTEMP: ALL_WORKSPACES,
  reportsTEMP: ALL_WORKSPACES,
  dataSecurityAndCompliancePostureTEMP: ALL_WORKSPACES,
  scannedFilesDOWNLOAD: [WorkspaceId.ADMIN],
  dataCustodianDOWNLOAD: [WorkspaceId.ADMIN],
  labelingPropagationDOWNLOAD: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  usageReportDOWNLOAD: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  dataManagementTEMP: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
  settingsTEMP: [WorkspaceId.DISCOVERY, WorkspaceId.ADMIN],
  ropaTempRoPA: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  assessmentsTempPIA: [WorkspaceId.PRIVACY],
  dsrReportsTemp: [WorkspaceId.PRIVACY, WorkspaceId.ADMIN],
  accessIntelligenceTemp: [WorkspaceId.SECURITY],
  delegatedRemediationTemp: [WorkspaceId.SECURITY],
  labelingTemp: [WorkspaceId.SECURITY],
  metadataExchangeTemp: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY, WorkspaceId.ADMIN],
};

const getPrivacyWorkspaceHomePage = (): string => {
  if (
    isPagePermitted(CONFIG.states.PRIVACY_EXECUTIVE_DASHBOARD) &&
    getApplicationPreference('SHOW_PRIVACY_EXECUTIVE_DASHBOARD_ENABLED')
  ) {
    return CONFIG.states.CATALOG_DISCOVERY;
  }

  return CONFIG.states.TASKS_LIST;
};

const getDiscoveryWorkspaceHomePage = (): string => {
  if (isPagePermitted(CONFIG.states.CATALOG_DISCOVERY) && getApplicationPreference('DATA_OVERVIEW_ENABLED')) {
    return CONFIG.states.CATALOG_DISCOVERY;
  }

  return CONFIG.states.TASKS_LIST;
};

const getSecurityWorkspaceHomePage = (): string => {
  if (isPagePermitted(CONFIG.states.CATALOG_DISCOVERY) && getApplicationPreference('DATA_OVERVIEW_ENABLED')) {
    return CONFIG.states.CATALOG_DISCOVERY;
  }

  return CONFIG.states.TASKS_LIST;
};

const getAdminWorkspaceHomePage = (): string => {
  if (isPagePermitted(CONFIG.states.CATALOG_DISCOVERY) && getApplicationPreference('DATA_OVERVIEW_ENABLED')) {
    return CONFIG.states.CATALOG_DISCOVERY;
  }

  return CONFIG.states.TASKS_LIST;
};

export const workspaces: Workspace[] = [
  {
    id: WorkspaceId.DISCOVERY,
    name: 'Discovery',
    icon: BigidAppLogoDiscoveryIcon,
    colorfulIcon: BigidAppLogoDiscoveryColorfulIcon,
    headerIcon: BigidHorizontalDiscoveryLogoIcon,
    color: BigidColorsV2.purple[700],
    getHomePage: getDiscoveryWorkspaceHomePage,
  },
  {
    id: WorkspaceId.PRIVACY,
    name: 'Privacy',
    icon: BigidAppLogoPrivacyIcon,
    colorfulIcon: BigidAppLogoPrivacyColorfulIcon,
    headerIcon: BigidHorizontalPrivacyLogoIcon,
    color: BigidColorsV2.cyan[700],
    getHomePage: getPrivacyWorkspaceHomePage,
  },
  {
    id: WorkspaceId.SECURITY,
    name: 'Security',
    icon: BigidAppLogoSecurityIcon,
    colorfulIcon: BigidAppLogoSecurityColorfulIcon,
    headerIcon: BigidHorizontalSecurityLogoIcon,
    color: BigidColorsV2.magenta[700],
    getHomePage: getSecurityWorkspaceHomePage,
  },
];

export const workspacesWithAdmin = [
  ...workspaces,
  {
    id: WorkspaceId.ADMIN,
    name: 'Admin',
    getHomePage: getAdminWorkspaceHomePage,
  },
];

export const getWorkspacesByState = (state: string): WorkspaceId[] => stateToWorkspace.get(state) || ALL_WORKSPACES;

export const getWorkspacesByStateAndPreferences = async (state: string): Promise<WorkspaceId[]> => {
  const resultWorkspaces: WorkspaceId[] = [];
  const workspacesByState = getWorkspacesByState(state);
  const preferences = await userPreferencesService.get<SideNavigationPreferencesByWorkspaces>(
    SIDE_NAVIGATION_BY_WORKSPACE,
  );

  Object.values(WorkspaceId).forEach(workspaceId => {
    const workspacePreferences = preferences?.data?.find(preferences => preferences.workspaceId === workspaceId);

    let statePreferenceVisible = false;
    let statePreferenceHidden = false;
    workspacePreferences?.preferences?.main?.forEach(preference => {
      if (preference.id === state) {
        if (preference.isVisible) {
          statePreferenceVisible = true;
        } else {
          statePreferenceHidden = true;
        }
      }

      if (preference.menuItems) {
        preference.menuItems.forEach(menuItem => {
          if (menuItem.id === state) {
            if (menuItem.isVisible && preference.isVisible) {
              statePreferenceVisible = true;
            } else {
              statePreferenceHidden = true;
            }
          }
        });
      }
    });

    if (statePreferenceVisible || (!statePreferenceHidden && workspacesByState.includes(workspaceId))) {
      resultWorkspaces.push(workspaceId);
    }
  });

  return resultWorkspaces;
};

export const doesPageAllowedForWorkspace = async (state: string, workspace: WorkspaceId): Promise<boolean> => {
  return (await getWorkspacesByStateAndPreferences(state)).includes(workspace);
};

export const doesAppPageAllowedForWorkspace = async (
  workspace: WorkspaceId,
  appId?: string,
  appRoute?: string,
): Promise<boolean> => {
  if (!appId) return false;
  const appInfo = await customAppService.getCustomAppByID(appId);
  const allowedWorkspaces = await getAppPageWorkspaces(appInfo.data[0], appRoute);
  return allowedWorkspaces.includes(workspace);
};

export const getAppPageWorkspaces = async (app?: AppInfo, appRoute?: string): Promise<WorkspaceId[]> => {
  if (!app) {
    return ALL_WORKSPACES;
  }
  const resultWorkspaces: WorkspaceId[] = [];
  const appDefaultWorkspaces = app.workspaces_details?.workspaces || ALL_WORKSPACES;
  const preferences = await userPreferencesService.get<SideNavigationPreferencesByWorkspaces>(
    SIDE_NAVIGATION_BY_WORKSPACE,
  );
  const state = appRoute
    ? `${CONFIG.states.CUSTOM_APP_DEEP_LINKING}/${app.id}/${appRoute}`
    : `${CONFIG.states.CUSTOM_APP}/${app.id}`;

  Object.values(WorkspaceId).forEach(workspaceId => {
    const workspacePreferences = preferences?.data?.find(preferences => preferences.workspaceId === workspaceId);
    const allStatePreferences = workspacePreferences?.preferences?.main?.flatMap(preference => [
      preference,
      ...(preference.menuItems || []),
    ]);
    const statePreferenceVisible = allStatePreferences?.find(
      preference => preference.id === state && preference.isVisible,
    );
    const statePreferenceHidden = allStatePreferences?.find(
      preference => preference.id === state && !preference.isVisible,
    );
    if (statePreferenceVisible || (!statePreferenceHidden && appDefaultWorkspaces.includes(workspaceId))) {
      resultWorkspaces.push(workspaceId);
    }
  });
  return resultWorkspaces;
};

export const resolveNextWorkspace = (workspacesToChoose: WorkspaceId[], currentWorkspace: WorkspaceId): WorkspaceId => {
  if (workspacesToChoose.length === 0) return currentWorkspace;
  if (workspacesToChoose.includes(currentWorkspace)) return currentWorkspace;
  return workspacesToChoose[0];
};

export const getWorkspacesByNavLink = (navLink: string): WorkspaceId[] =>
  customNavLinkToWorkspace[navLink] || getWorkspacesByState(navLink) || ALL_WORKSPACES;

export const isSecurityWorkSpaceDashboard = (name: string) => name === SECURITY_WORKSPACE_DASHBOARD_NAME;

export const isPageTechnical = (lastVisitedPage: string) => {
  return lastVisitedPage === CONFIG.states.FORBIDDEN || lastVisitedPage === CONFIG.states.NOT_FOUND;
};

export const canPageBeLastVisited = (lastVisitedPage: string) => {
  return isPagePermitted(lastVisitedPage) && !isPageTechnical(lastVisitedPage);
};

// TEMP will be removed when these apps will extend their manifest
export const setWorkspacesSettingsForCustomApp = (tpa: any) => {
  if (tpa.tpa_name === 'PIA') {
    tpa.workspaces_details = {
      workspaces: ['privacy', 'admin'],
      sidebar_items: [
        {
          workspaces: ['privacy', 'admin'],
          path: 'dataSecurityAndComplianceTEMP',
          icon: 'BigidFlowIcon',
          label: 'Data Security and Compliance',
          name: 'DATA_SECURITY_AND_COMPLIANCE_POSTURE',
          order: 200,
          subItems: [
            {
              workspaces: ['privacy', 'admin'],
              label: 'Assessments',
              name: 'ASSESSMENTS',
              path: '/',
              icon: 'PiaAppIcon',
              order: 900,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'RoPA') {
    tpa.workspaces_details = {
      workspaces: ['privacy', 'admin'],
      sidebar_items: [
        {
          workspaces: ['privacy', 'admin'],
          path: 'dataMappingTemp',
          icon: 'BigidFlowIcon',
          label: 'Data Mapping',
          name: 'DATA_MAPPING',
          order: 700,
          subItems: [
            {
              workspaces: ['privacy', 'admin'],
              label: 'Business Processes',
              name: 'PROCESSES',
              path: '/',
              icon: 'RopaAppIcon',
              order: 100,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'Data Retention') {
    tpa.workspaces_details = {
      workspaces: ALL_WORKSPACES,
      sidebar_items: [
        {
          workspaces: ALL_WORKSPACES,
          path: 'dataLifecycleTemp',
          icon: 'BigidProcessIcon',
          label: 'Data Lifecycle',
          name: 'DATA_LIFECYCLE',
          order: 400,
          subItems: [
            {
              workspaces: ALL_WORKSPACES,
              label: 'Retention',
              name: 'RETENTION',
              path: '/',
              icon: 'RetentionAppIcon',
              order: 100,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'Remediation') {
    tpa.workspaces_details = {
      workspaces: ALL_WORKSPACES,
      sidebar_items: [
        {
          workspaces: ALL_WORKSPACES,
          path: 'dataLifecycleTemp',
          icon: 'BigidProcessIcon',
          label: 'Data Lifecycle',
          name: 'DATA_LIFECYCLE',
          order: 400,
          subItems: [
            {
              workspaces: ALL_WORKSPACES,
              label: 'Delegated Remediation',
              name: 'REMEDIATION',
              path: '/',
              icon: 'RemediationAppIcon',
              order: 300,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'Alation Integration App') {
    tpa.workspaces_details = {
      workspaces: ['discovery', 'admin'],
      sidebar_items: [
        {
          workspaces: ['discovery', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['discovery', 'admin'],
              label: 'Alation',
              name: 'ALATION',
              path: '/',
              icon: 'BigidAppLogoAlationIcon',
              order: 100,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'BigID Collibra Integration App') {
    tpa.workspaces_details = {
      workspaces: ['discovery', 'admin'],
      sidebar_items: [
        {
          workspaces: ['discovery', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['discovery', 'admin'],
              label: 'Collibra',
              name: 'COLLIBRA',
              path: '/',
              icon: 'BigidAppLogoCollibraIcon',
              order: 200,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'EDC Integration App') {
    tpa.workspaces_details = {
      workspaces: ['discovery', 'admin'],
      sidebar_items: [
        {
          workspaces: ['discovery', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['discovery', 'admin'],
              label: 'EDC',
              name: 'EDC',
              path: '/',
              icon: 'BigidAppLogoEdcInformaticaIcon',
              order: 300,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'BigID Microsoft Purview Integration App') {
    tpa.workspaces_details = {
      workspaces: ['discovery', 'admin'],
      sidebar_items: [
        {
          workspaces: ['discovery', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['discovery', 'admin'],
              label: 'Purview',
              name: 'PURVIEW',
              path: '/',
              icon: 'BigidAppLogoMicrosoftPurviewIcon',
              order: 400,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'Snowflake Secure Analytics') {
    tpa.workspaces_details = {
      workspaces: ['security', 'admin'],
      sidebar_items: [
        {
          workspaces: ['security', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['security', 'admin'],
              label: 'Snowflake',
              name: 'SNOWFLAKE',
              path: '/',
              icon: 'BigidAppLogoSnowflakeIcon',
              order: 500,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'BigID Databricks Integration App') {
    tpa.workspaces_details = {
      workspaces: ['security', 'admin'],
      sidebar_items: [
        {
          workspaces: ['security', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['security', 'admin'],
              label: 'Databricks',
              name: 'DATABRICKS',
              path: '/',
              icon: 'DatabricksIcon',
              order: 600,
            },
          ],
        },
      ],
    };
  }
  if (tpa.tpa_name === 'ServiceNOW Integration') {
    tpa.workspaces_details = {
      workspaces: ['discovery', 'admin'],
      sidebar_items: [
        {
          workspaces: ['discovery', 'admin'],
          path: 'metadataExchangeTemp',
          icon: 'BigidProcessIcon',
          label: 'Metadata Exchange',
          name: 'METADATA_EXCHANGE',
          order: 600,
          subItems: [
            {
              workspaces: ['discovery', 'admin'],
              label: 'ServiceNow',
              name: 'SERVICE_NOW',
              path: '/',
              icon: 'ServicenowIcon',
              order: 700,
            },
          ],
        },
      ],
    };
  }

  return tpa;
};
