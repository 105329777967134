import React, { FC, MutableRefObject, useEffect, useMemo } from 'react';
import { isEmpty, isNull } from 'lodash';
import { BigidLoader, BigidSidePanel, PrimaryButton } from '@bigid-ui/components';
import { CLASSIFIER_TYPES, ClassifierGridRow } from '../types/ClassifierTypes';
import { SidePanelBasicInfo } from './SidePanelBasicInfo';
import { useLocalTranslation } from '../translations';
import { DropdownActions } from '../components/DropdownActions/DropdownActions';
import { useDropDownActions } from '../hooks/useDropDownActions';
import { OOTBChecksumValidation } from '../../../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import { generateDataAid } from '@bigid-ui/utils';
import { getNerClassifierTitle, isRegex } from '../utils/utils';
import { Content } from './Content';
import styled from '@emotion/styled';
import { analyticsService } from '../../../services/analyticsService';
import { ClassifiersEventsEnum } from '../types/Events';
import { usePermissions } from '../hooks/usePermissions';

export const StyledButtonsContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

export interface ClassifiersSidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  handleOpenClassifierTesterDialogOpen: () => void;
  setClassifiersToUpdateCount: (cb: (prevCount: number) => number) => void;
  checksumValidations: OOTBChecksumValidation[];
  formControlsRef: MutableRefObject<any>;
  isLoading: boolean;
  onFormSubmit: (classifier?: ClassifierGridRow) => void;
  isDocMenuItemVisible: boolean;
  docAppId: string;
  isSaveButtonVisible: boolean;
  setActiveClassifier?: (classifier: ClassifierGridRow) => void;
  activeClassifier: ClassifierGridRow;
  updateActiveClassifierRef: (classifier: ClassifierGridRow) => void;
  isLoadingClassifier?: boolean;
}

export const ClassifiersSidePanel: FC<ClassifiersSidePanelProps> = ({
  isOpen,
  onClose,
  checksumValidations,
  handleOpenClassifierTesterDialogOpen,
  formControlsRef,
  isLoading,
  onFormSubmit,
  isDocMenuItemVisible,
  docAppId,
  isSaveButtonVisible,
  setClassifiersToUpdateCount,
  setActiveClassifier,
  activeClassifier,
  updateActiveClassifierRef,
  isLoadingClassifier,
}) => {
  const { t } = useLocalTranslation('sidePanel');
  const { isCorrelationPermitted } = usePermissions();

  const classifier: ClassifierGridRow = useMemo(() => {
    if (!isEmpty(activeClassifier)) {
      return {
        ...activeClassifier,
        classifierType: isNull(activeClassifier.classifierType)
          ? CLASSIFIER_TYPES.CONTENT
          : activeClassifier.classifierType,
      };
    }

    return {} as ClassifierGridRow;
  }, [activeClassifier]);

  const openClassifierTesterDialog = () => {
    setActiveClassifier(formControlsRef.current.getValues());
    handleOpenClassifierTesterDialogOpen();
  };

  const { isLoading: isActionInProgress, items: dropDownItems } = useDropDownActions({
    classifier,
    setActiveClassifier,
    onSidePanelClose: onClose,
    openClassifierTesterDialog,
    setClassifiersToUpdateCount,
    isDocMenuItemVisible,
    docAppId,
    updateActiveClassifierRef,
  });

  const isSidePanelLoading = isLoading || isActionInProgress;

  const title =
    classifier.classifierType === CLASSIFIER_TYPES.NER
      ? getNerClassifierTitle(classifier.classification_name, classifier.modelName)
      : classifier.classification_name;

  useEffect(() => {
    analyticsService.trackManualEvent(ClassifiersEventsEnum.SIDE_PANEL_OPENED, {
      classifier,
    });
  }, [classifier.classification_name]);

  const handleSubmit = () => {
    if (classifier.classifierType === CLASSIFIER_TYPES.NER) {
      onFormSubmit(classifier);
      return;
    }
    onFormSubmit();
  };

  const isShowDropdownActions =
    Boolean(dropDownItems.length) && classifier.classifierType !== CLASSIFIER_TYPES.CORRELATION;

  const isSaveShouldBeVisible = (type: CLASSIFIER_TYPES) => {
    if (isRegex(activeClassifier.classifierType)) {
      return (
        (isSaveButtonVisible && activeClassifier.isOutOfTheBox && isCorrelationPermitted) ||
        (isSaveButtonVisible && !activeClassifier.isOutOfTheBox)
      );
    }

    switch (type) {
      case CLASSIFIER_TYPES.DOC:
      case CLASSIFIER_TYPES.NER:
      case CLASSIFIER_TYPES.CORRELATION:
        return isSaveButtonVisible && isCorrelationPermitted;

      default:
        return false;
    }
  };

  return (
    <BigidSidePanel
      open={isOpen}
      maxWidth="medium"
      title={title}
      onClose={onClose}
      content={
        <>
          {(isSidePanelLoading || isLoadingClassifier) && <BigidLoader />}
          <Content
            classifier={classifier}
            formControlsRef={formControlsRef}
            checksumValidations={checksumValidations}
          />
        </>
      }
      basicInfo={
        <SidePanelBasicInfo classifier={classifier}>
          <StyledButtonsContainer>
            {isShowDropdownActions && <DropdownActions items={dropDownItems} />}
            {isSaveShouldBeVisible(classifier.classifierType) && (
              <PrimaryButton
                dataAid={generateDataAid('ClassifiersSidePanel', ['save-button'])}
                onClick={() => handleSubmit()}
                size="medium"
                text={t('save')}
              />
            )}
          </StyledButtonsContainer>
        </SidePanelBasicInfo>
      }
    />
  );
};
