import React, { FC, useEffect } from 'react';
import {
  BigidDropZone,
  BigidPrimaryCheckbox,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
  StyledButtonType,
  TertiaryButton,
} from '@bigid-ui/components';
import { openSystemDialog } from '../../../react/services/systemDialogService';
import { notificationService } from '../../../react/services/notificationService';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
import { BigidExternalLinkIcon, BigidHelpIcon, BigidDownloadIcon } from '@bigid-ui/icons';
import { docsUrls } from '../../../react/config/publicUrls';
import { httpService } from '../../../react/services/httpService';
import { styled } from '@mui/material';

const IconBox = styled('span')`
  padding-right: 5px;
  display: flex;
`;

const IconBoxRight = styled('span')`
  padding-left: 5px;
  display: flex;
`;

const DocumentButton: FC<StyledButtonType> = props => {
  return (
    <TertiaryButton
      {...props}
      endIcon={
        <IconBoxRight>
          <BigidExternalLinkIcon />
        </IconBoxRight>
      }
    />
  );
};

const DownloadButton: FC<StyledButtonType> = props => {
  return (
    <TertiaryButton
      {...props}
      startIcon={
        <IconBox>
          <BigidDownloadIcon />
        </IconBox>
      }
    />
  );
};

const FlexWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipIconWrapper = styled('div')`
  line-height: 1;
`;

export interface BigidImportDialogProps {
  onFileUpdate: (file: File, includeExcludeList: boolean) => void;
  label?: string;
  accept?: string[] | string;
  maxSize?: number;
}

type OnImportClicked = (file: File, isImportExcludeList: boolean) => void;

export const BigidImportDialog: FC<BigidImportDialogProps> = ({
  label = '',
  accept = 'text/csv',
  maxSize,
  onFileUpdate,
}) => {
  const isExcludeListFFEnabled = getApplicationPreference('CLASSIFIER_EXCLUDE_LIST_FF');
  const [fileToImport, setFileToImport] = React.useState<File>(null);
  const [isImportExcludeList, setIsImportExcludeList] = React.useState<boolean>(isExcludeListFFEnabled);

  useEffect(() => {
    if (fileToImport) {
      onFileUpdate(fileToImport, isImportExcludeList);
    }
  }, [fileToImport, isImportExcludeList, onFileUpdate]);

  const handleOnDrop = (files: File[]) => {
    const file = files.length ? files[0] : null;
    setFileToImport(file);
  };

  return (
    <div>
      <BigidDropZone
        accept={accept}
        files={fileToImport ? [fileToImport] : []}
        onDrop={handleOnDrop}
        maxSize={maxSize}
        label={label}
      />
      {isExcludeListFFEnabled && (
        <FlexWrapper>
          <BigidPrimaryCheckbox
            checked={isImportExcludeList}
            label="Import classifiers exclude list"
            onChange={(_e, checked: boolean) => setIsImportExcludeList(checked)}
            dataAid="BigidCheckbox-ExcludeList"
          />
          <BigidTooltip
            fontSize="medium"
            placement="bottom"
            title="Uncheck this option if you want the import process to ignore Exclude list values included in the import file"
          >
            <TooltipIconWrapper>
              <BigidHelpIcon />
            </TooltipIconWrapper>
          </BigidTooltip>
        </FlexWrapper>
      )}
    </div>
  );
};

export const openImportClassifiersDialog = async (onImportClicked: OnImportClicked) => {
  let requireFile: File;
  let requireIsImportExcludeList: boolean;
  const contentProps = {
    label: 'Upload a CSV/TXT file here:',
    onFileUpdate: (file: File, isImportExcludeList: boolean) => {
      requireFile = file;
      requireIsImportExcludeList = isImportExcludeList;
    },
  };

  const handleOpenDocumentation = () => {
    window.open(docsUrls.CLASSIFIERS_MANAGEMENT_DOC, '_blank');
  };

  const handleDownloadFile = () => {
    httpService.downloadStaticFile('import-classifiers-template.csv');
  };

  return new Promise<any>(resolve => {
    openSystemDialog({
      title: 'Import Classifier',
      content: BigidImportDialog,
      contentProps,
      onClose: () => null,
      buttons: [
        { component: DownloadButton, onClick: handleDownloadFile, text: 'Download template', alignment: 'left' },
        { component: DocumentButton, onClick: handleOpenDocumentation, text: 'Documentation', alignment: 'left' },
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: 'Import',
          component: PrimaryButton,
          onClick: async () => {
            if (!requireFile) {
              notificationService.error('Please choose a file to import');
              resolve(null);
              return;
            }
            if (requireFile) {
              await onImportClicked(requireFile, requireIsImportExcludeList);
              resolve(null);
            }
          },
          isClose: true,
        },
      ],
    });
  });
};
