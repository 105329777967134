import { useMemo } from 'react';
import { isPermitted } from '../../../services/userPermissionsService';
import { CLASSIFIERS_PERMISSIONS, CORRELATION_PERMISSIONS } from '@bigid/permissions';
import { DOC_APP_PERMISSIONS } from '../constants/permissions';

export const usePermissions = () => {
  const permissions = useMemo(
    () => ({
      isDeletePermitted: isPermitted(CLASSIFIERS_PERMISSIONS.DELETE.name),
      isEditPermitted: isPermitted(CLASSIFIERS_PERMISSIONS.EDIT.name),
      isCreatePermitted: isPermitted(CLASSIFIERS_PERMISSIONS.CREATE.name),
      isImportPermitted: isPermitted(CLASSIFIERS_PERMISSIONS.IMPORT.name),
      isExportPermitted: isPermitted(CLASSIFIERS_PERMISSIONS.EXPORT.name),
      isDocAppPermitted:
        isPermitted(DOC_APP_PERMISSIONS.READ_CUSTOM_APP.name) ||
        isPermitted(DOC_APP_PERMISSIONS.READ.name) ||
        isPermitted(DOC_APP_PERMISSIONS.MANAGE_CUSTOM_APP.name),
      isCorrelationPermitted: isPermitted(CORRELATION_PERMISSIONS.MANAGE.name),
    }),
    [],
  );

  return permissions;
};
