import { EmailEditorEvents, emailEditorEventEmitter } from '../../services/eventEmitters/emailEditorEvents';
import EmailEditorEmailStructurePreview from '../../assets/icons/EmailEditorEmailStructurePreview.svg';
import { openSystemDialog } from '../../services/systemDialogService';
import { httpService } from '../../services/httpService';
import { getFixedT } from './translations';
import { BigidFormField, BigidFormValues } from '@bigid-ui/components';

const t = getFixedT('');
const tMessages = getFixedT('messages');
const EMAIL_TEMPLATES_PATH = 'email-templates';

export interface EmailEditorOptions {
  onClose?: (args?: { [key: string]: unknown }) => void;
  title?: string;
  templateId?: string;
  viewOnlyMode?: boolean;
}

export enum EmailTemplateType {
  COLLABORATOR_INVITATION = 'dsCollaborationInvitation',
  CUSTOM = 'custom',
}

export enum EmailTemplateFieldType {
  TITLE = 'title',
  HTML = 'html',
  BUTTON_TEXT = 'button-text',
  BUTTON_URL = 'button-url',
  LINK_TEXT = 'link-text',
  LINK_URL = 'link-url',
}

interface EmailTemplateContentHeader {
  text: string;
  image: string;
  imageType: string;
}

interface EmailTemplateContentBodyItem {
  fields: EmailTemplateContentFields[];
}

interface EmailTemplateContentFields {
  fieldName: string;
  fieldType: EmailTemplateFieldType;
  value: string;
  isSystem?: boolean;
}

export interface EmailTemplateContent {
  subject: string;
  header: EmailTemplateContentHeader;
  body: EmailTemplateContentBodyItem[];
}

export interface EmailTemplateData {
  _id?: string;
  name: string;
  type: string;
  language?: string;
  description: string;
  content: EmailTemplateContent;
  created_at?: string;
  updated_at?: string;
  allowedVariables?: string[];
  isSystem?: boolean;
}

export interface TestEmailPayload {
  emailContent: EmailTemplateContent;
  brandName: string;
  recipientEmail: string;
}

export const openEmailEditor = (options: EmailEditorOptions = {}): void => {
  emailEditorEventEmitter.emit(EmailEditorEvents.OPEN_EMAIL_EDITOR, [options]);
};

export const closeEmailEditor = (): void => {
  emailEditorEventEmitter.emit(EmailEditorEvents.CLOSE_EMAIL_EDITOR);
};

export const openEmailStructureDialog = () => {
  openSystemDialog({
    title: t('emailStructureDialogTitle'),
    content: EmailEditorEmailStructurePreview,
    maxWidth: 'md',
    onClose: () => null,
  });
};

export const normalizedFieldName = (fieldName: string, newIndex: string) => {
  return fieldName.replace(/\d+/g, newIndex);
};

export const generateAdditionalFieldProperties = (
  formFieldName: string,
  contentFieldsProperties: BigidFormField[],
): Pick<EmailTemplateContentFields, 'fieldType' | 'isSystem'> => {
  const fieldProperties = contentFieldsProperties.find(field => field.name === formFieldName);
  return {
    fieldType: fieldProperties?.misc?.fieldType || '',
    ...(fieldProperties?.disabled && { isSystem: true }),
  };
};

export const clearEmptySectionsFromTemplateBody = (templateBody: EmailTemplateContentBodyItem[]) => {
  return templateBody.filter(section => {
    return section.fields.some(field => field.value.trim() !== '');
  });
};

export const generateTemplateBody = (
  sectionFields: string[][],
  emailContentValues: BigidFormValues,
  contentFieldsProperties: BigidFormField[],
): EmailTemplateContentBodyItem[] => {
  const templateBody: EmailTemplateContentBodyItem[] = [];
  sectionFields.forEach((section, sectionIndex) => {
    const fields = {
      fields: section.map(formFieldName => {
        return {
          fieldName: normalizedFieldName(formFieldName, `${sectionIndex}`),
          value: emailContentValues[formFieldName] || '',
          ...generateAdditionalFieldProperties(formFieldName, contentFieldsProperties),
        };
      }),
    };
    templateBody.push(fields);
  });
  return clearEmptySectionsFromTemplateBody(templateBody);
};

export const generateEmailContentPayload = (
  sectionFields: string[][],
  emailContentValues: BigidFormValues,
  contentFieldsProperties: BigidFormField[],
): EmailTemplateContent => {
  const { subject, headerText, headerImageLink } = emailContentValues;
  return {
    subject: subject,
    header: {
      text: headerText,
      image: headerImageLink,
      imageType: 'url',
    },
    body: generateTemplateBody(sectionFields, emailContentValues, contentFieldsProperties),
  };
};

export const generateEmailTemplatePayload = (
  templateData: EmailTemplateData,
  sectionFields: string[][],
  contentFieldsProperties: BigidFormField[],
  templateDetailsValues: BigidFormValues,
  emailContentValues: BigidFormValues,
): EmailTemplateData => {
  const { templateCategory, templateDescription, templateName } = templateDetailsValues;

  return {
    ...(templateData?.language && { language: templateData.language }),
    ...(templateData?.allowedVariables && { allowedVariables: templateData.allowedVariables }),
    name: templateName,
    type: templateCategory[0]?.value,
    description: templateDescription || '',
    content: generateEmailContentPayload(sectionFields, emailContentValues, contentFieldsProperties),
  };
};

export const getFieldByName = (fields: BigidFormField[], fieldName: string) => {
  return fields.find(field => field.name === fieldName);
};

export const removeKeysByNumberFromObject = (obj: Record<string, string>, number: number) => {
  const numberStr = number.toString();

  for (const key in obj) {
    if (key.includes(numberStr)) {
      delete obj[key];
    }
  }
  return obj;
};

export const getInitialTemplate = async (templateType: EmailTemplateType) => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: EmailTemplateData }>(`${EMAIL_TEMPLATES_PATH}/base-template/${templateType}`);
    if (!data || !data?.content) {
      throw new Error(tMessages('missingTemplateData'));
    }
    return data;
  } catch (e) {
    console.error('Fetch template error', e);
    throw new Error(e?.message);
  }
};

export const saveTemplate = async (payload: EmailTemplateData, templateId: string) => {
  let createdTemplateId: string;
  try {
    if (templateId) {
      await httpService.put(`${EMAIL_TEMPLATES_PATH}/${templateId}`, payload);
    } else {
      const response = await httpService.post(EMAIL_TEMPLATES_PATH, payload);
      createdTemplateId = response?.data?.data?._id;
    }
    return {
      successMessage: tMessages('successfullySavedTemplate', { templateName: payload.name }),
      name: payload?.name,
      id: createdTemplateId,
    };
  } catch (e) {
    console.error('Save template error', e);
    throw e;
  }
};

export const getTemplateData = async (templateId: string) => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: EmailTemplateData }>(`${EMAIL_TEMPLATES_PATH}/${templateId}`);
    if (!data || !data?.content) {
      throw new Error(tMessages('missingTemplateData'));
    }
    return data;
  } catch (e) {
    console.error('Fetch template error', e);
    throw new Error(e?.message);
  }
};

export const getTemplateTypes = async () => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: EmailTemplateType[] }>(`${EMAIL_TEMPLATES_PATH}/types`);
    return data;
  } catch (e) {
    console.error('Fetch template types error', e);
    throw new Error(e?.message);
  }
};

export const sendTestEmail = async (payload: TestEmailPayload) => {
  return httpService.post(`${EMAIL_TEMPLATES_PATH}/send-test-email`, payload);
};
