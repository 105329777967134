import React from 'react';
import { BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import { GuideState } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import { CorrelationSetGuideContent } from '../../../components/CorrelationSetGuide';
import styled from '@emotion/styled';

const ErrorContainer = styled('div')`
  margin-top: 12px;

  > div {
    overflow-x: scroll;
  }
`;

interface TrainingGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

const MARGIN_BOTTOM = 2;

export const TrainingGuideContent = ({ contentTitle, guideState }: TrainingGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent');

  return (
    <>
      <CorrelationSetGuideContent>
        <BigidHeading6>{contentTitle}</BigidHeading6>
        <BigidBody1 size="medium" marginBottom={MARGIN_BOTTOM}>
          {t('trainingStep.text1')}
        </BigidBody1>
        <BigidBody1 size="medium" marginBottom={MARGIN_BOTTOM}>
          {t('trainingStep.text2')}
        </BigidBody1>
        <BigidBody1 size="medium" marginBottom={MARGIN_BOTTOM}>
          {t('trainingStep.text3')}
        </BigidBody1>
        <BigidBody1 size="medium" marginBottom={MARGIN_BOTTOM}>
          {t('trainingStep.text4')}
        </BigidBody1>
        <BigidBody1>{t('trainingStep.text3')}</BigidBody1>
      </CorrelationSetGuideContent>
    </>
  );
};
