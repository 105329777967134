import React, { ComponentType, ReactNode, SVGProps } from 'react';
import {
  BigidApplyIcon,
  BigidColumnsIcon,
  BigidExternalLinkIcon,
  BigidFileIcon,
  BigidHelpIcon,
  BigidApplyFilledIcon,
  BigidDislikeFilledIcon,
  BigidLikeFilledIcon,
  IconComponentProps,
} from '@bigid-ui/icons';
import {
  BigidBody1,
  BigidColorsV2,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  EntityEventPayload,
  EntityEvents,
  entityEventsEmitter,
  BigidStatusBadgeProps,
  BigidFilter,
  BigidSelectOption,
  BigidFilterOptionType,
  BigidFieldFilter,
} from '@bigid-ui/components';
import {
  AdditionalAttribute,
  CuratedAttribute,
  CuratedAttributeType,
  CuratedDataSource,
  CuratedField,
  CuratedFieldStatus,
  CurationStatus,
  DetailedObjectType,
  SamplingStatus,
  structuredObjectsTypes,
  SavedFilter,
  CurationFieldsAsyncFilters,
} from './curationService';
import { CONFIG } from '../../../config/common';
import { getFixedT } from './translations';
import { HistoryItem } from '../../components/BigidHeader/HeaderService';
import { CurationGuidedTourStageId, CurationStageId } from './useCurationState';
import {
  BigidPageTitleHelper,
  BigidPageTitleHelperButtonType,
  BigidPageTitleHelperProps,
} from '../../components/BigidHeader/BigidPageTitle/BigidPageTitleHelper';
import { EventEmitter, generateDataAid } from '@bigid-ui/utils';
import { docsUrls } from '../../config/publicUrls';
import { BigidGridAddRowsPayload, BigidGridDeleteRowsPayload } from '@bigid-ui/grid';
import { AdditionalAttributesDialogConfig } from './CuratedFields/useCurationFieldsConfig';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { getApplicationPreference } from '../../services/appPreferencesService';

export enum CurationStatusEvents {
  UPDATE_STATUS = 'updateStatus',
}

export const isNoteEnabledForReject = () => getApplicationPreference('ENABLE_CLASSIFIER_TUNING_REJECT_FORCE_NOTE');
export const isCTLightPreviewEnabled = () => getApplicationPreference('CLASSIFIER_TUNING_LIGHT_PREVIEW_ENABLED');

export const curationStatusEventsEmitter = new EventEmitter<CurationStatusEvents>();

export function getCuratedFieldStatusIconConfig(
  reviewStatus: CuratedFieldStatus,
): ComponentType<IconComponentProps & SVGProps<SVGSVGElement>> {
  let icon;

  switch (reviewStatus) {
    case CuratedFieldStatus.APPROVED:
      icon = BigidLikeFilledIcon;
      break;
    case CuratedFieldStatus.REJECTED:
      icon = BigidDislikeFilledIcon;
      break;
  }

  return icon;
}

export function getPercentageValueFormatted(value: number): string {
  return `${(Number(value) * 100).toFixed(2).replace(/[.,]00$/, '')}%`;
}

export function getCuratedFieldMasterDetailsIcon(detailedObjectType: DetailedObjectType): ReactNode {
  let icon: ReactNode;

  switch (true) {
    case structuredObjectsTypes.includes(detailedObjectType):
      icon = <BigidColumnsIcon size="large" staticMode />;
      break;
    case [DetailedObjectType.UNSTRUCTURED, DetailedObjectType.APP].includes(detailedObjectType):
      icon = <BigidFileIcon size="large" staticMode />;
      break;
  }

  return icon;
}

export function getBackToOriginPageNavButtonLabel(originState: HistoryItem): string {
  const t = getFixedT('Curation.navigation');
  let label: string;

  switch (originState?.state) {
    case CONFIG.states.SCANS_NEW_SCANS_COMPLETED:
      label = t('completedScans');
      break;
    case CONFIG.states.SCANS_SCAN_INSIGHTS:
      label = t('scanInsight');
      break;
    case CONFIG.states.DATA_SOURCE_CONNECTIONS:
      label = t('dataSourceConnections');
      break;
    default:
      label = t('exit');
  }

  return label;
}

export function getAreAllAttributesReviewed(attributesCurationStatus: CurationStatus): boolean {
  let areAllAttributesReviewed = false;

  if (attributesCurationStatus) {
    const { curatedCount, totalCount } = attributesCurationStatus || {};
    areAllAttributesReviewed = curatedCount === totalCount;
  }

  return areAllAttributesReviewed;
}

export function getFindingHighlightColorByStatus(reviewStatus: CuratedFieldStatus): string {
  let color: string;

  switch (reviewStatus) {
    case CuratedFieldStatus.UNCURATED:
      color = BigidColorsV2.blue[200];
      break;
    case CuratedFieldStatus.APPROVED:
      //NOTE: temporarily disabled
      // color = BigidColorsV2.green[50];
      break;
    case CuratedFieldStatus.REJECTED:
      color = BigidColorsV2.red[500];
      break;
  }

  return color;
}

export function getFindingIndicatorColorByStatus(reviewStatus: CuratedFieldStatus): string {
  let color: string;

  switch (reviewStatus) {
    case CuratedFieldStatus.UNCURATED:
      color = BigidColorsV2.blue[700];
      break;
    case CuratedFieldStatus.APPROVED:
      color = BigidColorsV2.green[700];
      break;
    case CuratedFieldStatus.REJECTED:
      color = BigidColorsV2.red[700];
      break;
  }

  return color;
}

export function getCuratedFieldDisplayName(curatedField: CuratedField): string {
  let displayFieldName: string;
  const t = getFixedT('GetCuratedFieldDisplayName');
  const { fieldName, detailedObjectType } = curatedField;

  if (structuredObjectsTypes.includes(detailedObjectType)) {
    displayFieldName = fieldName;
  } else if (detailedObjectType === DetailedObjectType.UNSTRUCTURED) {
    displayFieldName = t('content');
  } else if (detailedObjectType === DetailedObjectType.APP) {
    displayFieldName = fieldName.length >= 15 ? t('attachment') : fieldName;
  }

  return displayFieldName;
}

export function getStatusDisplayName(reviewStatus: CuratedFieldStatus): string {
  let displayName: string;
  const t = getFixedT('GetStatusDisplayName');

  switch (reviewStatus) {
    case CuratedFieldStatus.UNCURATED:
      displayName = t('uncurated');
      break;
    case CuratedFieldStatus.APPROVED:
      displayName = t('approved');
      break;
    case CuratedFieldStatus.REJECTED:
      displayName = t('rejected');
      break;
  }

  return displayName;
}

export function getCurationProgressBarColor({ curatedCount, totalCount }: CurationStatus): string {
  return curatedCount === totalCount ? BigidColorsV2.green[700] : BigidColorsV2.purple[400];
}

export function getReviewedTextCalculated(totalItems: number, totalReviewed: number, whichCurationPage: string) {
  const t = whichCurationPage == 'datasource' ? getFixedT('CuratedDataSourceContent') : getFixedT('CuratedAttributes');
  const textToDisplay = whichCurationPage == 'datasource' ? t('reviewedAttributes') : t('reviewedObjects');

  return `${totalReviewed}/${totalItems} ${textToDisplay} (${Math.round((totalReviewed / totalItems) * 100)}%)`;
}

export function getSamplingStatusText(dataSource: CuratedDataSource) {
  const { samplingStatus, curatedAttributes, totalAttributes, isSamplingStuck } = dataSource;
  const t = getFixedT('CuratedDataSourceContent');

  if (samplingStatus === SamplingStatus.COMPLETED) {
    return totalAttributes ? getReviewedTextCalculated(totalAttributes, curatedAttributes, 'datasource') : '';
  } else if (samplingStatus === SamplingStatus.RUNNING && !isSamplingStuck) {
    return t('SamplingInProgress');
  }
}

export function getGuidedTourKeyByCurationStateId(currentStageId: CurationStageId): CurationGuidedTourStageId {
  let guidedTourId: CurationGuidedTourStageId;

  switch (currentStageId) {
    case CurationStageId.CURATED_SOURCES:
      guidedTourId = CurationGuidedTourStageId.DATA_SOURCES;
      break;
    case CurationStageId.CURATED_ATTRIBUTES:
      guidedTourId = CurationGuidedTourStageId.ATTRIBUTES;
      break;
    case CurationStageId.CURATED_FIELDS:
      guidedTourId = CurationGuidedTourStageId.FIELDS;
      break;
  }

  return guidedTourId;
}

export function getCurationGuidedTourStageTranslation(curationGuidedTourStageId: CurationGuidedTourStageId) {
  let stageTranslation;

  switch (curationGuidedTourStageId) {
    case CurationGuidedTourStageId.DATA_SOURCES:
      stageTranslation = 'CurationGuidedTour.DataSource';
      break;
    case CurationGuidedTourStageId.ATTRIBUTES:
      stageTranslation = 'CurationGuidedTour.Attributes';
      break;
    case CurationGuidedTourStageId.FIELDS:
      stageTranslation = 'CurationGuidedTour.Fields';
      break;
    case CurationGuidedTourStageId.PREVIEW:
      stageTranslation = 'CurationGuidedTour.Preview';
      break;
    case CurationGuidedTourStageId.COLUMN_PROFILE:
      stageTranslation = 'CurationGuidedTour.ColumnProfile';
      break;
    case CurationGuidedTourStageId.ADDITIONAL_ATTRIBUTES:
      stageTranslation = 'CurationGuidedTour.AdditionalAttributes';
      break;
  }

  return stageTranslation;
}

export interface CurationHeaderHelpIconProps {
  onCurationGuidedTourStart: () => void;
}

export const getPageTitle = (displayName = '', attributeName = '') => {
  if (displayName && attributeName) {
    return displayName === attributeName ? displayName : `${attributeName} (${displayName})`;
  }

  return displayName || attributeName;
};

export const curationPageTitleHelper = ({ onCurationGuidedTourStart }: CurationHeaderHelpIconProps) => {
  const t = getFixedT('CurationPageTitleHelper');
  const curationHeaderBody = t('body') as string;

  const pageTitleHelperProps: BigidPageTitleHelperProps = {
    dataAid: generateDataAid('curation', ['title', 'helper']),
    contentWidth: 365,
    togglerIcon: BigidHelpIcon,
    title: t('title'),
    body: <BigidBody1>{curationHeaderBody}</BigidBody1>,
    buttons: [
      {
        type: BigidPageTitleHelperButtonType.TERTIARY,
        text: t('buttons.tertiary'),
        endIcon: <BigidExternalLinkIcon />,
        onClick: () => {
          window.open(docsUrls.CLASSIFIER_TUNING, '_blank');
        },
      },
      {
        type: BigidPageTitleHelperButtonType.PRIMARY,
        text: t('buttons.primary'),
        onClick: () => {
          onCurationGuidedTourStart();
        },
      },
    ],
  };
  return { titleHelperComponent: <BigidPageTitleHelper {...pageTitleHelperProps} /> };
};
export const getAdditionalAttributesDialogProps = (
  additionalAttributesDialogConfigSetup: AdditionalAttributesDialogConfig,
) => {
  return {
    dataAid: generateDataAid(additionalAttributesDialogConfigSetup.dataAid, ['dialog']),
    objectsSelected: additionalAttributesDialogConfigSetup.objectsSelected,
    orderAfterSave: additionalAttributesDialogConfigSetup.orderAfterSave,
    isBulkMode: additionalAttributesDialogConfigSetup.isBulkMode,

    onSave: (addedAttributes: Partial<AdditionalAttribute>[], deletedAttributes: Partial<AdditionalAttribute>[]) => {
      if (addedAttributes.length > 0) {
        const payload: EntityEventPayload<BigidGridAddRowsPayload> = {
          entityId: additionalAttributesDialogConfigSetup.gridId,
          payload: {
            rows: addedAttributes.map(attribute => ({ id: attribute.attributeId, ...attribute })),
          },
        };
        entityEventsEmitter.emit(EntityEvents.ADD, payload);
        entityEventsEmitter.emit(EntityEvents.RELOAD, additionalAttributesDialogConfigSetup.gridId);
      }

      if (deletedAttributes.length > 0) {
        const payload: EntityEventPayload<BigidGridDeleteRowsPayload> = {
          entityId: additionalAttributesDialogConfigSetup.gridId,
          payload: {
            rows: deletedAttributes.map(attribute => ({ id: attribute.attributeId, ...attribute })),
          },
        };
        entityEventsEmitter.emit(EntityEvents.DELETE, payload);
      }
    },
  };
};

export const getReviewedEntitiesProgressIcon = (curatedCount: number, totalCount: number) => {
  if (curatedCount === totalCount) {
    return <BigidApplyFilledIcon color="positive" />;
  } else {
    return <BigidApplyIcon staticMode />;
  }
};

const getDataSourceStatusBadgeProps = (dataSource: CuratedDataSource) => {
  const { isSupported, samplingStatus, totalAttributes, curatedAttributes, isSamplingStuck } = dataSource;
  const t = getFixedT('Curation.common.statusBadge');

  switch (true) {
    case !isSupported:
      return {
        label: t('notSupported'),
        type: BigidStatusBadgeType.WARNING,
        size: BigidStatusBadgeSize.SMALL,
      };

    case samplingStatus == SamplingStatus.NEVER_SAMPLED || isSamplingStuck:
      return {
        label: t('notSampled'),
        type: BigidStatusBadgeType.DISABLED,
        size: BigidStatusBadgeSize.SMALL,
      };

    case totalAttributes == 0 && samplingStatus == SamplingStatus.COMPLETED:
      return {
        label: t('noAttributes'),
        type: BigidStatusBadgeType.DISABLED,
        size: BigidStatusBadgeSize.SMALL,
      };

    case curatedAttributes == totalAttributes && samplingStatus == SamplingStatus.COMPLETED:
      return {
        label: t('completed'),
        type: BigidStatusBadgeType.SUCCESS,
        size: BigidStatusBadgeSize.SMALL,
      };

    case curatedAttributes > 0 && samplingStatus == SamplingStatus.COMPLETED:
      return {
        label: t('inProgress'),
        type: BigidStatusBadgeType.INFO,
        size: BigidStatusBadgeSize.SMALL,
      };

    case samplingStatus == SamplingStatus.ERROR:
      return {
        label: t('error'),
        type: BigidStatusBadgeType.ERROR,
        size: BigidStatusBadgeSize.SMALL,
      };

    default:
      return {
        label: t('notStarted'),
        type: BigidStatusBadgeType.PENDING,
        size: BigidStatusBadgeSize.SMALL,
      };
  }
};

const getAttributeStatusBadgeProps = (attribute: CuratedAttribute) => {
  const { approvedCount, rejectedCount, isCompletelyCurated } = attribute;
  const isCuratingInProgress = approvedCount + rejectedCount > 0;
  const t = getFixedT('Curation.common.statusBadge');

  switch (true) {
    case isCompletelyCurated:
      return {
        label: t('completed'),
        type: BigidStatusBadgeType.SUCCESS,
        size: BigidStatusBadgeSize.SMALL,
      };

    case isCuratingInProgress:
      return {
        label: t('inProgress'),
        type: BigidStatusBadgeType.INFO,
        size: BigidStatusBadgeSize.SMALL,
      };

    default:
      return {
        label: t('notStarted'),
        type: BigidStatusBadgeType.PENDING,
        size: BigidStatusBadgeSize.SMALL,
      };
  }
};

export const getDataSourceStatusBadge = (dataSource: CuratedDataSource) => {
  const statusBadgeProps: BigidStatusBadgeProps = getDataSourceStatusBadgeProps(dataSource);

  return <BigidStatusBadge label={statusBadgeProps.label} type={statusBadgeProps.type} size={statusBadgeProps.size} />;
};

export const getAttributeStatusBadge = (attribute: CuratedAttribute) => {
  const statusBadgeProps: BigidStatusBadgeProps = getAttributeStatusBadgeProps(attribute);

  return <BigidStatusBadge label={statusBadgeProps.label} type={statusBadgeProps.type} size={statusBadgeProps.size} />;
};

export function getStatusBaseQuery(attributeType: CuratedAttributeType, attributeName: string, source?: string) {
  const filter: BigidFilter = [
    {
      field: 'attributeName',
      value: encodeURIComponent(attributeName),
      operator: 'in',
    },
    {
      field: 'attributeType',
      value: attributeType,
      operator: 'in',
    },
  ];

  if (source) {
    filter.push({
      field: 'source',
      value: [encodeURIComponent(source)],
      operator: 'in',
    });
  }

  return parseFieldFiltersToSearchQuery(filter, Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')));
}

export function getSelectedFilterOptions(
  filterOptions: BigidSelectOption[] | BigidFilterOptionType[],
  selectedFilters: SavedFilter,
): BigidFilterOptionType[] {
  return filterOptions?.map(option => {
    const isSelected = selectedFilters.values?.includes(option.value.toString());
    return { ...option, isSelected };
  });
}

export function getFilterSelectedValues(options: BigidFilterOptionType[]): string[] {
  return options?.filter(option => option.isSelected)?.map(option => option.value.toString());
}

export function getFilterFromToolBar(filters: BigidFieldFilter[], name: string) {
  return filters?.find(filter => filter?.field === name);
}

export function getFilterFromUserPreference(filters: SavedFilter[], name: string) {
  return filters?.find(filter => filter?.filterName === name);
}

export function getHoveredLine(element: HTMLDivElement, mouseY: number, lineHeight: number) {
  const rect = element?.getBoundingClientRect();
  const offsetY = mouseY - rect.top;

  return Math.floor(Math.max(offsetY / lineHeight, 0));
}

export function getDetailedObjectTypeLabel(detailedObjectType: DetailedObjectType): string {
  const t = getFixedT('Curation.common.detailedObjectTypeLabels');

  switch (detailedObjectType) {
    case DetailedObjectType.STRUCTURED:
      return t('structured');
    case DetailedObjectType.UNSTRUCTURED:
      return t('unstructured');
    case DetailedObjectType.STRUCTURED_FILE:
      return t('structured_file');
    case DetailedObjectType.PARTITIONED_TABLE:
      return t('partition_table');
    case DetailedObjectType.APP:
      return t('app');
    default:
      return detailedObjectType;
  }
}

function getObjectFieldsOptions(data: string[]): BigidSelectOption[] {
  return data.map((value: string) => {
    return {
      value,
      label: value,
      isSelected: false,
    };
  });
}

export function getFilterDataByField(fieldType: CurationFieldsAsyncFilters, data: any) {
  switch (fieldType) {
    case CurationFieldsAsyncFilters.CONTAINER:
      return data.containers ? getObjectFieldsOptions(data.containers) : null;

    case CurationFieldsAsyncFilters.FIELD_NAME:
      return data.fieldNames ? getObjectFieldsOptions(data.fieldNames) : null;

    case CurationFieldsAsyncFilters.OBJECT_NAME:
      return data.objectNames ? getObjectFieldsOptions(data.objectNames) : null;
  }
}
