import {
  BigidCaption,
  BigidColors,
  BigidDialog,
  BigidHeading5,
  BigidLink,
  BigidLoader,
  BigidRadio,
  BigidSearchableList,
  BigidSearchableListItem,
  BigidTooltip,
  PrimaryButton,
} from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RadioGroup from '@mui/material/RadioGroup';
import { notificationService } from '../../services/notificationService';
import { $state } from '../../services/angularServices';
import BigidHelpIcon from '../../assets/icons/BigidHelpFilled.svg';
import {
  AdvancedClassifier,
  getNormalizedClassifiers,
  NormalizedClassifier,
} from '../../views/Classification/ClassifiersService';
import { CLASSIFIER_ALGORITHMS } from '../../../administration/classifiers/classifiers.service';

const useStyles = makeStyles({
  header: {
    color: BigidColors.purple[600],
    paddingBottom: '10px',
  },
  radioButton: {
    display: 'flex',
    '& > :nth-child(1)': {
      margin: '0px',
    },
  },
  helpCircle: {
    marginLeft: '5px',
    marginTop: '11px',
  },
  sectionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  links: {
    paddingTop: '10px',
    paddingLeft: '5px',
  },
  search: {
    width: '100%',
    padding: '10px 0px',
  },
  selectTitle: {
    padding: '20px 0px',
  },
  flex: {
    display: 'flex',
  },
  listOverflow: {
    flex: '1',
    overflow: 'auto',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectMsg: {
    position: 'absolute',
    marginTop: '70px',
    zIndex: 1,
    color: 'red',
  },
  loaderPosition: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
});

interface ScanProfileClassifier {
  name: string;
  type: string;
}

interface ScanProfileClassifiersProps {
  classifiers: ScanProfileClassifier[];
  onClassifierSelect: (selection: ScanProfileClassifier[]) => void;
  selectedClassifiersEmpty: (isEmpty: boolean) => void;
  onClassifierDelete: () => void;
}

const radioOption = [
  {
    label: 'Use Global Classifiers',
    value: 'global',
    tooltip:
      'Classifiers that will run as a part of this scan will be the ones enabled in the Classifiers Settings page',
  },
  {
    label: 'Select Classifiers',
    value: 'select',
    tooltip:
      'Select classifiers to run as a part of this scan (regardless of what is enabled in the Classifiers Settings page)',
  },
];

export const ScanProfileClassifiers: FC<ScanProfileClassifiersProps> = ({
  classifiers,
  onClassifierSelect,
  selectedClassifiersEmpty,
  onClassifierDelete,
}) => {
  const [showCustomClassifiersSection, setShowCustomClassifiersSection] = useState<boolean>(false);
  const [isSelectedClassifiersEmpty, setIsSelectedClassifiersEmpty] = useState<boolean>(false);
  const [valueOfCheckedRadioButton, setValueOfCheckedRadioButton] = useState<string>('global');
  const [nerClassifiersTitleToIdMap, setNerClassifiersTitleToIdMap] = useState<Record<string, string>>({});
  const [classifiersList, setClassifiersList] = useState<BigidSearchableListItem[]>([]);
  const [deletedClassifier, setDeletedClassifier] = useState<ScanProfileClassifier[]>([]);
  const [listToRender, setListToRender] = useState<BigidSearchableListItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles({});
  const classifierTypeRegex = 'REGEX';

  const getNerClassifierIdFromTitle = useCallback(
    (text: string, subText: string): string => {
      const advancedClassifierTypeList = [CLASSIFIER_ALGORITHMS.NER, CLASSIFIER_ALGORITHMS.DOC];
      if (!advancedClassifierTypeList.includes(subText)) {
        return text;
      }
      return nerClassifiersTitleToIdMap[text] || text;
    },
    [nerClassifiersTitleToIdMap],
  );
  const handleOnSelect = useCallback(
    (selectedItems: BigidSearchableListItem[]): void => {
      onClassifierSelect(
        selectedItems.map(({ subText, text }) => ({
          type: subText || classifierTypeRegex,
          name: getNerClassifierIdFromTitle(text, subText),
        })),
      );

      setIsSelectedClassifiersEmpty(selectedItems.length === 0);
    },
    [getNerClassifierIdFromTitle, onClassifierSelect],
  );

  const getListOfClassifiersToRender = (
    classifiersList: BigidSearchableListItem[],
    selectedClassifiers: ScanProfileClassifier[],
  ) => {
    setIsLoading(true);
    const classifierListToRender = classifiersList.map(classifier => {
      const foundSameClassifier = selectedClassifiers.some(selectedClassifier => {
        return selectedClassifier.name === getNerClassifierIdFromTitle(classifier.text, classifier.subText);
      });
      return { ...classifier, checked: foundSameClassifier };
    });
    setTimeout(() => setIsLoading(false), 50);
    return classifierListToRender;
  };

  useEffect(() => {
    selectedClassifiersEmpty(isSelectedClassifiersEmpty);
  }, [isSelectedClassifiersEmpty, selectedClassifiersEmpty]);

  useEffect(() => {
    try {
      const fetchClassifiers = async () => {
        const { classifiers: fetchedClassifiers } = await getNormalizedClassifiers('filterClassificationRegex=true');
        setNerClassifiersTitleToIdMap(
          fetchedClassifiers.reduce(
            (acc, { nerTitle, originalData }) =>
              nerTitle
                ? {
                    ...acc,
                    [nerTitle]: originalData?.[0]?._id,
                  }
                : acc,
            {},
          ),
        );
        const reorganizeClassifiersArray = fetchedClassifiers.map((classifier: NormalizedClassifier) => ({
          text: classifier.nerTitle || classifier.name,
          subText: classifier.type === 'data' ? '' : classifier.type.toUpperCase(),
          checked: classifier.originalData[0].enabled == null || classifier.originalData[0].enabled == true,
          description: 'description' in classifier.originalData[0] ? classifier.originalData[0].description : undefined,
          subTextColor:
            classifier.type === 'doc'
              ? BigidColors.blue[700]
              : classifier.type === CLASSIFIER_ALGORITHMS.NER.toLowerCase() && BigidColors.purple[900],
        }));

        setClassifiersList(reorganizeClassifiersArray);
      };
      fetchClassifiers();
    } catch (e) {
      notificationService.error('An error has occurred');
      console.error(e.message);
    }
  }, []);

  useEffect(() => {
    setShowCustomClassifiersSection(valueOfCheckedRadioButton === 'select');
  }, [valueOfCheckedRadioButton]);

  useEffect(() => {
    if (classifiers.length === 0 && classifiersList.length > 0) {
      setListToRender(classifiersList);
    } else {
      if (classifiers.length > 0 && classifiersList.length > 0) {
        setListToRender(getListOfClassifiersToRender(classifiersList, classifiers));
        setValueOfCheckedRadioButton('select');
        setDeletedClassifier(
          classifiers.filter(savedClassifier =>
            classifiersList.every(
              classifier =>
                getNerClassifierIdFromTitle(classifier.text, classifier.subText) !== savedClassifier.name &&
                (classifier.subText !== savedClassifier.type ||
                  (savedClassifier.type === classifierTypeRegex && classifier.subText.length !== 0)),
            ),
          ),
        );
      }
    }
  }, [classifiersList, classifiers]); // eslint-disable-line react-hooks/exhaustive-deps

  const textWhenClassifierDeleted = useMemo(() => {
    return deletedClassifier.length > 1
      ? 'Classifiers ' +
          deletedClassifier?.map(a => ' ' + a.name) +
          ' have been deleted from the system and, therefore, they have also been deleted from this scan profile'
      : 'A classifier ' +
          deletedClassifier?.[0]?.name +
          ' has been deleted from the system and, therefore, it has also been deleted from this scan profile';
  }, [deletedClassifier]);

  const handleRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setValueOfCheckedRadioButton(value);
    setIsLoading(true);
    setShowCustomClassifiersSection(value === 'select');
    if (value === 'global') {
      onClassifierSelect([]);
      setIsSelectedClassifiersEmpty(false);
    }
    setTimeout(() => setIsLoading(false), 50);
  };

  const confirmDeleteMessage = (): void => {
    deletedClassifier.map(deletedOne => {
      const filteredArray = classifiers.filter(classifier => {
        return classifier.name !== deletedOne.name;
      });
      onClassifierSelect(filteredArray);
      onClassifierDelete();
    });
    setDeletedClassifier([]);
  };

  const handleNavigateToClassifiersPage = (): void => {
    $state.go('classifiers');
  };

  return (
    <div className={classes.wrapper}>
      <BigidHeading5 className={classes.header}>Classifiers</BigidHeading5>
      <div className={classes.sectionContainer}>
        <RadioGroup onChange={handleRadioOnChange}>
          {radioOption.map((option, index) => {
            return (
              <div className={classes.radioButton} key={index}>
                {/* //FIXME: reminder: make radioButton small! */}
                <BigidRadio
                  value={option.value}
                  label={option.label}
                  dataAid={`scanProfileClassifiers-radioButton-${option.value}`}
                  checked={valueOfCheckedRadioButton === option.value}
                  size={'small'}
                />
                <BigidTooltip title={option.tooltip} placement="top">
                  <div>
                    <BigidHelpIcon className={classes.helpCircle} />
                  </div>
                </BigidTooltip>
              </div>
            );
          })}
        </RadioGroup>
        <BigidCaption className={classes.links}>
          <BigidLink text="Review Classifiers" onClick={handleNavigateToClassifiersPage} />
        </BigidCaption>
      </div>
      {showCustomClassifiersSection && (
        <>
          <BigidCaption
            className={classes.selectTitle}
            display="block"
            data-aid="ScanProfileClassifiers-selected-classifiers"
          >
            Select which classifiers will be enabled when this scan is running
          </BigidCaption>
          {listToRender.length > 0 && (
            <div className={classes.listOverflow}>
              <BigidCaption className={classes.selectMsg} data-aid="ScanProfileClassifiers-select-msg">
                {isSelectedClassifiersEmpty ? 'To save scan profile please select at least one classifier' : ''}
              </BigidCaption>
              {isLoading && (
                <div className={classes.loaderPosition}>
                  <BigidLoader position={'relative'} />
                </div>
              )}
              {!isLoading && (
                <BigidSearchableList
                  listItems={listToRender}
                  onSelect={handleOnSelect}
                  showToolbar
                  showSelectionColumn
                  searchPlaceHolder="Search by name or description"
                  nameColumnWidth={100}
                  entityName="Classifiers"
                  showDescription
                />
              )}
            </div>
          )}
        </>
      )}
      {deletedClassifier.length > 0 && (
        <BigidDialog
          isOpen={deletedClassifier.length > 0}
          maxWidth="xs"
          showCloseIcon={false}
          onClose={confirmDeleteMessage}
          title="Information"
          borderTop
          buttons={[{ text: 'OK', onClick: confirmDeleteMessage, component: PrimaryButton }]}
        >
          {textWhenClassifierDeleted}
        </BigidDialog>
      )}
    </div>
  );
};

angular
  .module('app')
  .component(
    'scanProfileClassifiers',
    convertToAngular<ScanProfileClassifiersProps>(ScanProfileClassifiers, [
      'classifiers',
      'onClassifierSelect',
      'selectedClassifiersEmpty',
      'onClassifierDelete',
    ]),
  );
