import React, { useMemo, useState } from 'react';
import { BigidRemoveIcon, BigidKeyIcon, BigidAdministrationIcon, BigidAddContainedIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import {
  ActionData,
  BigidColorsV2,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidTooltip,
  EntityEvents,
  ToolbarActionType,
  entityEventsEmitter,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumn,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import { DsarObjectsColumns } from '../ProfileSettingsTypes';
import { getCellValueForIncludeExcludeV2 as getCellValueForIncludeExclude } from '../gridCellValues';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { getObjectsColumns, updateObjectsColumns } from '../../dsarService';
import { ObjectsColumnsAttributesDialog, useObjectsColumnsAttributesDialog } from './ObjectsColumnsAttributesDialog';
import { DsarSettingsTrackingEvents, trackDsar } from '../../analyticsUtils';

const GridWrapper = styled('div')`
  background-color: ${BigidColorsV2.white};
  border-radius: 4px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const ColumnPrimaryWrapper = styled('div')`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

type Grid = BigidGridWithToolbarProps<DsarObjectsColumns>;
type ObjectsSettingsSidePanelContentProps = {
  profileId: string;
  fullyQualifiedObjectName: string;
  objectId: string;
};

const columns: BigidGridColumn<DsarObjectsColumns>[] = [
  {
    width: 175,
    title: 'Column Name',
    name: 'columnName',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ columnName, isPrimaryKey }) =>
      isPrimaryKey ? (
        <ColumnPrimaryWrapper>
          {`${columnName} `}
          <BigidTooltip title="Primary key column indicator">
            <span>
              <BigidKeyIcon />
            </span>
          </BigidTooltip>
        </ColumnPrimaryWrapper>
      ) : (
        columnName
      ),
    filteringEnabled: false,
    sortingEnabled: false,
    tooltip: {
      value: 'Include this column in scan results',
    },
  },
  {
    width: 110,
    title: 'Include',
    name: 'isIncluded',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ isIncluded }) => getCellValueForIncludeExclude(isIncluded),
    filteringEnabled: false,
    sortingEnabled: false,
    tooltip: {
      value: 'Include this column in scan results',
    },
  },
  {
    title: 'Data Type',
    name: 'columnType',
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ columnType }) => columnType,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    title: 'Search Attributes',
    name: 'attributes1',
    type: BigidGridColumnTypes.CHIPS,
    width: 300,
    getCellValue: getIncludedAttributesCellValue(true),
    filteringEnabled: false,
    sortingEnabled: false,
    tooltip: {
      value: 'Use these attributes to search for DSAR data',
    },
  },
  {
    title: 'Disregard Attributes',
    name: 'attributes2',
    type: BigidGridColumnTypes.CHIPS,
    width: 190,
    getCellValue: getIncludedAttributesCellValue(false),
    filteringEnabled: false,
    sortingEnabled: false,
    tooltip: {
      value: 'Do not use these attributes to search for DSAR data',
    },
  },
];

export const ObjectsSettingsSidePanelContent = ({
  profileId,
  fullyQualifiedObjectName,
  objectId,
}: ObjectsSettingsSidePanelContentProps) => {
  const [attributeNames, setAttributeNames] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const filterToolbarConfig = useMemo(() => getFilterConfig(attributeNames, searchTerm), [attributeNames, searchTerm]);
  const objectsColumnsAttributesDialogHook = useObjectsColumnsAttributesDialog();

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarObjectsColumns> = useMemo(() => {
    const reloadGrid = (shouldReload: boolean) => {
      if (shouldReload) {
        // Inline actions don't trigger grid reload without this emitter.
        entityEventsEmitter.emit(EntityEvents.RELOAD);
      }
    };

    const includeExcludeExecutor =
      (isIncludedInReport: boolean, isInlineAction?: boolean) =>
      async ({ selectedRowIds }: ActionData) => {
        const selectedColumns = selectedRowIds as string[];
        const shouldReload = await includeExcludeObjects(
          profileId,
          objectId,
          isIncludedInReport,
          selectedColumns,
          fullyQualifiedObjectName,
        );

        reloadGrid(shouldReload);
        if (isInlineAction) {
          const event = isIncludedInReport
            ? DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_INCLUDE_INLINE_ACTION
            : DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_EXCLUDE_INLINE_ACTION;
          trackDsar(event);
        }

        return { shouldGridReload: shouldReload, shouldClearSelection: shouldReload };
      };

    const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarObjectsColumns> = {
      showFilteringControls: true,
      columns: columns,
      entityName: 'columns',
      defaultSorting: [{ field: 'columnName', order: 'asc' }],
      toolbarActions: [
        {
          label: 'Include',
          type: ToolbarActionType.TERTIARY,
          icon: BigidAddContainedIcon,
          execute: includeExcludeExecutor(true),
          disable: shouldDisableAction,
          show: ({ totalRows, selectedRowIds }) =>
            !!totalRows && !!selectedRowIds.length && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_INCLUDE_ACTION,
          },
        },
        {
          label: 'Exclude',
          type: ToolbarActionType.TERTIARY,
          icon: BigidRemoveIcon,
          execute: includeExcludeExecutor(false),
          disable: shouldDisableAction,
          show: ({ totalRows, selectedRowIds }) =>
            !!totalRows && !!selectedRowIds.length && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_EXCLUDE_ACTION,
          },
        },
        {
          label: 'Manage Attributes',
          icon: BigidAdministrationIcon,
          execute: async ({ selectedRows }) => {
            const { columnName, attributeList } = (selectedRows[0] || {}) as DsarObjectsColumns;
            trackDsar(DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_INLINE_ACTION);
            await objectsColumnsAttributesDialogHook.openDialog({
              attributeList: (attributeList || []).sort(
                (a1, a2) =>
                  a1?.attributeName?.localeCompare(a2?.attributeName) ||
                  a1?.attributeOriginalName?.localeCompare(a2?.attributeOriginalName),
              ),
              columnName,
              profileId,
              fullyQualifiedObjectName,
            });
            return { shouldClearSelection: true };
          },
          disable: () => false,
          show: () => true,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Include',
          icon: BigidAddContainedIcon,
          execute: includeExcludeExecutor(true, true),
          disable: () => false,
          show: ({ selectedRows }) => !selectedRows?.[0]?.isIncluded,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Exclude',
          icon: BigidRemoveIcon,
          execute: includeExcludeExecutor(false, true),
          disable: () => false,
          show: ({ selectedRows }) => selectedRows?.[0]?.isIncluded,
          isInline: true,
          hideActionInToolBar: true,
        },
      ],
      filterToolbarConfig,
      onGridStateChange: ({ filter }) => {
        const searchFilter = filter.find(({ operator }) => operator === 'textSearch');
        if ((searchFilter || searchTerm) && searchFilter?.value !== searchTerm) {
          setSearchTerm((searchFilter?.value || '') as string);
        }
      },
      fetchData: async gridQueryParams => {
        const { columns } = await getObjectsColumnsByProfileId(profileId, fullyQualifiedObjectName);
        const attributeNames = getAttributeNames(columns);
        setAttributeNames(attributeNames);
        const filteredColumns = filterProfiles(columns, gridQueryParams);
        return {
          data: filteredColumns,
          totalCount: filteredColumns.length,
        };
      },
    };

    return gridWithToolbarConfig;
  }, [
    filterToolbarConfig,
    profileId,
    objectId,
    fullyQualifiedObjectName,
    objectsColumnsAttributesDialogHook,
    searchTerm,
  ]);

  return (
    <>
      <GridWrapper>
        <BigidGridWithToolbar {...gridWithToolbarConfig} />
        <ObjectsColumnsAttributesDialog {...objectsColumnsAttributesDialogHook.dialogProps} />
      </GridWrapper>
    </>
  );
};

const getObjectsColumnsByProfileId = async (
  profileId: string,
  fullyQualifiedObjectName: string,
): Promise<{ columns: DsarObjectsColumns[]; totalCount: number }> => {
  try {
    const { columns, totalCount } = await getObjectsColumns(profileId, fullyQualifiedObjectName);
    return { columns: columns.map(column => ({ ...column, id: column.columnName })), totalCount };
  } catch (err) {
    notificationService.error(`Failed to get Objects Columns.`);
    console.error(`Failed to get SAR Request objects columns by profileId '${profileId}': ${JSON.stringify(err)}`);
    return { columns: [], totalCount: 0 };
  }
};

const shouldDisableAction = ({ selectedRowIds }: ActionData) => !selectedRowIds.length;

const getAttributeNames = (columns: DsarObjectsColumns[]) => [
  ...new Set(
    columns.reduce(
      (arr, { attributeList }) => [...arr, ...attributeList.map(({ attributeName }) => attributeName)],
      [],
    ),
  ),
];

const includeExcludeObjects = async (
  profileId: string,
  objectId: string,
  isIncluded: boolean,
  selectedColumns: string[],
  fullyQualifiedObjectName: string,
) => {
  try {
    const key = isIncluded ? 'includeColumns' : 'excludeColumns';
    await updateObjectsColumns(profileId, fullyQualifiedObjectName, {
      [key]: { columns: selectedColumns },
    });
    notificationService.success(`Changes saved`);
    return true;
  } catch (err) {
    notificationService.error(`Failed to update Objects Settings.`);
    console.error(
      `Failed to update Objects Settings by objectId: ${objectId} and excludedColumns '${selectedColumns.join()}': ${JSON.stringify(
        err,
      )}`,
    );
    return false;
  }
};

const getFilterConfig = (attributeNames: string[] | null, searchTerm: string): Grid['filterToolbarConfig'] => {
  if (!attributeNames) {
    return;
  }
  return {
    filters: [
      {
        title: 'Selection',
        field: 'isIncluded',
        operator: 'equal',
        single: true,
        value: [],
        options: [
          { value: true, label: 'Included', isSelected: false },
          { value: false, label: 'Excluded', isSelected: false },
        ],
      },
      {
        title: 'Attribute Name',
        field: 'attributeList',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: attributeNames.map(name => ({ value: name, label: name, isSelected: false })),
      },
      {
        title: 'Includes Attributes',
        field: 'hasAttributeList',
        operator: 'equal',
        single: true,
        value: [],
        options: [
          { value: true, label: 'Yes', isSelected: false },
          { value: false, label: 'No', isSelected: false },
        ],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['columnName'],
      initialValue: searchTerm,
      operator: 'textSearch',
    },
  };
};

const filterProfiles = (columns: DsarObjectsColumns[], queryComponents: BigidGridQueryComponents) =>
  columns.filter(column => {
    const getFilter = (filterName: string) => queryComponents.filter.find(filter => filter.field === filterName);

    const selectionPredicate = () => {
      const selectionFilter = getFilter('isIncluded');
      if (!selectionFilter) {
        return true;
      }
      const columnProp = selectionFilter.field as keyof DsarObjectsColumns;
      const columnValue = column[columnProp] as keyof DsarObjectsColumns;
      const filterValue = selectionFilter.value;
      return filterValue === columnValue;
    };

    const attributeNamesPredicate = () => {
      const filter = getFilter('attributeList');
      if (!filter) {
        return true;
      }
      const columnProp = filter.field as keyof DsarObjectsColumns;
      const attributeList = column[columnProp] as DsarObjectsColumns['attributeList'];
      const attributeListNames = attributeList.map(({ attributeName }) => attributeName);
      const filterValues = filter.value as string[];
      return !!filterValues.filter(Set.prototype.has, new Set(attributeListNames)).length;
    };

    const hasAttributeListPredicate = () => {
      const filter = getFilter('hasAttributeList');
      if (!filter) {
        return true;
      }
      const attributeList = column['attributeList'];
      const attributeListHasValues = !!attributeList.length;
      const filterValue = filter.value;
      return filterValue === attributeListHasValues;
    };

    const searchPredicate = () => {
      const prop = 'columnName';
      const searchFilter = getFilter(prop);
      if (!searchFilter) {
        return true;
      }
      const columnName = column[prop]?.toLowerCase() as keyof DsarObjectsColumns;
      const filterValue = (searchFilter.value as string).toLowerCase();
      return columnName.includes(filterValue);
    };

    return selectionPredicate() && attributeNamesPredicate() && hasAttributeListPredicate() && searchPredicate();
  });

function getIncludedAttributesCellValue(isIncluded: boolean) {
  return ({ attributeList }: DsarObjectsColumns) => ({
    chips: {
      value: attributeList
        .filter(attr => attr.isIncluded === isIncluded)
        .map(({ attributeName, rank, calcConfidenceLevel }) => {
          const confidenceLevel = `${(calcConfidenceLevel * 100).toFixed(0)}%`;
          return {
            label: `${attributeName}${calcConfidenceLevel ? ` (${confidenceLevel})` : ''}`,
            icon: <BigidConfidenceIndicator level={rank.toLowerCase() as BigidConfidenceLevel} />,
            tooltipProps: {
              title: `${attributeName} (${rank}${calcConfidenceLevel ? ` - ${confidenceLevel}` : ''})`,
            },
          };
        }),
      isDisabled: true,
      isAutoFit: false,
      entityMaxWidth: 200,
    },
  });
}
