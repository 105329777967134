import React, { ChangeEvent, useState } from 'react';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { PrimaryButton, TertiaryButton, BigidTextField } from '@bigid-ui/components';
import { getFixedT, useLocalTranslation } from '../../translations';
import { notificationService } from '../../../../services/notificationService';

const TRANSLATION_PATH = 'wizard.previewStep.addAliasDialog';
const t = getFixedT(TRANSLATION_PATH);

interface DialogContentProps {
  value: string;
  onChange: (value: string, isFormValid: boolean) => void;
}

export function isAliasNameValid(name = '') {
  const pattern = new RegExp(/^[\w\-\s\(\):]+$/);
  const isValid = pattern.test(name);

  if (!isValid) {
    return t('validationMessage');
  }

  return '';
}

export const DialogContent = ({ value, onChange }: DialogContentProps) => {
  const { t } = useLocalTranslation(TRANSLATION_PATH);
  const [aliasName, setAliasName] = useState(value);

  const [errorMessage, setErrorMessage] = useState('');

  const onHandleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const errorMessage = isAliasNameValid(value);

    setAliasName(value);
    setErrorMessage(errorMessage);

    onChange(value, !errorMessage);
  };

  return (
    <>
      <BigidTextField
        value={aliasName}
        onChange={onHandleChange}
        label={t('label')}
        errorMessage={errorMessage}
        autoFocus
      />
    </>
  );
};

interface openAddColumnAliasDialogProps {
  alias: string;
}

export const openAddColumnAliasDialog = async ({ alias }: openAddColumnAliasDialogProps) => {
  return new Promise<{ isSaved: boolean; aliasName?: string }>(resolve => {
    let aliasName = alias || '';
    let isFormValid = true;

    const contentProps = {
      onChange: (value: string, isValid: boolean) => {
        aliasName = value;
        isFormValid = isValid;
      },
      value: aliasName,
    };

    openSystemDialog({
      title: t('title'),
      contentProps,
      content: DialogContent,
      onClose: () => null,
      maxWidth: 'xs',
      buttons: [
        {
          text: t('discardBtn'),
          component: TertiaryButton,
          onClick: () => {
            resolve({
              isSaved: false,
            });
          },
          isClose: true,
        },
        {
          text: t('saveBtn'),
          component: PrimaryButton,
          onClick: () => {
            if (!isFormValid) {
              notificationService.error('The alias name is not valid');
              resolve({
                isSaved: false,
              });
              return;
            }

            resolve({
              aliasName,
              isSaved: true,
            });
          },
          isClose: true,
        },
      ],
    });
  });
};
