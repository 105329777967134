import React from 'react';
import { styled } from '@mui/material';
import { AppIntegrationSettings } from '../../../DataCatalog/DataCatalogService';
import { AppIntegrationTab } from '../../../DataCatalog/DataCatalogAppIntegrations/AppIntegrationTab';

export const GridWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-grow: 1;
`;

type DataRetentionTabProps = {
  data: AppIntegrationSettings;
  fullyQualifiedName: string;
};

export const DataRetentionTab: React.FC<DataRetentionTabProps> = ({ data, fullyQualifiedName }) => {
  const { appId, appFriendlyName, objectDetails } = data;

  return (
    <GridWrapper>
      <AppIntegrationTab
        fullyQualifiedName={fullyQualifiedName}
        tabSettings={objectDetails.tabs[0]}
        appId={appId}
        appName={appFriendlyName}
        shouldIgnoreQueryParams
      />
    </GridWrapper>
  );
};
