import { BigidAreaChart } from '@bigid-ui/visualisation';
import { BigidColorsV2, capitalizeFirstLetter } from '@bigid-ui/components';
import React, { FC } from 'react';
import { DataDuplicationAreaChartProps } from '../types/DataDuplicationWidgetTypes';

const DATA_DUPLICATION_CONFIG = {
  color: BigidColorsV2.orange[600],
  amount: {
    formatter: '#.A',
  },
  volume: {
    formatter: '#.B',
  },
} as const;

export const DataDuplicationAreaChart: FC<DataDuplicationAreaChartProps> = ({ selectedView, data }) => {
  return (
    <BigidAreaChart
      legendProps={{ hideLegend: true }}
      colorDataMap={{ [selectedView]: DATA_DUPLICATION_CONFIG.color }}
      isLineSmoothed={true}
      numberFormat={DATA_DUPLICATION_CONFIG[selectedView].formatter}
      data={data}
      seriesLabelFormatter={series => capitalizeFirstLetter(series)}
      showChartCursor={true}
    />
  );
};
