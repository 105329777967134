import React from 'react';
import { CatalogGridCardBaseData } from '../../types';
import { BigidObjectPath, BigidObjectPathLevel } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { getDataSourceIconByDsType } from '@bigid-ui/icons';

const Root = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 100%;
`;

type FullPathProps = Pick<CatalogGridCardBaseData, 'fullPath'> & {
  highlight?: string;
  dsType: string;
};

export const FullPath = ({ fullPath, highlight, dsType }: FullPathProps) => {
  const dataAid = 'FullPath';

  const fullPathToObjectPathMap: BigidObjectPathLevel[] = fullPath.map<BigidObjectPathLevel>(({ name }, index) => {
    const objectPath: BigidObjectPathLevel = {
      name: name,
      highlight,
    };

    if (index === 0) {
      objectPath.icon = {
        icon: getDataSourceIconByDsType(dsType),
        tooltip: dsType,
      };
    }

    return objectPath;
  });

  return (
    <Root data-aid={generateDataAid(dataAid, ['root'])}>
      <BigidObjectPath path={fullPathToObjectPathMap} />
    </Root>
  );
};
