import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidPaper, objectToQueryString, ToolbarActionType } from '@bigid-ui/components';
import { getDetailsByObjectName } from '../DataCatalogDetails/DataCatalogDetailsService';
import { notificationService } from '../../../services/notificationService';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import {
  findSimilarTables,
  getLatestSimilarTablesData,
  RequestStatus,
  SimilarTablesResponse,
  SimilarTablesResult,
  tagSimilarTables,
} from './services/dataCatalogSimilarTablesService';
import { getGridEntityName, getGridEntityTooltip, getSimilarTablesGridColumns } from './utilities/gridUtils';
import styled from '@emotion/styled';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { SSE_EVENTS } from '../../../services/sseService';
import { RequestContent } from './Request/RequestContent';
import { ThresholdDialog } from './ThresholdModal/ThresholdDialog';
import { ResultsContentLoader } from './Results/ResultsContentLoader';
import { NoDataContentEmptyState } from './EmptyStates/NoDataContentEmptyState';
import { NotSupportedEmptyState } from './EmptyStates/NotSupportedEmptyState';
import { BigidAddTagIcon, BigidInProgressIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { AssignTagDialog } from './Actions/AssignTagDialog';
import { isPermitted } from '../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { TagBulkAssignmentPayloadData } from '../DataCatalogAsyncOps/operations/TagBulkAssignment';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getIsRequestDetailsAvailableByStatus, SIMILAR_TABLES_TRACKING_EVENTS } from './utilities/utils';
import { useLocalTranslation } from './translations';
import { similarTablesEventEmitter } from './services/similarTablesEventService';
import { RequestFailedEmptyState } from './EmptyStates/RequestFailedEmptyState';
import { analyticsService } from '../../../services/analyticsService';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '0px 16px',
  gap: '12px',
  overflow: 'auto',
  width: '100%',
});

const GridWrapper = styled('div')({
  flexDirection: 'column',
  alignItems: 'flex-start',
  overflow: 'auto',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
});

const LoaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
});

const gridColumns = getSimilarTablesGridColumns();

type DataCatalogSimilarTables = {
  fullyQualifiedName: string;
};
export const DataCatalogSimilarTables: FunctionComponent<DataCatalogSimilarTables> = ({ fullyQualifiedName }) => {
  const { t } = useLocalTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSupported, setIsSupported] = useState<boolean>(false);
  const [similarTablesData, setSimilarTablesData] = useState<SimilarTablesResponse>();
  const [isSimilarityThresholdDialogOpen, setIsSimilarityThresholdDialogOpen] = useState<boolean>(false);
  const [isAssignTagDialogOpen, setIsAssignTagDialogOpen] = useState<boolean>(false);
  const [wasInitialFetchAttempt, setWasInitialFetchAttempt] = useState<boolean>(false);

  const resetStates = () => {
    setIsSupported(false);
    setSimilarTablesData(null);
    setWasInitialFetchAttempt(false);
  };

  const handleOpenAssignTagDialog = () => {
    setIsAssignTagDialogOpen(true);
  };

  const handleCloseAssignTagDialog = () => {
    setIsAssignTagDialogOpen(false);
  };

  const handleSubmitAssignTagDialog = async (tagsToAssign: TagBulkAssignmentPayloadData) => {
    try {
      await tagSimilarTables({
        requestId: similarTablesData?.request?.requestId,
        catalogTag: tagsToAssign?.tags?.[0],
        totalResults: similarTablesData?.totalResults,
      });
      handleCloseAssignTagDialog();
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    }
  };

  const handleOpenSimilarityThresholdDialog = () => {
    setIsSimilarityThresholdDialogOpen(true);
  };

  const handleCloseThresholdDialog = () => {
    setIsSimilarityThresholdDialogOpen(false);
  };

  const handleSubmitThresholdDialog = async (threshold: number) => {
    try {
      const response = await findSimilarTables({
        fullyQualifiedName,
        threshold,
      });
      setIsLoading(true);
      setSimilarTablesData(response);
      handleCloseThresholdDialog();
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickRecalculate = async (fullyQualifiedName: string, threshold: number) => {
    try {
      const query = objectToQueryString({ recalculate: true });
      const response = await findSimilarTables(
        {
          fullyQualifiedName,
          threshold,
        },
        query,
      );
      setSimilarTablesData(response);
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    }
  };

  const handleFindSimilarTablesEvent = useCallback(
    async (eventFullyQualifiedName: string) => {
      if (fullyQualifiedName === eventFullyQualifiedName) {
        try {
          const response = await getLatestSimilarTablesData(eventFullyQualifiedName);
          setSimilarTablesData(response);
          analyticsService.trackManualEvent(SIMILAR_TABLES_TRACKING_EVENTS.FIND_SIMILAR_TABLES_RESULT, {
            threshold: response?.request?.threshold,
            totalResults: response?.totalResults,
          });
        } catch (error) {
          notificationService.error(t('commonError'));
          console.error(`${t('commonError')}: ${error}`);
        }
      }
    },
    [fullyQualifiedName],
  );

  const checkSupportedObjectAndFetchLatestData = useCallback(async (fullyQualifiedName: string) => {
    try {
      const {
        data: { ds },
      } = await getDetailsByObjectName(fullyQualifiedName);
      const structuredClusteringEnabled = ds?.[0]?.structured_clustering_enabled;
      if (structuredClusteringEnabled) {
        const response = await getLatestSimilarTablesData(fullyQualifiedName);
        setSimilarTablesData(response);
        setIsSupported(true);
      } else {
        setIsSupported(false);
      }
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    } finally {
      setWasInitialFetchAttempt(true);
    }
  }, []);

  useEffect(() => {
    const deregister: EventEmitterDeregistrator = similarTablesEventEmitter.addEventListener(
      SSE_EVENTS.FIND_SIMILAR_TABLES_EVENT,
      handleFindSimilarTablesEvent,
    );
    return () => {
      deregister?.();
    };
  }, [handleFindSimilarTablesEvent]);

  useEffect(() => {
    resetStates();
    checkSupportedObjectAndFetchLatestData(fullyQualifiedName);
  }, [fullyQualifiedName]);

  const isReady: boolean = useMemo(() => {
    return getIsRequestDetailsAvailableByStatus(similarTablesData?.request?.status);
  }, [similarTablesData]);

  const gridConfig = useMemo<BigidGridWithToolbarProps<SimilarTablesResult>>(
    () => ({
      columns: gridColumns,
      showSortingControls: false,
      hideColumnChooser: true,
      fetchData: async () => {
        return {
          totalCount: similarTablesData?.results?.length,
          data: similarTablesData?.results,
        };
      },
      entityName: getGridEntityName(similarTablesData?.results?.length, similarTablesData?.totalResults),
      entityTooltip: getGridEntityTooltip(similarTablesData?.results?.length, similarTablesData?.totalResults),
      toolbarActions: [
        {
          label: t('grid.actions.assignTag.label'),
          icon: similarTablesData?.request?.isAssignTag ? BigidInProgressIcon : BigidAddTagIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: similarTablesData?.request?.isAssignTag ? t('grid.actions.assignTag.tooltip') : '',
          isGlobal: true,
          execute: async () => {
            handleOpenAssignTagDialog();
            return { shouldGridReload: false };
          },
          disable: () => {
            return similarTablesData?.request?.isAssignTag || !similarTablesData?.results?.length;
          },
          show: () =>
            isPermitted(TAGS_PERMISSIONS.CREATE.name) &&
            isPermitted(TAGS_PERMISSIONS.READ.name) &&
            isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
        },
        {
          label: t('grid.actions.viewObject.label'),
          isTooltipFollowCursor: true,
          show: () => true,
          execute: async ({ selectedRows }) => {
            const { fullyQualifiedName } = selectedRows[0];
            $state.go(CONFIG.states.CATALOG_PREVIEW, {
              id: encodeURIComponent(fullyQualifiedName),
            });
            return { shouldGridReload: false };
          },
          disable: () => {
            return false;
          },
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidExternalLinkIcon,
        },
      ],
    }),
    [similarTablesData],
  );

  return (
    <Wrapper>
      <ThresholdDialog
        isOpen={isSimilarityThresholdDialogOpen}
        onClose={handleCloseThresholdDialog}
        onSubmit={handleSubmitThresholdDialog}
      />
      <AssignTagDialog
        isOpen={isAssignTagDialogOpen}
        onClose={handleCloseAssignTagDialog}
        onSubmit={handleSubmitAssignTagDialog}
      />
      {wasInitialFetchAttempt && (
        <Fragment>
          {!isSupported && <NotSupportedEmptyState />}
          {isSupported && (
            <Fragment>
              {similarTablesData ? (
                <Fragment>
                  <RequestContent
                    requestDetails={similarTablesData?.request}
                    handleOpenSimilarityThresholdDialog={handleOpenSimilarityThresholdDialog}
                    handleClickRecalculate={handleClickRecalculate}
                  />
                  {isReady && !isLoading ? (
                    <Fragment>
                      {similarTablesData?.request?.status === RequestStatus.FAILED ? (
                        <RequestFailedEmptyState />
                      ) : (
                        <GridWrapper>
                          <BigidPaper>
                            <BigidGridWithToolbar {...gridConfig} />
                          </BigidPaper>
                        </GridWrapper>
                      )}
                    </Fragment>
                  ) : (
                    <LoaderWrapper>
                      <ResultsContentLoader />
                    </LoaderWrapper>
                  )}
                </Fragment>
              ) : (
                <NoDataContentEmptyState handleOpenSimilarityThresholdDialog={handleOpenSimilarityThresholdDialog} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};
