import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Aside } from './mappers/styles';
import { DataSourceConnectionErrorInsights } from './DataSourceConnectionErrorInsights';
import { DataSourceConnectionGuide } from './DataSourceConnectionGuide/DataSourceConnectionGuide';
import { DataSourceConnectionResults } from './DataSourceConnectionResults/DataSourceConnectionResults';
import { DataSourceConnectionDialogLayout } from '../DataSourceConnectionDialog/DataSourceConnectionDialog';
import { mapTestConnectionResolutionStepsToOptions } from './mappers/connection';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../DataSourcesEventTrackerUtils';
import { DsConnectionDocItemResource, DsConnectionDocPreConnectionType as PreConnectionType } from './types';
import { useDataSourceModalContext } from './hooks/useDataSourceModalContext';
import type { TestConnectionErrorResolution } from './hooks/useTestConnection';
import type { GetHighlightedWordsForLogType } from '../DataSourceConfiguration/utils/scannerLogsUtils';
import {
  DataSourceTestConnectionRow,
  DataSourceTestConnectionRowType,
} from '../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';

type DataSourceConnectionModalAsideProps = {
  documentationUrl: string;
  loading?: boolean;
  content: DsConnectionDocItemResource[];
  logs: GetHighlightedWordsForLogType;
  layout: DataSourceConnectionDialogLayout;
  errorInsights: TestConnectionErrorResolution;
  authType: string;
  rowType?: DataSourceTestConnectionRowType;
  rows?: DataSourceTestConnectionRow[];
  onTest: () => void;
  onShowTestResults: () => void;
  onSaveDataSource: (dataSourceName: string) => Promise<boolean>;
  dataSourceName: string;
};

export const DataSourceConnectionModalAside = ({
  documentationUrl,
  content,
  logs,
  loading: isContentLoading,
  layout,
  errorInsights,
  authType,
  rowType,
  rows,
  onShowTestResults,
  onTest,
  onSaveDataSource,
  dataSourceName,
}: DataSourceConnectionModalAsideProps): JSX.Element => {
  const [isExpanded, setExpanded] = useState(true);
  const [isHowToConnectTabExpanded, setHowToConnectTabExpanded] = useState(true);
  const { configDataSourceState, isStandalone } = useDataSourceModalContext();

  const { resolutionSteps, summarizedErrorMessage, detailedErrorMessage } = errorInsights ?? {};
  const options = mapTestConnectionResolutionStepsToOptions(resolutionSteps);
  const tabOverrides = { [PreConnectionType.HOW_TO_CONNECT]: { isOpen: isHowToConnectTabExpanded } };

  const isErrorInsightsVisible = !!errorInsights;
  const isConnectionResultsVisible = !!rows?.length && layout === DataSourceConnectionDialogLayout.STANDALONE;

  const handleShowDocumentation = () => {
    if (documentationUrl) {
      trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SEE_DOCS_CLICK, {
        dsType: configDataSourceState?.selectedType,
      });
      window.open(documentationUrl);
    }
  };

  const handleConnectionGuideTabChange = (_: SyntheticEvent, expanded: boolean) => setExpanded(expanded);

  useEffect(() => {
    const shouldHideConnectionGuide = isErrorInsightsVisible || isConnectionResultsVisible;
    shouldHideConnectionGuide && setExpanded(false);
  }, [isErrorInsightsVisible, isConnectionResultsVisible]);

  useEffect(() => {
    setHowToConnectTabExpanded(true);
  }, [authType]);

  return (
    <Aside.Wrapper>
      <Aside.Content>
        <>
          <DataSourceConnectionGuide
            expanded={!isErrorInsightsVisible && !isConnectionResultsVisible ? true : isExpanded}
            overrides={tabOverrides}
            showExpandIcon={isErrorInsightsVisible}
            loading={isContentLoading}
            content={content}
            onShowDocumentation={handleShowDocumentation}
            onChange={handleConnectionGuideTabChange}
            onSaveDataSource={onSaveDataSource}
            dataSourceName={dataSourceName}
          />
          {isConnectionResultsVisible && (
            <DataSourceConnectionResults
              timestamp={configDataSourceState?.lastTestDate}
              rows={rows}
              rowType={rowType}
              onTest={onTest}
              onShow={onShowTestResults}
            />
          )}
          {isErrorInsightsVisible && (
            <DataSourceConnectionErrorInsights
              logs={logs}
              title={summarizedErrorMessage ?? detailedErrorMessage}
              options={options ?? []}
              resolution={errorInsights}
              authType={authType}
              state={configDataSourceState}
            />
          )}
        </>
      </Aside.Content>
    </Aside.Wrapper>
  );
};
