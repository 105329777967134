import React, { FC, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { BigidMenu, BigidTabsItem, SecondaryButton } from '@bigid-ui/components';
import { BigidBookmarkIcon } from '@bigid-ui/icons';
import { createSavedViewMenuItems, SavedViewData } from '../services/executiveDashboardServices';
import { useLocalTranslation } from '../translations';

export const SAVED_VIEWS_BUTTON_WIDTH = '131px';

export interface SavedViewsMenuProps {
  tabItems: BigidTabsItem<SavedViewData>[];
  currentUserId: string;
  onShowView: (tabId: string) => void;
  onDeleteView: (filterId: string, filterName: string) => Promise<void>;
  onRenameView: (filterId: string, filterName: string) => Promise<void>;
  isEmptySystem?: boolean;
}

const SavedViewsButtonWrapper = styled('div')({
  flexBasis: SAVED_VIEWS_BUTTON_WIDTH,
});

export const SavedViewsMenu: FC<SavedViewsMenuProps> = ({
  tabItems,
  currentUserId,
  onShowView,
  onDeleteView,
  onRenameView,
  isEmptySystem,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuAnchorRef = useRef();
  const { t } = useLocalTranslation();

  const onMenuButtonClick = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <SavedViewsButtonWrapper ref={menuAnchorRef}>
        <SecondaryButton
          startIcon={<BigidBookmarkIcon />}
          text={t('buttonLables.savedViews')}
          size="medium"
          onClick={onMenuButtonClick}
          disabled={isEmptySystem}
          dataAid="saved-views-button"
        />
      </SavedViewsButtonWrapper>
      <BigidMenu
        items={createSavedViewMenuItems(tabItems, currentUserId, onShowView, onDeleteView, onRenameView)}
        onMenuClose={handleCloseMenu}
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        isSearchAvailable
      />
    </>
  );
};
