import {
  AdvancedToolbarOverrideValue,
  DateRangeFilterSchema,
  BigidDropdownOption,
  advancedToolbarFilterUtils,
  BigidAdvancedToolbarFilterUnion,
  BigidAdvancedToolbarFilterTypes as FilterType,
} from '@bigid-ui/components';
import {
  BigidValueType as ValueType,
  QueryConditionOperation as ExpressionFieldOperation,
  QueryExpressionOperator as ExpressionOperation,
  parseAbstractQueryTreeIntoString,
  parseExpressionsIntoAbstractQueryTree,
  getIsValidQueryString,
  getAllAbstractQueryTreeConditions,
  parseAbstractQueryTreeFromNodes,
  AbstractQueryExistsConditionNode as AbstractTreeExistenceNode,
  AbstractQueryConditionNode as AbstractTreeRegularNode,
  AbstractQueryTreeConditions,
  AbstractQueryNode as AbstractTreeNodeUnion,
} from '@bigid/query-object-serialization';
import { notificationService } from '../notificationService';
import { groupBy } from 'lodash';

export { ExpressionFieldOperation as ExpressionFieldOperation };
export { ExpressionOperation as ExpressionOperation };
export { FilterType as FilterType };
export { AbstractTreeNodeUnion as AbstractTreeNodeUnion };
export { AbstractTreeRegularNode as AbstractTreeRegularNode };
export { AbstractTreeExistenceNode as AbstractTreeExistenceNode };

export type ConsumableFilter = AdvancedToolbarOverrideValue;

export type AbstractTreeNodesGrouped = AbstractQueryTreeConditions;

export type ExpressionValuePreprocessorFunction = (option: BigidDropdownOption) => string;

export type ExpressionOperandPreprocessorFunction = (
  filter?: ConsumableFilter,
  option?: BigidDropdownOption,
) => {
  operand: string;
  isNegation?: boolean;
};

export type BiqlToFilterIdComposerFunction = (
  value?: string,
  parentId?: string,
  node?: AbstractTreeNodeUnion,
) => string;

export type BiqlToFilterParentIdComposerFunction = (node?: AbstractTreeNodeUnion) => string;

export type BiqlToFilterConfigLookupFunction = (node?: AbstractTreeNodeUnion) => boolean;

export enum ExpressionType {
  /** operand >= date1 AND operand  =< date2*/
  DATE = 'DATE',
  /** operand IN (string1, string2) */
  STRING_MULTIPLE = 'STRING_MULTIPLE',
  /** prefix.operand1 IN (string1, string2) OR/AND prefix.operand2 IN (string3, string4) */
  STRING_MULTIPLE_NESTED = 'STRING_MULTIPLE_NESTED',
  /** operand = to_bool(value) */
  BOOLEAN = 'BOOLEAN',
  /** exists(operand) */
  BOOLEAN_EXISTS = 'BOOLEAN_EXISTS',
}

/**
  please note that certain optional props are relevant for particular types of filter
*/
export interface FilterConfig {
  /**
    used to fetch filter config when parsing BIQL to filter;
    parser returns operand lower-cased, for example, scanDate becomes scandate - a known problem;
  */
  lookup: BiqlToFilterConfigLookupFunction;
  /**
    filter ID from your page's filter config;
    used also for fetching filter's config when parsing filter to BIQL;
  */
  filterId: string;
  /**
    filter type to match the appropriate toolbar filter type when parsing BIQL to consumable filter;
  */
  filterType: FilterType;
  /**
    filter type to match the appropriate abstract tree node type;
  */
  expressionType: ExpressionType;
  /**
    operation to build an expression when parsin filter to BIQL;
  */
  expressionFieldOperation?: ExpressionFieldOperation;
  /**
    expression operation to build relations between nested expressions;
    or when using special operations such as "exists" when parsing filter to BIQL;
  */
  expressionOperation?: ExpressionOperation;
  /**
    composes an expression operator when parsing filter to BIQL;
    relevant for tags and similar hierarchical filters when operator contains prefixes;
  */
  expressionOperandPreprocessor?: ExpressionOperandPreprocessorFunction;
  /**
    extracts an actual expression value from the dropdown option,
    in case when filter option is an object when parsing filter to BIQL;
  */
  expressionValuePreprocessor?: ExpressionValuePreprocessorFunction;
  /**
   composes filter option ID out of the BIQL expression value;
   relevant for dropdown filters especially for boolean filter when negation with true might result as a special value;
  */
  filterIdComposer?: BiqlToFilterIdComposerFunction;
  /**
   composes filter option parentId out of the operand;
   relevant for dropdown filters such as tags and similar hierarchical filters when operator contains prefixes;
   */
  filterParentIdComposer?: BiqlToFilterParentIdComposerFunction;
}

export type FiltersConfig = FilterConfig[];

export type FilterToBiqlBilateralParserProps = {
  filtersConfig: FiltersConfig;
  isSilentException?: boolean;
};

export class FilterToBiqlBilateralParser {
  private readonly filtersConfig: FiltersConfig;
  private readonly isSilentException: boolean = true;

  constructor({ filtersConfig, isSilentException }: FilterToBiqlBilateralParserProps) {
    this.filtersConfig = filtersConfig;

    if (isSilentException) {
      this.isSilentException = isSilentException;
    }
  }

  private throwException(message?: string): void {
    console.error(`An error has occurred while parsing BIQL expression: ${message}`);

    if (!this.isSilentException) {
      notificationService.warning(message ?? "Can't parse BIQL expression.");
    }
  }

  private getIsFilterObjectValid(filter: ConsumableFilter): boolean {
    return (
      filter.hasOwnProperty('id') &&
      filter.hasOwnProperty('type') &&
      filter.hasOwnProperty('field') &&
      filter.hasOwnProperty('options')
    );
  }

  private getFilterConfigByFilterId(filterId: string): FilterConfig {
    return this.filtersConfig.find(filterConfigEntity => filterConfigEntity.filterId === filterId);
  }

  private getFilterConfigByNode(node: AbstractTreeNodeUnion): FilterConfig {
    return this.filtersConfig.find(({ lookup }) => lookup(node));
  }

  private getAbstractTreeNodeValue(
    options: BigidDropdownOption[],
    valuePreprocessor: ExpressionValuePreprocessorFunction,
  ): string[] {
    return options.map(option => {
      return valuePreprocessor ? valuePreprocessor(option) : String(option.value);
    });
  }

  private getAbstractTreeNodeOperand(
    filter: ConsumableFilter,
    filterConfig: FilterConfig,
    option?: BigidDropdownOption,
  ): {
    operand: string;
    isNegation?: boolean;
  } {
    const { field } = filter;
    const { expressionOperandPreprocessor } = filterConfig;

    let operandComputed: string;
    let isNegativeOperation = false;

    if (expressionOperandPreprocessor) {
      const { operand, isNegation = false } = expressionOperandPreprocessor(filter, option);

      operandComputed = operand;
      isNegativeOperation = isNegation;
    } else {
      operandComputed = field;
    }

    return {
      operand: operandComputed,
      isNegation: isNegativeOperation,
    };
  }

  private getDateAbstractTreeNode(filter: ConsumableFilter, filterConfig: FilterConfig): AbstractTreeNodeUnion {
    const { expressionOperation = ExpressionOperation.AND } = filterConfig;
    const pickersState = (filter.options as DateRangeFilterSchema).pickersState;
    const { from, until } = pickersState.dates;

    const nodes: AbstractTreeNodeUnion[] = [];
    const { operand, isNegation } = this.getAbstractTreeNodeOperand(filter, filterConfig);

    if (from) {
      nodes.push({
        name: operand,
        bigidName: operand,
        operation: ExpressionFieldOperation.GTE,
        operator: ExpressionOperation.UNDEFINED,
        type: ValueType.DATE,
        value: String(from),
        isNegativeOperation: isNegation,
        isIgnored: false,
        isTagsNegativeOperation: false,
        arrayFieldName: null,
      });
    }

    if (until) {
      nodes.push({
        name: operand,
        bigidName: operand,
        operation: ExpressionFieldOperation.LTE,
        operator: ExpressionOperation.UNDEFINED,
        type: ValueType.DATE,
        value: String(until),
        isNegativeOperation: isNegation,
        isIgnored: false,
        isTagsNegativeOperation: false,
        arrayFieldName: null,
      });
    }

    return parseAbstractQueryTreeFromNodes(nodes, expressionOperation) as AbstractTreeNodeUnion;
  }

  private getDateConsumableFilter(nodes: AbstractTreeRegularNode[], filterConfig: FilterConfig): ConsumableFilter {
    const { filterId, filterType } = filterConfig;
    const from = nodes.find(({ operation }) => operation === ExpressionFieldOperation.GTE);
    const until = nodes.find(({ operation }) => operation === ExpressionFieldOperation.LTE);

    return {
      id: filterId,
      type: filterType,
      options: {
        currentRangeOptionSelected: 'custom',
        pickersState: {
          currentMode: 'until',
          dates: {
            from: from ? (from.value as unknown as Date) : null,
            until: until ? (until.value as unknown as Date) : null,
          },
        },
      },
    };
  }

  private getMultipleNestedStringAbstractTreeNode(
    filter: ConsumableFilter,
    filterConfig: FilterConfig,
  ): AbstractTreeNodeUnion {
    const options = filter.options as BigidDropdownOption[];
    const {
      expressionValuePreprocessor,
      expressionFieldOperation = ExpressionFieldOperation.IN,
      expressionOperation = ExpressionOperation.AND,
    } = filterConfig;

    const groupedOptionsByParentId = Object.values(groupBy(options, 'parentId'));

    const nodes = groupedOptionsByParentId.map(options => {
      const { operand, isNegation } = this.getAbstractTreeNodeOperand(filter, filterConfig, options[0]);
      const valueExtracted = this.getAbstractTreeNodeValue(options, expressionValuePreprocessor);

      return {
        name: operand,
        bigidName: operand,
        operation: expressionFieldOperation,
        operator: ExpressionOperation.UNDEFINED,
        type: ValueType.STRING,
        value: valueExtracted,
        isNegativeOperation: isNegation,
        isIgnored: false,
        isTagsNegativeOperation: false,
        arrayFieldName: null,
      };
    }) as AbstractTreeNodeUnion[];

    return parseAbstractQueryTreeFromNodes(nodes, expressionOperation) as AbstractTreeNodeUnion;
  }

  private getMultipleNestedStringConsumableFilter(
    nodes: AbstractTreeRegularNode[],
    filterConfig: FilterConfig,
  ): ConsumableFilter {
    const { filterId, filterType, filterIdComposer, filterParentIdComposer } = filterConfig;

    if (!filterParentIdComposer || !filterIdComposer) {
      throw new Error(
        `'filterIdComposer' and 'filterParentIdComposer' params are mandatory to parse ${filterConfig.expressionType} expression`,
      );
    }

    const parentIdMappedToIds = new Map<string, string[]>();

    for (const node of nodes) {
      const parentId = filterParentIdComposer(node);

      const ids = (node.value as string[]).map(value => {
        return filterIdComposer(value, parentId, node);
      }, []);

      if (parentIdMappedToIds.has(parentId)) {
        const existingIds = parentIdMappedToIds.get(parentId);

        parentIdMappedToIds.set(parentId, [...existingIds, ...ids]);
      } else {
        parentIdMappedToIds.set(parentId, ids);
      }
    }

    return {
      id: filterId,
      type: filterType,
      options: [...parentIdMappedToIds].reduce((options, [parentId, ids]) => {
        return [
          ...options,
          ...ids.map(id => ({
            id,
            parentId,
            value: null, //NOTE: no need for selection but to comply with TS
            displayValue: null, //NOTE: no need for selection but to comply with TS
            isSelected: true,
          })),
        ];
      }, []),
    };
  }

  private getMultipleStringAbstractTreeNode(
    filter: ConsumableFilter,
    filterConfig: FilterConfig,
  ): AbstractTreeNodeUnion {
    const options = filter.options as BigidDropdownOption[];
    const { expressionValuePreprocessor, expressionFieldOperation = ExpressionFieldOperation.IN } = filterConfig;

    const valueExtracted = options.map(option => {
      return expressionValuePreprocessor ? expressionValuePreprocessor(option) : String(option.value);
    });

    const { operand, isNegation } = this.getAbstractTreeNodeOperand(filter, filterConfig, options[0]);

    const node = {
      name: operand,
      bigidName: operand,
      operation: expressionFieldOperation,
      operator: ExpressionOperation.UNDEFINED,
      type: ValueType.STRING,
      value: valueExtracted,
      isNegativeOperation: isNegation,
      isIgnored: false,
      isTagsNegativeOperation: false,
      arrayFieldName: null,
    } as AbstractTreeRegularNode;

    return parseAbstractQueryTreeFromNodes([node], ExpressionOperation.AND) as AbstractTreeNodeUnion;
  }

  private getMultipleStringConsumableFilter(
    nodes: AbstractTreeRegularNode[],
    filterConfig: FilterConfig,
  ): ConsumableFilter {
    const { filterId, filterType, filterIdComposer } = filterConfig;
    const ids = nodes.reduce((idsComputed, node) => {
      const values = node.value as string[];
      const ids = filterIdComposer ? values.map(value => filterIdComposer(value, null, node)) : values;

      return [...idsComputed, ...ids];
    }, []);

    return {
      id: filterId,
      type: filterType,
      options: ids.map(id => {
        return {
          id,
          value: null, //NOTE: no need for selection but to comply with TS
          displayValue: null, //NOTE: no need for selection but to comply with TS
          isSelected: true,
        };
      }),
    };
  }

  private getBooleanExactMatchAbstractTreeNode(
    filter: ConsumableFilter,
    filterConfig: FilterConfig,
  ): AbstractTreeNodeUnion {
    const options = filter.options as BigidDropdownOption[];
    const { expressionValuePreprocessor, expressionFieldOperation, expressionOperation } = filterConfig;

    const nodes = options.map(option => {
      const valueExtracted = this.getAbstractTreeNodeValue([option], expressionValuePreprocessor);
      const { operand, isNegation } = this.getAbstractTreeNodeOperand(filter, filterConfig, option);

      return {
        name: operand,
        bigidName: operand,
        operation: expressionFieldOperation ?? ExpressionFieldOperation.EQUAL,
        operator: expressionOperation ?? ExpressionOperation.UNDEFINED,
        type: ValueType.PRIMITIVE_BOOL,
        value: valueExtracted[0],
        isNegativeOperation: isNegation,
        isIgnored: false,
        isTagsNegativeOperation: false,
        arrayFieldName: null,
      };
    }) as AbstractTreeNodeUnion[];

    return parseAbstractQueryTreeFromNodes(
      nodes,
      expressionOperation ?? ExpressionOperation.AND,
    ) as AbstractTreeNodeUnion;
  }

  private getBooleanExactMatchConsumableFilter(
    nodes: AbstractTreeRegularNode[],
    filterConfig: FilterConfig,
  ): ConsumableFilter {
    const { filterId, filterType, filterIdComposer } = filterConfig;

    return {
      id: filterId,
      type: filterType,
      options: nodes
        .reduce((idsComputed, node) => {
          const value = node.value as string;
          const id = filterIdComposer ? filterIdComposer(value, null, node) : value;

          return [...idsComputed, id];
        }, [])
        .map(id => {
          return {
            id,
            value: null, //NOTE: no need for selection but to comply with TS
            displayValue: null, //NOTE: no need for selection but to comply with TS
            isSelected: true,
          };
        }),
    };
  }

  private getBooleanExistenceAbstractTreeNode(
    filter: ConsumableFilter,
    filterConfig: FilterConfig,
  ): AbstractTreeNodeUnion {
    const options = filter.options as BigidDropdownOption[];
    const { expressionOperation } = filterConfig;

    const nodes = options.map(option => {
      const { operand, isNegation } = this.getAbstractTreeNodeOperand(filter, filterConfig, option);

      return {
        name: operand,
        bigidName: operand,
        operator: ExpressionOperation.EXISTS,
        type: ValueType.PRIMITIVE_BOOL,
        value: undefined,
        isNegativeOperation: isNegation,
        isIgnored: false,
        isTagsNegativeOperation: false,
        arrayFieldName: null,
      };
    }) as AbstractTreeNodeUnion[];

    return parseAbstractQueryTreeFromNodes(
      nodes,
      expressionOperation ?? ExpressionOperation.AND,
    ) as AbstractTreeNodeUnion;
  }

  private getBooleanExistenceConsumableFilter(
    nodes: AbstractTreeExistenceNode[],
    filterConfig: FilterConfig,
  ): ConsumableFilter {
    const { filterId, filterType, filterIdComposer } = filterConfig;

    return {
      id: filterId,
      type: filterType,
      options: nodes
        .reduce((idsComputed, node) => {
          const value = node.value as string;
          const id = filterIdComposer ? filterIdComposer(value, null, node) : value;

          return [...idsComputed, id];
        }, [])
        .map(id => {
          return {
            id,
            value: null, //NOTE: no need for selection but to comply with TS
            displayValue: null, //NOTE: no need for selection but to comply with TS
            isSelected: true,
          };
        }),
    };
  }

  private getAbstractTreeNodesFromConsumableFilters(filter: ConsumableFilter[]): AbstractTreeNodeUnion[] {
    return filter.reduce((nodes, filterItem) => {
      if (!this.getIsFilterObjectValid(filterItem)) {
        return nodes;
      }

      const filterConfig = this.getFilterConfigByFilterId(String(filterItem.id));

      if (!filterConfig) {
        return nodes;
      }

      let abstractQueryNode;

      switch (filterConfig.expressionType) {
        case ExpressionType.STRING_MULTIPLE:
          abstractQueryNode = this.getMultipleStringAbstractTreeNode(filterItem, filterConfig);
          break;
        case ExpressionType.STRING_MULTIPLE_NESTED:
          abstractQueryNode = this.getMultipleNestedStringAbstractTreeNode(filterItem, filterConfig);
          break;
        case ExpressionType.BOOLEAN:
          abstractQueryNode = this.getBooleanExactMatchAbstractTreeNode(filterItem, filterConfig);
          break;
        case ExpressionType.BOOLEAN_EXISTS:
          abstractQueryNode = this.getBooleanExistenceAbstractTreeNode(filterItem, filterConfig);
          break;
        case ExpressionType.DATE:
          abstractQueryNode = this.getDateAbstractTreeNode(filterItem, filterConfig);
          break;
      }

      if (abstractQueryNode) {
        nodes.push(abstractQueryNode);
      }

      return nodes;
    }, []);
  }

  private getConsumableFiltersFromAbstractTreeNodes(nodesGrouped: AbstractTreeNodesGrouped): ConsumableFilter[] {
    const { regularConditions, existsConditions } = nodesGrouped ?? {};

    let consumableFilters: ConsumableFilter[] = [];

    if (regularConditions.length > 0) {
      const nodesGroupedByField = groupBy(regularConditions, 'name');

      consumableFilters = Object.keys(nodesGroupedByField).reduce((consumableFilters, field) => {
        const nodes = nodesGroupedByField[field];
        const filterConfig = this.getFilterConfigByNode(nodes[0]);

        if (!filterConfig) {
          return consumableFilters;
        }

        let consumableFilter: ConsumableFilter;

        switch (filterConfig.expressionType) {
          case ExpressionType.BOOLEAN:
            consumableFilter = this.getBooleanExactMatchConsumableFilter(nodes, filterConfig);
            break;
          case ExpressionType.STRING_MULTIPLE:
            consumableFilter = this.getMultipleStringConsumableFilter(nodes, filterConfig);
            break;
          case ExpressionType.STRING_MULTIPLE_NESTED:
            consumableFilter = this.getMultipleNestedStringConsumableFilter(nodes, filterConfig);
            break;
          case ExpressionType.DATE:
            consumableFilter = this.getDateConsumableFilter(nodes, filterConfig);
            break;
        }

        if (consumableFilter) {
          consumableFilters.push(consumableFilter);
        }

        return consumableFilters;
      }, []);
    }

    if (existsConditions.length > 0) {
      const nodesGroupedByField = groupBy(existsConditions, 'name');

      consumableFilters = Object.keys(nodesGroupedByField).reduce((consumableFilters, field) => {
        const nodes = nodesGroupedByField[field];
        const filterConfig = this.getFilterConfigByNode(nodes[0]);

        if (!filterConfig) {
          return consumableFilters;
        }

        let consumableFilter: ConsumableFilter;

        switch (filterConfig.expressionType) {
          case ExpressionType.BOOLEAN_EXISTS:
            consumableFilter = this.getBooleanExistenceConsumableFilter(nodes, filterConfig);
            break;
        }

        if (consumableFilter) {
          consumableFilters.push(consumableFilter);
        }

        return consumableFilters;
      }, []);
    }

    return consumableFilters;
  }

  public getConsumableFilter(filter: BigidAdvancedToolbarFilterUnion[]): ConsumableFilter[] {
    return advancedToolbarFilterUtils.getFilterOverrideValueBatch(filter);
  }

  public getIsBiqlValid(query: string): boolean {
    return getIsValidQueryString(query);
  }

  public parseConsumableFilterToBiql(filter: ConsumableFilter[]): string {
    try {
      const consumableQueryNodes = this.getAbstractTreeNodesFromConsumableFilters(filter);
      const abstractTree = parseExpressionsIntoAbstractQueryTree(consumableQueryNodes);

      return parseAbstractQueryTreeIntoString(abstractTree);
    } catch ({ message }) {
      this.throwException(message);

      return null;
    }
  }

  public parseOutputFilterToBiql(filter: BigidAdvancedToolbarFilterUnion[]): string {
    try {
      const consumableFilter = this.getConsumableFilter(filter);
      const consumableQueryNodes = this.getAbstractTreeNodesFromConsumableFilters(consumableFilter);
      const abstractTree = parseExpressionsIntoAbstractQueryTree(consumableQueryNodes);

      return parseAbstractQueryTreeIntoString(abstractTree);
    } catch ({ message }) {
      this.throwException(message);

      return null;
    }
  }

  public parseBiqlToFilter(query: string): ConsumableFilter[] {
    try {
      const nodes = getAllAbstractQueryTreeConditions(query);
      return this.getConsumableFiltersFromAbstractTreeNodes(nodes);
    } catch ({ message }) {
      this.throwException(message);

      return null;
    }
  }
}
