import { useEffect, useMemo, useState } from 'react';
import { getEventEmitter } from './toolbarEvents';
import { uniqueId } from 'lodash';
import {
  type BigidAdvancedToolbarFilterUnion,
  type AdvancedToolbarOverrideValue,
  advancedToolbarEventEmitter,
} from '@bigid-ui/components';

export enum DashboardToolbarEvent {
  UPDATE_FILTERS = 'updateViews',
  CLEAR_FILTERS = 'clearFilters',
  UPDATE_EXTERNAL_FILTERS = 'updateExternalFilters',
  UPDATE_ACTIVE_WIDGET_IDS = 'updateActiveWidgetIds',
  UPDATE_IS_SAVED_VIEWS_READY = 'updateIsSavedViewsReady',
}

export interface DashboardToolbarActions {
  updateFilters: (filters: BigidAdvancedToolbarFilterUnion[]) => void;
  updateExternalFilters: (filters: AdvancedToolbarOverrideValue[]) => void;
  clearFilters: () => void;
  updateActiveWidgetIds: (widgetIds: string[], isSelectAll?: boolean) => void;
  updateIsSavedViewsReady: (newStatus: boolean) => void;
  updateIsOverviewTabSelected: (isOverviewTab: boolean) => void;
}

const emitter = getEventEmitter();

export const useDashboardToolbar = (
  widgetIds: string[],
  filters: BigidAdvancedToolbarFilterUnion[] = [],
  id?: string,
  isSavedViewsEnabled?: boolean,
) => {
  const [toolbarId] = useState(id ?? uniqueId());
  const [externalAppliedFilters, setExternalAppliedFilters] = useState<AdvancedToolbarOverrideValue[]>([]);
  const [activeFilters, setActiveFilters] = useState<BigidAdvancedToolbarFilterUnion[]>(filters);
  const [activeWidgetIds, setActiveWidgetIds] = useState(widgetIds);
  const [isSavedViewsReady, setIsSavedViewsReady] = useState(!isSavedViewsEnabled);
  const [isOverviewTab, setIsOverviewTab] = useState<boolean>(false);

  const toolbarActions = useMemo(
    () => ({
      updateFilters: (filters: BigidAdvancedToolbarFilterUnion[]) =>
        emitter.emit(DashboardToolbarEvent.UPDATE_FILTERS, { filters, entityId: toolbarId }),
      updateExternalFilters: (filters: AdvancedToolbarOverrideValue[]) =>
        emitter.emit(DashboardToolbarEvent.UPDATE_EXTERNAL_FILTERS, { filters, entityId: toolbarId }),
      clearFilters: () =>
        emitter.emit(DashboardToolbarEvent.CLEAR_FILTERS, {
          entityId: toolbarId,
        }),
      updateActiveWidgetIds: (widgetIds: string[], isSelectAll?: boolean) =>
        emitter.emit(DashboardToolbarEvent.UPDATE_ACTIVE_WIDGET_IDS, { widgetIds, isSelectAll, entityId: toolbarId }),
      updateIsSavedViewsReady: (newStatus: boolean) => {
        emitter.emit(DashboardToolbarEvent.UPDATE_IS_SAVED_VIEWS_READY, { newStatus, entityId: toolbarId });
      },
      updateIsOverviewTabSelected: setIsOverviewTab,
    }),
    [toolbarId],
  );

  const handleFilterUpdate = (filters: BigidAdvancedToolbarFilterUnion[]) => {
    setActiveFilters(filters);
  };

  const handleActiveWidgetsUpdate = (updatedWidgetIds: string[], selectAll?: boolean) => {
    if (selectAll) {
      setActiveWidgetIds(widgetIds);
    } else if (updatedWidgetIds) {
      setActiveWidgetIds(updatedWidgetIds);
    }
  };

  const updateExternalFilters = (filters: AdvancedToolbarOverrideValue[]) => {
    // Info - Achieve the expected behavior that click on the selected tab will always force the filter update
    setExternalAppliedFilters([...filters]);
    if (!filters?.length) {
      toolbarActions.clearFilters();
      // Without this DATE_RANGE filter doesn't get reset. Looks like a bug in the bigid-ui component, which needs a fix.
      advancedToolbarEventEmitter.emit('AdvancedToolbar::RESET_FILTERS');
    }
  };

  const updateIsSavedViewsReady = (newStatus: boolean) => {
    setIsSavedViewsReady(newStatus);
  };

  useEffect(() => {
    const unregisterActions = [
      emitter.addEventListener(
        DashboardToolbarEvent.UPDATE_FILTERS,
        ({ filters, entityId }) => entityId === toolbarId && handleFilterUpdate(filters),
      ),
      emitter.addEventListener(
        DashboardToolbarEvent.UPDATE_EXTERNAL_FILTERS,
        ({ filters, entityId }) => entityId === toolbarId && updateExternalFilters(filters),
      ),
      emitter.addEventListener(
        DashboardToolbarEvent.CLEAR_FILTERS,
        ({ entityId }) => entityId === toolbarId && handleFilterUpdate([]),
      ),
      emitter.addEventListener(
        DashboardToolbarEvent.UPDATE_ACTIVE_WIDGET_IDS,
        ({ widgetIds, isSelectAll, entityId }) =>
          entityId === toolbarId && handleActiveWidgetsUpdate(widgetIds, isSelectAll),
      ),
      emitter.addEventListener(
        DashboardToolbarEvent.UPDATE_IS_SAVED_VIEWS_READY,
        ({ newStatus, entityId }) => entityId === toolbarId && updateIsSavedViewsReady(newStatus),
      ),
    ];
    return () => unregisterActions.forEach(unregister => unregister());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarId, filters]);

  return {
    filters,
    externalAppliedFilters,
    id: toolbarId,
    toolbarActions,
    activeFilters,
    activeWidgetIds,
    isSavedViewsReady,
    isOverviewTab,
  };
};
