import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDialog,
  BigidTagWizard,
  BigidTagBaseProps,
  BigidTagWizardDictionary,
  getTagsDictionary,
  BigidBody1,
  BigidTagsValidationPayload,
  BigidColorsV2,
} from '@bigid-ui/components';
import { TagEntity, getTagsAllPairs } from '../../../TagsManagement/TagsManagementService';
import { validateTag } from '../../../TagsManagement/TagsManagementUtils';
import { FormLabel, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../../../services/notificationService';
import { TAGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { attachTagsBulk, createAndAttachTagsBulk } from '../../DataCatalogService';
import { TagBulkAssignmentPayloadData } from '../../DataCatalogAsyncOps/operations/TagBulkAssignment';
import { useLocalTranslation } from '../translations';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  label: {
    width: '20%',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
  },
  wizard: {
    width: '80%',
  },
  error: {
    color: `${BigidColorsV2.red[600]} !important`,
    fontSize: '0.75rem',
    height: '16px',
    whiteSpace: 'nowrap',
  },
});

export interface AssignTagDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (tagsToAssign: TagBulkAssignmentPayloadData) => void;
}

export const AssignTagDialog: FC<AssignTagDialogProps> = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useLocalTranslation();
  const classes = useStyles({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [systemTags, setSystemTags] = useState<TagEntity[]>([]);
  const [dictionary, setDictionary] = useState<BigidTagWizardDictionary>();
  const [createdTag, setCreatedTag] = useState<BigidTagBaseProps>();
  const [attachedTag, setAttachedTag] = useState<BigidTagBaseProps>();
  const { isCreateTagsPermitted, isReadTagsPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
    }),
    [],
  );

  const submitCreatedTags = useCallback(async () => {
    try {
      return (await createAndAttachTagsBulk(systemTags, [createdTag], undefined, true)) as TagBulkAssignmentPayloadData;
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    }
  }, [createdTag, systemTags]);

  const submitAttachedTags = useCallback(async () => {
    try {
      return (await attachTagsBulk(systemTags, [attachedTag], undefined, true)) as TagBulkAssignmentPayloadData;
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    }
  }, [attachedTag, systemTags]);

  const submitChanges = useCallback(async () => {
    try {
      if (validationMessage) {
        setIsInvalid(true);
      } else {
        setIsLoading(true);
        let tagsToAssign;
        if (createdTag) {
          tagsToAssign = await submitCreatedTags();
        } else if (attachedTag) {
          tagsToAssign = await submitAttachedTags();
        }
        onSubmit(tagsToAssign);
        resetState();
      }
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    } finally {
      setIsLoading(false);
    }
  }, [attachedTag, createdTag, submitAttachedTags, submitCreatedTags]);

  const fetchTags = useCallback(async () => {
    try {
      setIsLoading(true);

      const tags = await getTagsAllPairs('', true);
      const dictionary = getTagsDictionary(tags.map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue })));

      setSystemTags(tags);
      setDictionary(dictionary);
    } catch (error) {
      notificationService.error(t('commonError'));
      console.error(`${t('commonError')}: ${error}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleTagCreate = useCallback((tag: BigidTagBaseProps): void => {
    setCreatedTag(tag);
    setAttachedTag(undefined);
  }, []);

  const handleTagAttach = useCallback((tag: BigidTagBaseProps): void => {
    setAttachedTag(tag);
    setCreatedTag(undefined);
  }, []);

  const resetState = () => {
    setIsInvalid(false);
    setValidationMessage(undefined);
    setSystemTags([]);
    setDictionary(undefined);
    setCreatedTag(undefined);
    setAttachedTag(undefined);
  };

  const handleOnDialogSave = () => {
    submitChanges();
  };

  const handleOnDialogClose = () => {
    resetState();
    onClose();
  };

  const handleOnMenuClose = (message: string) => {
    setValidationMessage(message);
  };

  const validateTagName = ({ tagName }: BigidTagsValidationPayload): string => {
    const { isTagNameValueValid } = validateTag;

    if (!isTagNameValueValid(tagName)) {
      return t('assignTagDialog.errors.invalidName');
    }

    return;
  };

  const validateTagValue = ({ tagName, tagValue }: BigidTagsValidationPayload): string => {
    const { isTagNameValueValid, isTagValueNotEqualsToTagName } = validateTag;

    if (!isTagNameValueValid(tagValue)) {
      return t('assignTagDialog.errors.invalidValue');
    }

    if (!isTagValueNotEqualsToTagName(tagValue, tagName)) {
      return t('assignTagDialog.errors.nameValueNotEqual');
    }

    return;
  };

  useEffect(() => {
    if (isOpen) {
      isReadTagsPermitted && fetchTags();
    }
  }, [isOpen, fetchTags, isReadTagsPermitted]);

  const showValidationMessage = isInvalid && validationMessage;

  return (
    <BigidDialog
      title={t('assignTagDialog.title')}
      isOpen={isOpen}
      onClose={handleOnDialogClose}
      borderTop
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnDialogClose,
          text: t('assignTagDialog.buttons.cancel'),
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogSave,
          text: t('assignTagDialog.buttons.assign'),
          disabled: !(Boolean(createdTag) || Boolean(attachedTag)),
        },
      ]}
      isLoading={isLoading}
      maxWidth="sm"
    >
      <div className={classes.wrapper}>
        <FormLabel
          id="Data-catalog-similar-tables-tag-assignment-label"
          className={classes.label}
          component={BigidBody1}
        >
          Tag
        </FormLabel>
        <div className={classes.wizard}>
          <BigidTagWizard
            dataAid="Data-catalog-similar-tables-tag-assignment-wizard"
            tagsDictionary={dictionary}
            onSelect={handleTagAttach}
            onCreate={handleTagCreate}
            isAbleToCreate={isCreateTagsPermitted}
            onMenuClose={handleOnMenuClose}
            validateName={validateTagName}
            validateValue={validateTagValue}
            isOpenByDefault={false}
            tagWizardMenuPosition="fixed"
            isStandalone
          />
          {showValidationMessage && (
            <FormHelperText error margin="dense" classes={{ error: classes.error }}>
              {validationMessage}
            </FormHelperText>
          )}
        </div>
      </div>
    </BigidDialog>
  );
};
