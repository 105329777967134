import React, { FC } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { AttributesGridRecord } from './AttributesLayout';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { useLocalTranslation } from './translations';
import { isNil } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

interface AttributesHotspotTooltipContentProps {
  dataAid?: string;
  row: AttributesGridRecord;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    fontSize: '14px',
  },
  properties: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  property: {
    display: 'flex',
    alignItems: 'center',
  },
  propertyName: {
    marginRight: '4px',
  },
  propertyValue: {
    fontWeight: 'bold',
  },
});

export const AttributesTreeMapTooltipContent: FC<AttributesHotspotTooltipContentProps> = ({
  dataAid = 'AttributesTreeMapTooltipContent',
  row,
}) => {
  const classes = useStyles({});
  const { t } = useLocalTranslation('hotspot');
  const { id, aggItemName, friendlyName, docCount, findings, categories = [] } = row;

  const isAttributeItem = row.hasOwnProperty('categories');

  return (
    <div className={classes.root} data-aid={generateDataAid(dataAid, [id])}>
      <div className={classes.properties}>
        {aggItemName && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'name'])}
            >
              {t('fields.name')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'value'])}
            >
              {aggItemName}
            </div>
          </div>
        )}
        {friendlyName && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'friendlyName', 'name'])}
            >
              {t('fields.friendlyName')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'friendlyName', 'value'])}
            >
              {friendlyName}
            </div>
          </div>
        )}
        {!isNil(docCount) && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'name'])}
            >
              {t('fields.numOfObjects')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'value'])}
            >
              {formatNumberCompact(docCount)}
            </div>
          </div>
        )}
        {!isNil(findings) && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'name'])}
            >
              {t('fields.numOfFindings')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'value'])}
            >
              {formatNumberCompact(findings)}
            </div>
          </div>
        )}
        {isAttributeItem && (
          <div className={classes.property}>
            <div
              className={classes.propertyName}
              data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'name'])}
            >
              {t('fields.categories')}
            </div>
            <div
              className={classes.propertyValue}
              data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'value'])}
            >
              {categories?.length > 0 ? categories.join(', ') : t('placeholders.noCategories')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
