import { RangeSelectionOptions } from '@bigid-ui/components';
import { DateRangeOption, FilterField } from './consts';

export const ONE_DAY_MS = 1000 * 3600 * 24;
const DAY_INTERVAL = 1;
const WEEK_INTERVAL = 7;
const MONTH_INTERVAL = 1;

enum INTERVAL_TYPES {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum BREAKDOWN_TYPE {
  CUSTOM = 'custom',
  DATE_RANGE = 'dateRange',
}

type BreakdownFunction = (from: Date, until: Date) => string;

const getDateUTCZeroHour = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const getDatesBreakdownForCustomDateRange = (startDate: Date, endDate: Date, intervalType: INTERVAL_TYPES): string => {
  const timestamps = getTimestampsForCustomDateRange(startDate, endDate, intervalType);
  if (!timestamps?.length) {
    return '';
  } else {
    const formattedDates = timestamps.map(timestamp => `"${timestamp.toISOString()}"`).join(', ');
    return `${FilterField.TIMESTAMP} IN (${formattedDates})`;
  }
};

const getTimestampsForCustomDateRange = (startDate: Date, endDate: Date, intervalType: INTERVAL_TYPES) => {
  const result: Date[] = [];

  const calcDate = getDateUTCZeroHour(new Date(startDate));
  const endUTC = getDateUTCZeroHour(new Date(endDate));

  while (calcDate <= endUTC) {
    result.push(new Date(calcDate));
    switch (intervalType) {
      case INTERVAL_TYPES.DAY:
        calcDate.setUTCDate(calcDate.getUTCDate() + DAY_INTERVAL);
        break;
      case INTERVAL_TYPES.WEEK:
        calcDate.setUTCDate(calcDate.getUTCDate() + WEEK_INTERVAL);
        break;
      case INTERVAL_TYPES.MONTH:
        calcDate.setUTCMonth(calcDate.getUTCMonth() + MONTH_INTERVAL);
        break;
    }
  }
  return result;
};

const getDays: BreakdownFunction = (startDate, endDate) =>
  getDatesBreakdownForCustomDateRange(startDate, endDate, INTERVAL_TYPES.DAY);

const getWeeks: BreakdownFunction = (startDate, endDate) =>
  getDatesBreakdownForCustomDateRange(startDate, endDate, INTERVAL_TYPES.WEEK);

const getMonths: BreakdownFunction = (startDate, endDate) =>
  getDatesBreakdownForCustomDateRange(startDate, endDate, INTERVAL_TYPES.MONTH);

const timeRangeRules: Map<(rangeInDays: number) => boolean, BreakdownFunction> = new Map([
  [(rangeInDays: number) => rangeInDays <= 31, getDays],
  [(rangeInDays: number) => rangeInDays <= 183, getWeeks],
  [(rangeInDays: number) => rangeInDays > 183, getMonths],
]);

export const getCustomDateBreakdown = (from: Date, until: Date): string => {
  validateDatesArray([from, until]);
  const fromUTC = getDateUTCZeroHour(from);
  const untilUTC = getDateUTCZeroHour(until);
  const rangeInDays = (untilUTC.getTime() - fromUTC.getTime()) / ONE_DAY_MS;

  for (const [rule, breakdownFunc] of timeRangeRules) {
    if (rule(rangeInDays)) {
      return breakdownFunc(fromUTC, untilUTC);
    }
  }

  return '';
};

const getMonthAgoNumOfDaysIncludingToday = (now: Date): number => {
  const lastMonthDate = new Date(now);
  lastMonthDate.setUTCMonth(lastMonthDate.getUTCMonth() - 1);
  return Math.floor((new Date(now).getTime() - lastMonthDate.getTime()) / ONE_DAY_MS) + 1;
};

export const getPastDateBreakdown = (
  optionSelected: RangeSelectionOptions = DateRangeOption.NONE,
  { from, until }: { from: Date; until: Date },
): string => {
  switch (optionSelected) {
    case DateRangeOption.LAST_WEEK:
    case DateRangeOption.NONE:
      return getTimestampPastString('8d');
    case DateRangeOption.LAST_MONTH:
      const daysAgo = getMonthAgoNumOfDaysIncludingToday(until);
      return getTimestampPastString(`${daysAgo}d`);
    case DateRangeOption.LAST_YEAR:
      return getCustomDateBreakdown(from, until);
  }
  throw new Error(`Unsupported option for date range ${optionSelected}`);
};

export const getTimestampPastString = (dateAlias: string) => {
  return `${FilterField.TIMESTAMP} > ${getPastXRelativeDate(dateAlias)}`;
};

const getPastXRelativeDate = (relativeDateKey: string) => {
  return `past(${relativeDateKey})`;
};

const validateDatesArray = (dateArr: Date[]) => {
  dateArr.forEach((date, _) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      const errMsg = `Invalid date ${date}`;
      console.log(errMsg);
      throw new Error(errMsg);
    }
  });
};
