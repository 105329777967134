import {
  BigidAdministrationIcon,
  BigidAdvanceSearchIcon,
  BigidCookieIcon,
  BigidDashboardIcon,
  BigidDataRetentionIcon,
  BigidDataSourceOnlyIcon,
  BigidEditIcon,
  BigidGlobeIcon,
  BigidGroupIcon,
  BigidGuidedTourIcon,
  BigidInventoryIcon,
  BigidPiaIcon,
  BigidPrivacyPortalIcon,
  BigidRemediationIcon,
  BigidReportsIllustration,
  BigidRopaIcon,
  BigidTableColumnsIcon,
  BigidWorkspaceIcon,
  BigidDefaultIcon,
  BigidHistoryIcon,
  BigidDatasetIcon,
  BigidTopicIcon,
  BigidAppLogoAlationIcon,
  BigidAppLogoEdcInformaticaIcon,
  BigidAppLogoMicrosoftPurviewIcon,
  BigidAppLogoSnowflakeIcon,
  Databricks,
  Servicenow,
  BigidListIcon,
  BigidToggleIcon,
  BigidAppLogoCollibraIcon,
} from '@bigid-ui/icons';
import { CONFIG } from '../../config/common';
import { CustomApps } from '../views/ApplicationsManagement/applicationManagementService';
import { BigidSidebarItemType } from '../components/NavigationSideBar/navigationSidebarUtils';
import { WorkspaceId } from './workspacesUtils';

const iconLabelToReactComponent: Record<string, any> = {
  PrivacyPortalAppIcon: BigidPrivacyPortalIcon,
  BigidDashboardIcon: BigidDashboardIcon,
  BigidTableColumnsIcon: BigidTableColumnsIcon,
  BigidInventoryIcon: BigidInventoryIcon,
  BigidCookieIcon: BigidCookieIcon,
  BigidEditIcon: BigidEditIcon,
  BigidDataSourceOnlyIcon: BigidDataSourceOnlyIcon,
  BigidAdministrationIcon: BigidAdministrationIcon,
  BigidAdvanceSearchIcon: BigidAdvanceSearchIcon,
  BigidGroupIcon: BigidGroupIcon,
  BigidGuidedTourIcon: BigidGuidedTourIcon,
  BigidWorkspaceIcon: BigidWorkspaceIcon,
  BigidGlobeIcon: BigidGlobeIcon,
  RopaAppIcon: BigidRopaIcon,
  PiaAppIcon: BigidPiaIcon,
  RetentionAppIcon: BigidDataRetentionIcon,
  RemediationAppIcon: BigidRemediationIcon,
  BigidHistoryIcon: BigidHistoryIcon,
  BigidDatasetIcon: BigidDatasetIcon,
  BigidTopicIcon: BigidTopicIcon,
  BigidAppLogoAlationIcon: BigidAppLogoAlationIcon,
  BigidAppLogoEdcInformaticaIcon: BigidAppLogoEdcInformaticaIcon,
  BigidAppLogoMicrosoftPurviewIcon: BigidAppLogoMicrosoftPurviewIcon,
  BigidAppLogoSnowflakeIcon: BigidAppLogoSnowflakeIcon,
  DatabricksIcon: Databricks,
  ServicenowIcon: Servicenow,
  BigidListIcon: BigidListIcon,
  BigidToggleIcon: BigidToggleIcon,
  BigidAppLogoCollibraIcon: BigidAppLogoCollibraIcon,
};

export const getLinkForCustomApp = (appId: string, linkInsideApp: string): string => {
  if (!linkInsideApp || !linkInsideApp.startsWith('/')) return linkInsideApp;
  if (linkInsideApp === '/') return `${CONFIG.states.CUSTOM_APP}/${appId}`;
  return `${CONFIG.states.CUSTOM_APP_DEEP_LINKING}/${appId}${linkInsideApp}`;
};

export const getSidebarItemsFromCustomApp = (
  customApp: CustomApps,
  onNavigationItemClicked: (link: string) => void,
): BigidSidebarItemType[] =>
  (customApp.workspaces_details?.sidebar_items || []).map(menuItem => ({
    isVisible: true,
    icon: iconLabelToReactComponent[menuItem.icon] || BigidDefaultIcon,
    title: menuItem.label,
    tooltipText: menuItem.label,
    workspaces: menuItem.workspaces as WorkspaceId[],
    order: menuItem.order,
    name: menuItem.name,
    onItemClicked: onNavigationItemClicked,
    link: getLinkForCustomApp(customApp._id, menuItem.path),
    isQuickView: menuItem.subItems?.length > 0,
    selectedItemStates: [
      getLinkForCustomApp(customApp._id, menuItem.path),
      ...(menuItem.extraHighlightedPaths || []).map(path => getLinkForCustomApp(customApp._id, path)),
      ...(menuItem.subItems || []).map(subItem => getLinkForCustomApp(customApp._id, subItem.path)),
      ...(menuItem.subItems || []).flatMap(
        subItem => subItem.extraHighlightedPaths?.map(path => getLinkForCustomApp(customApp._id, path)) || [],
      ),
    ],
    quickViewSchema: menuItem.subItems
      ? {
          onMasterPageLinkClicked: onNavigationItemClicked,
          emptyStateIcon: BigidReportsIllustration,
          emptyStateText: 'Add links to the app manifest',
          onItemClicked: onNavigationItemClicked,
          menuItems: menuItem.subItems.map(subItem => ({
            isVisible: true,
            workspaces: subItem.workspaces as WorkspaceId[],
            title: subItem.label,
            order: subItem.order,
            link: getLinkForCustomApp(customApp._id, subItem.path),
            icon: iconLabelToReactComponent[subItem.icon] || subItem.icon,
            selectedItemStates: [
              getLinkForCustomApp(customApp._id, subItem.path),
              ...(subItem.extraHighlightedPaths?.map(path => getLinkForCustomApp(customApp._id, path)) || []),
            ],
          })),
        }
      : undefined,
  }));
