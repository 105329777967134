import React from 'react';
import { DefaultStepProps } from '../../../types/CorrelationSetsTypes';
import { AttributesGrid } from '../../../components/AttributesGrid/AttributesGrid';

//STEP 2
export const PreviewStep = ({ detailsStepFormControls, activeStepId }: DefaultStepProps) => {
  return (
    <AttributesGrid
      activeStepId={activeStepId}
      detailsStepFormControls={detailsStepFormControls}
      isEditableGridColumns={true}
    />
  );
};
