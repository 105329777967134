import React, { FC, useEffect, useState } from 'react';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidHeading1,
  BigidLink,
  BigidLoader,
  BigidNotes1,
} from '@bigid-ui/components';
import { BigidChevronRightIcon } from '@bigid-ui/icons';
import {
  isMtMigrationInProgress,
  MtMigrationEmptyStates,
  MtNotSupportedViews,
  startMtMigrationForFeature,
  isMtFeatureFlagEnabledInConfigService,
  FeatureFlagStatus,
} from './mtMigrationEmptyStateUtils';
import styled from '@emotion/styled';
import { useLocalTranslation } from './translations';
import { generateDataAid } from '@bigid-ui/utils';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { analyticsService } from '../../services/analyticsService';
import { notificationService } from '../../services/notificationService';
import { initApplicationPreferences } from '../../services/appPreferencesService';

const HEADER_HEIGHT = 121;

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
  justifyContent: 'center',
  width: '100%',
});

const ViewContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const TextBox = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: 460,
  marginRight: 256,
  flexDirection: 'column',
});

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 16,
});

const PageDescription = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 25,
});

const AddButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 4,
});

const LoaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  paddingBottom: 1,
});

const StartMigrationButton = styled(BigidButtonIcon)({
  marginLeft: '4px',
});

export interface MtMigrationEmptyStateProps {
  id: MtNotSupportedViews;
}

export const trackEventMigration = (eventType: string) => {
  analyticsService.trackManualEvent(eventType);
};

const camelToUpperSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, letter => `_${letter}`).toUpperCase();
};

export const MtMigrationEmptyState: FC<MtMigrationEmptyStateProps> = ({ id }) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useLocalTranslation();

  const setInProgressClick = async () => {
    trackEventMigration(`${camelToUpperSnakeCase(id)}_CLICK_MIGRATION_REQUEST_BUTTON`);
    const isMigrationStarted = await startMtMigrationForFeature(id);
    if (isMigrationStarted) {
      setInProgress(true);
    }
  };

  const sendBiEventForLearnMoreClick = async () => {
    trackEventMigration(`${camelToUpperSnakeCase(id)}_CLICK_EMPTY_STATE_LEARN_MORE`);
  };

  useEffect(() => {
    const checkFeatureProgress = async () => {
      try {
        const isInProgress = await isMtMigrationInProgress(id);
        setInProgress(isInProgress);
        setIsLoading(false);
      } catch (e) {
        const isMtEnabled = await isMtFeatureFlagEnabledInConfigService();
        if (isMtEnabled === FeatureFlagStatus.DISABLED) {
          await initApplicationPreferences();
          window.location.reload();
        } else {
          if (isMtEnabled === FeatureFlagStatus.ENABLED) {
            notificationService.error(t('Common.errorMessages.featureState', { message: e?.message || ' ' }));
          }
          setIsLoading(false);
        }
      }
    };

    document.title = 'BigID';
    pageHeaderService.setIsHidden(true);
    checkFeatureProgress();
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, [id, t]);

  const currentFeature =
    MtMigrationEmptyStates.find(x => x.id === id) ||
    MtMigrationEmptyStates.find(x => x.id === MtNotSupportedViews.DEFAULT);

  return (
    <Root>
      {isLoading ? (
        <BigidLoader zIndex={1} />
      ) : (
        <ViewContainer>
          <TextBox>
            <Title>
              <BigidHeading1 data-aid={generateDataAid('MtMigrationEmptyState', ['title'])}>
                {currentFeature.title}
              </BigidHeading1>
            </Title>
            <PageDescription>
              <BigidBody1>
                {currentFeature.content}
                {currentFeature.id !== MtNotSupportedViews.DEFAULT && (
                  <BigidLink
                    href={currentFeature.buttonLink}
                    shouldOpenNewTab
                    text={t('General.learnMore')}
                    underline={'none'}
                    onClick={sendBiEventForLearnMoreClick}
                  />
                )}
                {'.'}
              </BigidBody1>
            </PageDescription>
            {inProgress ? (
              <AddButton>
                <LoaderContainer>
                  <BigidLoader size={15} thickness={2} position={'static'} zIndex={-1} />
                  <BigidBody1>{`${t('General.inProgress')}`}</BigidBody1>
                </LoaderContainer>
              </AddButton>
            ) : (
              currentFeature.migrationButtonText && (
                <AddButton>
                  <BigidBody1>{currentFeature.migrationButtonText}</BigidBody1>
                  <StartMigrationButton
                    icon={BigidChevronRightIcon}
                    size="small"
                    onClick={() => setInProgressClick()}
                    dataAid={generateDataAid('MtMigrationEmptyState', ['start-migration', 'button'])}
                  />
                </AddButton>
              )
            )}
            {currentFeature.id !== MtNotSupportedViews.DEFAULT && (
              <BigidNotes1 color={{ color: BigidColorsV2.gray[600] }}>{`${t(
                'General.migrationDescription',
              )}`}</BigidNotes1>
            )}
          </TextBox>
          {<currentFeature.illustration size="large" />}
        </ViewContainer>
      )}
    </Root>
  );
};
