import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DataSourceModel } from '../../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { getSupportedDataSources } from '../../../../../utilities/dataSourcesUtils';
import { getCellValueForConnectionStatus } from '../../../../DSAR/SarProfileSettings/gridCellValues';
import {
  getAutoDiscoveryPresetsOptions,
  getTags,
  OwnersCellValueMemorized,
  shouldShowOwners,
  shouldShowTags,
  TagsCellValueMemorized,
} from '../../../../../views/DataSources/DataSourceConnections/DataSourceConnectionGridConfiguration';
import { DiscoveryAppTypeToDisplayName } from '../../../../../views/DataSources/DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { BigidFilterType } from '@bigid-ui/components';
import { getTagFormattedName } from '../../../../../views/TagsManagement/TagsManagementUtils';
import { TagEntity } from '../../../../../views/TagsManagement/TagsManagementService';
import { getItOwnersOptions } from '../../../../../views/DataSources/DataSourcesService';

export const getSelectDataSourceGridColumns = (): BigidGridColumn<DataSourceModel>[] => [
  {
    name: 'name',
    title: 'Name',
    sortingEnabled: true,
    getCellValue: row => row.name,
    type: BigidGridColumnTypes.TEXT,
    width: 350,
  },
  {
    name: 'type',
    title: 'Type',
    sortingEnabled: true,
    getCellValue: row => row.type,
    type: BigidGridColumnTypes.TEXT,
    width: 350,
  },
  {
    name: 'connectionStatusScan',
    title: 'Last Scan',
    sortingEnabled: true,
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusScan }) => getCellValueForConnectionStatus(connectionStatusScan),
  },
  {
    title: 'Last Test',
    name: 'connectionStatusTest',
    sortingEnabled: false,
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusTest }) => getCellValueForConnectionStatus(connectionStatusTest),
  },
  ...(shouldShowOwners()
    ? [
        {
          title: 'IT Data Owners',
          name: 'owners_v2',
          sortingEnabled: false,
          width: 300,
          type: BigidGridColumnTypes.CUSTOM,
          getCellValue: row => {
            return <OwnersCellValueMemorized {...row} />;
          },
        } as BigidGridColumn<DataSourceModel>,
      ]
    : []),
  ...(shouldShowTags()
    ? [
        {
          title: 'Tags',
          name: 'tags',
          width: 300,
          sortingEnabled: false,
          type: BigidGridColumnTypes.CUSTOM,
          getCellValue: ({ tags, id }) => {
            if (tags.length > 0) {
              return <TagsCellValueMemorized id={id} tags={tags} />;
            } else {
              return <></>;
            }
          },
        } as BigidGridColumn<DataSourceModel>,
      ]
    : []),
  {
    title: 'Auto Discovery Type',
    name: 'discoveryAppType',
    sortingEnabled: false,
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ isFromDiscoveryApp, discoveryAppType }) =>
      isFromDiscoveryApp
        ? DiscoveryAppTypeToDisplayName[discoveryAppType as keyof typeof DiscoveryAppTypeToDisplayName] ??
          discoveryAppType
        : '',
  },
];

export async function getSelectDataSourceFilters() {
  const dataSourceTypes = await getSupportedDataSources();
  const [tagsAllPairs, autoDiscoveryPresetsOptions, initialItOwnersOptions] = await Promise.all([
    getTags(),
    getAutoDiscoveryPresetsOptions(),
    getItOwnersOptions(),
  ]);
  const filterToolbarConfig: BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig'] = {
    filters: [
      {
        title: 'Type',
        field: 'type',
        operator: 'in',
        options: dataSourceTypes.map(({ name, displayName }) => ({
          label: displayName || name,
          value: name.toLowerCase(),
          isSelected: false,
        })),
        value: [],
        displayLimit: 500,
      },
      {
        title: 'Last Test',
        field: 'connectionStatusTest.is_success',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Success',
            value: true,
            isSelected: false,
          },
          {
            label: 'Failed',
            value: false,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Last Scan',
        field: 'connectionStatusScan.is_success',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Success',
            value: true,
            isSelected: false,
          },
          {
            label: 'Failed',
            value: false,
            isSelected: false,
          },
        ],
      },
      ...(shouldShowTags()
        ? [
            {
              title: 'Tags',
              field: 'tags',
              operator: 'in',
              disabled: true,
              options: tagsAllPairs.map(({ tagName, tagValue, tagId, valueId, properties }: TagEntity) => ({
                label: `${properties?.displayName ?? getTagFormattedName(tagName)} : ${tagValue}`,
                value: JSON.stringify({ valueId, tagId }),
                isSelected: false,
              })),
              value: [],
            } as BigidFilterType,
          ]
        : []),
      {
        title: 'Auto Discovery Type',
        field: 'discoveryAppType',
        operator: 'in',
        disabled: true,
        options: (Object.keys(DiscoveryAppTypeToDisplayName) as (keyof typeof DiscoveryAppTypeToDisplayName)[]).map(
          discoveryAppType => ({
            label: DiscoveryAppTypeToDisplayName[discoveryAppType],
            value: discoveryAppType,
            isSelected: false,
          }),
        ),
        value: [],
      },
      {
        title: 'Auto Discovery Configuration',
        field: 'discoveryAppPresetId',
        operator: 'in',
        disabled: true,
        options: autoDiscoveryPresetsOptions,
        value: [],
      },
      ...(shouldShowOwners()
        ? [
            {
              title: 'Data Owner',
              field: 'owners_v2.id',
              operator: 'in',
              isSearchAsync: true,
              disabled: true,
              loadSearchOptions: getItOwnersOptions,
              options: initialItOwnersOptions,
              value: [],
            } as BigidFilterType,
          ]
        : []),
    ],
    searchConfig: {
      searchFilterKeys: ['name', 'friendly_name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };

  return filterToolbarConfig;
}
