import React, { useCallback, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { BigidBody1, BigidLink, BigidWidgetContainer } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { Content, DescriptionWrapper } from '../WidgetsStyles';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { Carousel, CarouselDataProps } from '../../../../../components/Carousel/Carousel';
import { CarouselItemsList } from './CarouselItemsList';
import { fetchWidgetData, WidgetName, WidgetSubtype } from '../../../services/dashboardService';
import { activeFiltersParser } from '../../utils';
import { useFiltersActions } from '../../hooks/useFiltersActions';
import { useWidgetEmptyState } from '../../hooks/useWidgetEmptyState';
import { ComplianceDashboardWidget } from '../../ComplianceDashboard';

interface FailedControlsWidgetProps {
  handleLoader: (value: boolean) => void;
  dataAid: string;
}

export const FailedControlsWidget = ({ dataAid, handleLoader }: FailedControlsWidgetProps) => {
  const [failedControls, setFailedControls] = useState<CarouselDataProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useLocalTranslation('compliance.widgets');
  const { activeFilters } = useFiltersActions();

  const { filter } = activeFiltersParser(activeFilters);

  const emptyState = useWidgetEmptyState(
    ComplianceDashboardWidget.FAILED_CONTROLS,
    hasError,
    failedControls?.length === 0,
    dataAid,
  );

  const handleRedirect = (value: string) => {
    $state.go(value);
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        dashboardData: { frameworks },
      } = await fetchWidgetData({
        filter,
        subType: WidgetSubtype.GROUP_BY_FRAMEWORK,
        widgetName: WidgetName.GROUP_BY_FRAMEWORK,
      });
      const preparedData = frameworks?.map((element: CarouselDataProps) => ({
        ...element,
        progressValue: (element.failedControlsCount / element.totalControls) * 100,
      }));
      const sortedDataByField = orderBy(preparedData, 'failedControlsCount', 'desc');
      setFailedControls(sortedDataByField);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <BigidWidgetContainer
      dataAid={dataAid}
      titleConfig={{
        title: t('failedControls.title'),
        subtitle: (
          <>
            {!hasError && (
              <DescriptionWrapper>
                <BigidBody1 fontSize={12}>{t('failedControls.compoundText.part1')}</BigidBody1>
                <BigidLink
                  data-aid={generateDataAid(dataAid, ['emptyState', 'policiesLink'])}
                  text={t('failedControls.compoundText.part2')}
                  onClick={() => handleRedirect(CONFIG.states.POLICIES)}
                  underline="hover"
                />
              </DescriptionWrapper>
            )}
          </>
        ),
      }}
      isLoading={isLoading}
      content={
        <Content>
          <Carousel data={failedControls} itemsToShow={5}>
            <CarouselItemsList data={failedControls} itemsToShow={5} handleLoader={handleLoader} />
          </Carousel>
        </Content>
      }
      contentHeight="143px"
      emptyStateConfig={emptyState}
    />
  );
};
