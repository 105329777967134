import { ComponentType, ReactText } from 'react';
import {
  BigidDataAttributeIllustration,
  BigidDataFileIllustration,
  BigidDataFindingsIllustration,
  BigidDataPolicyIllustration,
  BigidDataScanIllustration,
  IconComponentProps,
} from '@bigid-ui/icons';
import { BigidAdvancedToolbarFilterUnion, BigidColorsV2, BigidDropdownOption } from '@bigid-ui/components';
import {
  Aggregation,
  AggregationFilterOperand,
  AggregationItemBase,
  AggregationItemLookupParams,
  AggregationItemName,
  AggregationType,
} from '../catalogDiscoveryTypes';
import { PieChartWidgetSeries } from '../widgets/PieChartWidget/PieChartWidget';
import { BarChartWidgetItem } from '../widgets/BarChartWidget/BarChartWidget';
import {
  getAggregationTotalReducedBy,
  getDataFormatAggregationValueColor,
  getDataFormatAggregationValueLabel,
  getObjectStatusAggregationValueColor,
  getObjectStatusAggregationValueLabel,
  getSensitivityAggregationValueColor,
  getSensitivityAggregationValueLabel,
} from './common';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import { pieChartItemsOrderMap } from '../config/widgets';

type NumericValueFormatterFunc = (value: number) => string;

export type GetPieChartWidgetUnknownDataPayload = {
  aggregationData: AggregationItemBase[];
  filterType: AggregationType;
  filters?: BigidAdvancedToolbarFilterUnion[];
  groupBy?: 'aggItemName' | 'aggItemGroup';
  colors?: string[];
};

export type GetPieChartWidgetKnownDataPayload = {
  aggregationData: AggregationItemBase[];
  aggregationType: AggregationType;
  associatedFilterType?: AggregationType;
  filters?: BigidAdvancedToolbarFilterUnion[];
  isDefaultOptions?: boolean;
  pickedOptions?: string[];
};

export function getSummaryWidgetItem(data: Aggregation, lookupAggItemName: AggregationItemName): AggregationItemBase {
  const aggregationData = data?.aggData ?? [];
  return aggregationData?.find(({ aggItemName }) => aggItemName === lookupAggItemName);
}

export function getSummaryWidgetValue(
  data: Aggregation,
  lookupProps: AggregationItemLookupParams,
  valueFormatter?: NumericValueFormatterFunc,
): ReactText {
  const aggregationData = data?.aggData ?? [];
  const { name, value } = lookupProps;
  const aggregationItem = name ? aggregationData.find(({ aggItemName }) => aggItemName === name) : aggregationData[0];
  const valueComputed = !isNaN(aggregationItem?.[value]) ? Number(aggregationItem?.[value]) : 0;

  return valueFormatter ? valueFormatter(valueComputed) : formatNumberCompact(valueComputed, 1);
}

export function getAggregationKnownValueColor(type: AggregationType, aggregationItemName: string): string {
  switch (type) {
    case AggregationType.OBJECT_STATUS:
      return getObjectStatusAggregationValueColor(aggregationItemName);
    case AggregationType.SENSITIVITY:
      return getSensitivityAggregationValueColor(aggregationItemName);
    case AggregationType.DATA_FORMAT:
      return getDataFormatAggregationValueColor(aggregationItemName);
    default:
      return BigidColorsV2.gray[100];
  }
}

export function getAggregationKnownValueDisplayName(type: AggregationType, aggregationItemName: string): string {
  switch (type) {
    case AggregationType.OBJECT_STATUS:
      return getObjectStatusAggregationValueLabel(aggregationItemName);
    case AggregationType.SENSITIVITY:
      return getSensitivityAggregationValueLabel(aggregationItemName);
    case AggregationType.DATA_FORMAT:
      return getDataFormatAggregationValueLabel(aggregationItemName);
    default:
      return aggregationItemName;
  }
}

/**
 * Returns a widget data based on a predefined set of options
 */
export function getPieChartWidgetKnownData({
  aggregationData,
  aggregationType,
  associatedFilterType,
  filters,
  pickedOptions = [],
}: GetPieChartWidgetKnownDataPayload): PieChartWidgetSeries[] {
  const aggregationDefaultOptions = pieChartItemsOrderMap.get(aggregationType) ?? [];
  const aggregationDataComputed = aggregationData ?? [];

  return aggregationDefaultOptions.map(defaultOption => {
    const optionName = defaultOption.aggItemName;
    const aggregationItem = aggregationDataComputed.find(({ aggItemName }) => aggItemName === optionName);
    const value = aggregationItem?.docCount ?? 0;
    const filter = filters?.find(({ id }) => id === (associatedFilterType ?? aggregationType));

    const isActive = Boolean(
      (filter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;
        return value.aggItemName === (aggregationItem ?? defaultOption).aggItemName;
      }),
    );

    return {
      value,
      isActive: value > 0 && (pickedOptions.length === 0 || isActive),
      isDisabled: value === 0,
      isAppliedFilter: isActive,
      color: getAggregationKnownValueColor(aggregationType, optionName),
      category: getAggregationKnownValueDisplayName(aggregationType, optionName),
      aggItem: aggregationItem ?? defaultOption,
    };
  });
}

export function getSensitivityPieChartData({
  aggregationData,
  aggregationType,
  isDefaultOptions,
  pickedOptions = [],
}: GetPieChartWidgetKnownDataPayload): PieChartWidgetSeries[] {
  let aggregationDefaultOptions;

  if (isDefaultOptions) {
    aggregationDefaultOptions = pieChartItemsOrderMap.get(aggregationType) ?? [];
  } else {
    const sortedAggItemsData = aggregationData.sort((a, b) => b.docCount - a.docCount);
    const aggItemNames = sortedAggItemsData.map(item => ({
      aggItemName: item.aggItemName,
    }));
    aggregationDefaultOptions = aggItemNames.slice(0, 6); // We display max of 6 items for sensitivity
    aggregationDefaultOptions = aggregationDefaultOptions.sort((a, b) => a.aggItemName.localeCompare(b.aggItemName));
  }

  const aggregationDataComputed = aggregationData ?? [];

  return aggregationDefaultOptions.map((option, index) => {
    const optionName = option.aggItemName;
    const aggregationItem = aggregationDataComputed.find(({ aggItemName }) => aggItemName === optionName);
    const value = aggregationItem?.docCount ?? 0;

    return {
      value,
      isActive: value > 0 && (pickedOptions.length === 0 || pickedOptions.includes(option.aggItemName)),
      isDisabled: value === 0,
      isAppliedFilter: pickedOptions.includes(option.aggItemName),
      color: getSensitivityAggregationValueColor(optionName, isDefaultOptions, index),
      category: getAggregationKnownValueDisplayName(aggregationType, optionName),
      aggItem: aggregationItem ?? option,
    };
  });
}

/**
 * returns a widget data based on random set of aggregated data
 */
export function getPieChartWidgetUnknownData({
  aggregationData,
  filterType,
  filters = [],
  groupBy = 'aggItemName',
}: GetPieChartWidgetUnknownDataPayload): PieChartWidgetSeries[] {
  const groupByComputed = groupBy ?? 'aggItemName';

  return aggregationData?.map(aggregationItem => {
    const itemValueComputed = aggregationItem[groupByComputed];
    const filter = filters?.find(({ id }) => id === filterType);
    const active = Boolean(
      (filter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;

        return value[groupByComputed] === itemValueComputed;
      }),
    );

    return {
      value: aggregationItem.docCount,
      isActive: active,
      category: itemValueComputed,
      aggItem: aggregationItem,
    };
  });
}

export function getPieChartWidgetTotal(data: Partial<Aggregation>, groupBy?: 'docCount' | 'findings'): number {
  if (groupBy) {
    return getAggregationTotalReducedBy(data.aggData ?? [], groupBy);
  }

  return data?.aggTotal ?? 0;
}

export function getAggregationIcon(aggregation: AggregationType): ComponentType<IconComponentProps> {
  switch (aggregation) {
    case AggregationType.HAS_FINDINGS:
      return BigidDataFindingsIllustration;
    case AggregationType.HAS_OPEN_ACCESS:
      return BigidDataAttributeIllustration;
    case AggregationType.HAS_DUPLICATES:
      return BigidDataFileIllustration;
    case AggregationType.VIOLATED_POLICY_CARDINALITY:
      return BigidDataPolicyIllustration;
    case AggregationType.SCANNED_VOLUME:
      return BigidDataScanIllustration;
  }
}

export type GetBarChartWidgetUnknownDataPayload = {
  aggregationData: AggregationItemBase[];
  groupBy?: 'aggItemName' | 'aggItemGroup';
  type: AggregationType;
  useCount?: 'findings' | 'docCount' | 'groupDocCount';
  colors?: string[];
  filter?: BigidAdvancedToolbarFilterUnion[];
};

export function getBarChartWidgetUnknownData({
  aggregationData,
  groupBy = 'aggItemName',
  useCount = 'docCount',
  colors = [],
  filter = [],
  type,
}: GetBarChartWidgetUnknownDataPayload): BarChartWidgetItem[] {
  return aggregationData?.map((aggregationItem, index) => {
    const widgetFilter = filter?.find(({ id }) => id === type);
    const isActive = Boolean(
      (widgetFilter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;
        return value[groupBy] === aggregationItem[groupBy];
      }),
    );

    return {
      name: aggregationItem[groupBy],
      value: aggregationItem[useCount],
      color: colors[index] || BigidColorsV2.gray[100],
      aggItem: aggregationItem,
      isActive,
    };
  });
}

export type GetBarChartWidgetKnownDataPayload = {
  aggregationData: AggregationItemBase[];
  type: AggregationType;
  filter?: BigidAdvancedToolbarFilterUnion[];
  groupBy?: 'aggItemName' | 'aggItemGroup';
};

export function getFiltersExcludingCurrentType(
  filter: BigidAdvancedToolbarFilterUnion[],
  filterOperand: AggregationFilterOperand,
) {
  return filter.filter(item => item.field !== filterOperand);
}

export function getCurrentTypeAppliedOptions(
  filter: BigidAdvancedToolbarFilterUnion[],
  filterOperand: AggregationFilterOperand,
) {
  const currentFilterAppliedOptions = filter.filter(item => item.field === filterOperand);
  const currentFilterAppliedOptionsGenericObj: any = currentFilterAppliedOptions[0]?.options;

  return currentFilterAppliedOptionsGenericObj?.map((option: BigidAdvancedToolbarFilterUnion) => option.id) ?? [];
}
