import { capitalize } from 'lodash';
import { BigidGridQueryComponents } from '@bigid-ui/grid';

import { httpService } from '../../services/httpService';
import { queryService } from '../../services/queryService';

import { ComplianceRegulation, RegulationFramework } from './types';

export const fetchRegulationsData = async (
  queryComponents: BigidGridQueryComponents,
): Promise<{ data: ComplianceRegulation[]; totalCount: number }> => {
  const gridConfigQuery = queryService.getGridConfigQuery({
    ...queryComponents,
    filter: queryComponents.filter.map(item =>
      item.operator === 'textSearch' ? { ...item, value: item.value.toString().trim() } : item,
    ),
  });
  const {
    data: {
      data: { regulations, totalCount },
    },
  } = await httpService.fetch<{ data: { regulations: ComplianceRegulation[]; totalCount: number } }>(
    `compliance-regulations?${gridConfigQuery}`,
  );
  const data = regulations.map(item => ({ ...item, flagCode: capitalize(item.flagCode) as typeof item.flagCode }));

  return {
    totalCount,
    data,
  };
};

export const fetchRegulationsFrameworksData = async (queryComponents: BigidGridQueryComponents) => {
  const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
  const {
    data: { data: frameworksToRegulations },
  } = await httpService.fetch<{
    data: Array<RegulationFramework>;
  }>(`compliance-regulations/frameworks?${gridConfigQuery}`);

  return frameworksToRegulations?.sort((a, b) => a.name.localeCompare(b.name));
};

export const updateRegulationStatus = async (queryComponents: BigidGridQueryComponents, enabled: boolean) => {
  await httpService.put(`compliance-regulations/status`, { query: queryComponents, enabled });
};

export const updateRegulationsFrameworks = async (
  queryComponents: BigidGridQueryComponents,
  frameworks: RegulationFramework[],
) => {
  return await httpService.put(`compliance-regulations/frameworks`, { query: queryComponents, frameworks });
};
