import { httpService } from '../../../../../../../services/httpService';
import { notificationService } from '../../../../../../../services/notificationService';
import { getFixedT } from '../../../../../translations';

const errorMessages = getFixedT('SetEmailActionDialog.Errors');

export const getSystemUsers = async (searchValue?: string) => {
  try {
    const response = await httpService.fetch(
      `access-management/users?limit=100${
        searchValue ? `&filter=[{"field":"firstName", "value":["${searchValue}"], "operator":"contains"}]` : ''
      }`,
    );
    return response?.data?.data?.users;
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    console.error(err);
    notificationService.error(errorMessages('fetchDataError'));
  }
};

export const getEmailTemplates = async () => {
  try {
    const response = await httpService.fetch('email-templates');
    return response?.data?.data?.emailTemplates;
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    console.error(err);
    notificationService.error(errorMessages('fetchDataError'));
  }
};
