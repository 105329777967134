import React from 'react';
import { BigidErrorIllustration, BigidNoDataIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';
import { BigidBody1, BigidWidgetContainerEmptyStateConfig } from '@bigid-ui/components';

export function useEmptyState<T, E, R>(
  data: T[],
  error: E,
  fetchData: () => Promise<R>,
): BigidWidgetContainerEmptyStateConfig {
  const { t } = useLocalTranslation('widgets');
  return {
    isEmpty: data?.length === 0 || !!error,
    illustration: !!error ? BigidErrorIllustration : BigidNoDataIllustration,
    illustrationSize: 'small',
    description: <BigidBody1>{!!error ? t('emptyState.errorText') : t('emptyState.defaultText')}</BigidBody1>,
    actions: [{ show: () => !!error, label: t('emptyState.tryAgain'), execute: fetchData, isGlobal: true }],
  };
}
