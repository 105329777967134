import { openSystemDialog, BigidSystemDialogOptions } from '../../services/systemDialogService';
import { BigidCarouselMediaSlide } from '@bigid-ui/components';
import { EaProgramModalContent } from './EaProgramModalContent';
import { getApplicationPreference, initApplicationPreferences } from '../../services/appPreferencesService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { notificationService } from '../../services/notificationService';
import { httpService } from '../../services/httpService';
import { docsUrls } from '../../config/publicUrls';
import { getFixedT } from './translations';
import { ServiceConfigurationsModel } from '../../views/AdvancedTools/ServicesConfiguration/ServicesConfiguration';
import { EaProgramEvents, trackEaProgramEvent } from './eaProgramEventsUtil';
import { getLastMajorReleaseDate } from './eaProgramUtils';
import { isNil } from 'lodash';

export enum ONBOARDING_MODAL_STATE {
  NOT_SHOWED = 'NOT_SHOWED',
  SHOWED = 'SHOWED',
  DO_NOT_SHOW_AGAIN = 'DO_NOT_SHOW_AGAIN',
}

export const CUSTOMER_EA_PROGRAM_ENABLED_FF = 'CUSTOMER_EA_PROGRAM_ENABLED';
export const CUSTOMER_OPT_IN_EA_PROGRAM_FF = 'CUSTOMER_OPT_IN_EA_PROGRAM';
export const USE_SAAS_FF = 'USE_SAAS';
export const EA_PROGRAM_USER_PREFERENCES = 'eaProgram';
const t = getFixedT('');

export interface EaCarouselItems extends BigidCarouselMediaSlide {
  title: string;
  body: string;
  learnMoreUrl: string;
}

export enum EaProgramSubView {
  FEATURES_INTRO = 'featuresIntro',
  FEATURES_SELECTION = 'featuresSelection',
}

export enum EaProgramFeature {
  CATALOG_EXPLORATION = 'catalogExploration',
  AI_SECURITY = 'aiSecurity',
  COMPLIANCE_DASHBOARD = 'complianceDashboard',
  UNIVERSAL_CONSENT = 'universalConsent',
  DYNAMIC_FORMS = 'dynamicForms',
  OTHER_FEATURES = 'otherFeatures',
}

export interface EaFeatureItemData {
  id: EaProgramFeature;
  title: string;
  text: string;
  imageSource: string;
  learnMoreUrl: string;
}

export const eaFeatureItems: EaFeatureItemData[] = [
  {
    id: EaProgramFeature.CATALOG_EXPLORATION,
    title: t(`featureItem.${EaProgramFeature.CATALOG_EXPLORATION}.title`),
    text: t(`featureItem.${EaProgramFeature.CATALOG_EXPLORATION}.body`),
    imageSource: '/images/eaCatalogExplorationItem.svg',
    learnMoreUrl: docsUrls.CATALOG_EXPLORATION_DOC,
  },
  {
    id: EaProgramFeature.AI_SECURITY,
    title: t(`featureItem.${EaProgramFeature.AI_SECURITY}.title`),
    text: t(`featureItem.${EaProgramFeature.AI_SECURITY}.body`),
    imageSource: '/images/eaAiSecurityItem.svg',
    learnMoreUrl: docsUrls.AI_DATA_SECURITY_DOC,
  },
  {
    id: EaProgramFeature.COMPLIANCE_DASHBOARD,
    title: t(`featureItem.${EaProgramFeature.COMPLIANCE_DASHBOARD}.title`),
    text: t(`featureItem.${EaProgramFeature.COMPLIANCE_DASHBOARD}.body`),
    imageSource: '/images/eaComplianceDashboardItem.svg',
    learnMoreUrl: docsUrls.COMPLIANCE_DASHBOARD_DOC,
  },
  {
    id: EaProgramFeature.UNIVERSAL_CONSENT,
    title: t(`featureItem.${EaProgramFeature.UNIVERSAL_CONSENT}.title`),
    text: t(`featureItem.${EaProgramFeature.UNIVERSAL_CONSENT}.body`),
    imageSource: '/images/eaUniversalConsentItem.svg',
    learnMoreUrl: docsUrls.UNIVERSAL_CONSENT_DOC,
  },
  {
    id: EaProgramFeature.DYNAMIC_FORMS,
    title: t(`featureItem.${EaProgramFeature.DYNAMIC_FORMS}.title`),
    text: t(`featureItem.${EaProgramFeature.DYNAMIC_FORMS}.body`),
    imageSource: '/images/eaDynamicFormsItem.svg',
    learnMoreUrl: docsUrls.DYNAMIC_FORMS_DOC,
  },
  {
    id: EaProgramFeature.OTHER_FEATURES,
    title: t(`featureItem.${EaProgramFeature.OTHER_FEATURES}.title`),
    text: t(`featureItem.${EaProgramFeature.OTHER_FEATURES}.body`),
    imageSource: '/images/eaOtherFeatures.svg',
    learnMoreUrl: docsUrls.EA_PROGRAM_DOC,
  },
];

export const carouselItems: EaCarouselItems[] = [
  {
    title: t(`featureItem.${EaProgramFeature.CATALOG_EXPLORATION}.title`),
    body: t(`featureItem.${EaProgramFeature.CATALOG_EXPLORATION}.body`),
    url: '/images/eaCatalogExploration.svg',
    type: 'img',
    learnMoreUrl: docsUrls.CATALOG_EXPLORATION_DOC,
  },
  {
    title: t(`featureItem.${EaProgramFeature.AI_SECURITY}.title`),
    body: t(`featureItem.${EaProgramFeature.AI_SECURITY}.body`),
    url: '/images/eaAiSecurity.svg',
    type: 'img',
    learnMoreUrl: docsUrls.AI_DATA_SECURITY_DOC,
  },
  {
    title: t(`featureItem.${EaProgramFeature.COMPLIANCE_DASHBOARD}.title`),
    body: t(`featureItem.${EaProgramFeature.COMPLIANCE_DASHBOARD}.body`),
    url: '/images/eaComplianceDashboard.svg',
    type: 'img',
    learnMoreUrl: docsUrls.COMPLIANCE_DASHBOARD_DOC,
  },
  {
    title: t(`featureItem.${EaProgramFeature.UNIVERSAL_CONSENT}.title`),
    body: t(`featureItem.${EaProgramFeature.UNIVERSAL_CONSENT}.body`),
    url: '/images/eaUniversalConsent.svg',
    type: 'img',
    learnMoreUrl: docsUrls.UNIVERSAL_CONSENT_DOC,
  },
  {
    title: t(`featureItem.${EaProgramFeature.DYNAMIC_FORMS}.title`),
    body: t(`featureItem.${EaProgramFeature.DYNAMIC_FORMS}.body`),
    url: '/images/eaDynamicForms.svg',
    type: 'img',
    learnMoreUrl: docsUrls.DYNAMIC_FORMS_DOC,
  },
];

export const getOptedInToEaProgramValue = async () => {
  try {
    const { data } = await httpService.fetch<ServiceConfigurationsModel[]>(`services-configuration`);
    return data.find(param => param.name === CUSTOMER_OPT_IN_EA_PROGRAM_FF)?.value;
  } catch (e) {
    return getApplicationPreference(CUSTOMER_OPT_IN_EA_PROGRAM_FF);
  }
};

export const isEaProgramEnabled = () => {
  const useSaasEnv = getApplicationPreference(USE_SAAS_FF);
  const eaProgramEnabled = getApplicationPreference(CUSTOMER_EA_PROGRAM_ENABLED_FF);
  return useSaasEnv && eaProgramEnabled;
};

const generateEaProgramModalOptions = (): BigidSystemDialogOptions => {
  return {
    title: t('modalTitle'),
    onClose: () => trackEaProgramEvent(EaProgramEvents.EA_PROGRAM_CLOSE_DIALOG_CLICK),
    content: EaProgramModalContent,
    maxWidth: 'md',
    hideContentPadding: true,
  };
};

export const isSelectionExpired = (selectedTimestamp: string) => {
  const selectionDate = new Date(selectedTimestamp);

  return selectionDate.getTime() < getLastMajorReleaseDate().getTime();
};

export const setEaProgramOptInValue = async (isAccepted: boolean) => {
  // BCO-18083 - Customers who joins will only change the value to the initail value and publish the event.
  if (isAccepted) {
    isAccepted = null;
  }
  try {
    const configuration = {
      name: CUSTOMER_OPT_IN_EA_PROGRAM_FF,
      id: CUSTOMER_OPT_IN_EA_PROGRAM_FF,
      isEditable: false,
      isRestartRequired: false,
      markAsSensitiveKey: false,
      type: 'boolean',
      value: isAccepted,
    };
    const webPromise = httpService.put('services-configuration', {
      configuration: { ...configuration, service: 'web' },
    });
    const orchPromise = httpService.put('services-configuration', {
      configuration: { ...configuration, service: 'orchestrator' },
    });
    const orch2Promise = httpService.put('services-configuration', {
      configuration: { ...configuration, service: 'orchestrator2' },
    });

    await Promise.all([webPromise, orchPromise, orch2Promise]);
    await initApplicationPreferences();
  } catch {
    notificationService.error(t('errorMessages.serviceConfiguration'));
  }
};

export const updateEaProgramUserSelection = async (isAccepted: boolean) => {
  if (isAccepted !== false) {
    notificationService.success(t('joinedProgramMessage'));
  }
  await setEaProgramOptInValue(isAccepted);
  const { data = {} } = (await userPreferencesService.get(EA_PROGRAM_USER_PREFERENCES)) || {};
  await userPreferencesService.update({ preference: EA_PROGRAM_USER_PREFERENCES, data: { ...data, isAccepted } });
  await userPreferencesService.setSharedBooleanUserPreference(
    userPreferencesService.SHARED_PREFERENCES.INTEREST_TO_JOIN_THE_EA_PROGRAM,
    isAccepted,
  );
};

export const isSharedPrefFalsyOrExpired = async () => {
  try {
    const { data, updated_at } = await userPreferencesService.get(
      userPreferencesService.SHARED_PREFERENCES.INTEREST_TO_JOIN_THE_EA_PROGRAM,
    );
    if (data?.value && updated_at) {
      return isSelectionExpired(updated_at);
    }

    return true;
  } catch (err) {
    console.error(err);
    return true;
  }
};

export const shouldDisplayEaProgramModal = async () => {
  const isCustomerNotInEa = await isSharedPrefFalsyOrExpired();
  if (isCustomerNotInEa) {
    const { updated_at, data } = (await userPreferencesService.get(EA_PROGRAM_USER_PREFERENCES)) || {};

    if (data?.isDismissed) {
      return false;
    }

    if (isNil(data?.isAccepted)) {
      return true;
    }

    return isSelectionExpired(updated_at);
  }

  return false;
};

export const openEaProgramModal = () => {
  const eaProgramModalOptions = generateEaProgramModalOptions();
  openSystemDialog(eaProgramModalOptions);
};
