import { FilterField, specialFilterIds, WidgetName, widgetNameToFilter } from './consts';
import { BREAKDOWN_TYPE, getCustomDateBreakdown, getPastDateBreakdown, getTimestampPastString } from './dateRangeUtils';
import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import { stringifiedNestedFilter } from './utils';
import {
  AbstractTreeNodeUnion,
  ConsumableFilter,
  ExpressionType,
  FilterConfig,
  FilterToBiqlBilateralParser,
  FilterToBiqlBilateralParserProps,
  FilterType,
} from '../../services/BIQL/filterToBiqlBilateralParser';
import { pick } from 'lodash';

const DASHBOARD_FILTER_PAST_VALUE = '1d';

export const getDashboardDataFilterExpression = (
  filters: BigidAdvancedToolbarFilterUnion[],
  widgetName?: WidgetName,
): string | null => {
  const { dateRangeFilter, tagsFilter, remainingFilters } = extractSpecialFilters(filters);
  const filtersWithPolicies = getFilterForWidget(widgetName);
  const dateRangeBiql = processDateRangeFilter(dateRangeFilter);
  const tagsFilterString = processTagsFilter(tagsFilter);

  const regularFiltersQuery = processRegularFilters(remainingFilters as BigidAdvancedToolbarDropdownFilter[]);

  return combineFilterStrings([
    regularFiltersQuery,
    wrapInParentheses(filtersWithPolicies),
    wrapInParentheses(dateRangeBiql),
    wrapInParentheses(tagsFilterString),
  ]);
};

export const getDashboardFilterExpression = (widgetName: WidgetName, filterName: string, value = ''): string => {
  const dateRangeFilter = getTimestampPastString(DASHBOARD_FILTER_PAST_VALUE);
  const dashboardFilter = widgetNameToFilter.get(widgetName);
  const searchFilter = getSearchValueFilter(filterName, value);
  return combineFilterStrings([dateRangeFilter, dashboardFilter, searchFilter]);
};

const parseDateRangeToBiql = (dateRangeFilter: BigidAdvancedToolbarDateRangeFilter): string => {
  const optionSelected = dateRangeFilter?.options?.currentRangeOptionSelected;
  const from = dateRangeFilter?.options?.pickersState?.dates?.from;
  const until = dateRangeFilter?.options?.pickersState?.dates?.until;
  if (optionSelected === BREAKDOWN_TYPE.CUSTOM) {
    return getCustomDateBreakdown(from, until);
  } else {
    return getPastDateBreakdown(optionSelected, {
      from,
      until,
    });
  }
};

const extractSpecialFilters = (filters: BigidAdvancedToolbarFilterUnion[]) => {
  const dateRangeFilter = filters.find(filter => filter.id === FilterField.DATE_RANGE) || null;
  const tagsFilter = filters.find(filter => filter.id === FilterField.TAGS) || null;

  const remainingFilters = filters.filter(filter => !specialFilterIds.has(filter.id as string));

  return { dateRangeFilter, tagsFilter, remainingFilters };
};

const getFilterForWidget = (widgetName?: WidgetName): string => {
  if (!widgetName || !widgetNameToFilter.has(widgetName)) {
    return '';
  }
  return widgetNameToFilter.get(widgetName);
};

export const hasFiltersForWidget = (widgetName: WidgetName) => getFilterForWidget(widgetName) !== '';

const processTagsFilter = (tagsFilter: BigidAdvancedToolbarFilterUnion | null): string => {
  return tagsFilter ? stringifiedNestedFilter([tagsFilter]) : '';
};

const processDateRangeFilter = (dateRangeFilter: BigidAdvancedToolbarFilterUnion | null): string => {
  return parseDateRangeToBiql(dateRangeFilter as BigidAdvancedToolbarDateRangeFilter);
};

const processRegularFilters = (filters: BigidAdvancedToolbarDropdownFilter[]): string => {
  const transformedFilters = filters.map(filter => ({
    ...filter,
    options: filter.options.map(option => ({
      ...option,
      value: option?.value?.aggItemName || option.value,
    })),
  }));

  return parseFiltersToBIQL(transformedFilters);
};

const parseFiltersToBIQL = (filters: BigidAdvancedToolbarFilterUnion[]) => {
  const filterConfigs: FilterConfig[] = filters
    .filter(filter => filter.type !== FilterType.DATE_RANGE)
    .map(filter => {
      return {
        lookup: (node: AbstractTreeNodeUnion) => {
          return (node as any).name === filter;
        },
        filterId: String(filter.id),
        filterType: FilterType.DROPDOWN,
        expressionType: ExpressionType.STRING_MULTIPLE,
      };
    });

  const parserConfig: FilterToBiqlBilateralParserProps = {
    filtersConfig: filterConfigs,
  };

  const consumableFilters: ConsumableFilter[] = filters.map(filter => pick(filter, ['id', 'type', 'field', 'options']));

  const parser = new FilterToBiqlBilateralParser(parserConfig);
  return parser.parseConsumableFilterToBiql(consumableFilters);
};

const getSearchValueFilter = (filterName: string, value: string) => {
  if (!(filterName?.length > 0) || !(value?.length > 0)) {
    return '';
  }
  return `${filterName} = "${value}"`;
};

const combineFilterStrings = (filterStrings: string[]): string => {
  return filterStrings.filter(str => str.trim().length > 0).join(' AND ');
};

const wrapInParentheses = (filterString: string): string => {
  return filterString ? `(${filterString})` : '';
};
