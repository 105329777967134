import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isPermitted } from '../../services/userPermissionsService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { EA_PROGRAM_USER_PREFERENCES, EaProgramFeature, EaProgramSubView } from './eaProgramModalService';
import { BigidLoader } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { EaProgramModalFooter } from './EaProgramModalFooter';
import { EaFeaturesIntro } from './EaSubViews';
import { EaFeaturesSelection } from './EaSubViews/EaFeaturesSelection';

const EA_PROGRAM_CONTENT_COMPONENT_NAME = 'EaProgramModalContent';

const Root = styled('div')({
  display: 'flex',
  minHeight: 484,
  flexDirection: 'column',
  padding: '16px 16px 0 16px',
});

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.bigid.gray200,
  height: 1,
  marginLeft: '-16px',
  marginRight: '-16px',
}));

export const EaProgramModalContent: FC = () => {
  const [currentActiveView, setCurrentActiveView] = useState<EaProgramSubView>(EaProgramSubView.FEATURES_INTRO);
  const [selectedFeatures, setSelectedFeatures] = useState<EaProgramFeature[]>([]);
  const [isDismissed, setIsDismissed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isAdmin = isPermitted('admin');

  useEffect(() => {
    const updateDismissedStatus = async () => {
      const { data } = (await userPreferencesService.get(EA_PROGRAM_USER_PREFERENCES)) || {};
      setIsDismissed(!!data?.isDismissed);
      setIsLoading(false);
    };
    updateDismissedStatus();
  }, []);

  const onFeatureSelectionChange = (featureId: EaProgramFeature, isSelected: boolean) => {
    const clonedArray = [...selectedFeatures];

    if (isSelected) {
      if (!clonedArray.includes(featureId)) {
        clonedArray.push(featureId);
      }
    } else {
      const index = clonedArray.indexOf(featureId);
      if (index !== -1) {
        clonedArray.splice(index, 1);
      }
    }

    setSelectedFeatures(clonedArray);
  };

  const onSelectAllFeatures = () => {
    setSelectedFeatures(Object.values(EaProgramFeature));
  };

  return (
    <Root data-aid={generateDataAid(EA_PROGRAM_CONTENT_COMPONENT_NAME, [])}>
      {isLoading ? (
        <BigidLoader position="relative" />
      ) : (
        <>
          {currentActiveView === EaProgramSubView.FEATURES_INTRO ? (
            <EaFeaturesIntro />
          ) : (
            <EaFeaturesSelection
              selectedFeatures={selectedFeatures}
              onFeatureSelectionChange={onFeatureSelectionChange}
              onSelectAllFeatures={onSelectAllFeatures}
            />
          )}
          <Divider />
          <EaProgramModalFooter
            selectedFeatures={selectedFeatures}
            updateCurrentActiveView={setCurrentActiveView}
            currentActiveView={currentActiveView}
            isAdmin={isAdmin}
            isDismissed={isDismissed}
          />
        </>
      )}
    </Root>
  );
};
