import { CompletedParentScanState, ParentScanType, ScanActions, ScansGridRow, SSEType } from '../ScanTypes';
import { runScanActionDialog, getArchiveScanReport } from '../ScanService';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidFieldFilterOperator, BigidLoader, BigidPaper, ToolbarActionType } from '@bigid-ui/components';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { getInitialCompletedScansGridColumns } from './CompletedScansGridConfiguration';
import {
  fetchScanData,
  getInitialScanFilters,
  shouldShowScanAction,
  subscribeToSSEScanUpdates,
  showDownloadArchiveScan,
  labelingScanSubtypeIsNotTaggingScan,
} from '../ScanUtils';
import { BigidArchiveIcon, BigidRefreshIcon, BigidImportIcon, BigidDownloadIcon } from '@bigid-ui/icons';
import { ScanViewEnum } from '../ScansEventTrackerUtils';
import { isPermitted } from '../../../services/userPermissionsService';
import { isMultiTenantModeEnabled } from '../../../utilities/multiTenantUtils';
import { NoDataContentCompletedScans } from './NoDataContentCompletedScans';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: 'calc(100% - 72px)',
    maxHeight: 'calc(100% - 72px)',
    overflow: 'hidden',
  },
});

const allCompletedScanStates = Object.values(CompletedParentScanState);

const defaultFilter = [{ field: 'state', operator: 'in' as BigidFieldFilterOperator, value: allCompletedScanStates }];

export const ScansCompleted: FC = () => {
  const classes = useStyles();
  const hasArchivedRef = useRef(false);

  useEffect(() => {
    const unregister = subscribeToSSEScanUpdates(SSEType.PARENT, ScanViewEnum.COMPLETED_SCANS);
    return () => unregister();
  }, []);

  useEffect(() => {
    const hasArchivedHandler = async () => {
      const isShowArchiveScan = await showDownloadArchiveScan();
      hasArchivedRef.current = isShowArchiveScan;
    };
    hasArchivedHandler();
  }, []);

  const initialCompletedScansGridColumns = useMemo(getInitialCompletedScansGridColumns, []);
  const getInitialFilterToolbarConfig = useCallback(
    () => getInitialScanFilters(Object.values(allCompletedScanStates)),
    [],
  );
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: initialCompletedScansGridColumns,
    getInitialFilterToolbarConfig,
  });

  const config: BigidGridWithToolbarProps<ScansGridRow> = useMemo(
    () => ({
      pageSize: 100,
      entityName: 'Scans',
      defaultSorting: preferences?.grid?.sort || [{ field: 'completedAt', order: 'desc' }],
      showSortingControls: true,
      showSelectAll: false,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: queryComponents => fetchScanData(queryComponents, defaultFilter),
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Rescan',
          icon: BigidRefreshIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.RESCAN),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ state, isRbacPermitted, type, name }] }) =>
            isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name) &&
            isRbacPermitted &&
            shouldShowScanAction({
              state,
              stateCondition: [
                CompletedParentScanState.STOPPED,
                CompletedParentScanState.FAILED,
                CompletedParentScanState.COMPLETED_WITH_FAILURES,
                CompletedParentScanState.COMPLETED,
                CompletedParentScanState.ABORTED,
              ],
            }) &&
            !labelingScanSubtypeIsNotTaggingScan(type, name),
        },
        {
          label: 'Archive',
          execute: async actionData => runScanActionDialog(actionData, ScanActions.ARCHIVE),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidArchiveIcon,
          show: ({ selectedRows: [{ isRbacPermitted }] }) => isRbacPermitted,
        },
        {
          label: 'Download Failed Objects report',
          execute: async actionData => runScanActionDialog(actionData, ScanActions.DOWNLOAD_FAILED_OBJECTS_REPORT),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidDownloadIcon,
          show: ({ selectedRows: [{ isFailedObjectsReportEmpty }] }) =>
            !isFailedObjectsReportEmpty && !isMultiTenantModeEnabled(),
        },
        {
          label: 'Export',
          icon: BigidImportIcon,
          tooltip: 'Download Archive Scans',
          execute: async () => {
            await getArchiveScanReport();
            return {};
          },
          type: ToolbarActionType.ACTION_ICON,
          disable: () => false,
          show: () => hasArchivedRef.current,
          placement: 'end',
        },
      ],
      noDataContent: <NoDataContentCompletedScans />,
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...config} />}
      </BigidPaper>
    </div>
  );
};
