import { addNewResources, i18next } from '@bigid-ui/i18n';
import { useTranslation } from 'react-i18next';
import enResources from './locales/en.t.json'; const resources = new Map([['en', enResources]]);
export const FRAMEWORKS_NAMESPACE = 'bigid/frameworks';
export const REGULATIONS_NAMESPACE = 'bigid/regulations';
export const useLocalTranslation = keyPrefix => useTranslation(FRAMEWORKS_NAMESPACE, {
  keyPrefix
});
export const getFixedT = key => i18next.getFixedT(null, FRAMEWORKS_NAMESPACE, key);
resources.forEach((resource, language) => {
  addNewResources(resource, FRAMEWORKS_NAMESPACE, language);
  addNewResources(resource, REGULATIONS_NAMESPACE, language);
});