import { RefObject, useEffect, useState } from 'react';

export const useInputFocus = <T extends HTMLElement>(anchorEl: RefObject<T>, isDisabled: boolean) => {
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const handleFocus = () => {
      setIsFocused(true);
      setOpen(true);
    };
    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const root = anchorEl.current;
    const inputElement = root?.querySelector('input');

    if (root) {
      root.addEventListener('mouseenter', handleMouseEnter);
      root.addEventListener('mouseleave', handleMouseLeave);
    }

    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      inputElement.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
        inputElement.removeEventListener('blur', handleBlur);
      }

      if (root) {
        root.removeEventListener('mouseenter', handleMouseEnter);
        root.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [anchorEl, isDisabled]);

  const onClickOutsideHandleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorEl.current && anchorEl.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const handleChildClick = () => {
    setOpen(false);
    setIsFocused(false);
  };

  return { open, onClickOutsideHandleClose, handleChildClick, setOpen, isFocused, isHovered };
};
