import React, { FC, memo, useState } from 'react';
import {
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import { useCategories } from '../../hooks/useCategories';
import { ASSIGN_CATEGORIES_TYPE } from '../../types/AssignCategories';
import { CreateCategory } from '../../../../components/AssignCategoryDialog/types';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../../translations';
import { createCategoryRequest } from '../../services/classifiersManagementService';
import { getCategoriesGridFormat, getDefaultColorCategory } from '../../utils/utils';
import { LabelWithTooltip } from '../LabelWithTooltip/LabelWithTooltip';
import { usePermissions } from '../../hooks/usePermissions';

const CategoriesFieldDropdownContainer = styled('div')`
  margin-bottom: 16px;
`;

export const CategoriesFieldDropdown: FC<BigidFieldRenderProps> = memo(({ value, setValue }) => {
  const { isCorrelationPermitted } = usePermissions();
  const { t } = useLocalTranslation('form');
  const { categoriesList, initialSelectedCategories, setCategories } = useCategories(
    [{ categories: value || [] }],
    ASSIGN_CATEGORIES_TYPE.DEFAULT,
  );

  const [selectedCategories, setSelectedCategories] = useState<BigidDropdownValue>(initialSelectedCategories);

  const onCreateCategory = async (value: string): Promise<BigidDropdownOption> => {
    const color = getDefaultColorCategory();
    const category: CreateCategory = {
      color,
      name: value,
      description: '',
    };

    const response = await createCategoryRequest(category);
    const chip = {
      displayValue: value,
      id: response?.glossary_id,
      value: {
        color,
        description: '',
        display_name: value,
        unique_name: response?.glossary_id,
        glossary_id: response?.glossary_id,
      },
    };
    setCategories(prevCategories => [...prevCategories, chip.value]);
    setSelectedCategories(prev => [...prev, chip]);
    return chip;
  };

  const onSelect = (selectedOptions: BigidDropdownOption[]) => {
    setValue(getCategoriesGridFormat(selectedOptions));
    setSelectedCategories(selectedOptions);
  };

  return (
    <CategoriesFieldDropdownContainer>
      <LabelWithTooltip label={t('category')} tooltipText={t('tooltips.categories')} />
      <BigidDropdown
        size="large"
        onSelect={onSelect}
        options={categoriesList}
        value={selectedCategories}
        isMulti
        isCreatable
        isSearchable
        onCreate={onCreateCategory}
        dropDownListHeight={150}
        displayLimit={categoriesList?.length}
        applyOnChange
        isAsync
        isIgnoreCaseSensitive
        readOnly={!isCorrelationPermitted}
      />
    </CategoriesFieldDropdownContainer>
  );
}, compareObjectsExceptFunctions);
