import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import { BasicDetails } from './BasicDetails';
import { BigidLoader } from '@bigid-ui/components';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { OverviewTags } from './OverviewTags';
import { OverviewCategories } from './OverviewCategories';
import { useGetObjectDetails } from '../hooks/useGetObjectDetails';
import { CatalogSidePanelProps } from '../CatalogSidePanel';
import { useGetOverviewEntities } from '../hooks/useGetOverviewEntities';
import { OverviewFullPath } from './OverviewFullPath';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 32px;
  padding-top: 16px;
`;

export const Overview = ({ selectedItem }: CatalogSidePanelProps) => {
  const { data, id } = selectedItem;
  const fqn = String(id);
  const { isCreateTagsPermitted, isReadTagsPermitted, isObjectTagsAssignmentPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isObjectTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
    }),
    [],
  );

  const objectDetailsResponse = useGetObjectDetails(fqn);

  const { dataSourceDetailsResponse, objectCategoriesResponse, usersListResponse } = useGetOverviewEntities(
    fqn,
    data.datasource?.value,
  );

  const {
    data: { data: objectDetails },
    refetch: refetchDetails,
    isLoading,
  } = objectDetailsResponse;
  const { data: usersList, refetch: refetchUsers } = usersListResponse;
  const { data: objectCategories } = objectCategoriesResponse;
  const { data: dataSourceDetails } = dataSourceDetailsResponse;

  const refetchData = () => {
    refetchDetails();
    refetchUsers();
  };
  return (
    <Root>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <OverviewTags
            isReadTagsPermitted={isReadTagsPermitted}
            isCreateTagsPermitted={isCreateTagsPermitted}
            objectDetails={objectDetails}
            isObjectTagsAssignmentPermitted={isObjectTagsAssignmentPermitted}
            refetchDetails={refetchDetails}
          />
          <OverviewCategories categories={objectCategories} />
          <BasicDetails
            details={objectDetails}
            refetchData={refetchData}
            usersList={usersList}
            dsOwners={dataSourceDetails?.owners_v2}
          />
          <OverviewFullPath scannerType={objectDetails.scannerType} fullPath={objectDetails.fullPath} />
        </>
      )}
    </Root>
  );
};
