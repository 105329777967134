import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../../translations';
import { BigidDsSuccessIllustration } from '@bigid-ui/icons';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';

const FindingsChartContainer = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '16px 24px 24px 24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '8px',
}));

export const DataRiskFindingsIndicator: FC<{ findingsCount: number }> = ({ findingsCount }) => {
  const { t } = useLocalTranslation('dataRiskWidget');
  return (
    <FindingsChartContainer>
      <BigidDsSuccessIllustration />
      <BigidBody1 size="large" fontSize="26px">
        {formatNumberCompact(findingsCount, 0)}
      </BigidBody1>
      <BigidBody1>{t('discoveredFindings')}</BigidBody1>
    </FindingsChartContainer>
  );
};
