import { httpService } from './httpService';
import { License, licenseService } from './licenseService';
import { AppLicense, AppLicenseType, appsLicenseService } from './appsLicenseService';
import { CustomApps } from '../views/ApplicationsManagement/applicationManagementService';
import { $state } from './angularServices';
import { CONFIG } from '../../config/common';
import { PresetPayload } from '../views/CustomApp/views/CustomAppActions/CustomAppActions';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { isEmpty } from 'lodash';
import { notificationService } from './notificationService';
import { GlobalPreset } from '../views/CustomApp/utils/CustomAppTypes';
import { ExecutionModel } from '../views/CustomApp/views/ActivityLog/ActivityLog';
import { getApplicationPreference } from './appPreferencesService';
import { setWorkspacesSettingsForCustomApp } from '../utilities/workspacesUtils';

const EMAIL_APP_NAME = 'Email';

export interface GoToCustomApp {
  appName?: string;
  appRoute?: string;
  appId?: string;
}

const addCustomAppBaseUrl = (createAppPayload: CreateAppPayload) => httpService.post('tpa', createAppPayload);

const upgradeCustomApp = (tpaId: string, params: any) => httpService.put(`tpa/${tpaId}`, params);

const getCustomAppGlobalParams = (tpaId: string) => httpService.fetch(`tpa/${tpaId}`);

const getCustomAppActions = (tpaId: string, sortByIndex = false) => {
  const sortByIndexUrl = sortByIndex ? `?sort_by=index&is_asc=true` : ``;
  return httpService.fetch(`tpa/${tpaId}/actions${sortByIndexUrl}`);
};

const getCustomAppLastExecutions = (tpaId: string) => httpService.fetch(`tpa/${tpaId}/executions/latest`);

const getCustomAppExecutionsCount = (tpaId: string) => httpService.fetch(`tpa/${tpaId}/executions/count`);

const updateCustomAppParamValues = (tpaId: string, params: any) => httpService.put(`tpa/${tpaId}/all_params`, params);

const getCustomApps = async () => {
  const data = await httpService.fetch('tpa');
  const showEmailApp = getApplicationPreference('SHOW_EMAIL_APP');
  const filteredCustomApps = data.data.filter(
    (customApp: CustomApps) => customApp.tpa_name !== EMAIL_APP_NAME || showEmailApp === true,
  );
  data.data = filteredCustomApps;
  data.data.forEach(setWorkspacesSettingsForCustomApp);
  return data;
};

const getCustomAppByID = async (appId: string) => {
  const data = await httpService.fetch(`tpa/${appId}`);
  data.data.forEach(setWorkspacesSettingsForCustomApp);
  return data;
};
const getCustomAppByName = (tpaName: string) => httpService.fetch(`tpa/${tpaName}`);

const getAppGlobalPresets = async (appId: string) =>
  httpService.fetch<{ data: GlobalPreset[] }>(`tpa/${appId}/global_presets`);

const addGlobalPreset = (appId: string, globalPreset: Partial<GlobalPreset>) =>
  httpService.post(`tpa/${appId}/global_presets`, globalPreset);

const editGlobalPreset = (appId: string, globalPresetId: string, globalPreset: Partial<GlobalPreset>) =>
  httpService.put(`tpa/${appId}/global_presets/${globalPresetId}`, globalPreset);

const deleteGlobalPreset = (appId: string, globalPresetId: string) =>
  httpService.delete(`/tpa/${appId}/global_presets/${globalPresetId}`);

const deleteCustomApp = (appId: string) => httpService.delete(`tpa/${appId}`);

const deleteAllAppInstances = (manifestName: string) => httpService.delete(`tpa/instances/${manifestName}`);

const addActionPreset = (appId: string, actionId: string, preset: PresetPayload) =>
  httpService.post(`tpa/${appId}/actions/${actionId}/preset`, preset);

const editActionPreset = (appId: string, actionId: string, presetId: string, preset: PresetPayload) =>
  httpService.put(`tpa/${appId}/actions/${actionId}/preset/${presetId}`, preset);

const deleteActionPreset = (appId: string, actionId: string, presetId: string) =>
  httpService.delete(`tpa/${appId}/actions/${actionId}/preset/${presetId}`);

const downloadLogs = (appId: string) => httpService.downloadFile(`tpa/${appId}/file-download/logs`, { format: 'txt' });

const runCustomAppPreset = (actionId: string, presetId: string, appId: string) =>
  httpService.post(`tpa/${appId}/executions`, { actionId, presetId });

const editTpaExecution = (execution: ExecutionModel) =>
  httpService.put(`tpa/executions/${execution._id.toString()}`, { ...execution, statusEnum: execution.status_enum });

const setScheduleCustomAppAction = (appId: string, actionId: string, isSchedule: boolean, cronExpression: string) =>
  httpService.put(`tpa/${appId}/actions/${actionId}/schedule`, {
    isSchedule: isSchedule,
    scheduleExpression: cronExpression,
  });

const getImageListFromDockerhub = () => httpService.fetch(`tpa/dockerhub_images`);

const deploySelectedTpa = (appImageName: string, appImage: string, port: string) =>
  httpService.post(`tpa/deploy_app`, { appImageName, appImage, port });

const uninstallTpa = (appImageName: string) => httpService.post(`tpa/uninstall_app`, { appImageName });

const getCustomAppExecutions = (appId: string, queryComponents?: BigidGridQueryComponents) => {
  const { limit, skip } = { ...queryComponents };
  let route = `tpa/${appId}/executions?sort_by=updated_at`;

  if (limit) {
    route = `${route}&limit=${limit}`;
  }
  if (skip) {
    route = `${route}&skip=${skip}`;
  }

  return httpService.fetch(route);
};

const getCustomAppExecutionsAttachments = <T = any>(appId: string) =>
  httpService.fetch<T>(`tpa/${appId}/executions/attachments`, { sort_by: 'updated_at' });

const isValidURL = (str: string): boolean => {
  if (str.indexOf('?') !== -1) return false;
  try {
    const url = new URL(str);
    return url.protocol === 'http' + ':' || url.protocol === 'https' + ':';
  } catch (err) {
    return false;
  }
};

const verifyCustomApp = (verifyAppPayload: CreateAppPayload) => httpService.post('tpa/verify', verifyAppPayload);

const fetchApplicationsFromMarketplace = () => httpService.fetch('tpa/marketplace');

export const goToCustomApp = async ({ appName = '', appRoute = '', appId = '' }: GoToCustomApp) => {
  const { data: apps } = await getCustomApps();
  const app = apps.find((app: CustomApps) => app.tpa_name === appName || app._id == appId);
  if (app) {
    $state.go(CONFIG.states.CUSTOM_APP, { appRoute, id: app._id });
  } else {
    console.error('Cant find customApp', { appName, appId });
  }
};

type CreateCustomAppUsingURL = {
  createAppPayload: CreateAppPayload;
  onUrlIsNotValid: () => void;
  onSuccess: (tpaId: string, name: string, vendor: string) => Promise<void>;
  onError: (errorMessage: string) => void;
};

export type CreateAppPayload = {
  appUrl: string;
  isRemoteApplication?: boolean;
  scannerGroup?: string;
  instanceId?: string;
};

const createCustomAppUsingURL = async ({
  createAppPayload,
  onUrlIsNotValid,
  onSuccess,
  onError,
}: CreateCustomAppUsingURL) => {
  const isValidUrl = isValidURL(createAppPayload.appUrl);
  if (!isValidUrl) {
    onUrlIsNotValid();
    return;
  } else if (createAppPayload.isRemoteApplication && isEmpty(createAppPayload.scannerGroup)) {
    notificationService.error('Please fill up the scanner group field for remote application');
    return;
  }
  try {
    const {
      data: { tpaId, name, vendor },
    } = await addCustomAppBaseUrl(createAppPayload);
    onSuccess(tpaId, name, vendor);
  } catch (e) {
    const {
      data: { message },
    } = e.response;
    onError(message);
  }
};

const handleAppLicenseAfterInstall = async () => {
  const { data } = await httpService.fetch<{ license: License; supportedApps: AppLicense[] }>('license');
  licenseService.setLicense(data.license);
  appsLicenseService.setAppsLicense(data.supportedApps);
};
export type CustomAppVerification = {
  verifyAppPayload: CreateAppPayload;
  onVerified: () => void;
  onVerifiedWithLicense: () => void;
  onNotVerifiedByBigId: (emptyVerificationKey: boolean) => void;
  onError: (errorMessage: string) => void;
};

const customAppVerification = async ({
  verifyAppPayload,
  onError,
  onVerified,
  onVerifiedWithLicense,
  onNotVerifiedByBigId,
}: CustomAppVerification) => {
  try {
    const {
      data: {
        data: { verified, empty: emptyVerificationKey, verifiedApp },
      },
    } = await verifyCustomApp(verifyAppPayload);

    if (verified) {
      const isLicensed =
        appsLicenseService
          .getAppsLicense()
          .find(({ name, vendor }) => verifiedApp.name === name && verifiedApp.vendor === vendor) ||
        licenseService.hasCustomAppInSingleApps(verifiedApp.name, verifiedApp.vendor);

      if (verifiedApp.licenseType.toLowerCase() === AppLicenseType.PAID && !isLicensed) {
        onVerified();
      } else {
        onVerifiedWithLicense();
      }
    } else {
      onNotVerifiedByBigId(emptyVerificationKey);
    }
  } catch (e) {
    const {
      data: { message },
    } = e.response;
    onError(message);
  }
};

export const customAppService = {
  addCustomAppBaseUrl,
  upgradeCustomApp,
  getCustomAppGlobalParams,
  getCustomAppActions,
  updateCustomAppParamValues,
  getCustomApps,
  getCustomAppByID,
  deleteCustomApp,
  deleteAllAppInstances,
  addActionPreset,
  editActionPreset,
  deleteActionPreset,
  downloadLogs,
  runCustomAppPreset,
  getCustomAppExecutions,
  getCustomAppExecutionsCount,
  getCustomAppExecutionsAttachments,
  setScheduleCustomAppAction,
  getImageListFromDockerhub,
  deploySelectedTpa,
  uninstallTpa,
  isValidURL,
  verifyCustomApp,
  goToCustomApp,
  getCustomAppByName,
  fetchApplicationsFromMarketplace,
  getCustomAppLastExecutions,
  createCustomAppUsingURL,
  handleAppLicenseAfterInstall,
  customAppVerification,
  getAppGlobalPresets,
  addGlobalPreset,
  deleteGlobalPreset,
  editGlobalPreset,
  editTpaExecution,
};
