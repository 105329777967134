import React, { FunctionComponent, useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import {
  BigidPaper,
  BigidFilter,
  PrimaryButton,
  BigidLoader,
  BigidSelect,
  BigidSelectOption,
  BigidBody2,
  BigidHighlightIndicator,
  BigidCaption,
  objectToQueryString,
  BigidColors,
  BigidHeading6,
  BigidBody1,
} from '@bigid-ui/components';
import { BigidAuditIllustration } from '@bigid-ui/icons';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumnTypes, BigidGridColumn } from '@bigid-ui/grid';
import { DataCatalogRecord } from '../DataCatalogService';
import {
  getPreviewTableData,
  getPreviewTableDataCached,
  deletePreviewTableData,
  DataCatalogPreviewTableField,
  DataCatalogPreviewTableRecord,
} from './DataCatalogTablePreviewService';
import { getColumnsByObjectName, DataCatalogObjectColumn } from '../DataCatalogColumns/DataCatalogColumnsService';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../../services/notificationService';
import classNames from 'classnames';
import { AxiosError } from 'axios';
import { CatalogEventsEnum } from '../events';
import { analyticsService } from '../../../services/analyticsService';
import {
  getIsScannerTypeFeatureSupported,
  ScannerTypeFeature,
} from '../../../services/scannerTypesSupportedFeaturesService';

interface DataCatalogTablePreviewStyles {
  isColumnsAmountExceedsLimit: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    padding: '5px',
  },
  rootReduced: {
    padding: '12px 5px 5px 5px',
  },
  previewDataTriggerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  previewDataTriggerLabel: {},
  previewDataTriggerSelect: {
    width: 75,
    marginLeft: 20,
  },
  previewDataTriggerButton: {
    marginLeft: 10,
  },
  gridContainer: {
    height: '100%',
    width: '100%',
  },
  grid: {
    height: 'calc(100% - 50px)',
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '50px',
    alignItems: 'center',
    padding: '0 12px',
    borderTop: `1px solid ${BigidColors.borderLight}`,
  },
  legend: ({ isColumnsAmountExceedsLimit }: DataCatalogTablePreviewStyles) => ({
    display: 'flex',
    flex: 1,
    justifyContent: isColumnsAmountExceedsLimit ? 'space-between' : 'flex-end',
  }),
  legendItem: {
    display: 'flex',
  },
  legendItemLabel: {},
  legendItemIcon: {
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
  },
  unsupportedPreview: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const previewFieldsLimitOptions: BigidSelectOption[] = [
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const getGridRows = (tableRecords: DataCatalogPreviewTableField[][]): DataCatalogPreviewTableRecord[] => {
  return tableRecords.reduce((rows, tableRecord, index) => {
    return [
      ...rows,
      {
        ...tableRecord.reduce(
          (row, { fieldName, fieldValue }) => {
            if (fieldName === 'id') {
              return row;
            } else {
              return { ...row, [fieldName]: fieldValue };
            }
          },
          { id: index.toString() },
        ),
      },
    ];
  }, [] as DataCatalogPreviewTableRecord[]);
};

const getGridColumns = (rows: DataCatalogPreviewTableRecord[]): BigidGridColumn<DataCatalogPreviewTableRecord>[] => {
  const [row] = rows;
  return Object.keys(row).reduce((columns, columnName) => {
    if (columnName === 'id') return columns;

    return [
      ...columns,
      {
        name: columnName,
        title: columnName,
        getCellValue: (row: DataCatalogPreviewTableRecord) => row[columnName],
        type: BigidGridColumnTypes.TEXT,
      },
    ];
  }, []);
};

const columnsAmountLimit = 50;

export const DataCatalogTablePreview: FunctionComponent<DataCatalogRecord> = ({
  source,
  fullyQualifiedName,
  type,
  scannerType,
}: DataCatalogRecord) => {
  const isPreviewSupported = useMemo(
    () => getIsScannerTypeFeatureSupported(scannerType, ScannerTypeFeature.HAS_PREVIEW),
    [scannerType],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([]);
  const [currPreviewLimit, setCurrPreviewLimit] = useState<BigidSelectOption[]>([previewFieldsLimitOptions[0]]);
  const [rows, setRows] = useState<DataCatalogPreviewTableRecord[]>([]);
  const [columns, setColumns] = useState<BigidGridColumn<DataCatalogPreviewTableRecord>[]>([]);
  const [isColumnsAmountExceedsLimit, setIsColumnsAmountExceedsLimit] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [wasInitialFetchAttempt, setWasInitialFetchAttempt] = useState<boolean>(false);
  const classes = useStyles({ isColumnsAmountExceedsLimit });

  useEffect(() => {
    const trackData = {
      source,
      fullyQualifiedName,
      dsType: type,
    };

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_VIEW_PREVIEW_TABLE, trackData);
  }, [source, fullyQualifiedName, type]);

  const resetGrid = (): void => {
    setRows([]);
    setColumns([]);
    setIsFetched(false);
  };

  //TODO: handle unknown columns use case separately, under the hood
  const gridConfig = useMemo<BigidGridWithToolbarProps<DataCatalogPreviewTableRecord>>(
    () => ({
      externalFilter,
      entityName: 'rows in preview',
      showSortingControls: false,
      hideColumnChooser: true,
      fetchData: async () => {
        return {
          totalCount: rows.length,
          data: rows,
        };
      },
      columns,
      toolbarActions: [
        {
          label: 'Clear From Cache',
          isGlobal: true,
          execute: async () => {
            try {
              await deletePreviewTableData(fullyQualifiedName);
              notificationService.success('Cache successfully has been cleared');
              resetGrid();
            } catch ({ message }) {
              console.error(`An error has occurred: ${message}`);
              notificationService.error('An error has occurred');
            } finally {
              return Promise.resolve({ shouldGridReload: false });
            }
          },
          show: () => true,
        },
      ],
    }),
    [columns, rows, externalFilter, fullyQualifiedName],
  );

  const handlePreviewDataSelectChange = (option: BigidSelectOption[]): void => {
    setCurrPreviewLimit(option);
  };

  const handlePreviewDataButtonClick = () => {
    fetchPreviewData(fullyQualifiedName, currPreviewLimit[0].value);
  };

  const handleFetchError = ({
    message,
    response,
  }: AxiosError<{
    name: string;
    message: string;
  }>): void => {
    if ((response.status === 501 || response.status === 503) && response.data) {
      const { data } = response;
      notificationService.error(data.message);
    } else {
      const name = response.data.name;
      if (name === 'TIMEOUT' || name === 'DS_CONNECTION_ERROR') {
        notificationService.error(response.data.message);
      } else {
        notificationService.error('An error has occurred');
      }
    }
    console.error(`An error has occurred: ${message}`);
  };

  const setGridConfig = (
    objectColumns: DataCatalogObjectColumn[],
    previewData: DataCatalogPreviewTableField[][],
  ): void => {
    const rows = getGridRows(previewData);
    const columns = getGridColumns(rows).map(columnConfig => {
      const column = objectColumns.find(({ column_name }) => column_name === columnConfig.name);
      if (column?.attribute_list) {
        return {
          ...columnConfig,
          highlight: {
            color: BigidColors.green[700],
            text: `Findings in this column: ${column.attribute_list
              .map(({ attribute_name }) => attribute_name)
              .join(', ')}`,
          },
        };
      } else {
        return columnConfig;
      }
    });

    if (columns.length > columnsAmountLimit) {
      setColumns(columns.slice(0, columnsAmountLimit));
      setIsColumnsAmountExceedsLimit(true);
    } else {
      setColumns(columns);
      setIsColumnsAmountExceedsLimit(false);
    }

    setRows(rows);
    setIsFetched(true);
  };

  const fetchPreviewData = useCallback(async (fullyQualifiedName: string, limit: number) => {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await getPreviewTableData({ fullyQualifiedName, limit });
      const query = objectToQueryString({
        object_name: fullyQualifiedName,
        requireTotalCount: false,
      });
      const { data } = await getColumnsByObjectName(query);

      if (results.length > 0) {
        setGridConfig(data, results);
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchPreviewCachedData = useCallback(
    async (fullyQualifiedName: string) => {
      try {
        setIsLoading(true);

        const {
          data: { results, amountOfFields },
        } = await getPreviewTableDataCached(fullyQualifiedName);
        const query = objectToQueryString({
          object_name: fullyQualifiedName,
          requireTotalCount: false,
        });
        const { data } = await getColumnsByObjectName(query);

        if (amountOfFields > 0 && results.length > 0) {
          setGridConfig(data, results);
        }
      } catch (error) {
        handleFetchError(error);
      } finally {
        setIsLoading(false);
        setWasInitialFetchAttempt(true);
      }
    },
    [source],
  );

  useEffect(() => {
    setExternalFilter([]);
    resetGrid();
    if (isPreviewSupported) {
      fetchPreviewCachedData(fullyQualifiedName);
    }
  }, [fetchPreviewCachedData, fullyQualifiedName, isPreviewSupported]);

  return (
    <div className={classNames(classes.root, isFetched && classes.rootReduced)}>
      <BigidPaper isOutlined={isFetched} caption={isFetched ? 'Data Preview' : ''} hasShadow={!isFetched}>
        {isPreviewSupported ? (
          <>
            {wasInitialFetchAttempt && (
              <Fragment>
                {!isFetched ? (
                  <div className={classes.previewDataTriggerContainer}>
                    <div className={classes.previewDataTriggerLabel}>
                      <BigidBody2>Select the number of rows to preview</BigidBody2>
                    </div>
                    <div className={classes.previewDataTriggerSelect}>
                      <BigidSelect
                        isSearchable
                        isDisabled={isLoading}
                        menuPlacement="bottom"
                        menuPosition="absolute"
                        value={currPreviewLimit}
                        options={previewFieldsLimitOptions}
                        onChange={handlePreviewDataSelectChange}
                        dataAid={'DataCatalogTablePreview-limit-select'}
                      />
                    </div>
                    <div className={classes.previewDataTriggerButton}>
                      <PrimaryButton
                        size="medium"
                        onClick={handlePreviewDataButtonClick}
                        disabled={isLoading}
                        dataAid={'DataCatalogTablePreview-fetch-data'}
                        text="Preview Data"
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.gridContainer}>
                    <div className={classes.grid}>
                      <BigidGridWithToolbar {...gridConfig} />
                    </div>
                    <div className={classes.footer}>
                      <div className={classes.legend}>
                        {isColumnsAmountExceedsLimit && (
                          <div className={classes.legendItem}>
                            <div className={classes.legendItemIcon}>
                              <BigidHeading6>*</BigidHeading6>
                            </div>
                            <div className={classes.legendItemLabel}>
                              <BigidCaption>
                                Number of columns in the preview is limited to {columnsAmountLimit}
                              </BigidCaption>
                            </div>
                          </div>
                        )}
                        <div className={classes.legendItem}>
                          <div className={classes.legendItemIcon}>
                            <BigidHighlightIndicator diameter={7} color={BigidColors.green[700]} />
                          </div>
                          <div className={classes.legendItemLabel}>
                            <BigidCaption>Columns with Findings</BigidCaption>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
            {isLoading && <BigidLoader />}
          </>
        ) : (
          <div className={classes.unsupportedPreview}>
            <BigidAuditIllustration />
            <BigidBody1>The data source &lsquo;{source}&rsquo; does not support preview</BigidBody1>
          </div>
        )}
      </BigidPaper>
    </div>
  );
};
