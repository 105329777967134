import { BigidTooltip, PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { BigidWarningFilledIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { noop } from 'lodash';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocalTranslation } from './translations';
import { FiltersModalContent } from './FiltersModal/FiltersModalContent';
import { openSystemDialog } from '../../../services/systemDialogService';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { useTheme } from '@mui/styles';
import { generateDataAid } from '@bigid-ui/utils';
import { useInputFocus } from './hooks/useInputFocus';
import { useQuery } from 'react-query';
import {
  fetchQuickSearchResults,
  getRecentlySearchedItems,
  updateRecentlySearchedPreferences,
} from './dataExplorerQuickSearchService';
import { QuickSearchResults } from './components/QuickSearchResults';
import { RecentlySearchedResults } from './components/RecentlySearchedResults';
import { Theme } from '@mui/material/styles';
import { ModeToggle } from './components/ModeToggle';
import { useSearchMode } from './hooks/useSearchMode';
import { SearchMode } from './types';
import { SearchButtons } from './components/SearchButtons';
import { getIsValidQueryString } from '@bigid/query-object-serialization';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { QuickSearchPopper } from './components/QuickSearchPopper';

const SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD = 2;

type PropsWithTheme = RootProps & {
  theme: Theme;
};
const getBorder = ({ isInvalid, isActive, theme }: PropsWithTheme) => {
  if (isInvalid) {
    return `1px solid ${theme.vars.tokens.bigid.negativeStrong}`;
  }

  if (isActive) {
    return theme.vars.tokens.bigid.borderCta;
  }

  return theme.vars.tokens.bigid.borderDefault;
};

const getBackgroundColor = ({ theme, isDisabled }: PropsWithTheme) => {
  if (isDisabled) {
    return theme.vars.tokens.bigid.backgroundDisabled;
  }
  return theme.vars.tokens.bigid.backgroundPrimary;
};

type RootProps = Pick<DataExplorerAdvancedSearchProps, 'width' | 'isSmall'> & {
  isActive?: boolean;
  isInvalid?: boolean;
  isDisabled: boolean;
};

const Root = styled('form')<RootProps>`
  border-radius: 4px;
  padding: ${({ isSmall }) => (isSmall ? '3px' : '8px')};
  display: flex;
  gap: 8px;
  border: ${getBorder};
  background-color: ${getBackgroundColor};
  align-items: center;
  width: ${({ width }) => width};
  position: relative;
  cursor: ${({ isDisabled }) => isDisabled && 'default'};
`;

const WarningContainer = styled('span')`
  position: absolute;
  right: -28px;
  top: 10px;
`;

const Input = styled('input')`
  padding: 0;
  border: none;
  flex-grow: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundPrimary};
  background-color: none;
`;

const getTransitionPayload = (mode: SearchMode, searchValue: string) => {
  const queryParamName = mode === 'searchText' ? 'query' : 'biqlFilter';

  return {
    query: queryParamName === 'query' ? searchValue : null,
    biqlFilter: queryParamName === 'biqlFilter' ? searchValue : null,
    queryMode: mode,
  };
};

export type DataExplorerAdvancedSearchProps = {
  width?: string;
  initialMode?: SearchMode;
  initialSearchValue?: string;
  quickSearchDisabled?: boolean;
  dataAid?: string;
  isSmall?: boolean;
  onSubmit?: (searchText: string) => void;
  customModePlaceholders?: Record<SearchMode, string>;
  onModeChange?: (mode: SearchMode) => void;
};
export const DataExplorerAdvancedSearch = ({
  width = '640px',
  initialMode = 'searchText',
  initialSearchValue = '',
  quickSearchDisabled,
  isSmall,
  onSubmit,
  customModePlaceholders,
  onModeChange,
}: DataExplorerAdvancedSearchProps) => {
  const { t } = useLocalTranslation();
  const [isInvalidFilter, setIsInvalidFilter] = useState(false);
  const { mode, debouncedSearchValue, handleModeChange, handleInputChange, clearInput, searchValue } = useSearchMode({
    initialMode,
    initialSearchValue,
    onModeChange: () => {
      onModeChange?.(mode);
      setIsInvalidFilter(false);
    },
  });
  const [openFilters, setOpenFilters] = useState(false);
  const theme = useTheme();
  const rootRef = useRef<HTMLFormElement>(null);
  const builderResultsRef = useRef(noop);
  const dataAid = 'DataExplorerAdvancedSearch';
  const isMetadataSearchDisabled = useMemo(() => !getApplicationPreference('METADATA_SEARCH_ENABLED'), []);

  const placeholderValue = isMetadataSearchDisabled
    ? t(`placeholder.disabled`)
    : customModePlaceholders?.[mode] ?? t?.(`placeholder.${mode}`) ?? '';

  const {
    isFocused,
    isHovered,
    handleChildClick,
    onClickOutsideHandleClose,
    open: openQuickSearch,
    setOpen,
  } = useInputFocus<HTMLFormElement>(rootRef, isMetadataSearchDisabled);

  const trimmedSearchValue = searchValue.trim();

  const shouldFetchAdvancedData = !quickSearchDisabled && isFocused;

  const handleModalClose = () => setOpenFilters(false);

  const handleButtonSubmit = useCallback(() => {
    builderResultsRef.current();
    handleModalClose();
    onSubmit?.(trimmedSearchValue);
  }, [builderResultsRef, onSubmit, trimmedSearchValue]);

  const handleFiltersButtonClick = () => {
    setOpenFilters(value => !value);
  };

  const { data: recentlySearchedItems } = useQuery(['recentlySearchedItems'], getRecentlySearchedItems, {
    enabled: shouldFetchAdvancedData,
    keepPreviousData: true,
    placeholderData: [],
  });

  const showProcessSearchResults =
    debouncedSearchValue?.length > SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD && mode === 'searchText';

  const {
    data: { data: quickSearchData },
    isLoading: isQuickSearchLoading,
    isFetching: isQuickSearchFetching,
  } = useQuery(['quickSearch', debouncedSearchValue], () => fetchQuickSearchResults(debouncedSearchValue), {
    enabled: showProcessSearchResults,
    placeholderData: {
      data: {
        catalog: { count: 0, results: [] },
        datasource: { count: 0, results: [] },
        policy: { count: 0, results: [] },
      },
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    if (openFilters) {
      openSystemDialog({
        content: FiltersModalContent,
        onClose: handleModalClose,
        hideContentPadding: true,
        title: t('Modal.Header.title'),
        showCloseIcon: true,
        contentProps: {
          onSubmitRef: builderResultsRef,
          dataAid,
        },
        fixedHeight: 493,
        maxWidth: 'md',
        buttons: [
          {
            component: TertiaryButton,
            onClick: handleModalClose,
            text: t('Modal.CloseButton'),
            size: 'medium',
            alignment: 'right',
            isClose: true,
            dataAid: generateDataAid(dataAid, ['modal', 'CloseButton']),
          },
          {
            component: PrimaryButton,
            onClick: handleButtonSubmit,
            text: t('Modal.SubmitButton'),
            size: 'medium',
            alignment: 'right',
            dataAid: generateDataAid(dataAid, ['modal', 'SubmitButton']),
          },
        ],
      });
    }
  }, [openFilters, handleButtonSubmit, t]);

  useEffect(() => {
    if (recentlySearchedItems?.length > 0 || showProcessSearchResults) {
      setOpen(true);
      return;
    }
    setOpen(false);
  }, [recentlySearchedItems, setOpen, showProcessSearchResults]);

  const handleQuerySubmit = async (event?: SyntheticEvent) => {
    event?.preventDefault();

    if (mode === 'queryFilter') {
      const isFilterInvalid = !getIsValidQueryString(trimmedSearchValue);

      setIsInvalidFilter(isFilterInvalid);
      if (isFilterInvalid) {
        return;
      }
    }

    const payload = getTransitionPayload(mode, trimmedSearchValue);

    if (trimmedSearchValue.length > 0) {
      await updateRecentlySearchedPreferences({
        searchValue: trimmedSearchValue,
        type: mode,
        created_at: Date.now(),
        entityName: 'catalog',
      });
    }

    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, payload);
    onSubmit?.(trimmedSearchValue);
  };

  const hasRecentlySearched = (recentlySearchedItems?.length ?? 0) > 0;
  const shouldShowDynamicResults = showProcessSearchResults || hasRecentlySearched;

  const shouldOpen = openQuickSearch && !quickSearchDisabled && shouldShowDynamicResults;

  const resultsToShow = showProcessSearchResults ? (
    <QuickSearchResults
      searchText={debouncedSearchValue}
      handleChildClick={handleChildClick}
      data={quickSearchData}
      isLoading={isQuickSearchLoading || isQuickSearchFetching}
    />
  ) : (
    <RecentlySearchedResults handleChildClick={handleChildClick} recentlySearchedItems={recentlySearchedItems} />
  );

  const handleSearchClear = () => {
    clearInput();

    if (quickSearchDisabled) {
      const payload = getTransitionPayload(mode, '');

      $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, payload);
    }
  };

  return (
    <Root
      width={width}
      ref={rootRef}
      isDisabled={isMetadataSearchDisabled}
      aria-disabled={isMetadataSearchDisabled}
      isSmall={isSmall}
      onSubmit={handleQuerySubmit}
      isInvalid={isInvalidFilter}
      isActive={isFocused || isHovered}
      data-aid={generateDataAid(dataAid, ['root'])}
      aria-invalid={isInvalidFilter}
    >
      <ModeToggle
        mode={initialMode}
        onChange={handleModeChange}
        isDisabled={isMetadataSearchDisabled}
        dataAid={dataAid}
      />
      <Input
        placeholder={placeholderValue}
        onChange={e => handleInputChange(e.target.value)}
        value={searchValue}
        disabled={isMetadataSearchDisabled}
        data-aid={generateDataAid(dataAid, ['input'])}
      />
      <SearchButtons
        searchValue={debouncedSearchValue}
        onClear={handleSearchClear}
        onSubmit={handleQuerySubmit}
        onFiltersClick={handleFiltersButtonClick}
        isDisabled={isMetadataSearchDisabled}
        dataAid={generateDataAid(dataAid, ['SearchButtons'])}
      />
      <QuickSearchPopper anchorEl={rootRef} open={shouldOpen} handleClose={onClickOutsideHandleClose}>
        {resultsToShow}
      </QuickSearchPopper>
      {isInvalidFilter && (
        <BigidTooltip title={t('invalidBiqlFilterError')}>
          <WarningContainer>
            <BigidWarningFilledIcon color={theme.vars.tokens.bigid.negativeStrong} />
          </WarningContainer>
        </BigidTooltip>
      )}
    </Root>
  );
};
