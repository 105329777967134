import { Box, styled } from '@mui/material';
import React, { useState } from 'react';
import { useLocalTranslation } from '../../translations';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidDropdown,
  BigidDropdownOption,
  BigidToggleButton,
  BigidToggleButtonProps,
} from '@bigid-ui/components';
import { BigidSearchIcon } from '@bigid-ui/icons';
import { FilterBuilder, FilterBuilderProps } from './FilterBuilder';
import { QueryBuilder } from './QueryBuilder';
import { ResultsEntityType } from '../../../../DataExplorerSearchResults/types';
import { useQuery } from 'react-query';
import { useCatalogFilters } from '../../../../DataExplorerSearchResults/SearchGrid/GridComponents/Toolbars/Catalog/useCatalogFiltersConfig';
import { fetchEntityFilters } from '../../../../DataExplorerSearchResults/SearchGrid/GridComponents/Toolbars/EnititiesToolbar/fetchFilters';
import { generateDataAid } from '@bigid-ui/utils';
import { mapDropdownFiltersToFilterString } from '../../../../../utilities/toolbarFilterToBIQL/toolbarFilterToBIQL';
import { parseCatalogFilterOutputToQueryString } from '../../../../DataExplorerSearchResults/queryHelpers';

const EntityChooseSection = styled('div')`
  padding: 16px 24px;
  display: flex;
  gap: 6px;
  align-items: center;
  border-bottom: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  background: ${({ theme }) => theme.vars.palette.bigid.gray125};
`;

const SearchModeContainer = styled('div')`
  display: flex;
  padding: 24px 24px 16px 24px;
`;

const BuilderContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  margin: 0 24px;
  border-radius: 8px;
  border: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  position: relative;
`;

const entityTypeToParserFnMap: Record<ResultsEntityType, (filters: BigidAdvancedToolbarFilterUnion[]) => string> = {
  catalog: parseCatalogFilterOutputToQueryString,
  datasource: mapDropdownFiltersToFilterString,
  policy: mapDropdownFiltersToFilterString,
};

export const ModalRoot = ({ onSubmitRef, dataAid }: Pick<FilterBuilderProps, 'onSubmitRef' | 'dataAid'>) => {
  const { t } = useLocalTranslation('Modal');
  const [selectedSearchEntity, setSelectedSearchEntity] = useState<ResultsEntityType>('catalog');
  const [selectedBuilderMode, setSelectedBuilderMode] = useState('filters');

  const catalogFilters = useCatalogFilters();

  const { data, isLoading } = useQuery(['EntityFiltersToolbar', selectedSearchEntity], () =>
    fetchEntityFilters(selectedSearchEntity),
  );

  const filtersMap: Record<ResultsEntityType, BigidAdvancedToolbarFilterUnion[]> = {
    catalog: catalogFilters,
    datasource: data,
    policy: data,
  };

  const dropDownOptions: BigidDropdownOption[] = [
    {
      displayValue: t('SearchSection.entities.catalog'),
      id: 'catalog',
      value: 'catalog',
      isSelected: true,
    },
    {
      displayValue: t('SearchSection.entities.policy'),
      id: 'policy',
      value: 'policy',
    },
    {
      displayValue: t('SearchSection.entities.datasource'),
      id: 'datasource',
      value: 'datasource',
    },
  ];

  const handleSearchEntitySelection = (options: BigidDropdownOption[]) => {
    setSelectedSearchEntity(options[0].id as ResultsEntityType);
  };

  const searchModeButtons: BigidToggleButtonProps[] = [
    {
      value: 'filters',
      label: t('FiltersButton'),
    },
    {
      value: 'query',
      label: t('QueryButton'),
    },
  ];

  const handleBuilderModeChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setSelectedBuilderMode(value);
  };

  const Builder = selectedBuilderMode === 'filters' ? FilterBuilder : QueryBuilder;

  const toolbarFilters = filtersMap[selectedSearchEntity];
  const parserFn = entityTypeToParserFnMap[selectedSearchEntity];

  return (
    <Box
      data-aid={generateDataAid(dataAid, ['root'])}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <EntityChooseSection>
        <BigidSearchIcon size="small" />
        <BigidBody1 flexShrink="0" fontWeight="700">
          {t('SearchSection.title')}
        </BigidBody1>
        <Box
          sx={{
            width: 240,
          }}
        >
          <BigidDropdown
            dataAid={generateDataAid(dataAid, ['search', 'entity', 'chooser'])}
            value={[dropDownOptions[0]]}
            options={dropDownOptions}
            onSelect={handleSearchEntitySelection}
          />
        </Box>
      </EntityChooseSection>
      <SearchModeContainer>
        <BigidToggleButton
          initialSelected="filters"
          toggleButtons={searchModeButtons}
          onChange={handleBuilderModeChange}
          size="medium"
          dataAid={generateDataAid(dataAid, ['entity', 'mode', 'toggle'])}
        />
      </SearchModeContainer>
      <BuilderContainer>
        <Builder
          dataAid={generateDataAid(dataAid, ['builder'])}
          filters={toolbarFilters}
          onSubmitRef={onSubmitRef}
          entityName={selectedSearchEntity}
          isLoading={isLoading}
          filterToQueryStringParserFn={parserFn}
        />
      </BuilderContainer>
    </Box>
  );
};
