import React, { FC, useMemo, useRef } from 'react';

import { BigidAdvancedToolbarFilterUnion, BigidWidgetContainer } from '@bigid-ui/components';
import { BigidDonutChart, BigidDonutChartSliceData } from '@bigid-ui/visualisation';
import { BigidInfoIcon } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import {
  DonutChartData,
  Series,
  SeverityColorMapper,
  WidgetProps,
  WidgetSubTypes,
} from '../PrivacyExecutiveDashboardTypes';
import { Menu } from './helpers/HelperComponents';
import { LegendFormatter, RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { fetchWidgetData } from '../services/dashboardService';
import { useEmptyState } from './hooks/useEmptyState';

interface DataRiskApiData {
  matrixSize: number;
  totalCases: number;
  riskCasesCount: {
    category: string;
    value: number;
  }[];
}

export const DataRiskWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading, error, fetchData } = useFetchWidgetData(fetchDataRiskWidgetData);
  const emptyStateConfig = useEmptyState(data, error, fetchData);
  const lineChartRef = useRef<Series>(null);

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: false, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <BigidDonutChart
            entityName="Open"
            chartSize={144}
            data={data || []}
            legendProps={{
              legendPosition: 'right',
              legendConfig: { orientation: 'column' },
              onLegendClick: (data, isActive) => {
                lineChartRef.current?.series.forEach(series => {
                  if (series.name.toLowerCase() === data.category?.toLowerCase()) {
                    isActive ? series.hide() : series.show();
                  }
                });
              },
              legendLabelFormatter: ({ category, value }: DonutChartData) => (
                <LegendFormatter>
                  <span>{category}</span>
                  <span>{value}</span>
                </LegendFormatter>
              ),
            }}
            numberFormat="#."
          />
        }
        titleConfig={{
          title,
          iconAfter: {
            icon: BigidInfoIcon,
            tooltip: t('dataRisk.tooltip'),
          },
        }}
        actionsSlot={<Menu menuItems={menuItems} />}
        isLoading={isLoading}
        emptyStateConfig={emptyStateConfig}
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskWidgetData = async (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
  severityColorMapper: SeverityColorMapper,
): Promise<BigidDonutChartSliceData[]> => {
  const widgetData: DataRiskApiData = await fetchWidgetData({
    subType: WidgetSubTypes.DATA_RISK,
    filter: activeFilters,
  });
  return attachWidgetProps(widgetData, severityColorMapper);
};

const attachWidgetProps = (
  widgetData: DataRiskApiData,
  severityColorMapper: SeverityColorMapper,
): BigidDonutChartSliceData[] => {
  return widgetData.riskCasesCount.map(risk => ({
    ...risk,
    isActive: true,
    color: severityColorMapper?.[risk.category],
  }));
};
