import React, { useMemo, useState } from 'react';
import { BigidCheckbox, BigidTooltip } from '@bigid-ui/components';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  useFetch,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { useCorrelationSetWizardContext } from '../../contexts/correlationSetContext';
import {
  CorrelationSetsWizardSteps,
  DefaultStepProps,
  TestConnectionColumn,
  WizardFormFields,
} from '../../types/CorrelationSetsTypes';
import styled from '@emotion/styled';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { GridCellDropdownMenu } from './GridCellDropdownMenu';
import { generateCustomQueryString, getIsCustomQueryStringValue } from '../../utils/utils';
import { GridHeader } from './GridHeader';
import { PreviewLoader } from '../PreviewLoader';

const GridHolder = styled.div`
  margin: 0 -16px;

  & [class='CellLayout-container'] {
    display: block;
  }
`;

const ColumnHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftColumnBox = styled.div`
  display: flex;
  align-items: center;
`;

const ColumnNameBox = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 130px;
`;

const OriginalColumnName = styled.span`
  color: gray;
  margin-left: 4px;
`;

//STEP 2
export function mapGridData(data: TestConnectionColumn[] = []) {
  // Split column data and organize it into rows
  const rows = [];

  // Determine max rows needed based on the longest split data in each column
  const maxRows = Math.max(...data.map(col => col.columnData?.split(',').length));

  // Initialize each row with empty objects for each column
  for (let i = 0; i < maxRows; i++) {
    const row: Record<string, any> = {};
    data.forEach(col => {
      const dataEntries = col.columnData?.split(',').map(item => item.trim());
      row[col.columnName] = dataEntries[i] || ''; // Add data or empty string if no data
    });
    rows.push(row);
  }

  return rows;
}

export function getGridColumns(data: TestConnectionColumn[] = [], width?: number) {
  const columns: BigidGridColumn<any>[] = data.map(({ columnName }) => {
    return {
      title: columnName,
      name: columnName,
      type: BigidGridColumnTypes.TEXT,
      width: width || 250,
      getCellValue: row => row[columnName],
    };
  });

  return columns;
}

interface CustomHeaderCellProps {
  columnData: TestConnectionColumn;
  onColumnDataChange: (columnName: string, columnData: Partial<TestConnectionColumn>) => void;
  isEditableMode: boolean;
}

const CustomHeaderCell = ({ columnData, onColumnDataChange, isEditableMode }: CustomHeaderCellProps) => {
  const { columnName, aliasColumnName, selection } = columnData;

  const handleSaveColumnAlias = (aliasValue: string) => {
    onColumnDataChange(columnName, {
      ...columnData,
      aliasColumnName: aliasValue,
    });
  };

  const handleSaveColumnFilter = (filter: string) => {
    console.log('Save filter', filter);
  };

  const handleColumnSelect = () => {
    onColumnDataChange(columnName, {
      ...columnData,
      selection: !selection,
    });
  };

  const label = (
    <ColumnNameBox>
      <BigidTooltip title={aliasColumnName ? `${aliasColumnName} (${columnName})` : columnName} placement="top">
        {aliasColumnName ? (
          <span>
            {aliasColumnName}
            <OriginalColumnName>({columnName})</OriginalColumnName>
          </span>
        ) : (
          <span>{columnName}</span>
        )}
      </BigidTooltip>
    </ColumnNameBox>
  );

  return (
    <ColumnHolder>
      <LeftColumnBox>
        {isEditableMode && <BigidCheckbox checked={selection} label="" onChange={handleColumnSelect} />}
        {label}
      </LeftColumnBox>
      {isEditableMode && (
        <GridCellDropdownMenu
          handleSaveColumnAlias={handleSaveColumnAlias}
          aliasColumnName={aliasColumnName}
          filter=""
          handleSaveColumnFilter={handleSaveColumnFilter}
        />
      )}
    </ColumnHolder>
  );
};

interface AttributesGridProps extends DefaultStepProps {
  isEditableGridColumns: boolean;
}

export const AttributesGrid = ({
  detailsStepFormControls,
  isEditableGridColumns,
  activeStepId,
}: AttributesGridProps) => {
  const { correlationSetFormData, isLoadingConnection } = useCorrelationSetWizardContext();
  const { attributes, attributesTrainingStep, isCustomQueryString, dsConnection, db_table } = correlationSetFormData;
  const isCustomQueryStringValue = getIsCustomQueryStringValue(isCustomQueryString);
  const currentInitialAttributes =
    activeStepId === CorrelationSetsWizardSteps.PREVIEW ? attributes : attributesTrainingStep;

  const [attributesState, setAttributesState] = useState(currentInitialAttributes);

  const gridData = useMemo(() => mapGridData(attributesState), [attributesState]);
  const gridColumns = useMemo(() => getGridColumns(attributesState), [attributesState]);

  const onColumnDataChange = (columnName: string, newAttributeData: Partial<TestConnectionColumn>) => {
    const updatedAttributesState = attributesState.map(attr =>
      attr.columnName === columnName ? { ...attr, ...newAttributeData } : attr,
    );

    //update custom query string value
    if (!isCustomQueryStringValue) {
      detailsStepFormControls.current.setValue(WizardFormFields.custom_query_string)(
        generateCustomQueryString({ ...correlationSetFormData, attributes: updatedAttributesState }),
      );
    }

    detailsStepFormControls.current.setValue(WizardFormFields.attributes)(updatedAttributesState);
    setAttributesState(updatedAttributesState);
  };

  const headerCell = (props: TableHeaderRow.CellProps) => {
    const isEditableMode = isEditableGridColumns ? !isCustomQueryStringValue : isEditableGridColumns;
    const { column } = props;
    const currentColumnData =
      attributesState.find(attr => attr.columnName === column.title) || ({} as TestConnectionColumn);

    return (
      <CustomHeaderCell
        columnData={currentColumnData}
        onColumnDataChange={onColumnDataChange}
        isEditableMode={isEditableMode}
      />
    );
  };

  const gridConfig = useMemo<BigidGridWithToolbarProps<any>>(
    () => ({
      gridId: `attributesPreview-${activeStepId}`,
      entityName: '',
      hideColumnChooser: true,
      showSortingControls: false,
      fetchData: async () => {
        return {
          totalCount: gridData.length,
          data: gridData,
        };
      },
      columns: gridColumns,
      displayActionToolbar: false,
    }),
    [gridData, gridColumns],
  );

  return (
    <GridHolder>
      {isLoadingConnection && <PreviewLoader />}
      <GridHeader db_table={db_table} isCustomQuery={isCustomQueryStringValue} dsConnection={dsConnection} />
      <BigidGridWithToolbar {...gridConfig} headerCellComponent={headerCell} />
    </GridHolder>
  );
};
