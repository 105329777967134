import React from 'react';

import { PrivacyExecutiveDashboardWidgets } from './PrivacyExecutiveDashboardTypes';
import { useLocalTranslation } from './translations';
import {
  DataRiskWidget,
  DataRiskTrendWidget,
  DataRiskTypesWidget,
  RenderActiveWidgetWrapper,
  WidgetContainer,
  WidgetRow,
  DataRiskLandscapeWidget,
  PerformanceMeasuresWidget,
  PerformanceOperationalSuccessWidget,
  DeletionWidget,
  PrivacyMapWidget,
} from './widgets';

export const WidgetWrapper = () => {
  const { t } = useLocalTranslation('widgets');

  return (
    <WidgetContainer>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK}>
          <DataRiskWidget title={t('dataRisk.title')} />
        </RenderActiveWidgetWrapper>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_TREND}>
          <DataRiskTrendWidget title={t('dataRiskTrend.title')} />
        </RenderActiveWidgetWrapper>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_TYPES}>
          <DataRiskTypesWidget title={t('dataRiskTypes.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_LANDSCAPE}>
          <DataRiskLandscapeWidget title={t('dataRiskLandscape.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.PERFORMANCE_MEASURES}>
          <PerformanceMeasuresWidget title={t('performanceMeasures.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.PERFORMANCE_OPERATIONAL_SUCCESS}>
          <PerformanceOperationalSuccessWidget title={t('performanceOperationalSuccess.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.DELETION}>
          <DeletionWidget title={t('dataDeletion.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.PRIVACY_MAP}>
          <PrivacyMapWidget title={t('privacyMap.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
    </WidgetContainer>
  );
};
