import React from 'react';
import { OverviewSection } from './OverviewSection';
import { useLocalTranslation } from '../../../../../translations';
import { BigidCategoryChip, BigidHeading6 } from '@bigid-ui/components';
import { ShowMore } from './ShowMore';
import { Category } from '../../../../../../DataCatalog/DataCatalogAttributes';
import { DataCatalogObjectDetails } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { FullPath } from '../../../../../components/FullPath/FullPath';
import { styled } from '@mui/material';

const Root = styled('div')`
  gap: 8px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: column;
`;

type OverviewFullPathProps = Pick<DataCatalogObjectDetails, 'fullPath' | 'scannerType'>;

export const OverviewFullPath = ({ fullPath, scannerType }: OverviewFullPathProps) => {
  const { t } = useLocalTranslation('sidePanel.overview');

  return (
    <OverviewSection
      title={t('fullPath')}
      contentOrientation="horizontal"
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      gap={8}
    >
      <FullPath dsType={scannerType} fullPath={fullPath} />
    </OverviewSection>
  );
};
