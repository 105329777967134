import React, { FC, useMemo, memo, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { BigidColorsV2, BigidHeading5, BigidLoader } from '@bigid-ui/components';
import { BigidLayout, LayoutContentType, BigidLayoutConfig, BigidHotspotsChartConfig } from '@bigid-ui/layout';
import { BigidGridProps, BigidGridRow, BigidGridDataFetchResult, BigidGridQueryComponents } from '@bigid-ui/grid';
import { BigidAttributeIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../../translations';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { AttributesGridAggregationItem } from '../../../../CatalogDiscovery/catalogDiscoveryTypes';
import { pageSizeAvailableOptions } from '../../../../CatalogDiscovery/content/AttributesLayout/AttributesTreeMapToolbar';
import { gridWidgetHeight } from '../../../../CatalogDiscovery/config/widgets';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { fetchCaseAttributes } from '../caseReportService';
import { HeaderIcon } from '../CaseReportComponents/caseReportStyles';
import { generateDataAid } from '@bigid-ui/utils';
import { ReducerActions } from '../../hooks/CaseSidePanelReducer';

type AttributesGridRecord = BigidGridRow & AttributesGridAggregationItem;

const Root = styled.div<{
  isVisible: boolean;
}>(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  height: `${gridWidgetHeight}px`,
  margin: '24px 0',
  position: 'relative',
}));

const WidgetWrapper = styled.div<{
  isVisible: boolean;
}>(({ isVisible }) => ({
  visibility: isVisible ? 'visible' : 'hidden',
}));

const HeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
});

const Header = styled.div({
  display: 'flex',
  padding: '22px 0px',
  flexDirection: 'row',
});

export const AttributesWidget: FC = memo(() => {
  const { t } = useLocalTranslation('CaseReport.AttributesWidget');
  const { caseSidePanelData, isAttributesWidgetVisible, dispatch } = useContext(CaseSidePanelContext);
  const [numOfAttributes, setNumOfAttributes] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const gridConfig: BigidGridProps<AttributesGridRecord> = useMemo(
    () => ({
      columns: [],
    }),
    [],
  );

  const hotspotsChartConfig: BigidHotspotsChartConfig = useMemo(
    () => ({
      paddingInner: 4,
      height: 412,
      biggestBoxMaxPercentage: 0.3,
      showOnlyOneMenu: true,
      tooltipText: ({ row }) => {
        return (
          `${t('grid.columns.name')}: ${row.aggItemName}\n` +
          `${t('grid.columns.numOfObjects')}: ${formatNumberCompact(row.docCount)}\n` +
          `${t('grid.columns.numOfFindings')}: ${formatNumberCompact(row.findings)}\n` +
          `${t('grid.columns.categories')}: ${
            row.categories?.length > 0 ? row.categories.join(', ') : t('grid.placeholders.noCategories')
          }`
        );
      },
      textTop: ({ row }) => {
        return row.aggItemName;
      },
      textBottom: ({ row }) => {
        return `${formatNumberCompact(row.findings)} ${t('hotspot.numOfFindings')}`;
      },
      fields: [
        {
          label: t('grid.columns.name'),
          value: 'aggItemName',
        },
        {
          label: t('grid.columns.friendlyName'),
          value: 'friendlyName',
        },
        {
          label: t('grid.columns.numOfFindings'),
          value: 'findings',
        },
        {
          label: t('grid.columns.numOfObjects'),
          value: 'numOfObjects',
        },
        {
          label: t('grid.columns.dataSources'),
          value: 'dataSourceCount',
        },
      ],
      hotspotsChartColorField: 'numOfObjects',
      hotspotsChartBoxSizeField: 'findings',
      hotspotsChartViewSize: pageSizeAvailableOptions[0],
    }),
    [t],
  );

  const layoutConfig: BigidLayoutConfig = useMemo(
    () => ({
      layoutId: 'AttributesLayout',
      content: {
        contentTypes: [LayoutContentType.HOTSPOTS_CHART],
        viewConfig: {
          fetchGridData: async (queryComponents: BigidGridQueryComponents) => {
            try {
              const { dataSourceName, policyName } = caseSidePanelData;
              const { aggData, totalCount } = await fetchCaseAttributes({
                dataSourceName,
                policyName,
                queryComponents,
              });
              setNumOfAttributes(totalCount);
              dispatch({
                type: ReducerActions.UPDATE_ATTRIBUTES_WIDGET_VISIBILITY,
                payload: { isAttributesWidgetVisible: totalCount > 0 },
              });
              if (totalCount === 0) {
                console.warn('Amount of attributes are equal to 0');
              }
              const result: BigidGridDataFetchResult<AttributesGridRecord> = {
                data: (aggData ?? []).map(aggDataItem => ({
                  ...aggDataItem,
                  id: aggDataItem.aggItemName,
                })) as AttributesGridRecord[],
                totalCount,
              };
              return result;
            } catch ({ message }) {
              dispatch({
                type: ReducerActions.UPDATE_ATTRIBUTES_WIDGET_VISIBILITY,
                payload: { isAttributesWidgetVisible: false },
              });
              dispatch({
                type: ReducerActions.UPDATE_WIDGETS_ERROR_STATUS,
                payload: { hasWidgetsErrors: true },
              });
              console.error(`An error has occurred: ${message}`);
              return {
                data: [],
                totalCount: 0,
              };
            } finally {
              setIsLoading(false);
            }
          },
          gridConfig,
          hotspotsChartConfig,
          toolbarConfig: {
            hideColumnChooser: true,
            hideToolbar: true,
          },
        },
      },
    }),
    [caseSidePanelData, dispatch, gridConfig, hotspotsChartConfig],
  );

  return (
    <Root isVisible={isAttributesWidgetVisible}>
      {isLoading && <BigidLoader />}
      <WidgetWrapper isVisible={!isLoading}>
        <Header>
          <HeaderIcon>
            <BigidAttributeIcon />
          </HeaderIcon>
          <HeaderWrapper>
            <BigidHeading5 paddingLeft={'6px'} fontWeight={700}>
              {t('widgetTitle')}
            </BigidHeading5>
            <BigidHeading5 paddingLeft={0.5} color={BigidColorsV2.purple[400]} fontWeight={700}>
              {formatNumberCompact(numOfAttributes, 1)}
            </BigidHeading5>
          </HeaderWrapper>
        </Header>
        <BigidLayout config={layoutConfig} data-aid={generateDataAid('AttributesWidget', ['container'])} />
      </WidgetWrapper>
    </Root>
  );
});
