import { userPreferencesService } from '../../services/userPreferencesService';
import { DataCatalogObjectType, DataCatalogRecord } from './DataCatalogService';

const LIMIT = 10;

export type RecentlyViewedCatalogItem = Pick<DataCatalogRecord, 'fullyQualifiedName' | 'objectName'> & {
  objectType: DataCatalogObjectType;
  dataSourceName: string;
  dataSourceType: string;
  lastViewedDate?: string;
};

export type DataCatalogObjectPreferences = {
  recentlyViewedItems: RecentlyViewedCatalogItem[];
};

const DATA_CATALOG_OBJECT_PREFERENCES_NAME = 'dataCatalogObjects';

export const setRecentlyViewedCatalogItem = async (catalogDetails: RecentlyViewedCatalogItem) => {
  try {
    const preferences = await userPreferencesService.get<DataCatalogObjectPreferences>(
      DATA_CATALOG_OBJECT_PREFERENCES_NAME,
    );
    if (preferences) {
      // 1. Create a new array of recently viewed items, with the new item at the top
      // 2. Remove the new item from the array if it already exists
      // 3. Take only the first 10 items
      const baseItems = preferences.data?.recentlyViewedItems ?? [];
      const firstTenItems = baseItems.slice(0, LIMIT);
      const filteredItems = firstTenItems.filter(item => item.fullyQualifiedName !== catalogDetails.fullyQualifiedName);
      const recentlyViewedCatalogItems = [catalogDetails, ...filteredItems];

      await userPreferencesService.update<DataCatalogObjectPreferences>({
        ...preferences,
        data: {
          recentlyViewedItems: recentlyViewedCatalogItems,
        },
      });
      return;
    }

    await userPreferencesService.add<DataCatalogObjectPreferences>({
      preference: DATA_CATALOG_OBJECT_PREFERENCES_NAME,
      data: {
        recentlyViewedItems: [catalogDetails],
      },
    });
  } catch (error) {
    console.error(`An error has occurred: ${error}`);
  }
};

export const getRecentlyViewedCatalogItems = async () => {
  try {
    const response = await userPreferencesService.get<DataCatalogObjectPreferences>(
      DATA_CATALOG_OBJECT_PREFERENCES_NAME,
    );

    if (!response) {
      throw new Error('Unable to retrieve recently viewed catalog items');
    }

    return response;
  } catch (error) {
    console.error(`An error has occurred: ${error}`);
    return {
      preference: '',
      data: {
        recentlyViewedItems: [],
      },
    };
  }
};
