import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { BigidBody1, BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataDuplicationCharts } from './charts/DataDuplicationCharts';
import { DiscoveryDashboardContext } from '../DiscoveryDashboardContext';
import { BigidInfoIcon, BigidNoDataIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import {
  DataDuplicationWidgetProps,
  DataDuplicationToggleOption,
  DataDuplicationDataPoint,
} from './types/DataDuplicationWidgetTypes';
import { requestDashboardData } from '../discoveryDashboardServices';
import { DashboardSubType, WidgetName } from '../consts';
import { getToggleBiEvent } from '../utils';
import { ReportWidgets } from '../types';

export const DataDuplicationWidget: FC<DataDuplicationWidgetProps> = ({ title, onToggle }) => {
  const [selectedView, setSelectedView] = useState<DataDuplicationToggleOption>(DataDuplicationToggleOption.VOLUME);
  const [dataDuplicationData, setDataDuplicationData] = useState<DataDuplicationDataPoint[] | null>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmptyWidgetData, setIsEmptyWidgetData] = useState<boolean>(false);

  const { isEmptySystem, activeFilters } = useContext(DiscoveryDashboardContext);

  const { t } = useLocalTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await requestDashboardData(
          DashboardSubType.DATA_DUPLICATION,
          WidgetName.DATA_DUPLICATION,
          activeFilters,
        );
        if (response?.data?.dashboardData) {
          setDataDuplicationData(response.data.dashboardData as DataDuplicationDataPoint[]);
          setIsEmptyWidgetData(false);
        } else {
          setIsEmptyWidgetData(true);
        }
      } catch {
        setDataDuplicationData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeFilters]);

  const processData = (data: DataDuplicationDataPoint[] | null) => {
    if (!data || data.length === 0) return { volumeData: [], amountData: [], latestEntry: null };

    const volumeData = data.map(({ category, volume }) => ({ category, volume }));
    const amountData = data.map(({ category, amount }) => ({ category, amount }));
    const latestEntry = data[data.length - 1];

    return { volumeData, amountData, latestEntry };
  };

  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    const valueTyped = value as DataDuplicationToggleOption;
    setSelectedView(valueTyped);
    onToggle?.(valueTyped);
  };

  const { volumeData, amountData, latestEntry } = useMemo(
    () => processData(dataDuplicationData),
    [dataDuplicationData],
  );

  const chartData = useMemo(
    () => (selectedView === DataDuplicationToggleOption.VOLUME ? volumeData : amountData),
    [selectedView, volumeData, amountData],
  );

  const renderActions = !isEmptySystem && (
    <BigidToggleButton
      onChange={onToggleChange}
      initialSelected={DataDuplicationToggleOption.VOLUME}
      size="small"
      toggleButtons={[
        {
          value: DataDuplicationToggleOption.VOLUME,
          label: t(`dataDuplicationWidget.toggleOptions.${DataDuplicationToggleOption.VOLUME}`),
          bi: getToggleBiEvent(DataDuplicationToggleOption.VOLUME, ReportWidgets.DUPLICATION),
        },
        {
          value: DataDuplicationToggleOption.AMOUNT,
          label: t(`dataDuplicationWidget.toggleOptions.${DataDuplicationToggleOption.AMOUNT}`),
          bi: getToggleBiEvent(DataDuplicationToggleOption.AMOUNT, ReportWidgets.DUPLICATION),
        },
      ]}
    />
  );

  return (
    <BigidWidgetContainer
      content={
        <DataDuplicationCharts
          selectedView={selectedView}
          data={chartData}
          isLoading={isLoading}
          latestEntry={latestEntry}
        />
      }
      titleConfig={{
        title,
        iconAfter: {
          icon: BigidInfoIcon,
          tooltip: (
            <>
              <BigidBody1 display="inline" fontWeight="bold">
                {title}
              </BigidBody1>
              <BigidBody1 display="inline">{t('dataDuplicationWidget.tooltip')}</BigidBody1>{' '}
            </>
          ),
        },
      }}
      actionsSlot={renderActions}
      emptyStateConfig={{
        isEmpty: isEmptySystem || isEmptyWidgetData,
        showEmptyStateWithFilter: isEmptyWidgetData,
        illustration: BigidNoDataIllustration,
        description: <BigidBody1>{t('emptyStateWidget.noData')}</BigidBody1>,
      }}
      contentHeight={'300px'}
    />
  );
};
