import React, { FC, useContext, useEffect, useState } from 'react';
import { BigidBody1, BigidColorsV2, BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataRiskCharts } from './charts/DataRiskCharts';
import { DiscoveryDashboardContext } from '../DiscoveryDashboardContext';
import { BigidInfoIcon, BigidNoDataIllustration } from '@bigid-ui/icons';
import { DataRiskDataPoint, DataRiskWidgetData, ProgressChartData, ReportWidgets } from '../types';
import { getFixedT, useLocalTranslation } from '../translations';
import { requestDashboardData } from '../discoveryDashboardServices';
import { DashboardSubType, getInitialProgressChartData, WidgetName } from '../consts';
import { getToggleBiEvent } from '../utils';

export enum DataRiskToggleOption {
  FINDINGS = 'findings',
  OBJECTS = 'objects',
}

const INITIAL_TOGGLE_VALUE = DataRiskToggleOption.OBJECTS;

export interface DataRiskWidgetProps {
  title: string;
  onToggle?: (value: DataRiskToggleOption) => void;
}

const transformDataPointsToProgressChart = (
  dataPoints: DataRiskDataPoint[],
  toggleValue: DataRiskToggleOption,
): ProgressChartData => {
  if (!dataPoints?.length) {
    return {
      total: 0,
      data: [],
    };
  }
  const t = getFixedT('dataRiskWidget');
  const lastDataPoint = dataPoints[dataPoints.length - 1];
  if (toggleValue === DataRiskToggleOption.OBJECTS) {
    return {
      total: lastDataPoint.scanned,
      data: [
        {
          category: t('discovered'),
          value: lastDataPoint.discovered,
          isActive: true,
          color: BigidColorsV2.magenta[300],
        },
      ],
    };
  }
  return {
    total: lastDataPoint.discovered,
    data: [],
  };
};

export const DataRiskWidget: FC<DataRiskWidgetProps> = ({ title, onToggle }) => {
  const [viewByOption, setViewByOption] = useState<DataRiskToggleOption>(INITIAL_TOGGLE_VALUE);
  const [dataRiskData, setDataRiskData] = useState<DataRiskWidgetData>(null);
  const [areaChartData, setAreaChartData] = useState<DataRiskDataPoint[]>([]);
  const [progressChartData, setProgressChartData] = useState<ProgressChartData>(getInitialProgressChartData());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmptyWidgetData, setIsEmptyWidgetData] = useState<boolean>(false);

  const { isEmptySystem, activeFilters } = useContext(DiscoveryDashboardContext);

  const { t } = useLocalTranslation();

  useEffect(() => {
    const fetchWidgetData = async () => {
      try {
        setIsLoading(true);
        const response = await requestDashboardData(DashboardSubType.DATA_RISK, WidgetName.DATA_RISK, activeFilters);
        if (response?.data?.dashboardData) {
          setDataRiskData(response.data.dashboardData as DataRiskWidgetData);
          setIsEmptyWidgetData(false);
        } else {
          setIsEmptyWidgetData(true);
        }
      } catch (e) {
        setAreaChartData([]);
        setProgressChartData(getInitialProgressChartData());
      } finally {
        setIsLoading(false);
      }
    };

    fetchWidgetData();
  }, [activeFilters]);

  useEffect(() => {
    if (dataRiskData) {
      const dataPoints = dataRiskData[viewByOption]?.dataPoints;
      setAreaChartData(dataPoints);
      const progressData: ProgressChartData = transformDataPointsToProgressChart(dataPoints, viewByOption);
      setProgressChartData(progressData);
      setIsLoading(false);
    }
  }, [dataRiskData]);

  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string): void => {
    if (value === viewByOption) {
      return;
    }
    const valueTyped = value as DataRiskToggleOption;
    const dataPoints = dataRiskData?.[valueTyped]?.dataPoints ?? [];
    setViewByOption(valueTyped);
    setAreaChartData(dataPoints);
    const progressData: ProgressChartData = transformDataPointsToProgressChart(dataPoints, valueTyped);
    setProgressChartData(progressData);
    onToggle?.(valueTyped);
  };

  return (
    <BigidWidgetContainer
      content={
        <DataRiskCharts
          areaChart={areaChartData}
          progressChart={progressChartData}
          isLoading={isLoading}
          viewByOption={viewByOption}
        />
      }
      titleConfig={{
        title,
        iconAfter: {
          icon: BigidInfoIcon,
          tooltip: (
            <>
              <BigidBody1 display="inline" fontWeight="bold">
                {title}
              </BigidBody1>
              <BigidBody1 display="inline">{t('dataRiskWidget.tooltip')}</BigidBody1>{' '}
            </>
          ),
        },
      }}
      actionsSlot={
        <>
          {!isEmptySystem && (
            <BigidToggleButton
              onChange={onToggleChange}
              initialSelected={INITIAL_TOGGLE_VALUE}
              size="small"
              toggleButtons={[
                {
                  value: 'findings',
                  label: t('dataRiskWidget.findings'),
                  bi: getToggleBiEvent('findings', ReportWidgets.RISKS),
                },
                {
                  value: 'objects',
                  label: t('dataRiskWidget.objects'),
                  bi: getToggleBiEvent('objects', ReportWidgets.RISKS),
                },
              ]}
            />
          )}
        </>
      }
      emptyStateConfig={{
        isEmpty: isEmptySystem || isEmptyWidgetData,
        showEmptyStateWithFilter: isEmptyWidgetData,
        illustration: BigidNoDataIllustration,
        description: <BigidBody1>{t('emptyStateWidget.noData')}</BigidBody1>,
      }}
    />
  );
};
