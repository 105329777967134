import React, { FC } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { EaFeatureItemData, EaProgramFeature } from '../eaProgramModalService';
import { BigidBody1, BigidCheckbox, BigidLink } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

const EA_PROGRAM_FEATURE_ITEM = 'EaProgramFeatureItem';

const MainContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  height: 130px;
`;

const Image = styled('img')`
  width: 220px;
  padding-right: 24px;
`;

const TextSection = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-right: 12px;
  height: 100%;
  justify-content: space-between;
`;

const LearnMoreItem = styled('div')`
  display: flex;
  flex-direction: row-reverse;
`;

const CheckBoxContainer = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

export interface EaFeatureItemProps extends EaFeatureItemData {
  isSelected: boolean;
  onFeatureSelectionChange: (featureId: EaProgramFeature, isSelected: boolean) => void;
}

export const EaFeatureItem: FC<EaFeatureItemProps> = ({
  id,
  imageSource,
  title,
  text,
  isSelected,
  learnMoreUrl,
  onFeatureSelectionChange,
}) => {
  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onFeatureSelectionChange(id, checked);
  };
  const { t } = useLocalTranslation();

  return (
    <MainContainer data-aid={generateDataAid(EA_PROGRAM_FEATURE_ITEM, ['container'])}>
      <CheckBoxContainer>
        <BigidCheckbox
          dataAid={generateDataAid(EA_PROGRAM_FEATURE_ITEM, ['checkbox', id])}
          onChange={onCheckboxChange}
          checked={isSelected}
        />
      </CheckBoxContainer>
      <Image src={imageSource} />
      <TextSection>
        <div>
          <BigidBody1 fontWeight="bold">{title}</BigidBody1>
          <BigidBody1>{text}</BigidBody1>
        </div>
        <LearnMoreItem>
          <BigidLink underline="none" href={learnMoreUrl} shouldOpenNewTab={true} text={t('learnMoreLink')} />
        </LearnMoreItem>
      </TextSection>
    </MainContainer>
  );
};
