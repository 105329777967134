import {
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterUnion,
  BigidLoader,
  SelectedOptionsConfig,
  advancedToolbarFilterMinifier,
  AdvancedToolbarOverrideValueMinified,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { notificationService } from '../../../../../../services/notificationService';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { useLocalTranslation } from '../../../../translations';
import { WithDataAid } from '../../../../types';
import { fetchEntityFilters } from './fetchFilters';
import { $state } from '../../../../../../services/angularServices';
import {
  DataExplorerSearchFilterSelectBiPayload,
  sendDataExplorerResultsPageFilterSelectBiAnalytics,
} from '../../../../../DataExplorer/services/analytics';
import { mapDropdownFiltersToFilterString } from '../../../../../../utilities/toolbarFilterToBIQL/toolbarFilterToBIQL';

export const EntityFiltersToolbar: FC<WithDataAid> = ({ dataAid = 'EntityFiltersToolbar' }) => {
  const { t } = useLocalTranslation();
  const { dispatchCatalogSearchAction, query, entityType, state, additionalBiqlFilter } =
    useDataCatalogSearchResultsContext();

  const { data, isLoading, isError } = useQuery(
    ['EntityFiltersToolbar', entityType],
    () => fetchEntityFilters(entityType),
    {
      onError: () => {
        notificationService.error(t('errors.generic.message'));
      },
      cacheTime: 30 * 60 * 1000,
    },
  );

  const handleFiltersChange = useCallback(
    (selectedFilters: BigidAdvancedToolbarFilterUnion[]) => {
      const overrideValues = selectedFilters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue);
      const minifiedFilters = overrideValues.map(advancedToolbarFilterMinifier.getOverrideValueMinified);
      const filter = mapDropdownFiltersToFilterString(selectedFilters);

      dispatchCatalogSearchAction({
        type: 'UPDATE_SAVED_FILTERS_MAP',
        payload: {
          entityType,
          filter,
          overrideValues,
        },
      });

      $state.go($state.$current.name, { filters: selectedFilters.length > 0 ? JSON.stringify(minifiedFilters) : null });

      const biPayload: DataExplorerSearchFilterSelectBiPayload = {
        filter: overrideValues,
      };

      sendDataExplorerResultsPageFilterSelectBiAnalytics(biPayload);
    },
    [dispatchCatalogSearchAction, entityType],
  );

  const filters = isError ? [] : data;
  const overrideValue = state.overrideValuesMap[entityType];

  const overrideConfig: SelectedOptionsConfig = useMemo(
    () => ({
      strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
      values: overrideValue,
    }),
    [],
  );

  return isLoading ? (
    <BigidLoader position="relative" size={20} />
  ) : (
    filters.length > 0 && (
      <BigidAdvancedToolbarFilter
        dataAid={generateDataAid(dataAid, ['toolbar'])}
        filters={filters}
        selectedOptionsConfig={overrideConfig}
        onFiltersChange={handleFiltersChange}
        filterSearchMetadata={{
          searchText: query,
          additionalBiqlFilter,
        }}
      />
    )
  );
};
