import React, { FC, useMemo, useState } from 'react';

import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidColorsV2,
  BigidToggleButton,
  BigidWidgetContainer,
  BigidWorldMap,
  BigidWorldMapImageSeries,
  BigidWorldMapSeriesCategory,
} from '@bigid-ui/components';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

import { CountryDefinition, getCopyOfCountries } from '../../../../config/countries';
import { useLocalTranslation } from '../translations';
import {
  WidgetProps,
  PrivacyMapWidgetData,
  PrivacyMapWidgetApiData,
  PrivacyMapCategories,
} from '../PrivacyExecutiveDashboardTypes';
import { Menu } from './helpers/HelperComponents';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { snakeCase, upperFirst } from 'lodash';

const TABS = Object.values(PrivacyMapCategories).map(category => ({
  label: category,
  value: category,
}));

export const PrivacyMapWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const [currentTab, setCurrentTab] = useState(0);
  const { data, isLoading } = useFetchWidgetData(fetchPrivacyMapWidgetData);
  const imageData = data?.[TABS[currentTab].label] || [];
  const categories = categoryColorMapper[TABS[currentTab].label];

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <>
      <BigidWidgetContainer
        content={
          <BigidWorldMap
            chartId="PrivacyMapId"
            height={416}
            imageData={imageData}
            legendConfig={{
              filters: [],
              isCollapsed: false,
              isHidden: false,
              isInteractive: true,
            }}
            lineData={[]}
            mapConfig={{
              categories,
              isMapContainerOutlined: true,
              isMapSeriesGroupedIntoClusters: true,
              isMultipleSelectionEnabled: true,
              defaultZoomLevel: 2,
            }}
          />
        }
        titleConfig={{
          title,
        }}
        actionsSlot={
          <>
            <BigidToggleButton
              size="small"
              toggleButtons={TABS}
              initialSelected={TABS[currentTab].value}
              onChange={(_, newValue) => setCurrentTab(TABS.findIndex(tab => newValue === tab.value))}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: imageData?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
      />
    </>
  );
};

const fetchPrivacyMapWidgetData = (activeFilters: BigidAdvancedToolbarFilterUnion[]): Promise<PrivacyMapWidgetData> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 1000, attachAdditionalWidgetProps(data));
  });
};

type Coordinates = CountryDefinition['points'][0];
type CountryPointsMapper = Record<string, Coordinates>;

/**
 * The world map widget doesn't support multiple categories
 * from a single location. For example if we have Assets and Vendors
 * from the same location - e.g. Israel - the map shows only one of
 * the categories.
 *
 * To solve this issue we shift each additional category either in
 * latitude or longitude or both with 1 degree, so that all categories are shown on the map.
 */

const coordinatesShiftMapper: number[][] = [
  [0, 0], // the first category keeps its coordinates intact
  [0, -1], // the second category is shifted 1 degree in longitude
  [-1, 0], // the third category is shifted 1 degree in latitude
  [-1, -1], // the fourth category is shifted 1 degree both in latitude and longitude
];

const shiftPoints = (points: Coordinates, index: number) => {
  const [latitudeShift = 0, longitudeShift = 0] = coordinatesShiftMapper[index];
  return {
    latitude: points.latitude + latitudeShift,
    longitude: points.longitude + longitudeShift,
  };
};

const attachAdditionalWidgetProps = (data: PrivacyMapWidgetApiData[]) => {
  const countryPointsMapper = getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS')).reduce(
    (acc, country) => {
      acc[country.displayName] = country.points[0];
      return acc;
    },
    {} as CountryPointsMapper,
  );
  return data.reduce((acc, item) => {
    const itemCategoriesMapper = [...new Set(item.data.map(item => item.category))].reduce(
      (acc, category, i) => ((acc[category] = i), acc),
      {} as Record<string, number>,
    );
    acc[item.category] = item.data.map((item, i) => ({
      id: `${item.category}_${item.location}_${i}`,
      category: item.category,
      value: item.count,
      name: upperFirst(item.category),
      tooltipContent: `<strong style={{ color: 'purple' }}>{name}: {value}</strong>`,
      ...shiftPoints(countryPointsMapper[item.location], itemCategoriesMapper[item.category]),
    }));
    return acc;
  }, {} as PrivacyMapWidgetData);
};

const getClusterTooltipContent = (images: BigidWorldMapImageSeries[]) => {
  const totalComputed = images.reduce((total, image) => total + (image?.value || 0), 0);

  return `<strong>Total: ${totalComputed}</strong>`;
};

const categoryColorMapper: Record<PrivacyMapCategories, BigidWorldMapSeriesCategory[]> = {
  [PrivacyMapCategories.DATA_MAPPING]: [
    {
      displayName: 'Vendors',
      fillColor: BigidColorsV2.blue[400],
      name: 'vendors',
      strokeColor: BigidColorsV2.blue[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Data sources',
      fillColor: BigidColorsV2.magenta[400],
      name: 'data_sources',
      strokeColor: BigidColorsV2.magenta[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Assets',
      fillColor: BigidColorsV2.purple[400],
      name: 'assets',
      strokeColor: BigidColorsV2.purple[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Legal entities',
      fillColor: BigidColorsV2.cyan[400],
      name: 'legal_entities',
      strokeColor: BigidColorsV2.cyan[600],
      getClusterTooltipContent,
    },
  ],
  [PrivacyMapCategories.RESIDENCIES]: [
    {
      displayName: PrivacyMapCategories.RESIDENCIES,
      fillColor: BigidColorsV2.blue[300],
      name: snakeCase(PrivacyMapCategories.RESIDENCIES),
      strokeColor: BigidColorsV2.blue[500],
      getClusterTooltipContent,
    },
  ],
  [PrivacyMapCategories.RISKS]: [],
  [PrivacyMapCategories.REQUESTS]: [
    {
      displayName: 'View',
      fillColor: BigidColorsV2.blue[400],
      name: 'view',
      strokeColor: BigidColorsV2.blue[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Edit',
      fillColor: BigidColorsV2.magenta[400],
      name: 'edit',
      strokeColor: BigidColorsV2.magenta[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Delete',
      fillColor: BigidColorsV2.purple[400],
      name: 'delete',
      strokeColor: BigidColorsV2.purple[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Appeal',
      fillColor: BigidColorsV2.cyan[400],
      name: 'appeal',
      strokeColor: BigidColorsV2.cyan[600],
      getClusterTooltipContent,
    },
  ],
};

const data = [
  {
    category: 'Data mapping',
    data: [
      {
        category: 'vendors',
        location: 'Israel',
        count: 5,
      },
      {
        category: 'vendors',
        location: 'Israel',
        count: 5,
      },
      {
        category: 'vendors',
        location: 'Israel',
        count: 5,
      },
      {
        category: 'legal_entities',
        location: 'Israel',
        count: 2,
      },
      {
        category: 'data_sources',
        location: 'Israel',
        count: 11,
      },
      {
        category: 'assets',
        location: 'Israel',
        count: 8,
      },
      {
        category: 'legal_entities',
        location: 'Germany',
        count: 2,
      },
      {
        category: 'data_sources',
        location: 'Italy',
        count: 11,
      },
      {
        category: 'assets',
        location: 'Italy',
        count: 8,
      },
    ],
  },
  {
    category: 'Residencies',
    data: [
      {
        category: 'residencies',
        location: 'Israel',
        count: 5,
      },
      {
        category: 'residencies',
        location: 'Germany',
        count: 2,
      },
      {
        category: 'residencies',
        location: 'Italy',
        count: 11,
      },
      {
        category: 'residencies',
        location: 'Italy',
        count: 8,
      },
    ],
  },
  {
    category: 'Risks',
    data: [
      {
        category: 'vendors',
        location: 'Israel',
        count: 5,
      },
      {
        category: 'legal_entities',
        location: 'Germany',
        count: 2,
      },
      {
        category: 'data_sources',
        location: 'Italy',
        count: 11,
      },
      {
        category: 'assets',
        location: 'Italy',
        count: 8,
      },
    ],
  },
  {
    category: 'Requests',
    data: [
      {
        category: 'view',
        location: 'Israel',
        count: 5,
      },
      {
        category: 'edit',
        location: 'Germany',
        count: 2,
      },
      {
        category: 'delete',
        location: 'Italy',
        count: 11,
      },
      {
        category: 'appeal',
        location: 'Italy',
        count: 8,
      },
    ],
  },
];
