import React from 'react';
import { EntitySection } from './EntitySection/EntitySection';
import { noop, take } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { RecentlySearchedItem } from '../types';
import { RecentlySearchedListItem } from './EntitySection/ListItems/RecentlySearchedListItem';

type RecentlySearchedItemsProps = {
  handleChildClick: () => void;
  recentlySearchedItems: RecentlySearchedItem[];
};
export const RecentlySearchedResults = ({
  recentlySearchedItems = [],
  handleChildClick,
}: RecentlySearchedItemsProps) => {
  if (!recentlySearchedItems.length) return null;

  const list = take(recentlySearchedItems, 5).map((recentlySearchedItem, index) => {
    return (
      <RecentlySearchedListItem
        onListItemClick={handleChildClick}
        recentlySearchedItem={recentlySearchedItem}
        key={recentlySearchedItem.created_at}
        dataAid={generateDataAid('RecentlySearchedListItem', [index])}
      />
    );
  });

  return <EntitySection listItems={list} handleShowAllClick={noop} sectionName="recentlySearched" />;
};
