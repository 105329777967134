import React, { useCallback, useState } from 'react';
import { TopFailedControlsWidget, TopFailedWidgetResponse } from './TopFailedControlsWidget/TopFailedControlsWidget';
import { TopRiskDataResponse, TopRiskWidget } from './TopRiskWidget/TopRiskWidget';
import { DataSourceWidget, DataSourceWidgetResponse } from './DataSourceWidget/DataSourceWidget';
import { FailedControlsWidget } from './FailedControlsWidget/FailedControlsWidget';
import { ContentWrapper, WidgetsSection } from './WidgetsStyles';
import { generateDataAid } from '@bigid-ui/utils';
import { ComplianceDashboardWidget } from '../ComplianceDashboard';
import { BigidLoader } from '@bigid-ui/components';
import { CarouselDataProps } from '../../../../components/Carousel/Carousel';
import { IndexStatus } from './IndexStatus';
import { WidgetName, WidgetSubtype } from '../../services/dashboardService';

export enum MenuActions {
  OPEN_SECURITY_POSTURE = 'Open in Security Posture',
  ADD_FILTERS = 'Add to filters',
}

interface WidgetsContainerProps {
  activeWidgetIds: string[];
  dataAid?: string;
}

export interface WidgetResponseProps {
  dashboardData: ComplianceResponseProps;
}

export interface ComplianceResponseProps {
  frameworks?: CarouselDataProps[];
  controls?: TopFailedWidgetResponse[];
  policies?: TopRiskDataResponse[];
  dataSourceTypes?: DataSourceWidgetResponse[];
  grid_unique_value_filter?: string[];
}

export interface Paging {
  limit: number;
  skip: number;
}

export interface WidgetRequestData {
  subType: WidgetSubtype;
  widgetName: WidgetName;
  filter?: string;
  paging?: Paging;
  gridFieldName?: string;
}

export const WidgetsContainer = ({ dataAid = 'complianceDashboard', activeWidgetIds }: WidgetsContainerProps) => {
  const [isRedirectLoading, setisRedirectLoading] = useState<boolean>(false);
  const isActiveWidget = (widgetId: ComplianceDashboardWidget) => !!activeWidgetIds.includes(widgetId);
  const widgetSectionAmount = [
    isActiveWidget(ComplianceDashboardWidget.TOP_FAILED),
    isActiveWidget(ComplianceDashboardWidget.TOP_RISK),
  ].filter((value: boolean) => value);

  const handleLoader = useCallback((value: boolean) => {
    setisRedirectLoading(value);
  }, []);

  return (
    <>
      {isRedirectLoading ? (
        <BigidLoader />
      ) : (
        <ContentWrapper data-aid={generateDataAid(dataAid, ['container'])}>
          {isActiveWidget(ComplianceDashboardWidget.FAILED_CONTROLS) && (
            <FailedControlsWidget
              dataAid={generateDataAid(dataAid, ['failedControls', 'widget'])}
              handleLoader={handleLoader}
            />
          )}
          <WidgetsSection amountOfWidgets={widgetSectionAmount.length} isHidden={widgetSectionAmount.length === 0}>
            {isActiveWidget(ComplianceDashboardWidget.TOP_FAILED) && (
              <TopFailedControlsWidget
                dataAid={generateDataAid(dataAid, ['topFailed', 'widget'])}
                handleLoader={handleLoader}
              />
            )}
            {isActiveWidget(ComplianceDashboardWidget.TOP_RISK) && (
              <TopRiskWidget dataAid={generateDataAid(dataAid, ['topRisk', 'widget'])} />
            )}
          </WidgetsSection>
          {isActiveWidget(ComplianceDashboardWidget.DATA_SOURCE) && (
            <DataSourceWidget dataAid={generateDataAid(dataAid, ['dataSource', 'widget'])} />
          )}
          <IndexStatus handleLoader={handleLoader} />
        </ContentWrapper>
      )}
    </>
  );
};
