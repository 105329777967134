import React, { FC, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidCaption, BigidColorsV2, BigidLoader, BigidPaper } from '@bigid-ui/components';
import { dateTimeService } from '@bigid-ui/i18n';
import { useCatalogRuleApply } from '../CatalogRulesAsyncOps/operations/useCatalogRuleApply';
import { DataCatalogAsyncOperationStatus } from '../../DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { CatalogRule } from '../catalogRulesService';
import { DateISO8601 } from '../../../types/types';
import { notificationService } from '../../../services/notificationService';
import { max } from 'lodash';

interface CatalogRuleApplyProgressProps {
  dataAid?: string;
  isApplied?: boolean;
  rule: CatalogRule;
  onComplete: (rule: Pick<CatalogRule, 'id' | 'updatedAt'>) => void;
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 5px',
  },
  title: {
    color: BigidColorsV2.purple[400],
  },
  description: {
    display: 'flex',
  },
  loader: {
    position: 'relative',
  },
  progress: {
    display: 'flex',
    width: '86px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  percentage: {
    color: BigidColorsV2.gray[600],
  },
  lastApplied: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const getDisplayLastApplied = (rule: CatalogRule): DateISO8601 => {
  let displayDate;
  const datesToCompare: number[] = [];
  const { lastAppliedDate, lastAppliedAllDate } = rule;

  if (lastAppliedDate) {
    datesToCompare.push(new Date(lastAppliedDate).getTime());
  }

  if (lastAppliedAllDate) {
    datesToCompare.push(new Date(lastAppliedAllDate).getTime());
  }

  if (datesToCompare.length > 0) {
    displayDate = dateTimeService.formatDate(max(datesToCompare));
  }

  return displayDate;
};

export const CatalogRuleApplyProgress: FC<CatalogRuleApplyProgressProps> = ({
  dataAid = 'CatalogRuleApplyProgress',
  rule,
  isApplied,
  onComplete,
}) => {
  const classes = useStyles();

  const [percentage, setPercentage] = useState<number>(null);
  const [isOperationInProgress, setIsOperationInProgress] = useState<boolean>(false);

  const { operation } = useCatalogRuleApply({ sseRoutingKey: rule?.sseRoutingKey });

  useEffect(() => {
    if (operation) {
      const { id, status, percentage, updatedAt } = operation;

      switch (true) {
        case status === DataCatalogAsyncOperationStatus.RUNNING:
          setIsOperationInProgress(true);
          setPercentage(Math.round(percentage));
          break;
        case status === DataCatalogAsyncOperationStatus.COMPLETED:
          setIsOperationInProgress(false);
          setPercentage(100);
          onComplete({ id, updatedAt });
          break;
        case status === DataCatalogAsyncOperationStatus.ERROR:
          const notificationMessage = `An error has occurred while applying ${rule.name} rule.`;

          notificationService.error(notificationMessage);
          console.error(notificationMessage);
          break;
      }
    } else {
      setIsOperationInProgress(false);
    }
  }, [onComplete, operation, rule.name]);

  useEffect(() => {
    if (isApplied) {
      setIsOperationInProgress(true);
      setPercentage(0);
    }
  }, [isApplied]);

  const lastApplied = getDisplayLastApplied(rule);

  return (
    <BigidPaper classes={{ root: classes.root }} dataAid={dataAid}>
      {isOperationInProgress ? (
        <div className={classes.progress}>
          {percentage !== 100 && (
            <div className={classes.loader}>
              <BigidLoader size={10} />
            </div>
          )}
          <BigidCaption className={classes.percentage}>{isNaN(percentage) ? '...' : percentage}% applied</BigidCaption>
        </div>
      ) : (
        lastApplied && (
          <div className={classes.lastApplied}>
            <BigidCaption>Last applied</BigidCaption>
            <BigidCaption>{lastApplied}</BigidCaption>
          </div>
        )
      )}
    </BigidPaper>
  );
};
