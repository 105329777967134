import { useState, useEffect, useCallback } from 'react';
import type { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';

import { usePrivacyExecutiveDashboardContext } from '../../PrivacyExecutiveDashboardContext';
import { SeverityColorMapper } from '../../PrivacyExecutiveDashboardTypes';

export function useFetchWidgetData<T>(
  fetcherFunction: (
    activeFilters: BigidAdvancedToolbarFilterUnion[],
    severityColorMapper?: SeverityColorMapper,
  ) => Promise<T>,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);
  const { activeFilters, severityColorMapper } = usePrivacyExecutiveDashboardContext();

  const fetchData = useCallback(async () => {
    if (!severityColorMapper) return;

    setIsLoading(true);
    setError(null);
    try {
      const result = await fetcherFunction(activeFilters, severityColorMapper);
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [activeFilters, severityColorMapper, fetcherFunction]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, error, severityColorMapper, fetchData };
}
