import { BigidAreaChart } from '@bigid-ui/visualisation';
import { BigidColorsV2, capitalizeFirstLetter } from '@bigid-ui/components';
import React, { FC } from 'react';
import { DataRiskDataPoint } from '../../types';

export const DataRiskAreaChart: FC<{ areaChart: DataRiskDataPoint[] }> = ({ areaChart = [] }) => {
  return (
    <BigidAreaChart
      colorDataMap={{ scanned: BigidColorsV2.purple[600], discovered: BigidColorsV2.magenta[300] }}
      isLineSmoothed={true}
      numberFormat="#.A"
      data={areaChart}
      seriesLabelFormatter={series => capitalizeFirstLetter(series)}
      showChartCursor={true}
    />
  );
};
