import React, { FC, memo, useContext } from 'react';
import { styled } from '@mui/material';
import { useQuery } from 'react-query';
import { useLocalTranslation } from '../../../DataSourceConnectionModal/translations';

import { DataSourceTestConnectionStatusEnum } from '../../hooks/useTestConnection';
import { DataSourceConfigurationContext } from '../../DataSourceConfigurationContext';
import { MainContentConnectedStatus } from './ConnectedStatus/MainContentConnectedStatus';
import { getTestConnectionSSEStatus } from '../../../../../services/dataSourcesService';
import { DataSourceConnectionOverlayInProgress } from '../../../DataSourceConnectionModal/DataSourceConnectionOverlay/DataSourceConnectionOverlayInProgress';
import { MainContentConnectionError } from './ConnectionError/MainContentConnectionError';
import { MainContentDraftStatus } from './DraftStatus/MainContentDraftStatus';

const OverlayWrapper = styled('div')`
  margin: auto;
`;

const connectionStatusToComponenet = ({
  onTestAgain,
  handleEditConnection,
}: MainStepConnectionTabProps): Record<DataSourceTestConnectionStatusEnum, JSX.Element> => ({
  [DataSourceTestConnectionStatusEnum.success]: <MainContentConnectedStatus onTestAgain={onTestAgain} />,
  [DataSourceTestConnectionStatusEnum.failed]: (
    <MainContentConnectionError onTestAgain={onTestAgain} onViewDetails={handleEditConnection} />
  ),
  [DataSourceTestConnectionStatusEnum.partialSuccess]: (
    <MainContentConnectionError onTestAgain={onTestAgain} onViewDetails={handleEditConnection} />
  ),
  [DataSourceTestConnectionStatusEnum.notStarted]: <MainContentDraftStatus onConnect={handleEditConnection} />,
});

export interface MainStepConnectionTabProps {
  onTestAgain: () => void;
  addListenerToTestConnectionEvent?: (sseEventId: string) => void;
  handleEditConnection: () => Promise<void>;
}

export const MainStepConnectionTab: FC<MainStepConnectionTabProps> = memo(props => {
  const { testStatus, id, testInProgress } = useContext(DataSourceConfigurationContext);
  const mainContent = connectionStatusToComponenet({ ...props })[testStatus];
  const { t } = useLocalTranslation();
  useQuery(['connectionTabTestInProgress', id], () => getTestConnectionSSEStatus(id), {
    onSuccess: data => {
      const { seeEventID, isInProgress } = data;
      isInProgress && props.addListenerToTestConnectionEvent(seeEventID);
    },
  });

  return testInProgress ? (
    <OverlayWrapper>
      <DataSourceConnectionOverlayInProgress
        dataAid="DataSourceTestingOverlayInProgress"
        headerText={t('connectionInProgressOverlay.testing')}
      />
    </OverlayWrapper>
  ) : (
    mainContent
  );
});
