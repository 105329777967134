import React from 'react';
import { RecentlySearchedItem } from '../../../types';
import { sendDataExplorerQuickSearchListItemClickBiAnalytics } from '../../../../services/analytics';
import { $state } from '../../../../../../services/angularServices';
import { CONFIG } from '../../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { BigidFilterIcon, BigidQueryLanguageIcon } from '@bigid-ui/icons';
import { ListItemContainer } from './GenericListItem';

const getSearchTypeIcon = (type: string) => {
  switch (type) {
    case 'filterBuilder':
      return BigidFilterIcon;

    case 'queryFilter':
      return BigidQueryLanguageIcon;

    default: {
      return () => <></>;
    }
  }
};

const getPayloadFromRecentlySearch = ({ entityName, searchValue, savedFilters, type }: RecentlySearchedItem) => {
  const payload: Record<string, unknown> = {
    activeTab: entityName,
  };

  switch (type) {
    case 'filterBuilder':
      payload.queryMode = 'searchText';
      payload.selectedFiltersConfig = savedFilters;
      break;

    case 'queryFilter':
      payload.queryMode = 'queryFilter';
      payload.biqlFilter = searchValue;
      break;

    default:
      payload.query = searchValue;
      payload.queryMode = 'searchText';
  }

  return payload;
};

const Content = styled('div')`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
`;

type RecentlySearchedListItemProps = {
  recentlySearchedItem: RecentlySearchedItem;
  onListItemClick: () => void;
  dataAid?: string;
};

export const RecentlySearchedListItem = ({
  recentlySearchedItem,
  dataAid,
  onListItemClick,
}: RecentlySearchedListItemProps) => {
  const { searchValue, type } = recentlySearchedItem;
  const handleOnClick = () => {
    onListItemClick?.();
    sendDataExplorerQuickSearchListItemClickBiAnalytics({
      entityName: 'recentlySearchedItemClick',
      id: searchValue,
    });

    const payload = getPayloadFromRecentlySearch(recentlySearchedItem);

    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, payload);
  };

  const Icon = getSearchTypeIcon(type);

  return (
    <ListItemContainer data-aid={generateDataAid(dataAid, ['value', searchValue])} onClick={handleOnClick}>
      <Content>
        <BigidTooltip title={searchValue}>
          <BigidBody1 whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {searchValue}
          </BigidBody1>
        </BigidTooltip>

        <div>
          <Icon width={18} />
        </div>
      </Content>
    </ListItemContainer>
  );
};
