export const HYBRID_CONNECTORS = ['adls-gen2', 'google-cloud-storage-v2', 's3-v2', 'azure-synapse'];

export const FLAT_CONNECTORS = [
  'sas',
  'vertica-v2',
  'mysql-v2',
  'rds-mysql',
  'rds-mariadb',
  'rds-aurora-mysql',
  'workday-v2',
  'workday-v2-wql',
  'athena-v2',
  'oracle-v2',
  'rds-oracle',
];

export const SUB_FLAT_CONNECTORS = [
  'mssql-v2',
  'rds-mssql',
  'postgresql-v2',
  'rds-postgresql',
  'rds-aurora-postgresql',
  'snowflake-v2',
];

export const NON_LEGACY_CONNECTORS = [...FLAT_CONNECTORS, ...SUB_FLAT_CONNECTORS];

export const DS_SUPPORTED_COMPOSITE_UNIQUE_ID = [
  'cassandra',
  'cassandra-v2',
  'rdb-db2',
  'rdb-db2iseries',
  'rdb-hana',
  'hive-jdbc',
  'rdb-mysql',
  'rdb-mssql',
  'rdb-oracle',
  'rdb-postgresql',
  'presto',
  'rdb-sybase',
  'rdb-teradata',
];
