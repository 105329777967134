import { BigidTreemapChartOption } from '@bigid-ui/visualisation';
import { AttributesGridRecord } from './AttributesLayout';

const DEFAULT_CATEGORY_FOR_ATTRIBUTES_WITH_NO_CATEGORIES = 'Other';

type AttributeItem = {
  id: string;
  name: string;
  value: number;
  dummyData: any;
  children?: BigidTreemapChartOption[];
  findings?: number;
};

type CategoryItem = {
  name: string;
  id: string;
  items: AttributeItem[];
  docCount: number;
  findings: number;
  aggItemName: string;
};

export const getArrayOfCategoriesWithAttributeAsChildren = (data: AttributesGridRecord[]): CategoryItem[] => {
  const uniqueCategoriesMap = new Map<string, CategoryItem>();

  data.forEach(item => {
    const categories =
      item.categories.length > 0 ? item.categories : [DEFAULT_CATEGORY_FOR_ATTRIBUTES_WITH_NO_CATEGORIES];

    const itemAsOption: AttributeItem = {
      id: item.aggItemName,
      name: item.aggItemName,
      value: item.docCount,
      dummyData: item,
      children: undefined,
      findings: item.findings,
    };

    categories.forEach(categoryName => {
      // Add unique category to the map with current child

      if (!uniqueCategoriesMap.has(categoryName)) {
        uniqueCategoriesMap.set(categoryName, {
          aggItemName: categoryName,
          name: categoryName,
          id: categoryName,
          items: [itemAsOption],
          docCount: itemAsOption.value,
          findings: itemAsOption.findings,
        });
      }

      // Add child to existing category
      else {
        const currentCategoryItem = uniqueCategoriesMap.get(categoryName)!;

        currentCategoryItem.items.push(itemAsOption);
        currentCategoryItem.docCount += itemAsOption.value;
        currentCategoryItem.findings += itemAsOption.findings;
      }
    });
  });

  // We want to sort it for slicing purposes in the component side so we will always display the X with the higher value
  const uniqueItemsByCategoriesSorted = Array.from(uniqueCategoriesMap.values())
    .map(category => ({
      ...category,
      items: category.items.sort((a, b) => b.value - a.value),
    }))
    .sort((a, b) => b.docCount - a.docCount);

  return uniqueItemsByCategoriesSorted;
};
