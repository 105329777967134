import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import { BigidCircularProgressBar } from '@bigid-ui/visualisation';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { styled } from '@mui/material';
import { ProgressChartData } from '../../types';
import { useLocalTranslation } from '../../translations';

const LegendContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 216px;
`;

export const DataRiskProgressChart: FC<{ progressChart: ProgressChartData }> = ({ progressChart }) => {
  const { t } = useLocalTranslation('dataRiskWidget');

  const formatLegends = (dataItem: any) => {
    const percentage = `(${Math.round(dataItem?.percent)}%)`;

    return (
      <LegendContainer>
        <BigidBody1>{dataItem?.category}</BigidBody1>
        <BigidBody1>
          {formatNumberCompact(dataItem?.value, 2)} {percentage}
        </BigidBody1>
      </LegendContainer>
    );
  };

  return (
    <BigidCircularProgressBar
      legendProps={{
        legendPosition: 'bottom',
        shouldHideSeries: false,
        hideLegend: false,
        legendLabelFormatter: dataItem => formatLegends(dataItem),
        legendConfig: { orientation: 'column' },
      }}
      size={230}
      data={progressChart.data}
      numberFormat="#.A"
      label={t('progressChartTotalLabel')}
      value={progressChart.total}
    />
  );
};
