import { BigidAreaChartDataItem } from '@bigid-ui/visualisation';

export enum DataDuplicationToggleOption {
  AMOUNT = 'amount',
  VOLUME = 'volume',
}

export interface DataDuplicationWidgetProps {
  title: string;
  onToggle?: (value: DataDuplicationToggleOption) => void;
}

export interface DataDuplicationDataPoint {
  category: string;
  amount: number;
  volume: number;
}

export interface DataDuplicationVolumeDataPoint extends BigidAreaChartDataItem {
  category: string;
  volume: number;
}

export interface DataDuplicationAmountDataPoint extends BigidAreaChartDataItem {
  category: string;
  amount: number;
}

export interface DuplicationSizeAndCostProps {
  latestEntry: DataDuplicationDataPoint;
}

export interface DataDuplicationAreaChartProps {
  selectedView: DataDuplicationToggleOption;
  data: DataDuplicationVolumeDataPoint[] | DataDuplicationAmountDataPoint[];
}

export interface DataDuplicationChartsProps extends DuplicationSizeAndCostProps, DataDuplicationAreaChartProps {
  isLoading: boolean;
}
