import { MutableRefObject } from 'react';
import { BigidGridRow } from '@bigid-ui/grid';
import { ClassifierGridRow } from '../../ClassifiersManagement/types/ClassifierTypes';
import { Attributes as AttributesClassifier } from '../../ClassifiersManagement/types/Attributes';
import { BigidDropdownOption } from '@bigid-ui/components';

export interface GuideState {
  isDefaultContentVisible?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export interface TestConnectionColumn {
  columnName: string;
  partialCorrelationSet: boolean;
  columnIdentifiability: string;
  selection: boolean;
  columnData: string;
  aliasColumnName?: string;
}

export interface SelectOptionType {
  label?: string;
  value: string;
  type?: DataSourceTypes;
  display_name?: string;
  id?: string;
  displayValue?: string;
}

export interface DsConnectionSelectOptionType {
  label?: string;
  value: string;
  type?: DataSourceTypes;
}

export interface CompositeAttribute {
  id: string | number;
  value: SelectOptionType[];
}

export interface CorrelationSetFormData {
  //general
  draft: boolean;

  //step 1
  name: string;
  dsConnection: DsConnectionSelectOptionType[];
  isCustomQueryString: boolean;
  db_table: string;
  custom_query_string: string;

  //additional fields that depend on the selected dsConnection type
  containerName?: string;
  subContainerName?: string;
  projectId?: string;

  // advance options step1
  identities_force_sample: boolean;
  identities_sample_size: string;
  skip_sample_start: boolean;
  skip_sample_size: string | number;
  use_random_sampling: boolean;

  //step2 PreviewStep
  attributes?: TestConnectionColumn[];

  // Step 3, Traning
  attributesTrainingStep?: TestConnectionColumn[];
  display_name?: SelectOptionType[];
  unique_id: BigidDropdownOption[];
  scanConnectionTimeoutInSeconds?: number;
  residency?: SelectOptionType[];
  enabled: boolean;
  composite_attributes: CompositeAttribute[];
}

export enum WizardFormFields {
  //step1
  name = 'name',
  dsConnection = 'dsConnection',
  isCustomQueryString = 'isCustomQueryString',
  db_table = 'db_table',
  custom_query_string = 'custom_query_string',

  //additional fields that depend on the selected dsConnection type
  containerName = 'containerName',
  subContainerName = 'subContainerName',
  projectId = 'projectId',

  // advance options step1
  identities_force_sample = 'identities_force_sample',
  identities_sample_size = 'identities_sample_size',

  skip_sample_start = 'skip_sample_start',
  skip_sample_size = 'skip_sample_size',
  use_random_sampling = 'use_random_sampling',

  // step2 PreviewStep
  attributes = 'attributes',

  // step3 TrainingStep
  attributesTrainingStep = 'attributesTrainingStep',
  isAddCompositeIdentifiers = 'isAddCompositeIdentifiers',
  composite_attributes = 'composite_attributes',
  unique_id = 'unique_id',
  unique_id_delimeter = 'unique_id_delimeter',
  scanConnectionTimeoutInSeconds = 'scanConnectionTimeoutInSeconds',
  display_name = 'display_name',
  residency = 'residency',
  enabled = 'enabled',
}

// WIZARD TYPES
export enum CorrelationSetsWizardSteps {
  DETAILS = 'DETAILS',
  PREVIEW = 'PREVIEW',
  TRAINING = 'TRAINING',
  RESULTS = 'RESULTS',
}

export interface DefaultStepProps {
  activeStepId: CorrelationSetsWizardSteps;
  detailsStepFormControls: MutableRefObject<any>;
  id?: string;
}

interface CustomField {
  field_type: string;
  field_name: string;
  field_value: string;
  enable: boolean;
}

interface ColumnsTypes {
  columnName: string;
  columnType: string;
}

export interface CorrelationSetData {
  id_connection: CorrelationSet;
}

export interface CorrelationSet {
  _id: string;
  id: string;
  uuid: string;
  custom_query_string: string;
  identities_force_sample: boolean;
  identities_sample_size: string;
  skip_sample_start: boolean;
  skip_sample_size: number;
  use_random_sampling: boolean;
  unique_id_delimeter: string;
  db_table: string;
  name: string;
  dsConnection: string;
  dsConnectionType?: DataSourceTypes;
  containerName?: string;
  subContainerName?: string;
  projectId?: string;
  unique_id: string;
  attributes: TestConnectionColumn[];
  type: string;
  display_name: string;
  enabled: string;
  isCustomQueryString: boolean;
  columnsTypes: ColumnsTypes[];
  composite_attributes: [string[]]; // Define this type if you have specific details about the array content
  test_is_success: boolean;
  test_timestamp: string;
  unique_id_composite: any[]; // Define this type if you have specific details about the array content
  updated_at: string;
  connectionStatusTest: {
    is_success: boolean;
    last_connection: string;
    is_connection_ever_succeeded: boolean;
    num_of_object: number;
  };
  residency: string;
  scanConnectionTimeoutInSeconds?: number;
  draft: boolean;
}

export interface DataSourceType {
  _id: string;
  id: string;
  name: string;
  Is_sample_files: string;
  Is_sample_folders: string;
  authStrategy: string;
  autoRetry: number;
  box_service_account_json: string;
  certificate_id: string;
  classification_is_enabled: boolean;
  customFields: any[];
  custom_fields: CustomField[];
  differential: boolean;
  enabled: string;
  enrichment_is_enabled: boolean;
  exclude_user_regex: boolean;
  folder_percent_to_sample: string;
  include_file_types: boolean;
  isSuppressNotification: boolean;
  is_certificate: boolean;
  is_credential: boolean;
  is_idsor_supported: boolean;
  is_native_index: boolean;
  is_ocr_enabled: string;
  is_user_accounts_enabled: boolean;
  last_sub_scan_id: string;
  ner_classification_is_enabled: boolean;
  notAddToScope: boolean;
  numberOfParsingThreads: string;
  owners_v2: any[];
  password: string;
  rdb_comma_sep_table_list: string;
  rdb_is_only_user_objects: boolean;
  rdb_is_sample_data: boolean;
  rdb_is_scan_views: boolean;
  rdb_name: string;
  rdb_sample_data_max_percentage: string;
  rdb_sample_data_max_size: string;
  rdb_sample_type: string;
  rdb_url: string;
  row_identifier_expression_is_disabled: boolean;
  scanner_group: string;
  scanner_strategy: string;
  security_tier: string;
  stop_on_failure: boolean;
  structured_clustering_enabled: boolean;
  tags: any[];
  type: DataSourceTypes;
  created_at: string;
  updated_at: string;
  username: string;
}

// WIZARD TYPES END

export interface Category {
  _id?: string;
  color: string;
  display_name: string;
  unique_name: string;
  description: string;
  glossary_id?: string;
}

export interface StatisticsRow extends BigidGridRow {
  columnName: string;
  attributeName: string;
  categories: Category[];
  identifiability: string;
  avgValuesLength: number;
}

export interface FormValuesType extends ClassifierGridRow {
  attribute?: AttributesClassifier;
}

export type DataSourceTypes =
  | 'athena'
  | 'vertica'
  | 'vertica-v2'
  | 'dynamodb'
  | 'elasticsearch'
  | 'rdb-postgresql'
  | 'exchange'
  | 'hadoop-hawq'
  | 'hbase'
  | 'hugging-face'
  | 'couchbase'
  | 'documentum'
  | 'yellowbrick'
  | 'cockroachdb'
  | 'microstrategy'
  | 'microsoft-dynamics'
  | 'ldap'
  | 'greenplum'
  | 'informix'
  | 'rdb-db2'
  | 'rdb-db2iseries'
  | 'rdb-mysql'
  | 'rdb-sybase'
  | 'rdb-hana'
  | 'rdb-mssql'
  | 'rdb-teradata'
  | 'rdb-redshift'
  | 'rdb-activedirectory'
  | 'csv'
  | 'smb'
  | 'smb_v2'
  | 'adls-structured'
  | 'google-cloud-storage-v2'
  | 'databricks'
  | 'rdb-oracle'
  | 'cassandra'
  | 'cassandra-v2'
  | 'mongodb'
  | 'netezza'
  | 'tableau'
  | 'salesforce-marketing-cloud'
  | 'sap'
  | 'nfs'
  | 'nfs_v2'
  | 'efs'
  | 'hive-jdbc'
  | 'azure-blob'
  | 'netsuite'
  | 'gmail'
  | 'azure-file'
  | 'cosmosdb'
  | 'microsoft-teams'
  | 'presto'
  | 'servicenow'
  | 'servicenow-structured'
  | 'servicenow-v2'
  | 'snowflake'
  | 'datastore'
  | 'onedrive-personal'
  | 'sharepoint'
  | 'sharepoint-online'
  | 'o365-onedrive'
  | 'box'
  | 'cims'
  | 'salesforce'
  | 'redis'
  | 's3'
  | 's3-structured'
  | 'adls-gen2-structured'
  | 'sap-successfactors'
  | 'workday'
  | 'gitlab'
  | 'google-bigtable'
  | 'bigtable-v2'
  | 'datastore-v2'
  | 'spanner-v2'
  | 'google-cloud-storage'
  | 'googledrive'
  | 'generic-rest-api'
  | 'generic-rest-api-unstructured'
  | 'gcp-big-query'
  | 'spanner'
  | 'sas'
  | 'sas-dataservice'
  | 'hadoop'
  | 'hadoop_v2'
  | 'o365-outlook'
  | 'jira'
  | 'zendesk'
  | 'iterable'
  | 'iterable-dsar'
  | 'confluence'
  | 'confluence-v2'
  | 'github'
  | 'slack'
  | 'slack-v2'
  | 'asana-dsar'
  | 'slack-dsar'
  | 'trello-dsar'
  | 'hubspot-dsar'
  | 'docusign-dsar'
  | 'sharefile'
  | 'slack-files'
  | 'generic-cloud'
  | 'kdbplus'
  | 'mailchimp-dsar'
  | 'zendesk-dsar'
  | 'reltio-dsar'
  | 'gra-v2'
  | 'external-catalog'
  | 'zohodesk-dsar'
  | 'qualtrics-dsar'
  | 'kinesis'
  | 'oracle-eloqua'
  | 'localytics'
  | 'astradb'
  | 'bigcommerce-dsar'
  | 'vsam'
  | 's3-v2'
  | 'emr'
  | 'marklogic'
  | 'splunk'
  | 'aha'
  | 'custom-v2'
  | 'slack-enterprise'
  | 'documentdb'
  | 'stripe-dsar'
  | 'marketo-dsar'
  | 'azure-dsar'
  | 'zoho-crm-dsar'
  | 'bitbucket-cloud'
  | 'mysql-v2'
  | 'mssql-v2'
  | 'dropbox'
  | 'docusign'
  | 'adls-gen2'
  | 'azure-synapse'
  | 'azure-blob-v2'
  | 'azure-file-v2'
  | 'gmail-v2'
  | 'custom-proxy'
  | 'zendesk-v2'
  | 'kafka'
  | 'sharepoint-online-v2'
  | 'bullhorn'
  | 'zendesk-sell'
  | 'alicloud'
  | 'o365-outlook-v2'
  | 'oracle-cx'
  | 's3-on-premise'
  | 'zoho-crm'
  | 'workday-v2'
  | 'workday-v2-wql'
  | 'workday-rest'
  | 'workday-reports'
  | 'github-cloud'
  | 'github-on-prem'
  | 'filenet'
  | 'sharefile_v2'
  | 'couchdb'
  | 'gdrive-v2'
  | 'opentext'
  | 'onedrive-v2'
  | 'athena-v2'
  | 'unix-sftp'
  | 'sap-successfactors-v2'
  | 'windows-sftp'
  | 'ms-teams-v2'
  | 'google-firestore'
  | 'imanage'
  | 'workdocs'
  | 'sharepoint-v2'
  | 'atlas-vectorsearch'
  | 'o365-calendar'
  | 'o365-contacts'
  | 'neptune'
  | 'microsoft-dataverse'
  | 'microsoft-dynamics-v2'
  | 'postgresql-v2'
  | 'oracle-v2'
  | 'rds-mysql'
  | 'rds-mariadb'
  | 'rds-aurora-mysql'
  | 'rds-mssql'
  | 'rds-postgresql'
  | 'rds-aurora-postgresql'
  | 'jira-v2'
  | 'db2-v2'
  | 'rds-oracle'
  | 'oracle-cloud-erp'
  | 'oracle-cloud-hcm'
  | 'openai'
  | 'azure-openai'
  | 'tableau-v2'
  | 'snowflake-v2'
  | 'box-v2'
  | 'ebs'
  | 'tableau-server'
  | 'vertex-ai'
  | 'sap-business-suite-v2'
  | 'yugabyte'
  | 'smart-room'
  | 'gcs-structured'
  | 'application-template'
  | 'azure-rdbms-postgresql'
  | 'azure-rdbms-mysql'
  | 'gcp-cloud-postgresql'
  | 'gcp-cloud-mysql'
  | 'gcp-cloud-mssql'
  | 'azure-rdbms-mysql'
  | 'sparksql'
  | 'redis-v2';
