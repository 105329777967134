import {
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidLoader,
  BigidQueryBuilder,
  QueryLogicalOperatorMap,
  QueryMathOperatorMap,
  QueryNode,
  QueryOtherOperatorMap,
  QueryTree,
} from '@bigid-ui/components';
import { parseGraphQueryNodes, RulesStructNode } from '@bigid/query-object-serialization';
import { Box } from '@mui/material';
import React from 'react';
import { FilterBuilderProps } from './FilterBuilder';
import { getOperandsFromAdjustedFilters } from './queryBuilder.utils';
import { useQuery } from 'react-query';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { updateRecentlySearchedPreferences } from '../../dataExplorerQuickSearchService';

//NOTE: Excluding all nested dropdown filters since they are not yet supported by Query Builder
const excludedFiltersListIds = ['tags', 'cloudType', 'sensitivityFilter', 'category'];

export const QueryBuilder = ({ filters = [], entityName, onSubmitRef, dataAid }: FilterBuilderProps) => {
  const dropdownFilters = filters.filter(
    filter =>
      filter.type === BigidAdvancedToolbarFilterTypes.DROPDOWN && !excludedFiltersListIds.includes(String(filter.id)),
  ) as BigidAdvancedToolbarDropdownFilter[];

  const { data: operands, isFetching } = useQuery(
    ['adjustFiltersWithInitialOptions', entityName],
    () => getOperandsFromAdjustedFilters(dropdownFilters),
    {
      enabled: dropdownFilters.length > 0,
    },
  );

  const defaultQuery: QueryNode = {
    id: '0',
    operator: QueryLogicalOperatorMap.AND,
    rules: [
      {
        id: '0-1',
        operator: QueryMathOperatorMap.EQUAL,
        parentId: '0',
      },
    ],
  };

  const transformOperators = (node: QueryTree): QueryTree => {
    // Determine if we should change the operator
    const shouldChangeOperator = Array.isArray(node.rightOperand);

    // Recursively process children
    const newRules = node.rules ? node.rules.map(transformOperators) : undefined;

    // Return a new node object, preserving unchanged fields
    return {
      ...node,
      operator: shouldChangeOperator ? QueryOtherOperatorMap.IN : node.operator,
      rules: newRules,
    };
  };

  const handleQueryBuilderChange = (query: QueryTree) => {
    const transformedQuery = transformOperators(query);
    const filter = parseGraphQueryNodes(transformedQuery as unknown as RulesStructNode);

    onSubmitRef.current = () => {
      $state.go(
        CONFIG.states.CATALOG_SEARCH_RESULTS,
        {
          biqlFilter: filter,
          activeTab: entityName,
          queryMode: 'queryFilter',
        },
        {
          reload: true,
        },
      );
      updateRecentlySearchedPreferences({
        searchValue: filter,
        type: 'queryFilter',
        created_at: Date.now(),
        entityName,
      });
    };
  };

  const logicalOperators = [QueryLogicalOperatorMap.OR, QueryLogicalOperatorMap.AND];

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        backgroundColor: theme => theme.vars.palette.bigid.gray125,
      }}
    >
      {isFetching ? (
        <BigidLoader position="absolute" />
      ) : (
        <BigidQueryBuilder
          key={entityName}
          initialQuery={defaultQuery}
          logicalOperators={logicalOperators}
          onQueryChange={handleQueryBuilderChange}
          operands={operands}
          dataAid={generateDataAid(dataAid, ['queryBuilder'])}
        />
      )}
    </Box>
  );
};
