import React, { FC } from 'react';
import { InteractiveDashboardInfoBoxWidget } from './InteractiveDashboardInfoBoxWidget';
import { BigidPolicyIcon } from '@bigid-ui/icons';
import { BigidTooltip } from '@bigid-ui/components';
import { makeTitleActions } from './actions';
import { isArray, isNumber } from 'lodash';
import { PoliciesTriggered } from './ACIDashboardWrapper';

export interface ACIDashboardAccessPoliciesTriggeredProps {
  triggeredAccessPolicies: PoliciesTriggered['triggeredAccessPolicies'];
  totalObjectsFoundByAccessPolicies: PoliciesTriggered['totalObjectsFoundByAccessPolicies'];
}

export const ACIDashboardAccessPoliciesTriggered: FC<ACIDashboardAccessPoliciesTriggeredProps> = ({
  triggeredAccessPolicies,
  totalObjectsFoundByAccessPolicies,
}) => {
  return (
    <>
      {isNumber(totalObjectsFoundByAccessPolicies) && isArray(triggeredAccessPolicies) && (
        <InteractiveDashboardInfoBoxWidget
          icon={<BigidPolicyIcon size="large" />}
          data={
            <BigidTooltip title={triggeredAccessPolicies.join(',\n')}>
              <div>
                {triggeredAccessPolicies.length}
                {totalObjectsFoundByAccessPolicies > 0 ? '/' + totalObjectsFoundByAccessPolicies : ''}
              </div>
            </BigidTooltip>
          }
          titleActions={makeTitleActions.accessPoliciesTriggered()}
          description={'Access Policies Triggered'}
        />
      )}
      {totalObjectsFoundByAccessPolicies === 'IN_PROGRESS' && triggeredAccessPolicies === 'IN_PROGRESS' && (
        <InteractiveDashboardInfoBoxWidget data={<>Loading...</>} titleActions={[]} description={''} />
      )}
    </>
  );
};
