import React, { ChangeEvent, FC, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ClassificationLevel } from './SensitivityClassification';
import { BigidButtonIcon, BigidTextField, QueryTree, TextFieldIconPositionEnum } from '@bigid-ui/components';
import {
  BigidArrowDownIcon,
  BigidArrowUpIcon,
  BigidDeleteIcon,
  BigidDuplicateIcon,
  BigidQueryLanguageIcon,
  BigidApplyFilledIcon,
} from '@bigid-ui/icons';
import { PriorityChange } from './SensitivityClassificationForm';
import { QuerySelector, QueryType } from './QuerySelector';
import { ErrorField } from './formErrorReducer';
import * as bigidQueryObjectSerialization from '@bigid/query-object-serialization';
import { SensitivityClassificationsContext } from './SensitivityClassificationsContext';
import { isPermitted } from '../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { notificationService } from '../../services/notificationService';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  priorityBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 0px',
    boxShadow: ' 0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 6,
    width: 35,
    height: 35,
    marginRight: 15,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 30,
  },
});

export interface ClassificationLevelButtonsStatus {
  isPriorityUpDisabled: boolean;
  isPriorityDownDisabled: boolean;
  isDuplicateDisabled: boolean;
  isDeleteDisabled: boolean;
}

interface LevelFieldProps {
  level: ClassificationLevel;
  buttonsStatus: ClassificationLevelButtonsStatus;
}

const SuccessIcon = () => <BigidApplyFilledIcon color="positive" />;

export const checkIfQueryValid = (objectQuery?: QueryTree, stringQuery?: string): boolean => {
  return (
    (objectQuery && bigidQueryObjectSerialization.validateQuery(JSON.stringify(objectQuery)).valid) ||
    (bigidQueryObjectSerialization.validateQuery(stringQuery).valid && !stringQuery.includes(`""`))
  );
};

export const checkIfQueryContainsScTag = (stringQuery?: string): boolean => {
  return stringQuery.includes(`system.sensitivity`);
};

export const ClassificationLevelField: FC<LevelFieldProps> = ({ level, buttonsStatus }) => {
  const classes = useStyles({});
  const { setLevel, onDeleteLevel, onDuplicateLevel, onPriorityChangeLevel, dispatchError, errorState, sc } =
    useContext(SensitivityClassificationsContext);
  const { emptyQuery, notValidQuery, name, containsScQuery } = errorState?.classifications?.[level.id] || {};

  const getSuccessIcon = () => {
    return !containsScQuery && !notValidQuery && !emptyQuery && !name && level.isQueryTouched && level.name
      ? {
          Icon: SuccessIcon,
          position: TextFieldIconPositionEnum.right,
        }
      : undefined;
  };

  const handleNameChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    dispatchError({ type: ErrorField.CLASSIFICATION, payload: { id: level.id, name: null } });
    if (!target.value) {
      dispatchError({
        type: ErrorField.CLASSIFICATION,
        payload: { id: level.id, name: 'All levels must have a name and a query defined' },
      });
    }
    const newValue = target.value.replace(/\s{2,}/g, ' ');
    setLevel({ ...level, name: newValue });
  };

  const toggleQuerySelectorDisplay = () => {
    if (!isPermitted(CATALOG_PERMISSIONS.READ_MANUAL_FIELDS.name)) {
      notificationService.error(
        `An error has occurred: User permissions are insufficient, please contact your system administrator`,
      );
      return;
    }
    setLevel({ ...level, queryExpanded: !level.queryExpanded });
  };

  const handleQueryChange = (objectQuery?: QueryTree, stringQuery?: string) => {
    if (!objectQuery) {
      setLevel({
        ...level,
        [QueryType.String]: stringQuery,
        [QueryType.Object]: null,
        ['queryObj']: null,
        isQueryTouched: true,
      });
    } else if (!stringQuery) {
      setLevel({ ...level, [QueryType.Object]: objectQuery, isQueryTouched: true });
    } else {
      setLevel({
        ...level,
        [QueryType.Object]: objectQuery,
        [QueryType.String]: stringQuery,
        isQueryTouched: true,
      });
    }
  };

  const handleQueryTypeChange = (queryType: QueryType) => {
    setLevel({
      ...level,
      queryType: queryType,
    });
  };

  return (
    <div data-aid={`levelField-${level.priority}`} className={classes.root}>
      <div className={classes.row}>
        <div className={classes.priorityBox}>{level.priority + 1}</div>
        <BigidTextField
          errorMessage={errorState?.classifications?.[level.id]?.name}
          name={`levelField-${level.priority}-name`}
          icon={getSuccessIcon()}
          onChange={handleNameChange}
          value={level?.name}
          placeholder="Level name"
          type="text"
        />
        <div className={classes.toolbar}>
          <BigidButtonIcon
            icon={BigidQueryLanguageIcon}
            onClick={toggleQuerySelectorDisplay}
            dataAid={`levelField-${level.priority}-queryExpand`}
            selected={level.queryExpanded}
          />
          <BigidButtonIcon
            icon={BigidArrowUpIcon}
            onClick={() => onPriorityChangeLevel(level.priority, PriorityChange.Increase)}
            dataAid={`levelField-${level.priority}-priorityUp`}
            disabled={buttonsStatus?.isPriorityUpDisabled}
          />
          <BigidButtonIcon
            icon={BigidArrowDownIcon}
            onClick={() => onPriorityChangeLevel(level.priority, PriorityChange.Decrease)}
            dataAid={`levelField-${level.priority}-priorityDown`}
            disabled={buttonsStatus?.isPriorityDownDisabled}
          />
          <BigidButtonIcon
            icon={BigidDuplicateIcon}
            onClick={() => onDuplicateLevel(level)}
            dataAid={`levelField-${level.priority}-duplicate`}
            disabled={buttonsStatus?.isDuplicateDisabled}
          />
          <BigidButtonIcon
            icon={BigidDeleteIcon}
            onClick={() => onDeleteLevel(level)}
            dataAid={`levelField-${level.priority}-delete`}
            disabled={buttonsStatus?.isDeleteDisabled}
          />
        </div>
      </div>
      {level?.queryExpanded && (
        <QuerySelector
          classificationLevel={level}
          isQueryTouched={level?.isQueryTouched}
          objectQuery={level?.queryObject}
          stringQuery={level?.queryString}
          onQueryChange={handleQueryChange}
          onQueryTypeChange={handleQueryTypeChange}
        />
      )}
    </div>
  );
};
