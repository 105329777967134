import React from 'react';
import { ModalRoot } from './ModalRoot/ModalRoot';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../../../config/query';
import { FilterBuilderProps } from './ModalRoot/FilterBuilder';
import { generateDataAid } from '@bigid-ui/utils';

export const FiltersModalContent = ({ onSubmitRef, dataAid }: Pick<FilterBuilderProps, 'onSubmitRef' | 'dataAid'>) => {
  const queryClient = getQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ModalRoot onSubmitRef={onSubmitRef} dataAid={generateDataAid(dataAid, ['modal'])} />
    </QueryClientProvider>
  );
};
