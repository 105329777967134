import React, { useState, useEffect, useMemo } from 'react';
import type { BigidMenuItemProps } from '@bigid-ui/components';

import { usePrivacyExecutiveDashboardContext } from '../../PrivacyExecutiveDashboardContext';
import { Dropdown, DropdownSeverityStartIcon } from '../helpers/HelperComponents';

export function useSeverityDropdown() {
  const [severity, setSeverity] = useState<string>();
  const { severityColorMapper, severityDropdownItems } = usePrivacyExecutiveDashboardContext();
  const showAllSeverities = !Object.keys(severityColorMapper || {})?.includes(severity);
  const color = severityColorMapper?.[severity];

  useEffect(() => {
    if (!severity && severityColorMapper) {
      setSeverity(Object.keys(severityColorMapper)[0]);
    }
  }, [severity, severityColorMapper]);

  const handleSeveritySelect = (item: BigidMenuItemProps) => {
    setSeverity(item.id as string);
  };

  const SeverityDropdown = useMemo(() => {
    return () => (
      <Dropdown
        items={severityDropdownItems}
        startIcon={showAllSeverities ? null : <DropdownSeverityStartIcon color={color} small />}
        onItemSelect={handleSeveritySelect}
        currentItem={severity}
      />
    );
  }, [severity, severityDropdownItems, showAllSeverities, color]);

  return { severity, showAllSeverities, SeverityDropdown };
}
