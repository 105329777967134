export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertUserNameToString = (
  username: string,
  firstName: string | undefined,
  lastName: string | undefined,
  hideUsername?: boolean,
) => {
  return (
    `${firstName ? `${firstName} ` : ''}${lastName ?? ''} ${hideUsername ? '' : `(${username})`}`.trim() || username
  );
};
