import React, { FC, useEffect, useMemo, useState } from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import {
  BigidCarousel,
  BigidDialog,
  BigidCarouselProps,
  SecondaryButton,
  PrimaryButton,
  BigidCarouselMedia,
  BigidDialogProps,
  BigidDialogButtonType,
} from '@bigid-ui/components';
import { ConversionChoices } from './ConversionChoices';
import { ScanActions } from './ScanActions';
import { docsUrls } from '../../../../react/config/publicUrls';
import { getApplicationPreference } from '../../../../react/services/appPreferencesService';
import { userPreferencesService } from '../../../../react/services/userPreferencesService';
import { $state } from '../../../../react/services/angularServices';
import { BigidHelpIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';

const ContainerCarousel = styled('div')`
  [data-aid^='BigidCarousel-media-item'] img {
    width: 100%;
  }
`;

interface ConvertScanProfileGuidedTourProps {
  manualStart: boolean;
  setIsShowGuidedTour: (value: boolean) => void;
}

const carouselItems: BigidCarouselMedia[] = [
  {
    url: '/images/Bigid_convert_scan_profile1.svg',
    type: 'img',
  },
  {
    url: '/images/Bigid_convert_scan_profile2.svg',
    type: 'img',
  },
];

export const ConvertScanProfileDialogGuide: FC<ConvertScanProfileGuidedTourProps> = ({
  manualStart,
  setIsShowGuidedTour,
}) => {
  const [imageActiveIndex, setImageActiveIndex] = useState<number>(0);

  useEffect(() => {
    handleUserPreferenceManualStart();
  }, []);

  const handleUserPreferenceManualStart = async () => {
    const scanInsightUserPreference = await userPreferencesService.get($state.$current.name);
    const isShowDialogGuide = getApplicationPreference('SHOW_DIALOG_GUIDE_CONVERT_SCAN_PROFILE');
    if (!scanInsightUserPreference?.data?.isConvertScanProfileDialogGuide && isShowDialogGuide) {
      setIsShowGuidedTour(true);
    }
  };

  const handleClose = async () => {
    setIsShowGuidedTour(false);
    setImageActiveIndex(0);

    const editScanProfileUserPreference = await userPreferencesService.get($state.$current.name);
    if (!editScanProfileUserPreference?.data?.isConvertScanProfileDialogGuide) {
      await userPreferencesService.update({
        preference: $state.$current.name,
        data: { ...(editScanProfileUserPreference?.data || {}), isConvertScanProfileDialogGuide: true },
      });
    }
  };

  const openDocumentation = () => {
    window.open(docsUrls.CONVERTING_TO_SCAN, '_blank');
  };

  const indicatorHandler = (currentImageIndex: number) => {
    setImageActiveIndex(currentImageIndex);
  };

  const buttons: BigidDialogButtonType[] = useMemo(() => {
    const basicButtons: BigidDialogButtonType[] = [
      { component: SecondaryButton, onClick: openDocumentation, text: 'Open Documentation', alignment: 'left' },
    ];

    if (imageActiveIndex > 0) {
      basicButtons.push({
        component: SecondaryButton,
        onClick: () => setImageActiveIndex(currStepIndex => currStepIndex - 1),
        text: 'Previous',
        alignment: 'right',
        dataAid: 'Previous-button',
      });
    }

    if (imageActiveIndex < carouselItems.length - 1) {
      basicButtons.push({
        component: PrimaryButton,
        onClick: () => setImageActiveIndex(currStepIndex => currStepIndex + 1),
        text: 'Next',
        alignment: 'right',
        dataAid: 'Next-button',
      });
    }

    if (imageActiveIndex === carouselItems.length - 1) {
      basicButtons.push({
        component: PrimaryButton,
        onClick: handleClose,
        text: 'Done',
        alignment: 'right',
      });
    }

    return basicButtons;
  }, [handleClose, imageActiveIndex]);

  const bigidCarouselProps: BigidCarouselProps = {
    items: carouselItems,
    mediaHeight: 340,
  };

  const bigidDialogProps: BigidDialogProps = {
    title: 'Welcome to scan profile conversion',
    isOpen: manualStart,
    onClose: handleClose,
    buttons,
  };

  return (
    <BigidDialog {...bigidDialogProps}>
      <ContainerCarousel>
        <BigidCarousel {...bigidCarouselProps} onChange={indicatorHandler} index={imageActiveIndex} />
        {imageActiveIndex === 0 && <ConversionChoices />}
        {imageActiveIndex === 1 && <ScanActions />}
      </ContainerCarousel>
    </BigidDialog>
  );
};

export const getBigIdHelpIcon: FC<{ onClick: () => void }> = ({ onClick }) => {
  return <BigidHelpIcon cursor="pointer" onClick={onClick} />;
};

angular
  .module('app')
  .component(
    'convertScanProfileDialogGuide',
    convertToAngular(ConvertScanProfileDialogGuide, ['manualStart', 'setIsShowGuidedTour']),
  );
