import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { EntityEvents, entityEventsEmitter, PrimaryButton, ToolbarActionType } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { BigidAddIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';
import { notificationService } from '../../services/notificationService';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { deleteSensitivityClassification, getSensitivityClassifications } from './SensitivityClassificationService';
import { getFixedT, useLocalTranslation } from './translations';
import { SensitivityClassificationDataRow } from './Types';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 'calc(100% - 12px)',
  background: theme.vars.tokens.bigid.backgroundPrimary,
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '6px',
}));

export const SensitivityClassification: FC = () => {
  const [query, setQuery] = useState<string>('');

  const { t } = useLocalTranslation('SensitivityClassificationGrid');
  const notificationMessages = getFixedT('Notifications');
  const isACIEnabled: boolean = getApplicationPreference('ACI_ENABLED');
  const USER_PREFERENCES_IDENTIFIER = 'sensitivityClassificationNewDesign';

  const getScProgress = useCallback(
    (progress: string) => {
      const [completed, total] = progress.split('/').map(str => parseInt(str.trim(), 10));

      if (completed === total) {
        return t('Common.completed');
      } else {
        return `${progress} ${t('Common.partialProgressCompleted')}`;
      }
    },
    [t],
  );

  const goToEditForm = (scId: string) => {
    $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT, { id: scId });
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const refreshGridData = async () => {
      try {
        if (query) {
          const { scConfigs } = await getSensitivityClassifications(query);
          entityEventsEmitter.emit(EntityEvents.UPDATE, scConfigs, false);
          timeout = setTimeout(refreshGridData, 1000 * 30);
        }
      } catch (e) {
        console.error(notificationMessages('gridDataFetch'));
        notificationService.error(notificationMessages('gridDataFetch'));
      }
    };
    refreshGridData();
    return () => {
      clearInterval(timeout);
    };
  }, [notificationMessages, query]);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('Common.pageTitle'),
      rightSideComponentsContainer: (
        <PrimaryButton
          startIcon={<BigidAddIcon />}
          size="medium"
          onClick={() => $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT)}
          dataAid="add-new-group-button"
        >
          {t('Common.addGroupButton')}
        </PrimaryButton>
      ),
    });
  }, [t]);

  const getColumns = useMemo(() => {
    const columns: BigidGridColumn<SensitivityClassificationDataRow>[] = [
      {
        name: 'name',
        title: t('Columns.classificationGroup'),
        type: BigidGridColumnTypes.LINK,
        formatter: {
          onClick: async ({ value }) => {
            const { linkParams } = value;
            goToEditForm(linkParams.id);
            return {};
          },
        },
        getCellValue: ({ id, name }) => ({
          link: {
            text: name,
          },
          linkParams: {
            id,
          },
        }),
      },
      {
        name: 'classificationValues',
        title: t('Columns.classificationLevels'),
        sortingEnabled: false,
        getCellValue: row =>
          row.classifications.reduce<string>(
            (acc, classificationValue, index) => `${acc} ${index === 0 ? '' : '/'} ${classificationValue.name}`,
            '',
          ),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'description',
        title: t('Columns.description'),
        sortingEnabled: false,
        getCellValue: row => row.description,

        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'progress',
        title: t('Columns.syncProgress'),
        sortingEnabled: false,
        getCellValue: row => row.progress && getScProgress(row.progress),
        type: BigidGridColumnTypes.TEXT,
      },
    ];
    return columns;
  }, [getScProgress, t]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterConfig = useCallback(async (): Promise<BigidGridWithToolbarProps<any>['filterToolbarConfig']> => {
    return {
      filters: [],
    };
  }, []);

  const { isReady, gridColumns, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: USER_PREFERENCES_IDENTIFIER,
    initialGridColumns: getColumns,
    getInitialFilterToolbarConfig: filterConfig,
  });

  const config: BigidGridWithToolbarProps<SensitivityClassificationDataRow> = useMemo(
    () => ({
      entityName: t('EntityName.entityName'),
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => {
        return updatePreferences({ filterState: { filter }, gridState });
      },
      filterToolbarConfig,
      toolbarSearchConfig: {
        searchFilterKeys: ['name'],
        placeholder: t('Common.gridSearchPlaceholder'),
        initialValue: '',
        operator: 'contains',
        searchMaxWidth: '250px',
      },
      fetchData: async queryComponents => {
        try {
          const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
          setQuery(gridConfigQuery);
          const { scConfigs, totalCount } = await getSensitivityClassifications(gridConfigQuery);
          return {
            totalCount,
            data: scConfigs,
          };
        } catch ({
          response: {
            data: { message },
          },
        }) {
          notificationService.error(
            isACIEnabled
              ? `${notificationMessages('anErrorHasOccurred')} ${message}`
              : `${notificationMessages('anErrorHasOccurred')} ${notificationMessages('gridFetchErrorAciOff')}`,
          );
          console.error(`${notificationMessages('anErrorHasOccurred')} ${message}`);
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: gridColumns,
      toolbarActions: [
        {
          label: t('Common.delete'),
          isGlobal: true,
          isInline: true,
          type: ToolbarActionType.TERTIARY,
          icon: BigidDeleteIcon,
          execute: async actionData => {
            try {
              const isClassificationDeletionConfirmed = await showConfirmationDialog({
                entityNameSingular: t('Common.pageTitle'),
                actionName: t('Common.delete'),
                actionButtonName: t('Common.delete'),
              });
              if (isClassificationDeletionConfirmed) {
                await deleteSensitivityClassification(actionData?.selectedRows?.[0]?.id);
                return {
                  row: actionData?.selectedRows?.[0],
                  shouldGridReload: false,
                  entityEventToEmit: EntityEvents.DELETE,
                };
              } else {
                return {};
              }
            } catch (error) {
              notificationService.error(
                `${notificationMessages('anErrorHasOccurred')} ${error?.response?.data?.message}`,
              );
              console.error(`${notificationMessages('anErrorHasOccurred')} ${error?.response?.data?.message}`);
              return {};
            }
          },
          disable: () => false,
          show: actionData => actionData.selectedRowIds?.length === 1 && !actionData?.selectedRows?.[0]?.defaultSc,
        },
      ],
    }),
    [filterToolbarConfig, gridColumns, isACIEnabled, notificationMessages, t, updatePreferences],
  );

  return (
    <>
      {isReady && (
        <Container>
          <BigidGridWithToolbar {...config} />
        </Container>
      )}
    </>
  );
};
