import React from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidLink, BigidLoader } from '@bigid-ui/components';
import { DataCoverageAreaChart } from './DataCoverageAreaChart';
import { DataCoverageProgressChart } from './DataCoverageProgressChart';
import { DataCoverageDataPoint, ProgressChartData } from '../../types';
import { DataCoverageToggleOption } from '../DataCoverageWidget';
import { useLocalTranslation } from '../../translations';
import { docsUrls } from '../../../../config/publicUrls';

const ChartsContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 264px;
`;

const WidgetsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const VolumeDisclaimer = styled('div')`
  padding-top: 24px;
  width: 550px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: 4px;
`;

const ProgressChartWrapper = styled('div')`
  padding: 0 24px;
  margin-right: 80px;
  width: 275px;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const AreaChartWrapper = styled('div')<{ isProgressChartDisplayed?: boolean }>`
  flex-grow: 1;
  max-width: ${({ isProgressChartDisplayed }) => (isProgressChartDisplayed ? '1800px' : 'none')};
`;

export const DataCoverageCharts = ({
  areaChart,
  progressChart,
  isLoading = true,
  viewByOption,
}: {
  areaChart: DataCoverageDataPoint[];
  progressChart: ProgressChartData;
  isLoading: boolean;
  viewByOption: DataCoverageToggleOption;
}) => {
  const isProgressChartDisplayed = !!progressChart?.data?.length;
  const { t } = useLocalTranslation('dataCoverageWidget');

  return (
    <WidgetsContainer>
      <ChartsContainer>
        {isLoading ? (
          <BigidLoader />
        ) : (
          <>
            {isProgressChartDisplayed && (
              <ProgressChartWrapper>
                <DataCoverageProgressChart progressChart={progressChart} viewByOption={viewByOption} />
                {viewByOption === DataCoverageToggleOption.VOLUME && (
                  <VolumeDisclaimer>
                    <BigidBody1 display="inline" size="small">
                      {t('volumeDisclaimer')}
                    </BigidBody1>
                    <BigidLink
                      underline="none"
                      href={docsUrls.MANAGED_VOLUME_DOC}
                      shouldOpenNewTab={true}
                      text={t('learnMoreLink')}
                    />
                  </VolumeDisclaimer>
                )}
              </ProgressChartWrapper>
            )}
            <AreaChartWrapper isProgressChartDisplayed={isProgressChartDisplayed}>
              <DataCoverageAreaChart areaChart={areaChart} viewByOption={viewByOption} />
            </AreaChartWrapper>
          </>
        )}
      </ChartsContainer>
    </WidgetsContainer>
  );
};
