import React from 'react';
import { BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import { GuideState } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import { CorrelationSetGuideContent } from '../../../components/CorrelationSetGuide';

interface DetailsGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

const MARGIN_BOTTOM = 2;

export const PreviewGuideContent = ({ contentTitle, guideState }: DetailsGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent');

  return (
    <>
      <CorrelationSetGuideContent>
        <BigidHeading6>{contentTitle}</BigidHeading6>
        <BigidBody1 marginBottom={MARGIN_BOTTOM}>{t('previewStep.text1')}</BigidBody1>
        <BigidBody1 marginBottom={MARGIN_BOTTOM}>{t('previewStep.text2')}</BigidBody1>
        <BigidBody1 marginBottom={MARGIN_BOTTOM}>{t('previewStep.text3')}</BigidBody1>
        <BigidBody1 marginBottom={MARGIN_BOTTOM}>{t('previewStep.text4')}</BigidBody1>
        <BigidBody1>{t('previewStep.text5')}</BigidBody1>
      </CorrelationSetGuideContent>
    </>
  );
};
