import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidFormStateAndHandlers,
  BigidLoader,
  BigidTabs,
  BigidTabsItem,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import {
  saveNewViewWithDialog,
  deleteViewWithDialog,
  getSavedViews,
  SavedViewItem,
  SavedViewData,
  getUpdatedTabItemsWithSelectedTab,
  OVERVIEW_TAB_ID,
  renameViewWithDialog,
  getRenameDialogFormProps,
  getNewFilterDialogFormProps,
  updateDashboardPreferences,
  getHiddenViews,
  getDashboardPreference,
  getDisplayedTabItems,
  getTabIndexById,
  isSavedViewHasNoChanges,
  isCustomViewSelectedAndExist,
} from '../services/executiveDashboardServices';
import { executiveDashboardEventEmitter, ExecutiveDashboardEvents } from '../services/executiveDasboardEvents';
import { SavedViewsMenu, SAVED_VIEWS_BUTTON_WIDTH } from './SavedViewsMenu';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { getCurrentUser } from '../../../utilities/systemUsersUtils';
import { DashboardToolbarActions } from '../hooks/useDashboardToolbar';

export interface SavedFiltersTabsProps {
  dashboardId: string;
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  isExportButtonDisplayed: boolean;
  activeWidgetIds: string[];
  toolbarActions: DashboardToolbarActions;
  isEmptySystem?: boolean;
}

export const SavedFiltersTabsContainer = styled('div')<{ isExportButtonDisplayed: boolean }>(
  ({ isExportButtonDisplayed }) => ({
    height: '40px',
    width: isExportButtonDisplayed ? 'calc(100% - 110px)' : '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  }),
);

const LoaderWrapper = styled('div')`
  width: 100px;
  display: flex;
`;

const TabsWrapper = styled('div')<{ isEmptySystem: boolean }>(({ isEmptySystem, theme }) => ({
  width: `calc(100% - ${SAVED_VIEWS_BUTTON_WIDTH})`,
  ...(isEmptySystem && {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.vars.tokens.bigid.foregroundDisabled,
    },
  }),
}));

export const SavedFiltersTabs: FC<SavedFiltersTabsProps> = ({
  dashboardId,
  activeFilters,
  toolbarActions,
  isExportButtonDisplayed,
  activeWidgetIds,
  isEmptySystem,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabItems, setTabItems] = useState<BigidTabsItem<SavedViewData>[]>([]);
  const [tabsToDisplay, setTabsToDisplay] = useState<BigidTabsItem<SavedViewData>[]>([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const newFilterFormControls = useRef<BigidFormStateAndHandlers>();
  const remameFilterFormControls = useRef<BigidFormStateAndHandlers>();
  const selectedTabIdRef = useRef(OVERVIEW_TAB_ID);
  const { t } = useLocalTranslation();
  const { updateActiveWidgetIds, updateExternalFilters, updateIsSavedViewsReady, updateIsOverviewTabSelected } =
    toolbarActions;

  useEffect(() => {
    const getUserId = async () => {
      const { name } = await getCurrentUser();
      setCurrentUserId(name);
    };

    getUserId();
  }, []);

  const updateTabs = (newFilters: SavedViewItem[], hiddenViews: string[] = [], selectedViewId?: string) => {
    const newTabItems: BigidTabsItem<SavedViewData>[] = newFilters.map(item => ({
      label: item.text,
      id: item.id,
      data: {
        filter: item.filter?.toolbarFilters,
        isHidden: hiddenViews.includes(item.id),
        owner: item.userId,
        activeWidgetIds: item.filter?.activeWidgetIds,
      },
    }));
    const updatedTabItems = [
      {
        label: t('buttonLables.overview'),
        id: OVERVIEW_TAB_ID,
        data: { filter: [], isHidden: false, owner: 'system' },
      },
      ...newTabItems,
    ];
    if (isCustomViewSelectedAndExist(selectedViewId, newFilters)) {
      const displayedTabs = getDisplayedTabItems(updatedTabItems, isEmptySystem);
      const newSelectedTabIndex = getTabIndexById(selectedViewId, displayedTabs);
      if (newSelectedTabIndex) {
        setSelectedTabIndex(newSelectedTabIndex);
        updateExternalFilters(displayedTabs[newSelectedTabIndex].data.filter);
        updateActiveWidgetIds(displayedTabs[newSelectedTabIndex].data.activeWidgetIds);
        const tabsWithNewSelectedView = getUpdatedTabItemsWithSelectedTab(updatedTabItems, selectedViewId);
        setTabItems(tabsWithNewSelectedView);
        selectedTabIdRef.current = selectedViewId;
      }
    } else {
      setTabItems(updatedTabItems);
    }
  };

  const getEnrichedTabs = (fetchedViews: SavedViewItem[]) => {
    const enrichedTabs = [...tabItems];
    fetchedViews.forEach(view => {
      const isViewAlreadyExist = enrichedTabs.find(existedView => existedView.id === view.id);
      if (!isViewAlreadyExist) {
        enrichedTabs.push({
          label: view.text,
          id: view.id,
          data: {
            filter: view.filter?.toolbarFilters,
            isHidden: false,
            owner: view.userId,
            activeWidgetIds: view.filter?.activeWidgetIds,
          },
        });
      }
    });
    return enrichedTabs;
  };

  const saveView = useCallback(async () => {
    const currentViewLabel = selectedTabIndex ? tabsToDisplay[selectedTabIndex].label : null;
    const currentViewId = selectedTabIndex ? tabsToDisplay[selectedTabIndex].id : null;
    const currentViewOwner = selectedTabIndex ? tabsToDisplay[selectedTabIndex].data?.owner : null;
    const shouldForceNewView = !(currentViewLabel && currentUserId === currentViewOwner);
    const [{ isNewView }] = await saveNewViewWithDialog(
      activeFilters,
      activeWidgetIds,
      dashboardId,
      getNewFilterDialogFormProps(
        newFilterFormControls,
        tabsToDisplay,
        shouldForceNewView,
        currentViewLabel,
        selectedTabIndex,
      ),
      currentViewId,
      shouldForceNewView,
    );
    const fetchedViews = await getSavedViews(dashboardId);

    if (!isNewView) {
      updateTabs(fetchedViews, getHiddenViews(tabItems));
      notificationService.success(t('successMessages.changesSavedMessage'), {
        title: t('successMessages.changesSavedTitle'),
      });
    } else {
      const enrichedTabs = getEnrichedTabs(fetchedViews);
      if (enrichedTabs.length > tabItems.length) {
        const newSelectedTab = enrichedTabs[enrichedTabs.length - 1];
        const updatedTabItems = getUpdatedTabItemsWithSelectedTab(enrichedTabs, newSelectedTab.id);
        setTabItems(updatedTabItems);
        selectedTabIdRef.current = newSelectedTab.id;
        setSelectedTabIndex(tabsToDisplay.length);
        updateExternalFilters(newSelectedTab.data?.filter);
        updateActiveWidgetIds(newSelectedTab.data?.activeWidgetIds);
        updateDashboardPreferences(dashboardId, newSelectedTab.id, getHiddenViews(updatedTabItems));
        notificationService.success(t('successMessages.newViewMessage'), {
          title: t('successMessages.newViewTitle'),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, activeWidgetIds, tabItems, tabsToDisplay]);

  const getPrevTab = useCallback(
    (tabId: string) => {
      const currentIndex = getTabIndexById(tabId, tabsToDisplay);
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;
      return tabsToDisplay[prevIndex];
    },
    [tabsToDisplay],
  );

  const selectPrevTab = useCallback(
    (currentTabId: string, updatedTabs: BigidTabsItem<SavedViewData>[]) => {
      const prevTab = getPrevTab(currentTabId);
      const updatedTebItems = getUpdatedTabItemsWithSelectedTab(updatedTabs, prevTab.id);
      setTabItems(updatedTebItems);
      selectedTabIdRef.current = prevTab.id;
      setSelectedTabIndex(prevIndex => prevIndex - 1);
      updateExternalFilters(prevTab.data?.filter);
      updateActiveWidgetIds(prevTab.data?.activeWidgetIds, !prevTab.data?.activeWidgetIds);
      updateDashboardPreferences(dashboardId, prevTab.id, getHiddenViews(updatedTebItems));
    },
    [dashboardId, getPrevTab, updateActiveWidgetIds, updateExternalFilters],
  );

  const hideCurrentTab = useCallback(
    (tabId: string) => {
      const updatedTabs = [...tabItems];
      const tabIndex = updatedTabs.findIndex(tab => tab.id === tabId);
      updatedTabs[tabIndex].data.isHidden = true;
      selectPrevTab(tabId, updatedTabs);
    },
    [selectPrevTab, tabItems],
  );

  const onShowViewFromMenu = (tabId: string) => {
    const clickedViewIndex = tabItems.findIndex(tab => tab.id === tabId);
    if (tabItems[clickedViewIndex].data.isHidden) {
      const updatedTabs = [...tabItems];
      const tabIndex = updatedTabs.findIndex(tab => tab.id === tabId);
      updatedTabs[tabIndex].data.isHidden = false;
      setTabItems(getUpdatedTabItemsWithSelectedTab(updatedTabs, tabId));
      selectedTabIdRef.current = tabId;
      const newDisplayTabItems = getDisplayedTabItems(updatedTabs, isEmptySystem);
      const newSelectedIndex = getTabIndexById(tabId, newDisplayTabItems);
      setSelectedTabIndex(newSelectedIndex);
      updateExternalFilters(updatedTabs[tabIndex].data?.filter);
      updateActiveWidgetIds(updatedTabs[tabIndex].data?.activeWidgetIds, !updatedTabs[tabIndex].data?.activeWidgetIds);
      updateDashboardPreferences(dashboardId, selectedTabIdRef.current, getHiddenViews(updatedTabs));
    } else {
      onTabClick(clickedViewIndex, tabItems[clickedViewIndex]);
    }
  };

  const onHideCurrentView = useCallback(() => {
    hideCurrentTab(selectedTabIdRef.current);
  }, [hideCurrentTab]);

  const onTabClick = (value: number, tab: BigidTabsItem<any>) => {
    setTabItems(getUpdatedTabItemsWithSelectedTab(tabItems, tab.id));
    selectedTabIdRef.current = tab.id;
    setSelectedTabIndex(getTabIndexById(tab.id, tabsToDisplay));
    updateDashboardPreferences(dashboardId, tab.id, getHiddenViews(tabItems));
    updateExternalFilters(tab.data?.filter);
    updateActiveWidgetIds(tab.data?.activeWidgetIds, !tab.data?.activeWidgetIds);
    updateIsOverviewTabSelected(tab.id === OVERVIEW_TAB_ID);
  };

  const onDeleteView = async (filterId: string, filterName: string) => {
    const isFilterDeleted = await deleteViewWithDialog(filterId, filterName);
    if (isFilterDeleted) {
      const updatedTabs = [...tabItems];
      const deletedTabIndex = updatedTabs.findIndex(tab => tab.id === filterId);
      const currentTabIndex = updatedTabs.findIndex(tab => tab.id === selectedTabIdRef.current);
      updatedTabs.splice(deletedTabIndex, 1);
      setTabItems(updatedTabs);
      if (selectedTabIdRef.current === filterId) {
        setSelectedTabIndex(0);
        updateExternalFilters([]);
        updateActiveWidgetIds([], true);
        updateDashboardPreferences(dashboardId, OVERVIEW_TAB_ID, getHiddenViews(updatedTabs));
      }
      if (currentTabIndex > deletedTabIndex) {
        setSelectedTabIndex(prevIndex => prevIndex - 1);
      }
    }
  };

  const onRenameView = async (filterId: string, filterName: string) => {
    remameFilterFormControls.current = null;
    const { text: newTabLabel } = await renameViewWithDialog(
      filterId,
      filterName,
      getRenameDialogFormProps(remameFilterFormControls, tabItems, filterName),
    );
    if (newTabLabel) {
      const updatedTabs = [...tabItems];
      const tabIndex = updatedTabs.findIndex(tab => tab.id === filterId);
      updatedTabs[tabIndex].label = newTabLabel;
      setTabItems(updatedTabs);
    }
  };

  useEffect(() => {
    const updateSavedFilters = async () => {
      try {
        const fetchedViews = await getSavedViews(dashboardId);
        const { hiddenViews = [], lastViewSelected } = (await getDashboardPreference(dashboardId)) || {};
        updateTabs(fetchedViews, hiddenViews, lastViewSelected);
        updateIsOverviewTabSelected(lastViewSelected === OVERVIEW_TAB_ID);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
        updateIsSavedViewsReady(true);
      }
    };

    updateSavedFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId]);

  useEffect(() => {
    setTabsToDisplay(getDisplayedTabItems(tabItems, isEmptySystem, activeFilters, activeWidgetIds));
  }, [tabItems, isEmptySystem, activeFilters, activeWidgetIds]);

  useEffect(() => {
    if (isSavedViewHasNoChanges(tabsToDisplay[selectedTabIndex], activeFilters, activeWidgetIds)) {
      executiveDashboardEventEmitter.emit(ExecutiveDashboardEvents.DISABLE_VIEW_SAVING);
    } else {
      executiveDashboardEventEmitter.emit(ExecutiveDashboardEvents.ENABLE_VIEW_SAVING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, activeWidgetIds]);

  useEffect(() => {
    const unregisterSaveView = executiveDashboardEventEmitter.addEventListener(
      ExecutiveDashboardEvents.SAVE_VIEW,
      saveView,
    );

    const unregisterHideCurrentView = executiveDashboardEventEmitter.addEventListener(
      ExecutiveDashboardEvents.HIDE_CURRENT_VIEW,
      onHideCurrentView,
    );

    return function cleanup() {
      unregisterSaveView();
      unregisterHideCurrentView();
    };
  }, [saveView, onHideCurrentView]);

  return (
    <SavedFiltersTabsContainer
      isExportButtonDisplayed={isExportButtonDisplayed}
      data-aid={generateDataAid(dashboardId, ['saved-filters-tabs', 'container'])}
    >
      {isLoading ? (
        <LoaderWrapper>
          <BigidLoader thickness={2} size={20} position="relative" />
        </LoaderWrapper>
      ) : (
        <>
          <TabsWrapper isEmptySystem={isEmptySystem}>
            <BigidTabs
              scrollButtonsVisible
              selectedIndex={selectedTabIndex}
              onChange={onTabClick}
              variant="scrollable"
              tabs={tabsToDisplay}
              orientation="horizontal"
            />
          </TabsWrapper>
          <SavedViewsMenu
            onShowView={onShowViewFromMenu}
            onDeleteView={onDeleteView}
            onRenameView={onRenameView}
            tabItems={tabItems}
            currentUserId={currentUserId}
            isEmptySystem={isEmptySystem}
          />
        </>
      )}
    </SavedFiltersTabsContainer>
  );
};
