import { useMemo } from 'react';
import { useCorrelationSetWizardContext } from '../contexts/correlationSetContext';

export const useColumnsList = () => {
  const { correlationSetFormData } = useCorrelationSetWizardContext();

  const optionsList = useMemo(() => {
    return (
      correlationSetFormData?.attributes?.map(attribute => ({
        displayValue: attribute.columnName,
        id: attribute.columnName,
        value: attribute.columnName,
        label: attribute.columnName,
      })) || []
    );
  }, []);

  return {
    optionsList,
  };
};
