export const APP_MENU_HEADER = 'Applications';

export const CREATE_CUSTOM_APP_PAGE_TITLE = 'Add Custom Application';
export const EDIT_CUSTOM_APP_PAGE_TITLE = 'Edit Custom Application';
export const CREATE_CUSTOM_APP_FORM_TITLE = 'Configuration Settings';
export const CREATE_CUSTOM_APP_TITLE = 'Add a Custom Application';
export const CREATE_CUSTOM_APP_DESCRIPTION =
  'Custom applications are extensions to BigID, which provide new functionality such as connectivity to external' +
  ' systems, automation of steps based on BigID REST API, and more. Applications run separately from BigID and can be deployed externally or side-by-side, as long as they are accessible to BigID.';
export const INVALID_NAME_REGEX_MESSAGE =
  'Invalid value. Please use alphanumeric characters, spaces, underscore, dash and parentheses.';
export const INVALID_SCAN_NAME_REGEX_MESSAGE = 'Invalid value. Please use alphanumeric characters, spaces _ - . / () :';
export const ALLOWED_NAME_REGEX = new RegExp(/^[\w\-\s():]+$/);
export const TRAILING_SPACES = new RegExp(/^\s|\s$/);
export const TRAILING_SPACES_MESSAGE = 'Remove extra spaces before and/or after the value.';
