import React, { useEffect, useMemo, MutableRefObject, useReducer } from 'react';
import { isNull, isObject } from 'lodash';
import { isPermitted } from '../../../services/userPermissionsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { $state } from '../../../services/angularServices';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { runDefaultScanProfile } from './services/scan';
import { checkCollaboratorStatus } from './utils/collaboration';
import { CONFIG } from '../../../../config/common';
import { DEFAULT_MUI_OVERRIDES, DEFAULT_REDUCER_STATE } from './constants/constants';
import {
  DataSourceConnectionDialogLayout,
  DataSourceConnectionDialog as Dialog,
} from '../DataSourceConnectionDialog/DataSourceConnectionDialog';
import {
  GlobalStyles as DataSourceConnectionModalGlobalStyles,
  FooterLeftAction,
} from './DataSourceConnectionModalStyles';
import { DataSourceConnectionModalHeader } from './DataSourceConnectionModalHeader';
import { DataSourceTypes as DataSourceTypesV2, DsConnectionFieldSectionsEnum } from '../DataSourceConfiguration/types';
import { DataSourceConnectionModalContent } from './DataSourceConnectionModalContent';
import { DataSourceConnectionModalFooter } from './DataSourceConnectionModalFooter';
import { DataSourceConnectionModalAside } from './DataSourceConnectionModalAside';
import {
  BigidDialog,
  BigidDialogButtonType,
  BigidFormStateAndHandlers,
  BigidFormValues,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidSplitMenuButton } from '../../../components/BigidSplitMenuButton/BigidSplitMenuButton';
import {
  DataSourceConnectionModalOverlay,
  DataSourceConnectionModalOverlaySteps,
} from './DataSourceConnectionModalOverlay';
import { DataSourceConnectionOverlaySuccess } from './DataSourceConnectionOverlay/DataSourceConnectionOverlaySuccess';
import { useLocalTranslation } from './translations';
import { useDataSourceConfigState as useDataSourceConfigStateV2 } from './hooks/useDataSourceConfigState';
import { usePrevious } from './hooks/usePrevious';
import { useForm, EMPTY_ERROR_STATE } from './hooks/useForm';
import { useFormWithValidation as useFormWithExternalValidation } from './hooks/useFormWithValidation';
import { useSubmitDataSource as useSubmitDataSourceV2 } from './hooks/useSubmitDataSource';
import { useUpdateDataSource as useUpdateDataSourceV2 } from './hooks/useUpdateDataSource';
import { useTestConnection as useTestConnectionV2 } from './hooks/useTestConnection';
import { useGetConnectionGuide } from './hooks/useGetConnectionGuide';
import { useDataSourceModalNotificationListener } from './hooks/useDataSourceModalNotificationListener';
import { useDataSourceModalNotification } from './hooks/useDataSourceModalNotification';
import { OAuthAction, OAuthStatus, useOAuth } from './hooks/useOAuth';
import { DEFAULT_REDUCER, getReducerActions } from './config/reducer';
import { useGetTestConnectionStatus } from './hooks/useGetTestConnectionStatus';
import { useGetErrorResolution } from './hooks/useGetErrorResolution';
import { useCollaboration } from './hooks/useCollaboration';
import { useUser } from './hooks/useUser';
import { useGetDataSourceTypes } from './hooks/useGetDataSourceTypes';
import { useGetScanTypes } from '../DataSourceConfiguration/hooks/useGetScanTypes';
import { useInstantScanData } from '../DataSourceConnections/hooks/useInstantScanData';
import { DataSourceConnectionModalProvider } from './DataSourceConnectionModalProvider';
import {
  getDataSourceTypeDisplayName,
  mapBigidSelectOptionToValue,
  mapErrorStringToErrorParts,
  mapErrorStringToTestConnectionError,
  mapErrorsObjectToErrorMessage,
  mapFieldNameToErrorPayload,
  mapTestConnectionErrorToRelatedFields,
  mapTestConnectionResultsForNewExperience,
  mapTestConnectionStatusToOverlayStep,
} from './mappers/connection';
import { generateScanActions } from '../DataSourceConfiguration/config/wizard';
import { DataSourceTestConnectionStatusEnum } from '../DataSourceConfiguration/hooks/useTestConnection';
import {
  DataSourceTestConnectionRowType,
  DataSourcesTestConnectionGrid,
} from '../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { DataSourceConnectionCollaboration } from './DataSourceConnectionCollaboration/DataSourceConnectionCollaboration';
import { setMultipleTouched } from './utils/form';
import { trackEventDataSources, DataSourcesUITrackingEvent } from '../DataSourcesEventTrackerUtils';
import { generateDsDocsUrl } from './utils/utils';
import type { DataSourceConnectionConfigurationState } from './DataSourceConnectionConfiguration';
import type { TestConnectionError } from '../DataSourceConfiguration/constants/enums';
import type { DialogProps as MuiDialogProps } from '@mui/material';
import { BigidSaveIcon } from '@bigid-ui/icons';

type DataSourceConnectionModalProps = {
  isOpen: boolean;
  isEdit?: boolean;
  isStandalone?: boolean;
  isError?: boolean;
  onClose: (reload?: boolean, opts?: DataSourceHandlerOpts) => void;
  onSave?: (reload?: boolean, opts?: DataSourceHandlerOpts) => void;
  onChange?: (values: BigidFormValues) => void;
  onStateChange?: (state: DataSourceConnectionConfigurationState) => void;
  onAddDataSource?: (id?: string) => void;
  source: DataSourceTypesV2;
  dsName: string;
  sectionName?: DsConnectionFieldSectionsEnum;
  dsTypeLabel?: string;
  nameInDocs?: string;
  sourceId?: string;
  form?: MutableRefObject<BigidFormStateAndHandlers>;
  controls?: MutableRefObject<DataSourceConnectionModalControl>;
  shouldAutoConnect?: boolean;
  showDetachedSuccess?: boolean;
  showSidebar?: boolean;
  setShouldAutoConnect?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DataSourceHandlerOpts = {
  sourceId: string;
  scopeChanged?: boolean;
  scopes?: string[];
  wizardStep?: DsConnectionFieldSectionsEnum;
  navigateToEditPage?: boolean;
};

export enum DataSourceConnectionModalAction {
  TEST = 'test',
  SHOW_SUCCESS_MESSAGE = 'showSuccessMessage',
  SUBMIT = 'submit',
  CLOSE = 'close',
  UPDATE = 'update',
}

export type DataSourceConnectionModalControl = {
  handle: (action: DataSourceConnectionModalAction, args?: any[]) => Promise<void>;
  state: DataSourceConfigStateV2;
  onSuccess?: () => void;
  onError?: () => void;
  isInitiated: boolean;
};

export type DataSourceConfigStateV2 = Omit<ReturnType<typeof useDataSourceConfigStateV2>, 'configDataSourceState'> & {
  configDataSourceState: DataSourceConnectionConfigurationState;
};

type DataSourceFormProps = {
  name: string;
};

const NO_ACTIONS: unknown[] = [];
const NO_SCANS: BigidDialogButtonType[] = null;
const FORM_EMPTY = [{}];

export const DataSourceConnectionModal = ({
  source,
  sourceId,
  dsName,
  dsTypeLabel,
  nameInDocs,
  isOpen,
  isEdit = false,
  isError = false,
  isStandalone = false,
  shouldAutoConnect = false,
  showDetachedSuccess = false,
  showSidebar = true,
  sectionName,
  form,
  controls,
  onClose,
  onSave,
  onChange,
  onStateChange,
  onAddDataSource,
  setShouldAutoConnect,
}: DataSourceConnectionModalProps): JSX.Element => {
  const [state, dispatch] = useReducer(DEFAULT_REDUCER, {
    ...DEFAULT_REDUCER_STATE,
    layout: isStandalone ? DataSourceConnectionDialogLayout.STANDALONE : DataSourceConnectionDialogLayout.FULL,
    step: null,
  });
  const {
    setStep,
    setLayout,
    setShowCollaboration,
    setShowCollaborationSuccess,
    setShowTestResults,
    setIsFirstAttempt,
    setShowSaveBeforeClose,
  } = getReducerActions(dispatch);

  const {
    step,
    layout,
    showCollaboration,
    showCollaborationSuccess,
    showSaveBeforeClose,
    showTestResults,
    isFirstAttempt,
  } = state;

  const { t } = useLocalTranslation();
  const { email } = useUser();
  const { control, setErrors, useWatch, useValidation, getValues } =
    useFormWithExternalValidation<DataSourceFormProps>(form);
  const { validate } = useValidation(sourceId, {
    onError: (_field, _message, { isAppliedFromDialog }) => isAppliedFromDialog && setShowSaveBeforeClose(false),
  });
  const { control: cachedControl, isCached: isExternalControl } = useForm();
  const { watch, trigger } = useWatch();
  const [{ value: authenticationType }] = watch('authenticationType') ?? FORM_EMPTY;

  const dataSourceConfigState = useDataSourceConfigStateV2(cachedControl, {
    loadInitialDataFromTemplate: !isExternalControl,
    onChange: onStateChange,
  }) as DataSourceConfigStateV2;
  const { isLoading: isLoadingFormData, initialValues } = dataSourceConfigState.configDataSourceState;

  useDataSourceModalNotificationListener({ isOpen, control, sourceId });
  const {
    emitTestConnectionSucceeded,
    emitTestConnectionError,
    emitSaveDataSourceError,
    displayTestConnectionInProgressNotification,
    displayScanFailedNotification,
    displayScanStartedNotification,
  } = useDataSourceModalNotification({
    control,
    source,
  });

  const {
    query: { data: collaboration, isLoading: isLoadingCollaborators },
  } = useCollaboration(sourceId);

  const isFormReady = isLoadingFormData ? false : !!source && !!initialValues;
  const isGetConnectionGuideEnabled = isFormReady;
  const isDsCollaborator = checkCollaboratorStatus(collaboration?.users, email, isLoadingCollaborators);

  const {
    data: content,
    isLoading: isLoadingConnectionInfo,
    isIdle: isConnectionInfoIdle,
    isIdleTimeout: isConnectionInfoIdleTimeout,
  } = useGetConnectionGuide(source, authenticationType, { query: { enabled: isGetConnectionGuideEnabled } });

  const {
    onSubmitHandler,
    isLoading: isSubmitting,
    isSubmitted,
    isError: isSubmitError,
    data: submitted,
    getPayload: getSubmitPayload,
    errors: submitError,
  } = useSubmitDataSourceV2(dataSourceConfigState, control, {
    upsertId: sourceId,
    onSuccess: async () => {
      control.current.setAllTouched(false);
      onSave?.();
    },
  });

  const { data: dataSourceTypes, isLoading: isLoadingDsTypes } = useGetDataSourceTypes(false, {
    query: {
      enabled: !dsTypeLabel,
    },
  });

  const {
    onUpdateHandler,
    isLoading: isUpdating,
    isError: isUpdateError,
    errors: updateError,
  } = useUpdateDataSourceV2(sourceId, dataSourceConfigState, control, {
    onSuccess: () => {
      control.current.setAllTouched(false);
      onSave?.();
    },
  });

  const submitHandler = async (): Promise<boolean> => {
    const isSuccess = await Promise.resolve(!isSubmitted ? onSubmitHandler() : onUpdateHandler(false));
    return isSuccess;
  };

  const {
    onTestHandler,
    getHighlightedWordsForLog,
    isLoading: isTestInProgress,
    isError: isTestConnectivityError,
    isErrorResolutionCached,
    status,
    errors,
    error,
    enabled,
    data,
    addListenerToTestConnectionEvent,
  } = useTestConnectionV2(dataSourceConfigState, control, {
    onSuccess: async () => {
      emitTestConnectionSucceeded();
      controls?.current?.onSuccess?.();
      !isSubmitted ? onSubmitHandler(false) : onUpdateHandler(false);
    },
    onError: async (error, resolvedErrorType) => {
      //@info touch the fields again after being reset by validateAndSubmit function
      const touchedFields = Object.keys(control.current?.touchedFields ?? {});
      // eslint-disable-next-line prettier/prettier
      setMultipleTouched(touchedFields, control);
      emitTestConnectionError();
      controls?.current?.onError?.();

      handleFormErrors(resolvedErrorType, error);
    },
  });

  const { data: scanTypes, isLoading: isLoadingScanTypes } = useGetScanTypes(source, {
    query: { enabled: !!source },
  });

  const { executeQuickScan, getSupportedTemplates, isScanTemplatesLoading, isScanTemplatesEnabled } =
    useInstantScanData();

  const previousStatus = usePrevious(status);
  const prevAuthenticationType = usePrevious(authenticationType);

  const { configDataSourceState } = dataSourceConfigState;

  const isScanAvailable = isSubmitting || isUpdating ? false : isSubmitted;
  const isLoadingScanActions = isLoadingScanTypes || isScanTemplatesLoading;
  const isTestingPermitted = isPermitted(DATA_SOURCES_PERMISSIONS.TEST_CONNECTION.name);
  const isPrerequisitesStage = layout === DataSourceConnectionDialogLayout.SIMPLE;
  const isTestConnectionEnabled = isPrerequisitesStage ? true : enabled && isTestingPermitted && !isTestInProgress;
  const isGetTestStatusEnabled = !!(sourceId && isOpen && DataSourceTestConnectionStatusEnum.notStarted === status);
  const isSaveAsDraftEnabled =
    !isSubmitting && !configDataSourceState?.isLoading && !configDataSourceState?.isLoadingData;
  const isLoadingConnectionGuide = isConnectionInfoIdleTimeout
    ? false
    : isLoadingConnectionInfo || isConnectionInfoIdle;
  const isErrorResolutionEnabled =
    isError || (isStandalone ? isTestConnectivityError : isTestConnectivityError || isErrorResolutionCached);

  const [errorType, errorMessage] = error ? mapErrorStringToErrorParts(error) : [null, null];
  const shouldTriggerAutoConnection = shouldAutoConnect && isFormReady && isTestConnectionEnabled;
  const { name: dataSourceName } = getValues?.() ?? {};
  const dataSourceTypeLabel = isLoadingDsTypes ? dsTypeLabel : getDataSourceTypeDisplayName(dataSourceTypes, source);

  // @info handlers
  const handleDsValidationName = async (isAppliedFromDialog = false) => validate('name', { isAppliedFromDialog });

  const handleShowCollaborationSuccess = async () => setShowCollaborationSuccess(true);

  const handleSaveAndClose = async () => {
    const { name } = control.current.getValues();
    const shouldReload = isSubmitted && name !== sourceId;
    const isSuccess = await submitHandler();
    if (isSuccess) {
      onClose(shouldReload, { sourceId: name });
      displayTestConnectionInProgressNotification();
    }
  };

  const handleCloseAfterSuccessfulConnect = () => {
    const { name } = control.current.getValues();
    const shouldReload = isSubmitted && name !== sourceId;
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SUCCESS_CLOSE_CLICK, {
      dsType: source,
      dsName: name,
    });
    onClose(shouldReload, { sourceId: name });
  };

  const handleClose = () =>
    !control.current.isSubmitted && control.current.formTouched
      ? setShowSaveBeforeClose(true)
      : handleCloseAfterSuccessfulConnect();

  const handleGoToEditConnection = () => {
    setShowTestResults(false);
    handleBackToEdit();
  };

  const handleGoToConnectionEditPage = () => {
    const { name } = control.current.getValues() ?? {};
    const shouldReload = isSubmitted && name !== sourceId;

    const handlerRedirect = () =>
      $state.go(CONFIG.states.CONFIG_DATA_SOURCE, {
        id: shouldReload ? name : submitted.name,
        isAfterSave: true,
        wizardStep: DsConnectionFieldSectionsEnum.details,
        dsLabel: dsTypeLabel,
      });

    return isEdit || isStandalone
      ? onClose(shouldReload, { sourceId: name, wizardStep: DsConnectionFieldSectionsEnum.details })
      : handlerRedirect();
  };

  const handleSubmitWithValidation = async () => {
    const { name, scope } = control.current.getValues() ?? {};
    const shouldReload = name !== sourceId;
    const hasScopeChanged = !!control.current?.touchedFields?.['scope'];
    const scopes = mapBigidSelectOptionToValue(scope ?? []);

    await control.current.validate();

    const isValidDsName = await handleDsValidationName();
    if (!isValidDsName) return;

    const isSuccess = await submitHandler();
    isSuccess && (await onSave?.(shouldReload, { sourceId: name, scopeChanged: hasScopeChanged, scopes }));
  };

  const handleValidateAndSaveAsDraft = async (isAppliedFromDialog = false) => {
    await control.current.validate();
    const isValidDsName = await handleDsValidationName(isAppliedFromDialog);
    if (!isValidDsName) return false;

    const isSuccess = await submitHandler();
    if (isAppliedFromDialog) {
      trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_CLOSE_SAVE_AS_DRAFT_CLICK, {
        dsType: source,
        dsName: name,
      });
    } else {
      trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SAVE_AS_DRAFT_CLICK, {
        dsType: source,
        dsName: name,
      });
    }

    return isSuccess;
  };

  const handleSubmitBeforeClose = async (isAppliedFromDialog = false) => {
    const { name } = control.current.getValues() ?? {};
    const shouldReload = isSubmitted && name !== sourceId;

    const isSuccess = await handleValidateAndSaveAsDraft(isAppliedFromDialog);

    isSuccess && setShowSaveBeforeClose(false);
    if (isSuccess) {
      onClose(shouldReload, { sourceId: name });
    }
  };

  const handleSkipToDetails = async () => {
    const { name } = control.current.getValues() ?? {};

    const isSuccess = await handleValidateAndSaveAsDraft();

    isSuccess && setShowSaveBeforeClose(false);
    if (isSuccess) {
      onClose(true, { sourceId: name, navigateToEditPage: true, wizardStep: DsConnectionFieldSectionsEnum.details });
    }
  };

  const handleCloseWithoutSaving = () => {
    const { name } = control.current.getValues() ?? {};
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_CLOSE_CLICK, {
      dsType: source,
      dsName: name,
    });
    onClose();
  };

  const handleTestConnection = async () => {
    if (layout === DataSourceConnectionDialogLayout.SIMPLE) {
      setLayout(DataSourceConnectionDialogLayout.FULL);
      return;
    }

    const body = control.current.getValues();

    await control.current.validateAndSubmit(
      async () => {
        const isValidDsName = await handleDsValidationName();
        if (!isValidDsName) return;
        onTestHandler({
          ...body,
          isFirstAttempt,
          errorResolution: configDataSourceState?.errorResolution,
          preConnectionData: content?.data,
        });
        if (isFirstAttempt) {
          setIsFirstAttempt(false);
        }
      },
      errors => {
        const [field] = Object.keys(errors ?? {});
        if (field) {
          const element = document.querySelector<HTMLInputElement>(`input[name="${field}"]`);
          // @info Focus first error input
          element?.focus();
        }
      },
    );
  };

  const handleShowTestResults = () => {
    const { name } = control.current.getValues() ?? {};
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SUCCESS_VIEW_RESULTS_CLICK, {
      dsType: source,
      dsName: name,
    });
    setShowTestResults(true);
  };

  const handleBackToEdit = () => setStep(null);

  const handleStartScan = async () => {
    const { name } = control.current.getValues() ?? {};
    const shouldReload = isSubmitted && name !== sourceId;

    if (isSubmitted) {
      trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SUCCESS_SCAN_NOW_CLICK, {
        dsType: source,
        dsName: name,
      });
      runDefaultScanProfile(name, source, {
        onSuccess: () => displayScanStartedNotification(),
        onError: () => displayScanFailedNotification(),
      });
      onClose(shouldReload, { sourceId: name });
    }
  };

  const handleStartCustomScan = () => {
    const { name } = control.current.getValues();
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SUCCESS_CREATE_CUSTOM_SCAN_CLICK, {
      dsType: source,
      dsName: name,
    });
    const isCreateScanWizardEnabled = getApplicationPreference('ENABLE_SCAN_TEMPLATES');

    isCreateScanWizardEnabled
      ? $state.go(CONFIG.states.CREATE_SCAN, {
          prefill: {
            name: `Custom Scan For ${name}`,
            dataSourcesPreselected: [{ name, type: source }],
            isPrefillDsStep: true,
          },
        })
      : $state.go(CONFIG.states.CREATE_SCAN_PROFILE, {
          prefill: { name },
        });
  };

  const handleFormChange = (values: BigidFormValues) => {
    const [{ value: currentAuthenticationType }] = values?.authenticationType ?? [{}];
    const shouldTriggerChange = prevAuthenticationType !== currentAuthenticationType;
    shouldTriggerChange && trigger();
    onChange?.(values);
  };

  const handleSaveWithName = async (dataSourceName: string) => {
    control.current?.setValue('name')(dataSourceName);

    const isSuccess = await Promise.resolve(!isSubmitted ? onSubmitHandler() : onUpdateHandler(false));
    return isSuccess;
  };

  const handleModalAction = async (action: DataSourceConnectionModalAction, ...args: any[]) => {
    const handleAction = modalActions[action];
    await handleAction?.(...args);
  };

  const handleGoToModalEditConnection = async () => setStep(null);

  const handleUpdateModalState = async (...args: any[]) => {
    const [state] = args as unknown as [Partial<DataSourceConnectionConfigurationState>];
    dataSourceConfigState.updateState({ ...state });
    onStateChange?.({ ...dataSourceConfigState.configDataSourceState, ...state });
  };

  const handleFormErrors = async (resolvedErrorType: string, error: string) => {
    const errorType = resolvedErrorType ?? (error && mapErrorStringToTestConnectionError(error));

    if (!errorType) {
      return;
    }
    const payload = getSubmitPayload();
    const fields = mapTestConnectionErrorToRelatedFields(
      errorType as TestConnectionError,
      configDataSourceState,
      control,
      payload,
    );
    const errors = mapFieldNameToErrorPayload(fields);
    await control?.current?.validate();
    setErrors(errors);
  };

  useEffect(() => {
    control.current?.clear?.();
  }, [control, initialValues]);

  useEffect(() => {
    const shouldNotUpdateStep = sourceId && !previousStatus && status === DataSourceTestConnectionStatusEnum.notStarted;

    if (shouldNotUpdateStep) {
      return;
    }

    setStep(
      !isTestInProgress
        ? mapTestConnectionStatusToOverlayStep(status, showDetachedSuccess)
        : DataSourceConnectionModalOverlaySteps.IN_PROGRESS,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isTestInProgress]);

  useEffect(() => {
    const isFieldError = isObject(errors) || isNull(errors);
    isFieldError && setErrors(errors ?? EMPTY_ERROR_STATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    submitted?.name && onAddDataSource?.(submitted.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted?.name]);

  useEffect(() => {
    if (isOpen) {
      trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_PAGE_VIEW, {
        dsType: source,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (shouldTriggerAutoConnection) {
      setShouldAutoConnect(false);
      handleTestConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTriggerAutoConnection]);

  useEffect(() => {
    if (controls) {
      controls.current = {
        ...controls.current,
        handle: handleModalAction,
        state: dataSourceConfigState,
        isInitiated: true,
      };
    }
  });

  useEffect(() => {
    const isError = isSubmitted ? isUpdateError : isSubmitError;
    const error = isSubmitted ? updateError : submitError;
    const errorMessage = typeof error === 'string' ? error : mapErrorsObjectToErrorMessage(error);

    isError && emitSaveDataSourceError(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError, submitError, isSubmitError, isUpdateError]);

  useGetTestConnectionStatus(sourceId, {
    query: {
      enabled: isGetTestStatusEnabled,
      onSuccess: ({ seeEventID, isInProgress }) => {
        if (isInProgress) {
          addListenerToTestConnectionEvent(seeEventID);
        }
      },
    },
  });

  useGetErrorResolution(sourceId, isOpen, {
    query: {
      enabled: isOpen && isFormReady && !!sourceId,
      onSuccess: ({ errorResolution, error }) => {
        dataSourceConfigState.updateState({ errorResolution, testError: error });
        handleFormErrors(errorResolution?.errorType, error);
      },
    },
  });

  const { oAuthHandlers, oauthStatus } = useOAuth({
    async oauthSubmitHandler(oauthAction) {
      oauthAction === OAuthAction.CONNECTING && setStep(DataSourceConnectionModalOverlaySteps.IN_PROGRESS);
      return await submitHandler();
    },
    validationFunctions: [
      handleDsValidationName,
      async () => {
        await control?.current?.validate();
        return true;
      },
    ],
    onError() {
      handleBackToEdit();
    },
  });

  const modalActions: Record<DataSourceConnectionModalAction, (...args: any[]) => Promise<void>> = {
    [DataSourceConnectionModalAction.SHOW_SUCCESS_MESSAGE]: handleShowCollaborationSuccess,
    [DataSourceConnectionModalAction.SUBMIT]: handleSubmitWithValidation,
    [DataSourceConnectionModalAction.TEST]: handleTestConnection,
    [DataSourceConnectionModalAction.CLOSE]: handleGoToModalEditConnection,
    [DataSourceConnectionModalAction.UPDATE]: handleUpdateModalState,
  };

  const saveBeforeCloseActions = [
    {
      component: SecondaryButton,
      dataAid: 'DataSourceConnectionModal-close',
      isClose: true,
      onClick: handleCloseWithoutSaving,
      text: t('buttons.discard'),
    },
    {
      component: PrimaryButton,
      dataAid: 'DataSourceConnectionModal-save',
      disabled: isSubmitting,
      isClose: false,
      onClick: () => handleSubmitBeforeClose(true),
      text: t('buttons.save'),
    },
  ];

  const showTestResultsActions = [
    {
      component: SecondaryButton,
      dataAid: 'DataSourceConnectionModal-edit',
      disabled: !isSubmitted,
      isClose: true,
      onClick: handleGoToEditConnection,
      text: t('buttons.edit'),
    },
    {
      component: PrimaryButton,
      dataAid: 'DataSourceConnectionModal-scan',
      disabled: !isSubmitted,
      isClose: true,
      onClick: handleStartScan,
      text: t('buttons.scan'),
    },
  ];

  const footerActions = [
    {
      component: TertiaryButton,
      dataAid: 'DataSourceConnectionModal-saveAsDraft',
      as: FooterLeftAction,
      startIcon: <BigidSaveIcon />,
      disabled: !isSaveAsDraftEnabled,
      onClick: () => handleSubmitBeforeClose(),
      text: t(isEdit ? 'buttons.save' : 'buttons.saveAsDraft'),
    },
    {
      component: TertiaryButton,
      dataAid: 'DataSourceConnectionModal-skipToDetails',
      disabled: !isSaveAsDraftEnabled,
      onClick: handleSkipToDetails,
      text: t('buttons.skipToDetails'),
    },
    {
      component: PrimaryButton,
      dataAid: 'DataSourceConnectionModal-testConnection',
      disabled: !isTestConnectionEnabled,
      onClick: handleTestConnection,
      text: t('buttons.connect'),
    },
  ];

  const scanActions = !isLoadingScanActions
    ? generateScanActions(
        sourceId,
        getSupportedTemplates(scanTypes),
        executeQuickScan,
        scanTypes,
        control?.current?.getValues,
      )
    : [];

  const [mainScanAction] = scanActions ?? [];

  const scanButtonActions = [
    {
      id: 'scan',
      component: BigidSplitMenuButton,
      dataAid: 'DataSourceConnectionModal-action-scan-button',
      disabled: !isScanTemplatesEnabled || isLoadingScanActions || !mainScanAction,
      onClick: () => mainScanAction?.execute(),
      text: t('buttons.scan'),
      type: 'secondary',
      actions: scanActions
        ?.map(({ id, label, execute }) => ({
          id,
          text: label,
          label,
          type: 'secondary',
          dataAid: `DataSourceConnectionModal-action-scan-${id}`,
          onClick: execute,
        }))
        ?.filter((_, index) => index !== 0),
    },
  ];

  const { gridRowType, tablesResult } = useMemo(() => {
    return mapTestConnectionResultsForNewExperience(data?.tablesResult || []);
  }, [data?.tablesResult]);

  const documentationUrl = generateDsDocsUrl(nameInDocs);

  const dataSourceProviderConfig = { ...dataSourceConfigState, oAuthHandlers };

  return (
    <DataSourceConnectionModalProvider
      isDsCollaborator={isDsCollaborator}
      isStandalone={isStandalone}
      config={dataSourceProviderConfig}
      sourceId={sourceId}
      dsTypeLabel={dsTypeLabel || dataSourceTypeLabel}
      dsType={source}
    >
      <DataSourceConnectionModalGlobalStyles />
      {isStandalone ? (
        <Dialog
          showSidebar={showSidebar}
          layout={layout}
          config={{}}
          overlay={
            step && (
              <DataSourceConnectionModalOverlay
                owner={collaboration?.owner}
                // @info new scan actions included only as part of the new connection tab experience for now
                scans={isScanTemplatesEnabled ? scanButtonActions : NO_SCANS}
                step={step}
                isBackgroundTransparent={false}
                isScanAvailable={isScanAvailable}
                onStartScan={handleStartScan}
                onStartCustomScan={handleStartCustomScan}
                onBackToEdit={handleGoToConnectionEditPage}
                onShowResults={handleShowTestResults}
                onSave={handleSaveAndClose}
              />
            )
          }
        >
          <Dialog.Content>
            <DataSourceConnectionModalContent
              layout={layout}
              source={source}
              sourceId={sourceId}
              shouldForceRefreshFromSource={isEdit}
              logs={getHighlightedWordsForLog}
              errorDescription={errorMessage}
              errorType={errorType}
              control={control}
              onChange={handleFormChange}
              isLoading={oauthStatus === OAuthStatus.DISCONNECTING}
              sectionName={sectionName ?? DsConnectionFieldSectionsEnum.connection}
            />
          </Dialog.Content>
          <Dialog.Aside>
            <DataSourceConnectionModalAside
              logs={getHighlightedWordsForLog}
              layout={layout}
              rowType={gridRowType}
              rows={tablesResult}
              documentationUrl={documentationUrl}
              content={content?.data ?? []}
              loading={isLoadingConnectionGuide}
              errorInsights={isErrorResolutionEnabled ? configDataSourceState?.errorResolution : null}
              authType={authenticationType}
              onTest={handleTestConnection}
              onShowTestResults={handleShowTestResults}
              onSaveDataSource={handleSaveWithName}
              dataSourceName={dataSourceName}
            />
          </Dialog.Aside>
        </Dialog>
      ) : (
        <BigidDialog
          muiOverrides={DEFAULT_MUI_OVERRIDES as unknown as MuiDialogProps}
          fixedHeight
          borderTop
          maxWidth={isPrerequisitesStage ? 'sm' : 'xl'}
          title={(<DataSourceConnectionModalHeader dsType={source} title={dsName} />) as unknown as string}
          isOpen={isOpen}
          onClose={handleClose}
        >
          <Dialog
            showSidebar={showSidebar}
            layout={layout}
            config={{}}
            overlay={
              step && (
                <DataSourceConnectionModalOverlay
                  owner={collaboration?.owner}
                  step={step}
                  isBackgroundTransparent={step !== DataSourceConnectionModalOverlaySteps.SUCCESS}
                  isScanAvailable={isSubmitted}
                  onStartScan={handleStartScan}
                  onStartCustomScan={handleStartCustomScan}
                  onBackToEdit={handleGoToConnectionEditPage}
                  onShowResults={handleShowTestResults}
                  onSave={handleSaveAndClose}
                />
              )
            }
          >
            <Dialog.Content>
              <DataSourceConnectionModalContent
                layout={layout}
                source={source}
                sourceId={sourceId}
                shouldForceRefreshFromSource={isEdit}
                logs={getHighlightedWordsForLog}
                errorDescription={errorMessage}
                errorType={errorType}
                control={control}
                onChange={handleFormChange}
                isLoading={oauthStatus === OAuthStatus.DISCONNECTING}
                sectionName={sectionName ?? DsConnectionFieldSectionsEnum.connection}
              />
            </Dialog.Content>
            <Dialog.Footer>
              <DataSourceConnectionModalFooter buttons={footerActions} />
            </Dialog.Footer>
            <Dialog.Aside>
              <DataSourceConnectionModalAside
                logs={getHighlightedWordsForLog}
                layout={layout}
                documentationUrl={documentationUrl}
                content={content?.data ?? []}
                loading={isLoadingConnectionGuide}
                errorInsights={isErrorResolutionEnabled ? configDataSourceState?.errorResolution : null}
                authType={authenticationType}
                onTest={handleTestConnection}
                onShowTestResults={handleShowTestResults}
                onSaveDataSource={handleSaveWithName}
                dataSourceName={dataSourceName}
              />
            </Dialog.Aside>
          </Dialog>
        </BigidDialog>
      )}
      {showTestResults && (
        <BigidDialog
          title={t('testResults')}
          isOpen={showTestResults}
          buttons={isStandalone ? (NO_ACTIONS as BigidDialogButtonType[]) : showTestResultsActions}
          onClose={() => setShowTestResults(false)}
          maxWidth={gridRowType === DataSourceTestConnectionRowType.STRUCTURED || gridRowType === null ? 'md' : 'sm'}
        >
          <DataSourcesTestConnectionGrid
            gridRowType={gridRowType ?? DataSourceTestConnectionRowType.STRUCTURED}
            rows={tablesResult}
          />
        </BigidDialog>
      )}
      {showSaveBeforeClose && (
        <BigidDialog
          title={t('saveDataSource.header')}
          isOpen={showSaveBeforeClose}
          buttons={saveBeforeCloseActions}
          onClose={() => setShowSaveBeforeClose(false)}
        >
          {t('saveDataSource.content')}
        </BigidDialog>
      )}
      {showCollaboration && (
        <BigidDialog
          title={t('collaboration.header')}
          isOpen={showCollaboration}
          onClose={() => setShowCollaboration(false)}
        >
          <DataSourceConnectionCollaboration
            name={dataSourceName}
            shouldShowSaveBeforeCreate={!sourceId}
            onSave={handleSaveWithName}
            onClose={() => setShowCollaboration(false)}
            dsTypeLabel={dsTypeLabel || dataSourceTypeLabel}
            type={source}
            sourceId={sourceId}
          />
        </BigidDialog>
      )}
      {showCollaborationSuccess && (
        <BigidDialog
          borderTop={false}
          title=""
          isOpen={showCollaborationSuccess}
          onClose={() => setShowCollaborationSuccess(false)}
        >
          <DataSourceConnectionOverlaySuccess
            dataAid="DataSourceConnectionSuccessModal"
            isDetached
            owner={collaboration?.owner}
            // @info new scan actions included only as part of the new connection tab experience for now
            scans={isScanTemplatesEnabled ? scanButtonActions : NO_SCANS}
            isScanAvailable={isScanAvailable}
            onStartScan={handleStartScan}
            onBackToEdit={() => setShowCollaborationSuccess(false)}
            onStartCustomScan={handleStartCustomScan}
            onShowResults={handleShowTestResults}
          />
        </BigidDialog>
      )}
    </DataSourceConnectionModalProvider>
  );
};
