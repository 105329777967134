import React, { FC, useMemo, useState } from 'react';

import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidHeading2,
  BigidToggleButton,
  BigidTrendWidget,
  BigidWidgetContainer,
} from '@bigid-ui/components';
import { BigidInfoIcon, BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import { WidgetProps } from '../PrivacyExecutiveDashboardTypes';
import { Menu } from './helpers/HelperComponents';
import {
  PerformanceMeasuresSection,
  PerformanceMeasuresValue,
  PerformanceMeasuresValueWrapper,
  PerformanceMeasuresWrapper,
} from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';

interface PerformanceMeasuresWidgetData {
  type: 'Average time' | 'Amount';
  data: {
    category: 'Assessments' | 'Privacy requests' | 'Processes' | 'Data risks';
    value: number;
    previousValue: number;
  }[];
}

const TABS = [
  { label: 'Avg. time', value: 'avg' },
  { label: 'Amount', value: 'amt' },
];

export const PerformanceMeasuresWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading } = useFetchWidgetData(fetchPerformanceMeasuresWidgetData);
  const [currentTab, setCurrentTab] = useState(0);
  const isAverageTime = currentTab === 0;

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: false, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <>
      <BigidWidgetContainer
        content={
          <PerformanceMeasuresWrapper>
            {data?.[currentTab]?.data?.map(({ category, value, previousValue }) => {
              const absoluteDifference = previousValue - value;
              const percentageDifference = (absoluteDifference * 100) / previousValue;
              return (
                <PerformanceMeasuresSection key={category}>
                  <PerformanceMeasuresValueWrapper>
                    <BigidHeading2>
                      {isAverageTime ? (
                        t('performanceMeasures.numberOfDays', { numberOfDays: value })
                      ) : (
                        <>
                          {value}
                          <PerformanceMeasuresValue> {` / ${previousValue}`}</PerformanceMeasuresValue>
                        </>
                      )}
                    </BigidHeading2>
                    {previousValue && value !== previousValue && (
                      <BigidTrendWidget
                        hasBackground
                        growth={isAverageTime ? absoluteDifference : `${percentageDifference}%`}
                      />
                    )}
                  </PerformanceMeasuresValueWrapper>
                  <BigidBody1>{category}</BigidBody1>
                </PerformanceMeasuresSection>
              );
            })}
          </PerformanceMeasuresWrapper>
        }
        titleConfig={{
          title,
          subtitle: t('performanceMeasures.subTitle'),
          iconAfter: {
            icon: BigidInfoIcon,
            tooltip: t('performanceMeasures.tooltip'),
          },
        }}
        actionsSlot={
          <>
            <BigidToggleButton
              size="small"
              toggleButtons={TABS}
              initialSelected={TABS[currentTab].value}
              onChange={(_, newValue) => setCurrentTab(+(newValue === TABS[1].value))}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
      />
    </>
  );
};

const fetchPerformanceMeasuresWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<PerformanceMeasuresWidgetData[]> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 3000, data);
  });
};

const data = [
  {
    type: 'Average time',
    data: [
      {
        category: 'Assessments',
        value: 30,
        previousValue: 33,
      },
      {
        category: 'Privacy requests',
        value: 33,
        previousValue: 38,
      },
      {
        category: 'Processes',
        value: 40,
        previousValue: 38,
      },
      {
        category: 'Data risks',
        value: 12,
        previousValue: 15,
      },
    ],
  },
  {
    type: 'Amount',
    data: [
      {
        category: 'Assessments',
        value: 110,
        previousValue: 120,
      },
      {
        category: 'Privacy requests',
        value: 200,
        previousValue: 220,
      },
      {
        category: 'Processes',
        value: 105,
        previousValue: 100,
      },
      {
        category: 'Data risks',
        value: 200,
        previousValue: 250,
      },
    ],
  },
];
