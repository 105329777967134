import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BigidInlineNotification,
  BigidColorsV2,
  BigidFieldRenderProps,
  BigidForm,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormPropsOnChange,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidLoader,
  BigidSchedulerBase,
  BigidSelectOption,
  BigidLink,
  BigidBody1,
  BigidFormFieldTooltipContent,
  getFieldComponent,
} from '@bigid-ui/components';

import { SystemDialogContentProps } from '../../../services/systemDialogService';
import { isEmpty, isEqual } from 'lodash';
import {
  generateConfigurationFields,
  getReportingETlSettings,
  isConfigurationEqual,
  normalizeFormValues,
  ReportingEtlConfigurationFormField,
  ReportingEtlFormValues,
  RunningAdapters,
  SelectedAndDefaultAdapter,
  shouldDisableAdapterFields,
  isSelectedAdapterRunning,
  shouldShowErrorsOnGroupedFields,
  stringValidator,
  updateConfiguration,
  getEtlLicenses,
  EtlLicenses,
  getLicenseWarningMessage,
  isFieldVisible,
  getDefaultAdapter,
} from '../reportingEtlMonitoringUtils';
import { notificationService } from '../../../services/notificationService';
import {
  AdaptersLabels,
  AdapterTypeOptions,
  ENCRYPTED_CREDENTIALS_FORMAT,
  FIELDS_TO_IGNORE,
  GCP_ADAPTERS,
} from '../consts/ReportingEtlConsts';
import { GroupedEtlSettingsFields } from './GroupedSettingsFields';
import styled from '@emotion/styled';
import { $state, credentialsService } from '../../../services/angularServices';
import { useLocalTranslation } from '../translations';
import { publicUrls } from '../../../config/publicUrls';
import { tConfigurationDialog, tFormDescription } from '../translations/EtlFixedTranslations';
import { Box } from '@mui/material';
import { isPermitted } from '../../../services/userPermissionsService';
import { CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import { CredentialTypesEnum } from '../../DataSources/DataSourceConfiguration/fields/FormCredentialField';

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const Separator = styled('hr')`
  border-color: ${BigidColorsV2.gray[400]};
`;

const ExpansionPanel = styled('div')`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const WarningWrapper = styled('div')`
  margin-bottom: 10px;
  width: 100%;
`;

const Field = styled.div``;

const VerticalObjectField = styled(Box)`
  & fieldset > div {
    flex-wrap: wrap;
    row-gap: 8px;
  }
  & fieldset > div > label {
    padding-left: 0px;
  }
`;

const ChildFieldStyle = styled(VerticalObjectField)`
  background-color: ${({ theme }) => theme.vars.palette.bigid.gray150};
  padding: 10px 20px;
  border-radius: 4px;

  & + & {
    padding: 0px 20px 10px 20px;
  }
`;

export interface ConfigurationFormProps {
  formControls: MutableRefObject<BigidFormStateAndHandlers>;
  onChange: (state: { isDirty: boolean; selectedAdapter: AdapterTypeOptions }) => void;
  onAdapterChange: (isDirty: boolean) => void;
  runningAdapters: RunningAdapters;
  onSubmitSuccess: () => void;
}

const getFormTooltipDescription = (description: string): BigidFormFieldTooltipContent => {
  const [descriptionToDisplay, hyperLinkToDisplay] = description.split('.');
  return hyperLinkToDisplay ? (
    <BigidBody1>
      {`${tFormDescription(descriptionToDisplay)}`}
      <BigidLink
        text={tFormDescription(hyperLinkToDisplay)}
        href={publicUrls.AWS_NAMING_RULES_URL}
        shouldOpenNewTab={true}
      />{' '}
    </BigidBody1>
  ) : (
    tFormDescription(descriptionToDisplay)
  );
};

const getCredentialsData = async () => {
  try {
    const res: { data: any[] } = (isPermitted(CREDENTIALS_PERMISSIONS.READ.name) &&
      (await credentialsService.getCredentials({ isScannerOnly: { $not: { $eq: true } } }))) || { data: [] };
    const options = res.data.map(({ credential_id, type }) => ({
      id: credential_id,
      value: credential_id,
      label: type === CredentialTypesEnum.BigID ? credential_id : `${credential_id} (${type})`,
    }));

    return options;
  } catch (error) {
    notificationService.error('Error getting the list of credentials');
    return [];
  }
};

export const ReportingEtlConfigurationForm: FC<SystemDialogContentProps<ConfigurationFormProps>> = ({
  formControls,
  onChange,
  onAdapterChange,
  runningAdapters,
  onSubmitSuccess,
}) => {
  const { t } = useLocalTranslation();
  const defaultAdapter = useMemo(() => {
    const defaultAdapterType = getDefaultAdapter();
    return {
      label: AdaptersLabels[defaultAdapterType],
      value: defaultAdapterType,
    };
  }, []);
  const [configurationsMetadata, setConfigurationsMetadata] = useState<ReportingEtlConfigurationFormField[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isScheduleDisabled, setIsScheduleDisabled] = useState(false);
  const [credentialsOptions, setCredentialsOptions] = useState([]);
  const [currentConfiguration, setCurrentConfiguration] = useState<ReportingEtlFormValues>();
  const [allConfiguration, setAllConfiguration] =
    useState<Partial<Record<AdapterTypeOptions, ReportingEtlFormValues>>>();
  const [adapterConfiguration, setAdapterConfiguration] = useState<SelectedAndDefaultAdapter>({
    selectedAdapter: defaultAdapter,
    defaultAdapter: defaultAdapter,
  });
  const [etlLicenses, setEtlLicenses] = useState<EtlLicenses>({
    isDisLicense: false,
    isDataPipeLineLicense: false,
  });

  useEffect(() => {
    async function init() {
      try {
        const [reportingEtlSettings, credentialOptions] = await Promise.all([
          getReportingETlSettings($state.params.isDataInsightsStudio),
          getCredentialsData(),
        ]);
        const { settings, settingsMetadata, allSettings } = reportingEtlSettings;
        setConfigurationsMetadata(settingsMetadata);
        setCurrentConfiguration(settings);
        setAllConfiguration(allSettings);
        setCredentialsOptions(credentialOptions);
        const defaultSelectType: BigidSelectOption = {
          label: AdaptersLabels[settings.adapterType],
          value: settings.adapterType,
        };
        setAdapterConfiguration({
          selectedAdapter: defaultSelectType,
          defaultAdapter: defaultSelectType,
        });
        setEtlLicenses(getEtlLicenses());
      } catch (e) {
        notificationService.error(tConfigurationDialog('configurationFetchError'));
        console.error(`${tConfigurationDialog('configurationFetchError')} ${e}`);
      } finally {
        setIsLoading(false);
      }
    }

    init();
  }, []);

  const shouldShowLicenseAlert: boolean = useMemo(() => {
    const isDIsLicenseMissing =
      !etlLicenses.isDisLicense && adapterConfiguration?.selectedAdapter.value === AdapterTypeOptions.DIS;
    const isDataPipelineMissing =
      !etlLicenses.isDataPipeLineLicense && adapterConfiguration?.selectedAdapter.value !== AdapterTypeOptions.DIS;
    return isDIsLicenseMissing || isDataPipelineMissing;
  }, [adapterConfiguration?.selectedAdapter.value, etlLicenses.isDataPipeLineLicense, etlLicenses.isDisLicense]);

  const handleFormSubmit = async (values: BigidFormValues) => {
    try {
      setIsLoading(true);
      const configuration = normalizeFormValues(
        values,
        configurationsMetadata,
        adapterConfiguration.selectedAdapter.value,
      );
      await updateConfiguration(configuration, adapterConfiguration.selectedAdapter.value);
    } finally {
      setIsLoading(false);
      onSubmitSuccess();
    }
  };

  const fields: BigidFormField[] = useMemo(() => {
    const currentAdapter = adapterConfiguration.selectedAdapter.value;
    return configurationsMetadata
      .filter(({ adapterType }) => adapterType === currentAdapter)
      .map(({ name, displayName, type, required, description, options, isChildField, visibleIf }) => {
        const labelPosition = [BigidFormFieldTypes.SWITCH, BigidFormFieldTypes.CHECKBOX].includes(type)
          ? BigidFormFieldLabelPosition.left
          : BigidFormFieldLabelPosition.top;

        const Component = getFieldComponent(type ?? BigidFormFieldTypes.TEXT, labelPosition);
        const render = (props: BigidFieldRenderProps) => {
          if (!isFieldVisible(props.values, visibleIf)) return null;
          if (isChildField) {
            return (
              <Field as={ChildFieldStyle}>
                <Component {...props} />
              </Field>
            );
          }
          return <Component {...props} />;
        };

        return {
          type,
          name,
          render,
          label: displayName,
          labelPosition,
          isRequired: required,
          disabled: shouldDisableAdapterFields({
            fieldName: name,
            isDisLicensed: etlLicenses.isDisLicense,
            isDataPipelineLicense: etlLicenses.isDataPipeLineLicense,
            selectedAdapter: currentAdapter,
            isLoading,
          }),
          tooltipText: getFormTooltipDescription(description),
          validate: (value: unknown, formValues: Record<string, any>) => {
            if (isFieldVisible(formValues, visibleIf) && required && isEmpty(value)) {
              return `${displayName} is required`;
            }
            return false;
          },
          options: name === 'credential_id' ? credentialsOptions : options,
          ...(type === BigidFormFieldTypes.NUMBER && { fieldProps: { min: 0 } }),
          ...(type === BigidFormFieldTypes.SWITCH && {
            validate: () => {
              return false;
            },
            fieldProps: {
              label: '',
            },
          }),
          ...(type === BigidFormFieldTypes.PASSWORD && {
            fieldProps: { showValueOnlyIfDirty: true, rows: 5, multiline: true },
            validate: (value: string, formValues: Record<string, any>) => {
              if (!isFieldVisible(formValues, visibleIf)) return false;
              const {
                selectedAdapter: { value: selectedAdapterValue },
              } = adapterConfiguration;
              const valueToValidate = typeof value === 'string' ? value.trim() : value;
              try {
                if (value === ENCRYPTED_CREDENTIALS_FORMAT) {
                  return false;
                }
                if (GCP_ADAPTERS.includes(selectedAdapterValue)) {
                  JSON.parse(value);
                }
                if (
                  [AdapterTypeOptions.S3, AdapterTypeOptions.Azure].includes(selectedAdapterValue) &&
                  isEmpty(valueToValidate)
                ) {
                  return `${displayName} shouldn't be empty`;
                }
                return false;
              } catch (e) {
                return `${displayName} should be a valid JSON, ${e.message}`;
              }
            },
          }),
          ...(type === BigidFormFieldTypes.SCHEDULE_PICKER && {
            render: ({ value, setValue }: BigidFieldRenderProps) => {
              const onFieldsChange = (changedFields: Record<string, any>) => setValue({ ...value, ...changedFields });
              return (
                <BigidSchedulerBase onFieldsChange={onFieldsChange} schedule={value} onScheduleChange={setValue} />
              );
            },
          }),
          ...(type === BigidFormFieldTypes.TEXT && {
            validate: (value: string, formValues: Record<string, any>) => {
              return isFieldVisible(formValues, visibleIf) && stringValidator(value, required, displayName);
            },
          }),
          ...(type === BigidFormFieldTypes.SELECT && {
            validate: (value: BigidSelectOption[], formValues: Record<string, any>) => {
              if (isFieldVisible(formValues, visibleIf) && required && isEmpty(value?.[0]?.value)) {
                return `${displayName} is required`;
              }
              return false;
            },
          }),
        };
      });
  }, [
    adapterConfiguration,
    configurationsMetadata,
    etlLicenses.isDisLicense,
    etlLicenses.isDataPipeLineLicense,
    isLoading,
    credentialsOptions,
  ]);

  const showChangeConfigurationAlert = useMemo(() => {
    return isSelectedAdapterRunning(runningAdapters, adapterConfiguration?.selectedAdapter.value as AdapterTypeOptions);
  }, [runningAdapters, adapterConfiguration]);

  const renderForm = useCallback(
    ({ renderField, getValues, errors, wasSubmitted }: BigidFormRenderProps) => {
      const { select_type } = getValues();
      const selectedAdapterType: AdapterTypeOptions = select_type?.[0]?.value;
      const { topFields, advancedFields, schedulerFields, connectivityFields } = generateConfigurationFields(
        configurationsMetadata,
        selectedAdapterType,
        fields,
      );
      const { isErrorsInConnectivityFields, isErrorsInAdvancedFields } = shouldShowErrorsOnGroupedFields(
        errors,
        wasSubmitted,
        connectivityFields,
        advancedFields,
      );

      return (
        <>
          {topFields.map(({ name }) => renderField(name))}
          <Separator />
          <ExpansionPanel>
            {!isScheduleDisabled && (
              <GroupedEtlSettingsFields title={'Scheduling'} fields={schedulerFields} renderFunction={renderField} />
            )}
            <GroupedEtlSettingsFields
              title={'Connectivity Settings'}
              fields={connectivityFields}
              renderFunction={renderField}
              shouldShowError={isErrorsInConnectivityFields}
            />
            <GroupedEtlSettingsFields
              title={'Advanced Settings'}
              fields={advancedFields}
              renderFunction={renderField}
              shouldShowError={isErrorsInAdvancedFields}
            />
          </ExpansionPanel>
        </>
      );
    },
    [configurationsMetadata, fields, isScheduleDisabled],
  );

  const handleChange = useCallback<BigidFormPropsOnChange>(
    (values: BigidFormValues, { setFieldValue }) => {
      const { select_type: valuesSelectType, ...valuesToCompare } = values;
      const { select_type: configurationSelectType, ...configurationToCompare } = currentConfiguration;
      const isFormChanged = !isConfigurationEqual(configurationToCompare, valuesToCompare);
      const isAdapterChanged = !isEqual(valuesSelectType, configurationSelectType);
      const selectedAdapter = valuesSelectType[0].value;
      const isDisAdapterSelected = selectedAdapter === AdapterTypeOptions.DIS;
      const adapterToChange = isDisAdapterSelected ? etlLicenses.isDisLicense : etlLicenses.isDataPipeLineLicense;
      onAdapterChange(adapterToChange);
      onChange({ isDirty: adapterToChange, selectedAdapter });
      setIsScheduleDisabled(!values?.schedule_enabled);
      if (isAdapterChanged && allConfiguration) {
        // changing current configuration according to the selected type
        setAdapterConfiguration(prevState => ({
          ...prevState,
          selectedAdapter: valuesSelectType[0],
        }));
        const configurationToSet = allConfiguration[selectedAdapter as AdapterTypeOptions];
        setCurrentConfiguration(configurationToSet);
        // setting the new form values
        Object.entries(configurationToSet).forEach(([key, val]) => {
          if (!FIELDS_TO_IGNORE.includes(key)) {
            setFieldValue(key, val);
          }
        });
        formControls.current.setErrors({});
        onChange({ isDirty: isAdapterChanged, selectedAdapter });
      } else if (isEqual(adapterConfiguration.selectedAdapter, adapterConfiguration.defaultAdapter)) {
        onChange({ isDirty: isFormChanged, selectedAdapter: adapterConfiguration.selectedAdapter.value });
      }
    },
    [
      currentConfiguration,
      allConfiguration,
      adapterConfiguration.selectedAdapter,
      adapterConfiguration.defaultAdapter,
      onAdapterChange,
      etlLicenses.isDisLicense,
      etlLicenses.isDataPipeLineLicense,
      formControls,
      onChange,
    ],
  );

  return (
    <FormWrapper>
      {currentConfiguration && configurationsMetadata.length && (
        <>
          {showChangeConfigurationAlert && (
            <WarningWrapper>
              <BigidInlineNotification
                type="warning"
                text={[{ subText: tConfigurationDialog('modificationWarning.subText') as string }]}
                title={tConfigurationDialog('modificationWarning.title')}
                showMoreButton={false}
                open
              />
            </WarningWrapper>
          )}
          {shouldShowLicenseAlert && (
            <WarningWrapper>
              <BigidInlineNotification
                type="warning"
                text={[{ subText: `${getLicenseWarningMessage(adapterConfiguration?.selectedAdapter)}` }]}
                title={t('main.configurationDialog.licenseAlertTitle')}
                showMoreButton={false}
                open
              />
            </WarningWrapper>
          )}
          <BigidForm
            controlButtons={false}
            fields={fields}
            initialValues={currentConfiguration}
            stateAndHandlersRef={formControls}
            renderForm={renderForm}
            onChange={handleChange}
            onSubmit={handleFormSubmit}
            validateOn={BigidFormValidateOnTypes.CHANGE}
          />
        </>
      )}
      {isLoading && <BigidLoader />}
    </FormWrapper>
  );
};
