import { BigidFilter } from '@bigid-ui/components';
import { httpService } from './httpService';

export type WidgetSettings = {
  widgetId: string;
  filters?: BigidFilter;
};

export type GenerateReportData = {
  fileName: string;
  reportType: string;
  reportSettings?: Record<string, any>;
  reportWidgets?: WidgetSettings[];
  query?: any;
};

export const generateReport = async (payload: GenerateReportData) => {
  const { data } = await httpService.post(
    'generate-report',
    payload,
    {},
    { Accept: 'application/pdf' },
    { responseType: 'arraybuffer' },
  );

  return data;
};
