import { useQuery } from 'react-query';
import { getIntegrationSettings } from '../../../../../../DataCatalog/DataCatalogService';
import { validateAppTabSettings } from '../../../../../../DataCatalog/DataCatalogAppIntegrations/AppIntegrationTab';
import { notificationService } from '../../../../../../../services/notificationService';

export const DATA_RETENTION_FRIENDLY_NAME = 'Data Retention';

export const useAppSettings = (requestedApp: string) => {
  return useQuery(['getIntegrationSettings', getIntegrationSettings], async () => {
    try {
      const data = await getIntegrationSettings();

      return Array.isArray(data)
        ? data.find(app => app.appFriendlyName === requestedApp && validateAppTabSettings(app))
        : undefined;
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error('An error has occurred');

      return;
    }
  });
};
