import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Stack } from '@mui/material';
import {
  BigidCheckbox,
  BigidDialog,
  BigidSearch,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { Generic2, iconSets } from '@bigid-ui/icons';
import { BigidGridQueryComponents } from '@bigid-ui/grid';

import { notificationService } from '../../../services/notificationService';
import { fetchRegulationsFrameworksData, updateRegulationsFrameworks } from '../RegulationServices';
import { frameworkIconGenerator } from '../utils';
import { REGULATIONS_NAMESPACE } from '../translations';
import { ComplianceRegulation, RegulationFramework, RegulationFrameworkStatus } from '../types';

enum LoadingStatus {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}

export function AddFrameworksModal(props: {
  selectedRegulation?: ComplianceRegulation;
  isOpen: boolean;
  onClose: () => void;
  regulationsQuery: BigidGridQueryComponents;
}) {
  const { t } = useTranslation(REGULATIONS_NAMESPACE);
  const { onClose, isOpen, selectedRegulation, regulationsQuery } = props;
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.DEFAULT);
  const [regulationsFrameworks, setRegulationsFrameworks] = useState<RegulationFramework[]>([]);
  const [selectedList, setSelectedList] = useState<RegulationFramework[]>([]);

  const handleOnSearch = useCallback(
    (searchText: string): void => {
      setSelectedList(selectedList =>
        regulationsFrameworks
          .filter(regulationFramework =>
            regulationFramework.name.toLowerCase().includes(searchText.trim().toLowerCase()),
          )
          .map(regulationFramework => ({
            ...regulationFramework,
            status:
              selectedList.find(item => item.name === regulationFramework.name)?.status || regulationFramework.status,
          })),
      );
    },
    [regulationsFrameworks],
  );

  const handleCheck = useCallback((regulationFramework: RegulationFramework, isChecked: boolean) => {
    setSelectedList(list => {
      return list.map(item =>
        item.name === regulationFramework.name
          ? {
              ...regulationFramework,
              status: isChecked ? RegulationFrameworkStatus.SELECTED : RegulationFrameworkStatus.UNSELECTED,
            }
          : item,
      );
    });
  }, []);

  const handleSelectAll = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedList(list =>
      list.map(item => ({
        ...item,
        status: e.target.checked ? RegulationFrameworkStatus.SELECTED : RegulationFrameworkStatus.UNSELECTED,
      })),
    );
  }, []);

  const handleFetchFrameworks = useCallback(async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const data = await fetchRegulationsFrameworksData(regulationsQuery);
      setRegulationsFrameworks(data);
      setSelectedList(data);
      setLoadingStatus(LoadingStatus.SUCCESS);
    } catch (err) {
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
      onClose();
      notificationService.error(t('Error.requests.fetchRegulationsFrameworks'));
    }
  }, [t, onClose, regulationsQuery]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      await updateRegulationsFrameworks(regulationsQuery, selectedList);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
      setLoadingStatus(LoadingStatus.SUCCESS);
      onClose();
      notificationService.success(t('Success.requests.updateFrameworks'));
    } catch (err) {
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
      notificationService.success(t('Error.requests.updateFrameworks'));
    }
  }, [t, onClose, regulationsQuery, selectedList]);

  useEffect(() => {
    handleFetchFrameworks();
  }, [handleFetchFrameworks]);

  const allSelected = useMemo(
    () =>
      selectedList.every(item => item.status === RegulationFrameworkStatus.SELECTED) ||
      (selectedList.some(
        item =>
          item.status === RegulationFrameworkStatus.SELECTED || item.status === RegulationFrameworkStatus.INDETERMINATE,
      ) &&
        null),
    [selectedList],
  );

  const stateChanged = useMemo(
    () =>
      selectedList.some(
        item =>
          item.status !==
          regulationsFrameworks.find(regulationsframework => regulationsframework.name === item.name).status,
      ),
    [regulationsFrameworks, selectedList],
  );

  return (
    <BigidDialog
      title={(selectedRegulation ? `${selectedRegulation.name} ` : '') + t('Titles.relatedFrameworks')}
      isOpen={isOpen}
      icon={
        selectedRegulation
          ? () => {
              const Icon = iconSets.FlagIcons?.[selectedRegulation.flagCode] || Generic2;
              return <Icon width={20} height={20} />;
            }
          : null
      }
      borderTop
      borderBottom
      fixedHeight
      isLoading={loadingStatus === LoadingStatus.LOADING}
      onClose={onClose}
      maxWidth="xs"
      buttons={[
        {
          text: t('Labels.cancel'),
          component: TertiaryButton,
          onClick: onClose,
        },
        {
          text: t('Labels.update'),
          component: PrimaryButton,
          onClick: handleSubmit,
          disabled: !stateChanged,
        },
      ]}
    >
      <Stack pb="20px">
        <BigidSearch
          placeholder={t('Labels.search')}
          onChange={debounce(handleOnSearch, 600)}
          onSubmit={handleOnSearch}
        />
      </Stack>
      <Stack overflow="auto" mb={-1}>
        {!!selectedList.length && (
          <Stack px={1.5} py={1}>
            <BigidCheckbox
              label={t('Labels.selectAll')}
              checked={allSelected}
              indeterminate={allSelected === null}
              onChange={handleSelectAll}
            />
          </Stack>
        )}
        {selectedList.map(regulationFramework => (
          <Stack key={regulationFramework.name} px={1.5} py={1}>
            <BigidCheckbox
              label={regulationFramework.name}
              checked={regulationFramework.status === RegulationFrameworkStatus.SELECTED}
              indeterminate={regulationFramework.status === RegulationFrameworkStatus.INDETERMINATE}
              onChange={e => handleCheck(regulationFramework, e.target.checked)}
              icon={() => {
                const Icon = frameworkIconGenerator(regulationFramework.name);
                return <Icon width={20} height={20} style={{ margin: '0 4px', flex: 'none' }} />;
              }}
            />
          </Stack>
        ))}
      </Stack>
    </BigidDialog>
  );
}
