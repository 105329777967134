import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { QuerySelector } from './QuerySelector';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidDropdown,
  BigidDropdownOption,
  BigidSidePanel,
  BigidTabs,
  BigidTextField,
  PrimaryButton,
  QueryTree,
  TertiaryButton,
} from '@bigid-ui/components';
import { ClassificationLevel, QueryType, getLabels } from '../SensitivityClassificationService';
import { BigidAddIcon, BigidBookIcon, BigidDeleteIcon, BigidTagIllustration, BigidXIcon } from '@bigid-ui/icons';
import {
  ActionsWrapper,
  GuidelineBody,
  GuidelineFrame,
  GuidelineHeader,
  GuidelineItem,
  GuidelineItemBody,
  GuidelineNumberFrame,
  GuidelinesExpandToggle,
  LabelItemAdd,
  LabelItemDelete,
  LabelItemField,
  LabelItemFrame,
  LabelsActionsWrapper,
  LabelsEmptyStateRoot,
  MappedLabelsRoot,
  QuerySelectorWrapper,
  TabContentRoot,
  TabsWrapper,
} from './SeveritySidePanelStyles';
import { useLocalTranslation } from '../translations';
import { deepClone } from '@datadog/browser-core';
import * as bigidQueryObjectSerialization from '@bigid/query-object-serialization';
import { ErrorField } from './formErrorReducer';
import { SensitivityClassificationsContext } from './SensitivityClassificationsContext';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { AppLicense } from '../../../../react/services/appsLicenseService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { isNameValid } from '../../SensitivityClassification/classificationLevelsUtils';

interface BigidLabelDropdownOption extends BigidDropdownOption {
  type: string;
}

interface CalcificationLabel {
  type: string;
  label: string;
}

export interface SeveritySidePanelProps {
  classifications: ClassificationLevel;
  isOpen: boolean;
  isDefault: boolean;
  onClose: (isDirty: boolean) => void;
  onSave: (classification: ClassificationLevel) => void;
  onDelete: () => void;
  validateNameExist: (name: string) => boolean;
}

export const SeveritySidePanel: FC<SeveritySidePanelProps> = ({
  classifications,
  isOpen,
  isDefault,
  onClose,
  onSave,
  onDelete,
  validateNameExist,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [shouldShowLabels, setShouldShowLabels] = useState<boolean>(false);
  const [hideSeverityGuidelines, setHideSeverityGuidelines] = useState<boolean>(false);
  const [isGuidelinesExpanded, setIsGuidelinesExpanded] = useState<boolean>(false);
  const [labels, setLabels] = useState<BigidLabelDropdownOption[]>([]);
  const [sensitivityClassification, setSensitivityClassification] = useState<ClassificationLevel>(classifications);

  const [calcificationLabels, setClarificationLabels] = useState<CalcificationLabel[]>([]);

  const { t } = useLocalTranslation('SensitivityClassificationForm');

  const { dispatchError: dispatch } = useContext(SensitivityClassificationsContext);

  const handleQueryChange = useCallback(
    (objectQuery?: QueryTree, stringQuery?: string) => {
      if (!objectQuery) {
        const newClassifications: ClassificationLevel = {
          ...sensitivityClassification,
          queryString: stringQuery,
          queryObject: null,
          queryObj: null,
          isQueryTouched: true,
        };
        setIsDirty(true);
        setSensitivityClassification(prev => ({ ...prev, ...newClassifications }));
      } else if (!stringQuery) {
        const newClassifications: ClassificationLevel = {
          ...sensitivityClassification,
          queryObject: objectQuery,
          isQueryTouched: true,
        };
        setIsDirty(true);
        setSensitivityClassification(prev => ({ ...prev, ...newClassifications }));
      } else {
        const newClassifications: ClassificationLevel = {
          ...sensitivityClassification,
          queryObject: objectQuery,
          queryString: stringQuery,
          isQueryTouched: true,
        };
        setIsDirty(true);
        setSensitivityClassification(prev => ({ ...prev, ...newClassifications }));
      }

      return true;
    },
    [sensitivityClassification],
  );

  const querySelector = useMemo(
    () => ({
      classificationLevel: sensitivityClassification,
      onQueryChange: handleQueryChange,
      onQueryTypeChange: (queryType: QueryType) => {
        setIsDirty(true);
        sensitivityClassification.queryType = queryType;
        setSensitivityClassification({ ...sensitivityClassification, ...sensitivityClassification });
      },
      stringQuery: sensitivityClassification ? sensitivityClassification.queryString : '',
      objectQuery: sensitivityClassification ? sensitivityClassification.queryObject : undefined,
    }),
    [sensitivityClassification, handleQueryChange],
  );

  useEffect(() => {
    const setLabelsFromServer = async () => {
      const res = await getLabels();

      setLabels(
        res.labels.map(lbl => ({
          id: lbl.labelId,
          displayValue: lbl.name,
          value: lbl.labelId,
          type: lbl.type,
        })),
      );
    };

    const hasLicense: AppLicense[] = sessionStorageService?.get('appsLicense');
    const isLabelerActive = getApplicationPreference('LABELER_ENABLED');
    setShouldShowLabels(hasLicense?.some(item => item.name === 'File Labeling' && item.enable) && isLabelerActive);
    setLabelsFromServer();

    const hideSeverityGuidelinesFromSession = sessionStorageService.get<boolean>('hideSeverityGuidelines');
    setHideSeverityGuidelines(!!hideSeverityGuidelinesFromSession);
  }, []);

  const checkIfQueryContainsScTag = (stringQuery?: string): boolean => {
    return stringQuery.includes(`system.sensitivity`);
  };

  const checkIfQueryValid = (objectQuery?: QueryTree, stringQuery?: string): boolean => {
    return (
      (objectQuery && bigidQueryObjectSerialization.validateQuery(JSON.stringify(objectQuery)).valid) ||
      (bigidQueryObjectSerialization.validateQuery(stringQuery).valid && !stringQuery.includes(`""`))
    );
  };

  return (
    <BigidSidePanel
      open={isOpen}
      title={
        sensitivityClassification?.name?.length > 0
          ? `${sensitivityClassification?.priority + 1}: ${sensitivityClassification?.name}`
          : t('BigidSidePanel.new_level')
      }
      maxWidth="medium"
      titleActions={
        <ActionsWrapper>
          <BigidButtonIcon
            disabled={isDefault}
            dataAid="level-delete-button"
            icon={BigidDeleteIcon}
            onClick={onDelete}
          />
          <PrimaryButton
            disabled={isDefault}
            dataAid="level-save-button"
            onClick={() => {
              let isValid = true;

              dispatch({
                type: ErrorField.CLASSIFICATION,
                payload: {
                  id: sensitivityClassification.id,
                  emptyQuery: null,
                  notValidQuery: null,
                  containsScQuery: null,
                },
              });
              if (
                !sensitivityClassification.queryString ||
                JSON.stringify(sensitivityClassification.queryObject).includes(`""`)
              ) {
                dispatch({
                  type: ErrorField.CLASSIFICATION,
                  payload: { id: sensitivityClassification.id, emptyQuery: t('LevelsSidePanel.query_empty') },
                });
                isValid = false;
              }
              if (!checkIfQueryValid(sensitivityClassification.queryObject, sensitivityClassification.queryString)) {
                dispatch({
                  type: ErrorField.CLASSIFICATION,
                  payload: { id: sensitivityClassification.id, notValidQuery: t('LevelsSidePanel.query_invalid') },
                });
                isValid = false;
              }
              if (checkIfQueryContainsScTag(sensitivityClassification.queryString)) {
                dispatch({
                  type: ErrorField.CLASSIFICATION,
                  payload: {
                    id: sensitivityClassification.id,
                    containsScQuery: t('QuerySelector.query_contains_sensitivity_classification_tag'),
                  },
                });
                isValid = false;
              }

              const nameInvalid =
                sensitivityClassification?.name?.length === 0 || validateNameExist(sensitivityClassification?.name);
              setIsNameInvalid(nameInvalid);
              if (
                handleQueryChange(sensitivityClassification.queryObject, sensitivityClassification.queryString) &&
                !nameInvalid &&
                isValid
              )
                onSave(sensitivityClassification);
            }}
            size={'medium'}
          >
            {t('actions.save')}
          </PrimaryButton>
        </ActionsWrapper>
      }
      content={
        <>
          {shouldShowLabels && (
            <TabsWrapper>
              <BigidTabs
                onChange={value => setSelectedIndex(value)}
                tabs={[
                  {
                    label: t('BigidSidePanel.details'),
                  },
                  {
                    label: t('BigidSidePanel.mapped_labels'),
                  },
                ]}
                selectedIndex={selectedIndex}
              />
              {selectedIndex === 1 && (
                <LabelsActionsWrapper>
                  {hideSeverityGuidelines && (
                    <TertiaryButton
                      startIcon={<BigidBookIcon />}
                      dataAid="guidelines-button"
                      onClick={() => setHideSeverityGuidelines(false)}
                      size={'medium'}
                    >
                      {t('actions.guidelines')}
                    </TertiaryButton>
                  )}

                  <LabelItemAdd
                    startIcon={<BigidAddIcon />}
                    text={t('BigidSidePanel.add_label')}
                    onClick={() => {
                      setIsGuidelinesExpanded(false);
                      setClarificationLabels(prev => {
                        const newValues = deepClone(prev);
                        newValues.push({
                          label: '',
                          type: '',
                        });
                        return newValues;
                      });
                    }}
                    size={'medium'}
                  />
                </LabelsActionsWrapper>
              )}
            </TabsWrapper>
          )}
          <TabContentRoot>
            {selectedIndex === 0 && (
              <>
                <BigidTextField
                  label={t('BigidSidePanel.level_name')}
                  disabled={isDefault}
                  required
                  isError={isNameInvalid}
                  errorMessage={isNameInvalid ? t('BigidSidePanel.require_or_in_use') : undefined}
                  onChange={event => {
                    setIsNameInvalid(
                      event.target.value.length === 0 ||
                        validateNameExist(event.target.value) ||
                        !isNameValid(event.target.value),
                    );
                    setIsDirty(true);
                    setSensitivityClassification(prevValue => ({
                      ...prevValue,
                      name: event.target.value,
                    }));
                  }}
                  value={sensitivityClassification ? sensitivityClassification.name : undefined}
                />
                <QuerySelectorWrapper>
                  <BigidBody1 fontWeight={700}>{t('BigidSidePanel.query_builder')}</BigidBody1>
                  <QuerySelector
                    classificationLevel={querySelector.classificationLevel}
                    onQueryChange={querySelector.onQueryChange}
                    onQueryTypeChange={querySelector.onQueryTypeChange}
                    stringQuery={querySelector.stringQuery}
                    objectQuery={querySelector.objectQuery}
                    isQueryTouched={querySelector.classificationLevel.isQueryTouched}
                  />
                </QuerySelectorWrapper>
              </>
            )}
            {selectedIndex === 1 && (
              <MappedLabelsRoot>
                {!hideSeverityGuidelines && (
                  <Guideline
                    isExpanded={isGuidelinesExpanded}
                    onExpandToggle={() => setIsGuidelinesExpanded(pre => !pre)}
                    onClose={() => {
                      setHideSeverityGuidelines(true);
                      sessionStorageService.set('hideSeverityGuidelines', true);
                    }}
                  />
                )}
                {calcificationLabels.length > 0 &&
                  calcificationLabels.map((cLabel, index) => (
                    <LabelItem
                      key={index}
                      labels={labels}
                      calcificationLabel={cLabel}
                      index={index}
                      onValueChange={(index, calcificationLabel) => {
                        calcificationLabels[index] = calcificationLabel;
                        setClarificationLabels(deepClone(calcificationLabels));
                      }}
                      onDeleteLabel={index => {
                        const newCalcificationLabels = deepClone(calcificationLabels);
                        newCalcificationLabels.splice(index, 1);
                        setClarificationLabels(newCalcificationLabels);
                      }}
                    />
                  ))}

                {calcificationLabels.length === 0 && (
                  <LabelsEmptyStateRoot>
                    <BigidTagIllustration />
                    <div>
                      <BigidBody1>{t('BigidSidePanel.labels_empty_state')}</BigidBody1>
                    </div>
                  </LabelsEmptyStateRoot>
                )}
              </MappedLabelsRoot>
            )}
          </TabContentRoot>
        </>
      }
      onClose={async () => {
        let isDiscardConfirmed = true;
        if (isDirty) {
          isDiscardConfirmed = await showConfirmationDialog({
            entityNameSingular: '',
            actionName: t('Dialogs.unsaved_changes'),
            actionButtonName: t('actions.discard'),
            cancelButtonName: t('actions.close'),
            customDescription: t('Dialogs.discard_confirmation', {
              levelName: sensitivityClassification?.name,
            }),
            showCloseIcon: false,
          });
        }

        if (isDiscardConfirmed) onClose(isDirty);
      }}
    />
  );
};

const LabelItem: FC<{
  labels: BigidLabelDropdownOption[];
  index: number;
  calcificationLabel: CalcificationLabel;
  onValueChange: (index: number, calcificationLabel: CalcificationLabel) => void;
  onDeleteLabel: (index: number) => void;
}> = ({ labels, index, calcificationLabel, onValueChange, onDeleteLabel }) => {
  const { t } = useLocalTranslation('SensitivityClassificationForm');

  const labelTypes = [
    { id: 'MIP', displayValue: 'MIP', value: 'MIP' },
    { id: 'GDRIVE', displayValue: 'GDRIVE', value: 'GDRIVE' },
  ];

  return (
    <LabelItemFrame>
      <LabelItemField>
        <BigidBody1>{t('BigidSidePanel.labeling_provider')}</BigidBody1>
        <BigidDropdown
          options={labelTypes}
          onSelect={opt => {
            onValueChange(index, { ...calcificationLabel, type: opt[0].value });
          }}
          value={labelTypes.filter(type => type.value == calcificationLabel.type)}
        />
      </LabelItemField>
      <LabelItemField>
        <BigidBody1>{t('BigidSidePanel.label')}</BigidBody1>
        <BigidDropdown
          options={labels.filter(item => item.type === calcificationLabel.type)}
          onSelect={opts => {
            onValueChange(index, { ...calcificationLabel, label: opts[0].value });
          }}
        />
      </LabelItemField>

      <LabelItemDelete startIcon={<BigidDeleteIcon />} onClick={() => onDeleteLabel(index)} size={'medium'} />
    </LabelItemFrame>
  );
};

const Guideline: FC<{ isExpanded: boolean; onExpandToggle: () => void; onClose: () => void }> = ({
  isExpanded,
  onExpandToggle,
  onClose,
}) => {
  const { t } = useLocalTranslation('SensitivityClassificationForm');

  const getItems = () => {
    const items: Array<ReactNode> = [];
    const max = isExpanded ? 5 : 1;
    for (let index = 1; index <= max; index++) {
      items.push(
        <GuidelineItem key={index}>
          <GuidelineNumberFrame>
            <BigidBody1>{index}</BigidBody1>
          </GuidelineNumberFrame>
          <GuidelineItemBody>
            <BigidBody1 fontWeight={'bold'}>{t(`LevelsSidePanel.guidelines.title${index}`)}</BigidBody1>

            {t(`LevelsSidePanel.guidelines.body${index}`)
              .split('<br>')
              .map((item, index) => (
                <BigidBody1 key={index}>{item}</BigidBody1>
              ))}
          </GuidelineItemBody>
        </GuidelineItem>,
      );
    }

    return items;
  };

  return (
    <GuidelineFrame>
      <GuidelineHeader>
        <BigidBody1 fontWeight={'bold'}>{t('LevelsSidePanel.guidelineHeader')}</BigidBody1>
        <BigidButtonIcon icon={BigidXIcon} onClick={onClose} />
      </GuidelineHeader>
      <GuidelineBody>
        {getItems()}

        <GuidelinesExpandToggle>
          <TertiaryButton
            text={!isExpanded ? t('LevelsSidePanel.show_more') : t('LevelsSidePanel.show_less')}
            onClick={onExpandToggle}
            size={'medium'}
          />
        </GuidelinesExpandToggle>
      </GuidelineBody>
    </GuidelineFrame>
  );
};
