import {
  TagEntity,
  TagResponseEntity,
  TagsManagementGridRecord,
  TagCompositionPartType,
  TagAssignmentTarget,
  TagProperties,
} from './TagsManagementService';
import {
  BigidTagBaseProps,
  BigidTagWizardDictionary,
  BigidTagsValidationPayload,
  BigidIconType,
  BigidIcon,
  BigidIconSize,
  BigidIconSourceType,
} from '@bigid-ui/components';
import { LocalOfferOutlined } from '@mui/icons-material';
import { BigidSensitivityClassificationIcon } from '@bigid-ui/icons';
import ColumnIcon from '../../assets/icons/BigidTableColumn.svg';
import React from 'react';
import { getIsTagNameOrValueValid } from '../../utilities/tags';

export enum TagApplicationType {
  USER_DEFINED = 'userDefined',
  SENSITIVITY_CLASSIFICATION = 'sensitivityClassification',
  RISK = 'risk',
}

export const getTagEntityByName = (
  systemTags: TagEntity[],
  tagName: BigidTagBaseProps['name'],
  tagValue?: BigidTagBaseProps['value'],
): TagEntity => {
  return systemTags.find(
    systemTag => tagName === systemTag.tagName && (tagValue ? tagValue === systemTag.tagValue : true),
  );
};

export const getTagEntityById = (
  systemTags: TagEntity[],
  tagId: TagEntity['tagId'],
  tagValueId: TagEntity['valueId'],
): TagEntity => {
  return convertTagValueIfExplicitTag(
    systemTags.find(
      systemTag => tagId === systemTag.tagId && (systemTag.properties?.isExplicit || tagValueId === systemTag.valueId),
    ),
    tagValueId,
  );
};

function convertTagValueIfExplicitTag(tag: TagEntity, tagValue: TagEntity['valueId']): TagEntity {
  if (!tag || !tag.properties?.isExplicit) {
    return tag;
  }
  return {
    ...tag,
    tagValue,
  };
}

export const getTagResponseEntity = (
  systemTags: TagResponseEntity[],
  tag: BigidTagBaseProps,
  tagNameId?: string,
): TagResponseEntity => {
  if (tagNameId) {
    return systemTags.find(
      ({ name, type, parent_id }) => name === tag.value && type === 'VALUE' && parent_id === tagNameId,
    );
  } else {
    return systemTags.find(({ name, type }) => name === tag.name && type === TagCompositionPartType.tag);
  }
};

export const getTagsAggregatedById = (tags: TagEntity[]): TagsManagementGridRecord[] => {
  return tags.reduce(
    (tagsAggregated, { tagId, tagName, valueId, tagValue, isMutuallyExclusive = false, properties = {} }) => {
      const { displayName } = properties;
      const tagIndex = tagsAggregated.findIndex(tag => tag.tagId === tagId);

      if (tagIndex < 0) {
        return [
          ...tagsAggregated,
          { tagId, tagName, isMutuallyExclusive, tagValues: [{ tagValue, valueId }], displayName },
        ];
      } else {
        return tagsAggregated.map(tag =>
          tag.tagId === tagId ? { ...tag, displayName, tagValues: [...tag.tagValues, { tagValue, valueId }] } : tag,
        );
      }
    },
    [],
  );
};

export const validateTag = {
  isTagValuePairUnique: (tagValue: string, tagName: string, tagDictionary: BigidTagWizardDictionary): boolean => {
    return !(tagDictionary.get(tagName) || []).find(({ value, isAvailable }) => {
      return tagValue.toLowerCase() === value.toLowerCase() && !isAvailable;
    });
  },
  isTagValueNotEqualsToTagName: (tagValue: string, tagName: string): boolean => {
    return tagName !== tagValue;
  },
  isTagNameValueValid: getIsTagNameOrValueValid,
  isTagNameValueNotEmpty: (str: string): boolean => {
    return str.trim().length > 0;
  },
};

export const validateTagNameWithMessage = ({ tagName }: BigidTagsValidationPayload): string => {
  const { isTagNameValueValid } = validateTag;

  if (!isTagNameValueValid(tagName)) {
    return 'Invalid tag name';
  }

  return undefined;
};

export const validateTagValueWithMessage = ({
  tagName,
  tagValue,
  tagDictionary,
}: BigidTagsValidationPayload): string => {
  const { isTagValuePairUnique, isTagValueNotEqualsToTagName, isTagNameValueValid } = validateTag;

  if (!isTagValuePairUnique(tagValue, tagName, tagDictionary)) {
    return 'Tag & Value pair has to be unique';
  }

  if (!isTagValueNotEqualsToTagName(tagValue, tagName)) {
    return 'Tag & Value cannot be equal';
  }

  if (!isTagNameValueValid(tagValue)) {
    return 'Invalid tag value';
  }

  return undefined;
};

export const getTagFormattedName = (name: string) => {
  return name.replace(/^(system\..*\.)/, '');
};

export const applicationTypeToIconMap: Record<TagApplicationType, BigidIconType> = {
  [TagApplicationType.SENSITIVITY_CLASSIFICATION]: BigidSensitivityClassificationIcon,
  //TODO should get a new icon for risk and change it accordingly. This was written at 2/8/22, if it wasn't changed after one year, you can't change it because of backward compatability
  [TagApplicationType.RISK]: BigidSensitivityClassificationIcon,
  [TagApplicationType.USER_DEFINED]: LocalOfferOutlined,
};

export const getUserDefinedTagsOnly = (tags: TagEntity[]) => {
  return tags.filter(tag => isUserDefinedTag(tag));
};

export const isUserDefinedTag = (tag: TagEntity) => {
  return !tag?.properties?.applicationType || tag.properties.applicationType === TagApplicationType.USER_DEFINED;
};

export const getVisibleTagsOnly = (tags: TagEntity[]) => {
  return tags.filter(tag => !tag.properties?.hidden);
};

export const getTagIcon = (properties: TagProperties, tagType?: TagAssignmentTarget) => {
  let tagIcon: BigidIconType;
  switch (true) {
    case tagType === TagAssignmentTarget.column:
      tagIcon = ColumnIcon;
      break;
    case !properties || properties.applicationType === TagApplicationType.USER_DEFINED:
    case !Object.keys(applicationTypeToIconMap).includes(properties.applicationType):
      tagIcon = LocalOfferOutlined;
      break;
    default:
      tagIcon = applicationTypeToIconMap[properties.applicationType];
  }
  return tagIcon && <BigidIcon icon={tagIcon} size={BigidIconSize.REGULAR_PLUS} type={BigidIconSourceType.CUSTOM} />;
};
