import React from 'react';
import { BigidButtonIcon } from '@bigid-ui/components';
import { BigidAdvanceSearchIcon, BigidSearchIcon, BigidXIcon } from '@bigid-ui/icons';
import { Divider, styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';

const ButtonsGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

type Props = {
  searchValue: string;
  onClear: () => void;
  onSubmit: () => void;
  onFiltersClick: () => void;
  isDisabled: boolean;
  dataAid?: string;
};

export const SearchButtons = ({ searchValue, onClear, onSubmit, onFiltersClick, isDisabled, dataAid }: Props) => {
  return (
    <ButtonsGroup data-aid={generateDataAid(dataAid, ['root'])}>
      {searchValue.length > 0 && (
        <BigidButtonIcon
          size="small"
          icon={BigidXIcon}
          onClick={onClear}
          dataAid={generateDataAid(dataAid, ['clearButton'])}
        />
      )}
      <BigidButtonIcon
        size="small"
        icon={BigidSearchIcon}
        dataAid={generateDataAid(dataAid, ['submitButton'])}
        onClick={onSubmit}
        disabled={isDisabled}
      />
      <Divider orientation="vertical" flexItem />
      <BigidButtonIcon
        size="small"
        icon={BigidAdvanceSearchIcon}
        onClick={onFiltersClick}
        disabled={isDisabled}
        dataAid={generateDataAid(dataAid, ['filtersToggleButton'])}
      />
    </ButtonsGroup>
  );
};
