import React, { FC, useState, useEffect, ChangeEvent, MutableRefObject, useCallback, useMemo, ReactText } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidTextField,
  EntityEvents,
  entityEventsEmitter,
  QueryNode,
  BigidLoader,
  BigidInlineNotification,
} from '@bigid-ui/components';
import { CatalogRulesLayoutEntityActions } from '../CatalogRulesLayout';
import { CatalogRuleQuery } from './CatalogRuleQuery';
import { CatalogRuleApplyProgress } from './CatalogRuleApplyProgress';
import {
  CatalogRule,
  getCatalogRuleById,
  createCatalogRule,
  updateCatalogRule,
  applyCatalogRule,
  CatalogRuleType,
  CatalogAppliedRuleTask,
  getGlossaryAttributesList,
} from '../catalogRulesService';
import { getRuleValidationState, getDuplicateRule } from '../catalogRulesUtils';
import { SystemAttribute, getDataCatalogTaskByStatusAndSseRoute } from '../../DataCatalog/DataCatalogService';
import { DataCatalogAsyncOperationStatus } from '../../DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { notificationService } from '../../../services/notificationService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { omit } from 'lodash';
import classnames from 'classnames';
import { CATALOG_PERMISSIONS, COMPOUND_ATTRIBUTE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { CatalogRulesEvents, trackEventCatalogRules } from '../CatalogRulesEventTrackerUtils';

export interface CatalogRuleDetailsProps
  extends Pick<CatalogRule, 'id' | 'name' | 'createdAt' | 'isEnabled' | 'isPredefined'> {
  dataAid?: string;
  copySourceId?: CatalogRule['id'];
  actionsRef?: MutableRefObject<CatalogRulesLayoutEntityActions>;
}

export type CatalogRuleDetailsValidationState = {
  attributeName?: string;
  query?: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '5px',
    position: 'relative',
  },
  inlineNotification: {
    marginBottom: '24px',
  },
  applyingProgress: {
    marginBottom: '24px',
    height: '60px',
  },
  fields: {
    width: '60%',
  },
  field: {
    '&:not(:last-child)': {
      marginBottom: '20px',
    },
  },
  query: {
    marginTop: '20px',
    padding: '2px',
  },
  busy: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
});

export const CatalogRuleDetails: FC<CatalogRuleDetailsProps> = ({
  dataAid = 'CatalogRuleDetails',
  id,
  name,
  createdAt,
  copySourceId,
  isPredefined,
  actionsRef,
}) => {
  const classes = useStyles();

  const [rulePayload, setRulePayload] = useState<CatalogRule>(null);
  const [rulePayloadValidationState, setRulePayloadValidationState] = useState<CatalogRuleDetailsValidationState>();
  const [attributesList, setAttributesList] = useState<SystemAttribute[]>([]);
  const [isRuleApplyProgressWidgetShown, setIsRuleApplyProgressWidgetShown] = useState<boolean>(false);
  const [isQueryValid, setIsQueryValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRuleApplied, setIsRuleApplied] = useState<boolean>(false);
  const [isQueryInfoContainerShown, setIsQueryInfoContainerShown] = useState<boolean>(false);

  const { isUserAuthorisedToFetchAttributes, isUserAuthorisedToEdit, attributeNameComputed } = useMemo(() => {
    return {
      isUserAuthorisedToEdit: isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.EDIT.name) && !isPredefined,
      isUserAuthorisedToFetchAttributes: isPermitted(CATALOG_PERMISSIONS.READ_MANUAL_FIELDS.name),
      attributeNameComputed:
        (rulePayload?.createdAt ? rulePayload?.attributeFriendlyName : rulePayload?.action?.value) || '',
    };
  }, [isPredefined, rulePayload]);

  const createRule = useCallback(async (): Promise<CatalogRule> => {
    try {
      setIsLoading(true);

      const requestPayload = omit({ ...rulePayload, type: CatalogRuleType.COMPLEX_ATTRIBUTE, isEnabled: true }, ['id']);

      const data = await createCatalogRule(requestPayload);

      entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, rulePayload.id, data);
      notificationService.success(`${rulePayload.name} rule has been created successfully.`);

      return data;
    } catch ({ message, response: { status, data } }) {
      let notificationMessage = `An error occurred while creating ${rulePayload.name} rule`;

      if (status === 400) {
        notificationMessage = data.message;
      }

      console.error(`${notificationMessage}: ${message}`);
      notificationService.error(`${notificationMessage}.`);

      return;
    } finally {
      setIsLoading(false);
    }
  }, [rulePayload]);

  const updateRule = useCallback(async (): Promise<CatalogRule> => {
    trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_EDIT_RULE);
    try {
      setIsLoading(true);

      const payload = omit(rulePayload, ['updatedAt']);
      const data = await updateCatalogRule(payload.id, payload);

      entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, payload.id, data);
      notificationService.success(`${payload.name} rule successfully has been updated.`);

      return data;
    } catch ({ message }) {
      const notificationMessage = `An error occurred while updating ${rulePayload.name} rule`;

      console.error(`${notificationMessage}: ${message}`);
      notificationService.error(`${notificationMessage}.`);

      return;
    } finally {
      setIsLoading(false);
    }
  }, [rulePayload]);

  const applyRule = useCallback(async (): Promise<CatalogAppliedRuleTask> => {
    const isApplyRuleConfirmed = await showConfirmationDialog({
      entityNameSingular: 'Rule',
      actionName: 'Apply',
      actionButtonName: 'Apply',
      customDescription: `Please note that depending on the complexity of the Rule and volume of data, this operation may take a while to
        complete.`,
    });

    if (isApplyRuleConfirmed) {
      trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_APPLY);
      const { id, name } = rulePayload;

      try {
        return await applyCatalogRule(id);
      } catch ({ message, response }) {
        let notificationMessage;

        if (response.status === 400) {
          notificationMessage = 'Applying is still in progress';
          notificationService.warning(`${notificationMessage}.`);
        } else {
          notificationMessage = `An error occurred while applying ${name} rule`;
          notificationService.error(`${notificationMessage}.`);
        }

        console.error(`${notificationMessage}: ${message}`);

        return;
      }
    }
  }, [rulePayload]);

  actionsRef.current = {
    handleOnSave: async (): Promise<boolean> => {
      const validationState = getRuleValidationState(rulePayload);
      const isRuleValid = !Boolean(validationState) || !isQueryValid;

      setRulePayloadValidationState(validationState);

      if (isRuleValid) {
        if (rulePayload?.createdAt) {
          return Boolean(await updateRule());
        } else {
          const data = await createRule();
          const isCreateSucceeded = Boolean(data);

          if (isCreateSucceeded) {
            setRulePayload(data);
          }

          return Boolean(data);
        }
      } else {
        return false;
      }
    },
    handleOnApply: async (): Promise<boolean> => {
      const data = await applyRule();

      if (data) {
        const { sseRoutingKey, taskId } = data;

        setIsRuleApplied(true);
        setRulePayload(prevRulePayload => ({ ...prevRulePayload, sseRoutingKey }));
        setIsRuleApplyProgressWidgetShown(Boolean(taskId));
      }

      return Boolean(data);
    },
  };

  const handleOnDescriptionChange = useCallback(({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRulePayload(rulePayloadPrev => ({
      ...rulePayloadPrev,
      description: target.value,
    }));
  }, []);

  const handleOnAttributeNameChange = useCallback(
    ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_UPDATE_ATTRIBUTE_NAME);
      setRulePayload(rulePayloadPrev => {
        if (rulePayload?.createdAt) {
          return {
            ...rulePayloadPrev,
            attributeFriendlyName: target.value,
          };
        } else {
          return {
            ...rulePayloadPrev,
            action: {
              type: CatalogRuleType.CREATE_NEW_MANUAL_ATTRIBUTE,
              value: target.value,
            },
          };
        }
      });
    },
    [rulePayload?.createdAt],
  );

  const handleOnObjectifiedQueryChange = useCallback((query: QueryNode) => {
    setRulePayload(prevState => ({
      ...prevState,
      bigidQueryObject: query,
    }));
  }, []);

  const handleOnStringifiedQueryChange = useCallback((query: string) => {
    setRulePayload(prevState => ({
      ...prevState,
      bigidQuery: query,
    }));
  }, []);

  const handleRuleApplyCompleted = useCallback(
    (appliedRule: Pick<CatalogRule, 'id' | 'updatedAt'>) => {
      //Checking if an active rule is the same rule that was applied otherwise ignore
      if (id === appliedRule.id && createdAt) {
        setIsLoading(true);
        getCatalogRuleById(id)
          .then(rule => {
            if (rule.updatedAt) {
              const isRuleLastAppliedDateLater =
                new Date(rule.updatedAt).getTime() > new Date(appliedRule.updatedAt).getTime();

              if (isRuleLastAppliedDateLater) {
                setRulePayload(prevRulePayload => ({ ...prevRulePayload, lastAppliedDate: rule.updatedAt }));
              } else {
                setRulePayload({ ...rule, lastAppliedDate: appliedRule.updatedAt });
              }
            } else {
              setRulePayload({ ...rule, lastAppliedDate: appliedRule.updatedAt });
            }
          })
          .catch(({ message }) => {
            const notificationMessage = `An error has occurred while fetching ${name} rule`;

            console.error(`${notificationMessage}: ${message}`);
            notificationService.error(`${notificationMessage}.`);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [id, name, createdAt],
  );

  const handlePayloadExternalUpdate = useCallback((_rowId: ReactText, payloadChanges: Partial<CatalogRule>) => {
    setRulePayload(prevRulePayload => ({ ...prevRulePayload, ...payloadChanges }));
  }, []);

  const handleQueryBuilderFailedToInit = () => {
    if (!rulePayload.isPredefined) {
      setIsQueryInfoContainerShown(true);
    }
  };

  useEffect(() => {
    if (isUserAuthorisedToFetchAttributes) {
      getGlossaryAttributesList()
        .then(({ attributesList, glossaryOOTBAttributesList }) => {
          setAttributesList([
            ...attributesList,
            //NOTE: in order to align to the same type since the whole list is used simultaneously
            ...glossaryOOTBAttributesList.map(({ attributeId, attributeName, attributeType }) => {
              return {
                attribute_id: attributeId,
                attribute_original_name: attributeName,
                attribute_name: attributeName,
                attribute_type: attributeType,
                attribute_original_type: attributeType,
              };
            }),
          ]);
        })
        .catch(({ message }) => {
          const notificationMessage = 'An error has occurred while fetching attributes';

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(notificationMessage);
        });
    }
  }, [isUserAuthorisedToFetchAttributes]);

  useEffect(() => {
    const notificationMessage = `An error has occurred while fetching ${name} rule`;

    if (createdAt) {
      setIsQueryInfoContainerShown(false);
      trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_SELECT_RULE);
      setIsLoading(true);
      getCatalogRuleById(id)
        .then(rule => {
          const { lastAppliedAllDate, lastAppliedDate, sseRoutingKey } = rule;
          const hasEverBeenApplied = Boolean(lastAppliedAllDate || lastAppliedDate);

          setRulePayload(rule);

          if (sseRoutingKey) {
            getDataCatalogTaskByStatusAndSseRoute(DataCatalogAsyncOperationStatus.RUNNING, rule.sseRoutingKey)
              .then(([task]) => {
                setIsRuleApplied(Boolean(task) && !hasEverBeenApplied);
                setIsRuleApplyProgressWidgetShown(Boolean(task) || hasEverBeenApplied);
              })
              .catch(() => {
                setIsRuleApplyProgressWidgetShown(hasEverBeenApplied);
              });
          } else {
            setIsRuleApplyProgressWidgetShown(hasEverBeenApplied);
          }
        })
        .catch(({ message }) => {
          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (copySourceId) {
        setIsLoading(true);
        setIsRuleApplyProgressWidgetShown(false);
        getCatalogRuleById(copySourceId)
          .then(rule => {
            setRulePayload({ id, name, ...getDuplicateRule(rule) });
          })
          .catch(({ message }) => {
            console.error(`${notificationMessage}: ${message}`);
            notificationService.error(`${notificationMessage}.`);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setRulePayload(prevRulePayload => {
          if (prevRulePayload?.id === id) {
            return { ...prevRulePayload, id, name };
          } else {
            return { id, name };
          }
        });
        setIsRuleApplyProgressWidgetShown(false);
      }
    }
  }, [id, name, copySourceId, createdAt]);

  useEffect(() => {
    setIsQueryInfoContainerShown(false);
    setIsRuleApplied(false);
    setRulePayloadValidationState(null);
  }, [id]);

  useEffect(() => {
    const unregister = entityEventsEmitter.addEventListener(EntityEvents.UPDATE_BY_ID, handlePayloadExternalUpdate);

    return () => {
      unregister();
    };
  }, [handlePayloadExternalUpdate]);

  return (
    <div className={classnames(classes.root, isLoading && classes.busy)} data-aid={dataAid}>
      {isLoading && <BigidLoader />}
      {isQueryInfoContainerShown && (
        <div className={classes.inlineNotification}>
          <BigidInlineNotification
            open
            type="info"
            text={[
              {
                subText: `This compound attribute contains classifiers that are no longer available in the system.
                    You can’t edit the attribute that matches an unavailable classifier, but you can remove it.`,
              },
            ]}
          />
        </div>
      )}
      {isRuleApplyProgressWidgetShown && (
        <div className={classes.applyingProgress}>
          <CatalogRuleApplyProgress
            dataAid={`${dataAid}-apply-progress-${rulePayload?.name}`}
            rule={rulePayload}
            isApplied={isRuleApplied}
            onComplete={handleRuleApplyCompleted}
          />
        </div>
      )}
      <div className={classes.fields}>
        <div className={classes.field}>
          <BigidTextField
            dataAid={`${dataAid}-attribute-name`}
            label="Attribute Name"
            errorMessage={rulePayloadValidationState?.attributeName}
            onChange={handleOnAttributeNameChange}
            placeholder="Type name"
            required
            disabled={!isUserAuthorisedToEdit}
            value={attributeNameComputed}
          />
        </div>
        <div className={classes.field}>
          <BigidTextField
            dataAid={`${dataAid}-description`}
            label="Description"
            multiline
            onChange={handleOnDescriptionChange}
            placeholder="Type description"
            rows={5}
            disabled={!isUserAuthorisedToEdit}
            value={rulePayload?.description || ''}
          />
        </div>
      </div>
      <div className={classes.query}>
        <CatalogRuleQuery
          dataAid={`${dataAid}-query`}
          onObjectifiedQueryChange={handleOnObjectifiedQueryChange}
          onStringifiedQueryChange={handleOnStringifiedQueryChange}
          onQueryValidated={setIsQueryValid}
          rule={rulePayload}
          isReadOnly={!isUserAuthorisedToEdit}
          attributesList={attributesList}
          validationState={rulePayloadValidationState}
          isForcedStrigifiedQueryMode={!isUserAuthorisedToFetchAttributes}
          onQueryBuilderFailedToInit={handleQueryBuilderFailedToInit}
        />
      </div>
    </div>
  );
};
