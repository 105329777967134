import React, { KeyboardEvent } from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidButtonIcon, BigidTextField } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';

const DomainItemContainer = styled('div')({
  width: '606px',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
});

const TextFieldWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flexGrow: 1,
});

const ValidationMessage = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundTertiary,
  fontWeight: 400,
  lineHeight: '15px',
}));

export interface DomainItemData {
  value: string;
  isError: boolean;
  errorMessage?: string;
}

export interface DomainItemProps {
  originalIndex: number;
  displayIndex: number;
  onChange: (value: string, domainIndex: number) => void;
  onDeleteDomainClick: (domainIndex: number) => void;
  value: string;
  showError: boolean;
  errorMessage?: string;
  shouldFocus: boolean;
  addNewDomainItemOnEnterPress: () => void;
}

export const DomainItem: React.FC<DomainItemProps> = ({
  originalIndex,
  displayIndex,
  onChange,
  onDeleteDomainClick,
  value,
  showError,
  errorMessage,
  shouldFocus,
  addNewDomainItemOnEnterPress,
}) => {
  const { t } = useLocalTranslation('DomainSettings');
  const ENTER_KEY = 'Enter';

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      addNewDomainItemOnEnterPress();
    }
  };

  return (
    <DomainItemContainer>
      <TextFieldWrapper>
        <BigidTextField
          value={value}
          placeholder={t('Common.domainFieldPlaceholder')}
          onChange={event => onChange(event.target.value, displayIndex)}
          autoFocus={shouldFocus}
          isError={showError}
          onKeyDown={onKeyDown}
          dataAid={generateDataAid('domains-settings-tab', ['domain-item', 'text-field', value])}
        />
        {showError && <ValidationMessage size="small">{errorMessage}</ValidationMessage>}
      </TextFieldWrapper>
      <BigidButtonIcon
        icon={BigidDeleteIcon}
        onClick={() => onDeleteDomainClick(originalIndex)}
        dataAid={generateDataAid('domains-settings-tab', ['domain-item', 'delete-button', value])}
      />
    </DomainItemContainer>
  );
};
