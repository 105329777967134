import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../services/angularServices';
import { SearchGrid } from './SearchGrid/CatalogSearchGrid';
import { ProcessingIndicator } from './SearchGrid/GridComponents/ProcessingIndicator/ProcessingIndicator';
import { useLocalTranslation } from './translations';
import { WithDataAid } from './types';
import {
  DataExplorerSearchSubmitBiPayload,
  sendDataExplorerResultsPageBarSubmitBiAnalytics,
} from '../DataExplorer/services/analytics';
import { DataExplorerAdvancedSearch } from '../DataExplorer/DataExplorerAdvancedSearch/DataExplorerAdvancedSearch';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow: auto;
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  padding: 0 24px;
`;

const SearchBarContainer = styled('div')`
  width: 640px;
`;

const ProcessingWidgetContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Grid = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
`;

export const DataExplorerSearchResultsData: FC<WithDataAid> = ({ dataAid }) => {
  const { t } = useLocalTranslation();

  useEffect(() => {
    pageHeaderService.setTitle({
      breadcrumbs: [
        {
          label: t('header.searchResults'),
        },
      ],
    });
  }, [t]);

  const handleSearchBarSubmit = (searchText: string) => {
    const biPayload: DataExplorerSearchSubmitBiPayload = {
      searchText,
    };

    sendDataExplorerResultsPageBarSubmitBiAnalytics(biPayload);
  };

  const handleModeChange = (queryMode: string) => {
    $state.go($state.$current.name, { biqlFilter: null, query: null, queryMode }, { notify: false, reload: false });
  };

  const initialSearchValue = $stateParams.queryMode === 'queryFilter' ? $stateParams.biqlFilter : $stateParams.query;

  return (
    <Root data-aid={generateDataAid(dataAid, ['container'])}>
      <Header>
        <SearchBarContainer>
          <DataExplorerAdvancedSearch
            initialMode={$stateParams.queryMode}
            initialSearchValue={initialSearchValue || ''}
            onSubmit={handleSearchBarSubmit}
            quickSearchDisabled
            onModeChange={handleModeChange}
          />
        </SearchBarContainer>
        <ProcessingWidgetContainer>
          <ProcessingIndicator />
        </ProcessingWidgetContainer>
      </Header>
      <Grid>
        <SearchGrid dataAid={generateDataAid(dataAid, ['search', 'grid'])} />
      </Grid>
    </Root>
  );
};
