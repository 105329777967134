import React, { FC, useMemo, useState } from 'react';
import { pick } from 'lodash';

import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidLink,
  BigidList,
  BigidWidgetContainer,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidStackedBar } from '@bigid-ui/visualisation';
import {
  BigidApplicationSetupIcon,
  BigidChevronDownIcon,
  BigidChevronUpIcon,
  BigidGoToIcon,
  BigidIbanIcon,
  BigidNoDataIllustration,
  BigidVendorIcon,
} from '@bigid-ui/icons';

import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { useLocalTranslation } from '../translations';
import { AllSeverities, WidgetProps, WidgetSubTypes } from '../PrivacyExecutiveDashboardTypes';
import { fetchWidgetData } from '../services/dashboardService';

import { Menu, WidgetListWithTitle } from './helpers/HelperComponents';
import {
  SectionsWrapper,
  SectionWrapper,
  SectionTitle,
  SectionContent,
  SectionContentWrapper,
  HiddenContentWrapper,
  HiddenContentInnerWrapper,
} from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { useSeverityDropdown } from './hooks/useSeverityDropdown';

enum Sections {
  ASSETS = 'Assets',
  LEGAL_ENTITIES = 'Legal entities',
  VENDORS = 'Vendors',
}

interface DataRiskLandscapeResponse {
  totalAssets: number;
  totalLegalEntities: number;
  totalVendors: number;
  severityLevelDetails: SeverityLevelDetails[];
}

interface DataRiskLandscapeWidgetCase {
  entityName: string;
  count: number;
}

interface SeverityLevelDetails {
  severityLevel: string;
  color: string;
  assetsCount: number;
  vendorsCount: number;
  legalEntitiesCount: number;
  assets: DataRiskLandscapeWidgetCase[];
  vendors: DataRiskLandscapeWidgetCase[];
  legalEntities: DataRiskLandscapeWidgetCase[];
}

type Severity = Record<string, { count: number; cases: DataRiskLandscapeWidgetCase[]; color?: string }>;

type DataRiskLandscapeWidgetData2 = Record<
  Sections,
  {
    severities: Severity;
    totalCount: number;
  }
>;

const RISK_LANDSCAPE_SECTIONS = {
  [Sections.ASSETS]: {
    icon: BigidApplicationSetupIcon,
    addNewTranslationKey: 'dataRiskLandscape.addNewAsset',
    casesProp: 'assets',
    countProp: 'assetsCount',
    totalCountProp: 'totalAssets',
    onClick: () => {
      $state.go(CONFIG.states.APPLICATIONS_SETUP);
    },
  },
  [Sections.LEGAL_ENTITIES]: {
    icon: BigidIbanIcon,
    addNewTranslationKey: 'dataRiskLandscape.addNewLegalEntity',
    casesProp: 'legalEntities',
    countProp: 'legalEntitiesCount',
    totalCountProp: 'totalLegalEntities',
    onClick: () => {
      $state.go(CONFIG.states.LEGAL_ENTITIES);
    },
  },
  [Sections.VENDORS]: {
    icon: BigidVendorIcon,
    addNewTranslationKey: 'dataRiskLandscape.addNewVendor',
    casesProp: 'vendors',
    countProp: 'vendorsCount',
    totalCountProp: 'totalVendors',
    onClick: () => {
      $state.go(CONFIG.states.VENDORS);
    },
  },
};

export const DataRiskLandscapeWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading, error, fetchData } = useFetchWidgetData(fetchDataRiskLandscapeWidgetData);
  const { severity, showAllSeverities, SeverityDropdown } = useSeverityDropdown();
  const [isExpanded, setExpanded] = useState(false);

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: false, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <>
      <BigidWidgetContainer
        content={
          <SectionsWrapper>
            {Object.values(Sections).map(section => {
              const { icon: Icon, addNewTranslationKey, onClick } = RISK_LANDSCAPE_SECTIONS[section];
              const sectionData = data?.[section];
              const count = sectionData?.severities[severity].count;
              return (
                <SectionWrapper key={section}>
                  <SectionTitle>
                    <Icon />
                    <BigidBody1>
                      <strong>{section}</strong>
                    </BigidBody1>
                    {!!sectionData && (
                      <BigidButtonIcon size="xsmall" icon={() => <BigidGoToIcon size="small" />} onClick={onClick} />
                    )}
                  </SectionTitle>
                  <SectionContent>
                    {!!sectionData ? (
                      <SectionContentWrapper>
                        <BigidStackedBar
                          legendProps={{
                            hideLegend: true,
                          }}
                          slices={getSlices(
                            severity,
                            sectionData.severities,
                            sectionData.totalCount,
                            showAllSeverities,
                          )}
                        ></BigidStackedBar>
                        <BigidBody1 size="small" sx={{ marginLeft: '8px' }}>
                          {count
                            ? t(`dataRiskLandscape.countOfSection${showAllSeverities ? '' : 's'}`, {
                                count: count,
                                totalCount: sectionData.totalCount,
                                section: section.toLowerCase(),
                                severity: severity.toLowerCase(),
                              })
                            : t('dataRiskLandscape.noItemsInRisk', {
                                section: section.toLowerCase(),
                                severity: severity.toLowerCase(),
                              })}
                        </BigidBody1>
                        {count ? (
                          <HiddenContentWrapper isExpanded={isExpanded}>
                            <HiddenContentInnerWrapper>
                              <WidgetListWithTitle
                                headerTitleOne={
                                  showAllSeverities
                                    ? section
                                    : t('dataRiskLandscape.headerTitleOne', { section, severity })
                                }
                                headerTitleTwo={t('dataRiskLandscape.headerTitleTwo')}
                                margin={'0 8px 0 -4px'}
                              />
                              <BigidList
                                backgroundColor="#FFFFFF"
                                nameColumnWidth={90}
                                nameColumnHeader={
                                  showAllSeverities
                                    ? section
                                    : t('dataRiskLandscape.headerTitleOne', { section, severity })
                                }
                                valueColumnHeader={t('dataRiskLandscape.headerTitleTwo')}
                                listItems={sectionData.severities[severity].cases.slice(0, 5).map(c => ({
                                  id: c.entityName,
                                  isNameClickable: false,
                                  isValueClickable: false,
                                  name: c.entityName,
                                  value: String(c.count),
                                }))}
                              />
                            </HiddenContentInnerWrapper>
                          </HiddenContentWrapper>
                        ) : null}
                      </SectionContentWrapper>
                    ) : (
                      <BigidBody1 size="small">
                        {t(`dataRiskLandscape.noData`, { section: section.toLowerCase() })}{' '}
                        <BigidLink text={t(addNewTranslationKey)} onClick={onClick} />
                      </BigidBody1>
                    )}
                  </SectionContent>
                </SectionWrapper>
              );
            })}
          </SectionsWrapper>
        }
        titleConfig={{
          title,
        }}
        actionsSlot={
          <>
            <SeverityDropdown />
            <TertiaryButton
              size="small"
              onClick={() => setExpanded(isExpanded => !isExpanded)}
              text={t(`dataRiskLandscape.${isExpanded ? 'showLess' : 'showMore'}`)}
              endIcon={isExpanded ? <BigidChevronUpIcon /> : <BigidChevronDownIcon />}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: !!error,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{!!error ? t('emptyState.errorText') : t('emptyState.defaultText')}</BigidBody1>,
          actions: [{ show: () => !!error, label: t('emptyState.tryAgain'), execute: fetchData }],
        }}
      />
    </>
  );
};

const fetchDataRiskLandscapeWidgetData = async (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<DataRiskLandscapeWidgetData2> => {
  const widgetData: DataRiskLandscapeResponse = await fetchWidgetData({
    subType: WidgetSubTypes.DATA_RISK_LANDSCAPE,
    filter: activeFilters,
  });
  const transformedData = transformData(widgetData);
  return transformedData;
};

const getSlices = (severity: string, severities: Severity, totalCount: number, showAllSeverities: boolean) => {
  const slices = Object.entries({
    ...(showAllSeverities ? severities : pick(severities, severity)),
    [AllSeverities]: {
      count: totalCount - severities[severity].count,
      color: BigidColorsV2.gray[200],
    },
  }).map(([severity, value]) => ({
    color: value.color,
    label: severity,
    value: value.count,
    tooltip: () => (severity === AllSeverities ? `Not in risk: ${value.count}` : ''),
  }));

  return slices;
};

const transformData = (data: DataRiskLandscapeResponse) => {
  return Object.entries(RISK_LANDSCAPE_SECTIONS).reduce((sectionAcc, [section, sectionProps]) => {
    const aggregatedCases = new Map();
    const severities = data.severityLevelDetails.reduce((acc, severity) => {
      const cases = severity[sectionProps.casesProp as keyof SeverityLevelDetails] as DataRiskLandscapeWidgetCase[];
      acc[severity.severityLevel] = {
        cases,
        count: cases.length,
        color: severity.color,
      };
      cases.forEach(c => {
        const previousCount = aggregatedCases.get(c.entityName) || 0;
        aggregatedCases.set(c.entityName, c.count + previousCount);
      });
      return acc;
    }, {} as Severity);

    sectionAcc[section as Sections] = {
      severities: {
        ...severities,
        [AllSeverities]: {
          count: aggregatedCases.size,
          cases: Array.from(aggregatedCases.entries())
            .map(([entityName, count]) => ({ entityName, count }))
            .sort((a, b) => b.count - a.count),
        },
      },
      totalCount: data[sectionProps.totalCountProp as keyof DataRiskLandscapeResponse] as number,
    };
    return sectionAcc;
  }, {} as DataRiskLandscapeWidgetData2);
};
