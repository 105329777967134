import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidButtonIcon, BigidTooltip } from '@bigid-ui/components';
import { BigidEditIcon } from '@bigid-ui/icons';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledTitle = styled(BigidBody1)`
  overflow: hidden;
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundTertiary};
  text-overflow: ellipsis;
`;

export type DataPointProps = {
  title: string;
  value: ReactNode;
  editable?: boolean;
  onEdit?: () => void;
  multiline?: boolean;
};

export const DataPoint = ({ title, value, editable, onEdit, multiline }: DataPointProps) => {
  return (
    <Root>
      <StyledTitle>{title}</StyledTitle>
      <BigidTooltip title={value} placement="top-start">
        <BigidBody1 textOverflow="ellipsis" overflow="hidden" whiteSpace={multiline ? 'break-spaces' : 'nowrap'}>
          {value || '-'} {editable && <BigidButtonIcon size="small" onClick={onEdit} icon={BigidEditIcon} />}
        </BigidBody1>
      </BigidTooltip>
    </Root>
  );
};
