import React, { FC, useContext, useEffect, useState } from 'react';
import { BigidInfoIcon, BigidNoDataIllustration } from '@bigid-ui/icons';
import { BigidBody1, BigidColorsV2, BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataCoverageCharts } from './charts/DataCoverageCharts';
import { DiscoveryDashboardContext } from '../DiscoveryDashboardContext';
import { useLocalTranslation, getFixedT } from '../translations';
import {
  DataCoverageDataPoint,
  DataCoverageWidgetData,
  ProgressChartData,
  ProgressChartDataPoint,
  ReportWidgets,
} from '../types';
import { DashboardSubType, getInitialProgressChartData, WidgetName } from '../consts';
import { prepareProgressChartData, requestDashboardData } from '../discoveryDashboardServices';
import { getToggleBiEvent } from '../utils';

export enum DataCoverageToggleOption {
  AMOUNT = 'amount',
  VOLUME = 'volume',
}

export const DATA_COVERAGE_FORMATTERS = {
  [DataCoverageToggleOption.AMOUNT]: '#.A',
  [DataCoverageToggleOption.VOLUME]: '#.B',
};

const INITIAL_TOGGLE_VALUE = DataCoverageToggleOption.AMOUNT;

export interface DataCoverageWidgetProps {
  title: string;
  onToggle?: (value: DataCoverageToggleOption) => void;
}

const transformDataPointsToProgressChart = (dataPoints: DataCoverageDataPoint[]): ProgressChartDataPoint[] => {
  if (!dataPoints?.length) {
    return [];
  }

  const t = getFixedT('dataCoverageWidget');
  const numDataPoints = dataPoints.length;
  if (numDataPoints === 0) {
    return;
  }
  const lastDataPoint = dataPoints[numDataPoints - 1];
  return [
    {
      category: t('scanned'),
      value: lastDataPoint.scanned,
      isActive: true,
      color: BigidColorsV2.cyan[200],
    },
    {
      category: t('listed'),
      value: lastDataPoint.listed,
      isActive: true,
      color: BigidColorsV2.blue[600],
    },
  ];
};

export const DataCoverageWidget: FC<DataCoverageWidgetProps> = ({ title, onToggle }) => {
  const { t } = useLocalTranslation();
  const [viewByOption, setViewByOption] = useState<DataCoverageToggleOption>(INITIAL_TOGGLE_VALUE);
  const [dataCoverageData, setDataCoverageData] = useState<DataCoverageWidgetData>(null);
  const [areaChartData, setAreaChartData] = useState<DataCoverageDataPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progressChartData, setProgressChartData] = useState<ProgressChartData>(getInitialProgressChartData());
  const [isEmptyWidgetData, setIsEmptyWidgetData] = useState<boolean>(false);

  const { isEmptySystem, activeFilters } = useContext(DiscoveryDashboardContext);

  useEffect(() => {
    const fetchWidgetData = async () => {
      try {
        setIsLoading(true);
        const response = await requestDashboardData(
          DashboardSubType.DATA_COVERAGE,
          WidgetName.DATA_COVERAGE,
          activeFilters,
        );
        if (response?.data?.dashboardData) {
          setDataCoverageData(response.data.dashboardData as DataCoverageWidgetData);
          setIsEmptyWidgetData(false);
        } else {
          setIsEmptyWidgetData(true);
        }
      } catch (e) {
        setAreaChartData([]);
        setProgressChartData(getInitialProgressChartData());
      } finally {
        setIsLoading(false);
      }
    };

    fetchWidgetData();
  }, [activeFilters]);

  useEffect(() => {
    if (dataCoverageData) {
      const dataPoints = dataCoverageData?.[viewByOption]?.dataPoints;
      setAreaChartData(dataPoints);
      const progressData: ProgressChartData = {
        total: dataCoverageData?.[viewByOption]?.total,
        data: transformDataPointsToProgressChart(dataPoints),
      };

      setProgressChartData(prepareProgressChartData(progressData));
      setIsLoading(false);
    }
  }, [dataCoverageData]);

  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string): void => {
    if (value === viewByOption) {
      return;
    }
    const valueTyped = value as DataCoverageToggleOption;
    const dataPoints = dataCoverageData?.[valueTyped]?.dataPoints ?? [];
    setViewByOption(valueTyped);
    setAreaChartData(dataPoints);
    const progressData: ProgressChartData = {
      total: dataCoverageData?.[valueTyped].total,
      data: transformDataPointsToProgressChart(dataPoints),
    };

    setProgressChartData(prepareProgressChartData(progressData));

    onToggle?.(valueTyped);
  };

  return (
    <BigidWidgetContainer
      content={
        <DataCoverageCharts
          areaChart={areaChartData}
          progressChart={progressChartData}
          isLoading={isLoading}
          viewByOption={viewByOption}
        />
      }
      titleConfig={{
        title,
        iconAfter: {
          icon: BigidInfoIcon,
          tooltip: (
            <>
              <BigidBody1 display="inline" fontWeight="bold">
                {title}
              </BigidBody1>
              <BigidBody1 display="inline">{t('dataCoverageWidget.tooltip')}</BigidBody1>{' '}
            </>
          ),
        },
      }}
      actionsSlot={
        <>
          {!isEmptySystem && (
            <BigidToggleButton
              onChange={onToggleChange}
              initialSelected={INITIAL_TOGGLE_VALUE}
              size="small"
              toggleButtons={[
                {
                  value: 'volume',
                  label: t('dataCoverageWidget.volume'),
                  bi: getToggleBiEvent('volume', ReportWidgets.COVERAGE),
                },
                {
                  value: 'amount',
                  label: t('dataCoverageWidget.amount'),
                  bi: getToggleBiEvent('amount', ReportWidgets.COVERAGE),
                },
              ]}
            />
          )}
        </>
      }
      emptyStateConfig={{
        isEmpty: isEmptySystem || isEmptyWidgetData,
        showEmptyStateWithFilter: isEmptyWidgetData,
        illustration: BigidNoDataIllustration,
        description: <BigidBody1>{t('emptyStateWidget.noData')}</BigidBody1>,
      }}
    />
  );
};
