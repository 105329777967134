import { Box, ClickAwayListener, Popper } from '@mui/material';
import React, { FC, PropsWithChildren, RefObject } from 'react';

export type QuickSearchPopperProps = {
  open: boolean;
  anchorEl: RefObject<HTMLFormElement>;
  handleClose: (event: MouseEvent | TouchEvent) => void;
};

export const QuickSearchPopper: FC<PropsWithChildren<QuickSearchPopperProps>> = ({
  anchorEl,
  children,
  handleClose,
  open,
}) => {
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        placement="bottom"
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            borderRadius: '4px',
            border: theme => theme.vars.tokens.bigid.borderDefault,
            width: anchorEl.current?.clientWidth || 'auto',
            minHeight: '150px',
          }}
        >
          {children}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};
