import {
  BigidAdvancedToolbarDropdownFilter,
  BigidDropdownOption,
  QueryComposerOperandConfig,
} from '@bigid-ui/components';

const booleanFiltersIds = ['objectStatus', 'duplicateFiles'];

export const getBuilderOperands = (
  filters: BigidAdvancedToolbarDropdownFilter[] = [],
): QueryComposerOperandConfig[] => {
  return filters.map(filter => {
    return {
      isMulti: true,
      leftOperand: {
        displayValue: filter.title,
        id: filter.field,
        value: filter.field,
      },
      useAsyncSearch: true,
      fetchRightOperands: async query => {
        const options = await filter.asyncOptionsFetch([], null, {
          searchText: query?.query || '',
          additionalBiqlFilter: '',
        });

        //NOTE: replacing boolean filters' option value so it'd be parsed correctly.
        if (booleanFiltersIds.includes(String(filter.id))) {
          const optionValueMap: Record<string, string> = {
            with: 'true',
            without: 'false',
          };
          return options.map(opt => ({
            ...opt,
            value: optionValueMap[opt.id] || opt.id,
          }));
        }

        return options.map<BigidDropdownOption>(opt => ({ ...opt, value: opt.id }));
      },
    };
  });
};

export const adjustFiltersWithInitialOptions = async (
  filters: BigidAdvancedToolbarDropdownFilter[],
): Promise<BigidAdvancedToolbarDropdownFilter[]> => {
  const adjustedFilters = await Promise.all(
    filters.map(async filter => {
      const options = await filter.asyncOptionsFetch([], null, { searchText: '', additionalBiqlFilter: '' });
      return {
        ...filter,
        options,
      };
    }),
  );

  return adjustedFilters;
};

export const getOperandsFromAdjustedFilters = async (filters: BigidAdvancedToolbarDropdownFilter[]) => {
  const adjustedFilters = await adjustFiltersWithInitialOptions(filters);

  return getBuilderOperands(adjustedFilters);
};
