import { analyticsService } from '../../services/analyticsService';
import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';

export enum ExecutiveDashboardTrackingEvents {
  EXECUTIVE_DASHBOARD_EXPORT_PDF_CLICKED = 'EXECUTIVE_DASHBOARD_EXPORT_PDF_CLICKED!',
  EXECUTIVE_DASHBOARD_SAVED_VIEW_CLICKED = 'EXECUTIVE_DASHBOARD_SAVED_VIEW_CLICKED',
  EXECUTIVE_DASHBOARD_SAVE_VIEW_CLICKED = 'EXECUTIVE_DASHBOARD_SAVE_VIEW_CLICKED',
}

export const trackExecutiveDashboardEvent = (
  eventType: ExecutiveDashboardTrackingEvents,
  data?: Record<string, unknown>,
) => {
  analyticsService.trackManualEvent(eventType as any, data);
};

export const getBiEvent = (
  eventType: ExecutiveDashboardTrackingEvents,
  activeFilters: BigidAdvancedToolbarFilterUnion[],
  activeWidgetIds: string[],
) => {
  return {
    eventType,
    eventData: {
      activeFilters,
      activeWidgetIds,
    },
  };
};
