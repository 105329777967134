import { httpService } from '../../services/httpService';
import { DateISO8601 } from '../../types/types';
import { isUserDefinedTag, TagApplicationType } from './TagsManagementUtils';

export enum TagAssignmentTarget {
  object = 'OBJECT',
  column = 'COLUMN',
}

export enum TagCompositionPartType {
  tag = 'TAG',
  value = 'VALUE',
}

export class TagProperties {
  applicationType?: TagApplicationType;
  hidden?: boolean;
  isExplicit?: boolean;
  displayName?: string;
  explicitValueType?: 'number' | 'string' | 'date';
}

export interface TagEntity {
  tagName?: string;
  tagValue?: string;
  tagId: string;
  valueId: string;
  tagType?: TagAssignmentTarget;
  isMutuallyExclusive?: boolean;
  properties?: TagProperties;
}

export type TagsManagementGridRecordValue = Pick<TagEntity, 'valueId' | 'tagValue'>;

export type TagsManagementGridRecord = Pick<TagEntity, 'tagId' | 'tagName' | 'isMutuallyExclusive'> & {
  tagValues: TagsManagementGridRecordValue[];
} & { displayName: string };
export interface UseTagPayloadData {
  fullyQualifiedName: string;
  source: string;
  type: TagAssignmentTarget;
  context?: string;
  tags: TagEntity[];
}

export type TagMutualExclusion = { from: Partial<TagEntity>; to: Partial<TagEntity> };

export interface UseTagResponseData {
  mutuallyExcluded?: TagMutualExclusion[];
}

export interface TagPayloadData {
  _id?: string;
  name: string;
  type: TagCompositionPartType;
  description?: string;
  parentId?: string;
  isMutuallyExclusive?: boolean;
}

export interface TagResponseEntity {
  _id: string;
  name: string;
  type: TagCompositionPartType;
  description?: string;
  parent_id?: string;
  created_at: DateISO8601;
  updated_at: DateISO8601;
}

const refreshHeaders = {
  'catalog-refresh-option': 'refresh',
};

export const getTags = () => {
  return httpService.fetch<{ data: TagResponseEntity[] }>(`data-catalog/tags`).then(({ data }) => data.data);
};

export const getTagsAllPairs = (searchQuery?: string, userDefinedOnly?: boolean) => {
  const query = searchQuery ? `?search=${searchQuery}` : '';

  return httpService
    .fetch<{ data: TagEntity[] }>(`data-catalog/tags/all-pairs${query}`)
    .then(({ data }) => (userDefinedOnly ? data.data.filter(tag => isUserDefinedTag(tag)) : data.data));
};

export const getDsTagsAllPairs = (searchQuery?: string) => {
  const query = searchQuery ? `?search=${encodeURIComponent(searchQuery)}` : '';
  return httpService
    .fetch<{ data: TagEntity[] }>(`data-catalog/tags/all-pairs-ds${query}`)
    .then(({ data }) => data.data);
};

export const getAttachedTagsAllPairs = (searchQuery?: string) => {
  const query = searchQuery ? `?search=${searchQuery}` : '';
  return httpService
    .fetch<{ data: TagEntity[] }>(`data-catalog/assigned-tags/all-pairs${query}`)
    .then(({ data }) => data.data);
};

export const getAttachedDsTagsAllPairs = (searchQuery?: string) => {
  const query = searchQuery ? `?search=${encodeURIComponent(searchQuery)}` : '';
  return httpService
    .fetch<{ data: TagEntity[] }>(`data-catalog/assigned-tags/all-pairs-ds${query}`)
    .then(({ data }) => data.data);
};

export const createTag = (data: TagPayloadData) => {
  return httpService.post(`data-catalog/tags`, data).then(({ data }) => data);
};

export const updateTag = (tagId: string, data: Partial<TagPayloadData>) => {
  return httpService.put(`data-catalog/tags/${tagId}`, data).then(({ data }) => data);
};

export const deleteTag = (id: string) => {
  return httpService.delete(`data-catalog/tags/${id}`).then(({ data }) => data);
};

export const attachTags = (data: UseTagPayloadData[]) => {
  return httpService
    .post(`data-catalog/manual-fields/tags`, { data }, undefined, refreshHeaders)
    .then(({ data }) => data);
};

export const detachTags = (data: UseTagPayloadData[]) => {
  return httpService.delete(`data-catalog/manual-fields/tags`, { data }, refreshHeaders).then(({ data }) => data);
};
