import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIView } from '@uirouter/react';
import { COMPLIANCE_FRAMEWORKS_PERMISSIONS, COMPLIANCE_REGULATIONS_PERMISSIONS } from '@bigid/permissions';
import { BigidTabsAndContent, BigidTabsAndContentProps, BigidTabsItem } from '@bigid-ui/components';

import { $state, $transitions } from '../../services/angularServices';
import { isPermitted } from '../../services/userPermissionsService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { pageHeaderService, TitleObject } from '../../../common/services/pageHeaderService';
import { CONFIG } from '../../../config/common';

import { FRAMEWORKS_NAMESPACE, REGULATIONS_NAMESPACE } from './translations';
import { AddRegulationsButton } from './Regulations/AddRegulationsButton';

export function RegulationsAndFrameworks() {
  const { t: tRegulations } = useTranslation(REGULATIONS_NAMESPACE);
  const { t: tFrameworks } = useTranslation(FRAMEWORKS_NAMESPACE);
  const tabs = useMemo<Array<BigidTabsItem & { name: string; title: TitleObject }>>(
    () => [
      ...(isPermitted(COMPLIANCE_FRAMEWORKS_PERMISSIONS.READ.name)
        ? [
            {
              label: tFrameworks('Labels.frameworks'),
              name: CONFIG.states.FRAMEWORKS,
              title: {
                pageTitle: tFrameworks('Titles.main'),
              },
            },
          ]
        : []),
      ...(isPermitted(COMPLIANCE_REGULATIONS_PERMISSIONS.READ.name) && getApplicationPreference('REGULATIONS_ENABLED')
        ? [
            {
              label: tRegulations('Labels.regulations'),
              name: CONFIG.states.REGULATIONS,
              title: {
                pageTitle: tRegulations('Titles.main'),
                rightSideComponentsContainer: <AddRegulationsButton />,
              },
            },
          ]
        : []),
    ],
    [tFrameworks, tRegulations],
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    Math.max(
      tabs.findIndex(tab => tab.name === $state.$current.name),
      0,
    ),
  );
  const tabsAndContentConfig = useMemo<BigidTabsAndContentProps>(
    () => ({
      tabProps: {
        size: 'large',
        tabs: tabs,
        onChange: tabIndex => {
          $state.go(tabs[tabIndex].name);
          setSelectedTabIndex(tabIndex);
        },
        selectedIndex: selectedTabIndex,
      },
    }),
    [selectedTabIndex, tabs],
  );

  useEffect(() => {
    const tab = tabs[selectedTabIndex];
    tab && pageHeaderService.setTitle(tab.title);

    if ($state.current.name === CONFIG.states.REGULATIONS_FRAMEWORKS) {
      $state.go(tabs[selectedTabIndex].name);
    }
    const deregisterListener = $transitions.onBefore(
      {
        to: state => state.name.includes(CONFIG.states.REGULATIONS_FRAMEWORKS),
      },
      transition => {
        const { name } = transition.to();
        const tabIndex = Math.max(
          0,
          tabs.findIndex(i => i.name === name),
        );

        if (name === CONFIG.states.REGULATIONS_FRAMEWORKS) {
          return $state.target(tabs[tabIndex].name);
        }
        setSelectedTabIndex(tabIndex);
      },
    );

    const deregisterTransitionListener = $transitions.onEnter(
      {
        to: state => state.name.includes(CONFIG.states.REGULATIONS_FRAMEWORKS),
      },
      transition => {
        const { name } = transition.to();
        const tab = tabs.find(i => i.name === name);
        pageHeaderService.setTitle(tab.title);
      },
    );

    return () => {
      deregisterListener?.();
      deregisterTransitionListener?.();
    };
  }, [tRegulations, selectedTabIndex, tabs]);

  return (
    <div>
      <BigidTabsAndContent {...tabsAndContentConfig} />
      <UIView />
    </div>
  );
}
