import React, { ChangeEvent, useState } from 'react';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { PrimaryButton, TertiaryButton, BigidTextField } from '@bigid-ui/components';
import { getFixedT, useLocalTranslation } from '../../translations';

const TRANSLATION_PATH = 'wizard.previewStep.addFilterDialog';
const t = getFixedT(TRANSLATION_PATH);

interface DialogContentProps {
  value: string;
  onChange: (event: any) => void;
}

export const DialogContent = ({ value, onChange }: DialogContentProps) => {
  const [filter, setFilter] = useState(value);

  const onHandleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter(e.target.value);
    onChange(e);
  };

  return (
    <>
      <BigidTextField value={filter} onChange={onHandleChange} multiline={true} rows={5} />
    </>
  );
};

interface openAddColumnFilterDialogProps {
  filter: string;
}

export const openAddColumnFilterDialog = async ({ filter }: openAddColumnFilterDialogProps) => {
  let filterValue = filter || '';

  const contentProps = {
    onChange: (event: any) => {
      filterValue = event.target.value;
    },
    value: filterValue,
  };

  return new Promise<{ filterValue?: string; isSaved: boolean }>(resolve => {
    openSystemDialog({
      title: t('title'),
      contentProps,
      content: DialogContent,
      onClose: () => null,
      maxWidth: 'xs',
      buttons: [
        {
          text: t('cancelBtn'),
          component: TertiaryButton,
          onClick: () => {
            resolve({
              isSaved: false,
            });
          },
          isClose: true,
        },
        {
          text: t('saveBtn'),
          component: PrimaryButton,
          onClick: () => {
            resolve({
              filterValue,
              isSaved: true,
            });
          },
          isClose: true,
        },
      ],
    });
  });
};
