import type {
  BigidAdvancedToolbarFilterUnion,
  BigidFieldFilter,
  OptionsTypes,
  DateRangeFilterSchema,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { DiscoveryDashboardWidget, ReportWidgets } from './types';

const discoveryDashboardWidgetToReportWidgetMapper = {
  [DiscoveryDashboardWidget.DATA_COVERAGE]: ReportWidgets.COVERAGE,
  [DiscoveryDashboardWidget.DATA_DUPLICATION]: ReportWidgets.DUPLICATION,
  [DiscoveryDashboardWidget.DATA_RISK]: ReportWidgets.RISKS,
  [DiscoveryDashboardWidget.SENSITIVE_DATA]: ReportWidgets.SENSITIVE,
};

export const isRangeFilter = (option: OptionsTypes): option is DateRangeFilterSchema => 'pickersState' in option;

const formatDate = (date: Date) =>
  new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);

const mapRangePickerToStringDateRange = (range: DateRangeFilterSchema) => {
  return `${formatDate(range?.pickersState?.dates?.from)} - ${formatDate(range?.pickersState?.dates?.until)}`;
};

const mapDropdownOptionsToStringArray = (options: BigidDropdownOption[]) =>
  options.map(({ value, displayValue }) => displayValue ?? value);

export const mapActiveFiltersToBigidFieldFilters = (active: BigidAdvancedToolbarFilterUnion[]): BigidFieldFilter[] =>
  active.map(({ field, operator, options, title }) => ({
    field: title ?? field, // @info use 'title' instead of 'field' value to get correct filter title
    operator,
    value: isRangeFilter(options) ? mapRangePickerToStringDateRange(options) : mapDropdownOptionsToStringArray(options),
  }));

export const mapDiscoveryDashboardWidgetToReportWidget = (widget: string): ReportWidgets =>
  discoveryDashboardWidgetToReportWidgetMapper[widget as DiscoveryDashboardWidget];
