import { BigidFilter } from '@bigid-ui/components';
import { BigidAreaChartDataItem } from '@bigid-ui/visualisation';
import { Aggregation } from '../DataExplorerSearchResults/types';
import { DataDuplicationDataPoint } from './widgets/types/DataDuplicationWidgetTypes';
import { SensitiveData } from './widgets';

export enum ReportWidgets {
  COVERAGE = 'data_coverage',
  RISKS = 'data_risk',
  SENSITIVE = 'sensitive_data',
  DUPLICATION = 'data_duplication',
}

export enum DiscoveryDashboardWidget {
  DATA_COVERAGE = 'dataCoverage',
  DATA_RISK = 'dataRisk',
  SENSITIVE_DATA = 'sensitiveData',
  DATA_DUPLICATION = 'dataDuplication',
}

export interface WidgetFetcherPayload {
  group: string;
  type: string;
  subType: string;
  additionalProperties: AdditionalProperties;
}

export interface AdditionalProperties {
  widgets: Widget[];
  filter: string;
  origin: string;
}

export interface Widget {
  widgetAggregationName: string;
  gridFieldName?: string;
}

export interface DataCoverageDataPoint extends BigidAreaChartDataItem {
  listed: number;
  scanned: number;
}

export interface DataRiskDataPoint extends BigidAreaChartDataItem {
  discovered: number;
  scanned?: number;
}

interface DataCoverageTotalPoints {
  total: number;
  dataPoints: DataCoverageDataPoint[];
}

export interface DataRiskTotalPoints {
  total: number;
  dataPoints: DataRiskDataPoint[];
}

export interface DataCoverageWidgetData {
  amount: DataCoverageTotalPoints;
  volume: DataCoverageTotalPoints;
}

export interface DataRiskWidgetData {
  findings: DataRiskTotalPoints;
  objects: DataRiskTotalPoints;
}

export type DashboardData = {
  dashboardData:
    | DataCoverageWidgetData
    | DataRiskWidgetData
    | DataDuplicationDataPoint[]
    | SensitiveData[]
    | Aggregation;
};

export type ExecutiveDashboardResponse = {
  data: DashboardData;
};

export interface ProgressChartData {
  total: number;
  data: ProgressChartDataPoint[];
}

export interface ProgressChartDataPoint {
  category: string;
  value: number;
  isActive: boolean;
  color: string;
}

export type WidgetSettings = {
  widgetId: string;
  filters?: BigidFilter;
};

export enum DiscoveryDashboardTrackingEvents {
  DISCOVERY_DASHBOARD_WIDGET_VIEW_TOGGLE_CLICKED = 'DISCOVERY_DASHBOARD_WIDGET_VIEW_TOGGLE_CLICKED',
}
