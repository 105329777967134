import { BigidAdvancedToolbarFilterTypes, BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { httpService } from '../../../services/httpService';
import { SeverityLevel, WidgetAggregationNames, WidgetSubTypes } from '../PrivacyExecutiveDashboardTypes';
import { DataSource } from '../../../utilities/dataSourcesUtils';
import { ApplicationEntity } from '../../ApplicationSetup/types';
import { VendorProject } from '../../Vendors/VendorsTypes';
import { LegalEntitiesInterface } from '../../LegalEntities/LegalEntitiesTypes';

interface WidgetPayload {
  subType: WidgetSubTypes;
  filter: BigidAdvancedToolbarFilterUnion[];
}

const WIDGET_ENDPOINT = `executive-dashboard/widget`;
const WIDGET_GROUP = 'PRIVACY';
const WIDGET_TYPE = 'PRIVACY';

const toISOString = (date: Date | string | null) => {
  if (date instanceof Date) {
    return date.toISOString();
  }
  return date;
};

const getQuery = (filter: BigidAdvancedToolbarFilterUnion) => {
  if (filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE) {
    const { from, until } = filter.options.pickersState.dates;
    return `(_es_updated_at >= \"${toISOString(from)}\" AND _es_updated_at <= \"${toISOString(until)}\")`;
  }
  const options = filter.options.map(o => JSON.stringify(o.id)).join(',');
  return `$${filter.field}.id IN (${options})`;
};

const buildFilterQuery = (filter: BigidAdvancedToolbarFilterUnion[]) => filter.map(getQuery).join(' AND ');

export const fetchWidgetData = async (widgetRequestData: WidgetPayload) => {
  const { subType, filter } = widgetRequestData;
  const {
    data: {
      data: { dashboardData },
    },
  } = await httpService.post(WIDGET_ENDPOINT, {
    group: WIDGET_GROUP,
    type: WIDGET_TYPE,
    subType,
    additionalProperties: {
      filter: buildFilterQuery(filter),
      widgets: [
        {
          widgetAggregationName: WidgetAggregationNames[subType],
          isTotalRequired: true,
        },
      ],
    },
  });

  return dashboardData;
};

export const fetchSeverityLevels = async (): Promise<SeverityLevel[]> => {
  const {
    data: { data },
  } = await httpService.fetch('privacy/risk-cases/severity-levels');
  return data;
};

export const fetchDataSources = async (): Promise<DataSource[]> => {
  const {
    data: { data },
  } = await httpService.fetch('ds-connections-types');
  return data;
};

export const fetchAssets = async (): Promise<ApplicationEntity[]> => {
  const {
    data: { applications },
  } = await httpService.fetch('applications');

  return applications;
};

export const fetchLegalEntities = async (): Promise<LegalEntitiesInterface[]> => {
  const {
    data: {
      data: { legalEntities },
    },
  } = await httpService.fetch(`data-mapping/legal-entities`);

  return legalEntities;
};

export async function fetchVendors(): Promise<VendorProject[]> {
  const {
    data: {
      data: { vendorProjects },
    },
  } = await httpService.fetch('data-mapping/vendors/projects');

  return vendorProjects;
}
