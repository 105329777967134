import React, { FC, memo, useContext } from 'react';
import { DataSourceTestConnectionStatusEnum } from '../../hooks/useTestConnection';
import { DataSourceConfigurationContext } from '../../DataSourceConfigurationContext';
import { SideContentConnectedStatus } from './ConnectedStatus/SideContectConnectedStatus';
import { SideContentConnectionError } from './ConnectionError/SideContectConnectionError';

const connectionStatusToComponenet = ({
  handleEditConnection,
}: SideStepConnectionTabProps): Record<DataSourceTestConnectionStatusEnum, JSX.Element | null> => ({
  [DataSourceTestConnectionStatusEnum.success]: <SideContentConnectedStatus onEditConnection={handleEditConnection} />,
  [DataSourceTestConnectionStatusEnum.failed]: <SideContentConnectionError />,
  [DataSourceTestConnectionStatusEnum.partialSuccess]: <SideContentConnectionError />,
  [DataSourceTestConnectionStatusEnum.notStarted]: null,
});

export interface SideStepConnectionTabProps {
  handleEditConnection: () => Promise<void>;
}

export const SideStepConnectionTab: FC<SideStepConnectionTabProps> = memo(props => {
  const { testStatus } = useContext(DataSourceConfigurationContext);
  const sideContent = connectionStatusToComponenet({ ...props })[testStatus];
  return <>{sideContent}</>;
});
