import React, { useMemo } from 'react';
import { SelectedColumn } from './useGetColumnsConfig';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { ColumnPreview } from './ColumnPreview/ColumnPreview';
import { styled } from '@mui/material';
import { ColumnsGridRow } from './sidePanelColumnsService';

const Root = styled('div')`
  display: flex;
  flex-grow: 1;
  min-height: 150px;
  overflow-y: auto;
  max-height: 550px;
  align-items: center;
  justify-content: center;
`;

type ColumnsMasterDetailsPreviewProps = {
  fullyQualifiedName: string;
  columnName: string;
};

export const ColumnsMasterDetailsPreview = ({ columnName, fullyQualifiedName }: ColumnsMasterDetailsPreviewProps) => {
  const { isColumnPreviewAvailable } = useMemo(
    () => ({
      isColumnPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
    }),
    [],
  );

  if (!isColumnPreviewAvailable) {
    return null;
  }

  return (
    <Root>
      <ColumnPreview columnName={columnName} fullyQualifiedName={fullyQualifiedName} />
    </Root>
  );
};
