import React, { useEffect, useState } from 'react';
import { ErrorInsights } from './mappers/styles';
import { DataSourceConnectionErrorInsightsContent } from './DataSourceConnectionErrorInsightsContent';
import { BigidInlineNotification, BigidInlineNotificationTextProps } from '@bigid-ui/components';
import { getScannerLogs, GetHighlightedWordsForLogType } from '../DataSourceConfiguration/utils/scannerLogsUtils';
import { BIGID_SUPPORT_URL, MAX_ERROR_INSIGHTS_TITLE_LENGTH, MIN_ERROR_OPTIONS_VISIBLE } from './constants/constants';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../DataSourcesEventTrackerUtils';
import { useDataSourceModalContext } from './hooks/useDataSourceModalContext';
import { useLocalTranslation } from './translations';
import type { TestConnectionErrorResolution } from './hooks/useTestConnection';
import { DataSourceConfigurationState } from '../DataSourceConfiguration/hooks/useDataSourceConfigState';

export type DataSourceConnectionErrorInsightsOption = { content: string; title: string; id: string };

type DataSourceConnectionErrorInsightsProps = {
  dataAid?: string;
  title: string;
  options: DataSourceConnectionErrorInsightsOption[];
  logs: GetHighlightedWordsForLogType;
  resolution: TestConnectionErrorResolution;
  authType: string;
  state?: DataSourceConfigurationState;
};

const NO_OPTIONS: DataSourceConnectionErrorInsightsOption[] = [];
const NO_TOOLTIP: string = null;

const DataSourceConnectionErrorInsightsTitle = ({
  title,
}: Pick<DataSourceConnectionErrorInsightsProps, 'title'>): JSX.Element => (
  <ErrorInsights.Title>{title}</ErrorInsights.Title>
);

export const DataSourceConnectionErrorInsights = ({
  dataAid = 'DataSourceConnectionErrorInsights',
  title,
  options,
  logs,
  resolution,
  authType,
  state,
}: DataSourceConnectionErrorInsightsProps): JSX.Element => {
  const [shouldShowMore, setShowMore] = useState(false);
  const { configDataSourceState } = useDataSourceModalContext();
  const { t } = useLocalTranslation();
  const isDomainIssue = state?.testStatus === 'PARTIAL_SUCCESS';
  const isTitleMaxLengthExceeded = title.length > MAX_ERROR_INSIGHTS_TITLE_LENGTH;

  const handleSendToSupport = () => {
    window.open(BIGID_SUPPORT_URL);
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_CONTACT_SUPPORT_CLICK, {
      dsType: configDataSourceState?.selectedType,
    });
  };

  const handleViewLogs = () => {
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SEE_LOGS_CLICK, {
      dsType: configDataSourceState?.selectedType,
    });
    getScannerLogs('', logs);
  };

  useEffect(() => {
    setShowMore(options?.length <= MIN_ERROR_OPTIONS_VISIBLE);
  }, [options]);

  const text: BigidInlineNotificationTextProps[] = [
    {
      subText: (
        <DataSourceConnectionErrorInsightsContent
          resolution={resolution}
          authType={authType}
          options={options ?? NO_OPTIONS}
          shouldShowMore={shouldShowMore}
          onShowMore={() => setShowMore(true)}
          onViewLogs={handleViewLogs}
          onSupport={handleSendToSupport}
          state={state}
        />
      ) as unknown as string,
    },
  ];

  return (
    <ErrorInsights.Wrapper data-aid={dataAid}>
      <BigidInlineNotification
        open
        type={isDomainIssue ? 'warning' : 'error'}
        tooltip={isTitleMaxLengthExceeded ? title : NO_TOOLTIP}
        title={
          (
            <DataSourceConnectionErrorInsightsTitle
              title={
                isDomainIssue ? t('insights.genericDomainsFailureTitle') : title || t('insights.genericErrorTitle')
              }
            />
          ) as unknown as string
        }
        text={text}
      />
    </ErrorInsights.Wrapper>
  );
};
