import { ReactText, useCallback, useEffect, useState } from 'react';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../services/sseService';
import { CustomAppStatus } from '../../CustomApp/views/ActivityLog/ActivityLog';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { notificationService } from '../../../services/notificationService';

interface AutoDiscoveryResponse {
  execution: {
    message: string;
    progress: number;
    status_enum: CustomAppStatus;
    tpa_action_id: ReactText;
    preset_id: string;
  };
}

export const IGNORED_MESSAGES = ['Just Started'];
export const SHOW_WARNING_MESSAGES = ['Storage Access issue for'];

const DEFAULT_STATE = { id: '', status: '', msg: '' };

export const isAutoDiscoveryConfigEvent = (list?: AutoDiscoveryConfigModel[], presetId?: string) =>
  list?.some?.(({ _id }) => _id === presetId);

export const useGridUpdateBySseEvents = (configsListRef: React.MutableRefObject<AutoDiscoveryConfigModel[]>) => {
  const [prevEvent, setPrevEvent] = useState(DEFAULT_STATE);
  const handleAutoDiscoveryWizardBroadcastEventReceived = useCallback(
    async ({ results = [] }: SSEDataMessage<AutoDiscoveryResponse>) => {
      const { preset_id: presetId, message, status_enum } = results[0]?.execution || {};

      if (SHOW_WARNING_MESSAGES.some(pattern => message.includes(pattern))) {
        notificationService.error(message);
      }
      if (!IGNORED_MESSAGES.includes(message) && isAutoDiscoveryConfigEvent(configsListRef.current, presetId)) {
        setPrevEvent(({ id, status, msg }) => {
          if (id !== presetId || status_enum !== status || msg !== message) {
            entityEventsEmitter.emit(EntityEvents.RELOAD);
          }
          return { id: presetId, status: status_enum, msg: message };
        });
      } else {
        setPrevEvent(DEFAULT_STATE);
      }
    },
    [configsListRef],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.TPA_ACTIONS_EXECUTIONS,
      handleAutoDiscoveryWizardBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleAutoDiscoveryWizardBroadcastEventReceived]);
};
